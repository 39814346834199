export default function ShippingDateIcon() {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.98283 0.834998C5.5434 0.834998 5.99783 1.28943 5.99783 1.85V3.12846H14.4111V1.85C14.4111 1.28943 14.8656 0.834998 15.4261 0.834998C15.9867 0.834998 16.4411 1.28943 16.4411 1.85V3.12846H16.9593C18.4035 3.12846 19.5743 4.29923 19.5743 5.74346V8.03636V9.98371H17.5443V9.05136H10.2046H2.865V17.1943C2.865 17.5174 3.12691 17.7793 3.45 17.7793H10.2046V19.8093H3.45C2.00577 19.8093 0.835 18.6385 0.835 17.1943V8.03636V5.74346C0.835 4.29923 2.00578 3.12846 3.45 3.12846H3.96783V1.85C3.96783 1.28943 4.42226 0.834998 4.98283 0.834998ZM2.865 7.02136H10.2046H17.5443V5.74346C17.5443 5.42037 17.2824 5.15846 16.9593 5.15846H3.45C3.12691 5.15846 2.865 5.42037 2.865 5.74346V7.02136ZM21.0923 11.3766L21.5204 11.8052C21.9611 12.2464 21.9611 12.9617 21.5204 13.403L20.3926 14.5319L18.3685 12.5056L19.4962 11.3766C19.937 10.9354 20.6516 10.9354 21.0923 11.3766ZM17.8971 12.9775L19.9213 15.0038L13.9071 21.0251C13.8102 21.1222 13.6787 21.1767 13.5417 21.1767H11.7369L11.7367 19.3586C11.7367 19.2213 11.7912 19.0897 11.8881 18.9927L17.8971 12.9775Z"
                fill="#353535"
            />
        </svg>
    );
}
