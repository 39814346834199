const regionNamesInstance = new Intl.DisplayNames(["fr"], { type: "region" });

function displayCountryName(countryCode: string): string {
    try {
        const countryName = regionNamesInstance.of(countryCode);
        return countryName || countryCode;
    } catch (error) {
        return countryCode;
    }
}

export default displayCountryName;
