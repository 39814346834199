import React from "react";

interface customNotesProps {
    notes: string | null;
    "data-cy": string;
}

const CustomNotes = (props: customNotesProps) => (
    <>
        <h6 className="mb-3">Notes</h6>
        <li className="nlt-item-info">
            <div
                className="nlt-item-info-data"
                data-cy={props["data-cy"]}
            >
                {props.notes}
            </div>
        </li>
    </>
);

export default CustomNotes;
