/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";

import { Typeahead } from "react-bootstrap-typeahead";
import {
    Container,
    DropdownItem,
    DropdownMenu,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";

import {
    DeviceFragment,
    refetchComputeDashboardDataQuery,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    UserRole,
    useAffectUserDeviceMutation,
    useCreateUserMutation,
    useFindUsersQuery,
} from "graphql/management/model/apollo";
import displayAlertMessage from "utils/displayAlertMessage/displayAlertMessage";
import EmailRegExp from "utils/email-utils/email-utils";

interface ErrorAssignUser {
    email: boolean;
    firstName: boolean;
    lastName: boolean;
}

type AssignMultipleUsersProps = {
    selectedDevice: DeviceFragment[];
    setSelectedDevice: React.Dispatch<React.SetStateAction<DeviceFragment[]>>;
};

const AssignMultipleUser = (props: AssignMultipleUsersProps) => {
    const usersQuery = useFindUsersQuery();

    const [createUser] = useCreateUserMutation();
    const [affectUserDevice] = useAffectUserDeviceMutation({
        refetchQueries: [
            refetchFindDevicesQuery(),
            refetchFindUsersQuery(),
            refetchFindOrganisationsQuery(),
            refetchComputeDashboardDataQuery(),
        ],
    });

    const [userAffectationModel, setUserAffectationModal] =
        useState<boolean>(false);

    const [currentUser, setCurrentUser] = useState<string>("none");
    const [saving, setSaving] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorAssignUser | null>({
        email: false,
        firstName: false,
        lastName: false,
    });
    const [newEmail, setNewEmail] = useState("");
    const [newFirstName, setNewFirstname] = useState("");
    const [newLastName, setNewLastname] = useState("");

    const UserNames =
        usersQuery.data &&
        usersQuery.data.findUser?.map(
            (user) => `${user.firstName} ${user.lastName} (${user.email})`,
        );

    const ResetEdit = () => {
        setNewEmail("");
        setNewFirstname("");
        setNewLastname("");
        setErrors(null);
        setSaving(false);
    };

    const ToggleUserAffectationModal = () => {
        setUserAffectationModal(!userAffectationModel);
        setCurrentUser("none");
        ResetEdit();
    };

    const AffectUser = async () => {
        setSaving(true);
        const email: string =
            currentUser && currentUser?.length > 0
                ? currentUser.split("(")[1]?.split(")")[0]
                : "";
        setErrors(null);

        try {
            if (email?.length > 0) {
                const selectedSerials = props.selectedDevice
                    .filter((device) => device.affectedUser?.email !== email)
                    .map((device) => device.serialNumber);
                await affectUserDevice({
                    variables: {
                        serials: selectedSerials,
                        email,
                    },
                });
            } else if (
                EmailRegExp.test(newEmail) &&
                newLastName?.trim()?.length > 0 &&
                newFirstName?.trim()?.length > 0
            ) {
                await createUser({
                    variables: {
                        email: newEmail,
                        firstName: newFirstName,
                        lastName: newLastName,
                        organisations: null,
                        phoneNumber: "",
                        role: UserRole.USER,
                    },
                    onCompleted: async () => {
                        const selectedSerials = props.selectedDevice.map(
                            (device) => device.serialNumber,
                        );
                        await affectUserDevice({
                            variables: {
                                serials: selectedSerials,
                                email: newEmail,
                            },
                        });
                    },
                });
            } else {
                setErrors({
                    email: !(
                        newEmail?.trim()?.length && EmailRegExp.test(newEmail)
                    ),
                    firstName: !(
                        newFirstName?.trim()?.length &&
                        newFirstName?.trim()?.length > 0
                    ),
                    lastName: !(
                        newLastName?.trim()?.length &&
                        newLastName?.trim()?.length > 0
                    ),
                });
                setSaving(false);
                return;
            }
        } catch (error) {
            displayAlertMessage(
                "Vérifiez l'adresse email renseignée.<br/>Si le problème persiste, contactez nous dans le chat",
                "error",
                ResetEdit,
            );

            return;
        }
        props.setSelectedDevice([]);
        ToggleUserAffectationModal();
    };

    return (
        <>
            <DropdownMenu className="dropdown-content">
                <DropdownItem onClick={ToggleUserAffectationModal}>
                    Affecter un utilisateur
                </DropdownItem>
            </DropdownMenu>
            <Modal
                isOpen={userAffectationModel}
                toggle={ToggleUserAffectationModal}
                className="modal-body"
                centere
            >
                <ModalHeader toggle={ToggleUserAffectationModal}>
                    Qui utilise ces appareils ?
                </ModalHeader>
                <ModalBody>
                    <Container
                        fluid
                        className="bd-example-row"
                    >
                        <h6 className="mb-3">
                            Choisissez parmi vos utilisateurs :
                        </h6>
                        <FormGroup className="mb-3">
                            {(() => {
                                // https://github.com/ericgio/react-bootstrap-typeahead/issues/703
                                let ref: Typeahead<string> | null;
                                return (
                                    <Typeahead
                                        id="basic-typeahead"
                                        multiple={false}
                                        clearButton
                                        emptyLabel="Aucun résultat"
                                        paginationText="Plus de résultats..."
                                        onChange={(e) => setCurrentUser(e[0])}
                                        options={UserNames || []}
                                        placeholder="Choisir un utilisateur..."
                                        onBlur={() => ref?.hideMenu()}
                                        ref={(el) => (ref = el)}
                                    />
                                );
                            })()}
                        </FormGroup>

                        <h6 className="mb-3">
                            Ou créez un nouvel utilisateur :
                        </h6>
                        <Form
                            className="needs-validation"
                            id="management-amu"
                            name="management-amu"
                        >
                            <Row className="nlt-row-form-fix">
                                <div className="nlt-item-info-label-edit col-md-3 required">
                                    Adresse e-mail
                                </div>
                                <FormGroup className="col-md-7">
                                    {!errors?.email ? (
                                        <span className="mandatory-label-small" />
                                    ) : (
                                        <span className="nlt-mandatory-label-small nlt-font-red">
                                            {errors?.email &&
                                                "L'email est invalide"}
                                        </span>
                                    )}
                                    <Input
                                        className="form-control"
                                        type="email"
                                        placeholder="laura.petit@cleaq.com"
                                        onChange={(e) =>
                                            setNewEmail(e.target.value)
                                        }
                                        defaultValue=""
                                    />
                                </FormGroup>
                            </Row>
                            <Row className="nlt-row-form-fix">
                                <div className="nlt-item-info-label-edit col-md-3 required">
                                    Nom
                                </div>
                                <FormGroup className="col-md-7">
                                    {!errors?.lastName ? (
                                        <span className="mandatory-label-small" />
                                    ) : (
                                        <span className="nlt-mandatory-label-small nlt-font-red">
                                            {errors?.lastName &&
                                                "Le nom de famille est requis"}
                                        </span>
                                    )}
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Petit"
                                        onChange={(e) =>
                                            setNewLastname(e.target.value)
                                        }
                                        defaultValue=""
                                    />
                                </FormGroup>
                            </Row>
                            <Row className="nlt-row-form-fix">
                                <div className="nlt-item-info-label-edit col-md-3 required">
                                    Prénom
                                </div>
                                <FormGroup className="col-md-7">
                                    {!errors?.firstName ? (
                                        <span className="mandatory-label-small" />
                                    ) : (
                                        <span className="nlt-mandatory-label-small nlt-font-red">
                                            {errors?.firstName &&
                                                "Le prénom est requis"}
                                        </span>
                                    )}
                                    <Input
                                        className="form-control"
                                        type="text"
                                        onChange={(e) =>
                                            setNewFirstname(e.target.value)
                                        }
                                        placeholder="Laura"
                                        defaultValue=""
                                    />
                                </FormGroup>
                            </Row>
                        </Form>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    {!saving ? (
                        <>
                            <button
                                className="nlt-button-inside-form-cancel"
                                onClick={ToggleUserAffectationModal}
                            >
                                Annuler
                            </button>
                            <button
                                className="nlt-button-inside-form-save"
                                onClick={AffectUser}
                            >
                                Affecter l'utilisateur
                            </button>
                        </>
                    ) : (
                        <div className="nlt-loader-container-modal">
                            <div className="nlt-loader-save" />
                        </div>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
};

export default AssignMultipleUser;
