import {
    DeviceComputedStatus,
    DeviceOs,
    DeviceType,
} from "graphql/management/model/apollo";

export const osLabels = new Map([
    [DeviceOs.MAC, "MacOS"],
    [DeviceOs.WINDOWS, "Windows"],
    [DeviceOs.LINUX, "Linux"],
    [DeviceOs.ANDROID, "Android"],
    [DeviceOs.CHROME, "ChromeOS"],
    [DeviceOs.IOS, "iOS"],
    [DeviceOs.OTHER, "Autre"],
]);

export const typesLabels = new Map([
    [DeviceType.LAPTOP, "Ordinateur portable"],
    [DeviceType.DESKTOP, "Ordinateur bureau"],
    [DeviceType.TABLET, "Tablette"],
    [DeviceType.PHONE, "Smartphone"],
    [DeviceType.OTHER, "Autre"],
]);

export const statusList = [
    "À associer",
    "En stock",
    "En cours d'utilisation",
    "Incident en cours",
    "En maintenance",
];

export const computedStatusLabels = new Map([
    [DeviceComputedStatus.TO_LINK, statusList[0]],
    [DeviceComputedStatus.AVAILABLE, statusList[1]],
    [DeviceComputedStatus.BEING_USED, statusList[2]],
    [DeviceComputedStatus.INCIDENT, statusList[3]],
    [DeviceComputedStatus.MAINTENANCE, statusList[4]],
]);
