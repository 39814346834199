import React from "react";

import { CircularProgress } from "@material-ui/core";

import {
    useFindAdminQuery,
    useFindInstanceQuery,
} from "graphql/management/model/apollo";

export default function AccountCompanies() {
    const adminQuery = useFindAdminQuery();
    const instance = useFindInstanceQuery();

    if (adminQuery.loading || instance.loading) {
        return (
            <div className="loader-wrapper">
                <CircularProgress color="primary" />
            </div>
        );
    }

    const AdminList = adminQuery.data?.findUser.map((user) => (
        <div
            key={user.email}
            className="nlt-admin-container"
        >
            <div className="nlt-admin">
                <div className="nlt-admin-name">
                    {`${user.firstName} ${user.lastName}`}
                </div>
                <div className="nlt-admin-mail">{user.email}</div>
            </div>
            <div className="text-right">
                <a
                    href={`mailto:${user.email}`}
                    className="nlt-admin-button"
                >
                    <i className="icofont icofont-email" />
                </a>
            </div>
        </div>
    ));

    return (
        <div>
            <div className="nlt-card-full-company">
                <div className="nlt-card-header">
                    <span className="nlt-card-title">
                        Informations entreprise
                    </span>
                </div>
                <div className="nlt-wide-card-right-panel-container">
                    <div className="nlt-card-header mb-3">
                        <div
                            className="flex"
                            style={{ alignItems: "center" }}
                        >
                            <div className="icon-company">
                                <i
                                    className="icofont icofont-building-alt"
                                    style={{ fontSize: "40px" }}
                                />
                            </div>
                            <div className="nlt-card-title-company">
                                {instance.data?.currentInstance?.name}
                            </div>
                        </div>
                    </div>
                    <h6 className="mt-2 title6">Mes administrateurs</h6>
                    <div className="adminList">{AdminList}</div>
                </div>
            </div>
        </div>
    );
}
