/* eslint-disable consistent-return */
import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import settings from "config";
import ApiClientNames from "graphql/consts";

const errorLink = onError(({ operation, networkError, forward }) => {
    if (networkError) {
        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`);
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
            headers: {
                ...oldHeaders,
                authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
            },
        });
        return forward(operation);
    }
});

const httpManagementLink = new HttpLink({
    uri: `${settings.REACT_APP_API_MANAGEMENT_URL}`,
});

const httpOrderLink = new HttpLink({
    uri: `${settings.REACT_APP_API_ORDER_URL}`,
});

const httpCatalogLink = new HttpLink({
    uri: `${settings.REACT_APP_API_CATALOG_URL}`,
});

const authLink = setContext((request, { headers }) => {
    if (request.context?.clientName === ApiClientNames.CATALOG) {
        return { headers: { ...headers } };
    }
    const token = localStorage.getItem("bearerToken");
    return {
        headers: {
            ...headers,
            "Content-type": "application/json",
            authorization: `Bearer ${token}`,
        },
    };
});

const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
        authLink,
        errorLink,
        ApolloLink.split(
            (operation) =>
                operation.getContext()?.clientName === ApiClientNames.ORDER,
            httpOrderLink,
            ApolloLink.split(
                (operation) =>
                    operation.getContext()?.clientName ===
                    ApiClientNames.CATALOG,
                httpCatalogLink,
                httpManagementLink,
            ),
        ),
    ]),
});

export default apolloClient;
