class ProductAddress {
    id: string = "";

    name: string = "";

    recipient: string = "";

    label: string = "";

    zipcode: string = "";

    cityName: string = "";

    country: string = "";

    instructions: string = "";

    phoneNumber: string = "";

    constructor(
        id: string,
        name: string,
        recipient: string,
        label: string,
        zipcode: string,
        cityName: string,
        country: string,
        instructions: string,
        phoneNumber: string,
    ) {
        this.id = id;
        this.name = name;
        this.recipient = recipient;
        this.label = label;
        this.zipcode = zipcode;
        this.cityName = cityName;
        this.country = country;
        this.instructions = instructions;
        this.phoneNumber = phoneNumber;
    }
}

export default ProductAddress;
