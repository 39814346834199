import React from "react";

const UserPlaceHolder = () => (
    <div className="nlt-user-placeholder">
        <div className="nlt-document-placeholder-icon">
            <i className="icofont icofont-ui-user" />
        </div>
        <div className="nlt-document-info">
            <div className="nlt-document-title">Aucun Utilisateur</div>
            <div className="nlt-document-date">
                Retrouvez ici la liste de vos utilisateurs
            </div>
        </div>
    </div>
);

export default UserPlaceHolder;
