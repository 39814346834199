/* eslint-disable max-classes-per-file */
import {
    AudioAccessoryFragment,
    DockstationFragment,
    KeyboardFragment,
    MouseFragment,
    ScreenFragment,
    VariantFragment,
} from "graphql/catalog/model/apollo";

export class DeviceProductSpecs {
    processor: string | null;

    ram: string | null;

    storage: string | null;

    constructor(contenfulItem: VariantFragment) {
        this.processor = contenfulItem.processorValue;
        this.ram = contenfulItem.ramValue?.toString() ?? null;
        this.storage = contenfulItem.storageValue?.toString() ?? null;
    }
}

export class AudioProductSpecs {
    type: string;

    noiseCancellation: boolean;

    connectivity: string[] = [];

    constructor(contenfulItem: AudioAccessoryFragment) {
        this.type = contenfulItem.type ?? "n/a";
        this.noiseCancellation = contenfulItem.noiseCancellation ?? false;
        contenfulItem.connectivity?.forEach((e) => {
            if (e !== null) {
                this.connectivity.push(e);
            }
        });
    }
}

export class DockstationProductSpecs {
    compatibility: string;

    connection: string;

    connectivity: string[] = [];

    constructor(contenfulItem: DockstationFragment) {
        this.compatibility = contenfulItem.compatibility ?? "n/a";
        this.connection = contenfulItem.connection ?? "n/a";
        contenfulItem.connectivity?.forEach((e) => {
            if (e !== null) {
                this.connectivity.push(e);
            }
        });
    }
}

export class KeyboardsProductSpecs {
    compatibility: string;

    numPad: boolean;

    connectivity: string[] = [];

    constructor(contenfulItem: KeyboardFragment) {
        this.compatibility = contenfulItem.compatibility ?? "n/a";
        this.numPad = contenfulItem.numPad ?? false;
        contenfulItem.connectivity?.forEach((e) => {
            if (e !== null) {
                this.connectivity.push(e);
            }
        });
    }
}

export class MousesProductSpecs {
    brand: string;

    type: string;

    power: string;

    compatibility: string;

    connectivity: string[] = [];

    constructor(contenfulItem: MouseFragment) {
        this.brand = contenfulItem.brand ?? "n/a";
        this.type = contenfulItem.type ?? "n/a";
        this.power = contenfulItem.power ?? "n/a";
        this.compatibility = contenfulItem.compatibility ?? "n/a";
        contenfulItem.connectivity?.forEach((e) => {
            if (e !== null) {
                this.connectivity.push(e);
            }
        });
    }
}

export class ScreensProductSpecs {
    brand: string;

    size: number;

    resolution: string;

    refreshRate: number;

    integratedCamera: boolean;

    connectivity: string[] = [];

    constructor(contenfulItem: ScreenFragment) {
        this.brand = contenfulItem.brand ?? "n/a";
        this.size = contenfulItem.size ?? 0;
        this.resolution = contenfulItem.resolution ?? "n/a";
        this.refreshRate = contenfulItem.refreshRate ?? 0;
        this.integratedCamera = contenfulItem.integratedCamera ?? false;
        contenfulItem.connectivity?.forEach((e) => {
            if (e !== null) {
                this.connectivity.push(e);
            }
        });
    }
}
