import React from "react";

import "./placeholder.scss";

const PlaceHolder = () => (
    <div className="order-item-placeholder">
        <div className="nlt-document-placeholder-icon">
            <i className="icofont icofont-file-alt" />
        </div>
        <div className="nlt-document-info">
            <div className="nlt-document-title">Aucune Commande</div>
            <div className="nlt-document-date">
                Retrouvez ici la liste de vos commandes
            </div>
        </div>
    </div>
);

export default PlaceHolder;
