import React from "react";

import Input from "components/common/input/Input/Input";

import "./searchbar.scss";

interface SearchBarInterface {
    searchBarType?: string;
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    handleSearchChange: (event: any) => void;
    dataCy?: string;
}

const SearchBar = ({
    searchBarType = "wide",
    searchTerm,
    setSearchTerm,
    handleSearchChange,
    dataCy = "search-field",
}: SearchBarInterface) => (
    <div
        className={`${
            searchBarType === "card" ? "search-row-card" : "search-row-wide"
        }`}
    >
        <Input
            type="text"
            value={searchTerm}
            onChange={(e) => handleSearchChange(e)}
            placeholder="Recherche..."
            data-cy={dataCy}
        />
        {searchTerm && searchTerm?.length > 0 && (
            <i
                aria-hidden="true"
                onClick={() => setSearchTerm("")}
                className="fa fa-times cross-reset-input"
                data-cy="search-reset"
            />
        )}
    </div>
);

export default SearchBar;
