import Product from "components/business/order/product/product";
import ProductUser from "components/business/order/product/product-user";

import CartAction from "./cart-action";

class SetProductUserAction implements CartAction {
    payload: {
        product: Product;
        user: ProductUser | null;
    };

    constructor(product: Product, user: ProductUser | null) {
        this.payload = {
            product,
            user,
        };
    }

    apply(cart: Product[]) {
        const productIndex = cart.indexOf(this.payload.product);
        const updatedCart = [...cart];
        if (productIndex >= 0) {
            updatedCart[productIndex].user = this.payload.user;
        }
        return updatedCart;
    }
}

export default SetProductUserAction;
