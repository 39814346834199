/* eslint-disable */
// This file was automatically generated and should not be edited.
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends " $fragmentName" | "__typename"
              ? T[P]
              : never;
      };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    DateTime: { input: any; output: any };
    Dimension: { input: any; output: any };
    HexColor: { input: any; output: any };
    JSON: { input: any; output: any };
    Quality: { input: any; output: any };
};

/** Article for the knowledge center [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/article) */
export type Article = Entry & {
    __typename: "Article";
    content: Maybe<ArticleContent>;
    contentfulMetadata: ContentfulMetadata;
    json: Maybe<Scalars["JSON"]["output"]>;
    linkedFrom: Maybe<ArticleLinkingCollections>;
    sys: Sys;
};

/** Article for the knowledge center [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/article) */
export type ArticlecontentArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Article for the knowledge center [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/article) */
export type ArticlejsonArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Article for the knowledge center [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/article) */
export type ArticlelinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ArticleCollection = {
    __typename: "ArticleCollection";
    items: Array<Maybe<Article>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type ArticleContent = {
    __typename: "ArticleContent";
    json: Scalars["JSON"]["output"];
    links: ArticleContentLinks;
};

export type ArticleContentAssets = {
    __typename: "ArticleContentAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type ArticleContentEntries = {
    __typename: "ArticleContentEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type ArticleContentLinks = {
    __typename: "ArticleContentLinks";
    assets: ArticleContentAssets;
    entries: ArticleContentEntries;
    resources: ArticleContentResources;
};

export type ArticleContentResources = {
    __typename: "ArticleContentResources";
    block: Array<ArticleContentResourcesBlock>;
    hyperlink: Array<ArticleContentResourcesHyperlink>;
    inline: Array<ArticleContentResourcesInline>;
};

export type ArticleContentResourcesBlock = ResourceLink & {
    __typename: "ArticleContentResourcesBlock";
    sys: ResourceSys;
};

export type ArticleContentResourcesHyperlink = ResourceLink & {
    __typename: "ArticleContentResourcesHyperlink";
    sys: ResourceSys;
};

export type ArticleContentResourcesInline = ResourceLink & {
    __typename: "ArticleContentResourcesInline";
    sys: ResourceSys;
};

export type ArticleFilter = {
    AND?: InputMaybe<Array<InputMaybe<ArticleFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<ArticleFilter>>>;
    content_contains?: InputMaybe<Scalars["String"]["input"]>;
    content_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    content_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    json_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    sys?: InputMaybe<SysFilter>;
};

export type ArticleLinkingCollections = {
    __typename: "ArticleLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
};

export type ArticleLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum ArticleOrder {
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
    __typename: "Asset";
    contentType: Maybe<Scalars["String"]["output"]>;
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<Scalars["String"]["output"]>;
    fileName: Maybe<Scalars["String"]["output"]>;
    height: Maybe<Scalars["Int"]["output"]>;
    linkedFrom: Maybe<AssetLinkingCollections>;
    size: Maybe<Scalars["Int"]["output"]>;
    sys: Sys;
    title: Maybe<Scalars["String"]["output"]>;
    url: Maybe<Scalars["String"]["output"]>;
    width: Maybe<Scalars["Int"]["output"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetcontentTypeArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetdescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetfileNameArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetheightArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetlinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetsizeArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssettitleArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AsseturlArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
    transform?: InputMaybe<ImageTransformOptions>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetwidthArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssetCollection = {
    __typename: "AssetCollection";
    items: Array<Maybe<Asset>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type AssetFilter = {
    AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
    contentType?: InputMaybe<Scalars["String"]["input"]>;
    contentType_contains?: InputMaybe<Scalars["String"]["input"]>;
    contentType_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    contentType_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    contentType_not?: InputMaybe<Scalars["String"]["input"]>;
    contentType_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    contentType_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    description_contains?: InputMaybe<Scalars["String"]["input"]>;
    description_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    description_not?: InputMaybe<Scalars["String"]["input"]>;
    description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    description_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    fileName?: InputMaybe<Scalars["String"]["input"]>;
    fileName_contains?: InputMaybe<Scalars["String"]["input"]>;
    fileName_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    fileName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    fileName_not?: InputMaybe<Scalars["String"]["input"]>;
    fileName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    height?: InputMaybe<Scalars["Int"]["input"]>;
    height_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    height_gt?: InputMaybe<Scalars["Int"]["input"]>;
    height_gte?: InputMaybe<Scalars["Int"]["input"]>;
    height_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    height_lt?: InputMaybe<Scalars["Int"]["input"]>;
    height_lte?: InputMaybe<Scalars["Int"]["input"]>;
    height_not?: InputMaybe<Scalars["Int"]["input"]>;
    height_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    size?: InputMaybe<Scalars["Int"]["input"]>;
    size_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    size_gt?: InputMaybe<Scalars["Int"]["input"]>;
    size_gte?: InputMaybe<Scalars["Int"]["input"]>;
    size_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    size_lt?: InputMaybe<Scalars["Int"]["input"]>;
    size_lte?: InputMaybe<Scalars["Int"]["input"]>;
    size_not?: InputMaybe<Scalars["Int"]["input"]>;
    size_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    sys?: InputMaybe<SysFilter>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    title_contains?: InputMaybe<Scalars["String"]["input"]>;
    title_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    title_not?: InputMaybe<Scalars["String"]["input"]>;
    title_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    url?: InputMaybe<Scalars["String"]["input"]>;
    url_contains?: InputMaybe<Scalars["String"]["input"]>;
    url_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    url_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    url_not?: InputMaybe<Scalars["String"]["input"]>;
    url_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    url_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    width?: InputMaybe<Scalars["Int"]["input"]>;
    width_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    width_gt?: InputMaybe<Scalars["Int"]["input"]>;
    width_gte?: InputMaybe<Scalars["Int"]["input"]>;
    width_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    width_lt?: InputMaybe<Scalars["Int"]["input"]>;
    width_lte?: InputMaybe<Scalars["Int"]["input"]>;
    width_not?: InputMaybe<Scalars["Int"]["input"]>;
    width_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type AssetLinkingCollections = {
    __typename: "AssetLinkingCollections";
    audioCollection: Maybe<AudioCollection>;
    dockstationCollection: Maybe<DockstationCollection>;
    entryCollection: Maybe<EntryCollection>;
    integrationCollection: Maybe<IntegrationCollection>;
    keyboardCollection: Maybe<KeyboardCollection>;
    mouseCollection: Maybe<MouseCollection>;
    productCollection: Maybe<ProductCollection>;
    screenCollection: Maybe<ScreenCollection>;
};

export type AssetLinkingCollectionsaudioCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssetLinkingCollectionsdockstationCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssetLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssetLinkingCollectionsintegrationCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssetLinkingCollectionskeyboardCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssetLinkingCollectionsmouseCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssetLinkingCollectionsproductCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssetLinkingCollectionsscreenCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum AssetOrder {
    contentType_ASC = "contentType_ASC",
    contentType_DESC = "contentType_DESC",
    fileName_ASC = "fileName_ASC",
    fileName_DESC = "fileName_DESC",
    height_ASC = "height_ASC",
    height_DESC = "height_DESC",
    size_ASC = "size_ASC",
    size_DESC = "size_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    url_ASC = "url_ASC",
    url_DESC = "url_DESC",
    width_ASC = "width_ASC",
    width_DESC = "width_DESC",
}

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type Audio = Entry & {
    __typename: "Audio";
    companyCoefficient: Maybe<Scalars["JSON"]["output"]>;
    connectivity: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<AudioDescription>;
    image: Maybe<Asset>;
    linkedFrom: Maybe<AudioLinkingCollections>;
    name: Maybe<Scalars["String"]["output"]>;
    noiseCancellation: Maybe<Scalars["Boolean"]["output"]>;
    price24: Maybe<Scalars["Float"]["output"]>;
    price36: Maybe<Scalars["Float"]["output"]>;
    price48: Maybe<Scalars["Float"]["output"]>;
    purchasePriceHt: Maybe<Scalars["Float"]["output"]>;
    shippingDelay: Maybe<Scalars["Int"]["output"]>;
    sys: Sys;
    totalCostOfLeasing: Maybe<Scalars["Float"]["output"]>;
    totalCostOfOwnership: Maybe<Scalars["Float"]["output"]>;
    type: Maybe<Scalars["String"]["output"]>;
    webflowId: Maybe<Scalars["String"]["output"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudiocompanyCoefficientArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudioconnectivityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudiodescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudioimageArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudiolinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudionameArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudionoiseCancellationArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type Audioprice24Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type Audioprice36Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type Audioprice48Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudiopurchasePriceHtArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudioshippingDelayArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudiototalCostOfLeasingArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudiototalCostOfOwnershipArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudiotypeArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Audio accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/audio) */
export type AudiowebflowIdArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type AudioCollection = {
    __typename: "AudioCollection";
    items: Array<Maybe<Audio>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type AudioDescription = {
    __typename: "AudioDescription";
    json: Scalars["JSON"]["output"];
    links: AudioDescriptionLinks;
};

export type AudioDescriptionAssets = {
    __typename: "AudioDescriptionAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type AudioDescriptionEntries = {
    __typename: "AudioDescriptionEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type AudioDescriptionLinks = {
    __typename: "AudioDescriptionLinks";
    assets: AudioDescriptionAssets;
    entries: AudioDescriptionEntries;
    resources: AudioDescriptionResources;
};

export type AudioDescriptionResources = {
    __typename: "AudioDescriptionResources";
    block: Array<AudioDescriptionResourcesBlock>;
    hyperlink: Array<AudioDescriptionResourcesHyperlink>;
    inline: Array<AudioDescriptionResourcesInline>;
};

export type AudioDescriptionResourcesBlock = ResourceLink & {
    __typename: "AudioDescriptionResourcesBlock";
    sys: ResourceSys;
};

export type AudioDescriptionResourcesHyperlink = ResourceLink & {
    __typename: "AudioDescriptionResourcesHyperlink";
    sys: ResourceSys;
};

export type AudioDescriptionResourcesInline = ResourceLink & {
    __typename: "AudioDescriptionResourcesInline";
    sys: ResourceSys;
};

export type AudioFilter = {
    AND?: InputMaybe<Array<InputMaybe<AudioFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<AudioFilter>>>;
    companyCoefficient_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    connectivity_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    description_contains?: InputMaybe<Scalars["String"]["input"]>;
    description_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    image_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    noiseCancellation?: InputMaybe<Scalars["Boolean"]["input"]>;
    noiseCancellation_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    noiseCancellation_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    price24?: InputMaybe<Scalars["Float"]["input"]>;
    price24_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price24_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price24_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36?: InputMaybe<Scalars["Float"]["input"]>;
    price36_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price36_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48?: InputMaybe<Scalars["Float"]["input"]>;
    price48_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price48_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    purchasePriceHt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceHt_gt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_gte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceHt_lt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_lte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    shippingDelay?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shippingDelay_gt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_gte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    shippingDelay_lt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_lte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    sys?: InputMaybe<SysFilter>;
    totalCostOfLeasing?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfLeasing_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfLeasing_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfOwnership_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    type?: InputMaybe<Scalars["String"]["input"]>;
    type_contains?: InputMaybe<Scalars["String"]["input"]>;
    type_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    type_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    type_not?: InputMaybe<Scalars["String"]["input"]>;
    type_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId_not?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
};

export type AudioLinkingCollections = {
    __typename: "AudioLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
    productCollection: Maybe<ProductCollection>;
};

export type AudioLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AudioLinkingCollectionsproductCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<
        Array<InputMaybe<AudioLinkingCollectionsProductCollectionOrder>>
    >;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum AudioLinkingCollectionsProductCollectionOrder {
    appearanceOrder_ASC = "appearanceOrder_ASC",
    appearanceOrder_DESC = "appearanceOrder_DESC",
    battery_ASC = "battery_ASC",
    battery_DESC = "battery_DESC",
    bluetooth_ASC = "bluetooth_ASC",
    bluetooth_DESC = "bluetooth_DESC",
    brand_ASC = "brand_ASC",
    brand_DESC = "brand_DESC",
    colors_ASC = "colors_ASC",
    colors_DESC = "colors_DESC",
    fingerPrintReader_ASC = "fingerPrintReader_ASC",
    fingerPrintReader_DESC = "fingerPrintReader_DESC",
    foldable_ASC = "foldable_ASC",
    foldable_DESC = "foldable_DESC",
    height_ASC = "height_ASC",
    height_DESC = "height_DESC",
    isRefurbed_ASC = "isRefurbed_ASC",
    isRefurbed_DESC = "isRefurbed_DESC",
    keyboardBacklight_ASC = "keyboardBacklight_ASC",
    keyboardBacklight_DESC = "keyboardBacklight_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    osDescription_ASC = "osDescription_ASC",
    osDescription_DESC = "osDescription_DESC",
    os_ASC = "os_ASC",
    os_DESC = "os_DESC",
    repairability_ASC = "repairability_ASC",
    repairability_DESC = "repairability_DESC",
    screenSizeDescription_ASC = "screenSizeDescription_ASC",
    screenSizeDescription_DESC = "screenSizeDescription_DESC",
    screenSizeValue_ASC = "screenSizeValue_ASC",
    screenSizeValue_DESC = "screenSizeValue_DESC",
    shortDescription_ASC = "shortDescription_ASC",
    shortDescription_DESC = "shortDescription_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    tactile_ASC = "tactile_ASC",
    tactile_DESC = "tactile_DESC",
    thickness_ASC = "thickness_ASC",
    thickness_DESC = "thickness_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    type_ASC = "type_ASC",
    type_DESC = "type_DESC",
    webcam_ASC = "webcam_ASC",
    webcam_DESC = "webcam_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
    weight_ASC = "weight_ASC",
    weight_DESC = "weight_DESC",
    width_ASC = "width_ASC",
    width_DESC = "width_DESC",
    wifi_ASC = "wifi_ASC",
    wifi_DESC = "wifi_DESC",
}

export enum AudioOrder {
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    noiseCancellation_ASC = "noiseCancellation_ASC",
    noiseCancellation_DESC = "noiseCancellation_DESC",
    price24_ASC = "price24_ASC",
    price24_DESC = "price24_DESC",
    price36_ASC = "price36_ASC",
    price36_DESC = "price36_DESC",
    price48_ASC = "price48_ASC",
    price48_DESC = "price48_DESC",
    purchasePriceHt_ASC = "purchasePriceHt_ASC",
    purchasePriceHt_DESC = "purchasePriceHt_DESC",
    shippingDelay_ASC = "shippingDelay_ASC",
    shippingDelay_DESC = "shippingDelay_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    type_ASC = "type_ASC",
    type_DESC = "type_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
}

/** Changelog indicating new App updates [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/changelog) */
export type Changelog = Entry & {
    __typename: "Changelog";
    content: Maybe<ChangelogContent>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom: Maybe<ChangelogLinkingCollections>;
    publicationDate: Maybe<Scalars["DateTime"]["output"]>;
    shortDescription: Maybe<Scalars["String"]["output"]>;
    sys: Sys;
    title: Maybe<Scalars["String"]["output"]>;
};

/** Changelog indicating new App updates [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/changelog) */
export type ChangelogcontentArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Changelog indicating new App updates [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/changelog) */
export type ChangeloglinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Changelog indicating new App updates [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/changelog) */
export type ChangelogpublicationDateArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Changelog indicating new App updates [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/changelog) */
export type ChangelogshortDescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Changelog indicating new App updates [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/changelog) */
export type ChangelogtitleArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChangelogCollection = {
    __typename: "ChangelogCollection";
    items: Array<Maybe<Changelog>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type ChangelogContent = {
    __typename: "ChangelogContent";
    json: Scalars["JSON"]["output"];
    links: ChangelogContentLinks;
};

export type ChangelogContentAssets = {
    __typename: "ChangelogContentAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type ChangelogContentEntries = {
    __typename: "ChangelogContentEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type ChangelogContentLinks = {
    __typename: "ChangelogContentLinks";
    assets: ChangelogContentAssets;
    entries: ChangelogContentEntries;
    resources: ChangelogContentResources;
};

export type ChangelogContentResources = {
    __typename: "ChangelogContentResources";
    block: Array<ChangelogContentResourcesBlock>;
    hyperlink: Array<ChangelogContentResourcesHyperlink>;
    inline: Array<ChangelogContentResourcesInline>;
};

export type ChangelogContentResourcesBlock = ResourceLink & {
    __typename: "ChangelogContentResourcesBlock";
    sys: ResourceSys;
};

export type ChangelogContentResourcesHyperlink = ResourceLink & {
    __typename: "ChangelogContentResourcesHyperlink";
    sys: ResourceSys;
};

export type ChangelogContentResourcesInline = ResourceLink & {
    __typename: "ChangelogContentResourcesInline";
    sys: ResourceSys;
};

export type ChangelogFilter = {
    AND?: InputMaybe<Array<InputMaybe<ChangelogFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<ChangelogFilter>>>;
    content_contains?: InputMaybe<Scalars["String"]["input"]>;
    content_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    content_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    publicationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
    publicationDate_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    publicationDate_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
    publicationDate_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
    publicationDate_in?: InputMaybe<
        Array<InputMaybe<Scalars["DateTime"]["input"]>>
    >;
    publicationDate_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
    publicationDate_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
    publicationDate_not?: InputMaybe<Scalars["DateTime"]["input"]>;
    publicationDate_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["DateTime"]["input"]>>
    >;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shortDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    shortDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    sys?: InputMaybe<SysFilter>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    title_contains?: InputMaybe<Scalars["String"]["input"]>;
    title_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    title_not?: InputMaybe<Scalars["String"]["input"]>;
    title_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ChangelogLinkingCollections = {
    __typename: "ChangelogLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
};

export type ChangelogLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum ChangelogOrder {
    publicationDate_ASC = "publicationDate_ASC",
    publicationDate_DESC = "publicationDate_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    title_ASC = "title_ASC",
    title_DESC = "title_DESC",
}

export type ContentfulMetadata = {
    __typename: "ContentfulMetadata";
    tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
    tags?: InputMaybe<ContentfulMetadataTagsFilter>;
    tags_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ContentfulMetadataTagsFilter = {
    id_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    id_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    id_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
    __typename: "ContentfulTag";
    id: Maybe<Scalars["String"]["output"]>;
    name: Maybe<Scalars["String"]["output"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type Dockstation = Entry & {
    __typename: "Dockstation";
    companyCoefficient: Maybe<Scalars["JSON"]["output"]>;
    compatibility: Maybe<Scalars["String"]["output"]>;
    connection: Maybe<Scalars["String"]["output"]>;
    connectivity: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<DockstationDescription>;
    image: Maybe<Asset>;
    linkedFrom: Maybe<DockstationLinkingCollections>;
    name: Maybe<Scalars["String"]["output"]>;
    price24: Maybe<Scalars["Float"]["output"]>;
    price36: Maybe<Scalars["Float"]["output"]>;
    price48: Maybe<Scalars["Float"]["output"]>;
    purchasePriceHt: Maybe<Scalars["Float"]["output"]>;
    shippingDelay: Maybe<Scalars["Int"]["output"]>;
    sys: Sys;
    totalCostOfLeasing: Maybe<Scalars["Float"]["output"]>;
    totalCostOfOwnership: Maybe<Scalars["Float"]["output"]>;
    webflowId: Maybe<Scalars["String"]["output"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationcompanyCoefficientArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationcompatibilityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationconnectionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationconnectivityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationdescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationimageArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationlinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationnameArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type Dockstationprice24Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type Dockstationprice36Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type Dockstationprice48Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationpurchasePriceHtArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationshippingDelayArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationtotalCostOfLeasingArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationtotalCostOfOwnershipArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Dockstation accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/dockstation) */
export type DockstationwebflowIdArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type DockstationCollection = {
    __typename: "DockstationCollection";
    items: Array<Maybe<Dockstation>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type DockstationDescription = {
    __typename: "DockstationDescription";
    json: Scalars["JSON"]["output"];
    links: DockstationDescriptionLinks;
};

export type DockstationDescriptionAssets = {
    __typename: "DockstationDescriptionAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type DockstationDescriptionEntries = {
    __typename: "DockstationDescriptionEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type DockstationDescriptionLinks = {
    __typename: "DockstationDescriptionLinks";
    assets: DockstationDescriptionAssets;
    entries: DockstationDescriptionEntries;
    resources: DockstationDescriptionResources;
};

export type DockstationDescriptionResources = {
    __typename: "DockstationDescriptionResources";
    block: Array<DockstationDescriptionResourcesBlock>;
    hyperlink: Array<DockstationDescriptionResourcesHyperlink>;
    inline: Array<DockstationDescriptionResourcesInline>;
};

export type DockstationDescriptionResourcesBlock = ResourceLink & {
    __typename: "DockstationDescriptionResourcesBlock";
    sys: ResourceSys;
};

export type DockstationDescriptionResourcesHyperlink = ResourceLink & {
    __typename: "DockstationDescriptionResourcesHyperlink";
    sys: ResourceSys;
};

export type DockstationDescriptionResourcesInline = ResourceLink & {
    __typename: "DockstationDescriptionResourcesInline";
    sys: ResourceSys;
};

export type DockstationFilter = {
    AND?: InputMaybe<Array<InputMaybe<DockstationFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<DockstationFilter>>>;
    companyCoefficient_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    compatibility?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_contains?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    compatibility_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    compatibility_not?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connection?: InputMaybe<Scalars["String"]["input"]>;
    connection_contains?: InputMaybe<Scalars["String"]["input"]>;
    connection_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    connection_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    connection_not?: InputMaybe<Scalars["String"]["input"]>;
    connection_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    connection_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    description_contains?: InputMaybe<Scalars["String"]["input"]>;
    description_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    image_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    price24?: InputMaybe<Scalars["Float"]["input"]>;
    price24_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price24_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price24_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36?: InputMaybe<Scalars["Float"]["input"]>;
    price36_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price36_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48?: InputMaybe<Scalars["Float"]["input"]>;
    price48_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price48_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    purchasePriceHt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceHt_gt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_gte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceHt_lt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_lte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    shippingDelay?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shippingDelay_gt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_gte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    shippingDelay_lt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_lte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    sys?: InputMaybe<SysFilter>;
    totalCostOfLeasing?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfLeasing_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfLeasing_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfOwnership_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    webflowId?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId_not?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
};

export type DockstationLinkingCollections = {
    __typename: "DockstationLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
    productCollection: Maybe<ProductCollection>;
};

export type DockstationLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DockstationLinkingCollectionsproductCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<
        Array<InputMaybe<DockstationLinkingCollectionsProductCollectionOrder>>
    >;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum DockstationLinkingCollectionsProductCollectionOrder {
    appearanceOrder_ASC = "appearanceOrder_ASC",
    appearanceOrder_DESC = "appearanceOrder_DESC",
    battery_ASC = "battery_ASC",
    battery_DESC = "battery_DESC",
    bluetooth_ASC = "bluetooth_ASC",
    bluetooth_DESC = "bluetooth_DESC",
    brand_ASC = "brand_ASC",
    brand_DESC = "brand_DESC",
    colors_ASC = "colors_ASC",
    colors_DESC = "colors_DESC",
    fingerPrintReader_ASC = "fingerPrintReader_ASC",
    fingerPrintReader_DESC = "fingerPrintReader_DESC",
    foldable_ASC = "foldable_ASC",
    foldable_DESC = "foldable_DESC",
    height_ASC = "height_ASC",
    height_DESC = "height_DESC",
    isRefurbed_ASC = "isRefurbed_ASC",
    isRefurbed_DESC = "isRefurbed_DESC",
    keyboardBacklight_ASC = "keyboardBacklight_ASC",
    keyboardBacklight_DESC = "keyboardBacklight_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    osDescription_ASC = "osDescription_ASC",
    osDescription_DESC = "osDescription_DESC",
    os_ASC = "os_ASC",
    os_DESC = "os_DESC",
    repairability_ASC = "repairability_ASC",
    repairability_DESC = "repairability_DESC",
    screenSizeDescription_ASC = "screenSizeDescription_ASC",
    screenSizeDescription_DESC = "screenSizeDescription_DESC",
    screenSizeValue_ASC = "screenSizeValue_ASC",
    screenSizeValue_DESC = "screenSizeValue_DESC",
    shortDescription_ASC = "shortDescription_ASC",
    shortDescription_DESC = "shortDescription_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    tactile_ASC = "tactile_ASC",
    tactile_DESC = "tactile_DESC",
    thickness_ASC = "thickness_ASC",
    thickness_DESC = "thickness_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    type_ASC = "type_ASC",
    type_DESC = "type_DESC",
    webcam_ASC = "webcam_ASC",
    webcam_DESC = "webcam_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
    weight_ASC = "weight_ASC",
    weight_DESC = "weight_DESC",
    width_ASC = "width_ASC",
    width_DESC = "width_DESC",
    wifi_ASC = "wifi_ASC",
    wifi_DESC = "wifi_DESC",
}

export enum DockstationOrder {
    compatibility_ASC = "compatibility_ASC",
    compatibility_DESC = "compatibility_DESC",
    connection_ASC = "connection_ASC",
    connection_DESC = "connection_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    price24_ASC = "price24_ASC",
    price24_DESC = "price24_DESC",
    price36_ASC = "price36_ASC",
    price36_DESC = "price36_DESC",
    price48_ASC = "price48_ASC",
    price48_DESC = "price48_DESC",
    purchasePriceHt_ASC = "purchasePriceHt_ASC",
    purchasePriceHt_DESC = "purchasePriceHt_DESC",
    shippingDelay_ASC = "shippingDelay_ASC",
    shippingDelay_DESC = "shippingDelay_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
}

export type Entry = {
    contentfulMetadata: ContentfulMetadata;
    sys: Sys;
};

export type EntryCollection = {
    __typename: "EntryCollection";
    items: Array<Maybe<Entry>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type EntryFilter = {
    AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    sys?: InputMaybe<SysFilter>;
};

export enum EntryOrder {
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
}

/** Feature flags for environment, instance or user. [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/featureFlagConfiguration) */
export type FeatureFlagConfiguration = Entry & {
    __typename: "FeatureFlagConfiguration";
    contentfulMetadata: ContentfulMetadata;
    helpWidget: Maybe<Scalars["Boolean"]["output"]>;
    id: Maybe<Scalars["String"]["output"]>;
    integrationsPage: Maybe<Scalars["Boolean"]["output"]>;
    linkedFrom: Maybe<FeatureFlagConfigurationLinkingCollections>;
    nextCatalog: Maybe<Scalars["Boolean"]["output"]>;
    sys: Sys;
    tunnelSecurity: Maybe<Scalars["Boolean"]["output"]>;
};

/** Feature flags for environment, instance or user. [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/featureFlagConfiguration) */
export type FeatureFlagConfigurationhelpWidgetArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Feature flags for environment, instance or user. [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/featureFlagConfiguration) */
export type FeatureFlagConfigurationidArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Feature flags for environment, instance or user. [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/featureFlagConfiguration) */
export type FeatureFlagConfigurationintegrationsPageArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Feature flags for environment, instance or user. [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/featureFlagConfiguration) */
export type FeatureFlagConfigurationlinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Feature flags for environment, instance or user. [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/featureFlagConfiguration) */
export type FeatureFlagConfigurationnextCatalogArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Feature flags for environment, instance or user. [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/featureFlagConfiguration) */
export type FeatureFlagConfigurationtunnelSecurityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeatureFlagConfigurationCollection = {
    __typename: "FeatureFlagConfigurationCollection";
    items: Array<Maybe<FeatureFlagConfiguration>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type FeatureFlagConfigurationFilter = {
    AND?: InputMaybe<Array<InputMaybe<FeatureFlagConfigurationFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<FeatureFlagConfigurationFilter>>>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    helpWidget?: InputMaybe<Scalars["Boolean"]["input"]>;
    helpWidget_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    helpWidget_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    id_contains?: InputMaybe<Scalars["String"]["input"]>;
    id_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    id_not?: InputMaybe<Scalars["String"]["input"]>;
    id_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    integrationsPage?: InputMaybe<Scalars["Boolean"]["input"]>;
    integrationsPage_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    integrationsPage_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    nextCatalog?: InputMaybe<Scalars["Boolean"]["input"]>;
    nextCatalog_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    nextCatalog_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    sys?: InputMaybe<SysFilter>;
    tunnelSecurity?: InputMaybe<Scalars["Boolean"]["input"]>;
    tunnelSecurity_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    tunnelSecurity_not?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FeatureFlagConfigurationLinkingCollections = {
    __typename: "FeatureFlagConfigurationLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
};

export type FeatureFlagConfigurationLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum FeatureFlagConfigurationOrder {
    helpWidget_ASC = "helpWidget_ASC",
    helpWidget_DESC = "helpWidget_DESC",
    id_ASC = "id_ASC",
    id_DESC = "id_DESC",
    integrationsPage_ASC = "integrationsPage_ASC",
    integrationsPage_DESC = "integrationsPage_DESC",
    nextCatalog_ASC = "nextCatalog_ASC",
    nextCatalog_DESC = "nextCatalog_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    tunnelSecurity_ASC = "tunnelSecurity_ASC",
    tunnelSecurity_DESC = "tunnelSecurity_DESC",
}

export enum ImageFormat {
    AVIF = "AVIF",
    /** JPG image format. */
    JPG = "JPG",
    /**
     * Progressive JPG format stores multiple passes of an image in progressively higher detail.
     *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
     *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
     *         early as possible to make the layout look as designed.
     */
    JPG_PROGRESSIVE = "JPG_PROGRESSIVE",
    /** PNG image format */
    PNG = "PNG",
    /**
     * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
     *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
     */
    PNG8 = "PNG8",
    /** WebP image format. */
    WEBP = "WEBP",
}

export enum ImageResizeFocus {
    /** Focus the resizing on the bottom. */
    BOTTOM = "BOTTOM",
    /** Focus the resizing on the bottom left. */
    BOTTOM_LEFT = "BOTTOM_LEFT",
    /** Focus the resizing on the bottom right. */
    BOTTOM_RIGHT = "BOTTOM_RIGHT",
    /** Focus the resizing on the center. */
    CENTER = "CENTER",
    /** Focus the resizing on the largest face. */
    FACE = "FACE",
    /** Focus the resizing on the area containing all the faces. */
    FACES = "FACES",
    /** Focus the resizing on the left. */
    LEFT = "LEFT",
    /** Focus the resizing on the right. */
    RIGHT = "RIGHT",
    /** Focus the resizing on the top. */
    TOP = "TOP",
    /** Focus the resizing on the top left. */
    TOP_LEFT = "TOP_LEFT",
    /** Focus the resizing on the top right. */
    TOP_RIGHT = "TOP_RIGHT",
}

export enum ImageResizeStrategy {
    /** Crops a part of the original image to fit into the specified dimensions. */
    CROP = "CROP",
    /** Resizes the image to the specified dimensions, cropping the image if needed. */
    FILL = "FILL",
    /** Resizes the image to fit into the specified dimensions. */
    FIT = "FIT",
    /**
     * Resizes the image to the specified dimensions, padding the image if needed.
     *         Uses desired background color as padding color.
     */
    PAD = "PAD",
    /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
    SCALE = "SCALE",
    /** Creates a thumbnail from the image. */
    THUMB = "THUMB",
}

export type ImageTransformOptions = {
    /**
     * Desired background color, used with corner radius or `PAD` resize strategy.
     *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
     */
    backgroundColor?: InputMaybe<Scalars["HexColor"]["input"]>;
    /**
     * Desired corner radius in pixels.
     *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
     *         Defaults to `0`. Uses desired background color as padding color,
     *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
     */
    cornerRadius?: InputMaybe<Scalars["Int"]["input"]>;
    /** Desired image format. Defaults to the original image format. */
    format?: InputMaybe<ImageFormat>;
    /** Desired height in pixels. Defaults to the original image height. */
    height?: InputMaybe<Scalars["Dimension"]["input"]>;
    /**
     * Desired quality of the image in percents.
     *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
     */
    quality?: InputMaybe<Scalars["Quality"]["input"]>;
    /** Desired resize focus area. Defaults to `CENTER`. */
    resizeFocus?: InputMaybe<ImageResizeFocus>;
    /** Desired resize strategy. Defaults to `FIT`. */
    resizeStrategy?: InputMaybe<ImageResizeStrategy>;
    /** Desired width in pixels. Defaults to the original image width. */
    width?: InputMaybe<Scalars["Dimension"]["input"]>;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type Integration = Entry & {
    __typename: "Integration";
    about: Maybe<IntegrationAbout>;
    category: Maybe<Scalars["String"]["output"]>;
    configuration: Maybe<Scalars["JSON"]["output"]>;
    contentfulMetadata: ContentfulMetadata;
    displayName: Maybe<Scalars["String"]["output"]>;
    handledByRollee: Maybe<Scalars["Boolean"]["output"]>;
    linkedFrom: Maybe<IntegrationLinkingCollections>;
    links: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    logo: Maybe<Asset>;
    longDescription: Maybe<IntegrationLongDescription>;
    name: Maybe<Scalars["String"]["output"]>;
    shortDescription: Maybe<Scalars["String"]["output"]>;
    sys: Sys;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type IntegrationaboutArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type IntegrationcategoryArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type IntegrationconfigurationArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type IntegrationdisplayNameArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type IntegrationhandledByRolleeArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type IntegrationlinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type IntegrationlinksArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type IntegrationlogoArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type IntegrationlongDescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type IntegrationnameArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Third party apps that the Cleaq app can integrate with [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/integration) */
export type IntegrationshortDescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type IntegrationAbout = {
    __typename: "IntegrationAbout";
    json: Scalars["JSON"]["output"];
    links: IntegrationAboutLinks;
};

export type IntegrationAboutAssets = {
    __typename: "IntegrationAboutAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type IntegrationAboutEntries = {
    __typename: "IntegrationAboutEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type IntegrationAboutLinks = {
    __typename: "IntegrationAboutLinks";
    assets: IntegrationAboutAssets;
    entries: IntegrationAboutEntries;
    resources: IntegrationAboutResources;
};

export type IntegrationAboutResources = {
    __typename: "IntegrationAboutResources";
    block: Array<IntegrationAboutResourcesBlock>;
    hyperlink: Array<IntegrationAboutResourcesHyperlink>;
    inline: Array<IntegrationAboutResourcesInline>;
};

export type IntegrationAboutResourcesBlock = ResourceLink & {
    __typename: "IntegrationAboutResourcesBlock";
    sys: ResourceSys;
};

export type IntegrationAboutResourcesHyperlink = ResourceLink & {
    __typename: "IntegrationAboutResourcesHyperlink";
    sys: ResourceSys;
};

export type IntegrationAboutResourcesInline = ResourceLink & {
    __typename: "IntegrationAboutResourcesInline";
    sys: ResourceSys;
};

export type IntegrationCollection = {
    __typename: "IntegrationCollection";
    items: Array<Maybe<Integration>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type IntegrationFilter = {
    AND?: InputMaybe<Array<InputMaybe<IntegrationFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<IntegrationFilter>>>;
    about_contains?: InputMaybe<Scalars["String"]["input"]>;
    about_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    about_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    category?: InputMaybe<Scalars["String"]["input"]>;
    category_contains?: InputMaybe<Scalars["String"]["input"]>;
    category_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    category_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    category_not?: InputMaybe<Scalars["String"]["input"]>;
    category_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    category_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    configuration_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    displayName?: InputMaybe<Scalars["String"]["input"]>;
    displayName_contains?: InputMaybe<Scalars["String"]["input"]>;
    displayName_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    displayName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    displayName_not?: InputMaybe<Scalars["String"]["input"]>;
    displayName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    displayName_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    handledByRollee?: InputMaybe<Scalars["Boolean"]["input"]>;
    handledByRollee_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    handledByRollee_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    links_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    links_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    links_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    links_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    logo_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    longDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    longDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    longDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shortDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    shortDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    sys?: InputMaybe<SysFilter>;
};

export type IntegrationLinkingCollections = {
    __typename: "IntegrationLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
};

export type IntegrationLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IntegrationLongDescription = {
    __typename: "IntegrationLongDescription";
    json: Scalars["JSON"]["output"];
    links: IntegrationLongDescriptionLinks;
};

export type IntegrationLongDescriptionAssets = {
    __typename: "IntegrationLongDescriptionAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type IntegrationLongDescriptionEntries = {
    __typename: "IntegrationLongDescriptionEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type IntegrationLongDescriptionLinks = {
    __typename: "IntegrationLongDescriptionLinks";
    assets: IntegrationLongDescriptionAssets;
    entries: IntegrationLongDescriptionEntries;
    resources: IntegrationLongDescriptionResources;
};

export type IntegrationLongDescriptionResources = {
    __typename: "IntegrationLongDescriptionResources";
    block: Array<IntegrationLongDescriptionResourcesBlock>;
    hyperlink: Array<IntegrationLongDescriptionResourcesHyperlink>;
    inline: Array<IntegrationLongDescriptionResourcesInline>;
};

export type IntegrationLongDescriptionResourcesBlock = ResourceLink & {
    __typename: "IntegrationLongDescriptionResourcesBlock";
    sys: ResourceSys;
};

export type IntegrationLongDescriptionResourcesHyperlink = ResourceLink & {
    __typename: "IntegrationLongDescriptionResourcesHyperlink";
    sys: ResourceSys;
};

export type IntegrationLongDescriptionResourcesInline = ResourceLink & {
    __typename: "IntegrationLongDescriptionResourcesInline";
    sys: ResourceSys;
};

export enum IntegrationOrder {
    category_ASC = "category_ASC",
    category_DESC = "category_DESC",
    displayName_ASC = "displayName_ASC",
    displayName_DESC = "displayName_DESC",
    handledByRollee_ASC = "handledByRollee_ASC",
    handledByRollee_DESC = "handledByRollee_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    shortDescription_ASC = "shortDescription_ASC",
    shortDescription_DESC = "shortDescription_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
}

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type Keyboard = Entry & {
    __typename: "Keyboard";
    brand: Maybe<Scalars["String"]["output"]>;
    companyCoefficient: Maybe<Scalars["JSON"]["output"]>;
    compatibility: Maybe<Scalars["String"]["output"]>;
    connectivity: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<KeyboardDescription>;
    image: Maybe<Asset>;
    linkedFrom: Maybe<KeyboardLinkingCollections>;
    name: Maybe<Scalars["String"]["output"]>;
    numPad: Maybe<Scalars["Boolean"]["output"]>;
    price24: Maybe<Scalars["Float"]["output"]>;
    price36: Maybe<Scalars["Float"]["output"]>;
    price48: Maybe<Scalars["Float"]["output"]>;
    purchasePriceHt: Maybe<Scalars["Float"]["output"]>;
    shippingDelay: Maybe<Scalars["Int"]["output"]>;
    sys: Sys;
    totalCostOfLeasing: Maybe<Scalars["Float"]["output"]>;
    totalCostOfOwnership: Maybe<Scalars["Float"]["output"]>;
    webflowId: Maybe<Scalars["String"]["output"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardbrandArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardcompanyCoefficientArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardcompatibilityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardconnectivityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboarddescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardimageArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardlinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardnameArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardnumPadArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type Keyboardprice24Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type Keyboardprice36Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type Keyboardprice48Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardpurchasePriceHtArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardshippingDelayArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardtotalCostOfLeasingArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardtotalCostOfOwnershipArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Keyboard accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/keyboard) */
export type KeyboardwebflowIdArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type KeyboardCollection = {
    __typename: "KeyboardCollection";
    items: Array<Maybe<Keyboard>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type KeyboardDescription = {
    __typename: "KeyboardDescription";
    json: Scalars["JSON"]["output"];
    links: KeyboardDescriptionLinks;
};

export type KeyboardDescriptionAssets = {
    __typename: "KeyboardDescriptionAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type KeyboardDescriptionEntries = {
    __typename: "KeyboardDescriptionEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type KeyboardDescriptionLinks = {
    __typename: "KeyboardDescriptionLinks";
    assets: KeyboardDescriptionAssets;
    entries: KeyboardDescriptionEntries;
    resources: KeyboardDescriptionResources;
};

export type KeyboardDescriptionResources = {
    __typename: "KeyboardDescriptionResources";
    block: Array<KeyboardDescriptionResourcesBlock>;
    hyperlink: Array<KeyboardDescriptionResourcesHyperlink>;
    inline: Array<KeyboardDescriptionResourcesInline>;
};

export type KeyboardDescriptionResourcesBlock = ResourceLink & {
    __typename: "KeyboardDescriptionResourcesBlock";
    sys: ResourceSys;
};

export type KeyboardDescriptionResourcesHyperlink = ResourceLink & {
    __typename: "KeyboardDescriptionResourcesHyperlink";
    sys: ResourceSys;
};

export type KeyboardDescriptionResourcesInline = ResourceLink & {
    __typename: "KeyboardDescriptionResourcesInline";
    sys: ResourceSys;
};

export type KeyboardFilter = {
    AND?: InputMaybe<Array<InputMaybe<KeyboardFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<KeyboardFilter>>>;
    brand?: InputMaybe<Scalars["String"]["input"]>;
    brand_contains?: InputMaybe<Scalars["String"]["input"]>;
    brand_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    brand_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    brand_not?: InputMaybe<Scalars["String"]["input"]>;
    brand_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    brand_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    companyCoefficient_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    compatibility?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_contains?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    compatibility_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    compatibility_not?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    description_contains?: InputMaybe<Scalars["String"]["input"]>;
    description_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    image_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    numPad?: InputMaybe<Scalars["Boolean"]["input"]>;
    numPad_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    numPad_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    price24?: InputMaybe<Scalars["Float"]["input"]>;
    price24_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price24_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price24_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36?: InputMaybe<Scalars["Float"]["input"]>;
    price36_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price36_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48?: InputMaybe<Scalars["Float"]["input"]>;
    price48_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price48_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    purchasePriceHt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceHt_gt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_gte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceHt_lt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_lte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    shippingDelay?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shippingDelay_gt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_gte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    shippingDelay_lt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_lte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    sys?: InputMaybe<SysFilter>;
    totalCostOfLeasing?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfLeasing_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfLeasing_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfOwnership_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    webflowId?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId_not?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
};

export type KeyboardLinkingCollections = {
    __typename: "KeyboardLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
    productCollection: Maybe<ProductCollection>;
};

export type KeyboardLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type KeyboardLinkingCollectionsproductCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<
        Array<InputMaybe<KeyboardLinkingCollectionsProductCollectionOrder>>
    >;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum KeyboardLinkingCollectionsProductCollectionOrder {
    appearanceOrder_ASC = "appearanceOrder_ASC",
    appearanceOrder_DESC = "appearanceOrder_DESC",
    battery_ASC = "battery_ASC",
    battery_DESC = "battery_DESC",
    bluetooth_ASC = "bluetooth_ASC",
    bluetooth_DESC = "bluetooth_DESC",
    brand_ASC = "brand_ASC",
    brand_DESC = "brand_DESC",
    colors_ASC = "colors_ASC",
    colors_DESC = "colors_DESC",
    fingerPrintReader_ASC = "fingerPrintReader_ASC",
    fingerPrintReader_DESC = "fingerPrintReader_DESC",
    foldable_ASC = "foldable_ASC",
    foldable_DESC = "foldable_DESC",
    height_ASC = "height_ASC",
    height_DESC = "height_DESC",
    isRefurbed_ASC = "isRefurbed_ASC",
    isRefurbed_DESC = "isRefurbed_DESC",
    keyboardBacklight_ASC = "keyboardBacklight_ASC",
    keyboardBacklight_DESC = "keyboardBacklight_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    osDescription_ASC = "osDescription_ASC",
    osDescription_DESC = "osDescription_DESC",
    os_ASC = "os_ASC",
    os_DESC = "os_DESC",
    repairability_ASC = "repairability_ASC",
    repairability_DESC = "repairability_DESC",
    screenSizeDescription_ASC = "screenSizeDescription_ASC",
    screenSizeDescription_DESC = "screenSizeDescription_DESC",
    screenSizeValue_ASC = "screenSizeValue_ASC",
    screenSizeValue_DESC = "screenSizeValue_DESC",
    shortDescription_ASC = "shortDescription_ASC",
    shortDescription_DESC = "shortDescription_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    tactile_ASC = "tactile_ASC",
    tactile_DESC = "tactile_DESC",
    thickness_ASC = "thickness_ASC",
    thickness_DESC = "thickness_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    type_ASC = "type_ASC",
    type_DESC = "type_DESC",
    webcam_ASC = "webcam_ASC",
    webcam_DESC = "webcam_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
    weight_ASC = "weight_ASC",
    weight_DESC = "weight_DESC",
    width_ASC = "width_ASC",
    width_DESC = "width_DESC",
    wifi_ASC = "wifi_ASC",
    wifi_DESC = "wifi_DESC",
}

export enum KeyboardOrder {
    brand_ASC = "brand_ASC",
    brand_DESC = "brand_DESC",
    compatibility_ASC = "compatibility_ASC",
    compatibility_DESC = "compatibility_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    numPad_ASC = "numPad_ASC",
    numPad_DESC = "numPad_DESC",
    price24_ASC = "price24_ASC",
    price24_DESC = "price24_DESC",
    price36_ASC = "price36_ASC",
    price36_DESC = "price36_DESC",
    price48_ASC = "price48_ASC",
    price48_DESC = "price48_DESC",
    purchasePriceHt_ASC = "purchasePriceHt_ASC",
    purchasePriceHt_DESC = "purchasePriceHt_DESC",
    shippingDelay_ASC = "shippingDelay_ASC",
    shippingDelay_DESC = "shippingDelay_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
}

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type Mouse = Entry & {
    __typename: "Mouse";
    brand: Maybe<Scalars["String"]["output"]>;
    companyCoefficient: Maybe<Scalars["JSON"]["output"]>;
    compatibility: Maybe<Scalars["String"]["output"]>;
    connectivity: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<MouseDescription>;
    image: Maybe<Asset>;
    linkedFrom: Maybe<MouseLinkingCollections>;
    name: Maybe<Scalars["String"]["output"]>;
    power: Maybe<Scalars["String"]["output"]>;
    price24: Maybe<Scalars["Float"]["output"]>;
    price36: Maybe<Scalars["Float"]["output"]>;
    price48: Maybe<Scalars["Float"]["output"]>;
    purchasePriceHt: Maybe<Scalars["Float"]["output"]>;
    shippingDelay: Maybe<Scalars["Int"]["output"]>;
    sys: Sys;
    totalCostOfLeasing: Maybe<Scalars["Float"]["output"]>;
    totalCostOfOwnership: Maybe<Scalars["Float"]["output"]>;
    type: Maybe<Scalars["String"]["output"]>;
    webflowId: Maybe<Scalars["String"]["output"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MousebrandArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MousecompanyCoefficientArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MousecompatibilityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MouseconnectivityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MousedescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MouseimageArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MouselinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MousenameArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MousepowerArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type Mouseprice24Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type Mouseprice36Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type Mouseprice48Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MousepurchasePriceHtArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MouseshippingDelayArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MousetotalCostOfLeasingArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MousetotalCostOfOwnershipArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MousetypeArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Mouse accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/mouse) */
export type MousewebflowIdArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type MouseCollection = {
    __typename: "MouseCollection";
    items: Array<Maybe<Mouse>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type MouseDescription = {
    __typename: "MouseDescription";
    json: Scalars["JSON"]["output"];
    links: MouseDescriptionLinks;
};

export type MouseDescriptionAssets = {
    __typename: "MouseDescriptionAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type MouseDescriptionEntries = {
    __typename: "MouseDescriptionEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type MouseDescriptionLinks = {
    __typename: "MouseDescriptionLinks";
    assets: MouseDescriptionAssets;
    entries: MouseDescriptionEntries;
    resources: MouseDescriptionResources;
};

export type MouseDescriptionResources = {
    __typename: "MouseDescriptionResources";
    block: Array<MouseDescriptionResourcesBlock>;
    hyperlink: Array<MouseDescriptionResourcesHyperlink>;
    inline: Array<MouseDescriptionResourcesInline>;
};

export type MouseDescriptionResourcesBlock = ResourceLink & {
    __typename: "MouseDescriptionResourcesBlock";
    sys: ResourceSys;
};

export type MouseDescriptionResourcesHyperlink = ResourceLink & {
    __typename: "MouseDescriptionResourcesHyperlink";
    sys: ResourceSys;
};

export type MouseDescriptionResourcesInline = ResourceLink & {
    __typename: "MouseDescriptionResourcesInline";
    sys: ResourceSys;
};

export type MouseFilter = {
    AND?: InputMaybe<Array<InputMaybe<MouseFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<MouseFilter>>>;
    brand?: InputMaybe<Scalars["String"]["input"]>;
    brand_contains?: InputMaybe<Scalars["String"]["input"]>;
    brand_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    brand_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    brand_not?: InputMaybe<Scalars["String"]["input"]>;
    brand_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    brand_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    companyCoefficient_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    compatibility?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_contains?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    compatibility_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    compatibility_not?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    compatibility_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    description_contains?: InputMaybe<Scalars["String"]["input"]>;
    description_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    image_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    power?: InputMaybe<Scalars["String"]["input"]>;
    power_contains?: InputMaybe<Scalars["String"]["input"]>;
    power_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    power_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    power_not?: InputMaybe<Scalars["String"]["input"]>;
    power_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    power_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    price24?: InputMaybe<Scalars["Float"]["input"]>;
    price24_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price24_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price24_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36?: InputMaybe<Scalars["Float"]["input"]>;
    price36_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price36_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48?: InputMaybe<Scalars["Float"]["input"]>;
    price48_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price48_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    purchasePriceHt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceHt_gt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_gte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceHt_lt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_lte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    shippingDelay?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shippingDelay_gt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_gte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    shippingDelay_lt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_lte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    sys?: InputMaybe<SysFilter>;
    totalCostOfLeasing?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfLeasing_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfLeasing_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfOwnership_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    type?: InputMaybe<Scalars["String"]["input"]>;
    type_contains?: InputMaybe<Scalars["String"]["input"]>;
    type_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    type_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    type_not?: InputMaybe<Scalars["String"]["input"]>;
    type_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId_not?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
};

export type MouseLinkingCollections = {
    __typename: "MouseLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
    productCollection: Maybe<ProductCollection>;
};

export type MouseLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MouseLinkingCollectionsproductCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<
        Array<InputMaybe<MouseLinkingCollectionsProductCollectionOrder>>
    >;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum MouseLinkingCollectionsProductCollectionOrder {
    appearanceOrder_ASC = "appearanceOrder_ASC",
    appearanceOrder_DESC = "appearanceOrder_DESC",
    battery_ASC = "battery_ASC",
    battery_DESC = "battery_DESC",
    bluetooth_ASC = "bluetooth_ASC",
    bluetooth_DESC = "bluetooth_DESC",
    brand_ASC = "brand_ASC",
    brand_DESC = "brand_DESC",
    colors_ASC = "colors_ASC",
    colors_DESC = "colors_DESC",
    fingerPrintReader_ASC = "fingerPrintReader_ASC",
    fingerPrintReader_DESC = "fingerPrintReader_DESC",
    foldable_ASC = "foldable_ASC",
    foldable_DESC = "foldable_DESC",
    height_ASC = "height_ASC",
    height_DESC = "height_DESC",
    isRefurbed_ASC = "isRefurbed_ASC",
    isRefurbed_DESC = "isRefurbed_DESC",
    keyboardBacklight_ASC = "keyboardBacklight_ASC",
    keyboardBacklight_DESC = "keyboardBacklight_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    osDescription_ASC = "osDescription_ASC",
    osDescription_DESC = "osDescription_DESC",
    os_ASC = "os_ASC",
    os_DESC = "os_DESC",
    repairability_ASC = "repairability_ASC",
    repairability_DESC = "repairability_DESC",
    screenSizeDescription_ASC = "screenSizeDescription_ASC",
    screenSizeDescription_DESC = "screenSizeDescription_DESC",
    screenSizeValue_ASC = "screenSizeValue_ASC",
    screenSizeValue_DESC = "screenSizeValue_DESC",
    shortDescription_ASC = "shortDescription_ASC",
    shortDescription_DESC = "shortDescription_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    tactile_ASC = "tactile_ASC",
    tactile_DESC = "tactile_DESC",
    thickness_ASC = "thickness_ASC",
    thickness_DESC = "thickness_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    type_ASC = "type_ASC",
    type_DESC = "type_DESC",
    webcam_ASC = "webcam_ASC",
    webcam_DESC = "webcam_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
    weight_ASC = "weight_ASC",
    weight_DESC = "weight_DESC",
    width_ASC = "width_ASC",
    width_DESC = "width_DESC",
    wifi_ASC = "wifi_ASC",
    wifi_DESC = "wifi_DESC",
}

export enum MouseOrder {
    brand_ASC = "brand_ASC",
    brand_DESC = "brand_DESC",
    compatibility_ASC = "compatibility_ASC",
    compatibility_DESC = "compatibility_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    power_ASC = "power_ASC",
    power_DESC = "power_DESC",
    price24_ASC = "price24_ASC",
    price24_DESC = "price24_DESC",
    price36_ASC = "price36_ASC",
    price36_DESC = "price36_DESC",
    price48_ASC = "price48_ASC",
    price48_DESC = "price48_DESC",
    purchasePriceHt_ASC = "purchasePriceHt_ASC",
    purchasePriceHt_DESC = "purchasePriceHt_DESC",
    shippingDelay_ASC = "shippingDelay_ASC",
    shippingDelay_DESC = "shippingDelay_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    type_ASC = "type_ASC",
    type_DESC = "type_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
}

/** Page details used by the App [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/pageContent) */
export type PageContent = Entry & {
    __typename: "PageContent";
    contentfulMetadata: ContentfulMetadata;
    helpUrls: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    helpUrlsJson: Maybe<Scalars["JSON"]["output"]>;
    linkedFrom: Maybe<PageContentLinkingCollections>;
    name: Maybe<Scalars["String"]["output"]>;
    slug: Maybe<Scalars["String"]["output"]>;
    sys: Sys;
};

/** Page details used by the App [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/pageContent) */
export type PageContenthelpUrlsArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Page details used by the App [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/pageContent) */
export type PageContenthelpUrlsJsonArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Page details used by the App [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/pageContent) */
export type PageContentlinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Page details used by the App [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/pageContent) */
export type PageContentnameArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Page details used by the App [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/pageContent) */
export type PageContentslugArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type PageContentCollection = {
    __typename: "PageContentCollection";
    items: Array<Maybe<PageContent>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type PageContentFilter = {
    AND?: InputMaybe<Array<InputMaybe<PageContentFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<PageContentFilter>>>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    helpUrlsJson_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    helpUrls_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    helpUrls_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    helpUrls_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    helpUrls_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    slug?: InputMaybe<Scalars["String"]["input"]>;
    slug_contains?: InputMaybe<Scalars["String"]["input"]>;
    slug_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    slug_not?: InputMaybe<Scalars["String"]["input"]>;
    slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    sys?: InputMaybe<SysFilter>;
};

export type PageContentLinkingCollections = {
    __typename: "PageContentLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
};

export type PageContentLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum PageContentOrder {
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    slug_ASC = "slug_ASC",
    slug_DESC = "slug_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
}

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type Product = Entry & {
    __typename: "Product";
    appearanceOrder: Maybe<Scalars["Int"]["output"]>;
    battery: Maybe<Scalars["String"]["output"]>;
    bluetooth: Maybe<Scalars["String"]["output"]>;
    brand: Maybe<Scalars["String"]["output"]>;
    catalog: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    colors: Maybe<Scalars["String"]["output"]>;
    contentfulMetadata: ContentfulMetadata;
    fingerPrintReader: Maybe<Scalars["Boolean"]["output"]>;
    foldable: Maybe<Scalars["Boolean"]["output"]>;
    height: Maybe<Scalars["Float"]["output"]>;
    image: Maybe<Asset>;
    isRefurbed: Maybe<Scalars["Boolean"]["output"]>;
    keyboardBacklight: Maybe<Scalars["Boolean"]["output"]>;
    linkedFrom: Maybe<ProductLinkingCollections>;
    longDescription: Maybe<ProductLongDescription>;
    moreDetails: Maybe<ProductMoreDetails>;
    name: Maybe<Scalars["String"]["output"]>;
    os: Maybe<Scalars["String"]["output"]>;
    osDescription: Maybe<Scalars["String"]["output"]>;
    repairability: Maybe<Scalars["Float"]["output"]>;
    repairabilityIndex: Maybe<Asset>;
    screenSizeDescription: Maybe<Scalars["String"]["output"]>;
    screenSizeValue: Maybe<Scalars["Float"]["output"]>;
    shortDescription: Maybe<Scalars["String"]["output"]>;
    suggestedAccessoriesCollection: Maybe<ProductSuggestedAccessoriesCollection>;
    sys: Sys;
    tactile: Maybe<Scalars["Boolean"]["output"]>;
    thickness: Maybe<Scalars["Float"]["output"]>;
    totalCostOfLeasing: Maybe<Scalars["Float"]["output"]>;
    totalCostOfOwnership: Maybe<Scalars["Float"]["output"]>;
    type: Maybe<Scalars["String"]["output"]>;
    variantsCollection: Maybe<ProductVariantsCollection>;
    webcam: Maybe<Scalars["Boolean"]["output"]>;
    webflowId: Maybe<Scalars["String"]["output"]>;
    weight: Maybe<Scalars["Float"]["output"]>;
    width: Maybe<Scalars["Float"]["output"]>;
    wifi: Maybe<Scalars["String"]["output"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductappearanceOrderArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductbatteryArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductbluetoothArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductbrandArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductcatalogArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductcolorsArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductfingerPrintReaderArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductfoldableArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductheightArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductimageArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductisRefurbedArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductkeyboardBacklightArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductlinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductlongDescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductmoreDetailsArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductnameArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductosArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductosDescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductrepairabilityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductrepairabilityIndexArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductscreenSizeDescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductscreenSizeValueArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductshortDescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductsuggestedAccessoriesCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<ProductSuggestedAccessoriesFilter>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProducttactileArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductthicknessArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProducttotalCostOfLeasingArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProducttotalCostOfOwnershipArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProducttypeArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductvariantsCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<ProductVariantsCollectionOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<VariantFilter>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductwebcamArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductwebflowIdArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductweightArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductwidthArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Devices that are rented by Triliz [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/product) */
export type ProductwifiArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductCollection = {
    __typename: "ProductCollection";
    items: Array<Maybe<Product>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type ProductFilter = {
    AND?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
    appearanceOrder?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    appearanceOrder_gt?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_gte?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    appearanceOrder_lt?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_lte?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_not?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    battery?: InputMaybe<Scalars["String"]["input"]>;
    battery_contains?: InputMaybe<Scalars["String"]["input"]>;
    battery_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    battery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    battery_not?: InputMaybe<Scalars["String"]["input"]>;
    battery_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    battery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    bluetooth?: InputMaybe<Scalars["String"]["input"]>;
    bluetooth_contains?: InputMaybe<Scalars["String"]["input"]>;
    bluetooth_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    bluetooth_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    bluetooth_not?: InputMaybe<Scalars["String"]["input"]>;
    bluetooth_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    bluetooth_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    brand?: InputMaybe<Scalars["String"]["input"]>;
    brand_contains?: InputMaybe<Scalars["String"]["input"]>;
    brand_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    brand_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    brand_not?: InputMaybe<Scalars["String"]["input"]>;
    brand_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    brand_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    catalog_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    catalog_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    catalog_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    catalog_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    colors?: InputMaybe<Scalars["String"]["input"]>;
    colors_contains?: InputMaybe<Scalars["String"]["input"]>;
    colors_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    colors_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    colors_not?: InputMaybe<Scalars["String"]["input"]>;
    colors_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    colors_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    fingerPrintReader?: InputMaybe<Scalars["Boolean"]["input"]>;
    fingerPrintReader_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    fingerPrintReader_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    foldable?: InputMaybe<Scalars["Boolean"]["input"]>;
    foldable_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    foldable_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    height?: InputMaybe<Scalars["Float"]["input"]>;
    height_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    height_gt?: InputMaybe<Scalars["Float"]["input"]>;
    height_gte?: InputMaybe<Scalars["Float"]["input"]>;
    height_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    height_lt?: InputMaybe<Scalars["Float"]["input"]>;
    height_lte?: InputMaybe<Scalars["Float"]["input"]>;
    height_not?: InputMaybe<Scalars["Float"]["input"]>;
    height_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    image_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    isRefurbed?: InputMaybe<Scalars["Boolean"]["input"]>;
    isRefurbed_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    isRefurbed_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    keyboardBacklight?: InputMaybe<Scalars["Boolean"]["input"]>;
    keyboardBacklight_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    keyboardBacklight_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    longDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    longDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    longDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    moreDetails_contains?: InputMaybe<Scalars["String"]["input"]>;
    moreDetails_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    moreDetails_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    os?: InputMaybe<Scalars["String"]["input"]>;
    osDescription?: InputMaybe<Scalars["String"]["input"]>;
    osDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    osDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    osDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    osDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    osDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    osDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    os_contains?: InputMaybe<Scalars["String"]["input"]>;
    os_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    os_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    os_not?: InputMaybe<Scalars["String"]["input"]>;
    os_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    os_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    repairability?: InputMaybe<Scalars["Float"]["input"]>;
    repairabilityIndex_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    repairability_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    repairability_gt?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_gte?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    repairability_lt?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_lte?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_not?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    screenSizeDescription?: InputMaybe<Scalars["String"]["input"]>;
    screenSizeDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    screenSizeDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    screenSizeDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    screenSizeDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    screenSizeDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    screenSizeDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    screenSizeValue?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    screenSizeValue_gt?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_gte?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    screenSizeValue_lt?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_lte?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_not?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shortDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    shortDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    suggestedAccessories?: InputMaybe<cfsuggestedAccessoriesMultiTypeNestedFilter>;
    suggestedAccessoriesCollection_exists?: InputMaybe<
        Scalars["Boolean"]["input"]
    >;
    sys?: InputMaybe<SysFilter>;
    tactile?: InputMaybe<Scalars["Boolean"]["input"]>;
    tactile_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    tactile_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    thickness?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    thickness_gt?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_gte?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    thickness_lt?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_lte?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_not?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    totalCostOfLeasing?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfLeasing_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfLeasing_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfOwnership_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    type?: InputMaybe<Scalars["String"]["input"]>;
    type_contains?: InputMaybe<Scalars["String"]["input"]>;
    type_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    type_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    type_not?: InputMaybe<Scalars["String"]["input"]>;
    type_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    variants?: InputMaybe<cfVariantNestedFilter>;
    variantsCollection_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webcam?: InputMaybe<Scalars["Boolean"]["input"]>;
    webcam_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webcam_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId_not?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    weight?: InputMaybe<Scalars["Float"]["input"]>;
    weight_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    weight_gt?: InputMaybe<Scalars["Float"]["input"]>;
    weight_gte?: InputMaybe<Scalars["Float"]["input"]>;
    weight_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    weight_lt?: InputMaybe<Scalars["Float"]["input"]>;
    weight_lte?: InputMaybe<Scalars["Float"]["input"]>;
    weight_not?: InputMaybe<Scalars["Float"]["input"]>;
    weight_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    width?: InputMaybe<Scalars["Float"]["input"]>;
    width_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    width_gt?: InputMaybe<Scalars["Float"]["input"]>;
    width_gte?: InputMaybe<Scalars["Float"]["input"]>;
    width_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    width_lt?: InputMaybe<Scalars["Float"]["input"]>;
    width_lte?: InputMaybe<Scalars["Float"]["input"]>;
    width_not?: InputMaybe<Scalars["Float"]["input"]>;
    width_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    wifi?: InputMaybe<Scalars["String"]["input"]>;
    wifi_contains?: InputMaybe<Scalars["String"]["input"]>;
    wifi_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    wifi_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    wifi_not?: InputMaybe<Scalars["String"]["input"]>;
    wifi_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    wifi_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ProductLinkingCollections = {
    __typename: "ProductLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
    variantCollection: Maybe<VariantCollection>;
};

export type ProductLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProductLinkingCollectionsvariantCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<
        Array<InputMaybe<ProductLinkingCollectionsVariantCollectionOrder>>
    >;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum ProductLinkingCollectionsVariantCollectionOrder {
    generation_ASC = "generation_ASC",
    generation_DESC = "generation_DESC",
    graphicsCardDescription_ASC = "graphicsCardDescription_ASC",
    graphicsCardDescription_DESC = "graphicsCardDescription_DESC",
    graphicsCardValue_ASC = "graphicsCardValue_ASC",
    graphicsCardValue_DESC = "graphicsCardValue_DESC",
    manufacturing_ASC = "manufacturing_ASC",
    manufacturing_DESC = "manufacturing_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    price12_ASC = "price12_ASC",
    price12_DESC = "price12_DESC",
    price24_ASC = "price24_ASC",
    price24_DESC = "price24_DESC",
    price36_ASC = "price36_ASC",
    price36_DESC = "price36_DESC",
    price48_ASC = "price48_ASC",
    price48_DESC = "price48_DESC",
    processorDescription_ASC = "processorDescription_ASC",
    processorDescription_DESC = "processorDescription_DESC",
    processorValue_ASC = "processorValue_ASC",
    processorValue_DESC = "processorValue_DESC",
    purchasePriceHt_ASC = "purchasePriceHt_ASC",
    purchasePriceHt_DESC = "purchasePriceHt_DESC",
    purchasePriceTtc_ASC = "purchasePriceTtc_ASC",
    purchasePriceTtc_DESC = "purchasePriceTtc_DESC",
    quantity_ASC = "quantity_ASC",
    quantity_DESC = "quantity_DESC",
    ramDescription_ASC = "ramDescription_ASC",
    ramDescription_DESC = "ramDescription_DESC",
    ramValue_ASC = "ramValue_ASC",
    ramValue_DESC = "ramValue_DESC",
    refurb_ASC = "refurb_ASC",
    refurb_DESC = "refurb_DESC",
    repairability_ASC = "repairability_ASC",
    repairability_DESC = "repairability_DESC",
    repairs_ASC = "repairs_ASC",
    repairs_DESC = "repairs_DESC",
    shippingDelay_ASC = "shippingDelay_ASC",
    shippingDelay_DESC = "shippingDelay_DESC",
    storageDescription_ASC = "storageDescription_ASC",
    storageDescription_DESC = "storageDescription_DESC",
    storageValue_ASC = "storageValue_ASC",
    storageValue_DESC = "storageValue_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    transport_ASC = "transport_ASC",
    transport_DESC = "transport_DESC",
    usageScore_ASC = "usageScore_ASC",
    usageScore_DESC = "usageScore_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
}

export type ProductLongDescription = {
    __typename: "ProductLongDescription";
    json: Scalars["JSON"]["output"];
    links: ProductLongDescriptionLinks;
};

export type ProductLongDescriptionAssets = {
    __typename: "ProductLongDescriptionAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type ProductLongDescriptionEntries = {
    __typename: "ProductLongDescriptionEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type ProductLongDescriptionLinks = {
    __typename: "ProductLongDescriptionLinks";
    assets: ProductLongDescriptionAssets;
    entries: ProductLongDescriptionEntries;
    resources: ProductLongDescriptionResources;
};

export type ProductLongDescriptionResources = {
    __typename: "ProductLongDescriptionResources";
    block: Array<ProductLongDescriptionResourcesBlock>;
    hyperlink: Array<ProductLongDescriptionResourcesHyperlink>;
    inline: Array<ProductLongDescriptionResourcesInline>;
};

export type ProductLongDescriptionResourcesBlock = ResourceLink & {
    __typename: "ProductLongDescriptionResourcesBlock";
    sys: ResourceSys;
};

export type ProductLongDescriptionResourcesHyperlink = ResourceLink & {
    __typename: "ProductLongDescriptionResourcesHyperlink";
    sys: ResourceSys;
};

export type ProductLongDescriptionResourcesInline = ResourceLink & {
    __typename: "ProductLongDescriptionResourcesInline";
    sys: ResourceSys;
};

export type ProductMoreDetails = {
    __typename: "ProductMoreDetails";
    json: Scalars["JSON"]["output"];
    links: ProductMoreDetailsLinks;
};

export type ProductMoreDetailsAssets = {
    __typename: "ProductMoreDetailsAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type ProductMoreDetailsEntries = {
    __typename: "ProductMoreDetailsEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type ProductMoreDetailsLinks = {
    __typename: "ProductMoreDetailsLinks";
    assets: ProductMoreDetailsAssets;
    entries: ProductMoreDetailsEntries;
    resources: ProductMoreDetailsResources;
};

export type ProductMoreDetailsResources = {
    __typename: "ProductMoreDetailsResources";
    block: Array<ProductMoreDetailsResourcesBlock>;
    hyperlink: Array<ProductMoreDetailsResourcesHyperlink>;
    inline: Array<ProductMoreDetailsResourcesInline>;
};

export type ProductMoreDetailsResourcesBlock = ResourceLink & {
    __typename: "ProductMoreDetailsResourcesBlock";
    sys: ResourceSys;
};

export type ProductMoreDetailsResourcesHyperlink = ResourceLink & {
    __typename: "ProductMoreDetailsResourcesHyperlink";
    sys: ResourceSys;
};

export type ProductMoreDetailsResourcesInline = ResourceLink & {
    __typename: "ProductMoreDetailsResourcesInline";
    sys: ResourceSys;
};

export enum ProductOrder {
    appearanceOrder_ASC = "appearanceOrder_ASC",
    appearanceOrder_DESC = "appearanceOrder_DESC",
    battery_ASC = "battery_ASC",
    battery_DESC = "battery_DESC",
    bluetooth_ASC = "bluetooth_ASC",
    bluetooth_DESC = "bluetooth_DESC",
    brand_ASC = "brand_ASC",
    brand_DESC = "brand_DESC",
    colors_ASC = "colors_ASC",
    colors_DESC = "colors_DESC",
    fingerPrintReader_ASC = "fingerPrintReader_ASC",
    fingerPrintReader_DESC = "fingerPrintReader_DESC",
    foldable_ASC = "foldable_ASC",
    foldable_DESC = "foldable_DESC",
    height_ASC = "height_ASC",
    height_DESC = "height_DESC",
    isRefurbed_ASC = "isRefurbed_ASC",
    isRefurbed_DESC = "isRefurbed_DESC",
    keyboardBacklight_ASC = "keyboardBacklight_ASC",
    keyboardBacklight_DESC = "keyboardBacklight_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    osDescription_ASC = "osDescription_ASC",
    osDescription_DESC = "osDescription_DESC",
    os_ASC = "os_ASC",
    os_DESC = "os_DESC",
    repairability_ASC = "repairability_ASC",
    repairability_DESC = "repairability_DESC",
    screenSizeDescription_ASC = "screenSizeDescription_ASC",
    screenSizeDescription_DESC = "screenSizeDescription_DESC",
    screenSizeValue_ASC = "screenSizeValue_ASC",
    screenSizeValue_DESC = "screenSizeValue_DESC",
    shortDescription_ASC = "shortDescription_ASC",
    shortDescription_DESC = "shortDescription_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    tactile_ASC = "tactile_ASC",
    tactile_DESC = "tactile_DESC",
    thickness_ASC = "thickness_ASC",
    thickness_DESC = "thickness_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    type_ASC = "type_ASC",
    type_DESC = "type_DESC",
    webcam_ASC = "webcam_ASC",
    webcam_DESC = "webcam_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
    weight_ASC = "weight_ASC",
    weight_DESC = "weight_DESC",
    width_ASC = "width_ASC",
    width_DESC = "width_DESC",
    wifi_ASC = "wifi_ASC",
    wifi_DESC = "wifi_DESC",
}

export type ProductSuggestedAccessoriesCollection = {
    __typename: "ProductSuggestedAccessoriesCollection";
    items: Array<Maybe<ProductSuggestedAccessoriesItem>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type ProductSuggestedAccessoriesFilter = {
    AND?: InputMaybe<Array<InputMaybe<ProductSuggestedAccessoriesFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<ProductSuggestedAccessoriesFilter>>>;
    companyCoefficient_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    connectivity_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    description_contains?: InputMaybe<Scalars["String"]["input"]>;
    description_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    image_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    price24?: InputMaybe<Scalars["Float"]["input"]>;
    price24_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price24_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price24_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36?: InputMaybe<Scalars["Float"]["input"]>;
    price36_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price36_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48?: InputMaybe<Scalars["Float"]["input"]>;
    price48_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price48_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    purchasePriceHt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceHt_gt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_gte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceHt_lt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_lte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    shippingDelay?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shippingDelay_gt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_gte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    shippingDelay_lt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_lte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    sys?: InputMaybe<SysFilter>;
    totalCostOfLeasing?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfLeasing_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfLeasing_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfOwnership_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    webflowId?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId_not?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
};

export type ProductSuggestedAccessoriesItem =
    | Audio
    | Dockstation
    | Keyboard
    | Mouse
    | Screen;

export type ProductVariantsCollection = {
    __typename: "ProductVariantsCollection";
    items: Array<Maybe<Variant>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export enum ProductVariantsCollectionOrder {
    generation_ASC = "generation_ASC",
    generation_DESC = "generation_DESC",
    graphicsCardDescription_ASC = "graphicsCardDescription_ASC",
    graphicsCardDescription_DESC = "graphicsCardDescription_DESC",
    graphicsCardValue_ASC = "graphicsCardValue_ASC",
    graphicsCardValue_DESC = "graphicsCardValue_DESC",
    manufacturing_ASC = "manufacturing_ASC",
    manufacturing_DESC = "manufacturing_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    price12_ASC = "price12_ASC",
    price12_DESC = "price12_DESC",
    price24_ASC = "price24_ASC",
    price24_DESC = "price24_DESC",
    price36_ASC = "price36_ASC",
    price36_DESC = "price36_DESC",
    price48_ASC = "price48_ASC",
    price48_DESC = "price48_DESC",
    processorDescription_ASC = "processorDescription_ASC",
    processorDescription_DESC = "processorDescription_DESC",
    processorValue_ASC = "processorValue_ASC",
    processorValue_DESC = "processorValue_DESC",
    purchasePriceHt_ASC = "purchasePriceHt_ASC",
    purchasePriceHt_DESC = "purchasePriceHt_DESC",
    purchasePriceTtc_ASC = "purchasePriceTtc_ASC",
    purchasePriceTtc_DESC = "purchasePriceTtc_DESC",
    quantity_ASC = "quantity_ASC",
    quantity_DESC = "quantity_DESC",
    ramDescription_ASC = "ramDescription_ASC",
    ramDescription_DESC = "ramDescription_DESC",
    ramValue_ASC = "ramValue_ASC",
    ramValue_DESC = "ramValue_DESC",
    refurb_ASC = "refurb_ASC",
    refurb_DESC = "refurb_DESC",
    repairability_ASC = "repairability_ASC",
    repairability_DESC = "repairability_DESC",
    repairs_ASC = "repairs_ASC",
    repairs_DESC = "repairs_DESC",
    shippingDelay_ASC = "shippingDelay_ASC",
    shippingDelay_DESC = "shippingDelay_DESC",
    storageDescription_ASC = "storageDescription_ASC",
    storageDescription_DESC = "storageDescription_DESC",
    storageValue_ASC = "storageValue_ASC",
    storageValue_DESC = "storageValue_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    transport_ASC = "transport_ASC",
    transport_DESC = "transport_DESC",
    usageScore_ASC = "usageScore_ASC",
    usageScore_DESC = "usageScore_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
}

export type Query = {
    __typename: "Query";
    _node: Maybe<_Node>;
    article: Maybe<Article>;
    articleCollection: Maybe<ArticleCollection>;
    asset: Maybe<Asset>;
    assetCollection: Maybe<AssetCollection>;
    audio: Maybe<Audio>;
    audioCollection: Maybe<AudioCollection>;
    changelog: Maybe<Changelog>;
    changelogCollection: Maybe<ChangelogCollection>;
    dockstation: Maybe<Dockstation>;
    dockstationCollection: Maybe<DockstationCollection>;
    entryCollection: Maybe<EntryCollection>;
    featureFlagConfiguration: Maybe<FeatureFlagConfiguration>;
    featureFlagConfigurationCollection: Maybe<FeatureFlagConfigurationCollection>;
    integration: Maybe<Integration>;
    integrationCollection: Maybe<IntegrationCollection>;
    keyboard: Maybe<Keyboard>;
    keyboardCollection: Maybe<KeyboardCollection>;
    mouse: Maybe<Mouse>;
    mouseCollection: Maybe<MouseCollection>;
    pageContent: Maybe<PageContent>;
    pageContentCollection: Maybe<PageContentCollection>;
    product: Maybe<Product>;
    productCollection: Maybe<ProductCollection>;
    screen: Maybe<Screen>;
    screenCollection: Maybe<ScreenCollection>;
    variant: Maybe<Variant>;
    variantCollection: Maybe<VariantCollection>;
};

export type Query_nodeArgs = {
    id: Scalars["ID"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryarticleArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryarticleCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<ArticleOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<ArticleFilter>;
};

export type QueryassetArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryassetCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<AssetOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<AssetFilter>;
};

export type QueryaudioArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryaudioCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<AudioOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<AudioFilter>;
};

export type QuerychangelogArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QuerychangelogCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<ChangelogOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<ChangelogFilter>;
};

export type QuerydockstationArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QuerydockstationCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<DockstationOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<DockstationFilter>;
};

export type QueryentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<EntryOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<EntryFilter>;
};

export type QueryfeatureFlagConfigurationArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryfeatureFlagConfigurationCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<FeatureFlagConfigurationOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<FeatureFlagConfigurationFilter>;
};

export type QueryintegrationArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryintegrationCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<IntegrationOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<IntegrationFilter>;
};

export type QuerykeyboardArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QuerykeyboardCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<KeyboardOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<KeyboardFilter>;
};

export type QuerymouseArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QuerymouseCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<MouseOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<MouseFilter>;
};

export type QuerypageContentArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QuerypageContentCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<PageContentOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<PageContentFilter>;
};

export type QueryproductArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryproductCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<ProductOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<ProductFilter>;
};

export type QueryscreenArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryscreenCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<ScreenOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<ScreenFilter>;
};

export type QueryvariantArgs = {
    id: Scalars["String"]["input"];
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryvariantCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<Array<InputMaybe<VariantOrder>>>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<VariantFilter>;
};

export type ResourceLink = {
    sys: ResourceSys;
};

export type ResourceSys = {
    __typename: "ResourceSys";
    linkType: Scalars["String"]["output"];
    urn: Scalars["String"]["output"];
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type Screen = Entry & {
    __typename: "Screen";
    brand: Maybe<Scalars["String"]["output"]>;
    companyCoefficient: Maybe<Scalars["JSON"]["output"]>;
    connectivity: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<ScreenDescription>;
    image: Maybe<Asset>;
    integratedCamera: Maybe<Scalars["Boolean"]["output"]>;
    linkedFrom: Maybe<ScreenLinkingCollections>;
    name: Maybe<Scalars["String"]["output"]>;
    price24: Maybe<Scalars["Float"]["output"]>;
    price36: Maybe<Scalars["Float"]["output"]>;
    price48: Maybe<Scalars["Float"]["output"]>;
    purchasePriceHt: Maybe<Scalars["Float"]["output"]>;
    refreshRate: Maybe<Scalars["Int"]["output"]>;
    resolution: Maybe<Scalars["String"]["output"]>;
    shippingDelay: Maybe<Scalars["Int"]["output"]>;
    size: Maybe<Scalars["Float"]["output"]>;
    sys: Sys;
    totalCostOfLeasing: Maybe<Scalars["Float"]["output"]>;
    totalCostOfOwnership: Maybe<Scalars["Float"]["output"]>;
    webflowId: Maybe<Scalars["String"]["output"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreenbrandArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreencompanyCoefficientArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreenconnectivityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreendescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreenimageArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreenintegratedCameraArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreenlinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreennameArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type Screenprice24Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type Screenprice36Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type Screenprice48Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreenpurchasePriceHtArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreenrefreshRateArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreenresolutionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreenshippingDelayArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreensizeArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreentotalCostOfLeasingArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreentotalCostOfOwnershipArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Screen accessories rented by Cleaq [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/screen) */
export type ScreenwebflowIdArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type ScreenCollection = {
    __typename: "ScreenCollection";
    items: Array<Maybe<Screen>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type ScreenDescription = {
    __typename: "ScreenDescription";
    json: Scalars["JSON"]["output"];
    links: ScreenDescriptionLinks;
};

export type ScreenDescriptionAssets = {
    __typename: "ScreenDescriptionAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type ScreenDescriptionEntries = {
    __typename: "ScreenDescriptionEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type ScreenDescriptionLinks = {
    __typename: "ScreenDescriptionLinks";
    assets: ScreenDescriptionAssets;
    entries: ScreenDescriptionEntries;
    resources: ScreenDescriptionResources;
};

export type ScreenDescriptionResources = {
    __typename: "ScreenDescriptionResources";
    block: Array<ScreenDescriptionResourcesBlock>;
    hyperlink: Array<ScreenDescriptionResourcesHyperlink>;
    inline: Array<ScreenDescriptionResourcesInline>;
};

export type ScreenDescriptionResourcesBlock = ResourceLink & {
    __typename: "ScreenDescriptionResourcesBlock";
    sys: ResourceSys;
};

export type ScreenDescriptionResourcesHyperlink = ResourceLink & {
    __typename: "ScreenDescriptionResourcesHyperlink";
    sys: ResourceSys;
};

export type ScreenDescriptionResourcesInline = ResourceLink & {
    __typename: "ScreenDescriptionResourcesInline";
    sys: ResourceSys;
};

export type ScreenFilter = {
    AND?: InputMaybe<Array<InputMaybe<ScreenFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<ScreenFilter>>>;
    brand?: InputMaybe<Scalars["String"]["input"]>;
    brand_contains?: InputMaybe<Scalars["String"]["input"]>;
    brand_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    brand_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    brand_not?: InputMaybe<Scalars["String"]["input"]>;
    brand_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    brand_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    companyCoefficient_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    connectivity_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    description_contains?: InputMaybe<Scalars["String"]["input"]>;
    description_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    image_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    integratedCamera?: InputMaybe<Scalars["Boolean"]["input"]>;
    integratedCamera_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    integratedCamera_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    price24?: InputMaybe<Scalars["Float"]["input"]>;
    price24_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price24_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price24_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36?: InputMaybe<Scalars["Float"]["input"]>;
    price36_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price36_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48?: InputMaybe<Scalars["Float"]["input"]>;
    price48_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price48_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    purchasePriceHt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceHt_gt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_gte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceHt_lt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_lte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    refreshRate?: InputMaybe<Scalars["Int"]["input"]>;
    refreshRate_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    refreshRate_gt?: InputMaybe<Scalars["Int"]["input"]>;
    refreshRate_gte?: InputMaybe<Scalars["Int"]["input"]>;
    refreshRate_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    refreshRate_lt?: InputMaybe<Scalars["Int"]["input"]>;
    refreshRate_lte?: InputMaybe<Scalars["Int"]["input"]>;
    refreshRate_not?: InputMaybe<Scalars["Int"]["input"]>;
    refreshRate_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    resolution?: InputMaybe<Scalars["String"]["input"]>;
    resolution_contains?: InputMaybe<Scalars["String"]["input"]>;
    resolution_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    resolution_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    resolution_not?: InputMaybe<Scalars["String"]["input"]>;
    resolution_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    resolution_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    shippingDelay?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shippingDelay_gt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_gte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    shippingDelay_lt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_lte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    size?: InputMaybe<Scalars["Float"]["input"]>;
    size_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    size_gt?: InputMaybe<Scalars["Float"]["input"]>;
    size_gte?: InputMaybe<Scalars["Float"]["input"]>;
    size_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    size_lt?: InputMaybe<Scalars["Float"]["input"]>;
    size_lte?: InputMaybe<Scalars["Float"]["input"]>;
    size_not?: InputMaybe<Scalars["Float"]["input"]>;
    size_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    sys?: InputMaybe<SysFilter>;
    totalCostOfLeasing?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfLeasing_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfLeasing_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfOwnership_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    webflowId?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId_not?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
};

export type ScreenLinkingCollections = {
    __typename: "ScreenLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
    productCollection: Maybe<ProductCollection>;
};

export type ScreenLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ScreenLinkingCollectionsproductCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<
        Array<InputMaybe<ScreenLinkingCollectionsProductCollectionOrder>>
    >;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum ScreenLinkingCollectionsProductCollectionOrder {
    appearanceOrder_ASC = "appearanceOrder_ASC",
    appearanceOrder_DESC = "appearanceOrder_DESC",
    battery_ASC = "battery_ASC",
    battery_DESC = "battery_DESC",
    bluetooth_ASC = "bluetooth_ASC",
    bluetooth_DESC = "bluetooth_DESC",
    brand_ASC = "brand_ASC",
    brand_DESC = "brand_DESC",
    colors_ASC = "colors_ASC",
    colors_DESC = "colors_DESC",
    fingerPrintReader_ASC = "fingerPrintReader_ASC",
    fingerPrintReader_DESC = "fingerPrintReader_DESC",
    foldable_ASC = "foldable_ASC",
    foldable_DESC = "foldable_DESC",
    height_ASC = "height_ASC",
    height_DESC = "height_DESC",
    isRefurbed_ASC = "isRefurbed_ASC",
    isRefurbed_DESC = "isRefurbed_DESC",
    keyboardBacklight_ASC = "keyboardBacklight_ASC",
    keyboardBacklight_DESC = "keyboardBacklight_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    osDescription_ASC = "osDescription_ASC",
    osDescription_DESC = "osDescription_DESC",
    os_ASC = "os_ASC",
    os_DESC = "os_DESC",
    repairability_ASC = "repairability_ASC",
    repairability_DESC = "repairability_DESC",
    screenSizeDescription_ASC = "screenSizeDescription_ASC",
    screenSizeDescription_DESC = "screenSizeDescription_DESC",
    screenSizeValue_ASC = "screenSizeValue_ASC",
    screenSizeValue_DESC = "screenSizeValue_DESC",
    shortDescription_ASC = "shortDescription_ASC",
    shortDescription_DESC = "shortDescription_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    tactile_ASC = "tactile_ASC",
    tactile_DESC = "tactile_DESC",
    thickness_ASC = "thickness_ASC",
    thickness_DESC = "thickness_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    type_ASC = "type_ASC",
    type_DESC = "type_DESC",
    webcam_ASC = "webcam_ASC",
    webcam_DESC = "webcam_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
    weight_ASC = "weight_ASC",
    weight_DESC = "weight_DESC",
    width_ASC = "width_ASC",
    width_DESC = "width_DESC",
    wifi_ASC = "wifi_ASC",
    wifi_DESC = "wifi_DESC",
}

export enum ScreenOrder {
    brand_ASC = "brand_ASC",
    brand_DESC = "brand_DESC",
    integratedCamera_ASC = "integratedCamera_ASC",
    integratedCamera_DESC = "integratedCamera_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    price24_ASC = "price24_ASC",
    price24_DESC = "price24_DESC",
    price36_ASC = "price36_ASC",
    price36_DESC = "price36_DESC",
    price48_ASC = "price48_ASC",
    price48_DESC = "price48_DESC",
    purchasePriceHt_ASC = "purchasePriceHt_ASC",
    purchasePriceHt_DESC = "purchasePriceHt_DESC",
    refreshRate_ASC = "refreshRate_ASC",
    refreshRate_DESC = "refreshRate_DESC",
    resolution_ASC = "resolution_ASC",
    resolution_DESC = "resolution_DESC",
    shippingDelay_ASC = "shippingDelay_ASC",
    shippingDelay_DESC = "shippingDelay_DESC",
    size_ASC = "size_ASC",
    size_DESC = "size_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
}

export type Sys = {
    __typename: "Sys";
    environmentId: Scalars["String"]["output"];
    firstPublishedAt: Maybe<Scalars["DateTime"]["output"]>;
    id: Scalars["String"]["output"];
    /** The locale that was requested. */
    locale: Maybe<Scalars["String"]["output"]>;
    publishedAt: Maybe<Scalars["DateTime"]["output"]>;
    publishedVersion: Maybe<Scalars["Int"]["output"]>;
    spaceId: Scalars["String"]["output"];
};

export type SysFilter = {
    firstPublishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    firstPublishedAt_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    firstPublishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
    firstPublishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
    firstPublishedAt_in?: InputMaybe<
        Array<InputMaybe<Scalars["DateTime"]["input"]>>
    >;
    firstPublishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
    firstPublishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
    firstPublishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
    firstPublishedAt_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["DateTime"]["input"]>>
    >;
    id?: InputMaybe<Scalars["String"]["input"]>;
    id_contains?: InputMaybe<Scalars["String"]["input"]>;
    id_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    id_not?: InputMaybe<Scalars["String"]["input"]>;
    id_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    publishedAt_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
    publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
    publishedAt_in?: InputMaybe<
        Array<InputMaybe<Scalars["DateTime"]["input"]>>
    >;
    publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
    publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
    publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
    publishedAt_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["DateTime"]["input"]>>
    >;
    publishedVersion?: InputMaybe<Scalars["Float"]["input"]>;
    publishedVersion_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    publishedVersion_gt?: InputMaybe<Scalars["Float"]["input"]>;
    publishedVersion_gte?: InputMaybe<Scalars["Float"]["input"]>;
    publishedVersion_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    publishedVersion_lt?: InputMaybe<Scalars["Float"]["input"]>;
    publishedVersion_lte?: InputMaybe<Scalars["Float"]["input"]>;
    publishedVersion_not?: InputMaybe<Scalars["Float"]["input"]>;
    publishedVersion_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type Variant = Entry & {
    __typename: "Variant";
    companyCoefficient: Maybe<Scalars["JSON"]["output"]>;
    contentfulMetadata: ContentfulMetadata;
    generation: Maybe<Scalars["String"]["output"]>;
    graphicsCardDescription: Maybe<Scalars["String"]["output"]>;
    graphicsCardValue: Maybe<Scalars["Int"]["output"]>;
    jobUsages: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    linkedFrom: Maybe<VariantLinkingCollections>;
    manufacturing: Maybe<Scalars["Float"]["output"]>;
    moreDetails: Maybe<VariantMoreDetails>;
    name: Maybe<Scalars["String"]["output"]>;
    price12: Maybe<Scalars["Float"]["output"]>;
    price24: Maybe<Scalars["Float"]["output"]>;
    price36: Maybe<Scalars["Float"]["output"]>;
    price48: Maybe<Scalars["Float"]["output"]>;
    processorDescription: Maybe<Scalars["String"]["output"]>;
    processorValue: Maybe<Scalars["String"]["output"]>;
    product: Maybe<Product>;
    purchasePriceHt: Maybe<Scalars["Float"]["output"]>;
    purchasePriceTtc: Maybe<Scalars["Float"]["output"]>;
    quantity: Maybe<Scalars["Int"]["output"]>;
    ramDescription: Maybe<Scalars["String"]["output"]>;
    ramValue: Maybe<Scalars["Int"]["output"]>;
    refurb: Maybe<Scalars["Float"]["output"]>;
    repairability: Maybe<Scalars["Float"]["output"]>;
    repairs: Maybe<Scalars["Float"]["output"]>;
    shippingDelay: Maybe<Scalars["Int"]["output"]>;
    storageDescription: Maybe<Scalars["String"]["output"]>;
    storageValue: Maybe<Scalars["Int"]["output"]>;
    sys: Sys;
    transport: Maybe<Scalars["Float"]["output"]>;
    usageScore: Maybe<Scalars["Int"]["output"]>;
    usageTypes: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    usages: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    webflowId: Maybe<Scalars["String"]["output"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantcompanyCoefficientArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantgenerationArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantgraphicsCardDescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantgraphicsCardValueArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantjobUsagesArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantlinkedFromArgs = {
    allowedLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantmanufacturingArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantmoreDetailsArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantnameArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type Variantprice12Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type Variantprice24Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type Variantprice36Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type Variantprice48Args = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantprocessorDescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantprocessorValueArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantproductArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    where?: InputMaybe<ProductFilter>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantpurchasePriceHtArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantpurchasePriceTtcArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantquantityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantramDescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantramValueArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantrefurbArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantrepairabilityArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantrepairsArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantshippingDelayArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantstorageDescriptionArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantstorageValueArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VarianttransportArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantusageScoreArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantusageTypesArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantusagesArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Technical Variant of the product [See type definition](https://app.contentful.com/spaces/85xu1vrcoskd/content_types/variant) */
export type VariantwebflowIdArgs = {
    locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type VariantCollection = {
    __typename: "VariantCollection";
    items: Array<Maybe<Variant>>;
    limit: Scalars["Int"]["output"];
    skip: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type VariantFilter = {
    AND?: InputMaybe<Array<InputMaybe<VariantFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<VariantFilter>>>;
    companyCoefficient_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    generation?: InputMaybe<Scalars["String"]["input"]>;
    generation_contains?: InputMaybe<Scalars["String"]["input"]>;
    generation_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    generation_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    generation_not?: InputMaybe<Scalars["String"]["input"]>;
    generation_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    generation_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    graphicsCardDescription?: InputMaybe<Scalars["String"]["input"]>;
    graphicsCardDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    graphicsCardDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    graphicsCardDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    graphicsCardDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    graphicsCardDescription_not_contains?: InputMaybe<
        Scalars["String"]["input"]
    >;
    graphicsCardDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    graphicsCardValue?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    graphicsCardValue_gt?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_gte?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    graphicsCardValue_lt?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_lte?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_not?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    jobUsages_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    jobUsages_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    jobUsages_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    jobUsages_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    manufacturing?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    manufacturing_gt?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_gte?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    manufacturing_lt?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_lte?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_not?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    moreDetails_contains?: InputMaybe<Scalars["String"]["input"]>;
    moreDetails_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    moreDetails_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    price12?: InputMaybe<Scalars["Float"]["input"]>;
    price12_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price12_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price12_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price12_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price12_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price12_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price12_not?: InputMaybe<Scalars["Float"]["input"]>;
    price12_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price24?: InputMaybe<Scalars["Float"]["input"]>;
    price24_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price24_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price24_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36?: InputMaybe<Scalars["Float"]["input"]>;
    price36_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price36_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48?: InputMaybe<Scalars["Float"]["input"]>;
    price48_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price48_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    processorDescription?: InputMaybe<Scalars["String"]["input"]>;
    processorDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    processorDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    processorDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    processorDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    processorDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    processorDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    processorValue?: InputMaybe<Scalars["String"]["input"]>;
    processorValue_contains?: InputMaybe<Scalars["String"]["input"]>;
    processorValue_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    processorValue_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    processorValue_not?: InputMaybe<Scalars["String"]["input"]>;
    processorValue_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    processorValue_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    product?: InputMaybe<cfProductNestedFilter>;
    product_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceHt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceHt_gt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_gte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceHt_lt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_lte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceTtc?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceTtc_gt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_gte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceTtc_lt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_lte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_not?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    quantity?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    quantity_gt?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_gte?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    quantity_lt?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_lte?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_not?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    ramDescription?: InputMaybe<Scalars["String"]["input"]>;
    ramDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    ramDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    ramDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    ramDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    ramDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    ramDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    ramValue?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    ramValue_gt?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_gte?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    ramValue_lt?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_lte?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_not?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    refurb?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    refurb_gt?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_gte?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    refurb_lt?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_lte?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_not?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    repairability?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    repairability_gt?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_gte?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    repairability_lt?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_lte?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_not?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    repairs?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    repairs_gt?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_gte?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    repairs_lt?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_lte?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_not?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    shippingDelay?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shippingDelay_gt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_gte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    shippingDelay_lt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_lte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    storageDescription?: InputMaybe<Scalars["String"]["input"]>;
    storageDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    storageDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    storageDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    storageDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    storageDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    storageDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    storageValue?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    storageValue_gt?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_gte?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    storageValue_lt?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_lte?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_not?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    sys?: InputMaybe<SysFilter>;
    transport?: InputMaybe<Scalars["Float"]["input"]>;
    transport_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    transport_gt?: InputMaybe<Scalars["Float"]["input"]>;
    transport_gte?: InputMaybe<Scalars["Float"]["input"]>;
    transport_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    transport_lt?: InputMaybe<Scalars["Float"]["input"]>;
    transport_lte?: InputMaybe<Scalars["Float"]["input"]>;
    transport_not?: InputMaybe<Scalars["Float"]["input"]>;
    transport_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    usageScore?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    usageScore_gt?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_gte?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    usageScore_lt?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_lte?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_not?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    usageTypes_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usageTypes_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usageTypes_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usageTypes_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    usages_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usages_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usages_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usages_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId_not?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
};

export type VariantLinkingCollections = {
    __typename: "VariantLinkingCollections";
    entryCollection: Maybe<EntryCollection>;
    productCollection: Maybe<ProductCollection>;
};

export type VariantLinkingCollectionsentryCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VariantLinkingCollectionsproductCollectionArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    locale?: InputMaybe<Scalars["String"]["input"]>;
    order?: InputMaybe<
        Array<InputMaybe<VariantLinkingCollectionsProductCollectionOrder>>
    >;
    preview?: InputMaybe<Scalars["Boolean"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum VariantLinkingCollectionsProductCollectionOrder {
    appearanceOrder_ASC = "appearanceOrder_ASC",
    appearanceOrder_DESC = "appearanceOrder_DESC",
    battery_ASC = "battery_ASC",
    battery_DESC = "battery_DESC",
    bluetooth_ASC = "bluetooth_ASC",
    bluetooth_DESC = "bluetooth_DESC",
    brand_ASC = "brand_ASC",
    brand_DESC = "brand_DESC",
    colors_ASC = "colors_ASC",
    colors_DESC = "colors_DESC",
    fingerPrintReader_ASC = "fingerPrintReader_ASC",
    fingerPrintReader_DESC = "fingerPrintReader_DESC",
    foldable_ASC = "foldable_ASC",
    foldable_DESC = "foldable_DESC",
    height_ASC = "height_ASC",
    height_DESC = "height_DESC",
    isRefurbed_ASC = "isRefurbed_ASC",
    isRefurbed_DESC = "isRefurbed_DESC",
    keyboardBacklight_ASC = "keyboardBacklight_ASC",
    keyboardBacklight_DESC = "keyboardBacklight_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    osDescription_ASC = "osDescription_ASC",
    osDescription_DESC = "osDescription_DESC",
    os_ASC = "os_ASC",
    os_DESC = "os_DESC",
    repairability_ASC = "repairability_ASC",
    repairability_DESC = "repairability_DESC",
    screenSizeDescription_ASC = "screenSizeDescription_ASC",
    screenSizeDescription_DESC = "screenSizeDescription_DESC",
    screenSizeValue_ASC = "screenSizeValue_ASC",
    screenSizeValue_DESC = "screenSizeValue_DESC",
    shortDescription_ASC = "shortDescription_ASC",
    shortDescription_DESC = "shortDescription_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    tactile_ASC = "tactile_ASC",
    tactile_DESC = "tactile_DESC",
    thickness_ASC = "thickness_ASC",
    thickness_DESC = "thickness_DESC",
    totalCostOfLeasing_ASC = "totalCostOfLeasing_ASC",
    totalCostOfLeasing_DESC = "totalCostOfLeasing_DESC",
    totalCostOfOwnership_ASC = "totalCostOfOwnership_ASC",
    totalCostOfOwnership_DESC = "totalCostOfOwnership_DESC",
    type_ASC = "type_ASC",
    type_DESC = "type_DESC",
    webcam_ASC = "webcam_ASC",
    webcam_DESC = "webcam_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
    weight_ASC = "weight_ASC",
    weight_DESC = "weight_DESC",
    width_ASC = "width_ASC",
    width_DESC = "width_DESC",
    wifi_ASC = "wifi_ASC",
    wifi_DESC = "wifi_DESC",
}

export type VariantMoreDetails = {
    __typename: "VariantMoreDetails";
    json: Scalars["JSON"]["output"];
    links: VariantMoreDetailsLinks;
};

export type VariantMoreDetailsAssets = {
    __typename: "VariantMoreDetailsAssets";
    block: Array<Maybe<Asset>>;
    hyperlink: Array<Maybe<Asset>>;
};

export type VariantMoreDetailsEntries = {
    __typename: "VariantMoreDetailsEntries";
    block: Array<Maybe<Entry>>;
    hyperlink: Array<Maybe<Entry>>;
    inline: Array<Maybe<Entry>>;
};

export type VariantMoreDetailsLinks = {
    __typename: "VariantMoreDetailsLinks";
    assets: VariantMoreDetailsAssets;
    entries: VariantMoreDetailsEntries;
    resources: VariantMoreDetailsResources;
};

export type VariantMoreDetailsResources = {
    __typename: "VariantMoreDetailsResources";
    block: Array<VariantMoreDetailsResourcesBlock>;
    hyperlink: Array<VariantMoreDetailsResourcesHyperlink>;
    inline: Array<VariantMoreDetailsResourcesInline>;
};

export type VariantMoreDetailsResourcesBlock = ResourceLink & {
    __typename: "VariantMoreDetailsResourcesBlock";
    sys: ResourceSys;
};

export type VariantMoreDetailsResourcesHyperlink = ResourceLink & {
    __typename: "VariantMoreDetailsResourcesHyperlink";
    sys: ResourceSys;
};

export type VariantMoreDetailsResourcesInline = ResourceLink & {
    __typename: "VariantMoreDetailsResourcesInline";
    sys: ResourceSys;
};

export enum VariantOrder {
    generation_ASC = "generation_ASC",
    generation_DESC = "generation_DESC",
    graphicsCardDescription_ASC = "graphicsCardDescription_ASC",
    graphicsCardDescription_DESC = "graphicsCardDescription_DESC",
    graphicsCardValue_ASC = "graphicsCardValue_ASC",
    graphicsCardValue_DESC = "graphicsCardValue_DESC",
    manufacturing_ASC = "manufacturing_ASC",
    manufacturing_DESC = "manufacturing_DESC",
    name_ASC = "name_ASC",
    name_DESC = "name_DESC",
    price12_ASC = "price12_ASC",
    price12_DESC = "price12_DESC",
    price24_ASC = "price24_ASC",
    price24_DESC = "price24_DESC",
    price36_ASC = "price36_ASC",
    price36_DESC = "price36_DESC",
    price48_ASC = "price48_ASC",
    price48_DESC = "price48_DESC",
    processorDescription_ASC = "processorDescription_ASC",
    processorDescription_DESC = "processorDescription_DESC",
    processorValue_ASC = "processorValue_ASC",
    processorValue_DESC = "processorValue_DESC",
    purchasePriceHt_ASC = "purchasePriceHt_ASC",
    purchasePriceHt_DESC = "purchasePriceHt_DESC",
    purchasePriceTtc_ASC = "purchasePriceTtc_ASC",
    purchasePriceTtc_DESC = "purchasePriceTtc_DESC",
    quantity_ASC = "quantity_ASC",
    quantity_DESC = "quantity_DESC",
    ramDescription_ASC = "ramDescription_ASC",
    ramDescription_DESC = "ramDescription_DESC",
    ramValue_ASC = "ramValue_ASC",
    ramValue_DESC = "ramValue_DESC",
    refurb_ASC = "refurb_ASC",
    refurb_DESC = "refurb_DESC",
    repairability_ASC = "repairability_ASC",
    repairability_DESC = "repairability_DESC",
    repairs_ASC = "repairs_ASC",
    repairs_DESC = "repairs_DESC",
    shippingDelay_ASC = "shippingDelay_ASC",
    shippingDelay_DESC = "shippingDelay_DESC",
    storageDescription_ASC = "storageDescription_ASC",
    storageDescription_DESC = "storageDescription_DESC",
    storageValue_ASC = "storageValue_ASC",
    storageValue_DESC = "storageValue_DESC",
    sys_firstPublishedAt_ASC = "sys_firstPublishedAt_ASC",
    sys_firstPublishedAt_DESC = "sys_firstPublishedAt_DESC",
    sys_id_ASC = "sys_id_ASC",
    sys_id_DESC = "sys_id_DESC",
    sys_publishedAt_ASC = "sys_publishedAt_ASC",
    sys_publishedAt_DESC = "sys_publishedAt_DESC",
    sys_publishedVersion_ASC = "sys_publishedVersion_ASC",
    sys_publishedVersion_DESC = "sys_publishedVersion_DESC",
    transport_ASC = "transport_ASC",
    transport_DESC = "transport_DESC",
    usageScore_ASC = "usageScore_ASC",
    usageScore_DESC = "usageScore_DESC",
    webflowId_ASC = "webflowId_ASC",
    webflowId_DESC = "webflowId_DESC",
}

export type _Node = {
    _id: Scalars["ID"]["output"];
};

export type cfProductNestedFilter = {
    AND?: InputMaybe<Array<InputMaybe<cfProductNestedFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<cfProductNestedFilter>>>;
    appearanceOrder?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    appearanceOrder_gt?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_gte?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    appearanceOrder_lt?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_lte?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_not?: InputMaybe<Scalars["Int"]["input"]>;
    appearanceOrder_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    battery?: InputMaybe<Scalars["String"]["input"]>;
    battery_contains?: InputMaybe<Scalars["String"]["input"]>;
    battery_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    battery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    battery_not?: InputMaybe<Scalars["String"]["input"]>;
    battery_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    battery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    bluetooth?: InputMaybe<Scalars["String"]["input"]>;
    bluetooth_contains?: InputMaybe<Scalars["String"]["input"]>;
    bluetooth_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    bluetooth_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    bluetooth_not?: InputMaybe<Scalars["String"]["input"]>;
    bluetooth_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    bluetooth_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    brand?: InputMaybe<Scalars["String"]["input"]>;
    brand_contains?: InputMaybe<Scalars["String"]["input"]>;
    brand_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    brand_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    brand_not?: InputMaybe<Scalars["String"]["input"]>;
    brand_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    brand_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    catalog_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    catalog_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    catalog_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    catalog_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    colors?: InputMaybe<Scalars["String"]["input"]>;
    colors_contains?: InputMaybe<Scalars["String"]["input"]>;
    colors_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    colors_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    colors_not?: InputMaybe<Scalars["String"]["input"]>;
    colors_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    colors_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    fingerPrintReader?: InputMaybe<Scalars["Boolean"]["input"]>;
    fingerPrintReader_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    fingerPrintReader_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    foldable?: InputMaybe<Scalars["Boolean"]["input"]>;
    foldable_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    foldable_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    height?: InputMaybe<Scalars["Float"]["input"]>;
    height_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    height_gt?: InputMaybe<Scalars["Float"]["input"]>;
    height_gte?: InputMaybe<Scalars["Float"]["input"]>;
    height_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    height_lt?: InputMaybe<Scalars["Float"]["input"]>;
    height_lte?: InputMaybe<Scalars["Float"]["input"]>;
    height_not?: InputMaybe<Scalars["Float"]["input"]>;
    height_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    image_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    isRefurbed?: InputMaybe<Scalars["Boolean"]["input"]>;
    isRefurbed_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    isRefurbed_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    keyboardBacklight?: InputMaybe<Scalars["Boolean"]["input"]>;
    keyboardBacklight_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    keyboardBacklight_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    longDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    longDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    longDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    moreDetails_contains?: InputMaybe<Scalars["String"]["input"]>;
    moreDetails_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    moreDetails_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    os?: InputMaybe<Scalars["String"]["input"]>;
    osDescription?: InputMaybe<Scalars["String"]["input"]>;
    osDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    osDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    osDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    osDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    osDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    osDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    os_contains?: InputMaybe<Scalars["String"]["input"]>;
    os_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    os_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    os_not?: InputMaybe<Scalars["String"]["input"]>;
    os_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    os_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    repairability?: InputMaybe<Scalars["Float"]["input"]>;
    repairabilityIndex_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    repairability_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    repairability_gt?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_gte?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    repairability_lt?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_lte?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_not?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    screenSizeDescription?: InputMaybe<Scalars["String"]["input"]>;
    screenSizeDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    screenSizeDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    screenSizeDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    screenSizeDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    screenSizeDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    screenSizeDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    screenSizeValue?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    screenSizeValue_gt?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_gte?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    screenSizeValue_lt?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_lte?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_not?: InputMaybe<Scalars["Float"]["input"]>;
    screenSizeValue_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    shortDescription?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shortDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    shortDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    shortDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    suggestedAccessoriesCollection_exists?: InputMaybe<
        Scalars["Boolean"]["input"]
    >;
    sys?: InputMaybe<SysFilter>;
    tactile?: InputMaybe<Scalars["Boolean"]["input"]>;
    tactile_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    tactile_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    thickness?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    thickness_gt?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_gte?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    thickness_lt?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_lte?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_not?: InputMaybe<Scalars["Float"]["input"]>;
    thickness_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    totalCostOfLeasing?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfLeasing_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfLeasing_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfOwnership_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    type?: InputMaybe<Scalars["String"]["input"]>;
    type_contains?: InputMaybe<Scalars["String"]["input"]>;
    type_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    type_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    type_not?: InputMaybe<Scalars["String"]["input"]>;
    type_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    variantsCollection_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webcam?: InputMaybe<Scalars["Boolean"]["input"]>;
    webcam_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webcam_not?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId_not?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    weight?: InputMaybe<Scalars["Float"]["input"]>;
    weight_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    weight_gt?: InputMaybe<Scalars["Float"]["input"]>;
    weight_gte?: InputMaybe<Scalars["Float"]["input"]>;
    weight_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    weight_lt?: InputMaybe<Scalars["Float"]["input"]>;
    weight_lte?: InputMaybe<Scalars["Float"]["input"]>;
    weight_not?: InputMaybe<Scalars["Float"]["input"]>;
    weight_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    width?: InputMaybe<Scalars["Float"]["input"]>;
    width_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    width_gt?: InputMaybe<Scalars["Float"]["input"]>;
    width_gte?: InputMaybe<Scalars["Float"]["input"]>;
    width_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    width_lt?: InputMaybe<Scalars["Float"]["input"]>;
    width_lte?: InputMaybe<Scalars["Float"]["input"]>;
    width_not?: InputMaybe<Scalars["Float"]["input"]>;
    width_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    wifi?: InputMaybe<Scalars["String"]["input"]>;
    wifi_contains?: InputMaybe<Scalars["String"]["input"]>;
    wifi_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    wifi_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    wifi_not?: InputMaybe<Scalars["String"]["input"]>;
    wifi_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    wifi_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type cfVariantNestedFilter = {
    AND?: InputMaybe<Array<InputMaybe<cfVariantNestedFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<cfVariantNestedFilter>>>;
    companyCoefficient_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    generation?: InputMaybe<Scalars["String"]["input"]>;
    generation_contains?: InputMaybe<Scalars["String"]["input"]>;
    generation_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    generation_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    generation_not?: InputMaybe<Scalars["String"]["input"]>;
    generation_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    generation_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    graphicsCardDescription?: InputMaybe<Scalars["String"]["input"]>;
    graphicsCardDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    graphicsCardDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    graphicsCardDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    graphicsCardDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    graphicsCardDescription_not_contains?: InputMaybe<
        Scalars["String"]["input"]
    >;
    graphicsCardDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    graphicsCardValue?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    graphicsCardValue_gt?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_gte?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    graphicsCardValue_lt?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_lte?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_not?: InputMaybe<Scalars["Int"]["input"]>;
    graphicsCardValue_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    jobUsages_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    jobUsages_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    jobUsages_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    jobUsages_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    manufacturing?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    manufacturing_gt?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_gte?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    manufacturing_lt?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_lte?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_not?: InputMaybe<Scalars["Float"]["input"]>;
    manufacturing_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    moreDetails_contains?: InputMaybe<Scalars["String"]["input"]>;
    moreDetails_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    moreDetails_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    price12?: InputMaybe<Scalars["Float"]["input"]>;
    price12_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price12_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price12_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price12_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price12_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price12_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price12_not?: InputMaybe<Scalars["Float"]["input"]>;
    price12_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price24?: InputMaybe<Scalars["Float"]["input"]>;
    price24_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price24_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price24_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36?: InputMaybe<Scalars["Float"]["input"]>;
    price36_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price36_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48?: InputMaybe<Scalars["Float"]["input"]>;
    price48_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price48_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    processorDescription?: InputMaybe<Scalars["String"]["input"]>;
    processorDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    processorDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    processorDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    processorDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    processorDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    processorDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    processorValue?: InputMaybe<Scalars["String"]["input"]>;
    processorValue_contains?: InputMaybe<Scalars["String"]["input"]>;
    processorValue_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    processorValue_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    processorValue_not?: InputMaybe<Scalars["String"]["input"]>;
    processorValue_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    processorValue_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    product_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceHt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceHt_gt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_gte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceHt_lt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_lte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceTtc?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceTtc_gt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_gte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceTtc_lt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_lte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_not?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceTtc_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    quantity?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    quantity_gt?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_gte?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    quantity_lt?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_lte?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_not?: InputMaybe<Scalars["Int"]["input"]>;
    quantity_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    ramDescription?: InputMaybe<Scalars["String"]["input"]>;
    ramDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    ramDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    ramDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    ramDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    ramDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    ramDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    ramValue?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    ramValue_gt?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_gte?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    ramValue_lt?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_lte?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_not?: InputMaybe<Scalars["Int"]["input"]>;
    ramValue_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    refurb?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    refurb_gt?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_gte?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    refurb_lt?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_lte?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_not?: InputMaybe<Scalars["Float"]["input"]>;
    refurb_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    repairability?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    repairability_gt?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_gte?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    repairability_lt?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_lte?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_not?: InputMaybe<Scalars["Float"]["input"]>;
    repairability_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    repairs?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    repairs_gt?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_gte?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    repairs_lt?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_lte?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_not?: InputMaybe<Scalars["Float"]["input"]>;
    repairs_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    shippingDelay?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shippingDelay_gt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_gte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    shippingDelay_lt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_lte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    storageDescription?: InputMaybe<Scalars["String"]["input"]>;
    storageDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
    storageDescription_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    storageDescription_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    storageDescription_not?: InputMaybe<Scalars["String"]["input"]>;
    storageDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    storageDescription_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    storageValue?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    storageValue_gt?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_gte?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    storageValue_lt?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_lte?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_not?: InputMaybe<Scalars["Int"]["input"]>;
    storageValue_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    sys?: InputMaybe<SysFilter>;
    transport?: InputMaybe<Scalars["Float"]["input"]>;
    transport_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    transport_gt?: InputMaybe<Scalars["Float"]["input"]>;
    transport_gte?: InputMaybe<Scalars["Float"]["input"]>;
    transport_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    transport_lt?: InputMaybe<Scalars["Float"]["input"]>;
    transport_lte?: InputMaybe<Scalars["Float"]["input"]>;
    transport_not?: InputMaybe<Scalars["Float"]["input"]>;
    transport_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    usageScore?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    usageScore_gt?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_gte?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    usageScore_lt?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_lte?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_not?: InputMaybe<Scalars["Int"]["input"]>;
    usageScore_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    usageTypes_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usageTypes_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usageTypes_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usageTypes_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    usages_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usages_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usages_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    usages_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId_not?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
};

export type cfsuggestedAccessoriesMultiTypeNestedFilter = {
    AND?: InputMaybe<
        Array<InputMaybe<cfsuggestedAccessoriesMultiTypeNestedFilter>>
    >;
    OR?: InputMaybe<
        Array<InputMaybe<cfsuggestedAccessoriesMultiTypeNestedFilter>>
    >;
    companyCoefficient_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    connectivity_contains_all?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_none?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_contains_some?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    connectivity_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    description_contains?: InputMaybe<Scalars["String"]["input"]>;
    description_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    image_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    name_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    name_not?: InputMaybe<Scalars["String"]["input"]>;
    name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    price24?: InputMaybe<Scalars["Float"]["input"]>;
    price24_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price24_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price24_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price24_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not?: InputMaybe<Scalars["Float"]["input"]>;
    price24_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36?: InputMaybe<Scalars["Float"]["input"]>;
    price36_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price36_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price36_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price36_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not?: InputMaybe<Scalars["Float"]["input"]>;
    price36_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48?: InputMaybe<Scalars["Float"]["input"]>;
    price48_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    price48_gt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_gte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    price48_lt?: InputMaybe<Scalars["Float"]["input"]>;
    price48_lte?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not?: InputMaybe<Scalars["Float"]["input"]>;
    price48_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
    purchasePriceHt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    purchasePriceHt_gt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_gte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    purchasePriceHt_lt?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_lte?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not?: InputMaybe<Scalars["Float"]["input"]>;
    purchasePriceHt_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    shippingDelay?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    shippingDelay_gt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_gte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
    shippingDelay_lt?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_lte?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not?: InputMaybe<Scalars["Int"]["input"]>;
    shippingDelay_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Int"]["input"]>>
    >;
    sys?: InputMaybe<SysFilter>;
    totalCostOfLeasing?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfLeasing_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfLeasing_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfLeasing_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    totalCostOfOwnership_gt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_gte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    totalCostOfOwnership_lt?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_lte?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not?: InputMaybe<Scalars["Float"]["input"]>;
    totalCostOfOwnership_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["Float"]["input"]>>
    >;
    webflowId?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_exists?: InputMaybe<Scalars["Boolean"]["input"]>;
    webflowId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    webflowId_not?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
    webflowId_not_in?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
};

export type CatalogImageFragment = {
    __typename: "Asset";
    url: string | null;
    title: string | null;
};

export type AudioAccessoryFragment = {
    __typename: "Audio";
    name: string | null;
    type: string | null;
    noiseCancellation: boolean | null;
    connectivity: Array<string | null> | null;
    price24: number | null;
    price36: number | null;
    price48: number | null;
    companyCoefficient: any | null;
    shippingDelay: number | null;
    sys: { __typename: "Sys"; id: string };
    image: {
        __typename: "Asset";
        url: string | null;
        title: string | null;
    } | null;
    description: { __typename: "AudioDescription"; json: any } | null;
};

export type FindAudioAccessoriesQueryVariables = Exact<{
    whereAudioAccessories?: InputMaybe<AudioFilter>;
}>;

export type FindAudioAccessoriesQuery = {
    audioCollection: {
        __typename: "AudioCollection";
        items: Array<{
            __typename: "Audio";
            name: string | null;
            type: string | null;
            noiseCancellation: boolean | null;
            connectivity: Array<string | null> | null;
            price24: number | null;
            price36: number | null;
            price48: number | null;
            companyCoefficient: any | null;
            shippingDelay: number | null;
            sys: { __typename: "Sys"; id: string };
            image: {
                __typename: "Asset";
                url: string | null;
                title: string | null;
            } | null;
            description: { __typename: "AudioDescription"; json: any } | null;
        } | null>;
    } | null;
};

export type FindChangelogQueryVariables = Exact<{ [key: string]: never }>;

export type FindChangelogQuery = {
    changelogCollection: {
        __typename: "ChangelogCollection";
        items: Array<{
            __typename: "Changelog";
            title: string | null;
            shortDescription: string | null;
            content: { __typename: "ChangelogContent"; json: any } | null;
        } | null>;
    } | null;
};

export type DockstationFragment = {
    __typename: "Dockstation";
    name: string | null;
    compatibility: string | null;
    connection: string | null;
    connectivity: Array<string | null> | null;
    price24: number | null;
    price36: number | null;
    price48: number | null;
    companyCoefficient: any | null;
    shippingDelay: number | null;
    sys: { __typename: "Sys"; id: string };
    image: {
        __typename: "Asset";
        url: string | null;
        title: string | null;
    } | null;
    description: { __typename: "DockstationDescription"; json: any } | null;
};

export type FindDockstationsQueryVariables = Exact<{
    whereDockstations?: InputMaybe<DockstationFilter>;
}>;

export type FindDockstationsQuery = {
    dockstationCollection: {
        __typename: "DockstationCollection";
        items: Array<{
            __typename: "Dockstation";
            name: string | null;
            compatibility: string | null;
            connection: string | null;
            connectivity: Array<string | null> | null;
            price24: number | null;
            price36: number | null;
            price48: number | null;
            companyCoefficient: any | null;
            shippingDelay: number | null;
            sys: { __typename: "Sys"; id: string };
            image: {
                __typename: "Asset";
                url: string | null;
                title: string | null;
            } | null;
            description: {
                __typename: "DockstationDescription";
                json: any;
            } | null;
        } | null>;
    } | null;
};

export type FeatureFlagsFragment = {
    __typename: "FeatureFlagConfiguration";
    id: string | null;
    helpWidget: boolean | null;
    integrationsPage: boolean | null;
    nextCatalog: boolean | null;
    sys: { __typename: "Sys"; id: string };
};

export type FindFeatureFlagsQueryVariables = Exact<{
    where?: InputMaybe<FeatureFlagConfigurationFilter>;
}>;

export type FindFeatureFlagsQuery = {
    featureFlagConfigurationCollection: {
        __typename: "FeatureFlagConfigurationCollection";
        items: Array<{
            __typename: "FeatureFlagConfiguration";
            id: string | null;
            helpWidget: boolean | null;
            integrationsPage: boolean | null;
            nextCatalog: boolean | null;
            sys: { __typename: "Sys"; id: string };
        } | null>;
    } | null;
};

export type CatalogIntegrationFragment = {
    __typename: "Integration";
    displayName: string | null;
    name: string | null;
    category: string | null;
    shortDescription: string | null;
    links: Array<string | null> | null;
    configuration: any | null;
    logo: { __typename: "Asset"; url: string | null } | null;
    longDescription: {
        __typename: "IntegrationLongDescription";
        json: any;
    } | null;
    about: { __typename: "IntegrationAbout"; json: any } | null;
};

export type FindCatalogIntegrationsQueryVariables = Exact<{
    whereIntegrations?: InputMaybe<IntegrationFilter>;
}>;

export type FindCatalogIntegrationsQuery = {
    integrationCollection: {
        __typename: "IntegrationCollection";
        items: Array<{
            __typename: "Integration";
            displayName: string | null;
            name: string | null;
            category: string | null;
            shortDescription: string | null;
            links: Array<string | null> | null;
            configuration: any | null;
            logo: { __typename: "Asset"; url: string | null } | null;
            longDescription: {
                __typename: "IntegrationLongDescription";
                json: any;
            } | null;
            about: { __typename: "IntegrationAbout"; json: any } | null;
        } | null>;
    } | null;
};

export type KeyboardFragment = {
    __typename: "Keyboard";
    brand: string | null;
    name: string | null;
    compatibility: string | null;
    numPad: boolean | null;
    connectivity: Array<string | null> | null;
    price24: number | null;
    price36: number | null;
    price48: number | null;
    companyCoefficient: any | null;
    shippingDelay: number | null;
    sys: { __typename: "Sys"; id: string };
    image: {
        __typename: "Asset";
        url: string | null;
        title: string | null;
    } | null;
    description: { __typename: "KeyboardDescription"; json: any } | null;
};

export type FindKeyboardsQueryVariables = Exact<{
    whereKeyboards?: InputMaybe<KeyboardFilter>;
}>;

export type FindKeyboardsQuery = {
    keyboardCollection: {
        __typename: "KeyboardCollection";
        items: Array<{
            __typename: "Keyboard";
            brand: string | null;
            name: string | null;
            compatibility: string | null;
            numPad: boolean | null;
            connectivity: Array<string | null> | null;
            price24: number | null;
            price36: number | null;
            price48: number | null;
            companyCoefficient: any | null;
            shippingDelay: number | null;
            sys: { __typename: "Sys"; id: string };
            image: {
                __typename: "Asset";
                url: string | null;
                title: string | null;
            } | null;
            description: {
                __typename: "KeyboardDescription";
                json: any;
            } | null;
        } | null>;
    } | null;
};

export type MouseFragment = {
    __typename: "Mouse";
    name: string | null;
    brand: string | null;
    type: string | null;
    power: string | null;
    compatibility: string | null;
    connectivity: Array<string | null> | null;
    price24: number | null;
    price36: number | null;
    price48: number | null;
    companyCoefficient: any | null;
    shippingDelay: number | null;
    sys: { __typename: "Sys"; id: string };
    image: {
        __typename: "Asset";
        url: string | null;
        title: string | null;
    } | null;
    description: { __typename: "MouseDescription"; json: any } | null;
};

export type FindMousesQueryVariables = Exact<{
    whereMouse?: InputMaybe<MouseFilter>;
}>;

export type FindMousesQuery = {
    mouseCollection: {
        __typename: "MouseCollection";
        items: Array<{
            __typename: "Mouse";
            name: string | null;
            brand: string | null;
            type: string | null;
            power: string | null;
            compatibility: string | null;
            connectivity: Array<string | null> | null;
            price24: number | null;
            price36: number | null;
            price48: number | null;
            companyCoefficient: any | null;
            shippingDelay: number | null;
            sys: { __typename: "Sys"; id: string };
            image: {
                __typename: "Asset";
                url: string | null;
                title: string | null;
            } | null;
            description: { __typename: "MouseDescription"; json: any } | null;
        } | null>;
    } | null;
};

export type FindPageContentQueryVariables = Exact<{
    wherePageContent?: InputMaybe<PageContentFilter>;
}>;

export type FindPageContentQuery = {
    pageContentCollection: {
        __typename: "PageContentCollection";
        items: Array<{
            __typename: "PageContent";
            name: string | null;
            slug: string | null;
            helpUrls: Array<string | null> | null;
            helpUrlsJson: any | null;
        } | null>;
    } | null;
};

export type ProductFragment = {
    __typename: "Product";
    name: string | null;
    os: string | null;
    osDescription: string | null;
    screenSizeDescription: string | null;
    keyboardBacklight: boolean | null;
    shortDescription: string | null;
    fingerPrintReader: boolean | null;
    height: number | null;
    width: number | null;
    thickness: number | null;
    screenSizeValue: number | null;
    weight: number | null;
    repairability: number | null;
    catalog: Array<string | null> | null;
    sys: { __typename: "Sys"; id: string };
    moreDetails: { __typename: "ProductMoreDetails"; json: any } | null;
    longDescription: { __typename: "ProductLongDescription"; json: any } | null;
    image: {
        __typename: "Asset";
        url: string | null;
        title: string | null;
    } | null;
    suggestedAccessoriesCollection: {
        __typename: "ProductSuggestedAccessoriesCollection";
        items: Array<
            | {
                  __typename: "Audio";
                  name: string | null;
                  type: string | null;
                  noiseCancellation: boolean | null;
                  connectivity: Array<string | null> | null;
                  price24: number | null;
                  price36: number | null;
                  price48: number | null;
                  companyCoefficient: any | null;
                  shippingDelay: number | null;
                  sys: { __typename: "Sys"; id: string };
                  image: {
                      __typename: "Asset";
                      url: string | null;
                      title: string | null;
                  } | null;
                  description: {
                      __typename: "AudioDescription";
                      json: any;
                  } | null;
              }
            | {
                  __typename: "Dockstation";
                  name: string | null;
                  compatibility: string | null;
                  connection: string | null;
                  connectivity: Array<string | null> | null;
                  price24: number | null;
                  price36: number | null;
                  price48: number | null;
                  companyCoefficient: any | null;
                  shippingDelay: number | null;
                  sys: { __typename: "Sys"; id: string };
                  image: {
                      __typename: "Asset";
                      url: string | null;
                      title: string | null;
                  } | null;
                  description: {
                      __typename: "DockstationDescription";
                      json: any;
                  } | null;
              }
            | {
                  __typename: "Keyboard";
                  brand: string | null;
                  name: string | null;
                  compatibility: string | null;
                  numPad: boolean | null;
                  connectivity: Array<string | null> | null;
                  price24: number | null;
                  price36: number | null;
                  price48: number | null;
                  companyCoefficient: any | null;
                  shippingDelay: number | null;
                  sys: { __typename: "Sys"; id: string };
                  image: {
                      __typename: "Asset";
                      url: string | null;
                      title: string | null;
                  } | null;
                  description: {
                      __typename: "KeyboardDescription";
                      json: any;
                  } | null;
              }
            | {
                  __typename: "Mouse";
                  name: string | null;
                  brand: string | null;
                  type: string | null;
                  power: string | null;
                  compatibility: string | null;
                  connectivity: Array<string | null> | null;
                  price24: number | null;
                  price36: number | null;
                  price48: number | null;
                  companyCoefficient: any | null;
                  shippingDelay: number | null;
                  sys: { __typename: "Sys"; id: string };
                  image: {
                      __typename: "Asset";
                      url: string | null;
                      title: string | null;
                  } | null;
                  description: {
                      __typename: "MouseDescription";
                      json: any;
                  } | null;
              }
            | {
                  __typename: "Screen";
                  name: string | null;
                  brand: string | null;
                  size: number | null;
                  resolution: string | null;
                  refreshRate: number | null;
                  integratedCamera: boolean | null;
                  connectivity: Array<string | null> | null;
                  price24: number | null;
                  price36: number | null;
                  price48: number | null;
                  companyCoefficient: any | null;
                  shippingDelay: number | null;
                  sys: { __typename: "Sys"; id: string };
                  image: {
                      __typename: "Asset";
                      url: string | null;
                      title: string | null;
                  } | null;
                  description: {
                      __typename: "ScreenDescription";
                      json: any;
                  } | null;
              }
            | null
        >;
    } | null;
};

export type FindProductsQueryVariables = Exact<{
    whereProduct?: InputMaybe<ProductFilter>;
}>;

export type FindProductsQuery = {
    productCollection: {
        __typename: "ProductCollection";
        items: Array<{
            __typename: "Product";
            name: string | null;
            os: string | null;
            osDescription: string | null;
            screenSizeDescription: string | null;
            keyboardBacklight: boolean | null;
            shortDescription: string | null;
            fingerPrintReader: boolean | null;
            height: number | null;
            width: number | null;
            thickness: number | null;
            screenSizeValue: number | null;
            weight: number | null;
            repairability: number | null;
            catalog: Array<string | null> | null;
            sys: { __typename: "Sys"; id: string };
            moreDetails: { __typename: "ProductMoreDetails"; json: any } | null;
            longDescription: {
                __typename: "ProductLongDescription";
                json: any;
            } | null;
            image: {
                __typename: "Asset";
                url: string | null;
                title: string | null;
            } | null;
            suggestedAccessoriesCollection: {
                __typename: "ProductSuggestedAccessoriesCollection";
                items: Array<
                    | {
                          __typename: "Audio";
                          name: string | null;
                          type: string | null;
                          noiseCancellation: boolean | null;
                          connectivity: Array<string | null> | null;
                          price24: number | null;
                          price36: number | null;
                          price48: number | null;
                          companyCoefficient: any | null;
                          shippingDelay: number | null;
                          sys: { __typename: "Sys"; id: string };
                          image: {
                              __typename: "Asset";
                              url: string | null;
                              title: string | null;
                          } | null;
                          description: {
                              __typename: "AudioDescription";
                              json: any;
                          } | null;
                      }
                    | {
                          __typename: "Dockstation";
                          name: string | null;
                          compatibility: string | null;
                          connection: string | null;
                          connectivity: Array<string | null> | null;
                          price24: number | null;
                          price36: number | null;
                          price48: number | null;
                          companyCoefficient: any | null;
                          shippingDelay: number | null;
                          sys: { __typename: "Sys"; id: string };
                          image: {
                              __typename: "Asset";
                              url: string | null;
                              title: string | null;
                          } | null;
                          description: {
                              __typename: "DockstationDescription";
                              json: any;
                          } | null;
                      }
                    | {
                          __typename: "Keyboard";
                          brand: string | null;
                          name: string | null;
                          compatibility: string | null;
                          numPad: boolean | null;
                          connectivity: Array<string | null> | null;
                          price24: number | null;
                          price36: number | null;
                          price48: number | null;
                          companyCoefficient: any | null;
                          shippingDelay: number | null;
                          sys: { __typename: "Sys"; id: string };
                          image: {
                              __typename: "Asset";
                              url: string | null;
                              title: string | null;
                          } | null;
                          description: {
                              __typename: "KeyboardDescription";
                              json: any;
                          } | null;
                      }
                    | {
                          __typename: "Mouse";
                          name: string | null;
                          brand: string | null;
                          type: string | null;
                          power: string | null;
                          compatibility: string | null;
                          connectivity: Array<string | null> | null;
                          price24: number | null;
                          price36: number | null;
                          price48: number | null;
                          companyCoefficient: any | null;
                          shippingDelay: number | null;
                          sys: { __typename: "Sys"; id: string };
                          image: {
                              __typename: "Asset";
                              url: string | null;
                              title: string | null;
                          } | null;
                          description: {
                              __typename: "MouseDescription";
                              json: any;
                          } | null;
                      }
                    | {
                          __typename: "Screen";
                          name: string | null;
                          brand: string | null;
                          size: number | null;
                          resolution: string | null;
                          refreshRate: number | null;
                          integratedCamera: boolean | null;
                          connectivity: Array<string | null> | null;
                          price24: number | null;
                          price36: number | null;
                          price48: number | null;
                          companyCoefficient: any | null;
                          shippingDelay: number | null;
                          sys: { __typename: "Sys"; id: string };
                          image: {
                              __typename: "Asset";
                              url: string | null;
                              title: string | null;
                          } | null;
                          description: {
                              __typename: "ScreenDescription";
                              json: any;
                          } | null;
                      }
                    | null
                >;
            } | null;
        } | null>;
    } | null;
};

export type ScreenFragment = {
    __typename: "Screen";
    name: string | null;
    brand: string | null;
    size: number | null;
    resolution: string | null;
    refreshRate: number | null;
    integratedCamera: boolean | null;
    connectivity: Array<string | null> | null;
    price24: number | null;
    price36: number | null;
    price48: number | null;
    companyCoefficient: any | null;
    shippingDelay: number | null;
    sys: { __typename: "Sys"; id: string };
    image: {
        __typename: "Asset";
        url: string | null;
        title: string | null;
    } | null;
    description: { __typename: "ScreenDescription"; json: any } | null;
};

export type FindScreensQueryVariables = Exact<{
    whereScreen?: InputMaybe<ScreenFilter>;
}>;

export type FindScreensQuery = {
    screenCollection: {
        __typename: "ScreenCollection";
        items: Array<{
            __typename: "Screen";
            name: string | null;
            brand: string | null;
            size: number | null;
            resolution: string | null;
            refreshRate: number | null;
            integratedCamera: boolean | null;
            connectivity: Array<string | null> | null;
            price24: number | null;
            price36: number | null;
            price48: number | null;
            companyCoefficient: any | null;
            shippingDelay: number | null;
            sys: { __typename: "Sys"; id: string };
            image: {
                __typename: "Asset";
                url: string | null;
                title: string | null;
            } | null;
            description: { __typename: "ScreenDescription"; json: any } | null;
        } | null>;
    } | null;
};

export type VariantProductFragment = {
    __typename: "Product";
    name: string | null;
    os: string | null;
    screenSizeValue: number | null;
    type: string | null;
    sys: { __typename: "Sys"; id: string };
    image: {
        __typename: "Asset";
        url: string | null;
        title: string | null;
    } | null;
};

export type VariantFragment = {
    __typename: "Variant";
    name: string | null;
    storageValue: number | null;
    storageDescription: string | null;
    processorValue: string | null;
    processorDescription: string | null;
    graphicsCardValue: number | null;
    graphicsCardDescription: string | null;
    ramValue: number | null;
    ramDescription: string | null;
    price24: number | null;
    price36: number | null;
    price48: number | null;
    companyCoefficient: any | null;
    usageTypes: Array<string | null> | null;
    shippingDelay: number | null;
    sys: { __typename: "Sys"; id: string };
    product: {
        __typename: "Product";
        name: string | null;
        os: string | null;
        screenSizeValue: number | null;
        type: string | null;
        sys: { __typename: "Sys"; id: string };
        image: {
            __typename: "Asset";
            url: string | null;
            title: string | null;
        } | null;
    } | null;
    moreDetails: { __typename: "VariantMoreDetails"; json: any } | null;
};

export type FindVariantsQueryVariables = Exact<{
    whereVariant?: InputMaybe<VariantFilter>;
}>;

export type FindVariantsQuery = {
    variantCollection: {
        __typename: "VariantCollection";
        items: Array<{
            __typename: "Variant";
            name: string | null;
            storageValue: number | null;
            storageDescription: string | null;
            processorValue: string | null;
            processorDescription: string | null;
            graphicsCardValue: number | null;
            graphicsCardDescription: string | null;
            ramValue: number | null;
            ramDescription: string | null;
            price24: number | null;
            price36: number | null;
            price48: number | null;
            companyCoefficient: any | null;
            usageTypes: Array<string | null> | null;
            shippingDelay: number | null;
            sys: { __typename: "Sys"; id: string };
            product: {
                __typename: "Product";
                name: string | null;
                os: string | null;
                screenSizeValue: number | null;
                type: string | null;
                sys: { __typename: "Sys"; id: string };
                image: {
                    __typename: "Asset";
                    url: string | null;
                    title: string | null;
                } | null;
            } | null;
            moreDetails: { __typename: "VariantMoreDetails"; json: any } | null;
        } | null>;
    } | null;
};

export type EntryCollectionFragment = {
    __typename: "EntryCollection";
    total: number;
    items: Array<
        | { __typename: "Article"; sys: { __typename: "Sys"; id: string } }
        | { __typename: "Audio"; sys: { __typename: "Sys"; id: string } }
        | { __typename: "Changelog"; sys: { __typename: "Sys"; id: string } }
        | { __typename: "Dockstation"; sys: { __typename: "Sys"; id: string } }
        | {
              __typename: "FeatureFlagConfiguration";
              sys: { __typename: "Sys"; id: string };
          }
        | { __typename: "Integration"; sys: { __typename: "Sys"; id: string } }
        | { __typename: "Keyboard"; sys: { __typename: "Sys"; id: string } }
        | { __typename: "Mouse"; sys: { __typename: "Sys"; id: string } }
        | { __typename: "PageContent"; sys: { __typename: "Sys"; id: string } }
        | { __typename: "Product"; sys: { __typename: "Sys"; id: string } }
        | { __typename: "Screen"; sys: { __typename: "Sys"; id: string } }
        | { __typename: "Variant"; sys: { __typename: "Sys"; id: string } }
        | null
    >;
};

export type GetTotalEntriesByIdQueryVariables = Exact<{
    id_in: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type GetTotalEntriesByIdQuery = {
    entryCollection: {
        __typename: "EntryCollection";
        total: number;
        items: Array<
            | { __typename: "Article"; sys: { __typename: "Sys"; id: string } }
            | { __typename: "Audio"; sys: { __typename: "Sys"; id: string } }
            | {
                  __typename: "Changelog";
                  sys: { __typename: "Sys"; id: string };
              }
            | {
                  __typename: "Dockstation";
                  sys: { __typename: "Sys"; id: string };
              }
            | {
                  __typename: "FeatureFlagConfiguration";
                  sys: { __typename: "Sys"; id: string };
              }
            | {
                  __typename: "Integration";
                  sys: { __typename: "Sys"; id: string };
              }
            | { __typename: "Keyboard"; sys: { __typename: "Sys"; id: string } }
            | { __typename: "Mouse"; sys: { __typename: "Sys"; id: string } }
            | {
                  __typename: "PageContent";
                  sys: { __typename: "Sys"; id: string };
              }
            | { __typename: "Product"; sys: { __typename: "Sys"; id: string } }
            | { __typename: "Screen"; sys: { __typename: "Sys"; id: string } }
            | { __typename: "Variant"; sys: { __typename: "Sys"; id: string } }
            | null
        >;
    } | null;
};

export const FeatureFlagsFragmentDoc = gql`
    fragment FeatureFlags on FeatureFlagConfiguration {
        sys {
            id
        }
        id
        helpWidget
        integrationsPage
        nextCatalog
    }
`;
export const CatalogIntegrationFragmentDoc = gql`
    fragment CatalogIntegration on Integration {
        displayName
        name
        logo {
            url
        }
        category
        shortDescription
        longDescription {
            json
        }
        about {
            json
        }
        links
        configuration
    }
`;
export const CatalogImageFragmentDoc = gql`
    fragment CatalogImage on Asset {
        url
        title
    }
`;
export const AudioAccessoryFragmentDoc = gql`
    fragment AudioAccessory on Audio {
        sys {
            id
        }
        name
        image {
            ...CatalogImage
        }
        description {
            json
        }
        type
        noiseCancellation
        connectivity
        price24
        price36
        price48
        companyCoefficient
        shippingDelay
    }
    ${CatalogImageFragmentDoc}
`;
export const DockstationFragmentDoc = gql`
    fragment Dockstation on Dockstation {
        sys {
            id
        }
        name
        image {
            url
            title
        }
        description {
            json
        }
        compatibility
        connection
        connectivity
        price24
        price36
        price48
        companyCoefficient
        shippingDelay
    }
`;
export const KeyboardFragmentDoc = gql`
    fragment Keyboard on Keyboard {
        sys {
            id
        }
        brand
        name
        image {
            url
            title
        }
        description {
            json
        }
        compatibility
        numPad
        connectivity
        price24
        price36
        price48
        companyCoefficient
        shippingDelay
    }
`;
export const MouseFragmentDoc = gql`
    fragment Mouse on Mouse {
        sys {
            id
        }
        name
        brand
        image {
            url
            title
        }
        description {
            json
        }
        type
        power
        compatibility
        connectivity
        price24
        price36
        price48
        companyCoefficient
        shippingDelay
    }
`;
export const ScreenFragmentDoc = gql`
    fragment Screen on Screen {
        sys {
            id
        }
        name
        brand
        image {
            url
            title
        }
        description {
            json
        }
        size
        resolution
        refreshRate
        integratedCamera
        connectivity
        price24
        price36
        price48
        companyCoefficient
        shippingDelay
    }
`;
export const ProductFragmentDoc = gql`
    fragment Product on Product {
        sys {
            id
        }
        name
        os
        osDescription
        screenSizeDescription
        keyboardBacklight
        shortDescription
        fingerPrintReader
        height
        width
        thickness
        screenSizeValue
        weight
        repairability
        moreDetails {
            json
        }
        longDescription {
            json
        }
        image {
            ...CatalogImage
        }
        catalog
        suggestedAccessoriesCollection {
            items {
                ... on Audio {
                    ...AudioAccessory
                }
                ... on Dockstation {
                    ...Dockstation
                }
                ... on Keyboard {
                    ...Keyboard
                }
                ... on Mouse {
                    ...Mouse
                }
                ... on Screen {
                    ...Screen
                }
            }
        }
    }
    ${CatalogImageFragmentDoc}
    ${AudioAccessoryFragmentDoc}
    ${DockstationFragmentDoc}
    ${KeyboardFragmentDoc}
    ${MouseFragmentDoc}
    ${ScreenFragmentDoc}
`;
export const VariantProductFragmentDoc = gql`
    fragment VariantProduct on Product {
        sys {
            id
        }
        name
        os
        screenSizeValue
        type
        image {
            ...CatalogImage
        }
    }
    ${CatalogImageFragmentDoc}
`;
export const VariantFragmentDoc = gql`
    fragment Variant on Variant {
        sys {
            id
        }
        name
        storageValue
        storageDescription
        processorValue
        processorDescription
        graphicsCardValue
        graphicsCardDescription
        ramValue
        ramDescription
        price24
        price36
        price48
        companyCoefficient
        usageTypes
        product {
            ...VariantProduct
        }
        moreDetails {
            json
        }
        shippingDelay
    }
    ${VariantProductFragmentDoc}
`;
export const EntryCollectionFragmentDoc = gql`
    fragment EntryCollection on EntryCollection {
        total
        items {
            sys {
                id
            }
        }
    }
`;
export const FindAudioAccessoriesDocument = gql`
    query FindAudioAccessories($whereAudioAccessories: AudioFilter) {
        audioCollection(where: $whereAudioAccessories) {
            items {
                ...AudioAccessory
            }
        }
    }
    ${AudioAccessoryFragmentDoc}
`;

/**
 * __useFindAudioAccessoriesQuery__
 *
 * To run a query within a React component, call `useFindAudioAccessoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAudioAccessoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAudioAccessoriesQuery({
 *   variables: {
 *      whereAudioAccessories: // value for 'whereAudioAccessories'
 *   },
 * });
 */
export function useFindAudioAccessoriesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindAudioAccessoriesQuery,
        FindAudioAccessoriesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FindAudioAccessoriesQuery,
        FindAudioAccessoriesQueryVariables
    >(FindAudioAccessoriesDocument, options);
}
export function useFindAudioAccessoriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindAudioAccessoriesQuery,
        FindAudioAccessoriesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindAudioAccessoriesQuery,
        FindAudioAccessoriesQueryVariables
    >(FindAudioAccessoriesDocument, options);
}
export type FindAudioAccessoriesQueryHookResult = ReturnType<
    typeof useFindAudioAccessoriesQuery
>;
export type FindAudioAccessoriesLazyQueryHookResult = ReturnType<
    typeof useFindAudioAccessoriesLazyQuery
>;
export type FindAudioAccessoriesQueryResult = Apollo.QueryResult<
    FindAudioAccessoriesQuery,
    FindAudioAccessoriesQueryVariables
>;
export function refetchFindAudioAccessoriesQuery(
    variables?: FindAudioAccessoriesQueryVariables,
) {
    return { query: FindAudioAccessoriesDocument, variables: variables };
}
export const FindChangelogDocument = gql`
    query FindChangelog {
        changelogCollection {
            items {
                title
                shortDescription
                content {
                    json
                }
            }
        }
    }
`;

/**
 * __useFindChangelogQuery__
 *
 * To run a query within a React component, call `useFindChangelogQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindChangelogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindChangelogQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindChangelogQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindChangelogQuery,
        FindChangelogQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindChangelogQuery, FindChangelogQueryVariables>(
        FindChangelogDocument,
        options,
    );
}
export function useFindChangelogLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindChangelogQuery,
        FindChangelogQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindChangelogQuery, FindChangelogQueryVariables>(
        FindChangelogDocument,
        options,
    );
}
export type FindChangelogQueryHookResult = ReturnType<
    typeof useFindChangelogQuery
>;
export type FindChangelogLazyQueryHookResult = ReturnType<
    typeof useFindChangelogLazyQuery
>;
export type FindChangelogQueryResult = Apollo.QueryResult<
    FindChangelogQuery,
    FindChangelogQueryVariables
>;
export function refetchFindChangelogQuery(
    variables?: FindChangelogQueryVariables,
) {
    return { query: FindChangelogDocument, variables: variables };
}
export const FindDockstationsDocument = gql`
    query FindDockstations($whereDockstations: DockstationFilter) {
        dockstationCollection(where: $whereDockstations) {
            items {
                ...Dockstation
            }
        }
    }
    ${DockstationFragmentDoc}
`;

/**
 * __useFindDockstationsQuery__
 *
 * To run a query within a React component, call `useFindDockstationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDockstationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDockstationsQuery({
 *   variables: {
 *      whereDockstations: // value for 'whereDockstations'
 *   },
 * });
 */
export function useFindDockstationsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindDockstationsQuery,
        FindDockstationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FindDockstationsQuery,
        FindDockstationsQueryVariables
    >(FindDockstationsDocument, options);
}
export function useFindDockstationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindDockstationsQuery,
        FindDockstationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindDockstationsQuery,
        FindDockstationsQueryVariables
    >(FindDockstationsDocument, options);
}
export type FindDockstationsQueryHookResult = ReturnType<
    typeof useFindDockstationsQuery
>;
export type FindDockstationsLazyQueryHookResult = ReturnType<
    typeof useFindDockstationsLazyQuery
>;
export type FindDockstationsQueryResult = Apollo.QueryResult<
    FindDockstationsQuery,
    FindDockstationsQueryVariables
>;
export function refetchFindDockstationsQuery(
    variables?: FindDockstationsQueryVariables,
) {
    return { query: FindDockstationsDocument, variables: variables };
}
export const FindFeatureFlagsDocument = gql`
    query FindFeatureFlags($where: FeatureFlagConfigurationFilter) {
        featureFlagConfigurationCollection(where: $where) {
            items {
                ...FeatureFlags
            }
        }
    }
    ${FeatureFlagsFragmentDoc}
`;

/**
 * __useFindFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useFindFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFeatureFlagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFeatureFlagsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindFeatureFlagsQuery,
        FindFeatureFlagsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FindFeatureFlagsQuery,
        FindFeatureFlagsQueryVariables
    >(FindFeatureFlagsDocument, options);
}
export function useFindFeatureFlagsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindFeatureFlagsQuery,
        FindFeatureFlagsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindFeatureFlagsQuery,
        FindFeatureFlagsQueryVariables
    >(FindFeatureFlagsDocument, options);
}
export type FindFeatureFlagsQueryHookResult = ReturnType<
    typeof useFindFeatureFlagsQuery
>;
export type FindFeatureFlagsLazyQueryHookResult = ReturnType<
    typeof useFindFeatureFlagsLazyQuery
>;
export type FindFeatureFlagsQueryResult = Apollo.QueryResult<
    FindFeatureFlagsQuery,
    FindFeatureFlagsQueryVariables
>;
export function refetchFindFeatureFlagsQuery(
    variables?: FindFeatureFlagsQueryVariables,
) {
    return { query: FindFeatureFlagsDocument, variables: variables };
}
export const FindCatalogIntegrationsDocument = gql`
    query FindCatalogIntegrations($whereIntegrations: IntegrationFilter) {
        integrationCollection(where: $whereIntegrations) {
            items {
                ...CatalogIntegration
            }
        }
    }
    ${CatalogIntegrationFragmentDoc}
`;

/**
 * __useFindCatalogIntegrationsQuery__
 *
 * To run a query within a React component, call `useFindCatalogIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCatalogIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCatalogIntegrationsQuery({
 *   variables: {
 *      whereIntegrations: // value for 'whereIntegrations'
 *   },
 * });
 */
export function useFindCatalogIntegrationsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindCatalogIntegrationsQuery,
        FindCatalogIntegrationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FindCatalogIntegrationsQuery,
        FindCatalogIntegrationsQueryVariables
    >(FindCatalogIntegrationsDocument, options);
}
export function useFindCatalogIntegrationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindCatalogIntegrationsQuery,
        FindCatalogIntegrationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindCatalogIntegrationsQuery,
        FindCatalogIntegrationsQueryVariables
    >(FindCatalogIntegrationsDocument, options);
}
export type FindCatalogIntegrationsQueryHookResult = ReturnType<
    typeof useFindCatalogIntegrationsQuery
>;
export type FindCatalogIntegrationsLazyQueryHookResult = ReturnType<
    typeof useFindCatalogIntegrationsLazyQuery
>;
export type FindCatalogIntegrationsQueryResult = Apollo.QueryResult<
    FindCatalogIntegrationsQuery,
    FindCatalogIntegrationsQueryVariables
>;
export function refetchFindCatalogIntegrationsQuery(
    variables?: FindCatalogIntegrationsQueryVariables,
) {
    return { query: FindCatalogIntegrationsDocument, variables: variables };
}
export const FindKeyboardsDocument = gql`
    query FindKeyboards($whereKeyboards: KeyboardFilter) {
        keyboardCollection(where: $whereKeyboards) {
            items {
                ...Keyboard
            }
        }
    }
    ${KeyboardFragmentDoc}
`;

/**
 * __useFindKeyboardsQuery__
 *
 * To run a query within a React component, call `useFindKeyboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindKeyboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindKeyboardsQuery({
 *   variables: {
 *      whereKeyboards: // value for 'whereKeyboards'
 *   },
 * });
 */
export function useFindKeyboardsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindKeyboardsQuery,
        FindKeyboardsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindKeyboardsQuery, FindKeyboardsQueryVariables>(
        FindKeyboardsDocument,
        options,
    );
}
export function useFindKeyboardsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindKeyboardsQuery,
        FindKeyboardsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindKeyboardsQuery, FindKeyboardsQueryVariables>(
        FindKeyboardsDocument,
        options,
    );
}
export type FindKeyboardsQueryHookResult = ReturnType<
    typeof useFindKeyboardsQuery
>;
export type FindKeyboardsLazyQueryHookResult = ReturnType<
    typeof useFindKeyboardsLazyQuery
>;
export type FindKeyboardsQueryResult = Apollo.QueryResult<
    FindKeyboardsQuery,
    FindKeyboardsQueryVariables
>;
export function refetchFindKeyboardsQuery(
    variables?: FindKeyboardsQueryVariables,
) {
    return { query: FindKeyboardsDocument, variables: variables };
}
export const FindMousesDocument = gql`
    query FindMouses($whereMouse: MouseFilter) {
        mouseCollection(where: $whereMouse) {
            items {
                ...Mouse
            }
        }
    }
    ${MouseFragmentDoc}
`;

/**
 * __useFindMousesQuery__
 *
 * To run a query within a React component, call `useFindMousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMousesQuery({
 *   variables: {
 *      whereMouse: // value for 'whereMouse'
 *   },
 * });
 */
export function useFindMousesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindMousesQuery,
        FindMousesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindMousesQuery, FindMousesQueryVariables>(
        FindMousesDocument,
        options,
    );
}
export function useFindMousesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindMousesQuery,
        FindMousesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindMousesQuery, FindMousesQueryVariables>(
        FindMousesDocument,
        options,
    );
}
export type FindMousesQueryHookResult = ReturnType<typeof useFindMousesQuery>;
export type FindMousesLazyQueryHookResult = ReturnType<
    typeof useFindMousesLazyQuery
>;
export type FindMousesQueryResult = Apollo.QueryResult<
    FindMousesQuery,
    FindMousesQueryVariables
>;
export function refetchFindMousesQuery(variables?: FindMousesQueryVariables) {
    return { query: FindMousesDocument, variables: variables };
}
export const FindPageContentDocument = gql`
    query FindPageContent($wherePageContent: PageContentFilter) {
        pageContentCollection(where: $wherePageContent) {
            items {
                name
                slug
                helpUrls
                helpUrlsJson
            }
        }
    }
`;

/**
 * __useFindPageContentQuery__
 *
 * To run a query within a React component, call `useFindPageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPageContentQuery({
 *   variables: {
 *      wherePageContent: // value for 'wherePageContent'
 *   },
 * });
 */
export function useFindPageContentQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindPageContentQuery,
        FindPageContentQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindPageContentQuery, FindPageContentQueryVariables>(
        FindPageContentDocument,
        options,
    );
}
export function useFindPageContentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindPageContentQuery,
        FindPageContentQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindPageContentQuery,
        FindPageContentQueryVariables
    >(FindPageContentDocument, options);
}
export type FindPageContentQueryHookResult = ReturnType<
    typeof useFindPageContentQuery
>;
export type FindPageContentLazyQueryHookResult = ReturnType<
    typeof useFindPageContentLazyQuery
>;
export type FindPageContentQueryResult = Apollo.QueryResult<
    FindPageContentQuery,
    FindPageContentQueryVariables
>;
export function refetchFindPageContentQuery(
    variables?: FindPageContentQueryVariables,
) {
    return { query: FindPageContentDocument, variables: variables };
}
export const FindProductsDocument = gql`
    query FindProducts($whereProduct: ProductFilter) {
        productCollection(where: $whereProduct, limit: 1000) {
            items {
                ...Product
            }
        }
    }
    ${ProductFragmentDoc}
`;

/**
 * __useFindProductsQuery__
 *
 * To run a query within a React component, call `useFindProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductsQuery({
 *   variables: {
 *      whereProduct: // value for 'whereProduct'
 *   },
 * });
 */
export function useFindProductsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindProductsQuery,
        FindProductsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindProductsQuery, FindProductsQueryVariables>(
        FindProductsDocument,
        options,
    );
}
export function useFindProductsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindProductsQuery,
        FindProductsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindProductsQuery, FindProductsQueryVariables>(
        FindProductsDocument,
        options,
    );
}
export type FindProductsQueryHookResult = ReturnType<
    typeof useFindProductsQuery
>;
export type FindProductsLazyQueryHookResult = ReturnType<
    typeof useFindProductsLazyQuery
>;
export type FindProductsQueryResult = Apollo.QueryResult<
    FindProductsQuery,
    FindProductsQueryVariables
>;
export function refetchFindProductsQuery(
    variables?: FindProductsQueryVariables,
) {
    return { query: FindProductsDocument, variables: variables };
}
export const FindScreensDocument = gql`
    query FindScreens($whereScreen: ScreenFilter) {
        screenCollection(where: $whereScreen) {
            items {
                ...Screen
            }
        }
    }
    ${ScreenFragmentDoc}
`;

/**
 * __useFindScreensQuery__
 *
 * To run a query within a React component, call `useFindScreensQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScreensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScreensQuery({
 *   variables: {
 *      whereScreen: // value for 'whereScreen'
 *   },
 * });
 */
export function useFindScreensQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindScreensQuery,
        FindScreensQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindScreensQuery, FindScreensQueryVariables>(
        FindScreensDocument,
        options,
    );
}
export function useFindScreensLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindScreensQuery,
        FindScreensQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindScreensQuery, FindScreensQueryVariables>(
        FindScreensDocument,
        options,
    );
}
export type FindScreensQueryHookResult = ReturnType<typeof useFindScreensQuery>;
export type FindScreensLazyQueryHookResult = ReturnType<
    typeof useFindScreensLazyQuery
>;
export type FindScreensQueryResult = Apollo.QueryResult<
    FindScreensQuery,
    FindScreensQueryVariables
>;
export function refetchFindScreensQuery(variables?: FindScreensQueryVariables) {
    return { query: FindScreensDocument, variables: variables };
}
export const FindVariantsDocument = gql`
    query FindVariants($whereVariant: VariantFilter) {
        variantCollection(where: $whereVariant, limit: 1000) {
            items {
                ...Variant
            }
        }
    }
    ${VariantFragmentDoc}
`;

/**
 * __useFindVariantsQuery__
 *
 * To run a query within a React component, call `useFindVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindVariantsQuery({
 *   variables: {
 *      whereVariant: // value for 'whereVariant'
 *   },
 * });
 */
export function useFindVariantsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindVariantsQuery,
        FindVariantsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindVariantsQuery, FindVariantsQueryVariables>(
        FindVariantsDocument,
        options,
    );
}
export function useFindVariantsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindVariantsQuery,
        FindVariantsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindVariantsQuery, FindVariantsQueryVariables>(
        FindVariantsDocument,
        options,
    );
}
export type FindVariantsQueryHookResult = ReturnType<
    typeof useFindVariantsQuery
>;
export type FindVariantsLazyQueryHookResult = ReturnType<
    typeof useFindVariantsLazyQuery
>;
export type FindVariantsQueryResult = Apollo.QueryResult<
    FindVariantsQuery,
    FindVariantsQueryVariables
>;
export function refetchFindVariantsQuery(
    variables?: FindVariantsQueryVariables,
) {
    return { query: FindVariantsDocument, variables: variables };
}
export const GetTotalEntriesByIdDocument = gql`
    query GetTotalEntriesById($id_in: [String!]!) {
        entryCollection(where: { sys: { id_in: $id_in } }) {
            ...EntryCollection
        }
    }
    ${EntryCollectionFragmentDoc}
`;

/**
 * __useGetTotalEntriesByIdQuery__
 *
 * To run a query within a React component, call `useGetTotalEntriesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalEntriesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalEntriesByIdQuery({
 *   variables: {
 *      id_in: // value for 'id_in'
 *   },
 * });
 */
export function useGetTotalEntriesByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetTotalEntriesByIdQuery,
        GetTotalEntriesByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetTotalEntriesByIdQuery,
        GetTotalEntriesByIdQueryVariables
    >(GetTotalEntriesByIdDocument, options);
}
export function useGetTotalEntriesByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTotalEntriesByIdQuery,
        GetTotalEntriesByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetTotalEntriesByIdQuery,
        GetTotalEntriesByIdQueryVariables
    >(GetTotalEntriesByIdDocument, options);
}
export type GetTotalEntriesByIdQueryHookResult = ReturnType<
    typeof useGetTotalEntriesByIdQuery
>;
export type GetTotalEntriesByIdLazyQueryHookResult = ReturnType<
    typeof useGetTotalEntriesByIdLazyQuery
>;
export type GetTotalEntriesByIdQueryResult = Apollo.QueryResult<
    GetTotalEntriesByIdQuery,
    GetTotalEntriesByIdQueryVariables
>;
export function refetchGetTotalEntriesByIdQuery(
    variables: GetTotalEntriesByIdQueryVariables,
) {
    return { query: GetTotalEntriesByIdDocument, variables: variables };
}
