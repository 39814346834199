import { StorageProducts } from "components/business/catalog/cart/utils";
import Product from "components/business/order/product/product";

import CartAction from "./cart-action";

class InitCart implements CartAction {
    defaultCart: Product[];

    constructor() {
        this.defaultCart = StorageProducts();
    }

    apply(_: Product[]) {
        return this.defaultCart;
    }
}

export default InitCart;
