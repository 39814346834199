import React, { useRef } from "react";

import { Dialog as HeadlessDialog } from "@headlessui/react";
import { useWindowSize } from "usehooks-ts";

import CrossIcon from "components/common/icons/CrossIcon";
import Button from "components/common/layout/Button/Button";

import styles from "./Dialog.module.scss";

interface DialogProps {
    open?: boolean;
    children: React.ReactNode;
    title: string;
    btnText?: string;
    onClose: () => void;
}

const Dialog = ({
    open = true,
    btnText = "",
    children,
    title,
    onClose,
}: DialogProps) => {
    const windowSize = useWindowSize();
    const ref = useRef(null);

    return (
        <HeadlessDialog
            as="div"
            open={open && windowSize.width > 1199}
            initialFocus={ref}
            onClose={onClose}
        >
            <div className={styles.container}>
                <div className={styles.dialog}>
                    <HeadlessDialog.Panel className={styles.content}>
                        <div className={styles.header}>
                            <HeadlessDialog.Title
                                as="h3"
                                className={styles.title}
                            >
                                {title}
                            </HeadlessDialog.Title>
                            <button
                                className={styles.closeButton}
                                onClick={onClose}
                            >
                                <CrossIcon />
                            </button>
                        </div>

                        <div className={styles.body}>{children}</div>
                        {btnText && (
                            <Button
                                style={{ alignSelf: "end" }}
                                type="button"
                                theme="primary"
                                onClick={onClose}
                            >
                                {btnText}
                            </Button>
                        )}
                    </HeadlessDialog.Panel>
                </div>
            </div>
        </HeadlessDialog>
    );
};

export default Dialog;
