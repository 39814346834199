import { ClearStorageProducts } from "components/business/catalog/cart/utils";
import Product from "components/business/order/product/product";

import CartAction from "./cart-action";

class ClearCartAction implements CartAction {
    private emptyCart: Product[] = [];

    apply(_: Product[]) {
        ClearStorageProducts();
        return this.emptyCart;
    }
}

export default ClearCartAction;
