import Filters, {
    FilterNames,
} from "components/business/catalog/filter/filters";

import CatalogFiltersAction from "./catalog-filters-action";

class SetCatalogFiltersAction implements CatalogFiltersAction {
    payload: {
        filterName: FilterNames;
        filter: string | number[];
    };

    constructor(filterName: FilterNames, filter: string | number[]) {
        this.payload = {
            filterName,
            filter,
        };
    }

    apply(catalogFilters: Filters) {
        let updatedCatalogFilters: Filters;
        if (this.payload.filterName === FilterNames.type) {
            updatedCatalogFilters = {
                storage: [],
                os: [],
                ram: [],
                type: [this.payload.filter as string],
                processor: [],
                screen: [],
                budget: [],
                searchTerm: "",
            };
        } else if (this.payload.filterName === FilterNames.budget) {
            updatedCatalogFilters = { ...catalogFilters };

            if (
                JSON.stringify(
                    updatedCatalogFilters[FilterNames.budget],
                ).includes(JSON.stringify(this.payload.filter))
            ) {
                updatedCatalogFilters[this.payload.filterName] =
                    updatedCatalogFilters[this.payload.filterName].filter(
                        (a) =>
                            JSON.stringify(a) !==
                            JSON.stringify(this.payload.filter),
                    );
            } else {
                updatedCatalogFilters[FilterNames.budget].push(
                    this.payload.filter as number[],
                );
            }
        } else if (this.payload.filterName === FilterNames.searchTerm) {
            updatedCatalogFilters = { ...catalogFilters };

            const filterValue = this.payload.filter as string;
            updatedCatalogFilters[FilterNames.searchTerm] = filterValue;
        } else {
            updatedCatalogFilters = { ...catalogFilters };
            if (
                !updatedCatalogFilters[this.payload.filterName].includes(
                    this.payload.filter as string,
                )
            ) {
                updatedCatalogFilters[this.payload.filterName] = [
                    ...updatedCatalogFilters[this.payload.filterName],
                    this.payload.filter,
                ] as string[];
            } else {
                updatedCatalogFilters[this.payload.filterName] =
                    updatedCatalogFilters[this.payload.filterName].filter(
                        (filter) => filter !== this.payload.filter,
                    );
            }
        }

        return updatedCatalogFilters;
    }
}

export default SetCatalogFiltersAction;
