import React from "react";

import styles from "./CardWithTitle.module.scss";

interface CardWithTitleProps {
    title: string;
    description: string;
    children: JSX.Element;
}

const CardWithTitle = (props: CardWithTitleProps) => (
    <div className={styles.card}>
        <div className={styles.header}>
            <h2 className={styles.title}>{props.title}</h2>
            <p className={styles.description}>{props.description}</p>
        </div>
        <div className={styles.body}>{props.children}</div>
    </div>
);

export default CardWithTitle;
