import React from "react";

import SkeletonDashboardEcodata from "components/business/dashboard/dashboard-ecodata/skeleton-dashboard-ecodata/skeleton-dashboard-ecodata";
import { InstanceDashboardFragment } from "graphql/management/model/apollo";

import DataCard from "./data-card/data-card";

import "./dashboard-eco-data.scss";

interface DashboardEcoDataProps {
    instanceDashboard: InstanceDashboardFragment | null | undefined;
    dashboardLoading: boolean;
}

export default function DashboardEcoData(props: DashboardEcoDataProps) {
    const devicesEcoData = props.instanceDashboard?.deviceEcoData;

    if (!devicesEcoData) {
        if (props.dashboardLoading) {
            return <SkeletonDashboardEcodata />;
        }
        return <></>;
    }

    const totalCarbonSavings =
        devicesEcoData.carbonSavings >= 1000
            ? `${(devicesEcoData.carbonSavings / 1000).toFixed(3)} t`
            : `${devicesEcoData.carbonSavings.toFixed(1)} kg`;

    const coffeeCarbonSavings = `${Math.floor(
        devicesEcoData.carbonSavings * 1.8,
    )} L de`;
    const planeCarbonSavings = `${Math.floor(
        devicesEcoData.carbonSavings * 4.4,
    )} km en`;

    const totalCarbonFootprint =
        devicesEcoData.carbonFootprint >= 1000
            ? `${(devicesEcoData.carbonFootprint / 1000).toFixed(3)} t`
            : `${devicesEcoData.carbonFootprint.toFixed(1)} kg`;

    const coffeeCarbonFootprint = `${Math.floor(
        devicesEcoData.carbonFootprint * 1.8,
    )} L de`;
    const planeCarbonFootprint = `${Math.floor(
        devicesEcoData.carbonFootprint * 4.4,
    )} km en`;

    return (
        <>
            {!props.dashboardLoading ? (
                <div className="custom-card dashboard-ecodata">
                    <div className="dashboard-ecodata__header">
                        <h2>L'empreinte carbone de vos équipements</h2>
                        <div className="dashboard-ecodata-more">
                            <a
                                href="https://cleaq.notion.site/M-thodologie-Simulateur-Carbone-989f4d8a22514417af9272c699594d82"
                                className="ecodata-more-button"
                                title="En savoir plus"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="icofont icofont-question-circle" />
                            </a>
                        </div>
                    </div>
                    <div className="dashboard-ecodata-row">
                        <div className="dashboard-ecodata-col">
                            <p className="dashboard-ecodata__category">
                                Fabrication et transport compris, votre flotte
                                Cleaq émet{" "}
                                <span
                                    data-cy="total-emission-co2"
                                    className="category__co2 category__emission-co2"
                                >
                                    {`${totalCarbonFootprint} CO`}
                                    <sub>2</sub>e
                                </span>
                            </p>
                            <div>
                                <p className="ecodata-paragraph">
                                    Cela représente :
                                </p>
                                <div className="ecodata-cards">
                                    <DataCard
                                        label={planeCarbonFootprint}
                                        data-cy="emission-plane"
                                        imageUrl="https://images.ctfassets.net/85xu1vrcoskd/3VJxPlgLz697FlofK1pwlV/97c74d52b625c1ea6a8c86f17939de40/ico-plane.png"
                                        emission
                                    />
                                    <DataCard
                                        label={coffeeCarbonFootprint}
                                        data-cy="emission-coffee"
                                        imageUrl="https://images.ctfassets.net/85xu1vrcoskd/6DeC5JZMNK10Tbm9AmTV1j/7540f0c8ee09faef428e41ca811fa956/ico-coffee.png"
                                        emission
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-ecodata-col">
                            <p className="dashboard-ecodata__category">
                                Grâce à l'offre Cleaq, vous évitez{" "}
                                <span
                                    data-cy="total-saved-co2"
                                    className="category__co2 category__saved-co2"
                                >
                                    {`${totalCarbonSavings} CO`}
                                    <sub>2</sub>e
                                </span>
                            </p>
                            <div>
                                <p className="ecodata-paragraph">
                                    C'est aussi :
                                </p>
                                <div className="ecodata-cards">
                                    <DataCard
                                        label={planeCarbonSavings}
                                        data-cy="saving-plane"
                                        imageUrl="https://images.ctfassets.net/85xu1vrcoskd/3VJxPlgLz697FlofK1pwlV/97c74d52b625c1ea6a8c86f17939de40/ico-plane.png"
                                    />
                                    <DataCard
                                        label={coffeeCarbonSavings}
                                        data-cy="saving-coffee"
                                        imageUrl="https://images.ctfassets.net/85xu1vrcoskd/6DeC5JZMNK10Tbm9AmTV1j/7540f0c8ee09faef428e41ca811fa956/ico-coffee.png"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <SkeletonDashboardEcodata />
            )}
        </>
    );
}
