/* eslint-disable no-console */
import React, { useState } from "react";

import { Combobox } from "@headlessui/react";

import ArrowDropDown from "components/common/icons/ArrowDropDown";
import ArrowDropUp from "components/common/icons/ArrowDropUp";
import CheckIcon from "components/common/icons/CheckIcon";

import styles from "./Filter.module.scss";

interface FilterProps {
    options: string[];
    label: string;
    onClick: (option: string | null) => void;
}

const Filter = ({ options, label, onClick }: FilterProps) => {
    const [selectedFilter, setSelectedFilter] = useState<string | null>("");

    const handleSelectCategory = (filter: string | null) => {
        if (selectedFilter === filter) {
            setSelectedFilter(null);
            onClick(null);
        } else {
            setSelectedFilter(filter);
            onClick(filter);
        }
    };

    return (
        <div className={styles.filter}>
            <Combobox name="name">
                {({ open }) => (
                    <>
                        <Combobox.Button
                            className={styles.button}
                            data-cy="filter-button"
                        >
                            <h4 className={styles.title}>
                                {selectedFilter ? selectedFilter : label}
                            </h4>
                            {open ? <ArrowDropUp /> : <ArrowDropDown />}
                        </Combobox.Button>
                        <Combobox.Options
                            className={styles.list}
                            static
                        >
                            {open &&
                                options.length > 0 &&
                                options.map((option) => (
                                    <Combobox.Option
                                        key={option}
                                        className={
                                            selectedFilter === option
                                                ? styles.listItemSelected
                                                : styles.listItem
                                        }
                                        value={option}
                                        onClick={() => {
                                            handleSelectCategory(option);
                                        }}
                                        data-cy={`filter-${option}`}
                                    >
                                        {option}
                                        {selectedFilter === option && (
                                            <CheckIcon color="#6f6c6c" />
                                        )}
                                    </Combobox.Option>
                                ))}
                        </Combobox.Options>
                    </>
                )}
            </Combobox>
        </div>
    );
};

export default Filter;
