const LoopIcon = () => (
    <svg
        width="13"
        height="13"
        viewBox="0 0 9 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.49984 3.49984V5.12484L6.6665 2.95817L4.49984 0.791504V2.4165C2.10567 2.4165 0.166504 4.35567 0.166504 6.74984C0.166504 7.60025 0.41567 8.39109 0.83817 9.05734L1.629 8.2665C1.37836 7.80038 1.24803 7.27907 1.24984 6.74984C1.24984 4.95692 2.70692 3.49984 4.49984 3.49984ZM8.1615 4.44234L7.37067 5.23317C7.609 5.68817 7.74984 6.20275 7.74984 6.74984C7.74984 8.54275 6.29275 9.99984 4.49984 9.99984V8.37484L2.33317 10.5415L4.49984 12.7082V11.0832C6.894 11.0832 8.83317 9.144 8.83317 6.74984C8.83317 5.89942 8.584 5.10859 8.1615 4.44234Z"
            fill="black"
        />
    </svg>
);

export default LoopIcon;
