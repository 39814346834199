import React from "react";

import { DEFAULT_SHIPPING_DELAY } from "components/business/catalog/shipping-delay-info/shipping-delay-info";
import Product from "components/business/order/product/product";
import ProductDeliveryDate from "components/business/order/product/product-delivery-date";
import { DeviceProductSpecs } from "components/business/order/product/product-specs";
import AffectedUserLabel from "components/business/order/step-form/affected-user-label";
import AccessorySpecs from "components/business/order/step-form/AssignAddress/product-item/product-item-info/accessory-specs/accessory-specs";
import { DeliveryTypeInput } from "graphql/order/model/apollo";
import displayDate from "utils/date/display-date";
import translator, {
    formatMemory,
    formatPrice,
} from "utils/translator/translator";

interface SummaryItemProps {
    product: Product;
}

interface SummaryDateInfoProps {
    deliveryDate: ProductDeliveryDate;
    shippingDelay: number;
}

export default function SummaryItem(props: SummaryItemProps) {
    const { product } = props;

    const SummaryDateInfo = (props: SummaryDateInfoProps) => {
        const { deliveryDate, shippingDelay } = props;
        const isScheduledDelivery =
            deliveryDate.deliveryType === DeliveryTypeInput.SCHEDULED;
        const delay = shippingDelay || DEFAULT_SHIPPING_DELAY;

        if (!deliveryDate || !shippingDelay) {
            return <></>;
        }

        if (!isScheduledDelivery) {
            return (
                <div className="summary-item-date">
                    <p className="summary-item-date-text">
                        Au plus tard {delay} jours après
                        <br />
                        la signature du contrat.
                    </p>
                </div>
            );
        }
        return (
            <div className="summary-item-date">
                <p style={{ lineHeight: 1.35 }}>
                    Livraison prévue le
                    <span className="summary-item-shipping-date">
                        {` ${displayDate(deliveryDate.date)}`}
                    </span>
                    <br />
                    <small>
                        <em>sous réserve de signature du contrat</em>
                    </small>
                </p>
            </div>
        );
    };

    const SummaryItemSpecs = () => {
        if (product.type === "Variant") {
            const specsProduct = product.specs as DeviceProductSpecs;
            return (
                <>
                    <span className="specs-infos">
                        {translator.translate(specsProduct.processor)}
                    </span>
                    <span className="specs-infos">
                        {formatMemory(parseFloat(specsProduct.ram ?? "0"))} RAM
                    </span>
                    <span className="specs-infos">
                        {formatMemory(parseFloat(specsProduct.storage ?? "0"))}{" "}
                        SSD
                    </span>
                </>
            );
        }
        return <AccessorySpecs accessory={product} />;
    };

    const SummaryItemLeasingDuration = () => (
        <div
            className="product-summary-leasing-duration"
            data-cy={`product-summary-leasing-duration-${product.innerId}`}
        >
            {`Renouvellement sur ${product.leasingDuration} mois`}
        </div>
    );

    const SummaryItemInfo = () => (
        <div className="summary-item-infos">
            <img
                className="summary-item-image"
                src={product.photo ?? ""}
                alt={product.name}
            />
            <div className="summary-item-name">
                <div className="name">{product.name}</div>
                <SummaryItemLeasingDuration />
                <div className="summary-item-details">
                    <SummaryItemSpecs />
                </div>
            </div>
        </div>
    );

    const SummaryItemPrice = () => (
        <div className="summary-item-price">
            <div
                className="negotiated-price"
                style={{ width: "100%" }}
            >
                {product.negotiated && "Votre prix: "}
            </div>
            <span className="quantity m-r-5">
                {formatPrice(product.pricing)}
            </span>
            HT / mois
            <div
                className="negotiated-price"
                style={{ width: "100%" }}
            >
                {product.negotiated &&
                    `Au lieu de ${formatPrice(product.normalPrice)}`}
            </div>
        </div>
    );

    return (
        <li className="summary-item">
            <div className="summary-item-left">
                <SummaryItemInfo />
                <div className="summary-item-user">
                    <AffectedUserLabel {...product} />
                </div>
            </div>
            <div className="summary-item-right">
                <SummaryDateInfo
                    deliveryDate={product.deliveryDate}
                    shippingDelay={product.shippingDelay}
                />
                <SummaryItemPrice />
            </div>
        </li>
    );
}
