import React from "react";

import { Nav, TabContent, TabPane } from "reactstrap";

interface deviceListInterface {
    isKeycloakUser: Boolean;
    children: JSX.Element;
}

const DeviceList = (props: deviceListInterface) => (
    <div className="nlt-wide-devices">
        <TabContent activeTab="1">
            <TabPane tabId="1">
                <div className="nlt-dev-1">
                    <div className="nlt-dev-2">
                        <Nav
                            className={`nlt-dev-3 ${
                                props.isKeycloakUser ? "user-noHover" : ""
                            }`}
                        >
                            {props.children}
                        </Nav>
                    </div>
                </div>
            </TabPane>
        </TabContent>
    </div>
);

export default DeviceList;
