import React from "react";

import { useHistory, useLocation, withRouter } from "react-router";

import {
    ProductItemCatalog,
    ProductPageProps,
} from "components/business/catalog/ProductItemCatalog/ProductItemCatalog";
import CartProvider from "components/provider/context/cart/cart-provider";
import CatalogFiltersProvider from "components/provider/context/catalog-filters/catalog-filters-provider";
import TempCartProvider from "components/provider/context/temp-cart/temp-cart-provider";

type LocationState = {
    state: ProductPageProps;
};

const ProductPage = () => {
    const history = useHistory();

    const returnToCatalog = () =>
        history.push(`${process.env.PUBLIC_URL}/order/new/catalog`);

    try {
        const location = useLocation() as LocationState;
        const { instanceId, product, variants } = location.state;

        return (
            <CartProvider>
                <CatalogFiltersProvider>
                    <TempCartProvider variants={variants}>
                        <ProductItemCatalog
                            instanceId={instanceId}
                            product={product}
                            variants={variants}
                            onClose={returnToCatalog}
                        />
                    </TempCartProvider>
                </CatalogFiltersProvider>
            </CartProvider>
        );
    } catch (e) {
        returnToCatalog();
        return <></>;
    }
};

export default withRouter(ProductPage);
