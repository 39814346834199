import React from "react";

import { withRouter } from "react-router-dom";

import LostCleaqeeImg from "components/common/images/lost-cleaqee.svg";
import SmallScreen from "components/common/layout/banner/small-screen";
import PageError from "components/common/layout/error/page-error/page-error";

import styles from "./Error404.module.scss";

const Error404 = (): JSX.Element => (
    <>
        <div className="nlt-container">
            <div className="nlt-card-left-panel">
                <PageError
                    image={{
                        src: LostCleaqeeImg,
                        alt: "Image d'un robot perdu sur la page 404",
                    }}
                    title="Nous sommes désolés, cette page n'est pas disponible"
                >
                    <p className={styles.paragraph}>
                        Il semblerait que cette page n'existe pas. <br />
                        N'hésitez pas à nous contacter en cas de problèmes.
                    </p>
                </PageError>
            </div>
        </div>
        <SmallScreen />
    </>
);

export default withRouter(Error404);
