import React from "react";

import getIconColor from "./utils";

interface TabletIconProps {
    isActive?: boolean;
}

export default function TabletIcon(props: TabletIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 23 17"
            width="23"
            height="17"
        >
            <rect
                width="23"
                height="17"
                rx="1.75"
                ry="1.75"
                fill={getIconColor(props.isActive ?? false)}
            />
            <rect
                x="1.7"
                y="1.91"
                width="19.59"
                height="13.18"
                fill="#eae7e1"
            />
        </svg>
    );
}

TabletIcon.defaultProps = {
    isActive: false,
};
