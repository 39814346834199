import CURRENT_STORAGE_VERSION from "components/business/catalog/cart/globalVars";
import Product from "components/business/order/product/product";

import CartAction from "./cart-action";

class ReduceProductQuantityAction implements CartAction {
    payload: {
        variantId: string;
        leasingDuration: number;
    };

    constructor(variantId: string, leasingDuration: number) {
        this.payload = {
            variantId,
            leasingDuration,
        };
    }

    apply(cart: Product[]) {
        const updatedCart = [...cart];
        for (let i = updatedCart.length - 1; i >= 0; i -= 1) {
            const product = updatedCart[i];
            if (
                product.variantId === this.payload.variantId &&
                product.leasingDuration === this.payload.leasingDuration
            ) {
                updatedCart.splice(i, 1);
                break;
            }
        }
        localStorage.setItem(
            CURRENT_STORAGE_VERSION,
            JSON.stringify(updatedCart),
        );
        return updatedCart;
    }
}

export default ReduceProductQuantityAction;
