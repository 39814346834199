import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import "./skeleton-document.scss";

const SkeletonDocument = () => (
    <div
        className="skeleton-document-item document-item"
        data-cy="document-item"
    >
        <div className="document-color" />
        <div className="skeleton-document-icon document-icon">
            <Skeleton
                variant="rect"
                width={19}
                height={22}
            />
        </div>
        <div className="document-info">
            <div
                className="document-name"
                data-cy="document-name"
            >
                <Skeleton
                    width={120}
                    height={24}
                />
            </div>
            <div
                className="document-type"
                data-cy="document-type"
            >
                <Skeleton width={45} />
            </div>
        </div>
    </div>
);

export default SkeletonDocument;
