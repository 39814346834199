import { useCallback } from "react";

import { useKeycloak } from "@react-keycloak/web";

import settings from "config";
import {
    FeatureFlagConfigurationFilter,
    FeatureFlagsFragment,
    useFindFeatureFlagsQuery,
} from "graphql/catalog/model/apollo";
import ApiClientNames from "graphql/consts";

export enum feature {
    HELP_WIDGET = "helpWidget",
    INTEGRATIONS_PAGE = "integrationsPage",
    NEXT_CATALOG = "next_catalog",
}

type featureConfigType = {
    [key: string]: boolean;
};

const defaultFeatureConfig: featureConfigType = {
    [feature.INTEGRATIONS_PAGE]: false,
    [feature.NEXT_CATALOG]: false,
};

export type featureToggleRouterType = {
    setFeature(featureName: feature, isEnabled: boolean | null): void;
    featureIsEnabled(featureName: feature): boolean;
};

function createToggleRouter(
    featureConfig: featureConfigType,
): featureToggleRouterType {
    return {
        setFeature(featureName: feature, isEnabled: boolean | null) {
            if (isEnabled === null) {
                return;
            }
            featureConfig[featureName] = isEnabled;
        },
        featureIsEnabled(featureName: feature): boolean {
            return featureConfig[featureName] ?? false;
        },
    };
}

const toggleRouter = createToggleRouter(defaultFeatureConfig);

type useFeatureFlagsReturnType = {
    router: featureToggleRouterType;
    loading: boolean;
};

export default function useFeatureFlags(): useFeatureFlagsReturnType {
    const { keycloak } = useKeycloak();

    const filter: FeatureFlagConfigurationFilter = {
        OR: [
            { id: settings.REACT_APP_ENV },
            { id: keycloak.tokenParsed?.["instanceId"] },
            { id: keycloak.tokenParsed?.["email"] },
        ],
    };

    const { data: queryResults, loading } = useFindFeatureFlagsQuery({
        context: { clientName: ApiClientNames.CATALOG },
        variables: { where: filter },
    });

    const featureFlags =
        queryResults?.featureFlagConfigurationCollection?.items;

    type FeatureFlagsKeys = keyof Omit<
        FeatureFlagsFragment,
        "id" | "sys" | "__typename"
    >;

    const getFeatureState = useCallback(
        (featureKey: FeatureFlagsKeys): boolean | null => {
            const envFlag = featureFlags?.find(
                (item) => item?.id === settings.REACT_APP_ENV,
            )?.[featureKey];

            const instanceFlag = featureFlags?.find(
                (item) => item?.id === keycloak.tokenParsed?.["instanceId"],
            )?.[featureKey];

            const userFlag = featureFlags?.find(
                (item) => item?.id === keycloak.tokenParsed?.["email"],
            )?.[featureKey];

            if (userFlag != null) {
                return userFlag;
            } else if (instanceFlag != null) {
                return instanceFlag;
            } else if (envFlag != null) {
                return envFlag;
            }

            return null;
        },
        [featureFlags, keycloak.tokenParsed],
    );

    toggleRouter.setFeature(feature.HELP_WIDGET, getFeatureState("helpWidget"));

    toggleRouter.setFeature(
        feature.INTEGRATIONS_PAGE,
        getFeatureState("integrationsPage"),
    );

    toggleRouter.setFeature(
        feature.NEXT_CATALOG,
        getFeatureState("nextCatalog"),
    );

    return {
        router: toggleRouter,
        loading: loading,
    };
}
