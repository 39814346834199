import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonIncidentList = () => (
    <div>
        <div className="nlt-incident-item">
            <div className="nlt-list-item-device-name pull-left">
                <Skeleton width={250} />
            </div>
            <div className="nlt-list-item-device-state pull-left">
                <Skeleton width={90} />
            </div>
        </div>
        <div className="nlt-incident-sep" />
    </div>
);

export default SkeletonIncidentList;
