import React from "react";

import { Redirect, useParams } from "react-router-dom";

const DeviceGateway = () => {
    const { serialNumber } = useParams<{ serialNumber: string }>();

    return (
        <Redirect
            to={{
                pathname: "/devices",
                state: { serialSearch: serialNumber },
            }}
        />
    );
};

export default DeviceGateway;
