/* eslint-disable no-unused-expressions */
import React from "react";

import { useHistory } from "react-router";
import { Row } from "reactstrap";
import { v4 as uuid } from "uuid";

import CardDashboard from "components/common/layout/card-dashboard/card-dashboard";
import SkeletonCard from "components/common/layout/card-dashboard/skeleton-card";
import {
    DeviceComputedStatus,
    InstanceDashboardFragment,
} from "graphql/management/model/apollo";

import "./dashboard-overview.scss";

interface DashboardInfo {
    instanceDashboard: InstanceDashboardFragment | null | undefined;
    dashboardLoading: boolean;
}

const DashboardOverview = (props: DashboardInfo) => {
    const history = useHistory();
    const RedirectToDevices = (status: DeviceComputedStatus) => {
        history.push({
            pathname: `${process.env.PUBLIC_URL}/devices`,
            state: { statusSearch: status },
        });
    };

    const getCount = (status: DeviceComputedStatus) =>
        props.instanceDashboard?.deviceComputedStatus?.find(
            (device) => device.deviceStatus === status,
        )?.count ?? 0;

    return (
        <div className="custom-card dashboard-overview-row">
            <div className="nlt-card-header">
                <div className="nlt-card-title">Aperçu de ma flotte</div>
            </div>
            <Row>
                {!props.dashboardLoading ? (
                    <>
                        <div className="dashboard-overview-list-cards">
                            <CardDashboard
                                title="Appareils en cours d'utilisation"
                                status={DeviceComputedStatus.BEING_USED}
                                redirectFunction={() =>
                                    RedirectToDevices(
                                        DeviceComputedStatus.BEING_USED,
                                    )
                                }
                                count={getCount(
                                    DeviceComputedStatus.BEING_USED,
                                )}
                            />
                            <CardDashboard
                                title="Incidents en cours"
                                status={DeviceComputedStatus.INCIDENT}
                                redirectFunction={() =>
                                    RedirectToDevices(
                                        DeviceComputedStatus.INCIDENT,
                                    )
                                }
                                count={getCount(DeviceComputedStatus.INCIDENT)}
                            />
                            <CardDashboard
                                title="Appareils en maintenance"
                                status={DeviceComputedStatus.MAINTENANCE}
                                redirectFunction={() =>
                                    RedirectToDevices(
                                        DeviceComputedStatus.MAINTENANCE,
                                    )
                                }
                                count={getCount(
                                    DeviceComputedStatus.MAINTENANCE,
                                )}
                            />
                            <CardDashboard
                                title="Appareils en stock"
                                status={DeviceComputedStatus.AVAILABLE}
                                redirectFunction={() =>
                                    RedirectToDevices(
                                        DeviceComputedStatus.AVAILABLE,
                                    )
                                }
                                count={getCount(DeviceComputedStatus.AVAILABLE)}
                            />
                        </div>
                    </>
                ) : (
                    <div className="skeleton-card-container">
                        {[...Array(4)].map(() => (
                            <SkeletonCard key={uuid()} />
                        ))}
                    </div>
                )}
            </Row>
        </div>
    );
};

export default DashboardOverview;
