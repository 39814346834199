/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState } from "react";

import { useKeycloak } from "@react-keycloak/web";
import { Typeahead } from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom";
import {
    Container,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Initials from "components/common/layout/initials/initials";
import Loader from "components/common/layout/loader/loader";
import {
    OrganisationFragment,
    OrganisationUserFragment,
    refetchComputeDashboardDataQuery,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    UserRole,
    useAffectUsersOrganisationsMutation,
    useCreateUserMutation,
    useFindOrganisationsLazyQuery,
    useFindUsersQuery,
    useDeleteUsersOrganisationsMutation,
} from "graphql/management/model/apollo";
import EmailRegExp from "utils/email-utils/email-utils";

type AddMemberProps = {
    selectedOrganisation: OrganisationFragment;
    setSelectedOrganisation: React.Dispatch<
        React.SetStateAction<OrganisationFragment | null | undefined>
    >;
};

interface AssignMemberType {
    email: string;
    firstName: string;
    lastName: string;
}

interface ErrorAddMember {
    email: boolean;
    firstName: boolean;
    lastName: boolean;
}

const CleaqSwal = withReactContent(Swal);

const AddMember = (props: AddMemberProps) => {
    const { initialized } = useKeycloak();
    const [organisationsQuery] = useFindOrganisationsLazyQuery();
    const usersQuery = useFindUsersQuery();
    const [saving, setSaving] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
    const [errors, setErrors] = useState<ErrorAddMember | null>();
    const [newMail, setNewMail] = useState("");
    const [newFirstName, setNewFirstname] = useState("");
    const [newLastName, setNewLastname] = useState("");
    const [createUser] = useCreateUserMutation();
    const [newUsers, setNewUsers] = useState<AssignMemberType[]>();

    const organisationRefetches = [
        refetchFindUsersQuery(),
        refetchFindOrganisationsQuery(),
        refetchFindDevicesQuery(),
    ];
    const [affectUsersOrganisations] = useAffectUsersOrganisationsMutation({
        refetchQueries: organisationRefetches,
    });
    const [deleteUsersOrganisations] = useDeleteUsersOrganisationsMutation({
        refetchQueries: organisationRefetches,
    });

    const history = useHistory();

    if (!initialized || usersQuery.loading) return <Loader />;

    const Redirect = (redirect: string, email: string) => {
        history.push({
            pathname: redirect,
            state: { mailSearch: email },
        });
    };

    function buildUserName(
        user: OrganisationUserFragment | AssignMemberType,
    ): string {
        return `${user.firstName} ${user.lastName} (${user.email})`;
    }

    const ResetEdit = () => {
        setNewUsers([]);
        setNewLastname("");
        setNewFirstname("");
        setNewMail("");
        setErrors(null);
        setSaving(false);
    };

    const ToggleModal = () => {
        setModal(!modal);
        setSelectedMembers(
            props.selectedOrganisation?.users?.map((user) =>
                buildUserName(user),
            ) || [],
        );
        ResetEdit();
    };

    function addUsers(users: string[]) {
        setSelectedMembers(selectedMembers.concat(users));
    }

    function deleteUser(user: string) {
        setSelectedMembers(
            selectedMembers.filter((userItem) => userItem !== user),
        );
    }

    async function refreshSelectedOrganisation() {
        const { data: organisations } = await organisationsQuery();
        const updatedOrganisation = organisations?.findOrganisations.find(
            (org) => org.id === props.selectedOrganisation.id,
        );
        props.setSelectedOrganisation(updatedOrganisation);
    }

    const AffectUser = async () => {
        setSaving(true);

        try {
            if (newUsers != null) {
                await Promise.all(
                    newUsers.map((user) => {
                        return createUser({
                            variables: {
                                email: user.email,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                organisations: null,
                                phoneNumber: "",
                                role: UserRole.USER,
                            },
                            refetchQueries: [
                                refetchFindUsersQuery(),
                                refetchComputeDashboardDataQuery(),
                            ],
                        });
                    }),
                );
            }

            const selectedOrgUsers = props.selectedOrganisation.users;
            if (selectedOrgUsers != null && selectedOrgUsers.length > 0) {
                const emailsToDelete = selectedOrgUsers.map(
                    (user) => user.email,
                );
                await deleteUsersOrganisations({
                    variables: {
                        emails: emailsToDelete,
                        organisationsIds: props.selectedOrganisation.id,
                    },
                });
            }

            const emailsToAffect = selectedMembers.map(
                (userString) => userString?.split("(")[1]?.split(")")[0],
            );
            if (emailsToAffect.length > 0) {
                await affectUsersOrganisations({
                    variables: {
                        emails: emailsToAffect,
                        organisationsIds: props.selectedOrganisation.id,
                    },
                });
            }

            await CleaqSwal.fire({
                title: "Confirmé !",
                html: "Le tag a bien été modifié",
                icon: "success",
            });
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Vérifiez l'adresse e-mail renseignée. <br/>Si le problème persiste, contactez nous dans le chat",
                icon: "error",
            });
            ResetEdit();
        } finally {
            await refreshSelectedOrganisation();
            ToggleModal();
        }
    };

    const AddCreateDevice = async () => {
        setErrors(null);

        if (
            EmailRegExp.test(newMail) &&
            newLastName?.trim()?.length > 0 &&
            newFirstName?.trim()?.length > 0
        ) {
            const newUser = {
                email: newMail,
                firstName: newFirstName,
                lastName: newLastName,
            };
            setNewUsers(newUsers?.concat(newUser));
            setNewLastname("");
            setNewFirstname("");
            setNewMail("");
            addUsers([buildUserName(newUser)]);
        } else {
            setErrors({
                email: !(newMail?.trim()?.length && EmailRegExp.test(newMail)),
                firstName: !(
                    newFirstName?.trim()?.length &&
                    newFirstName?.trim()?.length > 0
                ),
                lastName: !(
                    newLastName?.trim()?.length &&
                    newLastName?.trim()?.length > 0
                ),
            });
        }
    };

    const UserNames =
        usersQuery.data &&
        usersQuery.data.findUser
            ?.filter(
                (user) =>
                    !selectedMembers?.find((selectedMember) =>
                        selectedMember?.includes(user.email!),
                    ),
            )
            ?.map(
                (user) => `${user.firstName} ${user.lastName} (${user.email})`,
            );

    return (
        <>
            <div className="float-right">
                <a
                    aria-hidden="true"
                    className="nlt-button-inside-form"
                    onClick={ToggleModal}
                >
                    <i className="icofont icofont-layers m-r-5" />
                    Gérer
                </a>
            </div>
            <h6 className="mb-3">Membres</h6>

            <Modal
                isOpen={modal}
                toggle={ToggleModal}
                className="modal-body"
                centered
            >
                <ModalHeader toggle={ToggleModal}>
                    Ajoutez un membre au tag {props.selectedOrganisation.name}
                </ModalHeader>
                <ModalBody>
                    <Container
                        fluid
                        className="bd-example-row"
                    >
                        <h6>Choisissez un utilisateur existant :</h6>
                        <FormGroup>
                            {(() => {
                                // https://github.com/ericgio/react-bootstrap-typeahead/issues/703
                                let ref: Typeahead<string> | null;
                                return (
                                    <Typeahead
                                        id="basic-typeahead"
                                        multiple={false}
                                        emptyLabel="Aucun résultat"
                                        paginationText="Plus de résultats..."
                                        selected={[]}
                                        onChange={(e) => addUsers(e)}
                                        options={UserNames || []}
                                        placeholder="Choisir un utilisateur..."
                                        onBlur={() => ref?.hideMenu()}
                                        ref={(el) => (ref = el)}
                                    />
                                );
                            })()}
                        </FormGroup>

                        <h6 className="m-t-20">
                            Ou créez un nouvel utilisateur :
                        </h6>
                        <Form className="needs-validation">
                            <Row className="nlt-row-form-fix">
                                <div className="nlt-item-info-label-edit col-md-3 required">
                                    Adresse e-mail
                                </div>
                                <FormGroup className="col-md-7">
                                    {!errors?.email ? (
                                        <span className="mandatory-label-small" />
                                    ) : (
                                        <span className="nlt-mandatory-label-small nlt-font-red">
                                            {errors?.email &&
                                                "L'adresse e-mail est invalide"}
                                        </span>
                                    )}
                                    <Input
                                        className="form-control"
                                        type="email"
                                        placeholder="laura.petit@cleaq.com"
                                        onChange={(e) =>
                                            setNewMail(e.target.value)
                                        }
                                        value={newMail}
                                        defaultValue={newMail}
                                    />
                                </FormGroup>
                            </Row>
                            <Row className="nlt-row-form-fix">
                                <div className="nlt-item-info-label-edit col-md-3 required">
                                    Nom
                                </div>
                                <FormGroup className="col-md-7">
                                    {!errors?.lastName ? (
                                        <span className="mandatory-label-small" />
                                    ) : (
                                        <span className="nlt-mandatory-label-small nlt-font-red">
                                            {errors?.lastName &&
                                                "Le nom de famille est requis."}
                                        </span>
                                    )}
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Petit"
                                        onChange={(e) =>
                                            setNewLastname(e.target.value)
                                        }
                                        value={newLastName}
                                        defaultValue={newLastName}
                                    />
                                </FormGroup>
                            </Row>
                            <Row className="nlt-row-form-fix">
                                <div className="nlt-item-info-label-edit col-md-3 required">
                                    Prénom
                                </div>
                                <FormGroup className="col-md-7">
                                    {!errors?.firstName ? (
                                        <span className="mandatory-label-small" />
                                    ) : (
                                        <span className="nlt-mandatory-label-small nlt-font-red">
                                            {errors?.firstName &&
                                                "Le prénom est requis."}
                                        </span>
                                    )}
                                    <Input
                                        className="form-control"
                                        type="text"
                                        onChange={(e) =>
                                            setNewFirstname(e.target.value)
                                        }
                                        placeholder="Laura"
                                        value={newFirstName}
                                        defaultValue={newFirstName}
                                    />
                                </FormGroup>
                            </Row>
                            <Row className="nlt-row-form-fix">
                                <div
                                    aria-hidden="true"
                                    className="nlt-button-add-sm"
                                    onClick={AddCreateDevice}
                                >
                                    <i className="fa fa-plus nlt-button-icon-plus" />
                                    Ajouter
                                </div>
                            </Row>

                            {selectedMembers && selectedMembers?.length > 0 && (
                                <>
                                    <div className="nlt-device-sep" />
                                    <h6>Membres affectés</h6>
                                    <table className="table table-bordernone">
                                        <tbody>
                                            {selectedMembers.map((member) => (
                                                <tr key={member}>
                                                    <div className="nlt-contact-inside-form-light">
                                                        {member}
                                                    </div>
                                                    <div
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                            deleteUser(member)
                                                        }
                                                        className="float-right form-remove-element"
                                                    >
                                                        <i className="icofont icofont-bin m-r-5" />
                                                    </div>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </Form>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    {!saving ? (
                        <>
                            <button
                                type="button"
                                className="nlt-button-inside-form-cancel"
                                onClick={ToggleModal}
                            >
                                Annuler
                            </button>
                            <button
                                type="button"
                                className="nlt-button-inside-form-save"
                                onClick={AffectUser}
                            >
                                Valider
                            </button>
                        </>
                    ) : (
                        <div className="nlt-loader-container-modal">
                            <div className="nlt-loader-save" />
                        </div>
                    )}
                </ModalFooter>
            </Modal>
            {props.selectedOrganisation.users &&
            props.selectedOrganisation.users.length > 0 ? (
                <div
                    className="table table-bordernone"
                    style={{ display: "table" }}
                >
                    <div style={{ display: "table-row-group" }}>
                        {props.selectedOrganisation.users.map((user) => (
                            <div
                                style={{
                                    display: "table-row",
                                }}
                                key={user.email!}
                            >
                                <Initials
                                    firstName={user.firstName}
                                    lastName={user.lastName}
                                    sizeImage={30}
                                    customStyle={{ float: "left" }}
                                />
                                <div className="nlt-contact-inside-form">{`${user.firstName} ${user.lastName}`}</div>
                                <div
                                    aria-hidden="true"
                                    className="nlt-button-inside-user"
                                    onClick={() =>
                                        Redirect(
                                            `${process.env.PUBLIC_URL}/users`,
                                            user.email!,
                                        )
                                    }
                                >
                                    Voir
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <li>Aucun utilisateur membre !</li>
            )}
        </>
    );
};

export default AddMember;
