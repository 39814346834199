import React from "react";

import { AccessoryType } from "components/business/catalog/utils/types";
import { VariantFragment } from "graphql/catalog/model/apollo";

import "./DurationInput.scss";

interface DurationInputProps {
    currentLeasingDuration: number;
    setCurrentLeasingDuration: (duration: number) => void;
    product: VariantFragment | AccessoryType;
}

const DurationInput = (props: DurationInputProps) => {
    const zeroOrNull = (x: number | null) => x === null || x <= 0;

    return (
        <div className="spec-choice">
            <div className="spec-choice-title">Durée de location :</div>
            <div className="spec-choice-input">
                <button
                    type="button"
                    className={`${
                        (props.currentLeasingDuration === 24 && "choice") ||
                        (zeroOrNull(props.product.price24) && "unavailable")
                    }`}
                    onClick={() => props.setCurrentLeasingDuration(24)}
                    disabled={zeroOrNull(props.product.price24)}
                    data-cy="duration-24"
                >
                    24 mois
                </button>
                <button
                    type="button"
                    className={`${
                        (props.currentLeasingDuration === 36 && "choice") ||
                        (zeroOrNull(props.product.price36) && "unavailable")
                    }`}
                    onClick={() => props.setCurrentLeasingDuration(36)}
                    disabled={zeroOrNull(props.product.price36)}
                    data-cy="duration-36"
                >
                    36 mois
                </button>
                <button
                    type="button"
                    className={`${
                        (props.currentLeasingDuration === 48 && "choice") ||
                        (zeroOrNull(props.product.price48) && "unavailable")
                    }`}
                    onClick={() => props.setCurrentLeasingDuration(48)}
                    disabled={zeroOrNull(props.product.price48)}
                    data-cy="duration-48"
                >
                    48 mois
                </button>
            </div>
        </div>
    );
};

export default DurationInput;
