import React, { useEffect, useState } from "react";

import { Checkbox } from "@material-ui/core";

import "./multiple-item-selector.scss";

interface MultipleItemSelectorProps {
    onClick: () => void;
    selectedElements: number;
    allElements: number | undefined;
}

export default function MultipleItemSelector(props: MultipleItemSelectorProps) {
    const [checked, setChecked] = useState(
        props.allElements !== undefined &&
            props.selectedElements === props.allElements &&
            props.allElements !== 0,
    );

    const handleClick = () => {
        setChecked(!checked);
        props.onClick();
    };

    useEffect(() => {
        setChecked(
            props.allElements !== undefined &&
                props.selectedElements === props.allElements &&
                props.allElements !== 0,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedElements]);

    return (
        <Checkbox
            checked={checked}
            className={`multiple-item-selector${checked ? " checked" : ""}`}
            onClick={handleClick}
            inputProps={{ "aria-label": "checkbox" }}
        />
    );
}
