import React, { useState } from "react";

import { Link } from "react-router-dom";

import useFindCatalogIntegrations from "components/business/Integration/Hooks/useFindCatalogIntegrations";
import Card from "components/common/Card/Card";
import Description from "components/common/Card/Description";
import Filter from "components/common/Filter/Filter";
import MagnifyingGlassIcon from "components/common/icons/MagnifyingGlassIcon";
import Input from "components/common/input/Input/Input";
import SmallScreen from "components/common/layout/banner/small-screen";
import MultiToggle from "components/common/MultiToggle/MultiToggle";
import { useFindIntegrationsQuery } from "graphql/management/model/apollo";

import styles from "./Integrations.module.scss";
import SkeletonIntegrations from "./SkeletonIntegrations/SkeletonIntegrations";

const Integrations = () => {
    const [category, setCategory] = useState<string | null>(null);

    const [active, setActive] = useState<string>("Toutes");
    const [searchTerm, setSearchTerm] = useState("");

    const { data: instanceIntegrations, loading: instanceQueryLoading } =
        useFindIntegrationsQuery();

    const { data: catalogIntegrations, loading: catalogQueryLoading } =
        useFindCatalogIntegrations();

    const activeIntegrationProviders =
        instanceIntegrations?.currentInstance?.integrations.map((i) =>
            i.provider.toString(),
        );

    const displayedIntegrations = catalogIntegrations
        .filter((integration) => {
            switch (active) {
                case "Actives":
                    return activeIntegrationProviders?.includes(
                        integration.name.toUpperCase(),
                    );

                case "Disponibles":
                    return !activeIntegrationProviders?.includes(
                        integration.name.toUpperCase(),
                    );

                default:
                    return true;
            }
        })
        .filter((integration) => {
            if (category === null) {
                return true;
            } else {
                return integration.category === category.toLowerCase();
            }
        })
        .filter((integration) => {
            return (
                searchTerm === "" ||
                integration.displayName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            );
        });

    const multiToggleOptions = ["Toutes", "Actives", "Disponibles"];

    const categoriesWithDuplicates = catalogIntegrations.map((data) =>
        data.category.toUpperCase(),
    );

    const categories = Array.from(new Set(categoriesWithDuplicates));

    const handleReset = () => {
        setSearchTerm("");
    };

    return (
        <div className={styles.integrationsPage}>
            <div className={styles.integrationsContainer}>
                <div className={styles.header}>
                    <h2 className={styles.title}>Intégrations</h2>
                    <div className={styles.filters}>
                        <div className={styles.input}>
                            <Input
                                onChange={(e) => {
                                    e.preventDefault();
                                    setSearchTerm(e.target.value);
                                }}
                                value={searchTerm}
                                placeholder="Rechercher une intégration..."
                                icon={<MagnifyingGlassIcon />}
                                onReset={handleReset}
                                data-cy="search-integrations-input"
                            />
                        </div>
                        <Filter
                            options={categories}
                            label={"Catégories"}
                            onClick={setCategory}
                        />
                        <MultiToggle
                            options={multiToggleOptions}
                            onToggle={setActive}
                        />
                    </div>
                </div>
                <div
                    className={styles.body}
                    data-cy="integrations-body"
                >
                    {catalogQueryLoading || instanceQueryLoading
                        ? [...Array(3)].map((_, i) => (
                              <SkeletonIntegrations key={i} />
                          ))
                        : displayedIntegrations.map((integration) => (
                              <Link
                                  to={`/integrations/${integration.name}`}
                                  className={styles.cardContainer}
                                  key={integration.name}
                              >
                                  <Card
                                      img={
                                          <img
                                              alt={integration.displayName}
                                              src={integration.logoUrl}
                                              className={styles.image}
                                          />
                                      }
                                      title={integration.displayName}
                                      subtitle={
                                          <p>
                                              {integration.category.toUpperCase()}
                                          </p>
                                      }
                                      description={
                                          <Description
                                              details={
                                                  integration.shortDescription
                                              }
                                          />
                                      }
                                      cardFunction={() => {}}
                                      active={activeIntegrationProviders?.includes(
                                          integration.name.toUpperCase(),
                                      )}
                                  />
                              </Link>
                          ))}
                </div>
            </div>
            <SmallScreen />
        </div>
    );
};

export default Integrations;
