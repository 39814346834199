import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import "./skeleton-card.scss";

const SkeletonCard = () => (
    <div className="skeleton-card">
        <div className="skeleton-card-content">
            <Skeleton
                className="skeleton-item-icon"
                height={24}
                width={24}
                variant="circle"
            />
            <Skeleton
                variant="text"
                width={117}
            />
        </div>
    </div>
);

export default SkeletonCard;
