import React from "react";

import { currentUserFragment } from "graphql/management/model/apollo";

import ProfilePic from "../profile-pic/profile-pic";

interface AccountFormDetailsProps {
    setEditable: React.Dispatch<React.SetStateAction<boolean>>;
    user: currentUserFragment;
}

export default function AccountFormDetails(props: AccountFormDetailsProps) {
    const { setEditable, user } = props;

    return (
        <div>
            <div className="nlt-card-header">
                <span className="nlt-card-title">
                    Informations personnelles
                </span>
            </div>

            <div className="nlt-wide-card-right-panel-container">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "stretch",
                        marginTop: "2px",
                    }}
                >
                    <div className="info-avatar">
                        <ProfilePic currentUser={user} />
                    </div>
                    <div className="d-flex flex-column justify-content-between align-items-end">
                        <div className="nlt-card-title-buttons">
                            <div>
                                <div
                                    aria-hidden="true"
                                    className="nlt-button-inside"
                                    onClick={() => setEditable(true)}
                                >
                                    <i className="icofont icofont-pencil-alt-2 mr-2 mb-0" />
                                    Editer
                                </div>
                            </div>
                        </div>

                        <div className="nlt-wide-card-tag-wrapper">
                            {user.organisations &&
                            user.organisations.length > 0 ? (
                                user.organisations?.map((orga) => (
                                    <div
                                        key={orga.id!}
                                        style={{
                                            backgroundColor: orga.hexaColor!,
                                            color: "white",
                                        }}
                                        className="nlt-wide-card-tag-item mb-0"
                                    >
                                        {orga.name}
                                    </div>
                                ))
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                </div>

                <ul className="m-t-30">
                    <li className="nlt-item-info">
                        <div className="nlt-item-info-label">Nom</div>
                        <div className="nlt-item-info-data">
                            {user.lastName}
                        </div>
                    </li>

                    <li className="nlt-item-info">
                        <div className="nlt-item-info-label">Prénom</div>
                        <div className="nlt-item-info-data">
                            {user.firstName}
                        </div>
                    </li>

                    <li className="nlt-item-info">
                        <div className="nlt-item-info-label">
                            Adresse e-mail
                        </div>
                        <div className="nlt-item-info-data">{user.email}</div>
                    </li>
                    <li className="nlt-item-info">
                        <div className="nlt-item-info-label">N° téléphone</div>
                        <div className="nlt-item-info-data">
                            {user.phoneNumber}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}
