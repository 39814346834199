import React, { useState } from "react";

import { Tooltip } from "@material-ui/core";

import UpdateDurationTempCartAction from "components/provider/context/temp-cart/actions/update-duration-temp-cart-action";
import UpdateVariantTempCartAction from "components/provider/context/temp-cart/actions/update-specs-cart-action";
import {
    useTempCart,
    useTempCartDispatch,
} from "components/provider/context/temp-cart/temp-cart-provider";
import { VariantFragment } from "graphql/catalog/model/apollo";
import useEffectOnce from "utils/use-effect-once/use-effect-once";

import "./Configure.scss";

interface ConfigureProps {
    variants: VariantFragment[];
}

interface VariantBuild {
    processor: string;
    ram: string;
    storage: string;
}

class VariantOptions {
    constructor(processor: string[], ram: string[], storage: string[]) {
        this.processor = processor;
        this.ram = ram;
        this.storage = storage;
    }

    processor: string[];

    ram: string[];

    storage: string[];
}

function onlyUnique(value: string, index: number, self: string[]) {
    return self.indexOf(value) === index;
}

const Configure = (props: ConfigureProps) => {
    const { variant, leasingDuration } = useTempCart();
    const tempCartDispatch = useTempCartDispatch();

    const [selectedConfig, setSelectedConfig] = useState<VariantBuild>({
        processor: variant.processorDescription!!,
        ram: variant.ramDescription!!,
        storage: variant.storageDescription!!,
    });

    const sortVariants = (a: VariantFragment, b: VariantFragment) => {
        if (a?.price36) {
            return a?.price36!! > b?.price36!! ? 1 : -1;
        }
        return a?.price24!! > b?.price24!! ? 1 : -1;
    };

    const variantSorted = props.variants.sort(sortVariants);

    const availableOptions = new VariantOptions(
        variantSorted
            .map((variant) => variant.processorDescription!!)
            .filter(onlyUnique),
        variantSorted
            .map((variant) => variant.ramDescription!!)
            .filter(onlyUnique),
        variantSorted
            .map((variant) => variant.storageDescription!!)
            .filter(onlyUnique),
    );

    const [selectableOptions, setSelectableOptions] =
        useState<VariantOptions>(availableOptions);

    const chooseVariant = (config: VariantBuild): void => {
        const filteredVariants = variantSorted
            .filter(
                (variant) => variant.processorDescription === config.processor,
            )
            .filter((variant) => variant.ramDescription === config.ram)
            .filter((variant) => variant.storageDescription === config.storage);

        if (filteredVariants.length === 1 && filteredVariants[0]) {
            tempCartDispatch(
                new UpdateVariantTempCartAction(filteredVariants[0]),
            );

            const defaultDuration =
                filteredVariants[0].product?.type === "smartphone" ? 24 : 36;

            // eslint-disable-next-line no-restricted-syntax
            for (const duration of [24, 36, 48]) {
                const priceKey = `price${duration}` as keyof VariantFragment;
                const priceForDuration = filteredVariants[0][priceKey];

                if (
                    leasingDuration === duration &&
                    defaultDuration !== duration
                ) {
                    if (!(priceForDuration && priceForDuration > 0)) {
                        tempCartDispatch(
                            new UpdateDurationTempCartAction(defaultDuration),
                        );
                    }
                }
            }
        }
    };

    const getSelectableOptionsFromProcessor = (p: string): VariantOptions => {
        const tempOptions = { ...availableOptions };

        const variantsFilteredByProcessor = variantSorted.filter(
            (variant) => variant.processorDescription === p,
        );

        tempOptions.ram = variantsFilteredByProcessor
            .map((variant) => variant.ramDescription!!)
            .filter(onlyUnique);

        tempOptions.storage = variantsFilteredByProcessor
            .map((variant) => variant.storageDescription!!)
            .filter(onlyUnique);

        return tempOptions;
    };

    const selectProcessor = (processor: string): void => {
        const variantsFilteredByProcessor = variantSorted
            .sort((varA, varB) => (varA.price36 ?? 0) - (varB.price36 ?? 0))
            .filter((variant) => variant.processorDescription === processor);

        const tempConfig = {
            processor,
            ram: variantsFilteredByProcessor[0].ramDescription!!,
            storage: variantsFilteredByProcessor[0].storageDescription!!,
        };

        setSelectableOptions(getSelectableOptionsFromProcessor(processor));
        setSelectedConfig({ ...tempConfig });
        chooseVariant(tempConfig);
    };

    const selectRam = (ram: string): void => {
        if (!selectableOptions.ram.includes(ram)) {
            return;
        }

        const tempConfig = {
            ...selectedConfig,
            ram,
        };

        const variantsFilteredByProcessor = variantSorted.filter(
            (variant) => variant.processorDescription === tempConfig.processor,
        );

        const validStorage = variantsFilteredByProcessor
            .filter((variant) => variant.ramDescription === ram)
            .map((variant) => variant.storageDescription!!)
            .filter(onlyUnique);

        if (!validStorage.includes(tempConfig.storage)) {
            tempConfig.storage = validStorage[0].toString();
        }

        setSelectableOptions(
            getSelectableOptionsFromProcessor(tempConfig.processor),
        );
        setSelectedConfig({ ...tempConfig });
        chooseVariant(tempConfig);
    };

    const selectStorage = (storage: string): void => {
        if (!selectableOptions.storage.includes(storage)) {
            return;
        }

        const tempConfig = {
            ...selectedConfig,
            storage,
        };

        const variantsFilteredByProcessor = variantSorted.filter(
            (variant) => variant.processorDescription === tempConfig.processor,
        );

        const validRam = variantsFilteredByProcessor
            .filter((variant) => variant.storageDescription === storage)
            .map((variant) => variant.ramDescription!!)
            .filter(onlyUnique);

        if (!validRam.includes(tempConfig.ram)) {
            tempConfig.ram = validRam[0].toString();
        }

        setSelectableOptions(
            getSelectableOptionsFromProcessor(tempConfig.processor),
        );
        setSelectedConfig({ ...tempConfig });
        chooseVariant(tempConfig);
    };

    useEffectOnce(() => {
        selectProcessor(variant.processorDescription!!);
    });

    const configurableTypesProduct = ["desktop", "laptop"];

    return (
        <div className="configuration-specs-wrapper">
            {props.variants[0].product?.type &&
                configurableTypesProduct.includes(
                    props.variants[0].product.type,
                ) && (
                    <>
                        <div className="configuration-specs">
                            <div className="spec-choice-title">
                                Processeur
                                <Tooltip
                                    title="Le processeur est le cerveau de l'ordinateur.
                                            Plus celui-ci sera puissant, plus votre appareil sera
                                            capable de gérer une multitude d'informations en même temps."
                                    placement="right"
                                >
                                    <i className="icofont icofont-info-circle" />
                                </Tooltip>{" "}
                                :
                            </div>

                            <div className="spec-choice">
                                {availableOptions.processor.map((p) => (
                                    <button
                                        type="button"
                                        key={p}
                                        className={`${
                                            selectedConfig.processor === p &&
                                            "choice"
                                        }`}
                                        onClick={() => {
                                            selectProcessor(p);
                                        }}
                                        disabled={
                                            !selectableOptions.processor.includes(
                                                p,
                                            )
                                        }
                                    >
                                        {p}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="configuration-specs">
                            <div className="spec-choice-title">
                                Mémoire RAM
                                <Tooltip
                                    title="Plus la mémoire de votre ordinateur sera élevée,
                                            plus vous pourrez utiliser de logiciels en simultané
                                            avec des performances optimales."
                                    placement="right"
                                >
                                    <i className="icofont icofont-info-circle configure-tooltip" />
                                </Tooltip>{" "}
                                :
                            </div>
                            <div className="spec-choice">
                                {availableOptions.ram.map((r) => (
                                    <button
                                        type="button"
                                        key={r}
                                        className={`
                                            ${
                                                selectedConfig.ram === r &&
                                                "choice"
                                            } 
                                            ${
                                                !selectableOptions.ram.includes(
                                                    r,
                                                ) && "unavailable"
                                            }
                                        `}
                                        onClick={() => {
                                            selectRam(r);
                                        }}
                                        disabled={
                                            !selectableOptions.ram.includes(r)
                                        }
                                    >
                                        {r}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            <div className="configuration-specs">
                <div className="spec-choice-title">
                    Stockage
                    <Tooltip
                        title="Plus vous disposerez de mémoire, plus vous pourrez conserver un nombre important de documents, logiciels, photos & vidéos sur votre ordinateur."
                        placement="right"
                    >
                        <i className="icofont icofont-info-circle configure-tooltip" />
                    </Tooltip>{" "}
                    :
                </div>
                <div className="spec-choice">
                    {availableOptions.storage.map((s) => (
                        <button
                            type="button"
                            key={s}
                            className={`
                                ${selectedConfig.storage === s && "choice"} 
                                ${
                                    !selectableOptions.storage.includes(s) &&
                                    "unavailable"
                                }
                            `}
                            onClick={() => {
                                selectStorage(s);
                            }}
                            disabled={!selectableOptions.storage.includes(s)}
                        >
                            {s}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default Configure;
