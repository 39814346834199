import React, { useState } from "react";

import { Tooltip } from "@material-ui/core";
import classnames from "classnames";

import AddProduct from "components/business/catalog/utils/add-product";
import { AccessoryType } from "components/business/catalog/utils/types";
import CheckIcon from "components/common/icons/CheckIcon";
import CrossIcon from "components/common/icons/CrossIcon";
import EyeIcon from "components/common/icons/EyeIcon";
import UpdateAccessoryTempCartAction from "components/provider/context/temp-cart/actions/update-accessory-temp-cart-action";
import {
    useTempCart,
    useTempCartDispatch,
} from "components/provider/context/temp-cart/temp-cart-provider";
import getSpecsFromAccessory from "utils/catalog/get-specs-from-accessory";
import { formatPrice } from "utils/translator/translator";

import "./SuggestedAccessories.scss";

interface SuggestedAccessoriesProps {
    suggestedAccessory: AccessoryType;
    instanceId: string;
}

const SuggestedAccessories = (props: SuggestedAccessoriesProps) => {
    const [displayDetails, setDisplayDetails] = useState<boolean>(false);
    const { quantity, leasingDuration } = useTempCart();
    const tempCartDispatch = useTempCartDispatch();
    const [added, setAdded] = useState<boolean>(false);

    const pricingInfo = new AddProduct(
        props.suggestedAccessory,
        props.instanceId,
        1,
        leasingDuration,
    ).buildPricingInfo();

    const getAddButtonContent = () => {
        if (added) {
            return (
                <>
                    <CheckIcon color="#28A745" />
                    <span>Ajouté</span>
                </>
            );
        }
        return "Ajouter";
    };

    const specs = getSpecsFromAccessory(props.suggestedAccessory);

    return (
        <div className="suggested-accessory">
            <button
                type="button"
                className={classnames(
                    displayDetails
                        ? "suggested-accessory-details-button-cross"
                        : "suggested-accessory-details-button-eye",
                )}
                onClick={() => setDisplayDetails(!displayDetails)}
            >
                {!displayDetails ? <EyeIcon /> : <CrossIcon />}
            </button>
            <div className="suggested-accessory-top">
                {displayDetails ? (
                    <div className="suggested-accessory-details">
                        <div className="suggested-accessory-details-title">
                            {props.suggestedAccessory.name}
                        </div>
                        <div className="suggested-accessory-specs">
                            {Array.from(specs.keys()).map((key) => (
                                <div
                                    className="suggested-accessory-spec"
                                    key={key}
                                >
                                    <span>{key}</span>
                                    <span>{specs.get(key)}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="suggested-accessory-info">
                        {props.suggestedAccessory.image?.url && (
                            <img
                                alt={
                                    props.suggestedAccessory.image.title ||
                                    props.suggestedAccessory.name ||
                                    ""
                                }
                                src={props.suggestedAccessory.image.url}
                                className="suggested-accessory-photo"
                            />
                        )}
                        <p className="suggested-accessory-title">
                            {props.suggestedAccessory.name}
                        </p>
                    </div>
                )}
                <p className="suggested-accessory-price">
                    {formatPrice(pricingInfo.price)}
                    <span>
                        {" "}
                        HT / mois
                        {pricingInfo.negotiated && (
                            <Tooltip
                                title={`Au lieu de ${formatPrice(
                                    pricingInfo.normalPrice * quantity,
                                )}`}
                                placement="right"
                            >
                                <i className="icofont icofont-star" />
                            </Tooltip>
                        )}
                    </span>
                </p>
            </div>
            <div className="suggested-accessory-button">
                <button
                    type="button"
                    className={
                        added
                            ? "cleaq-button-suggested-accessory-added"
                            : "cleaq-button-suggested-accessory"
                    }
                    onClick={() => {
                        setAdded(!added);
                        tempCartDispatch(
                            new UpdateAccessoryTempCartAction(
                                props.suggestedAccessory,
                            ),
                        );
                    }}
                >
                    {getAddButtonContent()}
                </button>
            </div>
        </div>
    );
};

export default SuggestedAccessories;
