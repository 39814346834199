export default function CalendarIcon() {
    return (
        <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.83283 0.849998C4.83283 0.380556 4.45227 0 3.98283 0C3.51339 0 3.13283 0.380556 3.13283 0.849998V2.29346H2.45C1.0969 2.29346 0 3.39036 0 4.74346V7.03636V16.1943C0 17.5474 1.0969 18.6443 2.45 18.6443H15.9593C17.3124 18.6443 18.4093 17.5474 18.4093 16.1943V7.03636V4.74346C18.4093 3.39036 17.3124 2.29346 15.9593 2.29346H15.2761V0.849998C15.2761 0.380556 14.8956 0 14.4261 0C13.9567 0 13.5761 0.380556 13.5761 0.849998V2.29346H4.83283V0.849998ZM1.7 4.74346C1.7 4.32924 2.03579 3.99346 2.45 3.99346H15.9593C16.3735 3.99346 16.7093 4.32924 16.7093 4.74346V6.18636H9.20464H1.7V4.74346ZM9.20464 7.88636H16.7093V16.1943C16.7093 16.6085 16.3735 16.9443 15.9593 16.9443H2.45C2.03579 16.9443 1.7 16.6085 1.7 16.1943V7.88636H9.20464Z"
                fill="#353535"
            />
        </svg>
    );
}
