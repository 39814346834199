import React, { useState } from "react";

import Accessory from "components/business/catalog/accessory/accessory";
import Product from "components/business/catalog/product/product";
import { AccessoryType } from "components/business/catalog/utils/types";
import TempCartProvider from "components/provider/context/temp-cart/temp-cart-provider";
import { ProductFragment, VariantFragment } from "graphql/catalog/model/apollo";

import { ProductItemCatalog } from "../ProductItemCatalog/ProductItemCatalog";

interface CatalogItemsProps {
    instanceId: string;
    variants: Map<string, VariantFragment[]> | null;
    products: (AccessoryType | ProductFragment)[];
    isCatalogFilterTypeAccessory: () => boolean;
}

const CatalogItems = (props: CatalogItemsProps) => {
    const [selectedProduct, setSelectedProduct] = useState<
        AccessoryType | ProductFragment | null
    >(null);
    const [selectedVariants, setSelectedVariants] = useState<VariantFragment[]>(
        [],
    );

    return (
        <>
            {!props.isCatalogFilterTypeAccessory()
                ? props.products
                      .filter((product) => product)
                      .map((product) => {
                          const { variants } = props;
                          const productVariants =
                              variants && variants.get(product.sys.id);
                          if (productVariants) {
                              return (
                                  <button
                                      key={product.sys.id}
                                      type="button"
                                      className="product-card"
                                      data-cy="choose-catalog-button"
                                      onClick={() => {
                                          setSelectedProduct(
                                              product as ProductFragment,
                                          );
                                          setSelectedVariants(productVariants);
                                      }}
                                  >
                                      <Product
                                          product={product as ProductFragment}
                                          variants={productVariants}
                                          instanceId={props.instanceId}
                                      />
                                  </button>
                              );
                          }
                          return <React.Fragment key={product.sys.id} />;
                      })
                : props.products.map((accessory) => (
                      <button
                          key={accessory.sys.id}
                          type="button"
                          className="accessory-wrapper"
                          onClick={() => {
                              setSelectedProduct(accessory as AccessoryType);
                          }}
                      >
                          <Accessory
                              accessory={accessory as AccessoryType}
                              instanceId={props.instanceId}
                          />
                      </button>
                  ))}
            {selectedProduct && (
                <>
                    <TempCartProvider variants={selectedVariants}>
                        <ProductItemCatalog
                            instanceId={props.instanceId}
                            product={selectedProduct}
                            variants={selectedVariants}
                            onClose={() => {
                                setSelectedProduct(null);
                                setSelectedVariants([]);
                            }}
                        />
                    </TempCartProvider>
                </>
            )}
        </>
    );
};

export default CatalogItems;
