import React from "react";

import { withRouter } from "react-router-dom";
import { Row } from "reactstrap";

import DashboardDevices from "components/business/dashboard/dashboard-devices/dashboard-devices";
import DashboardEcoData from "components/business/dashboard/dashboard-ecodata/dashboard-eco-data";
import Optimize from "components/business/dashboard/dashboard-optimize/dashboard-optimize";
import DashboardOverview from "components/business/dashboard/dashboard-overview/dashboard-overview";
import SmallScreen from "components/common/layout/banner/small-screen";
import FileManager from "components/common/layout/file-manager/file-manager";
import {
    FileType,
    useComputeDashboardDataQuery,
    useFindInstanceQuery,
} from "graphql/management/model/apollo";
import useEffectOnce from "utils/use-effect-once/use-effect-once";
import "./dashboard.scss";

const Dashboard = () => {
    const dashboardQuery = useComputeDashboardDataQuery();
    const instanceQuery = useFindInstanceQuery();

    useEffectOnce(() => {
        dashboardQuery.refetch();
        instanceQuery.refetch();
    });

    const instanceDashboard = dashboardQuery.data?.instanceDashboard;
    const instanceFiles = instanceQuery?.data?.currentInstance?.files?.filter(
        (file) =>
            file.fileType === FileType.BILLING ||
            file.fileType === FileType.SUBSCRIPTION_INVOICE,
    );

    return (
        <>
            <div
                className="nlt-container"
                id="dashboard"
                style={{ padding: "15px" }}
            >
                <div className="nlt-container-scale email-wrap bookmark-wrap">
                    <div className="dashboard-top">
                        <DashboardOverview
                            instanceDashboard={instanceDashboard}
                            dashboardLoading={dashboardQuery.loading}
                        />
                        <DashboardEcoData
                            instanceDashboard={instanceDashboard}
                            dashboardLoading={dashboardQuery.loading}
                        />
                    </div>
                    <Row className="dashboard-files">
                        <FileManager
                            files={instanceFiles}
                            loading={instanceQuery.loading}
                            title="Mes Factures"
                        />
                        <div className="dashboard-optimize">
                            <Optimize
                                instanceDashboard={instanceDashboard}
                                dashboardLoading={dashboardQuery.loading}
                            />
                            <DashboardDevices
                                instanceDashboard={instanceDashboard}
                                dashboardLoading={dashboardQuery.loading}
                            />
                        </div>
                    </Row>
                </div>
            </div>
            <SmallScreen />
        </>
    );
};

export default withRouter(Dashboard);
