import React, { useState } from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    refetchFindBillingContactsQuery,
    useDeleteBillingContactsMutation,
    useFindBillingContactsQuery,
} from "graphql/management/model/apollo";

import AddBillingContact from "./AddBillingContact/AddBillingContact";
import BillingContactPlaceholder from "./BillingContactPlaceholder/BillingContactPlaceholder";

import "./BillingContact.scss";

const BillingContact = () => {
    const [displayAddContactModal, setdisplayAddContactModal] =
        useState<boolean>(false);
    const contactList = useFindBillingContactsQuery();

    const [DeleteBillingContactsMutation] = useDeleteBillingContactsMutation();

    const CleaqSwal = withReactContent(Swal);

    const contacts = contactList.data?.currentInstance?.billingContacts;

    const deleteContact = async (email: string) => {
        await DeleteBillingContactsMutation({
            variables: {
                emails: [email],
            },
            refetchQueries: [refetchFindBillingContactsQuery()],
        });
    };

    const deleteBillingContact = (email: string) => {
        CleaqSwal.fire({
            title: `Êtes-vous sûr de vouloir retirer ${email} des contacts de facturation ?`,
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: "Confirmer supression",
            cancelButtonText: "Annuler",
        }).then((result) => {
            if (result.isDenied) {
                deleteContact(email)
                    .then((response: any) => {
                        CleaqSwal.fire({
                            title: "Confirmé !",
                            html: "Le contact a bien été supprimé.",
                            icon: "success",
                        });
                    })
                    .catch((error: any) => {
                        CleaqSwal.fire({
                            title: "Oops, une erreur s'est produite !",
                            html: "Contactez-nous dans le chat !",
                            icon: "error",
                        });
                    });
            }
        });
    };

    const isContactListEmpty =
        contacts === null || contacts === undefined || contacts.length === 0;

    return (
        <div className="billing-contact">
            <div className="billing-contact-header">
                <h4>Contacts de facturation</h4>
                <div className="billing-add-contact">
                    <button
                        type="button"
                        className="btn-billing-contact-add"
                        onClick={() =>
                            setdisplayAddContactModal(!displayAddContactModal)
                        }
                    >
                        Ajouter un contact
                    </button>
                    {displayAddContactModal && (
                        <AddBillingContact
                            onClose={() => {
                                setdisplayAddContactModal(
                                    !displayAddContactModal,
                                );
                            }}
                        />
                    )}
                </div>
            </div>
            <div
                className="billing-contact-list"
                data-cy="billing-contact-list"
            >
                {isContactListEmpty ? (
                    <BillingContactPlaceholder />
                ) : (
                    contacts.map((contact) => (
                        <div
                            className="billing-contact-item"
                            key={contact}
                        >
                            <div className="billing-contact-item-email">
                                {contact}
                            </div>
                            <button
                                type="button"
                                className="billing-contact-trash"
                                data-cy="button-delete-contact"
                                onClick={() => {
                                    deleteBillingContact(contact);
                                }}
                            >
                                <i className="icofont icofont-trash" />
                            </button>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default BillingContact;
