import React from "react";

import "./page-error.scss";

interface ErrorImage {
    src: string;
    alt: string;
}

interface ErrorProps {
    title?: string;
    children: React.ReactNode;
    image: ErrorImage;
}

const PageError = ({ title, children, image }: ErrorProps) => (
    <div className="error-page">
        <div className="error-page__content">
            {title && <h3>{title}</h3>}
            {children}
        </div>
        {image && (
            <div className="error-page__image">
                <img
                    src={image.src}
                    alt={image.alt}
                />
            </div>
        )}
    </div>
);

PageError.defaultProps = {
    title: "",
};

export default PageError;
