import CURRENT_STORAGE_VERSION from "components/business/catalog/cart/globalVars";
import Product from "components/business/order/product/product";

import CartAction from "./cart-action";

class RemoveProductAction implements CartAction {
    payload: {
        variantId: string;
        leasingDuration: number;
    };

    constructor(variantId: string, leasingDuration: number) {
        this.payload = {
            variantId,
            leasingDuration,
        };
    }

    apply(cart: Product[]) {
        const updatedCart: Product[] = cart.filter(
            (product) =>
                product.variantId !== this.payload.variantId ||
                product.leasingDuration !== this.payload.leasingDuration,
        );
        localStorage.setItem(
            CURRENT_STORAGE_VERSION,
            JSON.stringify(updatedCart),
        );
        return updatedCart;
    }
}

export default RemoveProductAction;
