import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import "./skeleton-dashboard-ecodata.scss";

const SkeletonDashboardEcodata = () => (
    <>
        <div className="custom-card dashboard-ecodata">
            <div className="dashboard-ecodata__header">
                <Skeleton width={355} />
                <div className="dashboard-ecodata-more">
                    <Skeleton
                        variant="circle"
                        height={16}
                        width={16}
                    />
                </div>
            </div>
            <div className="dashboard-ecodata-row">
                <div className="dashboard-ecodata-col">
                    <div className="skeleton-dashboard-ecodata__category">
                        <Skeleton
                            variant="rect"
                            width={206}
                            height={50}
                        />
                    </div>
                    <div>
                        <div className="skeleton-ecodata-paragraph">
                            <Skeleton width={103} />
                        </div>
                        <div className="ecodata-cards">
                            <div className="data-card data-card-emission">
                                <Skeleton width={85} />
                            </div>
                            <div className="data-card data-card-emission">
                                <Skeleton width={85} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-ecodata-col">
                    <Skeleton
                        variant="rect"
                        width={206}
                        height={50}
                    />
                    <div>
                        <div className="skeleton-ecodata-paragraph">
                            <Skeleton width={71} />
                        </div>
                        <div className="ecodata-cards">
                            <div className="data-card data-card-saved">
                                <Skeleton width={85} />
                            </div>
                            <div className="data-card data-card-saved">
                                <Skeleton width={85} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default SkeletonDashboardEcodata;
