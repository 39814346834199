import React, { useState } from "react";

import { createPortal } from "react-dom";

import Product from "components/business/order/product/product";
import ProductAddress from "components/business/order/product/product-address";

import AddressModal from "./AddressModal/AddressModal";
import AssignAddressForm from "./assign-address-form/assign-address-form";

import "./AssignAddress.scss";

const AssignAddress = () => {
    const [currentAddress, setCurrentAddress] = useState<ProductAddress | null>(
        null,
    );
    const [addressForm, setAddressForm] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(
        null,
    );

    return (
        <>
            <h4 className="assign-address-title">Vos adresses enregistrées</h4>
            <AssignAddressForm
                setCurrentAddress={setCurrentAddress}
                setSelectedProduct={setSelectedProduct}
                onToggleAddressForm={() => {
                    setAddressForm(true);
                }}
            />
            {addressForm &&
                createPortal(
                    <AddressModal
                        address={currentAddress}
                        selectedProduct={selectedProduct}
                        onToggleAddressForm={() => {
                            setAddressForm(false);
                            setSelectedProduct(null);
                        }}
                    />,
                    document.getElementById("portal") as HTMLElement,
                )}
        </>
    );
};

export default AssignAddress;
