import { AccessoryType } from "components/business/catalog/utils/types";
import { TempCart } from "components/provider/context/temp-cart/types";

import TempCartAction from "./temp-cart-action";

class UpdateDurationTempCartAction implements TempCartAction {
    payload: {
        duration: number;
    };

    constructor(duration: number) {
        this.payload = {
            duration,
        };
    }

    apply(tempCart: TempCart) {
        const accessoriesWithPriceForDuration =
            tempCart.suggestedAccessories.filter((accessory) => {
                const priceKey =
                    `price${this.payload.duration}` as keyof AccessoryType;
                return accessory[priceKey] !== null && accessory[priceKey] > 0;
            });

        return {
            ...tempCart,
            suggestedAccessories: accessoriesWithPriceForDuration,
            leasingDuration: this.payload.duration,
        };
    }
}

export default UpdateDurationTempCartAction;
