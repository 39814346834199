import Keycloak from "keycloak-js";

export function cleaqParsedToken(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(""),
    );

    return JSON.parse(jsonPayload);
}

export function checkAuthorization(initialized: boolean, keycloak: Keycloak) {
    window.scrollTo(0, 0);

    if (initialized && keycloak.isTokenExpired()) {
        keycloak.logout().then(() => {
            window.location.href = keycloak.createLoginUrl();
        });
    }
}

export function logout(keycloak: Keycloak) {
    localStorage.setItem("bearerToken", "");
    localStorage.setItem("parsedToken", "");
    keycloak.logout();
}
