import React from "react";

import { Button as MuiButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import Moment from "moment";
import { createPortal } from "react-dom";
import { Redirect, useParams, useHistory } from "react-router";

import ProductByShippingDate from "components/business/order/order-history-list/product-by-address/product-by-shipping-date";
import SmallScreen from "components/common/layout/banner/small-screen";
import Button from "components/common/layout/Button/Button";
import CareContactDetails from "components/common/layout/CareContactDetails/CareContactDetails";
import ApiClientNames from "graphql/consts";
import { OrderStatus, useFindOrderQuery } from "graphql/order/model/apollo";
import { defineAddressStatus } from "utils/order-status/order-status";
import { formatPrice } from "utils/translator/translator";

import SkeletonOrderDetails from "./skeleton-order-details/skeleton-order-details";

import "./order-details.scss";

interface OrderDetailsParams {
    crmId: string;
}

const OrderDetails = () => {
    const params: OrderDetailsParams = useParams();
    const history = useHistory();
    const displayOrder = useFindOrderQuery({
        context: { clientName: ApiClientNames.ORDER },
        variables: { crmId: params.crmId },
    });

    if (displayOrder.loading) {
        return <SkeletonOrderDetails />;
    }

    if (!displayOrder.data || !displayOrder.data.findOrder) {
        return <Redirect to="/not-found" />;
    }

    const order = displayOrder.data.findOrder;

    const orderStatus = defineAddressStatus(order.status);

    const ReturnOnHistoryOrder = () =>
        history.push(`${process.env.PUBLIC_URL}/orders`);
    const RedirectFunction = (redirect: any, search: any) => {
        history.push({
            pathname: redirect,
            state: { mailSearch: search },
        });
    };

    const getLinkVariant =
        order.status === OrderStatus.PENDING_VALIDATION ||
        order.status === OrderStatus.PENDING_SIGNATURE
            ? "primary"
            : "";

    const getLinkText =
        order.status === OrderStatus.PENDING_VALIDATION ||
        order.status === OrderStatus.PENDING_SIGNATURE
            ? "Signer le bon de commande"
            : "Voir le bon de commande";

    return (
        <>
            <div className="order-list-side-panel">
                <div className="side-panel-elements">
                    <div className="side-panel-order-info-top">
                        <div>
                            <div className="visible">
                                <MuiButton
                                    variant="outlined"
                                    onClick={() => ReturnOnHistoryOrder()}
                                    startIcon={<ChevronLeft />}
                                >
                                    Retour
                                </MuiButton>
                            </div>
                        </div>
                        <div className="order-header-infos">
                            <h6 className="order-title">
                                Commande {order.name}
                            </h6>
                            <div>
                                {Moment(order.createdAt).format(
                                    "[du] DD/MM/YYYY",
                                )}
                            </div>
                            <div
                                className={`order-status status-${orderStatus.stylingProperty}`}
                                data-cy="order-status"
                            >
                                {orderStatus.label}
                            </div>
                            {order.contract !== null && order.contract.url && (
                                <div className="order-contract-info">
                                    <Button
                                        theme={getLinkVariant}
                                        as="a"
                                        href={order.contract.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {getLinkText}
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="orderby">
                            <div>Commande passée par</div>
                            <div
                                aria-hidden="true"
                                className="ordered-by"
                                onClick={() =>
                                    RedirectFunction(
                                        `${process.env.PUBLIC_URL}/users`,
                                        order.client.userEmail,
                                    )
                                }
                            >
                                {order.client.userName}
                            </div>
                        </div>
                    </div>
                    <div className="priceSum-container">
                        <div className="priceSum">
                            <p>Montant total</p>
                            <p>
                                <span>{formatPrice(order.priceSum)}</span> HT /
                                mois
                            </p>
                        </div>
                    </div>
                    {createPortal(
                        <CareContactDetails />,
                        document.getElementById("portal") as HTMLElement,
                    )}
                    <ProductByShippingDate crmId={params.crmId} />
                </div>
            </div>
            <SmallScreen />
        </>
    );
};

export default OrderDetails;
