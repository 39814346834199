import React from "react";

import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { Controller } from "react-hook-form";
import type { UseFormReturn } from "react-hook-form";
import { FormGroup, Input, Row } from "reactstrap";

import {
    DeviceFragment,
    DeviceStatus,
    DeviceType,
    DeviceSource,
} from "graphql/management/model/apollo";
import type { FormData } from "utils/device/device-form";
import { typesLabels, osLabels } from "utils/device/device-labels";
import deviceOs from "utils/device/device-os";
import { convertToDeviceType } from "utils/device/device-types-converter";

import DeviceUpdateSelect from "./device-update-select";
import DeviceUpdateText from "./device-update-text";
import "./device-update.scss";

interface deviceUpdateFormInterface {
    deviceForm: UseFormReturn<FormData>;
    deviceNames: string[];
    selectedDevice: DeviceFragment;
    setSelectedDevice: React.Dispatch<
        React.SetStateAction<DeviceFragment | null | undefined>
    >;
    selectedDeviceType: DeviceType;
    setSelectedDeviceType: React.Dispatch<React.SetStateAction<DeviceType>>;
    nameValue: string;
    setNameValue: React.Dispatch<React.SetStateAction<string>>;
}

const DeviceUpdateForm = (props: deviceUpdateFormInterface) => {
    const {
        control,
        formState: { errors },
    } = props.deviceForm;

    function deviceTypes() {
        const options: any = [];
        typesLabels.forEach((value, key) => {
            options.push(
                <option
                    key={value}
                    value={key}
                >
                    {value}
                </option>,
            );
        });
        return options;
    }

    return (
        <>
            <Row className="nlt-row-form-fix fix-padding">
                <div className="nlt-item-info-label-edit col-md-5 required">
                    Nom
                </div>
                <FormGroup className="col-md-7">
                    {!errors.name && (
                        <span className="mandatory-label-small m-t-0" />
                    )}
                    <span className="nlt-mandatory-label-small nlt-font-red m-t-0">
                        {errors.name && "Le nom de l'appareil est requis"}
                    </span>
                    <Controller
                        name="name"
                        control={control}
                        rules={{
                            required: false,
                            validate: (_) =>
                                props.nameValue.length > 0 &&
                                props.nameValue.trim().length > 0,
                        }}
                        render={({ field }) => (
                            <Autocomplete
                                id="autocomplete"
                                inputValue={field.value}
                                onInputChange={(e: any, data: any) =>
                                    props.setNameValue(data)
                                }
                                onChange={(e: any, data: any) =>
                                    props.setNameValue(data)
                                }
                                disabled={
                                    props.selectedDevice.source ===
                                    DeviceSource.CLEAQ
                                }
                                freeSolo
                                options={props.deviceNames}
                                defaultValue={props.selectedDevice.reference}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        variant="outlined"
                                        placeholder='MacBook Pro 15"'
                                        className="nlt-form-fix"
                                    />
                                )}
                            />
                        )}
                    />
                </FormGroup>
            </Row>
            <DeviceUpdateSelect
                data-cy="device-edit-status"
                label="Statut"
                id="device-status"
                name="status"
                defaultValue={props.selectedDevice.status}
                deviceForm={props.deviceForm}
            >
                <>
                    <option value={DeviceStatus.OTHER}>
                        Matériel à associer
                    </option>
                    <option value={DeviceStatus.SPARE}>
                        Matériel en stock
                    </option>
                    <option value={DeviceStatus.MAINTENANCE}>
                        Matériel en maintenance
                    </option>
                </>
            </DeviceUpdateSelect>
            <DeviceUpdateSelect
                data-cy="device-edit-type"
                label="Type d'appareil"
                id="device-type"
                name="deviceType"
                onChange={(event) =>
                    props.setSelectedDeviceType(
                        convertToDeviceType(event.target.value),
                    )
                }
                defaultValue={props.selectedDevice.specs.deviceType}
                deviceForm={props.deviceForm}
                disabled={props.selectedDevice.source === DeviceSource.CLEAQ}
            >
                <>{deviceTypes()}</>
            </DeviceUpdateSelect>
            <DeviceUpdateSelect
                data-cy="device-edit-os"
                label="OS de l'appareil"
                id="device-os"
                name="deviceOs"
                defaultValue={props.selectedDevice.specs.deviceOs}
                deviceForm={props.deviceForm}
                disabled={props.selectedDevice.source === DeviceSource.CLEAQ}
            >
                <>
                    {deviceOs(props.selectedDeviceType).map((os) => (
                        <option
                            key={os}
                            value={os}
                        >
                            {osLabels.get(os)}
                        </option>
                    ))}
                </>
            </DeviceUpdateSelect>
            <div className="nlt-device-sep" />
            <h6 className="mb-3">Caractéristiques techniques</h6>
            <DeviceUpdateText
                data-cy="device-edit-screen"
                label="Taille d'écran"
                name="screenSize"
                placeHolder='15"'
                defaultValue={props.selectedDevice.specs.screenSize ?? ""}
                deviceForm={props.deviceForm}
                disabled={props.selectedDevice.source === DeviceSource.CLEAQ}
            />
            <DeviceUpdateText
                data-cy="device-edit-storage"
                label="Stockage"
                name="storageSize"
                placeHolder="512Go"
                defaultValue={props.selectedDevice.specs.storageSize ?? ""}
                deviceForm={props.deviceForm}
                disabled={props.selectedDevice.source === DeviceSource.CLEAQ}
            />
            <DeviceUpdateText
                data-cy="device-edit-memory"
                label="Mémoire vive (RAM)"
                name="memorySize"
                placeHolder="16Go"
                defaultValue={props.selectedDevice.specs.memorySize ?? ""}
                deviceForm={props.deviceForm}
                disabled={props.selectedDevice.source === DeviceSource.CLEAQ}
            />
            <DeviceUpdateText
                data-cy="device-edit-cpu"
                label="Processeur"
                name="processor"
                placeHolder="Apple M1"
                defaultValue={props.selectedDevice.specs.processor ?? ""}
                deviceForm={props.deviceForm}
                disabled={props.selectedDevice.source === DeviceSource.CLEAQ}
            />
            <div className="nlt-device-sep" />
            <div>
                <h6 className="mb-3">Notes sur l'appareil</h6>
                <FormGroup className="col-md-12">
                    <Controller
                        name="customNotes"
                        control={control}
                        defaultValue={
                            props.selectedDevice.customNotes ?? undefined
                        }
                        render={({ field: { ref, ...rest } }) => (
                            <Input
                                {...rest}
                                data-cy="device-edit-notes"
                                className="form-control"
                                type="textarea"
                                name="customNotes"
                                placeholder="Notes sur l'appareil"
                                innerRef={ref}
                            />
                        )}
                    />
                </FormGroup>
            </div>
        </>
    );
};

export default DeviceUpdateForm;
