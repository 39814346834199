import React, { useState } from "react";

import { FilterNames } from "components/business/catalog/filter/filters";
import DesktopIcon from "components/common/icons/catalog/desktop-icon";
import LaptopIcon from "components/common/icons/catalog/laptop-icon";
import SmartphoneIcon from "components/common/icons/catalog/smartphone-icon";
import TabletIcon from "components/common/icons/catalog/tablet-icon";
import SetCatalogFiltersAction from "components/provider/context/catalog-filters/actions/set-catalog-filters-action";
import { useCatalogFiltersDispatch } from "components/provider/context/catalog-filters/catalog-filters-provider";

import FiltersAccessoryType from "./filters-accessory-type/filters-accessory-type";
import FiltersTypeEnum from "./filters-type-enum";

import "./filters-product-type.scss";

interface FiltersProductTypeProps {
    setResetCatalogDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}

const FiltersProductType = (props: FiltersProductTypeProps) => {
    const [active, setActive] = useState<FiltersTypeEnum>(
        FiltersTypeEnum.laptop,
    );
    const catalogFiltersDispatch = useCatalogFiltersDispatch();

    const toggleFilter = (filter: FiltersTypeEnum) => {
        const values: string = filter.toString();
        setActive(filter);
        if (active !== filter) {
            catalogFiltersDispatch(
                new SetCatalogFiltersAction(FilterNames.type, values),
            );

            props.setResetCatalogDisplay(true);
        }
    };

    return (
        <div
            className="filters-type"
            data-cy="filters-type"
        >
            <button
                type="button"
                onClick={() => {
                    toggleFilter(FiltersTypeEnum.laptop);
                }}
                className={`filters-product-type ${
                    active === FiltersTypeEnum.laptop ? "active" : "inactive"
                }`}
            >
                <LaptopIcon isActive={active === FiltersTypeEnum.laptop} />
                <span>Laptop</span>
            </button>
            <button
                type="button"
                onClick={() => {
                    toggleFilter(FiltersTypeEnum.desktop);
                }}
                className={`filters-product-type ${
                    active === FiltersTypeEnum.desktop ? "active" : "inactive"
                }`}
            >
                <DesktopIcon isActive={active === FiltersTypeEnum.desktop} />
                <span>Desktop</span>
            </button>
            <button
                type="button"
                onClick={() => {
                    toggleFilter(FiltersTypeEnum.smartphone);
                }}
                className={`filters-product-type ${
                    active === FiltersTypeEnum.smartphone
                        ? "active"
                        : "inactive"
                }`}
            >
                <SmartphoneIcon
                    isActive={active === FiltersTypeEnum.smartphone}
                />
                <span>Smartphone</span>
            </button>
            <button
                type="button"
                onClick={() => {
                    toggleFilter(FiltersTypeEnum.tablet);
                }}
                className={`filters-product-type ${
                    active === FiltersTypeEnum.tablet ? "active" : "inactive"
                }`}
            >
                <TabletIcon isActive={active === FiltersTypeEnum.tablet} />
                <span>Tablette</span>
            </button>
            <FiltersAccessoryType
                activeFilter={active}
                toggleFilter={toggleFilter}
            />
        </div>
    );
};
export default FiltersProductType;
