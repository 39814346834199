import Product from "components/business/order/product/product";

import CartAction from "./cart-action";

class RemoveAddressFromProductsAction implements CartAction {
    payload: {
        addressId: string;
    };

    constructor(addressId: string) {
        this.payload = { addressId };
    }

    apply(cart: Product[]) {
        const updatedCart = cart.map((product) => {
            if (
                product.address &&
                this.payload.addressId === product.address.id
            ) {
                const updatedProduct = product;
                updatedProduct.address = null;
                return updatedProduct;
            }
            return product;
        });
        return updatedCart;
    }
}

export default RemoveAddressFromProductsAction;
