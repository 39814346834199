import React from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    DeviceFragment,
    refetchComputeDashboardDataQuery,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    useDeleteDevicesMutation,
} from "graphql/management/model/apollo";

interface deleteMultipleDevicesInterface {
    selectedDevices: DeviceFragment[];
}

const CleaqSwal = withReactContent(Swal);

const DeleteMultipleDevices = (props: deleteMultipleDevicesInterface) => {
    const [deleteDevicesMutation] = useDeleteDevicesMutation();

    const deleteMultipleDevice = async () => {
        try {
            const result = await CleaqSwal.fire({
                title: `Vous souhaitez supprimer ${props.selectedDevices.length} appareils !`,
                html: "Toutes les informations liées à ces appareils seront définitivement supprimées.",
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
                cancelButtonText: "Annuler",
                denyButtonText: "Confirmer suppression",
            });
            if (result.isDenied) {
                try {
                    const serials = props.selectedDevices.map(
                        (device) => device.serialNumber,
                    );
                    await deleteDevicesMutation({
                        variables: {
                            serials,
                        },
                        refetchQueries: [
                            refetchComputeDashboardDataQuery(),
                            refetchFindDevicesQuery(),
                            refetchFindOrganisationsQuery(),
                            refetchFindUsersQuery(),
                        ],
                    });
                    CleaqSwal.fire({
                        title: "Confirmé !",
                        html: "Les appareils ont été supprimés",
                        icon: "success",
                    });
                } catch (error) {
                    CleaqSwal.fire({
                        title: "Oops, une erreur s'est produite !",
                        html: "Contactez-nous dans le chat !",
                        icon: "error",
                    });
                }
            }
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Contactez-nous dans le chat !",
                icon: "error",
            });
        }
    };

    return (
        <div
            aria-hidden="true"
            className="nlt-button-inside-delete"
            onClick={() => deleteMultipleDevice()}
            data-cy="button-delete"
        >
            <i className="icofont icofont-bin" />
        </div>
    );
};

export default DeleteMultipleDevices;
