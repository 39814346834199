import React from "react";

import { Controller } from "react-hook-form";
import type { UseFormReturn } from "react-hook-form";
import { Form, FormGroup, Input, Row } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import type { TagType } from "components/business/tag/types";
import ColorPicker from "components/common/input/color-picker/color-picker";
import {
    OrganisationFragment,
    refetchFindOrganisationsQuery,
    useCreateOrganisationMutation,
} from "graphql/management/model/apollo";

import TagSidePanel from "../layout/sidepanel/tag-sidepanel";

interface createTagInterface {
    tagForm: UseFormReturn<TagType>;
    saving: boolean;
    setSaving: React.Dispatch<React.SetStateAction<boolean>>;
    colorValue: string;
    setColorValue: React.Dispatch<React.SetStateAction<string>>;
    setSelectedTag: React.Dispatch<
        React.SetStateAction<OrganisationFragment | null | undefined>
    >;
    setIsCreateOrg: React.Dispatch<React.SetStateAction<boolean>>;
    cancelEdit: () => void;
}

const CleaqSwal = withReactContent(Swal);

const CreateTag = (props: createTagInterface) => {
    const { tagForm } = props;
    const { control, handleSubmit } = tagForm;
    const [createOrganisation] = useCreateOrganisationMutation();

    const CreateOrganisationForm = async (dataPush: TagType) => {
        try {
            await createOrganisation({
                variables: {
                    name: dataPush.name,
                    hexaColor: dataPush.hexaColor,
                },
                refetchQueries: [refetchFindOrganisationsQuery()],
                update: (_, result) => {
                    if (result.data?.createOrganisation) {
                        props.setSelectedTag(result.data.createOrganisation);
                    }
                    props.setIsCreateOrg(false);
                },
            });
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Contactez-nous dans le chat !",
                icon: "error",
            });
        }
        props.setSaving(false);
    };

    return (
        <TagSidePanel>
            <div className="profile-mail">
                <Form
                    noValidate
                    onSubmit={handleSubmit(CreateOrganisationForm)}
                >
                    <div className="media">
                        <div className="media-body mt-0">
                            {!props.saving ? (
                                <div className="nlt-card-title-buttons">
                                    <button
                                        type="submit"
                                        className="nlt-button-inside-form-save"
                                    >
                                        Enregistrer
                                    </button>
                                    <div
                                        aria-hidden="true"
                                        onClick={props.cancelEdit}
                                        className="nlt-button-inside-form-cancel"
                                    >
                                        Annuler
                                    </div>
                                </div>
                            ) : (
                                <div className="nlt-loader-container">
                                    <div className="nlt-loader-save" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="email-general">
                        <h6 className="mb-3">Informations sur le tag</h6>
                        <ul>
                            <Row className="nlt-row-form-fix">
                                <div className="nlt-item-info-label-edit col-md-5 required">
                                    Nom du tag
                                </div>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: (value) =>
                                            value?.trim()?.length > 0,
                                    }}
                                    render={({
                                        field: { ref, ...rest },
                                        fieldState: { error },
                                    }) => (
                                        <FormGroup className="col-md-7">
                                            {!error ? (
                                                <span className="mandatory-label-small" />
                                            ) : (
                                                <span className="nlt-mandatory-label-small nlt-font-red">
                                                    Le nom est requis.
                                                </span>
                                            )}
                                            <Input
                                                {...rest}
                                                className="form-control"
                                                type="text"
                                                placeholder="Marketing, Sales, Tech..."
                                                autoFocus
                                                innerRef={ref}
                                            />
                                        </FormGroup>
                                    )}
                                />
                            </Row>
                            <ColorPicker
                                title="Couleur du tag"
                                form={tagForm}
                                colorValue={props.colorValue}
                                setColorValue={props.setColorValue}
                            />
                        </ul>
                    </div>
                </Form>
            </div>
        </TagSidePanel>
    );
};

export default CreateTag;
