export default function OtherIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 15C10.2833 15 10.521 14.904 10.713 14.712C10.9043 14.5207 11 14.2833 11 14V10.8L11.9 11.7C12 11.8 12.1083 11.8707 12.225 11.912C12.3417 11.954 12.4667 11.975 12.6 11.975C12.7333 11.975 12.8583 11.954 12.975 11.912C13.0917 11.8707 13.2 11.8 13.3 11.7C13.4833 11.5167 13.575 11.2833 13.575 11C13.575 10.7167 13.4833 10.4833 13.3 10.3L10.7 7.7C10.5 7.5 10.2667 7.4 10 7.4C9.73333 7.4 9.5 7.5 9.3 7.7L6.7 10.3C6.51667 10.4833 6.425 10.7167 6.425 11C6.425 11.2833 6.51667 11.5167 6.7 11.7C6.88333 11.8833 7.11667 11.975 7.4 11.975C7.68333 11.975 7.91667 11.8833 8.1 11.7L9 10.8V14C9 14.2833 9.096 14.5207 9.288 14.712C9.47933 14.904 9.71667 15 10 15ZM2 18C1.45 18 0.979333 17.8043 0.588 17.413C0.196 17.021 0 16.55 0 16V4C0 3.45 0.196 2.97933 0.588 2.588C0.979333 2.196 1.45 2 2 2H7.175C7.44167 2 7.696 2.05 7.938 2.15C8.17933 2.25 8.39167 2.39167 8.575 2.575L10 4H18C18.55 4 19.021 4.196 19.413 4.588C19.8043 4.97933 20 5.45 20 6V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H2ZM2 4V16H18V6H9.175L7.175 4H2ZM2 4V16V4Z"
                fill="#353535"
            />
        </svg>
    );
}
