import React, { useEffect } from "react";

import ValidationIcon from "components/common/icons/navbar/validation-icon";
import ClearCartAction from "components/provider/context/cart/actions/clear-cart-action";
import { useCartDispatch } from "components/provider/context/cart/cart-provider";

import "./order-validation.scss";

interface ValidateOrderProps {
    type: ValidateType;
    onReturnCatalog: () => void;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}

export enum ValidateType {
    ERROR,
    SUCCESS,
}

const OrderValidation = (props: ValidateOrderProps) => {
    const cartDispatch = useCartDispatch();

    useEffect(() => {
        if (props.type === ValidateType.SUCCESS) {
            cartDispatch(new ClearCartAction());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="confirmation">
            {props.type === ValidateType.ERROR ? (
                <>
                    <div className="error-order">
                        <div className="end-icon">
                            <i className="icofont icofont-error" />
                        </div>
                        <div
                            className="error-order-title"
                            data-cy="error-order-title"
                        >
                            Oops, une erreur s'est produite !
                        </div>
                        <div className="error-order-messages">
                            <p className="error-order-message">
                                Il semble qu'une erreur se soit glissée dans la
                                création de votre commande.
                            </p>
                            <p className="error-order-message">
                                Pas de panique on a la solution. 💪
                            </p>
                            <div className="error-order-steps">
                                <p className="error-order-message">
                                    <span className="error-order-message-bold">
                                        Étape 1 :{" "}
                                    </span>
                                    Vérifiez sur votre historique des commandes
                                    que votre commande est bien présente, si
                                    c'est le cas tout va bien elle sera traitée
                                    rapidement !
                                    <br />
                                    <span className="error-order-message-bold">
                                        Étape 2 :{" "}
                                    </span>
                                    Si votre commande n'apparaît pas dans votre
                                    historique, vous pouvez retenter la commande
                                    en retournant sur{" "}
                                    <span
                                        className="error-order-message-link"
                                        aria-hidden="true"
                                        onClick={() => props.setStep(1)}
                                    >
                                        le catalogue
                                    </span>
                                    .
                                    <br />
                                    <span className="error-order-message-bold">
                                        Etape 3 :{" "}
                                    </span>
                                    Si ce message apparaît de nouveau, contactez
                                    nous dans{" "}
                                    <span
                                        className="error-order-message-link"
                                        aria-hidden="true"
                                        onClick={() => {
                                            window.HubSpotConversations.widget.open();
                                        }}
                                    >
                                        le chat
                                    </span>
                                    .
                                </p>
                            </div>
                        </div>
                        <div className="validation-button">
                            <button
                                type="button"
                                onClick={() => {
                                    props.onReturnCatalog();
                                }}
                                className="cleaq-button"
                                id="error-order-button"
                            >
                                Historique des commandes
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                <div className="validation">
                    <div className="end-icon-validation">
                        <ValidationIcon color="white" />
                    </div>
                    <div
                        className="validation-title"
                        data-cy="validation-title"
                    >
                        Votre commande a bien été enregistrée !
                    </div>
                    <div className="validation-message">
                        <p>Nous vous remercions pour votre confiance.</p>
                        <p>
                            Vous recevrez dans les prochaines minutes une
                            invitation à signer en ligne les documents
                            <br />
                            relatifs à cette commande. Cette signature
                            confirmera définitivement votre commande.
                        </p>
                        <p>
                            Notre équipe reste à votre écoute pour toute
                            question.
                        </p>
                    </div>
                    <div className="validation-button">
                        <button
                            type="button"
                            onClick={() => {
                                props.onReturnCatalog();
                            }}
                            className="cleaq-button"
                            id="confirmation-button"
                        >
                            Retour à la liste de commandes
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderValidation;
