import React from "react";

import BankDetailsForm from "components/business/BankDetailsForm/BankDetailsForm";
import BillingContact from "components/business/Billing/BillingContact";
import SmallScreen from "components/common/layout/banner/small-screen";
import FileManager from "components/common/layout/file-manager/file-manager";
import {
    BankDetailsFragment,
    FileType,
    useFindBankDetailsQuery,
    useFindInstanceQuery,
} from "graphql/management/model/apollo";

import styles from "./Billing.module.scss";

const Billing = () => {
    const instanceQuery = useFindInstanceQuery();

    const bankDetailsQuery = useFindBankDetailsQuery();

    const defaultBankDetails =
        bankDetailsQuery.data === undefined ||
        bankDetailsQuery.data.currentInstance === null ||
        bankDetailsQuery.data.currentInstance.bankDetails === null
            ? ({
                  iban: "",
                  bic: "",
              } as BankDetailsFragment)
            : bankDetailsQuery.data.currentInstance.bankDetails;

    if (bankDetailsQuery.loading || instanceQuery.loading) return null;

    const bills = instanceQuery.data?.currentInstance?.files?.filter(
        (file) =>
            file.fileType === FileType.BILLING ||
            file.fileType === FileType.SUBSCRIPTION_INVOICE,
    );

    return (
        <>
            <div
                className={styles.billingPage}
                data-cy="billing-page"
            >
                <BillingContact />
                <div className={styles.bankDetailsContainer}>
                    <BankDetailsForm defaultBankDetails={defaultBankDetails} />
                </div>
                <div className={styles.files}>
                    <FileManager
                        files={bills}
                        loading={instanceQuery.loading}
                        title="Mes Factures"
                    />
                </div>
            </div>
            <SmallScreen />
        </>
    );
};

export default Billing;
