import React from "react";

interface BravasIconProps {
    size: number;
}

const BravasIcon = ({ size }: BravasIconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 58.13 58.89"
        width={size}
        height={size}
        fill="#2e33dd"
    >
        <circle
            className="a"
            cx="29.06"
            cy="9.69"
            r="9.69"
        />
        <circle
            className="a"
            cx="29.06"
            cy="49.2"
            r="9.69"
        />
        <circle
            className="a"
            cx="9.69"
            cy="29.06"
            r="9.69"
        />
        <circle
            className="a"
            cx="48.44"
            cy="29.06"
            r="9.69"
        />
    </svg>
);

export default BravasIcon;
