import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";
import { Col } from "reactstrap";

const SkeletonDashboardDeviceList = () => (
    <Col
        xl={4}
        md={4}
        sm={12}
    >
        <h6>
            <Skeleton width={60} />
        </h6>
        <div className="table table-bordernone">
            <div className="nlt-ranked-list">
                <div className="nlt-ranked-device-sep" />
                <div className="nlt-ranked-device-name capitalise">
                    <Skeleton width={90} />
                </div>
                <div className="nlt-ranked-device-quantity">
                    <Skeleton width={15} />
                </div>
            </div>
        </div>
    </Col>
);

export default SkeletonDashboardDeviceList;
