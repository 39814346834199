import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";
import { v4 as uuid } from "uuid";

import "./skeleton-checkbox-filter.scss";

const SkeletonCheckboxFilter = () => (
    <div className="filter">
        <div className="filter-title">
            <h6>
                <Skeleton width={150} />
            </h6>
        </div>

        <div className="filter-options">
            <div className="skeleton-filters">
                {[...Array(4)].map((_) => (
                    <div
                        className="skeleton-filters-row"
                        key={uuid()}
                    >
                        <Skeleton
                            width={20}
                            height={30}
                        />
                        <Skeleton width={80} />
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default SkeletonCheckboxFilter;
