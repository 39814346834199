import { DeviceOs, DeviceType } from "graphql/management/model/apollo";

import { osLabels, typesLabels } from "./device-labels";

export function convertToDeviceType(DeviceTypeLabel: string) {
    let deviceType = DeviceType.OTHER;
    typesLabels.forEach((_, key) => {
        if (key.toString() === DeviceTypeLabel) {
            deviceType = key;
        }
    });
    return deviceType;
}

export function convertToOsType(DeviceOsLabel: string) {
    let deviceOs = DeviceOs.OTHER;
    osLabels.forEach((_, key) => {
        if (key.toString() === DeviceOsLabel) {
            deviceOs = key;
        }
    });
    return deviceOs;
}
