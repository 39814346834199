import React from "react";

interface UserIconProps {
    color?: string;
}

export default function UserIcon({ color = "black" }: UserIconProps) {
    return (
        <svg
            width="25"
            height="17"
            viewBox="0 0 25 17"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.42577 7.2573C5.69995 7.2573 6.7359 6.22135 6.7359 4.94717C6.7359 3.67299 5.69995 2.63704 4.42577 2.63704C3.15159 2.63704 2.11564 3.67299 2.11564 4.94717C2.11564 6.22135 3.15159 7.2573 4.42577 7.2573ZM20.5967 7.2573C21.8709 7.2573 22.9068 6.22135 22.9068 4.94717C22.9068 3.67299 21.8709 2.63704 20.5967 2.63704C19.3225 2.63704 18.2866 3.67299 18.2866 4.94717C18.2866 6.22135 19.3225 7.2573 20.5967 7.2573ZM21.7518 8.41237H19.4416C18.8063 8.41237 18.2324 8.66865 17.8137 9.08375C19.2684 9.88147 20.3007 11.3217 20.5245 13.0326H22.9068C23.5457 13.0326 24.0619 12.5165 24.0619 11.8776V10.7225C24.0619 9.44832 23.0259 8.41237 21.7518 8.41237ZM12.5112 8.41237C14.7456 8.41237 16.554 6.60397 16.554 4.36963C16.554 2.1353 14.7456 0.326904 12.5112 0.326904C10.2769 0.326904 8.4685 2.1353 8.4685 4.36963C8.4685 6.60397 10.2769 8.41237 12.5112 8.41237ZM15.2834 9.56743H14.9838C14.233 9.92839 13.3992 10.145 12.5112 10.145C11.6233 10.145 10.7931 9.92839 10.0387 9.56743H9.73907C7.44338 9.56743 5.58083 11.43 5.58083 13.7257V14.7652C5.58083 15.7218 6.35689 16.4978 7.31343 16.4978H17.709C18.6656 16.4978 19.4416 15.7218 19.4416 14.7652V13.7257C19.4416 11.43 17.5791 9.56743 15.2834 9.56743ZM7.20876 9.08375C6.79004 8.66865 6.21612 8.41237 5.58083 8.41237H3.2707C1.99652 8.41237 0.960571 9.44832 0.960571 10.7225V11.8776C0.960571 12.5165 1.47674 13.0326 2.11564 13.0326H4.49435C4.72175 11.3217 5.75409 9.88147 7.20876 9.08375Z"
                fill={color}
            />
        </svg>
    );
}
