import React from "react";

interface PaperPlaneIconProps {
    color?: string;
}

export default function PaperPlaneIcon({
    color = "black",
}: PaperPlaneIconProps) {
    return (
        <svg
            width="23"
            height="19"
            viewBox="0 0 23 19"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.8776 0.887119L1.89097 4.18455C1.11126 4.31167 0.71576 5.33228 1.15656 5.90726L3.76498 9.29184L17.7845 3.82928C18.0539 3.72264 18.1664 4.13625 17.908 4.28651L4.63861 12.0408L3.13742 15.1877C2.69732 16.1102 3.44622 16.9138 4.21818 16.4614L7.71472 14.42L10.7736 18.3842C11.1213 18.838 11.8115 18.7401 12.181 18.1822L22.5672 2.40619C23.0539 1.66903 22.6239 0.763286 21.8776 0.887119Z"
                fill={color}
            />
        </svg>
    );
}
