import React, { useState } from "react";

import { createPortal } from "react-dom";

import AddressModal from "components/business/order/step-form/AssignAddress/AddressModal/AddressModal";
import { InstanceFragment } from "graphql/management/model/apollo";

import AddressList from "./address-list/address-list";
import SkeletonAddressList from "./skeleton-address-list/skeleton-address-list";

interface ShippingAddressProps {
    instance: InstanceFragment | undefined | null;
    loading: boolean;
}

export default function ShippingAddress({
    instance,
    loading,
}: ShippingAddressProps) {
    const [addressForm, setAddressForm] = useState(false);

    return (
        <div className="col-md-4">
            <div className="custom-card col-12 pull-right">
                {loading ? (
                    <SkeletonAddressList />
                ) : (
                    <>
                        <AddressList
                            addresses={instance?.secondaryAddresses || []}
                            setAddressForm={setAddressForm}
                        />
                        {addressForm &&
                            createPortal(
                                <AddressModal
                                    onToggleAddressForm={() =>
                                        setAddressForm(false)
                                    }
                                    address={null}
                                    selectedProduct={null}
                                />,
                                document.getElementById(
                                    "portal",
                                ) as HTMLElement,
                            )}
                    </>
                )}
            </div>
        </div>
    );
}
