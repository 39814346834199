import React from "react";

import BankDetailsForm from "components/business/BankDetailsForm/BankDetailsForm";
import { BankDetailsFragment } from "graphql/management/model/apollo";

import styles from "./Signatory.module.scss";

interface SignatoryProps {
    defaultBankDetails: BankDetailsFragment;
}

const Signatory = (props: SignatoryProps) => (
    <div className={styles.signatoryLayout}>
        <h2 className={styles.title}>Informations de prélèvement</h2>
        <div className={styles.formsContainer}>
            <div className={styles.bankDetailsFormContainer}>
                <BankDetailsForm
                    defaultBankDetails={props.defaultBankDetails}
                />
            </div>
        </div>
    </div>
);
export default Signatory;
