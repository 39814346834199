import React, { useState } from "react";

import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, Check, Close } from "@material-ui/icons";

import "./multiple-select-filter.scss";

interface Option {
    label: string | null;
    onClick: () => void;
}

interface MultipleSelectFilterInfo {
    title: string;
    selectedTitle: string;
    options: Option[];
    resetFilter: () => void;
    "data-cy": string;
}

const MultipleSelectFilter = (props: MultipleSelectFilterInfo) => {
    const [selectedFilterIndex, setSelectedFilterIndex] = useState<number[]>(
        [],
    );
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleOnclick = (index: number) => {
        if (selectedFilterIndex.includes(index)) {
            const newList = selectedFilterIndex.filter(
                (number) => number !== index,
            );
            setSelectedFilterIndex(newList);
            props.options[index].onClick();
        } else {
            const newList = [...selectedFilterIndex, index];
            setSelectedFilterIndex(newList);
            props.options[index].onClick();
        }
    };

    return (
        <div className="multiple-select-filter">
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
            >
                <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    data-cy={`${props["data-cy"]}-button`}
                >
                    {selectedFilterIndex && selectedFilterIndex.length > 0 ? (
                        <div className="button-label">
                            <div>
                                {selectedFilterIndex.length}{" "}
                                {props.selectedTitle}
                            </div>
                            <Close
                                data-cy={`${props["data-cy"]}-reset`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.resetFilter();
                                    setSelectedFilterIndex([]);
                                }}
                                fontSize="small"
                                className="multi-select-reset-icon"
                            />
                        </div>
                    ) : (
                        <div>
                            {props.title}
                            {open ? (
                                <ArrowDropUp
                                    data-cy={`${props["data-cy"]}-fold`}
                                />
                            ) : (
                                <ArrowDropDown
                                    data-cy={`${props["data-cy"]}-unfold`}
                                />
                            )}
                        </div>
                    )}
                </Button>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement="bottom-start"
            >
                {({ TransitionProps }) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Grow {...TransitionProps}>
                        <ClickAwayListener
                            onClickAway={() => {
                                setOpen(false);
                            }}
                        >
                            <Paper data-cy={`${props["data-cy"]}-options`}>
                                <MenuList
                                    id="split-button-menu"
                                    autoFocusItem
                                >
                                    {props.options.map((option, index) => (
                                        <MenuItem
                                            data-cy={`${
                                                props["data-cy"]
                                            }-option-${index + 1}`}
                                            key={option.label}
                                            onClick={(event) =>
                                                handleOnclick(index)
                                            }
                                        >
                                            <div>{option.label}</div>
                                            <div className="multi-slect-filter-checkbox">
                                                {selectedFilterIndex.includes(
                                                    index,
                                                ) && (
                                                    <Check
                                                        fontSize="small"
                                                        stroke="black"
                                                    />
                                                )}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Paper>
                        </ClickAwayListener>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default MultipleSelectFilter;
