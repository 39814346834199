/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { type UseFormReturn } from "react-hook-form";
import { Form } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import type { TagType } from "components/business/tag/types";
import {
    OrganisationFragment,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    useUpdateOrganisationMutation,
} from "graphql/management/model/apollo";

import DeleteSingleTag from "../delete/delete-single-tag";
import TagSidePanel from "../layout/sidepanel/tag-sidepanel";

import TagDetailsDisplay from "./display/display-tag";
import TagDetailsUpdate from "./update/update-tag";

interface tagDetailsInterface {
    tagForm: UseFormReturn<TagType>;
    editable: boolean;
    setEditable: React.Dispatch<React.SetStateAction<boolean>>;
    saving: boolean;
    setSaving: React.Dispatch<React.SetStateAction<boolean>>;
    colorValue: string;
    setColorValue: React.Dispatch<React.SetStateAction<string>>;
    selectedTag: OrganisationFragment | null | undefined;
    setSelectedTag: React.Dispatch<
        React.SetStateAction<OrganisationFragment | null | undefined>
    >;
    cancelEdit: () => void;
}

const CleaqSwal = withReactContent(Swal);

const TagDetails = (props: tagDetailsInterface) => {
    const { handleSubmit } = props.tagForm;

    const [updateOrganisation] = useUpdateOrganisationMutation();

    const UpdateOrganisationForm = async (dataPush: TagType) => {
        try {
            await updateOrganisation({
                variables: {
                    name: dataPush.name,
                    id: props.selectedTag?.id!,
                    hexaColor: dataPush.hexaColor.toUpperCase(),
                },
                refetchQueries: [
                    refetchFindUsersQuery(),
                    refetchFindDevicesQuery(),
                    refetchFindOrganisationsQuery(),
                ],
                update: async (_, result) => {
                    if (result.data?.updateOrganisation) {
                        props.setSelectedTag(result.data.updateOrganisation);
                    }
                },
            });
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Contactez-nous dans le chat !",
                icon: "error",
            });
        }
        props.setEditable(false);
        props.setSaving(false);
    };

    return (
        <TagSidePanel>
            <div className="profile-mail">
                <Form onSubmit={handleSubmit(UpdateOrganisationForm)}>
                    <div className="media">
                        <div className="media-body mt-0">
                            {!props.saving ? (
                                <div className="nlt-card-title-buttons">
                                    <div>
                                        <a
                                            aria-hidden="true"
                                            className="nlt-button-inside"
                                            hidden={props.editable}
                                            onClick={() => {
                                                props.setEditable(true);
                                                props.setSelectedTag(
                                                    props.selectedTag,
                                                );
                                            }}
                                        >
                                            <i className="icofont icofont-pencil-alt-2 mr-2" />
                                            Editer
                                        </a>
                                    </div>
                                    <div className="float-left">
                                        <a
                                            aria-hidden="true"
                                            className="nlt-button-inside-close"
                                            onClick={() => {
                                                props.setSelectedTag(null);
                                            }}
                                            hidden={props.editable}
                                        >
                                            <i className="icofont icofont-close-line" />
                                        </a>
                                    </div>
                                    <div>
                                        {props.editable && (
                                            <DeleteSingleTag
                                                selectedTag={props.selectedTag}
                                                setSelectedTag={
                                                    props.setSelectedTag
                                                }
                                            />
                                        )}
                                    </div>
                                    <button
                                        type="submit"
                                        className="nlt-button-inside-form-save"
                                        hidden={!props.editable}
                                    >
                                        Enregistrer
                                    </button>
                                    <div
                                        aria-hidden="true"
                                        className="nlt-button-inside-cancel"
                                        onClick={props.cancelEdit}
                                        hidden={!props.editable}
                                    >
                                        Annuler
                                    </div>
                                </div>
                            ) : (
                                <div className="nlt-loader-container">
                                    <div className="nlt-loader-save" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="nlt-wide-card-right-panel-container">
                        <h6 className="mb-3">Informations sur le tag</h6>
                        <ul>
                            {props.editable ? (
                                <TagDetailsUpdate
                                    tagForm={props.tagForm}
                                    colorValue={props.colorValue}
                                    setColorValue={props.setColorValue}
                                />
                            ) : (
                                <TagDetailsDisplay
                                    selectedTag={props.selectedTag}
                                    setSelectedTag={props.setSelectedTag}
                                />
                            )}
                        </ul>
                    </div>
                </Form>
            </div>
        </TagSidePanel>
    );
};

export default TagDetails;
