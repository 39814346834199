import React, { useState } from "react";

import { withRouter } from "react-router-dom";

import Filters from "components/business/order/order-history-list/filters/filters";
import HistoryItemsList from "components/business/order/order-history-list/history-items-list";
import SmallScreen from "components/common/layout/banner/small-screen";
import SkeletonItem from "components/common/layout/loader/skeleton-item";
import SearchBar from "components/common/layout/searchbar/searchbar";
import ApiClientNames from "graphql/consts";
import { OrderStatus, useFindOrdersQuery } from "graphql/order/model/apollo";

import "./order-history.scss";

const OrderHistory = (props: any) => {
    const findOrdersQuery = useFindOrdersQuery({
        context: { clientName: ApiClientNames.ORDER },
        fetchPolicy: "cache-and-network",
    });

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOrderStatus, setFilteredOrderStatus] = useState<
        OrderStatus[]
    >([]);
    const [toggleSortDate, setToggleSortDate] = useState(false);

    const handleSearchChange = (event: any) => {
        event.preventDefault();
        setSearchTerm(event.target.value);
    };

    return (
        <>
            <div className="order-history">
                <div className="order-list-title ">
                    <span className="nlt-card-title">Mes commandes</span>
                </div>
                <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleSearchChange={handleSearchChange}
                />
                <Filters
                    filteredOrderStatus={filteredOrderStatus}
                    setFilteredOrderStatus={setFilteredOrderStatus}
                    setToggleSortDate={setToggleSortDate}
                    sortDateValue={toggleSortDate}
                />
                {findOrdersQuery.loading ? (
                    <SkeletonItem />
                ) : (
                    <HistoryItemsList
                        searchTerm={searchTerm}
                        orderList={findOrdersQuery?.data?.findOrders}
                        filteredOrderStatus={filteredOrderStatus}
                        sortOrderByDateAsc={toggleSortDate}
                    />
                )}
            </div>
            <SmallScreen />
        </>
    );
};

export default withRouter(OrderHistory);
