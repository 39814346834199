import React from "react";

import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import styles from "./SkeletonIntegration.module.scss";

const SkeletonIntegration = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.left}>
                    <Skeleton
                        circle
                        height={150}
                        width={150}
                        baseColor="#e1deda"
                        highlightColor="#fcf9f4"
                    />
                    <Skeleton
                        height={31}
                        width={96}
                        borderRadius={8}
                        baseColor="#e1deda"
                        highlightColor="#fcf9f4"
                    />
                    <Skeleton
                        width={46}
                        baseColor="#e1deda"
                        highlightColor="#fcf9f4"
                    />
                </div>
                <div className={styles.right}>
                    <div className={styles.header}>
                        <div>
                            <Skeleton
                                height={44}
                                width={200}
                                baseColor="#e1deda"
                                highlightColor="#fcf9f4"
                            />
                            <Skeleton
                                height={24}
                                width={300}
                                baseColor="#e1deda"
                                highlightColor="#fcf9f4"
                            />
                        </div>
                        <Skeleton
                            height={40}
                            width={83}
                            baseColor="#e1deda"
                            highlightColor="#fcf9f4"
                        />
                    </div>
                    <div className={styles.tabsContainer}>
                        <Skeleton
                            containerClassName={styles.tabs}
                            count={2}
                            height={20}
                            width={100}
                            baseColor="#e1deda"
                            highlightColor="#fcf9f4"
                        />
                    </div>
                    <div className={styles.longDescription}>
                        <Skeleton
                            height={20}
                            width={400}
                            baseColor="#e1deda"
                            highlightColor="#fcf9f4"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonIntegration;
