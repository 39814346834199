import React from "react";

import { Row } from "reactstrap";

import { InstanceFragment } from "graphql/management/model/apollo";
import displayCountryName from "utils/country/display-country";

interface companyDetailsViewLineProps {
    label: string;
    data: string | null | undefined;
    "data-cy": string;
}

const CompanyDetailsViewLine = (props: companyDetailsViewLineProps) => (
    <div
        style={{ display: "flex" }}
        className="mb-2"
    >
        <div className="nlt-item-info-label">{props.label}</div>
        <div
            className="nlt-item-info-data"
            data-cy={props["data-cy"]}
        >
            {props.data}
        </div>
    </div>
);

interface CompanyDetailsViewProps {
    instance: InstanceFragment | undefined | null;
}

export default function CompanyDetailsView({
    instance: currentInstance,
}: CompanyDetailsViewProps) {
    return (
        <Row className="m-b-12">
            <div className="col-md-12">
                <div className="col-12">
                    <div>
                        <h5 className="mb-3">Adresse de facturation</h5>
                        <div className="row">
                            <ul className="col-6">
                                <CompanyDetailsViewLine
                                    label="SIREN"
                                    data={currentInstance?.siren}
                                    data-cy="siren"
                                />
                                <CompanyDetailsViewLine
                                    label="Nom du contact"
                                    data={currentInstance?.representativeName}
                                    data-cy="representativeName"
                                />
                                <CompanyDetailsViewLine
                                    label="N° de téléphone"
                                    data={
                                        currentInstance?.shippingAddress
                                            ?.phoneNumber
                                    }
                                    data-cy="phoneNumber"
                                />
                                <CompanyDetailsViewLine
                                    label="Adresse e-mail"
                                    data={currentInstance?.email}
                                    data-cy="email"
                                />
                            </ul>
                            <ul className="col-6">
                                <CompanyDetailsViewLine
                                    label="À l'attention de"
                                    data={
                                        currentInstance?.shippingAddress
                                            ?.recipient
                                    }
                                    data-cy="recipient"
                                />
                                <CompanyDetailsViewLine
                                    label="Adresse"
                                    data={
                                        currentInstance?.shippingAddress
                                            ?.addressName
                                    }
                                    data-cy="addressName"
                                />
                                <CompanyDetailsViewLine
                                    label="Code Postal"
                                    data={
                                        currentInstance?.shippingAddress
                                            ?.zipCode
                                    }
                                    data-cy="zipCode"
                                />
                                <CompanyDetailsViewLine
                                    label="Commune"
                                    data={
                                        currentInstance?.shippingAddress
                                            ?.cityName
                                    }
                                    data-cy="cityName"
                                />
                                <CompanyDetailsViewLine
                                    label="Pays"
                                    data={displayCountryName(
                                        currentInstance?.shippingAddress
                                            ?.country as string,
                                    )}
                                    data-cy="country"
                                />
                                <CompanyDetailsViewLine
                                    label="Instructions de livraison"
                                    data={
                                        currentInstance?.shippingAddress
                                            ?.instructions
                                    }
                                    data-cy="instructions"
                                />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    );
}
