import React from "react";

import { withRouter } from "react-router-dom";
import { Row } from "reactstrap";

import AccountCompanies from "components/business/account/account-companies/account-companies";
import AccountDevices from "components/business/account/account-devices/account-devices";
import AccountForm from "components/business/account/account-form/account-form";
import Loader from "components/common/layout/loader/loader";
import { useFindCurrentUserQuery } from "graphql/management/model/apollo";
import useEffectOnce from "utils/use-effect-once/use-effect-once";

import "./account.scss";

const Account = () => {
    const currentUser = useFindCurrentUserQuery();
    const user = currentUser?.data?.findCurrentUser;

    useEffectOnce(() => {
        currentUser.refetch();
    });

    if (currentUser.loading) return <Loader />;

    return (
        <>
            <div
                id="account"
                className="nlt-container spacing-page"
            >
                <div className="nlt-container-scale email-wrap bookmark-wrap">
                    <Row>
                        <div className="nlt-card-document">
                            {user && <AccountForm user={user} />}
                        </div>
                        <AccountDevices user={user} />
                        <AccountCompanies />
                    </Row>
                </div>
            </div>
            <div className="nlt-responsive-security">
                <div>
                    Désolé, Cleaq n'est actuellement pas disponible sur petits
                    écrans.
                </div>
            </div>
        </>
    );
};

export default withRouter(Account);
