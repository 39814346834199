import React from "react";

import { DeviceComputedStatus } from "graphql/management/model/apollo";

import "./card-dashboard.scss";

interface CardDashboardInfo {
    title: string;
    count: number;
    status: DeviceComputedStatus;
    redirectFunction: () => void;
}

const CardDashboard = (props: CardDashboardInfo) => {
    const StatusClass = {
        TO_LINK: "card-to-link",
        BEING_USED: "card-being-used",
        INCIDENT: "card-incident",
        MAINTENANCE: "card-maintenance",
        AVAILABLE: "card-available",
    };

    return (
        <div
            aria-hidden="true"
            className={`card-dashboard  ${StatusClass[props.status]}`}
            onClick={props.redirectFunction}
        >
            <div className="dashboard-card-content">
                <h1>{props.count}</h1>
                <h4 className="dashboard-card-title">{props.title}</h4>
            </div>
        </div>
    );
};

export default CardDashboard;
