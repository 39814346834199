import React from "react";

import displayCountryName from "utils/country/display-country";

interface AddressItemProps {
    label: string;
    addressName: string;
    phoneNumber: string;
    zipcode: string;
    country: string;
    onClick: () => void;
}

const AddressItem = (props: AddressItemProps) => {
    const { label, addressName, phoneNumber, zipcode, country, onClick } =
        props;

    return (
        <div
            key={label}
            className="address-container2"
        >
            <div className="address-layout">
                <div className="address">
                    <div className="address-name">{label}</div>
                    <div className="address-text">{addressName}</div>
                    <div className="address-text">{phoneNumber}</div>
                    <div className="address-text">{zipcode}</div>
                    <div className="address-text">
                        {displayCountryName(country)}
                    </div>
                </div>
                <div
                    className="address-delete"
                    aria-hidden="true"
                    onClick={onClick}
                >
                    <div>
                        <i className="icofont icofont-bin bin-color" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddressItem;
