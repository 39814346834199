import React, { useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Collapse } from "reactstrap";

import {
    currentUserFragment,
    currentUserDeviceFragment,
    useFindDeviceIncidentQuery,
    IncidentFragment,
    IncidentReason,
} from "graphql/management/model/apollo";
import iconDeviceLink from "utils/device/device-icons-links";

const IncidentLabels = new Map([
    [IncidentReason.BROKEN, "Casse"],
    [IncidentReason.BREAKDOWN, "Panne"],
    [IncidentReason.STOLEN, "Vol"],
]);

interface DevicesProps {
    devices: Array<currentUserDeviceFragment>;
}

interface AccountDevicesProps {
    user: currentUserFragment | null | undefined;
}

interface DeviceWithIncident {
    device: currentUserDeviceFragment;
    incident?: IncidentFragment;
}

const DevicesList = (props: DevicesProps) => {
    const history = useHistory();
    const incidentsQuery = useFindDeviceIncidentQuery();
    const [showIncident, setShowIncident] = useState<string | null>(null);

    if (incidentsQuery.loading)
        return (
            <div className="loader-wrapper">
                <CircularProgress color="primary" />
            </div>
        );

    const devices: DeviceWithIncident[] = props.devices.map((device) => ({
        device,
        incident: incidentsQuery.data?.findDeviceIncident?.find(
            (incident) => incident.serialNumber === device.serialNumber,
        ),
    }));

    function toggleIncident(incidentId: string) {
        if (incidentId === showIncident) {
            setShowIncident(null);
        } else {
            setShowIncident(incidentId);
        }
    }

    function isToggle(incidentId: string): boolean {
        return incidentId === showIncident;
    }

    const redirectTo = (redirect: any, serial: string) => {
        history.push({
            pathname: redirect,
            state: { serialSearch: serial },
        });
    };

    const IncidentsAccordion = (device: DeviceWithIncident) =>
        device.incident?.id && (
            <li key={device.incident.id}>
                <span className="nlt-card-title-buttons">
                    <i
                        aria-hidden="true"
                        className="fa fa-chevron-right nlt-card-toggle-button"
                        onClick={() => toggleIncident(device.incident?.id!)}
                        style={
                            isToggle(device!.incident!.id!)
                                ? { transform: "rotate(90deg)" }
                                : { transform: "rotate(0deg)" }
                        }
                    />
                </span>
                <div className="nlt-incident-item">
                    <div className="nlt-list-item-device-name pull-left">
                        {device.device.reference}
                    </div>
                    <div className="nlt-list-item-device-state pull-left">
                        {IncidentLabels.get(device!.incident!.reason!)}
                    </div>
                </div>

                <Collapse
                    isOpen={isToggle(device!.incident!.id!)}
                    className="nlt-incident-text"
                >
                    {device!.incident!.details || "Aucun détail de l'incident"}
                </Collapse>
            </li>
        );

    return (
        <>
            <ul>
                {devices.map((device) => (
                    <li
                        key={device.device.id}
                        style={{ marginBottom: "5px" }}
                    >
                        <div
                            className="device-wrapper"
                            style={{
                                cursor:
                                    device.incident != null
                                        ? "pointer"
                                        : "unset",
                            }}
                            aria-hidden="true"
                            onClick={() => {
                                if (device.incident != null) {
                                    toggleIncident(device.incident.id);
                                }
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    userSelect: "none",
                                }}
                            >
                                <div
                                    style={{
                                        width: "50px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        className="nlt-ranked-device-icon"
                                        src={iconDeviceLink(
                                            device.device.deviceType!,
                                        )}
                                        alt={device.device.deviceType!}
                                    />
                                </div>
                                <p className="nlt-list-item-device-name">
                                    {device.device.reference}
                                </p>
                                {device.incident != null && (
                                    <p className="device-is-incident">
                                        <i className="icofont icofont-warning ml-3 mr-1" />
                                    </p>
                                )}
                            </div>
                            <div
                                aria-hidden="true"
                                className="nlt-button-inside-user"
                                onClick={() =>
                                    redirectTo(
                                        `${process.env.PUBLIC_URL}/devices`,
                                        device.device.serialNumber!,
                                    )
                                }
                            >
                                Voir
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            <h6 className="mt-4 title6">
                {devices.find((device) => device.incident)
                    ? "Mes incidents en cours"
                    : "Aucun incident déclaré"}
            </h6>
            <ul>{devices.map((device) => IncidentsAccordion(device))}</ul>
        </>
    );
};

const AccountDevices = (props: AccountDevicesProps) => {
    const { user } = props;

    return (
        <div>
            <div className="nlt-card-full-company">
                <div className="nlt-card-header">
                    <span className="nlt-card-title">Mes appareils</span>
                </div>
                <div className="nlt-wide-card-right-panel-container">
                    {user?.devices && user.devices?.length > 0 ? (
                        <DevicesList devices={user.devices} />
                    ) : (
                        <p>Aucun appareil associé !</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AccountDevices;
