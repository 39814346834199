import React, { createContext, useContext, useReducer } from "react";

import { VariantFragment } from "graphql/catalog/model/apollo";

import TempCartAction from "./actions/temp-cart-action";
import tempCartReducer from "./temp-cart-reducer";
import { TempCart } from "./types";

export const TempCartContext = createContext<TempCart>(undefined!);
export const TempCartDispatchContext = createContext<
    React.Dispatch<TempCartAction>
>(() => {});

interface TempCartProviderProps {
    variants: VariantFragment[];
    children: React.ReactNode;
}

const TempCartProvider = ({ variants, children }: TempCartProviderProps) => {
    if (variants.length <= 0) {
        return <>{children}</>;
    }

    const orderedVariants =
        variants &&
        variants.sort((vA, vB) => {
            if (vA.product?.type === "smartphone") {
                return (vA.price24 ?? 0) - (vB.price24 ?? 0);
            }
            return (vA.price36 ?? 0) - (vB.price36 ?? 0);
        });

    const cheapestVariant = orderedVariants[0];

    const initDuration =
        cheapestVariant.product?.type === "smartphone" ? 24 : 36;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [tempCart, dispatch] = useReducer(
        tempCartReducer,
        new TempCart(cheapestVariant, [], 1, initDuration),
    );

    return (
        <TempCartContext.Provider value={tempCart}>
            <TempCartDispatchContext.Provider value={dispatch}>
                {children}
            </TempCartDispatchContext.Provider>
        </TempCartContext.Provider>
    );
};

export function useTempCart(): TempCart {
    return useContext(TempCartContext);
}

export function useTempCartDispatch(): React.Dispatch<TempCartAction> {
    return useContext(TempCartDispatchContext);
}

export default TempCartProvider;
