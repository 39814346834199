import React from "react";

import { Controller } from "react-hook-form";
import type { UseFormReturn } from "react-hook-form";
import { FormGroup, Input, Row } from "reactstrap";

import type { TagType } from "components/business/tag/types";
import ColorPicker from "components/common/input/color-picker/color-picker";

import "./update-tag.scss";

interface tagDetailsUpdateInterface {
    tagForm: UseFormReturn<TagType>;
    colorValue: string;
    setColorValue: React.Dispatch<React.SetStateAction<string>>;
}

const TagDetailsUpdate = (props: tagDetailsUpdateInterface) => (
    <ul>
        <Row className="nlt-row-form-fix fix-padding">
            <div className="nlt-item-info-label-edit col-md-5 required">
                Nom du tag
            </div>

            <Controller
                name="name"
                control={props.tagForm.control}
                rules={{
                    required: true,
                    validate: (value) => value?.trim()?.length > 0,
                }}
                render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                }) => (
                    <FormGroup className="col-md-7">
                        {!error ? (
                            <span className="mandatory-label-small" />
                        ) : (
                            <span className="nlt-mandatory-label-small nlt-font-red">
                                Le nom est requis
                            </span>
                        )}
                        <Input
                            {...rest}
                            className="form-control"
                            type="text"
                            placeholder="Marketing, Sales, Tech..."
                            innerRef={ref}
                        />
                    </FormGroup>
                )}
            />
        </Row>
        <ColorPicker
            title="Couleur du tag"
            form={props.tagForm}
            colorValue={props.colorValue}
            setColorValue={props.setColorValue}
        />
    </ul>
);

export default TagDetailsUpdate;
