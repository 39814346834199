export default function ScheduleIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.12576 0C4.68409 0 5.13671 0.452616 5.13671 1.01095V2.29111H13.4983V1.01095C13.4983 0.452616 13.9509 0 14.5092 0C15.0675 0 15.5202 0.452616 15.5202 1.01095V2.29111H15.7215C17.3248 2.29111 18.6245 3.59081 18.6245 5.19407V7.1617V9.09789C18.6245 9.65018 18.1768 10.0979 17.6245 10.0979C17.0722 10.0979 16.6245 9.65018 16.6245 9.09789V8.1617H9.31771H2.01095V15.9551C2.01095 16.4537 2.41521 16.858 2.9139 16.858H9.31771C9.86999 16.858 10.3177 17.3057 10.3177 17.858C10.3177 18.4103 9.86999 18.858 9.31771 18.858H2.9139C1.31065 18.858 0.0109462 17.5583 0.0109462 15.9551V7.1617V5.19407C0.0109462 3.59081 1.31064 2.29111 2.9139 2.29111H3.11481V1.01095C3.11481 0.452616 3.56743 0 4.12576 0ZM2.01095 6.1617H9.31771H16.6245V5.19407C16.6245 4.69538 16.2202 4.29111 15.7215 4.29111H2.9139C2.41521 4.29111 2.01095 4.69538 2.01095 5.19407V6.1617ZM18.9012 11.8135C18.243 11.1806 17.1155 10.8545 16.32 10.8545C14.4466 10.8545 12.8218 11.839 12.0178 13.279H10.7675C10.3734 13.279 10.0539 13.5985 10.0539 13.9926C10.0539 14.3867 10.3734 14.7062 10.7675 14.7062H11.5277C11.4988 14.8982 11.4831 15.0942 11.4814 15.2932H10.7675C10.3734 15.2932 10.0539 15.6127 10.0539 16.0068C10.0539 16.4009 10.3734 16.7204 10.7675 16.7204H11.72C12.3528 18.5101 14.1732 19.8029 16.32 19.8029C17.1155 19.8029 18.243 19.4768 18.9012 18.8439C19.5593 18.211 18.8561 17.1385 18.243 17.5391C17.6298 17.9397 17.2166 18.1126 16.32 18.1126C15.0967 18.1126 14.1242 17.5244 13.5994 16.7204H15.4234C15.8175 16.7204 16.137 16.4009 16.137 16.0068C16.137 15.6127 15.8175 15.2932 15.4234 15.2932H13.1718C13.1748 15.0952 13.2028 14.8983 13.2539 14.7062H15.4234C15.8175 14.7062 16.137 14.3867 16.137 13.9926C16.137 13.5985 15.8175 13.279 15.4234 13.279H14.1906C14.7332 12.8318 15.4655 12.5448 16.32 12.5448C17.2166 12.5448 17.6298 12.7177 18.243 13.1183C18.8561 13.5189 19.5593 12.4464 18.9012 11.8135Z"
                fill="#353535"
            />
        </svg>
    );
}
