/* eslint-disable react/require-default-props */
import React, { CSSProperties } from "react";

type InitialsProps = {
    customStyle?: CSSProperties;
    sizeImage?: number;
    firstName: string | null;
    lastName: string | null;
};

const Initials = (props: InitialsProps) => {
    const setStyle = (style: CSSProperties | undefined) => {
        const styles = {};
        if (style) {
            Object.assign(styles, style);
        }
        if (props.sizeImage) {
            Object.assign(styles, {
                minWidth: props.sizeImage,
                width: props.sizeImage,
                height: props.sizeImage,
            });
        }

        return styles;
    };

    function getFirstLetter() {
        return props.firstName?.charAt(0).toUpperCase();
    }

    function getSecondLetter() {
        return props.lastName?.charAt(0).toUpperCase();
    }

    function customStyle(): CSSProperties | undefined {
        if (props.sizeImage) {
            return { fontSize: props.sizeImage / 2 - props.sizeImage / 8 };
        }

        return undefined;
    }

    return (
        <div
            className="nlt-avatar"
            style={setStyle(props.customStyle)}
        >
            <p style={customStyle()}>
                {getFirstLetter()}
                {getSecondLetter()}
            </p>
        </div>
    );
};

export default Initials;
