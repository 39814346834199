/* eslint-disable */
// This file was automatically generated and should not be edited.
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends " $fragmentName" | "__typename"
              ? T[P]
              : never;
      };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    _FieldSet: { input: any; output: any };
};

export type AddressInput = {
    addressName: Scalars["String"]["input"];
    cityName: Scalars["String"]["input"];
    country: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["String"]["input"]>;
    instructions?: InputMaybe<Scalars["String"]["input"]>;
    label: Scalars["String"]["input"];
    phoneNumber: Scalars["String"]["input"];
    recipient?: InputMaybe<Scalars["String"]["input"]>;
    zipCode: Scalars["String"]["input"];
};

export type AddressOutput = {
    __typename: "AddressOutput";
    addressName: Scalars["String"]["output"];
    cityName: Scalars["String"]["output"];
    country: Scalars["String"]["output"];
    id: Scalars["String"]["output"];
    instructions: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    phoneNumber: Scalars["String"]["output"];
    recipient: Scalars["String"]["output"];
    zipCode: Scalars["String"]["output"];
};

export type AffectedUserOutput = {
    __typename: "AffectedUserOutput";
    email: Scalars["String"]["output"];
    firstName: Scalars["String"]["output"];
    id: Scalars["String"]["output"];
    lastName: Scalars["String"]["output"];
    organisations: Maybe<Array<OrganisationUser>>;
};

export type BankDetailsInput = {
    bic: Scalars["String"]["input"];
    iban: Scalars["String"]["input"];
};

export type BankDetailsOutput = {
    __typename: "BankDetailsOutput";
    bic: Maybe<Scalars["String"]["output"]>;
    iban: Maybe<Scalars["String"]["output"]>;
};

export type BravasIntegrationInput = {
    fqdn: Scalars["String"]["input"];
};

export type CarbonFootprintOutput = {
    __typename: "CarbonFootprintOutput";
    manufacturing: Scalars["Float"]["output"];
    transport: Scalars["Float"]["output"];
};

export type CarbonSavingsOutput = {
    __typename: "CarbonSavingsOutput";
    refurb: Scalars["Float"]["output"];
    repairs: Scalars["Float"]["output"];
};

export type CsvColumnMappingInput = {
    actualColumnName: Scalars["String"]["input"];
    sample: Array<Scalars["String"]["input"]>;
    suggestedColumn: OwnerCsvColumn;
};

export type CsvColumnMappingOutput = {
    __typename: "CsvColumnMappingOutput";
    actualColumnName: Scalars["String"]["output"];
    sample: Array<Scalars["String"]["output"]>;
    suggestedColumn: OwnerCsvColumn;
};

export type CsvMappingInput = {
    columnMapping: Array<CsvColumnMappingInput>;
    separator: CsvSeparator;
};

export type CsvMappingOutput = {
    __typename: "CsvMappingOutput";
    columnMapping: Array<CsvColumnMappingOutput>;
    separator: CsvSeparator;
};

export enum CsvSeparator {
    COMMA = "COMMA",
    PIPE = "PIPE",
    SEMICILON = "SEMICILON",
    TAB = "TAB",
}

export enum DeviceComputedStatus {
    AVAILABLE = "AVAILABLE",
    BEING_USED = "BEING_USED",
    INCIDENT = "INCIDENT",
    MAINTENANCE = "MAINTENANCE",
    TO_LINK = "TO_LINK",
}

export type DeviceEcoData = {
    __typename: "DeviceEcoData";
    carbonFootprint: Scalars["Float"]["output"];
    carbonSavings: Scalars["Float"]["output"];
};

export type DeviceInput = {
    customNotes?: InputMaybe<Scalars["String"]["input"]>;
    reference: Scalars["String"]["input"];
    serialNumber: Scalars["String"]["input"];
    specs: DeviceSpecsInput;
    status: DeviceStatus;
};

export type DeviceNameCount = {
    __typename: "DeviceNameCount";
    count: Scalars["Int"]["output"];
    deviceName: Scalars["String"]["output"];
};

export enum DeviceOs {
    ANDROID = "ANDROID",
    CHROME = "CHROME",
    IOS = "IOS",
    LINUX = "LINUX",
    MAC = "MAC",
    OTHER = "OTHER",
    WINDOWS = "WINDOWS",
}

export type DeviceOsCount = {
    __typename: "DeviceOsCount";
    count: Scalars["Int"]["output"];
    productOs: DeviceOs;
};

export type DeviceOutput = {
    __typename: "DeviceOutput";
    affectedDate: Maybe<Scalars["String"]["output"]>;
    affectedUser: Maybe<AffectedUserOutput>;
    contractFile: Maybe<InstanceFile>;
    createdAt: Scalars["String"]["output"];
    customNotes: Maybe<Scalars["String"]["output"]>;
    ecoData: Maybe<EcoDataOutput>;
    id: Scalars["String"]["output"];
    incident: Maybe<IncidentOutput>;
    invoiceFile: Maybe<InstanceFile>;
    leasingData: Maybe<LeasingDataOutput>;
    ownerInstanceId: Maybe<Scalars["String"]["output"]>;
    reference: Scalars["String"]["output"];
    scheduleFile: Maybe<InstanceFile>;
    serialNumber: Scalars["String"]["output"];
    source: DeviceSource;
    specs: DeviceSpecsOutput;
    status: DeviceStatus;
};

export enum DeviceSource {
    CLEAQ = "CLEAQ",
    OTHER = "OTHER",
}

export type DeviceSpecsInput = {
    deviceOs: DeviceOs;
    deviceType: DeviceType;
    memorySize?: InputMaybe<Scalars["String"]["input"]>;
    processor?: InputMaybe<Scalars["String"]["input"]>;
    screenSize?: InputMaybe<Scalars["String"]["input"]>;
    storageSize?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeviceSpecsOutput = {
    __typename: "DeviceSpecsOutput";
    deviceOs: DeviceOs;
    deviceType: DeviceType;
    memorySize: Maybe<Scalars["String"]["output"]>;
    processor: Maybe<Scalars["String"]["output"]>;
    screenSize: Maybe<Scalars["String"]["output"]>;
    storageSize: Maybe<Scalars["String"]["output"]>;
    variantId: Maybe<Scalars["String"]["output"]>;
};

export enum DeviceStatus {
    MAINTENANCE = "MAINTENANCE",
    OTHER = "OTHER",
    SPARE = "SPARE",
}

export type DeviceStatusCount = {
    __typename: "DeviceStatusCount";
    count: Scalars["Int"]["output"];
    deviceStatus: DeviceComputedStatus;
    devices: Array<DeviceNameCount>;
};

export enum DeviceType {
    DESKTOP = "DESKTOP",
    LAPTOP = "LAPTOP",
    OTHER = "OTHER",
    PHONE = "PHONE",
    SMARTPHONE = "SMARTPHONE",
    TABLET = "TABLET",
}

export type DeviceTypeAndOsCount = {
    __typename: "DeviceTypeAndOsCount";
    catalogProductType: DeviceType;
    count: Scalars["Int"]["output"];
    productOs: DeviceOs;
};

export type DeviceTypeCount = {
    __typename: "DeviceTypeCount";
    count: Scalars["Int"]["output"];
    productType: DeviceType;
};

export type DeviceUserOutput = {
    __typename: "DeviceUserOutput";
    deviceOs: Maybe<DeviceOs>;
    deviceType: Maybe<DeviceType>;
    id: Maybe<Scalars["String"]["output"]>;
    reference: Maybe<Scalars["String"]["output"]>;
    serialNumber: Maybe<Scalars["String"]["output"]>;
};

export type DeviceUserToAffectCount = {
    __typename: "DeviceUserToAffectCount";
    devicesCount: Scalars["Int"]["output"];
    usersCount: Scalars["Int"]["output"];
};

export type EcoDataOutput = {
    __typename: "EcoDataOutput";
    carbonFootprint: CarbonFootprintOutput;
    carbonSavings: CarbonSavingsOutput;
    repairabilityIndex: Maybe<Scalars["Float"]["output"]>;
};

export enum FileType {
    BILLING = "BILLING",
    CONTRACT = "CONTRACT",
    IMPORT = "IMPORT",
    OTHER = "OTHER",
    PROFILE = "PROFILE",
    SAAS_INVOICE = "SAAS_INVOICE",
    SCHEDULE = "SCHEDULE",
    SUBSCRIPTION_INVOICE = "SUBSCRIPTION_INVOICE",
}

export type IncidentInput = {
    details: Scalars["String"]["input"];
    proof: Scalars["String"]["input"];
    reason: IncidentReason;
    serialNumber: Scalars["String"]["input"];
    startingDate: Scalars["String"]["input"];
};

export type IncidentOutput = {
    __typename: "IncidentOutput";
    details: Scalars["String"]["output"];
    deviceName: Scalars["String"]["output"];
    deviceOs: DeviceOs;
    deviceType: DeviceType;
    id: Scalars["String"]["output"];
    instanceName: Scalars["String"]["output"];
    proof: Maybe<Scalars["String"]["output"]>;
    reason: IncidentReason;
    serialNumber: Scalars["String"]["output"];
    startingDate: Scalars["String"]["output"];
    state: IncidentState;
};

export enum IncidentReason {
    BREAKDOWN = "BREAKDOWN",
    BROKEN = "BROKEN",
    LOCK = "LOCK",
    STOLEN = "STOLEN",
}

export enum IncidentState {
    DECLARED = "DECLARED",
    FUNCTIONAL = "FUNCTIONAL",
    PROCESSING = "PROCESSING",
    REJECTED = "REJECTED",
    REPAIRING = "REPAIRING",
    SENT = "SENT",
}

export type InstanceDashboardOutput = {
    __typename: "InstanceDashboardOutput";
    deviceAffectedRate: Maybe<Scalars["Int"]["output"]>;
    deviceComputedStatus: Maybe<Array<DeviceStatusCount>>;
    deviceEcoData: DeviceEcoData;
    deviceNameCount: Maybe<Array<DeviceNameCount>>;
    deviceOsCount: Maybe<Array<DeviceOsCount>>;
    deviceTypeCount: Maybe<Array<DeviceTypeCount>>;
    deviceTypeCountAndOs: Maybe<Array<DeviceTypeAndOsCount>>;
    deviceUsersToAffect: Maybe<DeviceUserToAffectCount>;
};

export type InstanceFile = {
    __typename: "InstanceFile";
    createdAt: Maybe<Scalars["String"]["output"]>;
    fileName: Scalars["String"]["output"];
    filePath: Scalars["String"]["output"];
    fileType: Maybe<FileType>;
};

export type InstanceOutput = {
    __typename: "InstanceOutput";
    affectedCatalogs: Array<Scalars["String"]["output"]>;
    bankDetails: Maybe<BankDetailsOutput>;
    billingContacts: Maybe<Array<Scalars["String"]["output"]>>;
    crmId: Maybe<Scalars["String"]["output"]>;
    email: Maybe<Scalars["String"]["output"]>;
    files: Maybe<Array<InstanceFile>>;
    id: Scalars["String"]["output"];
    integrations: Array<IntegrationOutput>;
    name: Scalars["String"]["output"];
    preconfig: Maybe<Scalars["String"]["output"]>;
    profilePic: Maybe<InstanceFile>;
    representativeName: Maybe<Scalars["String"]["output"]>;
    secondaryAddresses: Maybe<Array<AddressOutput>>;
    shareId: Scalars["String"]["output"];
    shippingAddress: Maybe<AddressOutput>;
    siren: Maybe<Scalars["String"]["output"]>;
};

export type InstanceUpdateInput = {
    email: Scalars["String"]["input"];
    representativeName: Scalars["String"]["input"];
    shippingAddress: AddressInput;
};

export type IntegrationConfigOutput = {
    __typename: "IntegrationConfigOutput";
    name: Scalars["String"]["output"];
    value: Scalars["String"]["output"];
};

export type IntegrationOutput = {
    __typename: "IntegrationOutput";
    config: Array<IntegrationConfigOutput>;
    provider: IntegrationProvider;
    type: IntegrationType;
};

export enum IntegrationProvider {
    BRAVAS = "BRAVAS",
}

export enum IntegrationType {
    MDM = "MDM",
}

export type LeasingDataOutput = {
    __typename: "LeasingDataOutput";
    filesCode: Maybe<Scalars["String"]["output"]>;
    leasingEnd: Scalars["String"]["output"];
    leasingMonthsDuration: Scalars["Int"]["output"];
    leasingPrice: Maybe<Scalars["Float"]["output"]>;
    leasingStart: Scalars["String"]["output"];
    purchasePrice: Maybe<Scalars["Float"]["output"]>;
};

export type Mutation = {
    __typename: "Mutation";
    affectUserDevice: Scalars["Boolean"]["output"];
    affectUserOrganisation: Scalars["Boolean"]["output"];
    affectUsersOrganisations: Scalars["Boolean"]["output"];
    createBillingContacts: Scalars["Boolean"]["output"];
    createDevice: DeviceOutput;
    createIncident: Maybe<IncidentOutput>;
    createIntegration: Scalars["Boolean"]["output"];
    createOrganisation: OrganisationOutput;
    createShippingAddress: Maybe<AddressOutput>;
    createUser: UserOutput;
    deleteBillingContacts: Scalars["Boolean"]["output"];
    deleteDevices: Scalars["Boolean"]["output"];
    deleteDevicesAffectation: Scalars["Boolean"]["output"];
    deleteIntegration: Scalars["Boolean"]["output"];
    deleteOrganisation: Scalars["Boolean"]["output"];
    deleteOrganisations: Scalars["Boolean"]["output"];
    deleteShippingAddress: Scalars["Boolean"]["output"];
    deleteUser: Scalars["Boolean"]["output"];
    deleteUserOrganisation: Scalars["Boolean"]["output"];
    deleteUsersOrganisations: Scalars["Boolean"]["output"];
    loadOwnerCsvImport: Scalars["Boolean"]["output"];
    updateAddress: InstanceOutput;
    updateBankDetails: InstanceOutput;
    updateDevice: Maybe<DeviceOutput>;
    updateOrganisation: Maybe<OrganisationOutput>;
    updateSiren: Scalars["Boolean"]["output"];
    updateUser: UserOutput;
    updateUserInstance: Maybe<InstanceOutput>;
    updateUserPassword: Scalars["Boolean"]["output"];
};

export type MutationaffectUserDeviceArgs = {
    userDeviceInput: UserDeviceInput;
};

export type MutationaffectUserOrganisationArgs = {
    userOrganisationInput: UserOrganisationInput;
};

export type MutationaffectUsersOrganisationsArgs = {
    usersOrganisationsInput: UsersOrganisationsInput;
};

export type MutationcreateBillingContactsArgs = {
    emails: Array<Scalars["String"]["input"]>;
};

export type MutationcreateDeviceArgs = {
    deviceInput: DeviceInput;
};

export type MutationcreateIncidentArgs = {
    incidentInput: IncidentInput;
};

export type MutationcreateIntegrationArgs = {
    bravasIntegrationInput?: InputMaybe<BravasIntegrationInput>;
};

export type MutationcreateOrganisationArgs = {
    organisationInput: OrganisationInput;
};

export type MutationcreateShippingAddressArgs = {
    inputAddress: AddressInput;
};

export type MutationcreateUserArgs = {
    userInput: UserInput;
};

export type MutationdeleteBillingContactsArgs = {
    emails: Array<Scalars["String"]["input"]>;
};

export type MutationdeleteDevicesArgs = {
    serials: Array<Scalars["String"]["input"]>;
};

export type MutationdeleteDevicesAffectationArgs = {
    userDeviceInput: UserDeviceInput;
};

export type MutationdeleteIntegrationArgs = {
    provider: IntegrationProvider;
};

export type MutationdeleteOrganisationArgs = {
    id: Scalars["String"]["input"];
};

export type MutationdeleteOrganisationsArgs = {
    ids: Array<Scalars["String"]["input"]>;
};

export type MutationdeleteShippingAddressArgs = {
    addressId: Scalars["String"]["input"];
};

export type MutationdeleteUserArgs = {
    email: Scalars["String"]["input"];
};

export type MutationdeleteUserOrganisationArgs = {
    userOrganisationInput: UserOrganisationInput;
};

export type MutationdeleteUsersOrganisationsArgs = {
    usersOrganisationsInput: UsersOrganisationsInput;
};

export type MutationloadOwnerCsvImportArgs = {
    filePath: Scalars["String"]["input"];
    importMapping: CsvMappingInput;
};

export type MutationupdateAddressArgs = {
    inputAddress: AddressInput;
};

export type MutationupdateBankDetailsArgs = {
    bankDetailsInput: BankDetailsInput;
};

export type MutationupdateDeviceArgs = {
    deviceInput: DeviceInput;
};

export type MutationupdateOrganisationArgs = {
    organisationInput: OrganisationInput;
};

export type MutationupdateSirenArgs = {
    siren: Scalars["String"]["input"];
};

export type MutationupdateUserArgs = {
    userUpdateInput: UserUpdateInput;
};

export type MutationupdateUserInstanceArgs = {
    instanceInput: InstanceUpdateInput;
};

export type MutationupdateUserPasswordArgs = {
    newPassword: Scalars["String"]["input"];
};

export type OrganisationInput = {
    hexaColor: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
};

export type OrganisationOutput = {
    __typename: "OrganisationOutput";
    createdAt: Scalars["String"]["output"];
    devices: Maybe<Array<DeviceUserOutput>>;
    hexaColor: Scalars["String"]["output"];
    id: Scalars["String"]["output"];
    instanceId: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    users: Maybe<Array<UserOutput>>;
};

export type OrganisationUser = {
    __typename: "OrganisationUser";
    hexaColor: Maybe<Scalars["String"]["output"]>;
    id: Maybe<Scalars["String"]["output"]>;
    name: Maybe<Scalars["String"]["output"]>;
};

export type OrganisationUserInput = {
    id?: InputMaybe<Scalars["String"]["input"]>;
};

export enum OwnerCsvColumn {
    DEFAULT = "DEFAULT",
    DEVICE_CUSTOM_NOTES = "DEVICE_CUSTOM_NOTES",
    DEVICE_MEMORY = "DEVICE_MEMORY",
    DEVICE_NAME = "DEVICE_NAME",
    DEVICE_OS = "DEVICE_OS",
    DEVICE_PROCESSOR = "DEVICE_PROCESSOR",
    DEVICE_SCREEN = "DEVICE_SCREEN",
    DEVICE_SERIAL = "DEVICE_SERIAL",
    DEVICE_STORAGE = "DEVICE_STORAGE",
    DEVICE_TYPE = "DEVICE_TYPE",
    USER_ADMIN = "USER_ADMIN",
    USER_EMAIL = "USER_EMAIL",
    USER_FIRSTNAME = "USER_FIRSTNAME",
    USER_LASTNAME = "USER_LASTNAME",
}

export type Query = {
    __typename: "Query";
    _service: Maybe<_Service>;
    currentInstance: Maybe<InstanceOutput>;
    findCurrentUser: Maybe<UserOutput>;
    findDevice: DeviceOutput;
    findDeviceIncident: Array<IncidentOutput>;
    findDevices: Array<DeviceOutput>;
    findOrganisations: Array<OrganisationOutput>;
    findUser: Array<UserOutput>;
    hasInstance: Scalars["Boolean"]["output"];
    instanceDashboard: Maybe<InstanceDashboardOutput>;
    isCleaqRentClient: Scalars["Boolean"]["output"];
    presignDownload: Scalars["String"]["output"];
    presignUploadCompany: Scalars["String"]["output"];
    presignUploadIncident: Scalars["String"]["output"];
    suggestImportCsvMapping: CsvMappingOutput;
};

export type QueryfindDeviceArgs = {
    serial: Scalars["String"]["input"];
};

export type QueryfindDeviceIncidentArgs = {
    userRole?: InputMaybe<UserRole>;
};

export type QueryfindUserArgs = {
    email?: InputMaybe<Scalars["String"]["input"]>;
    role?: InputMaybe<UserRole>;
    tagId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerypresignDownloadArgs = {
    filePath: Scalars["String"]["input"];
};

export type QuerypresignUploadCompanyArgs = {
    fileName: Scalars["String"]["input"];
    fileType: FileType;
    folder: Scalars["String"]["input"];
};

export type QuerypresignUploadIncidentArgs = {
    id: Scalars["String"]["input"];
};

export type QuerysuggestImportCsvMappingArgs = {
    filePath: Scalars["String"]["input"];
};

export type UserDeviceInput = {
    email?: InputMaybe<Scalars["String"]["input"]>;
    serials: Array<Scalars["String"]["input"]>;
};

export type UserInput = {
    customNotes?: InputMaybe<Scalars["String"]["input"]>;
    email: Scalars["String"]["input"];
    firstName: Scalars["String"]["input"];
    lastName: Scalars["String"]["input"];
    organisations?: InputMaybe<Array<InputMaybe<OrganisationUserInput>>>;
    phoneNumber: Scalars["String"]["input"];
    role: UserRole;
};

export type UserOrganisationInput = {
    email: Scalars["String"]["input"];
    idOrganisation: Scalars["String"]["input"];
};

export type UserOutput = {
    __typename: "UserOutput";
    createdAt: Scalars["String"]["output"];
    customNotes: Maybe<Scalars["String"]["output"]>;
    devices: Maybe<Array<DeviceUserOutput>>;
    email: Scalars["String"]["output"];
    firstName: Scalars["String"]["output"];
    id: Scalars["String"]["output"];
    instanceId: Scalars["String"]["output"];
    lastName: Scalars["String"]["output"];
    organisations: Maybe<Array<OrganisationUser>>;
    phoneNumber: Maybe<Scalars["String"]["output"]>;
    role: UserRole;
    token: Maybe<Scalars["String"]["output"]>;
};

export enum UserRole {
    ADMIN = "ADMIN",
    OWNER = "OWNER",
    PUSH_FILES = "PUSH_FILES",
    USER = "USER",
}

export type UserUpdateInput = {
    customNotes?: InputMaybe<Scalars["String"]["input"]>;
    email: Scalars["String"]["input"];
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    organisations?: InputMaybe<Array<InputMaybe<OrganisationUserInput>>>;
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    role?: InputMaybe<UserRole>;
};

export type UsersOrganisationsInput = {
    emails: Array<Scalars["String"]["input"]>;
    organisationsIds: Array<Scalars["String"]["input"]>;
};

export type _Service = {
    __typename: "_Service";
    sdl: Scalars["String"]["output"];
};

export type currentUserDeviceFragment = {
    __typename: "DeviceUserOutput";
    id: string | null;
    serialNumber: string | null;
    reference: string | null;
    deviceType: DeviceType | null;
    deviceOs: DeviceOs | null;
};

export type currentUserFragment = {
    __typename: "UserOutput";
    firstName: string;
    lastName: string;
    createdAt: string;
    role: UserRole;
    phoneNumber: string | null;
    email: string;
    instanceId: string;
    token: string | null;
    organisations: Array<{
        __typename: "OrganisationUser";
        id: string | null;
        name: string | null;
        hexaColor: string | null;
    }> | null;
    devices: Array<{
        __typename: "DeviceUserOutput";
        id: string | null;
        serialNumber: string | null;
        reference: string | null;
        deviceType: DeviceType | null;
        deviceOs: DeviceOs | null;
    }> | null;
};

export type FindCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type FindCurrentUserQuery = {
    findCurrentUser: {
        __typename: "UserOutput";
        firstName: string;
        lastName: string;
        createdAt: string;
        role: UserRole;
        phoneNumber: string | null;
        email: string;
        instanceId: string;
        token: string | null;
        organisations: Array<{
            __typename: "OrganisationUser";
            id: string | null;
            name: string | null;
            hexaColor: string | null;
        }> | null;
        devices: Array<{
            __typename: "DeviceUserOutput";
            id: string | null;
            serialNumber: string | null;
            reference: string | null;
            deviceType: DeviceType | null;
            deviceOs: DeviceOs | null;
        }> | null;
    } | null;
};

export type UpdateUserPasswordMutationVariables = Exact<{
    newPassword: Scalars["String"]["input"];
}>;

export type UpdateUserPasswordMutation = { updateUserPassword: boolean };

export type DeviceSpecsFragment = {
    __typename: "DeviceSpecsOutput";
    memorySize: string | null;
    screenSize: string | null;
    processor: string | null;
    storageSize: string | null;
    deviceType: DeviceType;
    deviceOs: DeviceOs;
};

export type UserOrganisationFragment = {
    __typename: "OrganisationUser";
    id: string | null;
    name: string | null;
    hexaColor: string | null;
};

export type AffectedUserFragment = {
    __typename: "AffectedUserOutput";
    firstName: string;
    lastName: string;
    email: string;
    organisations: Array<{
        __typename: "OrganisationUser";
        id: string | null;
        name: string | null;
        hexaColor: string | null;
    }> | null;
};

export type LeasingDataFragment = {
    __typename: "LeasingDataOutput";
    filesCode: string | null;
    leasingPrice: number | null;
    leasingEnd: string;
    leasingStart: string;
    leasingMonthsDuration: number;
};

export type CarbonFootprintFragment = {
    __typename: "CarbonFootprintOutput";
    manufacturing: number;
    transport: number;
};

export type CarbonSavingsFragment = {
    __typename: "CarbonSavingsOutput";
    repairs: number;
    refurb: number;
};

export type EcoDataFragment = {
    __typename: "EcoDataOutput";
    repairabilityIndex: number | null;
    carbonFootprint: {
        __typename: "CarbonFootprintOutput";
        manufacturing: number;
        transport: number;
    };
    carbonSavings: {
        __typename: "CarbonSavingsOutput";
        repairs: number;
        refurb: number;
    };
};

export type DeviceFragment = {
    __typename: "DeviceOutput";
    reference: string;
    serialNumber: string;
    createdAt: string;
    source: DeviceSource;
    status: DeviceStatus;
    affectedDate: string | null;
    customNotes: string | null;
    specs: {
        __typename: "DeviceSpecsOutput";
        memorySize: string | null;
        screenSize: string | null;
        processor: string | null;
        storageSize: string | null;
        deviceType: DeviceType;
        deviceOs: DeviceOs;
    };
    affectedUser: {
        __typename: "AffectedUserOutput";
        firstName: string;
        lastName: string;
        email: string;
        organisations: Array<{
            __typename: "OrganisationUser";
            id: string | null;
            name: string | null;
            hexaColor: string | null;
        }> | null;
    } | null;
    incident: {
        __typename: "IncidentOutput";
        id: string;
        startingDate: string;
        reason: IncidentReason;
        details: string;
        state: IncidentState;
        serialNumber: string;
        deviceName: string;
    } | null;
    leasingData: {
        __typename: "LeasingDataOutput";
        filesCode: string | null;
        leasingPrice: number | null;
        leasingEnd: string;
        leasingStart: string;
        leasingMonthsDuration: number;
    } | null;
    contractFile: {
        __typename: "InstanceFile";
        fileName: string;
        filePath: string;
        fileType: FileType | null;
    } | null;
    scheduleFile: {
        __typename: "InstanceFile";
        fileName: string;
        filePath: string;
        fileType: FileType | null;
    } | null;
    invoiceFile: {
        __typename: "InstanceFile";
        fileName: string;
        filePath: string;
        fileType: FileType | null;
    } | null;
    ecoData: {
        __typename: "EcoDataOutput";
        repairabilityIndex: number | null;
        carbonFootprint: {
            __typename: "CarbonFootprintOutput";
            manufacturing: number;
            transport: number;
        };
        carbonSavings: {
            __typename: "CarbonSavingsOutput";
            repairs: number;
            refurb: number;
        };
    } | null;
};

export type FindDevicesQueryVariables = Exact<{ [key: string]: never }>;

export type FindDevicesQuery = {
    findDevices: Array<{
        __typename: "DeviceOutput";
        reference: string;
        serialNumber: string;
        createdAt: string;
        source: DeviceSource;
        status: DeviceStatus;
        affectedDate: string | null;
        customNotes: string | null;
        specs: {
            __typename: "DeviceSpecsOutput";
            memorySize: string | null;
            screenSize: string | null;
            processor: string | null;
            storageSize: string | null;
            deviceType: DeviceType;
            deviceOs: DeviceOs;
        };
        affectedUser: {
            __typename: "AffectedUserOutput";
            firstName: string;
            lastName: string;
            email: string;
            organisations: Array<{
                __typename: "OrganisationUser";
                id: string | null;
                name: string | null;
                hexaColor: string | null;
            }> | null;
        } | null;
        incident: {
            __typename: "IncidentOutput";
            id: string;
            startingDate: string;
            reason: IncidentReason;
            details: string;
            state: IncidentState;
            serialNumber: string;
            deviceName: string;
        } | null;
        leasingData: {
            __typename: "LeasingDataOutput";
            filesCode: string | null;
            leasingPrice: number | null;
            leasingEnd: string;
            leasingStart: string;
            leasingMonthsDuration: number;
        } | null;
        contractFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        scheduleFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        invoiceFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        ecoData: {
            __typename: "EcoDataOutput";
            repairabilityIndex: number | null;
            carbonFootprint: {
                __typename: "CarbonFootprintOutput";
                manufacturing: number;
                transport: number;
            };
            carbonSavings: {
                __typename: "CarbonSavingsOutput";
                repairs: number;
                refurb: number;
            };
        } | null;
    }>;
};

export type FindDeviceQueryVariables = Exact<{
    serial: Scalars["String"]["input"];
}>;

export type FindDeviceQuery = {
    findDevice: {
        __typename: "DeviceOutput";
        reference: string;
        serialNumber: string;
        createdAt: string;
        source: DeviceSource;
        status: DeviceStatus;
        affectedDate: string | null;
        customNotes: string | null;
        specs: {
            __typename: "DeviceSpecsOutput";
            memorySize: string | null;
            screenSize: string | null;
            processor: string | null;
            storageSize: string | null;
            deviceType: DeviceType;
            deviceOs: DeviceOs;
        };
        affectedUser: {
            __typename: "AffectedUserOutput";
            firstName: string;
            lastName: string;
            email: string;
            organisations: Array<{
                __typename: "OrganisationUser";
                id: string | null;
                name: string | null;
                hexaColor: string | null;
            }> | null;
        } | null;
        incident: {
            __typename: "IncidentOutput";
            id: string;
            startingDate: string;
            reason: IncidentReason;
            details: string;
            state: IncidentState;
            serialNumber: string;
            deviceName: string;
        } | null;
        leasingData: {
            __typename: "LeasingDataOutput";
            filesCode: string | null;
            leasingPrice: number | null;
            leasingEnd: string;
            leasingStart: string;
            leasingMonthsDuration: number;
        } | null;
        contractFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        scheduleFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        invoiceFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        ecoData: {
            __typename: "EcoDataOutput";
            repairabilityIndex: number | null;
            carbonFootprint: {
                __typename: "CarbonFootprintOutput";
                manufacturing: number;
                transport: number;
            };
            carbonSavings: {
                __typename: "CarbonSavingsOutput";
                repairs: number;
                refurb: number;
            };
        } | null;
    };
};

export type CreateDeviceMutationVariables = Exact<{
    customNotes?: InputMaybe<Scalars["String"]["input"]>;
    reference: Scalars["String"]["input"];
    status: DeviceStatus;
    specs: DeviceSpecsInput;
    serialNumber: Scalars["String"]["input"];
}>;

export type CreateDeviceMutation = {
    createDevice: {
        __typename: "DeviceOutput";
        reference: string;
        serialNumber: string;
        createdAt: string;
        source: DeviceSource;
        status: DeviceStatus;
        affectedDate: string | null;
        customNotes: string | null;
        specs: {
            __typename: "DeviceSpecsOutput";
            memorySize: string | null;
            screenSize: string | null;
            processor: string | null;
            storageSize: string | null;
            deviceType: DeviceType;
            deviceOs: DeviceOs;
        };
        affectedUser: {
            __typename: "AffectedUserOutput";
            firstName: string;
            lastName: string;
            email: string;
            organisations: Array<{
                __typename: "OrganisationUser";
                id: string | null;
                name: string | null;
                hexaColor: string | null;
            }> | null;
        } | null;
        incident: {
            __typename: "IncidentOutput";
            id: string;
            startingDate: string;
            reason: IncidentReason;
            details: string;
            state: IncidentState;
            serialNumber: string;
            deviceName: string;
        } | null;
        leasingData: {
            __typename: "LeasingDataOutput";
            filesCode: string | null;
            leasingPrice: number | null;
            leasingEnd: string;
            leasingStart: string;
            leasingMonthsDuration: number;
        } | null;
        contractFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        scheduleFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        invoiceFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        ecoData: {
            __typename: "EcoDataOutput";
            repairabilityIndex: number | null;
            carbonFootprint: {
                __typename: "CarbonFootprintOutput";
                manufacturing: number;
                transport: number;
            };
            carbonSavings: {
                __typename: "CarbonSavingsOutput";
                repairs: number;
                refurb: number;
            };
        } | null;
    };
};

export type UpdateDeviceMutationVariables = Exact<{
    customNotes?: InputMaybe<Scalars["String"]["input"]>;
    reference: Scalars["String"]["input"];
    status: DeviceStatus;
    specs: DeviceSpecsInput;
    serialNumber: Scalars["String"]["input"];
}>;

export type UpdateDeviceMutation = {
    updateDevice: {
        __typename: "DeviceOutput";
        reference: string;
        serialNumber: string;
        createdAt: string;
        source: DeviceSource;
        status: DeviceStatus;
        affectedDate: string | null;
        customNotes: string | null;
        specs: {
            __typename: "DeviceSpecsOutput";
            memorySize: string | null;
            screenSize: string | null;
            processor: string | null;
            storageSize: string | null;
            deviceType: DeviceType;
            deviceOs: DeviceOs;
        };
        affectedUser: {
            __typename: "AffectedUserOutput";
            firstName: string;
            lastName: string;
            email: string;
            organisations: Array<{
                __typename: "OrganisationUser";
                id: string | null;
                name: string | null;
                hexaColor: string | null;
            }> | null;
        } | null;
        incident: {
            __typename: "IncidentOutput";
            id: string;
            startingDate: string;
            reason: IncidentReason;
            details: string;
            state: IncidentState;
            serialNumber: string;
            deviceName: string;
        } | null;
        leasingData: {
            __typename: "LeasingDataOutput";
            filesCode: string | null;
            leasingPrice: number | null;
            leasingEnd: string;
            leasingStart: string;
            leasingMonthsDuration: number;
        } | null;
        contractFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        scheduleFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        invoiceFile: {
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        } | null;
        ecoData: {
            __typename: "EcoDataOutput";
            repairabilityIndex: number | null;
            carbonFootprint: {
                __typename: "CarbonFootprintOutput";
                manufacturing: number;
                transport: number;
            };
            carbonSavings: {
                __typename: "CarbonSavingsOutput";
                repairs: number;
                refurb: number;
            };
        } | null;
    } | null;
};

export type DeleteDevicesMutationVariables = Exact<{
    serials: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type DeleteDevicesMutation = { deleteDevices: boolean };

export type IncidentFragment = {
    __typename: "IncidentOutput";
    id: string;
    startingDate: string;
    reason: IncidentReason;
    details: string;
    state: IncidentState;
    serialNumber: string;
    deviceName: string;
};

export type FindDeviceIncidentQueryVariables = Exact<{
    userRole?: InputMaybe<UserRole>;
}>;

export type FindDeviceIncidentQuery = {
    findDeviceIncident: Array<{
        __typename: "IncidentOutput";
        id: string;
        startingDate: string;
        reason: IncidentReason;
        details: string;
        state: IncidentState;
        serialNumber: string;
        deviceName: string;
    }>;
};

export type CreateIncidentMutationVariables = Exact<{
    serialNumber: Scalars["String"]["input"];
    startingDate: Scalars["String"]["input"];
    reason: IncidentReason;
    details: Scalars["String"]["input"];
    proof: Scalars["String"]["input"];
}>;

export type CreateIncidentMutation = {
    createIncident: {
        __typename: "IncidentOutput";
        id: string;
        startingDate: string;
        reason: IncidentReason;
        details: string;
        state: IncidentState;
        proof: string | null;
    } | null;
};

export type PresignIncidentUploadQueryVariables = Exact<{
    id: Scalars["String"]["input"];
}>;

export type PresignIncidentUploadQuery = { presignUploadIncident: string };

export type ImportCsvMappingFragment = {
    __typename: "CsvMappingOutput";
    separator: CsvSeparator;
    columnMapping: Array<{
        __typename: "CsvColumnMappingOutput";
        actualColumnName: string;
        suggestedColumn: OwnerCsvColumn;
        sample: Array<string>;
    }>;
};

export type SuggestMappingQueryVariables = Exact<{
    filePath: Scalars["String"]["input"];
}>;

export type SuggestMappingQuery = {
    suggestImportCsvMapping: {
        __typename: "CsvMappingOutput";
        separator: CsvSeparator;
        columnMapping: Array<{
            __typename: "CsvColumnMappingOutput";
            actualColumnName: string;
            suggestedColumn: OwnerCsvColumn;
            sample: Array<string>;
        }>;
    };
};

export type LoadOwnerCsvImportMutationVariables = Exact<{
    filePath: Scalars["String"]["input"];
    importMapping: CsvMappingInput;
}>;

export type LoadOwnerCsvImportMutation = { loadOwnerCsvImport: boolean };

export type AddressFragment = {
    __typename: "AddressOutput";
    id: string;
    label: string;
    recipient: string;
    addressName: string;
    zipCode: string;
    cityName: string;
    country: string;
    instructions: string | null;
    phoneNumber: string;
};

export type CreateShippingAddressMutationVariables = Exact<{
    inputAddress: AddressInput;
}>;

export type CreateShippingAddressMutation = {
    createShippingAddress: {
        __typename: "AddressOutput";
        id: string;
        label: string;
        recipient: string;
        addressName: string;
        zipCode: string;
        cityName: string;
        country: string;
        instructions: string | null;
        phoneNumber: string;
    } | null;
};

export type UpdateAddressMutationVariables = Exact<{
    inputAddress: AddressInput;
}>;

export type UpdateAddressMutation = {
    updateAddress: {
        __typename: "InstanceOutput";
        shippingAddress: {
            __typename: "AddressOutput";
            id: string;
            label: string;
            recipient: string;
            addressName: string;
            zipCode: string;
            cityName: string;
            country: string;
            instructions: string | null;
            phoneNumber: string;
        } | null;
        secondaryAddresses: Array<{
            __typename: "AddressOutput";
            id: string;
            label: string;
            recipient: string;
            addressName: string;
            zipCode: string;
            cityName: string;
            country: string;
            instructions: string | null;
            phoneNumber: string;
        }> | null;
    };
};

export type DeleteShippingAddressMutationVariables = Exact<{
    addressId: Scalars["String"]["input"];
}>;

export type DeleteShippingAddressMutation = { deleteShippingAddress: boolean };

export type BankDetailsFragment = {
    __typename: "BankDetailsOutput";
    iban: string | null;
    bic: string | null;
};

export type FindBankDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type FindBankDetailsQuery = {
    currentInstance: {
        __typename: "InstanceOutput";
        id: string;
        bankDetails: {
            __typename: "BankDetailsOutput";
            iban: string | null;
            bic: string | null;
        } | null;
    } | null;
};

export type UpdateBankDetailsMutationVariables = Exact<{
    iban: Scalars["String"]["input"];
    bic: Scalars["String"]["input"];
}>;

export type UpdateBankDetailsMutation = {
    updateBankDetails: {
        __typename: "InstanceOutput";
        id: string;
        bankDetails: {
            __typename: "BankDetailsOutput";
            iban: string | null;
            bic: string | null;
        } | null;
    };
};

export type FindBillingContactsQueryVariables = Exact<{ [key: string]: never }>;

export type FindBillingContactsQuery = {
    currentInstance: {
        __typename: "InstanceOutput";
        id: string;
        billingContacts: Array<string> | null;
    } | null;
};

export type DeleteBillingContactsMutationVariables = Exact<{
    emails: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type DeleteBillingContactsMutation = { deleteBillingContacts: boolean };

export type CreateBillingContactsMutationVariables = Exact<{
    emails: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type CreateBillingContactsMutation = { createBillingContacts: boolean };

export type DashboardDeviceTypeCountFragment = {
    __typename: "DeviceTypeCount";
    productType: DeviceType;
    count: number;
};

export type DashboardDeviceOsCountFragment = {
    __typename: "DeviceOsCount";
    productOs: DeviceOs;
    count: number;
};

export type DashboardDeviceNameCountFragment = {
    __typename: "DeviceNameCount";
    deviceName: string;
    count: number;
};

export type InstanceDashboardFragment = {
    __typename: "InstanceDashboardOutput";
    deviceAffectedRate: number | null;
    deviceUsersToAffect: {
        __typename: "DeviceUserToAffectCount";
        usersCount: number;
        devicesCount: number;
    } | null;
    deviceComputedStatus: Array<{
        __typename: "DeviceStatusCount";
        deviceStatus: DeviceComputedStatus;
        count: number;
    }> | null;
    deviceTypeCount: Array<{
        __typename: "DeviceTypeCount";
        productType: DeviceType;
        count: number;
    }> | null;
    deviceOsCount: Array<{
        __typename: "DeviceOsCount";
        productOs: DeviceOs;
        count: number;
    }> | null;
    deviceNameCount: Array<{
        __typename: "DeviceNameCount";
        deviceName: string;
        count: number;
    }> | null;
    deviceEcoData: {
        __typename: "DeviceEcoData";
        carbonFootprint: number;
        carbonSavings: number;
    };
};

export type ComputeDashboardDataQueryVariables = Exact<{
    [key: string]: never;
}>;

export type ComputeDashboardDataQuery = {
    instanceDashboard: {
        __typename: "InstanceDashboardOutput";
        deviceAffectedRate: number | null;
        deviceUsersToAffect: {
            __typename: "DeviceUserToAffectCount";
            usersCount: number;
            devicesCount: number;
        } | null;
        deviceComputedStatus: Array<{
            __typename: "DeviceStatusCount";
            deviceStatus: DeviceComputedStatus;
            count: number;
        }> | null;
        deviceTypeCount: Array<{
            __typename: "DeviceTypeCount";
            productType: DeviceType;
            count: number;
        }> | null;
        deviceOsCount: Array<{
            __typename: "DeviceOsCount";
            productOs: DeviceOs;
            count: number;
        }> | null;
        deviceNameCount: Array<{
            __typename: "DeviceNameCount";
            deviceName: string;
            count: number;
        }> | null;
        deviceEcoData: {
            __typename: "DeviceEcoData";
            carbonFootprint: number;
            carbonSavings: number;
        };
    } | null;
};

export type InstanceFileFragment = {
    __typename: "InstanceFile";
    fileName: string;
    filePath: string;
    fileType: FileType | null;
};

export type PresignCompanyUploadQueryVariables = Exact<{
    fileName: Scalars["String"]["input"];
    fileType: FileType;
    folder: Scalars["String"]["input"];
}>;

export type PresignCompanyUploadQuery = { presignUploadCompany: string };

export type PresignDownloadQueryVariables = Exact<{
    filePath: Scalars["String"]["input"];
}>;

export type PresignDownloadQuery = { presignDownload: string };

export type InstanceFragment = {
    __typename: "InstanceOutput";
    id: string;
    name: string;
    shareId: string;
    representativeName: string | null;
    email: string | null;
    siren: string | null;
    crmId: string | null;
    preconfig: string | null;
    affectedCatalogs: Array<string>;
    shippingAddress: {
        __typename: "AddressOutput";
        id: string;
        label: string;
        recipient: string;
        addressName: string;
        zipCode: string;
        cityName: string;
        country: string;
        instructions: string | null;
        phoneNumber: string;
    } | null;
    secondaryAddresses: Array<{
        __typename: "AddressOutput";
        id: string;
        label: string;
        recipient: string;
        addressName: string;
        zipCode: string;
        cityName: string;
        country: string;
        instructions: string | null;
        phoneNumber: string;
    }> | null;
    files: Array<{
        __typename: "InstanceFile";
        fileName: string;
        filePath: string;
        fileType: FileType | null;
    }> | null;
};

export type FindInstanceQueryVariables = Exact<{ [key: string]: never }>;

export type FindInstanceQuery = {
    currentInstance: {
        __typename: "InstanceOutput";
        id: string;
        name: string;
        shareId: string;
        representativeName: string | null;
        email: string | null;
        siren: string | null;
        crmId: string | null;
        preconfig: string | null;
        affectedCatalogs: Array<string>;
        shippingAddress: {
            __typename: "AddressOutput";
            id: string;
            label: string;
            recipient: string;
            addressName: string;
            zipCode: string;
            cityName: string;
            country: string;
            instructions: string | null;
            phoneNumber: string;
        } | null;
        secondaryAddresses: Array<{
            __typename: "AddressOutput";
            id: string;
            label: string;
            recipient: string;
            addressName: string;
            zipCode: string;
            cityName: string;
            country: string;
            instructions: string | null;
            phoneNumber: string;
        }> | null;
        files: Array<{
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        }> | null;
    } | null;
};

export type UpdateInstanceMutationVariables = Exact<{
    email: Scalars["String"]["input"];
    representativeName: Scalars["String"]["input"];
    shippingAddress: AddressInput;
}>;

export type UpdateInstanceMutation = {
    updateUserInstance: {
        __typename: "InstanceOutput";
        id: string;
        name: string;
        shareId: string;
        representativeName: string | null;
        email: string | null;
        siren: string | null;
        crmId: string | null;
        preconfig: string | null;
        affectedCatalogs: Array<string>;
        shippingAddress: {
            __typename: "AddressOutput";
            id: string;
            label: string;
            recipient: string;
            addressName: string;
            zipCode: string;
            cityName: string;
            country: string;
            instructions: string | null;
            phoneNumber: string;
        } | null;
        secondaryAddresses: Array<{
            __typename: "AddressOutput";
            id: string;
            label: string;
            recipient: string;
            addressName: string;
            zipCode: string;
            cityName: string;
            country: string;
            instructions: string | null;
            phoneNumber: string;
        }> | null;
        files: Array<{
            __typename: "InstanceFile";
            fileName: string;
            filePath: string;
            fileType: FileType | null;
        }> | null;
    } | null;
};

export type UpdateSirenMutationVariables = Exact<{
    siren: Scalars["String"]["input"];
}>;

export type UpdateSirenMutation = { updateSiren: boolean };

export type IntegrationConfigFragment = {
    __typename: "IntegrationConfigOutput";
    name: string;
    value: string;
};

export type IntegrationFragment = {
    __typename: "IntegrationOutput";
    type: IntegrationType;
    provider: IntegrationProvider;
    config: Array<{
        __typename: "IntegrationConfigOutput";
        name: string;
        value: string;
    }>;
};

export type FindIntegrationsQueryVariables = Exact<{ [key: string]: never }>;

export type FindIntegrationsQuery = {
    currentInstance: {
        __typename: "InstanceOutput";
        integrations: Array<{
            __typename: "IntegrationOutput";
            type: IntegrationType;
            provider: IntegrationProvider;
            config: Array<{
                __typename: "IntegrationConfigOutput";
                name: string;
                value: string;
            }>;
        }>;
    } | null;
};

export type DeleteIntegrationMutationVariables = Exact<{
    provider: IntegrationProvider;
}>;

export type DeleteIntegrationMutation = { deleteIntegration: boolean };

export type CreateIntegrationMutationVariables = Exact<{
    bravas?: InputMaybe<BravasIntegrationInput>;
}>;

export type CreateIntegrationMutation = { createIntegration: boolean };

export type OrganisationUserFragment = {
    __typename: "UserOutput";
    firstName: string;
    email: string;
    lastName: string;
};

export type OrganisationDeviceFragment = {
    __typename: "DeviceUserOutput";
    reference: string | null;
    serialNumber: string | null;
};

export type OrganisationFragment = {
    __typename: "OrganisationOutput";
    id: string;
    name: string;
    hexaColor: string;
    users: Array<{
        __typename: "UserOutput";
        firstName: string;
        email: string;
        lastName: string;
    }> | null;
    devices: Array<{
        __typename: "DeviceUserOutput";
        reference: string | null;
        serialNumber: string | null;
    }> | null;
};

export type FindOrganisationsQueryVariables = Exact<{ [key: string]: never }>;

export type FindOrganisationsQuery = {
    findOrganisations: Array<{
        __typename: "OrganisationOutput";
        id: string;
        name: string;
        hexaColor: string;
        users: Array<{
            __typename: "UserOutput";
            firstName: string;
            email: string;
            lastName: string;
        }> | null;
        devices: Array<{
            __typename: "DeviceUserOutput";
            reference: string | null;
            serialNumber: string | null;
        }> | null;
    }>;
};

export type CreateOrganisationMutationVariables = Exact<{
    name: Scalars["String"]["input"];
    hexaColor: Scalars["String"]["input"];
}>;

export type CreateOrganisationMutation = {
    createOrganisation: {
        __typename: "OrganisationOutput";
        id: string;
        name: string;
        hexaColor: string;
        users: Array<{
            __typename: "UserOutput";
            firstName: string;
            email: string;
            lastName: string;
        }> | null;
        devices: Array<{
            __typename: "DeviceUserOutput";
            reference: string | null;
            serialNumber: string | null;
        }> | null;
    };
};

export type UpdateOrganisationMutationVariables = Exact<{
    id: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    hexaColor: Scalars["String"]["input"];
}>;

export type UpdateOrganisationMutation = {
    updateOrganisation: {
        __typename: "OrganisationOutput";
        id: string;
        name: string;
        hexaColor: string;
        users: Array<{
            __typename: "UserOutput";
            firstName: string;
            email: string;
            lastName: string;
        }> | null;
        devices: Array<{
            __typename: "DeviceUserOutput";
            reference: string | null;
            serialNumber: string | null;
        }> | null;
    } | null;
};

export type DeleteOrganisationsMutationVariables = Exact<{
    ids: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type DeleteOrganisationsMutation = { deleteOrganisations: boolean };

export type AdminUserFragment = {
    __typename: "UserOutput";
    firstName: string;
    lastName: string;
    role: UserRole;
    email: string;
};

export type FindAdminQueryVariables = Exact<{ [key: string]: never }>;

export type FindAdminQuery = {
    findUser: Array<{
        __typename: "UserOutput";
        firstName: string;
        lastName: string;
        role: UserRole;
        email: string;
    }>;
};

export type UserDeviceFragment = {
    __typename: "DeviceUserOutput";
    serialNumber: string | null;
    reference: string | null;
    deviceType: DeviceType | null;
    deviceOs: DeviceOs | null;
};

export type UserFragment = {
    __typename: "UserOutput";
    firstName: string;
    lastName: string;
    createdAt: string;
    role: UserRole;
    phoneNumber: string | null;
    email: string;
    customNotes: string | null;
    organisations: Array<{
        __typename: "OrganisationUser";
        id: string | null;
        name: string | null;
        hexaColor: string | null;
    }> | null;
    devices: Array<{
        __typename: "DeviceUserOutput";
        serialNumber: string | null;
        reference: string | null;
        deviceType: DeviceType | null;
        deviceOs: DeviceOs | null;
    }> | null;
};

export type FindUsersQueryVariables = Exact<{ [key: string]: never }>;

export type FindUsersQuery = {
    findUser: Array<{
        __typename: "UserOutput";
        firstName: string;
        lastName: string;
        createdAt: string;
        role: UserRole;
        phoneNumber: string | null;
        email: string;
        customNotes: string | null;
        organisations: Array<{
            __typename: "OrganisationUser";
            id: string | null;
            name: string | null;
            hexaColor: string | null;
        }> | null;
        devices: Array<{
            __typename: "DeviceUserOutput";
            serialNumber: string | null;
            reference: string | null;
            deviceType: DeviceType | null;
            deviceOs: DeviceOs | null;
        }> | null;
    }>;
};

export type CreateUserMutationVariables = Exact<{
    email: Scalars["String"]["input"];
    firstName: Scalars["String"]["input"];
    phoneNumber: Scalars["String"]["input"];
    lastName: Scalars["String"]["input"];
    role: UserRole;
    customNotes?: InputMaybe<Scalars["String"]["input"]>;
    organisations?: InputMaybe<
        Array<OrganisationUserInput> | OrganisationUserInput
    >;
}>;

export type CreateUserMutation = {
    createUser: {
        __typename: "UserOutput";
        firstName: string;
        lastName: string;
        createdAt: string;
        role: UserRole;
        phoneNumber: string | null;
        email: string;
        customNotes: string | null;
        organisations: Array<{
            __typename: "OrganisationUser";
            id: string | null;
            name: string | null;
            hexaColor: string | null;
        }> | null;
        devices: Array<{
            __typename: "DeviceUserOutput";
            serialNumber: string | null;
            reference: string | null;
            deviceType: DeviceType | null;
            deviceOs: DeviceOs | null;
        }> | null;
    };
};

export type UpdateUserMutationVariables = Exact<{
    email: Scalars["String"]["input"];
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    role?: InputMaybe<UserRole>;
    customNotes?: InputMaybe<Scalars["String"]["input"]>;
    organisations?: InputMaybe<
        | Array<InputMaybe<OrganisationUserInput>>
        | InputMaybe<OrganisationUserInput>
    >;
}>;

export type UpdateUserMutation = {
    updateUser: {
        __typename: "UserOutput";
        firstName: string;
        lastName: string;
        createdAt: string;
        role: UserRole;
        phoneNumber: string | null;
        email: string;
        customNotes: string | null;
        organisations: Array<{
            __typename: "OrganisationUser";
            id: string | null;
            name: string | null;
            hexaColor: string | null;
        }> | null;
        devices: Array<{
            __typename: "DeviceUserOutput";
            serialNumber: string | null;
            reference: string | null;
            deviceType: DeviceType | null;
            deviceOs: DeviceOs | null;
        }> | null;
    };
};

export type DeleteUserMutationVariables = Exact<{
    email: Scalars["String"]["input"];
}>;

export type DeleteUserMutation = { deleteUser: boolean };

export type AffectUserDeviceMutationVariables = Exact<{
    email?: InputMaybe<Scalars["String"]["input"]>;
    serials: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type AffectUserDeviceMutation = { affectUserDevice: boolean };

export type DeleteDevicesAffectationMutationVariables = Exact<{
    serials: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type DeleteDevicesAffectationMutation = {
    deleteDevicesAffectation: boolean;
};

export type AffectUsersOrganisationsMutationVariables = Exact<{
    emails: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
    organisationsIds:
        | Array<Scalars["String"]["input"]>
        | Scalars["String"]["input"];
}>;

export type AffectUsersOrganisationsMutation = {
    affectUsersOrganisations: boolean;
};

export type DeleteUsersOrganisationsMutationVariables = Exact<{
    emails: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
    organisationsIds:
        | Array<Scalars["String"]["input"]>
        | Scalars["String"]["input"];
}>;

export type DeleteUsersOrganisationsMutation = {
    deleteUsersOrganisations: boolean;
};

export const currentUserDeviceFragmentDoc = gql`
    fragment currentUserDevice on DeviceUserOutput {
        id
        serialNumber
        reference
        deviceType
        deviceOs
    }
`;
export const currentUserFragmentDoc = gql`
    fragment currentUser on UserOutput {
        firstName
        lastName
        createdAt
        role
        organisations {
            id
            name
            hexaColor
        }
        phoneNumber
        email
        devices {
            ...currentUserDevice
        }
        instanceId
        token
    }
    ${currentUserDeviceFragmentDoc}
`;
export const DeviceSpecsFragmentDoc = gql`
    fragment DeviceSpecs on DeviceSpecsOutput {
        memorySize
        screenSize
        processor
        storageSize
        deviceType
        deviceOs
    }
`;
export const UserOrganisationFragmentDoc = gql`
    fragment UserOrganisation on OrganisationUser {
        id
        name
        hexaColor
    }
`;
export const AffectedUserFragmentDoc = gql`
    fragment AffectedUser on AffectedUserOutput {
        firstName
        lastName
        email
        organisations {
            ...UserOrganisation
        }
    }
    ${UserOrganisationFragmentDoc}
`;
export const IncidentFragmentDoc = gql`
    fragment Incident on IncidentOutput {
        id
        startingDate
        reason
        details
        state
        serialNumber
        deviceName
    }
`;
export const LeasingDataFragmentDoc = gql`
    fragment LeasingData on LeasingDataOutput {
        filesCode
        leasingPrice
        leasingEnd
        leasingStart
        leasingMonthsDuration
    }
`;
export const InstanceFileFragmentDoc = gql`
    fragment InstanceFile on InstanceFile {
        fileName
        filePath
        fileType
    }
`;
export const CarbonFootprintFragmentDoc = gql`
    fragment CarbonFootprint on CarbonFootprintOutput {
        manufacturing
        transport
    }
`;
export const CarbonSavingsFragmentDoc = gql`
    fragment CarbonSavings on CarbonSavingsOutput {
        repairs
        refurb
    }
`;
export const EcoDataFragmentDoc = gql`
    fragment EcoData on EcoDataOutput {
        carbonFootprint {
            ...CarbonFootprint
        }
        carbonSavings {
            ...CarbonSavings
        }
        repairabilityIndex
    }
    ${CarbonFootprintFragmentDoc}
    ${CarbonSavingsFragmentDoc}
`;
export const DeviceFragmentDoc = gql`
    fragment Device on DeviceOutput {
        reference
        serialNumber
        createdAt
        source
        status
        affectedDate
        customNotes
        specs {
            ...DeviceSpecs
        }
        affectedUser {
            ...AffectedUser
        }
        incident {
            ...Incident
        }
        leasingData {
            ...LeasingData
        }
        contractFile {
            ...InstanceFile
        }
        scheduleFile {
            ...InstanceFile
        }
        invoiceFile {
            ...InstanceFile
        }
        ecoData {
            ...EcoData
        }
    }
    ${DeviceSpecsFragmentDoc}
    ${AffectedUserFragmentDoc}
    ${IncidentFragmentDoc}
    ${LeasingDataFragmentDoc}
    ${InstanceFileFragmentDoc}
    ${EcoDataFragmentDoc}
`;
export const ImportCsvMappingFragmentDoc = gql`
    fragment ImportCsvMapping on CsvMappingOutput {
        separator
        columnMapping {
            actualColumnName
            suggestedColumn
            sample
        }
    }
`;
export const BankDetailsFragmentDoc = gql`
    fragment BankDetails on BankDetailsOutput {
        iban
        bic
    }
`;
export const DashboardDeviceTypeCountFragmentDoc = gql`
    fragment DashboardDeviceTypeCount on DeviceTypeCount {
        productType
        count
    }
`;
export const DashboardDeviceOsCountFragmentDoc = gql`
    fragment DashboardDeviceOsCount on DeviceOsCount {
        productOs
        count
    }
`;
export const DashboardDeviceNameCountFragmentDoc = gql`
    fragment DashboardDeviceNameCount on DeviceNameCount {
        deviceName
        count
    }
`;
export const InstanceDashboardFragmentDoc = gql`
    fragment InstanceDashboard on InstanceDashboardOutput {
        deviceAffectedRate
        deviceUsersToAffect {
            usersCount
            devicesCount
        }
        deviceComputedStatus {
            deviceStatus
            count
        }
        deviceTypeCount {
            ...DashboardDeviceTypeCount
        }
        deviceOsCount {
            ...DashboardDeviceOsCount
        }
        deviceNameCount {
            ...DashboardDeviceNameCount
        }
        deviceEcoData {
            carbonFootprint
            carbonSavings
        }
    }
    ${DashboardDeviceTypeCountFragmentDoc}
    ${DashboardDeviceOsCountFragmentDoc}
    ${DashboardDeviceNameCountFragmentDoc}
`;
export const AddressFragmentDoc = gql`
    fragment Address on AddressOutput {
        id
        label
        recipient
        addressName
        zipCode
        cityName
        country
        instructions
        phoneNumber
    }
`;
export const InstanceFragmentDoc = gql`
    fragment Instance on InstanceOutput {
        id
        name
        shareId
        representativeName
        email
        shippingAddress {
            ...Address
        }
        secondaryAddresses {
            ...Address
        }
        siren
        files {
            ...InstanceFile
        }
        crmId
        preconfig
        affectedCatalogs
    }
    ${AddressFragmentDoc}
    ${InstanceFileFragmentDoc}
`;
export const IntegrationConfigFragmentDoc = gql`
    fragment IntegrationConfig on IntegrationConfigOutput {
        name
        value
    }
`;
export const IntegrationFragmentDoc = gql`
    fragment Integration on IntegrationOutput {
        type
        provider
        config {
            ...IntegrationConfig
        }
    }
    ${IntegrationConfigFragmentDoc}
`;
export const OrganisationUserFragmentDoc = gql`
    fragment OrganisationUser on UserOutput {
        firstName
        email
        lastName
    }
`;
export const OrganisationDeviceFragmentDoc = gql`
    fragment OrganisationDevice on DeviceUserOutput {
        reference
        serialNumber
    }
`;
export const OrganisationFragmentDoc = gql`
    fragment Organisation on OrganisationOutput {
        id
        name
        hexaColor
        users {
            ...OrganisationUser
        }
        devices {
            ...OrganisationDevice
        }
    }
    ${OrganisationUserFragmentDoc}
    ${OrganisationDeviceFragmentDoc}
`;
export const AdminUserFragmentDoc = gql`
    fragment AdminUser on UserOutput {
        firstName
        lastName
        role
        email
    }
`;
export const UserDeviceFragmentDoc = gql`
    fragment UserDevice on DeviceUserOutput {
        serialNumber
        reference
        deviceType
        deviceOs
    }
`;
export const UserFragmentDoc = gql`
    fragment User on UserOutput {
        firstName
        lastName
        createdAt
        role
        organisations {
            ...UserOrganisation
        }
        phoneNumber
        email
        devices {
            ...UserDevice
        }
        customNotes
    }
    ${UserOrganisationFragmentDoc}
    ${UserDeviceFragmentDoc}
`;
export const FindCurrentUserDocument = gql`
    query FindCurrentUser {
        findCurrentUser {
            ...currentUser
        }
    }
    ${currentUserFragmentDoc}
`;

/**
 * __useFindCurrentUserQuery__
 *
 * To run a query within a React component, call `useFindCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindCurrentUserQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindCurrentUserQuery,
        FindCurrentUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindCurrentUserQuery, FindCurrentUserQueryVariables>(
        FindCurrentUserDocument,
        options,
    );
}
export function useFindCurrentUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindCurrentUserQuery,
        FindCurrentUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindCurrentUserQuery,
        FindCurrentUserQueryVariables
    >(FindCurrentUserDocument, options);
}
export type FindCurrentUserQueryHookResult = ReturnType<
    typeof useFindCurrentUserQuery
>;
export type FindCurrentUserLazyQueryHookResult = ReturnType<
    typeof useFindCurrentUserLazyQuery
>;
export type FindCurrentUserQueryResult = Apollo.QueryResult<
    FindCurrentUserQuery,
    FindCurrentUserQueryVariables
>;
export function refetchFindCurrentUserQuery(
    variables?: FindCurrentUserQueryVariables,
) {
    return { query: FindCurrentUserDocument, variables: variables };
}
export const UpdateUserPasswordDocument = gql`
    mutation UpdateUserPassword($newPassword: String!) {
        updateUserPassword(newPassword: $newPassword)
    }
`;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateUserPasswordMutation,
        UpdateUserPasswordMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateUserPasswordMutation,
        UpdateUserPasswordMutationVariables
    >(UpdateUserPasswordDocument, options);
}
export type UpdateUserPasswordMutationHookResult = ReturnType<
    typeof useUpdateUserPasswordMutation
>;
export type UpdateUserPasswordMutationResult =
    Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
>;
export const FindDevicesDocument = gql`
    query FindDevices {
        findDevices {
            ...Device
        }
    }
    ${DeviceFragmentDoc}
`;

/**
 * __useFindDevicesQuery__
 *
 * To run a query within a React component, call `useFindDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindDevicesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindDevicesQuery,
        FindDevicesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindDevicesQuery, FindDevicesQueryVariables>(
        FindDevicesDocument,
        options,
    );
}
export function useFindDevicesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindDevicesQuery,
        FindDevicesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindDevicesQuery, FindDevicesQueryVariables>(
        FindDevicesDocument,
        options,
    );
}
export type FindDevicesQueryHookResult = ReturnType<typeof useFindDevicesQuery>;
export type FindDevicesLazyQueryHookResult = ReturnType<
    typeof useFindDevicesLazyQuery
>;
export type FindDevicesQueryResult = Apollo.QueryResult<
    FindDevicesQuery,
    FindDevicesQueryVariables
>;
export function refetchFindDevicesQuery(variables?: FindDevicesQueryVariables) {
    return { query: FindDevicesDocument, variables: variables };
}
export const FindDeviceDocument = gql`
    query FindDevice($serial: String!) {
        findDevice(serial: $serial) {
            ...Device
        }
    }
    ${DeviceFragmentDoc}
`;

/**
 * __useFindDeviceQuery__
 *
 * To run a query within a React component, call `useFindDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDeviceQuery({
 *   variables: {
 *      serial: // value for 'serial'
 *   },
 * });
 */
export function useFindDeviceQuery(
    baseOptions: Apollo.QueryHookOptions<
        FindDeviceQuery,
        FindDeviceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindDeviceQuery, FindDeviceQueryVariables>(
        FindDeviceDocument,
        options,
    );
}
export function useFindDeviceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindDeviceQuery,
        FindDeviceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindDeviceQuery, FindDeviceQueryVariables>(
        FindDeviceDocument,
        options,
    );
}
export type FindDeviceQueryHookResult = ReturnType<typeof useFindDeviceQuery>;
export type FindDeviceLazyQueryHookResult = ReturnType<
    typeof useFindDeviceLazyQuery
>;
export type FindDeviceQueryResult = Apollo.QueryResult<
    FindDeviceQuery,
    FindDeviceQueryVariables
>;
export function refetchFindDeviceQuery(variables: FindDeviceQueryVariables) {
    return { query: FindDeviceDocument, variables: variables };
}
export const CreateDeviceDocument = gql`
    mutation CreateDevice(
        $customNotes: String
        $reference: String!
        $status: DeviceStatus!
        $specs: DeviceSpecsInput!
        $serialNumber: String!
    ) {
        createDevice(
            deviceInput: {
                customNotes: $customNotes
                reference: $reference
                status: $status
                specs: $specs
                serialNumber: $serialNumber
            }
        ) {
            ...Device
        }
    }
    ${DeviceFragmentDoc}
`;
export type CreateDeviceMutationFn = Apollo.MutationFunction<
    CreateDeviceMutation,
    CreateDeviceMutationVariables
>;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      customNotes: // value for 'customNotes'
 *      reference: // value for 'reference'
 *      status: // value for 'status'
 *      specs: // value for 'specs'
 *      serialNumber: // value for 'serialNumber'
 *   },
 * });
 */
export function useCreateDeviceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateDeviceMutation,
        CreateDeviceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateDeviceMutation,
        CreateDeviceMutationVariables
    >(CreateDeviceDocument, options);
}
export type CreateDeviceMutationHookResult = ReturnType<
    typeof useCreateDeviceMutation
>;
export type CreateDeviceMutationResult =
    Apollo.MutationResult<CreateDeviceMutation>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<
    CreateDeviceMutation,
    CreateDeviceMutationVariables
>;
export const UpdateDeviceDocument = gql`
    mutation UpdateDevice(
        $customNotes: String
        $reference: String!
        $status: DeviceStatus!
        $specs: DeviceSpecsInput!
        $serialNumber: String!
    ) {
        updateDevice(
            deviceInput: {
                customNotes: $customNotes
                reference: $reference
                status: $status
                specs: $specs
                serialNumber: $serialNumber
            }
        ) {
            ...Device
        }
    }
    ${DeviceFragmentDoc}
`;
export type UpdateDeviceMutationFn = Apollo.MutationFunction<
    UpdateDeviceMutation,
    UpdateDeviceMutationVariables
>;

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceMutation, { data, loading, error }] = useUpdateDeviceMutation({
 *   variables: {
 *      customNotes: // value for 'customNotes'
 *      reference: // value for 'reference'
 *      status: // value for 'status'
 *      specs: // value for 'specs'
 *      serialNumber: // value for 'serialNumber'
 *   },
 * });
 */
export function useUpdateDeviceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateDeviceMutation,
        UpdateDeviceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateDeviceMutation,
        UpdateDeviceMutationVariables
    >(UpdateDeviceDocument, options);
}
export type UpdateDeviceMutationHookResult = ReturnType<
    typeof useUpdateDeviceMutation
>;
export type UpdateDeviceMutationResult =
    Apollo.MutationResult<UpdateDeviceMutation>;
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<
    UpdateDeviceMutation,
    UpdateDeviceMutationVariables
>;
export const DeleteDevicesDocument = gql`
    mutation DeleteDevices($serials: [String!]!) {
        deleteDevices(serials: $serials)
    }
`;
export type DeleteDevicesMutationFn = Apollo.MutationFunction<
    DeleteDevicesMutation,
    DeleteDevicesMutationVariables
>;

/**
 * __useDeleteDevicesMutation__
 *
 * To run a mutation, you first call `useDeleteDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDevicesMutation, { data, loading, error }] = useDeleteDevicesMutation({
 *   variables: {
 *      serials: // value for 'serials'
 *   },
 * });
 */
export function useDeleteDevicesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteDevicesMutation,
        DeleteDevicesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteDevicesMutation,
        DeleteDevicesMutationVariables
    >(DeleteDevicesDocument, options);
}
export type DeleteDevicesMutationHookResult = ReturnType<
    typeof useDeleteDevicesMutation
>;
export type DeleteDevicesMutationResult =
    Apollo.MutationResult<DeleteDevicesMutation>;
export type DeleteDevicesMutationOptions = Apollo.BaseMutationOptions<
    DeleteDevicesMutation,
    DeleteDevicesMutationVariables
>;
export const FindDeviceIncidentDocument = gql`
    query FindDeviceIncident($userRole: UserRole) {
        findDeviceIncident(userRole: $userRole) {
            ...Incident
        }
    }
    ${IncidentFragmentDoc}
`;

/**
 * __useFindDeviceIncidentQuery__
 *
 * To run a query within a React component, call `useFindDeviceIncidentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDeviceIncidentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDeviceIncidentQuery({
 *   variables: {
 *      userRole: // value for 'userRole'
 *   },
 * });
 */
export function useFindDeviceIncidentQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindDeviceIncidentQuery,
        FindDeviceIncidentQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FindDeviceIncidentQuery,
        FindDeviceIncidentQueryVariables
    >(FindDeviceIncidentDocument, options);
}
export function useFindDeviceIncidentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindDeviceIncidentQuery,
        FindDeviceIncidentQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindDeviceIncidentQuery,
        FindDeviceIncidentQueryVariables
    >(FindDeviceIncidentDocument, options);
}
export type FindDeviceIncidentQueryHookResult = ReturnType<
    typeof useFindDeviceIncidentQuery
>;
export type FindDeviceIncidentLazyQueryHookResult = ReturnType<
    typeof useFindDeviceIncidentLazyQuery
>;
export type FindDeviceIncidentQueryResult = Apollo.QueryResult<
    FindDeviceIncidentQuery,
    FindDeviceIncidentQueryVariables
>;
export function refetchFindDeviceIncidentQuery(
    variables?: FindDeviceIncidentQueryVariables,
) {
    return { query: FindDeviceIncidentDocument, variables: variables };
}
export const CreateIncidentDocument = gql`
    mutation CreateIncident(
        $serialNumber: String!
        $startingDate: String!
        $reason: IncidentReason!
        $details: String!
        $proof: String!
    ) {
        createIncident(
            incidentInput: {
                serialNumber: $serialNumber
                startingDate: $startingDate
                reason: $reason
                details: $details
                proof: $proof
            }
        ) {
            id
            startingDate
            reason
            details
            state
            proof
        }
    }
`;
export type CreateIncidentMutationFn = Apollo.MutationFunction<
    CreateIncidentMutation,
    CreateIncidentMutationVariables
>;

/**
 * __useCreateIncidentMutation__
 *
 * To run a mutation, you first call `useCreateIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentMutation, { data, loading, error }] = useCreateIncidentMutation({
 *   variables: {
 *      serialNumber: // value for 'serialNumber'
 *      startingDate: // value for 'startingDate'
 *      reason: // value for 'reason'
 *      details: // value for 'details'
 *      proof: // value for 'proof'
 *   },
 * });
 */
export function useCreateIncidentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateIncidentMutation,
        CreateIncidentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateIncidentMutation,
        CreateIncidentMutationVariables
    >(CreateIncidentDocument, options);
}
export type CreateIncidentMutationHookResult = ReturnType<
    typeof useCreateIncidentMutation
>;
export type CreateIncidentMutationResult =
    Apollo.MutationResult<CreateIncidentMutation>;
export type CreateIncidentMutationOptions = Apollo.BaseMutationOptions<
    CreateIncidentMutation,
    CreateIncidentMutationVariables
>;
export const PresignIncidentUploadDocument = gql`
    query PresignIncidentUpload($id: String!) {
        presignUploadIncident(id: $id)
    }
`;

/**
 * __usePresignIncidentUploadQuery__
 *
 * To run a query within a React component, call `usePresignIncidentUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresignIncidentUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresignIncidentUploadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePresignIncidentUploadQuery(
    baseOptions: Apollo.QueryHookOptions<
        PresignIncidentUploadQuery,
        PresignIncidentUploadQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PresignIncidentUploadQuery,
        PresignIncidentUploadQueryVariables
    >(PresignIncidentUploadDocument, options);
}
export function usePresignIncidentUploadLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PresignIncidentUploadQuery,
        PresignIncidentUploadQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PresignIncidentUploadQuery,
        PresignIncidentUploadQueryVariables
    >(PresignIncidentUploadDocument, options);
}
export type PresignIncidentUploadQueryHookResult = ReturnType<
    typeof usePresignIncidentUploadQuery
>;
export type PresignIncidentUploadLazyQueryHookResult = ReturnType<
    typeof usePresignIncidentUploadLazyQuery
>;
export type PresignIncidentUploadQueryResult = Apollo.QueryResult<
    PresignIncidentUploadQuery,
    PresignIncidentUploadQueryVariables
>;
export function refetchPresignIncidentUploadQuery(
    variables: PresignIncidentUploadQueryVariables,
) {
    return { query: PresignIncidentUploadDocument, variables: variables };
}
export const SuggestMappingDocument = gql`
    query SuggestMapping($filePath: String!) {
        suggestImportCsvMapping(filePath: $filePath) {
            ...ImportCsvMapping
        }
    }
    ${ImportCsvMappingFragmentDoc}
`;

/**
 * __useSuggestMappingQuery__
 *
 * To run a query within a React component, call `useSuggestMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestMappingQuery({
 *   variables: {
 *      filePath: // value for 'filePath'
 *   },
 * });
 */
export function useSuggestMappingQuery(
    baseOptions: Apollo.QueryHookOptions<
        SuggestMappingQuery,
        SuggestMappingQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SuggestMappingQuery, SuggestMappingQueryVariables>(
        SuggestMappingDocument,
        options,
    );
}
export function useSuggestMappingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SuggestMappingQuery,
        SuggestMappingQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        SuggestMappingQuery,
        SuggestMappingQueryVariables
    >(SuggestMappingDocument, options);
}
export type SuggestMappingQueryHookResult = ReturnType<
    typeof useSuggestMappingQuery
>;
export type SuggestMappingLazyQueryHookResult = ReturnType<
    typeof useSuggestMappingLazyQuery
>;
export type SuggestMappingQueryResult = Apollo.QueryResult<
    SuggestMappingQuery,
    SuggestMappingQueryVariables
>;
export function refetchSuggestMappingQuery(
    variables: SuggestMappingQueryVariables,
) {
    return { query: SuggestMappingDocument, variables: variables };
}
export const LoadOwnerCsvImportDocument = gql`
    mutation LoadOwnerCsvImport(
        $filePath: String!
        $importMapping: CsvMappingInput!
    ) {
        loadOwnerCsvImport(filePath: $filePath, importMapping: $importMapping)
    }
`;
export type LoadOwnerCsvImportMutationFn = Apollo.MutationFunction<
    LoadOwnerCsvImportMutation,
    LoadOwnerCsvImportMutationVariables
>;

/**
 * __useLoadOwnerCsvImportMutation__
 *
 * To run a mutation, you first call `useLoadOwnerCsvImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadOwnerCsvImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadOwnerCsvImportMutation, { data, loading, error }] = useLoadOwnerCsvImportMutation({
 *   variables: {
 *      filePath: // value for 'filePath'
 *      importMapping: // value for 'importMapping'
 *   },
 * });
 */
export function useLoadOwnerCsvImportMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LoadOwnerCsvImportMutation,
        LoadOwnerCsvImportMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        LoadOwnerCsvImportMutation,
        LoadOwnerCsvImportMutationVariables
    >(LoadOwnerCsvImportDocument, options);
}
export type LoadOwnerCsvImportMutationHookResult = ReturnType<
    typeof useLoadOwnerCsvImportMutation
>;
export type LoadOwnerCsvImportMutationResult =
    Apollo.MutationResult<LoadOwnerCsvImportMutation>;
export type LoadOwnerCsvImportMutationOptions = Apollo.BaseMutationOptions<
    LoadOwnerCsvImportMutation,
    LoadOwnerCsvImportMutationVariables
>;
export const CreateShippingAddressDocument = gql`
    mutation CreateShippingAddress($inputAddress: AddressInput!) {
        createShippingAddress(inputAddress: $inputAddress) {
            ...Address
        }
    }
    ${AddressFragmentDoc}
`;
export type CreateShippingAddressMutationFn = Apollo.MutationFunction<
    CreateShippingAddressMutation,
    CreateShippingAddressMutationVariables
>;

/**
 * __useCreateShippingAddressMutation__
 *
 * To run a mutation, you first call `useCreateShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShippingAddressMutation, { data, loading, error }] = useCreateShippingAddressMutation({
 *   variables: {
 *      inputAddress: // value for 'inputAddress'
 *   },
 * });
 */
export function useCreateShippingAddressMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateShippingAddressMutation,
        CreateShippingAddressMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateShippingAddressMutation,
        CreateShippingAddressMutationVariables
    >(CreateShippingAddressDocument, options);
}
export type CreateShippingAddressMutationHookResult = ReturnType<
    typeof useCreateShippingAddressMutation
>;
export type CreateShippingAddressMutationResult =
    Apollo.MutationResult<CreateShippingAddressMutation>;
export type CreateShippingAddressMutationOptions = Apollo.BaseMutationOptions<
    CreateShippingAddressMutation,
    CreateShippingAddressMutationVariables
>;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($inputAddress: AddressInput!) {
        updateAddress(inputAddress: $inputAddress) {
            shippingAddress {
                ...Address
            }
            secondaryAddresses {
                ...Address
            }
        }
    }
    ${AddressFragmentDoc}
`;
export type UpdateAddressMutationFn = Apollo.MutationFunction<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      inputAddress: // value for 'inputAddress'
 *   },
 * });
 */
export function useUpdateAddressMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateAddressMutation,
        UpdateAddressMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateAddressMutation,
        UpdateAddressMutationVariables
    >(UpdateAddressDocument, options);
}
export type UpdateAddressMutationHookResult = ReturnType<
    typeof useUpdateAddressMutation
>;
export type UpdateAddressMutationResult =
    Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
>;
export const DeleteShippingAddressDocument = gql`
    mutation DeleteShippingAddress($addressId: String!) {
        deleteShippingAddress(addressId: $addressId)
    }
`;
export type DeleteShippingAddressMutationFn = Apollo.MutationFunction<
    DeleteShippingAddressMutation,
    DeleteShippingAddressMutationVariables
>;

/**
 * __useDeleteShippingAddressMutation__
 *
 * To run a mutation, you first call `useDeleteShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShippingAddressMutation, { data, loading, error }] = useDeleteShippingAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDeleteShippingAddressMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteShippingAddressMutation,
        DeleteShippingAddressMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteShippingAddressMutation,
        DeleteShippingAddressMutationVariables
    >(DeleteShippingAddressDocument, options);
}
export type DeleteShippingAddressMutationHookResult = ReturnType<
    typeof useDeleteShippingAddressMutation
>;
export type DeleteShippingAddressMutationResult =
    Apollo.MutationResult<DeleteShippingAddressMutation>;
export type DeleteShippingAddressMutationOptions = Apollo.BaseMutationOptions<
    DeleteShippingAddressMutation,
    DeleteShippingAddressMutationVariables
>;
export const FindBankDetailsDocument = gql`
    query FindBankDetails {
        currentInstance {
            id
            bankDetails {
                ...BankDetails
            }
        }
    }
    ${BankDetailsFragmentDoc}
`;

/**
 * __useFindBankDetailsQuery__
 *
 * To run a query within a React component, call `useFindBankDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBankDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBankDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindBankDetailsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindBankDetailsQuery,
        FindBankDetailsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindBankDetailsQuery, FindBankDetailsQueryVariables>(
        FindBankDetailsDocument,
        options,
    );
}
export function useFindBankDetailsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindBankDetailsQuery,
        FindBankDetailsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindBankDetailsQuery,
        FindBankDetailsQueryVariables
    >(FindBankDetailsDocument, options);
}
export type FindBankDetailsQueryHookResult = ReturnType<
    typeof useFindBankDetailsQuery
>;
export type FindBankDetailsLazyQueryHookResult = ReturnType<
    typeof useFindBankDetailsLazyQuery
>;
export type FindBankDetailsQueryResult = Apollo.QueryResult<
    FindBankDetailsQuery,
    FindBankDetailsQueryVariables
>;
export function refetchFindBankDetailsQuery(
    variables?: FindBankDetailsQueryVariables,
) {
    return { query: FindBankDetailsDocument, variables: variables };
}
export const UpdateBankDetailsDocument = gql`
    mutation UpdateBankDetails($iban: String!, $bic: String!) {
        updateBankDetails(bankDetailsInput: { iban: $iban, bic: $bic }) {
            id
            bankDetails {
                ...BankDetails
            }
        }
    }
    ${BankDetailsFragmentDoc}
`;
export type UpdateBankDetailsMutationFn = Apollo.MutationFunction<
    UpdateBankDetailsMutation,
    UpdateBankDetailsMutationVariables
>;

/**
 * __useUpdateBankDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateBankDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankDetailsMutation, { data, loading, error }] = useUpdateBankDetailsMutation({
 *   variables: {
 *      iban: // value for 'iban'
 *      bic: // value for 'bic'
 *   },
 * });
 */
export function useUpdateBankDetailsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateBankDetailsMutation,
        UpdateBankDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateBankDetailsMutation,
        UpdateBankDetailsMutationVariables
    >(UpdateBankDetailsDocument, options);
}
export type UpdateBankDetailsMutationHookResult = ReturnType<
    typeof useUpdateBankDetailsMutation
>;
export type UpdateBankDetailsMutationResult =
    Apollo.MutationResult<UpdateBankDetailsMutation>;
export type UpdateBankDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateBankDetailsMutation,
    UpdateBankDetailsMutationVariables
>;
export const FindBillingContactsDocument = gql`
    query FindBillingContacts {
        currentInstance {
            id
            billingContacts
        }
    }
`;

/**
 * __useFindBillingContactsQuery__
 *
 * To run a query within a React component, call `useFindBillingContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBillingContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBillingContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindBillingContactsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindBillingContactsQuery,
        FindBillingContactsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FindBillingContactsQuery,
        FindBillingContactsQueryVariables
    >(FindBillingContactsDocument, options);
}
export function useFindBillingContactsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindBillingContactsQuery,
        FindBillingContactsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindBillingContactsQuery,
        FindBillingContactsQueryVariables
    >(FindBillingContactsDocument, options);
}
export type FindBillingContactsQueryHookResult = ReturnType<
    typeof useFindBillingContactsQuery
>;
export type FindBillingContactsLazyQueryHookResult = ReturnType<
    typeof useFindBillingContactsLazyQuery
>;
export type FindBillingContactsQueryResult = Apollo.QueryResult<
    FindBillingContactsQuery,
    FindBillingContactsQueryVariables
>;
export function refetchFindBillingContactsQuery(
    variables?: FindBillingContactsQueryVariables,
) {
    return { query: FindBillingContactsDocument, variables: variables };
}
export const DeleteBillingContactsDocument = gql`
    mutation DeleteBillingContacts($emails: [String!]!) {
        deleteBillingContacts(emails: $emails)
    }
`;
export type DeleteBillingContactsMutationFn = Apollo.MutationFunction<
    DeleteBillingContactsMutation,
    DeleteBillingContactsMutationVariables
>;

/**
 * __useDeleteBillingContactsMutation__
 *
 * To run a mutation, you first call `useDeleteBillingContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillingContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillingContactsMutation, { data, loading, error }] = useDeleteBillingContactsMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useDeleteBillingContactsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteBillingContactsMutation,
        DeleteBillingContactsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteBillingContactsMutation,
        DeleteBillingContactsMutationVariables
    >(DeleteBillingContactsDocument, options);
}
export type DeleteBillingContactsMutationHookResult = ReturnType<
    typeof useDeleteBillingContactsMutation
>;
export type DeleteBillingContactsMutationResult =
    Apollo.MutationResult<DeleteBillingContactsMutation>;
export type DeleteBillingContactsMutationOptions = Apollo.BaseMutationOptions<
    DeleteBillingContactsMutation,
    DeleteBillingContactsMutationVariables
>;
export const CreateBillingContactsDocument = gql`
    mutation CreateBillingContacts($emails: [String!]!) {
        createBillingContacts(emails: $emails)
    }
`;
export type CreateBillingContactsMutationFn = Apollo.MutationFunction<
    CreateBillingContactsMutation,
    CreateBillingContactsMutationVariables
>;

/**
 * __useCreateBillingContactsMutation__
 *
 * To run a mutation, you first call `useCreateBillingContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingContactsMutation, { data, loading, error }] = useCreateBillingContactsMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useCreateBillingContactsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateBillingContactsMutation,
        CreateBillingContactsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateBillingContactsMutation,
        CreateBillingContactsMutationVariables
    >(CreateBillingContactsDocument, options);
}
export type CreateBillingContactsMutationHookResult = ReturnType<
    typeof useCreateBillingContactsMutation
>;
export type CreateBillingContactsMutationResult =
    Apollo.MutationResult<CreateBillingContactsMutation>;
export type CreateBillingContactsMutationOptions = Apollo.BaseMutationOptions<
    CreateBillingContactsMutation,
    CreateBillingContactsMutationVariables
>;
export const ComputeDashboardDataDocument = gql`
    query ComputeDashboardData {
        instanceDashboard {
            ...InstanceDashboard
        }
    }
    ${InstanceDashboardFragmentDoc}
`;

/**
 * __useComputeDashboardDataQuery__
 *
 * To run a query within a React component, call `useComputeDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeDashboardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useComputeDashboardDataQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ComputeDashboardDataQuery,
        ComputeDashboardDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ComputeDashboardDataQuery,
        ComputeDashboardDataQueryVariables
    >(ComputeDashboardDataDocument, options);
}
export function useComputeDashboardDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ComputeDashboardDataQuery,
        ComputeDashboardDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ComputeDashboardDataQuery,
        ComputeDashboardDataQueryVariables
    >(ComputeDashboardDataDocument, options);
}
export type ComputeDashboardDataQueryHookResult = ReturnType<
    typeof useComputeDashboardDataQuery
>;
export type ComputeDashboardDataLazyQueryHookResult = ReturnType<
    typeof useComputeDashboardDataLazyQuery
>;
export type ComputeDashboardDataQueryResult = Apollo.QueryResult<
    ComputeDashboardDataQuery,
    ComputeDashboardDataQueryVariables
>;
export function refetchComputeDashboardDataQuery(
    variables?: ComputeDashboardDataQueryVariables,
) {
    return { query: ComputeDashboardDataDocument, variables: variables };
}
export const PresignCompanyUploadDocument = gql`
    query PresignCompanyUpload(
        $fileName: String!
        $fileType: FileType!
        $folder: String!
    ) {
        presignUploadCompany(
            fileName: $fileName
            fileType: $fileType
            folder: $folder
        )
    }
`;

/**
 * __usePresignCompanyUploadQuery__
 *
 * To run a query within a React component, call `usePresignCompanyUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresignCompanyUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresignCompanyUploadQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *      folder: // value for 'folder'
 *   },
 * });
 */
export function usePresignCompanyUploadQuery(
    baseOptions: Apollo.QueryHookOptions<
        PresignCompanyUploadQuery,
        PresignCompanyUploadQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PresignCompanyUploadQuery,
        PresignCompanyUploadQueryVariables
    >(PresignCompanyUploadDocument, options);
}
export function usePresignCompanyUploadLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PresignCompanyUploadQuery,
        PresignCompanyUploadQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PresignCompanyUploadQuery,
        PresignCompanyUploadQueryVariables
    >(PresignCompanyUploadDocument, options);
}
export type PresignCompanyUploadQueryHookResult = ReturnType<
    typeof usePresignCompanyUploadQuery
>;
export type PresignCompanyUploadLazyQueryHookResult = ReturnType<
    typeof usePresignCompanyUploadLazyQuery
>;
export type PresignCompanyUploadQueryResult = Apollo.QueryResult<
    PresignCompanyUploadQuery,
    PresignCompanyUploadQueryVariables
>;
export function refetchPresignCompanyUploadQuery(
    variables: PresignCompanyUploadQueryVariables,
) {
    return { query: PresignCompanyUploadDocument, variables: variables };
}
export const PresignDownloadDocument = gql`
    query PresignDownload($filePath: String!) {
        presignDownload(filePath: $filePath)
    }
`;

/**
 * __usePresignDownloadQuery__
 *
 * To run a query within a React component, call `usePresignDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresignDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresignDownloadQuery({
 *   variables: {
 *      filePath: // value for 'filePath'
 *   },
 * });
 */
export function usePresignDownloadQuery(
    baseOptions: Apollo.QueryHookOptions<
        PresignDownloadQuery,
        PresignDownloadQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PresignDownloadQuery, PresignDownloadQueryVariables>(
        PresignDownloadDocument,
        options,
    );
}
export function usePresignDownloadLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PresignDownloadQuery,
        PresignDownloadQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PresignDownloadQuery,
        PresignDownloadQueryVariables
    >(PresignDownloadDocument, options);
}
export type PresignDownloadQueryHookResult = ReturnType<
    typeof usePresignDownloadQuery
>;
export type PresignDownloadLazyQueryHookResult = ReturnType<
    typeof usePresignDownloadLazyQuery
>;
export type PresignDownloadQueryResult = Apollo.QueryResult<
    PresignDownloadQuery,
    PresignDownloadQueryVariables
>;
export function refetchPresignDownloadQuery(
    variables: PresignDownloadQueryVariables,
) {
    return { query: PresignDownloadDocument, variables: variables };
}
export const FindInstanceDocument = gql`
    query FindInstance {
        currentInstance {
            ...Instance
        }
    }
    ${InstanceFragmentDoc}
`;

/**
 * __useFindInstanceQuery__
 *
 * To run a query within a React component, call `useFindInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInstanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindInstanceQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindInstanceQuery,
        FindInstanceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindInstanceQuery, FindInstanceQueryVariables>(
        FindInstanceDocument,
        options,
    );
}
export function useFindInstanceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindInstanceQuery,
        FindInstanceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindInstanceQuery, FindInstanceQueryVariables>(
        FindInstanceDocument,
        options,
    );
}
export type FindInstanceQueryHookResult = ReturnType<
    typeof useFindInstanceQuery
>;
export type FindInstanceLazyQueryHookResult = ReturnType<
    typeof useFindInstanceLazyQuery
>;
export type FindInstanceQueryResult = Apollo.QueryResult<
    FindInstanceQuery,
    FindInstanceQueryVariables
>;
export function refetchFindInstanceQuery(
    variables?: FindInstanceQueryVariables,
) {
    return { query: FindInstanceDocument, variables: variables };
}
export const UpdateInstanceDocument = gql`
    mutation UpdateInstance(
        $email: String!
        $representativeName: String!
        $shippingAddress: AddressInput!
    ) {
        updateUserInstance(
            instanceInput: {
                email: $email
                representativeName: $representativeName
                shippingAddress: $shippingAddress
            }
        ) {
            ...Instance
        }
    }
    ${InstanceFragmentDoc}
`;
export type UpdateInstanceMutationFn = Apollo.MutationFunction<
    UpdateInstanceMutation,
    UpdateInstanceMutationVariables
>;

/**
 * __useUpdateInstanceMutation__
 *
 * To run a mutation, you first call `useUpdateInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstanceMutation, { data, loading, error }] = useUpdateInstanceMutation({
 *   variables: {
 *      email: // value for 'email'
 *      representativeName: // value for 'representativeName'
 *      shippingAddress: // value for 'shippingAddress'
 *   },
 * });
 */
export function useUpdateInstanceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateInstanceMutation,
        UpdateInstanceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateInstanceMutation,
        UpdateInstanceMutationVariables
    >(UpdateInstanceDocument, options);
}
export type UpdateInstanceMutationHookResult = ReturnType<
    typeof useUpdateInstanceMutation
>;
export type UpdateInstanceMutationResult =
    Apollo.MutationResult<UpdateInstanceMutation>;
export type UpdateInstanceMutationOptions = Apollo.BaseMutationOptions<
    UpdateInstanceMutation,
    UpdateInstanceMutationVariables
>;
export const UpdateSirenDocument = gql`
    mutation UpdateSiren($siren: String!) {
        updateSiren(siren: $siren)
    }
`;
export type UpdateSirenMutationFn = Apollo.MutationFunction<
    UpdateSirenMutation,
    UpdateSirenMutationVariables
>;

/**
 * __useUpdateSirenMutation__
 *
 * To run a mutation, you first call `useUpdateSirenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSirenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSirenMutation, { data, loading, error }] = useUpdateSirenMutation({
 *   variables: {
 *      siren: // value for 'siren'
 *   },
 * });
 */
export function useUpdateSirenMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateSirenMutation,
        UpdateSirenMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateSirenMutation,
        UpdateSirenMutationVariables
    >(UpdateSirenDocument, options);
}
export type UpdateSirenMutationHookResult = ReturnType<
    typeof useUpdateSirenMutation
>;
export type UpdateSirenMutationResult =
    Apollo.MutationResult<UpdateSirenMutation>;
export type UpdateSirenMutationOptions = Apollo.BaseMutationOptions<
    UpdateSirenMutation,
    UpdateSirenMutationVariables
>;
export const FindIntegrationsDocument = gql`
    query FindIntegrations {
        currentInstance {
            integrations {
                ...Integration
            }
        }
    }
    ${IntegrationFragmentDoc}
`;

/**
 * __useFindIntegrationsQuery__
 *
 * To run a query within a React component, call `useFindIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindIntegrationsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindIntegrationsQuery,
        FindIntegrationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FindIntegrationsQuery,
        FindIntegrationsQueryVariables
    >(FindIntegrationsDocument, options);
}
export function useFindIntegrationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindIntegrationsQuery,
        FindIntegrationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindIntegrationsQuery,
        FindIntegrationsQueryVariables
    >(FindIntegrationsDocument, options);
}
export type FindIntegrationsQueryHookResult = ReturnType<
    typeof useFindIntegrationsQuery
>;
export type FindIntegrationsLazyQueryHookResult = ReturnType<
    typeof useFindIntegrationsLazyQuery
>;
export type FindIntegrationsQueryResult = Apollo.QueryResult<
    FindIntegrationsQuery,
    FindIntegrationsQueryVariables
>;
export function refetchFindIntegrationsQuery(
    variables?: FindIntegrationsQueryVariables,
) {
    return { query: FindIntegrationsDocument, variables: variables };
}
export const DeleteIntegrationDocument = gql`
    mutation DeleteIntegration($provider: IntegrationProvider!) {
        deleteIntegration(provider: $provider)
    }
`;
export type DeleteIntegrationMutationFn = Apollo.MutationFunction<
    DeleteIntegrationMutation,
    DeleteIntegrationMutationVariables
>;

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useDeleteIntegrationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteIntegrationMutation,
        DeleteIntegrationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteIntegrationMutation,
        DeleteIntegrationMutationVariables
    >(DeleteIntegrationDocument, options);
}
export type DeleteIntegrationMutationHookResult = ReturnType<
    typeof useDeleteIntegrationMutation
>;
export type DeleteIntegrationMutationResult =
    Apollo.MutationResult<DeleteIntegrationMutation>;
export type DeleteIntegrationMutationOptions = Apollo.BaseMutationOptions<
    DeleteIntegrationMutation,
    DeleteIntegrationMutationVariables
>;
export const CreateIntegrationDocument = gql`
    mutation CreateIntegration($bravas: BravasIntegrationInput) {
        createIntegration(bravasIntegrationInput: $bravas)
    }
`;
export type CreateIntegrationMutationFn = Apollo.MutationFunction<
    CreateIntegrationMutation,
    CreateIntegrationMutationVariables
>;

/**
 * __useCreateIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntegrationMutation, { data, loading, error }] = useCreateIntegrationMutation({
 *   variables: {
 *      bravas: // value for 'bravas'
 *   },
 * });
 */
export function useCreateIntegrationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateIntegrationMutation,
        CreateIntegrationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateIntegrationMutation,
        CreateIntegrationMutationVariables
    >(CreateIntegrationDocument, options);
}
export type CreateIntegrationMutationHookResult = ReturnType<
    typeof useCreateIntegrationMutation
>;
export type CreateIntegrationMutationResult =
    Apollo.MutationResult<CreateIntegrationMutation>;
export type CreateIntegrationMutationOptions = Apollo.BaseMutationOptions<
    CreateIntegrationMutation,
    CreateIntegrationMutationVariables
>;
export const FindOrganisationsDocument = gql`
    query FindOrganisations {
        findOrganisations {
            ...Organisation
        }
    }
    ${OrganisationFragmentDoc}
`;

/**
 * __useFindOrganisationsQuery__
 *
 * To run a query within a React component, call `useFindOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindOrganisationsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindOrganisationsQuery,
        FindOrganisationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FindOrganisationsQuery,
        FindOrganisationsQueryVariables
    >(FindOrganisationsDocument, options);
}
export function useFindOrganisationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindOrganisationsQuery,
        FindOrganisationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindOrganisationsQuery,
        FindOrganisationsQueryVariables
    >(FindOrganisationsDocument, options);
}
export type FindOrganisationsQueryHookResult = ReturnType<
    typeof useFindOrganisationsQuery
>;
export type FindOrganisationsLazyQueryHookResult = ReturnType<
    typeof useFindOrganisationsLazyQuery
>;
export type FindOrganisationsQueryResult = Apollo.QueryResult<
    FindOrganisationsQuery,
    FindOrganisationsQueryVariables
>;
export function refetchFindOrganisationsQuery(
    variables?: FindOrganisationsQueryVariables,
) {
    return { query: FindOrganisationsDocument, variables: variables };
}
export const CreateOrganisationDocument = gql`
    mutation CreateOrganisation($name: String!, $hexaColor: String!) {
        createOrganisation(
            organisationInput: { name: $name, hexaColor: $hexaColor }
        ) {
            ...Organisation
        }
    }
    ${OrganisationFragmentDoc}
`;
export type CreateOrganisationMutationFn = Apollo.MutationFunction<
    CreateOrganisationMutation,
    CreateOrganisationMutationVariables
>;

/**
 * __useCreateOrganisationMutation__
 *
 * To run a mutation, you first call `useCreateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisationMutation, { data, loading, error }] = useCreateOrganisationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      hexaColor: // value for 'hexaColor'
 *   },
 * });
 */
export function useCreateOrganisationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateOrganisationMutation,
        CreateOrganisationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateOrganisationMutation,
        CreateOrganisationMutationVariables
    >(CreateOrganisationDocument, options);
}
export type CreateOrganisationMutationHookResult = ReturnType<
    typeof useCreateOrganisationMutation
>;
export type CreateOrganisationMutationResult =
    Apollo.MutationResult<CreateOrganisationMutation>;
export type CreateOrganisationMutationOptions = Apollo.BaseMutationOptions<
    CreateOrganisationMutation,
    CreateOrganisationMutationVariables
>;
export const UpdateOrganisationDocument = gql`
    mutation UpdateOrganisation(
        $id: String!
        $name: String!
        $hexaColor: String!
    ) {
        updateOrganisation(
            organisationInput: { id: $id, name: $name, hexaColor: $hexaColor }
        ) {
            ...Organisation
        }
    }
    ${OrganisationFragmentDoc}
`;
export type UpdateOrganisationMutationFn = Apollo.MutationFunction<
    UpdateOrganisationMutation,
    UpdateOrganisationMutationVariables
>;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      hexaColor: // value for 'hexaColor'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateOrganisationMutation,
        UpdateOrganisationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateOrganisationMutation,
        UpdateOrganisationMutationVariables
    >(UpdateOrganisationDocument, options);
}
export type UpdateOrganisationMutationHookResult = ReturnType<
    typeof useUpdateOrganisationMutation
>;
export type UpdateOrganisationMutationResult =
    Apollo.MutationResult<UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<
    UpdateOrganisationMutation,
    UpdateOrganisationMutationVariables
>;
export const DeleteOrganisationsDocument = gql`
    mutation DeleteOrganisations($ids: [String!]!) {
        deleteOrganisations(ids: $ids)
    }
`;
export type DeleteOrganisationsMutationFn = Apollo.MutationFunction<
    DeleteOrganisationsMutation,
    DeleteOrganisationsMutationVariables
>;

/**
 * __useDeleteOrganisationsMutation__
 *
 * To run a mutation, you first call `useDeleteOrganisationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganisationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganisationsMutation, { data, loading, error }] = useDeleteOrganisationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteOrganisationsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteOrganisationsMutation,
        DeleteOrganisationsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteOrganisationsMutation,
        DeleteOrganisationsMutationVariables
    >(DeleteOrganisationsDocument, options);
}
export type DeleteOrganisationsMutationHookResult = ReturnType<
    typeof useDeleteOrganisationsMutation
>;
export type DeleteOrganisationsMutationResult =
    Apollo.MutationResult<DeleteOrganisationsMutation>;
export type DeleteOrganisationsMutationOptions = Apollo.BaseMutationOptions<
    DeleteOrganisationsMutation,
    DeleteOrganisationsMutationVariables
>;
export const FindAdminDocument = gql`
    query FindAdmin {
        findUser(role: OWNER) {
            ...AdminUser
        }
    }
    ${AdminUserFragmentDoc}
`;

/**
 * __useFindAdminQuery__
 *
 * To run a query within a React component, call `useFindAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAdminQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindAdminQuery,
        FindAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindAdminQuery, FindAdminQueryVariables>(
        FindAdminDocument,
        options,
    );
}
export function useFindAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindAdminQuery,
        FindAdminQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindAdminQuery, FindAdminQueryVariables>(
        FindAdminDocument,
        options,
    );
}
export type FindAdminQueryHookResult = ReturnType<typeof useFindAdminQuery>;
export type FindAdminLazyQueryHookResult = ReturnType<
    typeof useFindAdminLazyQuery
>;
export type FindAdminQueryResult = Apollo.QueryResult<
    FindAdminQuery,
    FindAdminQueryVariables
>;
export function refetchFindAdminQuery(variables?: FindAdminQueryVariables) {
    return { query: FindAdminDocument, variables: variables };
}
export const FindUsersDocument = gql`
    query FindUsers {
        findUser {
            ...User
        }
    }
    ${UserFragmentDoc}
`;

/**
 * __useFindUsersQuery__
 *
 * To run a query within a React component, call `useFindUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindUsersQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindUsersQuery,
        FindUsersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindUsersQuery, FindUsersQueryVariables>(
        FindUsersDocument,
        options,
    );
}
export function useFindUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindUsersQuery,
        FindUsersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindUsersQuery, FindUsersQueryVariables>(
        FindUsersDocument,
        options,
    );
}
export type FindUsersQueryHookResult = ReturnType<typeof useFindUsersQuery>;
export type FindUsersLazyQueryHookResult = ReturnType<
    typeof useFindUsersLazyQuery
>;
export type FindUsersQueryResult = Apollo.QueryResult<
    FindUsersQuery,
    FindUsersQueryVariables
>;
export function refetchFindUsersQuery(variables?: FindUsersQueryVariables) {
    return { query: FindUsersDocument, variables: variables };
}
export const CreateUserDocument = gql`
    mutation CreateUser(
        $email: String!
        $firstName: String!
        $phoneNumber: String!
        $lastName: String!
        $role: UserRole!
        $customNotes: String
        $organisations: [OrganisationUserInput!]
    ) {
        createUser(
            userInput: {
                email: $email
                phoneNumber: $phoneNumber
                firstName: $firstName
                lastName: $lastName
                organisations: $organisations
                role: $role
                customNotes: $customNotes
            }
        ) {
            ...User
        }
    }
    ${UserFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
    CreateUserMutation,
    CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      phoneNumber: // value for 'phoneNumber'
 *      lastName: // value for 'lastName'
 *      role: // value for 'role'
 *      customNotes: // value for 'customNotes'
 *      organisations: // value for 'organisations'
 *   },
 * });
 */
export function useCreateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateUserMutation,
        CreateUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
        CreateUserDocument,
        options,
    );
}
export type CreateUserMutationHookResult = ReturnType<
    typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
    Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
    CreateUserMutation,
    CreateUserMutationVariables
>;
export const UpdateUserDocument = gql`
    mutation UpdateUser(
        $email: String!
        $firstName: String
        $phoneNumber: String
        $lastName: String
        $role: UserRole
        $customNotes: String
        $organisations: [OrganisationUserInput]
    ) {
        updateUser(
            userUpdateInput: {
                email: $email
                phoneNumber: $phoneNumber
                firstName: $firstName
                lastName: $lastName
                organisations: $organisations
                role: $role
                customNotes: $customNotes
            }
        ) {
            ...User
        }
    }
    ${UserFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
    UpdateUserMutation,
    UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      phoneNumber: // value for 'phoneNumber'
 *      lastName: // value for 'lastName'
 *      role: // value for 'role'
 *      customNotes: // value for 'customNotes'
 *      organisations: // value for 'organisations'
 *   },
 * });
 */
export function useUpdateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateUserMutation,
        UpdateUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
        UpdateUserDocument,
        options,
    );
}
export type UpdateUserMutationHookResult = ReturnType<
    typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
    Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($email: String!) {
        deleteUser(email: $email)
    }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
    DeleteUserMutation,
    DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDeleteUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteUserMutation,
        DeleteUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
        DeleteUserDocument,
        options,
    );
}
export type DeleteUserMutationHookResult = ReturnType<
    typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
    Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
>;
export const AffectUserDeviceDocument = gql`
    mutation AffectUserDevice($email: String, $serials: [String!]!) {
        affectUserDevice(userDeviceInput: { email: $email, serials: $serials })
    }
`;
export type AffectUserDeviceMutationFn = Apollo.MutationFunction<
    AffectUserDeviceMutation,
    AffectUserDeviceMutationVariables
>;

/**
 * __useAffectUserDeviceMutation__
 *
 * To run a mutation, you first call `useAffectUserDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAffectUserDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [affectUserDeviceMutation, { data, loading, error }] = useAffectUserDeviceMutation({
 *   variables: {
 *      email: // value for 'email'
 *      serials: // value for 'serials'
 *   },
 * });
 */
export function useAffectUserDeviceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AffectUserDeviceMutation,
        AffectUserDeviceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        AffectUserDeviceMutation,
        AffectUserDeviceMutationVariables
    >(AffectUserDeviceDocument, options);
}
export type AffectUserDeviceMutationHookResult = ReturnType<
    typeof useAffectUserDeviceMutation
>;
export type AffectUserDeviceMutationResult =
    Apollo.MutationResult<AffectUserDeviceMutation>;
export type AffectUserDeviceMutationOptions = Apollo.BaseMutationOptions<
    AffectUserDeviceMutation,
    AffectUserDeviceMutationVariables
>;
export const DeleteDevicesAffectationDocument = gql`
    mutation DeleteDevicesAffectation($serials: [String!]!) {
        deleteDevicesAffectation(userDeviceInput: { serials: $serials })
    }
`;
export type DeleteDevicesAffectationMutationFn = Apollo.MutationFunction<
    DeleteDevicesAffectationMutation,
    DeleteDevicesAffectationMutationVariables
>;

/**
 * __useDeleteDevicesAffectationMutation__
 *
 * To run a mutation, you first call `useDeleteDevicesAffectationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDevicesAffectationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDevicesAffectationMutation, { data, loading, error }] = useDeleteDevicesAffectationMutation({
 *   variables: {
 *      serials: // value for 'serials'
 *   },
 * });
 */
export function useDeleteDevicesAffectationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteDevicesAffectationMutation,
        DeleteDevicesAffectationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteDevicesAffectationMutation,
        DeleteDevicesAffectationMutationVariables
    >(DeleteDevicesAffectationDocument, options);
}
export type DeleteDevicesAffectationMutationHookResult = ReturnType<
    typeof useDeleteDevicesAffectationMutation
>;
export type DeleteDevicesAffectationMutationResult =
    Apollo.MutationResult<DeleteDevicesAffectationMutation>;
export type DeleteDevicesAffectationMutationOptions =
    Apollo.BaseMutationOptions<
        DeleteDevicesAffectationMutation,
        DeleteDevicesAffectationMutationVariables
    >;
export const AffectUsersOrganisationsDocument = gql`
    mutation AffectUsersOrganisations(
        $emails: [String!]!
        $organisationsIds: [String!]!
    ) {
        affectUsersOrganisations(
            usersOrganisationsInput: {
                emails: $emails
                organisationsIds: $organisationsIds
            }
        )
    }
`;
export type AffectUsersOrganisationsMutationFn = Apollo.MutationFunction<
    AffectUsersOrganisationsMutation,
    AffectUsersOrganisationsMutationVariables
>;

/**
 * __useAffectUsersOrganisationsMutation__
 *
 * To run a mutation, you first call `useAffectUsersOrganisationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAffectUsersOrganisationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [affectUsersOrganisationsMutation, { data, loading, error }] = useAffectUsersOrganisationsMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      organisationsIds: // value for 'organisationsIds'
 *   },
 * });
 */
export function useAffectUsersOrganisationsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AffectUsersOrganisationsMutation,
        AffectUsersOrganisationsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        AffectUsersOrganisationsMutation,
        AffectUsersOrganisationsMutationVariables
    >(AffectUsersOrganisationsDocument, options);
}
export type AffectUsersOrganisationsMutationHookResult = ReturnType<
    typeof useAffectUsersOrganisationsMutation
>;
export type AffectUsersOrganisationsMutationResult =
    Apollo.MutationResult<AffectUsersOrganisationsMutation>;
export type AffectUsersOrganisationsMutationOptions =
    Apollo.BaseMutationOptions<
        AffectUsersOrganisationsMutation,
        AffectUsersOrganisationsMutationVariables
    >;
export const DeleteUsersOrganisationsDocument = gql`
    mutation DeleteUsersOrganisations(
        $emails: [String!]!
        $organisationsIds: [String!]!
    ) {
        deleteUsersOrganisations(
            usersOrganisationsInput: {
                emails: $emails
                organisationsIds: $organisationsIds
            }
        )
    }
`;
export type DeleteUsersOrganisationsMutationFn = Apollo.MutationFunction<
    DeleteUsersOrganisationsMutation,
    DeleteUsersOrganisationsMutationVariables
>;

/**
 * __useDeleteUsersOrganisationsMutation__
 *
 * To run a mutation, you first call `useDeleteUsersOrganisationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersOrganisationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersOrganisationsMutation, { data, loading, error }] = useDeleteUsersOrganisationsMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      organisationsIds: // value for 'organisationsIds'
 *   },
 * });
 */
export function useDeleteUsersOrganisationsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteUsersOrganisationsMutation,
        DeleteUsersOrganisationsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteUsersOrganisationsMutation,
        DeleteUsersOrganisationsMutationVariables
    >(DeleteUsersOrganisationsDocument, options);
}
export type DeleteUsersOrganisationsMutationHookResult = ReturnType<
    typeof useDeleteUsersOrganisationsMutation
>;
export type DeleteUsersOrganisationsMutationResult =
    Apollo.MutationResult<DeleteUsersOrganisationsMutation>;
export type DeleteUsersOrganisationsMutationOptions =
    Apollo.BaseMutationOptions<
        DeleteUsersOrganisationsMutation,
        DeleteUsersOrganisationsMutationVariables
    >;
