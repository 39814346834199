/* eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }] */
import { v4 as uuid } from "uuid";

import { AccessoryType } from "components/business/catalog/utils/types";
import { VariantFragment } from "graphql/catalog/model/apollo";

import ProductAdress from "./product-address";
import ProductDeliveryDate from "./product-delivery-date";
import {
    DeviceProductSpecs,
    AudioProductSpecs,
    DockstationProductSpecs,
    KeyboardsProductSpecs,
    MousesProductSpecs,
    ScreensProductSpecs,
} from "./product-specs";
import ProductUser from "./product-user";

const DEFAULT_SHIPPING_DELAY = 5;

export default class Product {
    innerId: string = uuid();

    type: string;

    variantId: string;

    name: string;

    pricing: number;

    user: ProductUser | null = null;

    address: ProductAdress | null = null;

    instructions: string | null = null;

    photo: string | null;

    specs:
        | DeviceProductSpecs
        | AudioProductSpecs
        | DockstationProductSpecs
        | KeyboardsProductSpecs
        | MousesProductSpecs
        | ScreensProductSpecs;

    negotiated: boolean;

    normalPrice: number;

    deliveryDate: ProductDeliveryDate;

    shippingDelay: number;

    leasingDuration: number;

    constructor(
        contentfulItem: VariantFragment | AccessoryType,
        negotiated: boolean,
        pricing: number,
        leasingDuration: number,
    ) {
        this.type = contentfulItem.__typename;
        this.variantId = contentfulItem.sys.id;
        this.name = contentfulItem.name ?? "";
        this.pricing = pricing;
        this.negotiated = negotiated;
        this.shippingDelay =
            contentfulItem.shippingDelay || DEFAULT_SHIPPING_DELAY;
        this.deliveryDate = new ProductDeliveryDate(this.shippingDelay);
        this.leasingDuration = leasingDuration;

        switch (leasingDuration) {
            case 24:
                this.normalPrice = contentfulItem.price24 ?? 0.0;
                break;
            case 36:
                this.normalPrice = contentfulItem.price36 ?? 0.0;
                break;
            case 48:
                this.normalPrice = contentfulItem.price48 ?? 0.0;
                break;
            default:
                this.normalPrice = 0.0;
        }

        switch (contentfulItem.__typename) {
            case "Audio":
                this.photo = contentfulItem.image?.url ?? null;
                this.specs = new AudioProductSpecs(contentfulItem);
                break;

            case "Dockstation":
                this.photo = contentfulItem.image?.url ?? null;
                this.specs = new DockstationProductSpecs(contentfulItem);
                break;

            case "Keyboard":
                this.photo = contentfulItem.image?.url ?? null;
                this.specs = new KeyboardsProductSpecs(contentfulItem);
                break;

            case "Mouse":
                this.photo = contentfulItem.image?.url ?? null;
                this.specs = new MousesProductSpecs(contentfulItem);
                break;

            case "Screen":
                this.photo = contentfulItem.image?.url ?? null;
                this.specs = new ScreensProductSpecs(contentfulItem);
                break;

            default:
                this.photo = contentfulItem.product?.image?.url ?? null;
                this.specs = new DeviceProductSpecs(contentfulItem);
                break;
        }
    }
}

export function cloneProduct(product: Product): Product {
    return {
        ...product,
        innerId: uuid(),
        user: null,
        address: null,
        instructions: null,
        deliveryDate: new ProductDeliveryDate(product.shippingDelay),
    };
}
