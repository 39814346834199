import React from "react";

const ArrowDropUp = () => {
    return (
        <svg
            width="11"
            height="5"
            viewBox="0 0 11 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.34436 5L5.34436 0L10.3444 5H0.34436Z"
                fill="#373732"
            />
        </svg>
    );
};

export default ArrowDropUp;
