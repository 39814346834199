import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonAddressList = () => (
    <>
        <div className="nlt-button-inside-form pull-right">
            <Skeleton
                variant="rect"
                width={50}
                height={20}
            />
        </div>
        <div className="nlt-card-header">
            <div className="nlt-card-title">Adresses de livraison</div>
        </div>
        <div className="address-list">
            <div className="address-wrapper add-address">
                <div>
                    <div className="address-list-wrapper">
                        <div className="address-container2">
                            <div className="address-layout">
                                <div className="address">
                                    <div className="address-name">
                                        <Skeleton width={80} />
                                    </div>
                                    <div className="address-text">
                                        <Skeleton width={170} />
                                    </div>
                                    <div className="address-text">
                                        <Skeleton width={50} />
                                    </div>
                                    <div className="address-text">
                                        <Skeleton width={60} />
                                    </div>
                                </div>
                                <div className="address-delete">
                                    <div>
                                        <i className="icofont icofont-bin bin-color" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default SkeletonAddressList;
