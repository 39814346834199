import React from "react";

import { UserDeviceFragment } from "graphql/management/model/apollo";

interface UserItemDevicesProps {
    devices: UserDeviceFragment[];
}

const UserItemDevices = ({ devices }: UserItemDevicesProps) => {
    if (devices.length <= 2) {
        return devices.map((device) => (
            <div
                key={device.serialNumber}
                className="nlt-device-item-data-info"
            >
                {`${device.reference}`}
            </div>
        ));
    }
    return (
        <>
            <div
                key={devices[0].serialNumber}
                className="nlt-device-item-data-info"
            >
                {`${devices[0].reference}`}
            </div>
            <div className="nlt-device-item-data-info">
                {`et ${devices.length - 1} de plus`}
            </div>
        </>
    );
};
export default UserItemDevices;
