/* eslint-disable no-nested-ternary */
import React, { useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import fr from "date-fns/locale/fr";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import {
    Container,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Loader from "components/common/layout/loader/loader";
import {
    DeviceFragment,
    IncidentFragment,
    IncidentReason,
    refetchComputeDashboardDataQuery,
    refetchFindDeviceIncidentQuery,
    useCreateIncidentMutation,
    useFindDevicesQuery,
    usePresignIncidentUploadLazyQuery,
} from "graphql/management/model/apollo";

import { IncidentLables } from "./incident-list";
import styles from "./incident.module.scss";

registerLocale("fr", fr);

type IncidentProps = {
    selectedDevice: DeviceFragment;
    setSelectedDevice: React.Dispatch<
        React.SetStateAction<DeviceFragment | null | undefined>
    >;
    incident: IncidentFragment | null;
};

const CleaqSwal = withReactContent(Swal);

interface ErrorIncident {
    proof: boolean;
}

const Incident = (props: IncidentProps) => {
    const { initialized } = useKeycloak();
    const devicesQuery = useFindDevicesQuery();
    const [presignUpload] = usePresignIncidentUploadLazyQuery();
    const [modal, setModal] = useState(false);
    const [newReason, setNewReason] = useState<IncidentReason>();
    const [newDate, setNewDate] = useState(new Date());
    const [errors, setErrors] = useState<ErrorIncident | null>();
    const [newInfos, setNewInfos] = useState("");
    const [newFile, setNewFile] = useState<FileList | null>();
    const [loadingSendingIncident, setLoadingSendingIncident] = useState(false);
    const [createIncident] = useCreateIncidentMutation();

    if (!initialized || devicesQuery.loading) return <Loader />;

    function convertToIncidentReason(
        incidentReasonString: string,
    ): IncidentReason {
        let incidentReason = IncidentReason.BREAKDOWN;
        IncidentLables.forEach((value, key) => {
            if (key.toString() === incidentReasonString) {
                incidentReason = key;
            }
        });
        return incidentReason;
    }

    const IncidentOptions = [
        IncidentReason.STOLEN,
        IncidentReason.BREAKDOWN,
        IncidentReason.BROKEN,
    ].map((incident) => (
        <option
            key={incident}
            value={incident}
        >
            {IncidentLables.get(incident)}
        </option>
    ));

    const ToggleModal = () => {
        setModal(!modal);
        setNewReason(IncidentReason.BREAKDOWN);
        setNewDate(new Date());
        setNewInfos("");
        setNewFile(null);
        setErrors(null);
    };

    const DeclareIncident = async () => {
        setLoadingSendingIncident(true);

        if (newReason === IncidentReason.STOLEN && !newFile) {
            setErrors({ proof: true });
            setLoadingSendingIncident(false);
            return;
        }

        let proof = "";
        if (newFile) {
            const presignUploadResult = await presignUpload({
                variables: { id: newFile[0].name },
            });
            if (presignUploadResult.data) {
                proof = presignUploadResult.data.presignUploadIncident;

                await fetch(
                    new Request(proof, {
                        body: newFile[0],
                        method: "PUT",
                    }),
                ).then(async (res) => {
                    if (!res.ok) {
                        setLoadingSendingIncident(false);
                        await CleaqSwal.fire({
                            title: "Une erreur s'est produite.",
                            html: "L'incident n'a pas pu être déclaré car le fichier joint n'a pas pu être envoyé.",
                            icon: "error",
                        });
                        return;
                    }
                });
            }
        }

        await createIncident({
            variables: {
                details: newInfos,
                reason: newReason!,
                serialNumber: props.selectedDevice.serialNumber,
                startingDate: newDate.toISOString(),
                proof,
            },
            refetchQueries: [
                refetchComputeDashboardDataQuery(),
                refetchFindDeviceIncidentQuery(),
            ],
        });

        const devices = await devicesQuery.refetch();
        props.setSelectedDevice(
            devices.data.findDevices.find(
                (device) =>
                    device.serialNumber === props.selectedDevice.serialNumber,
            ),
        );

        ToggleModal();
        setLoadingSendingIncident(false);

        await CleaqSwal.fire({
            title: "On s'en occupe ! &#x1F4AA;",
            html: "Nos équipes vont prendre contact avec vous dans la journée.",
            icon: "success",
        });
    };

    function parseDate(dateString: string): Date {
        const formats = [
            moment.ISO_8601,
            "DD/MM/YYYY HH:mm",
            "MM/DD/YYYY, hh:mm a",
            "DD/MM/YYYY",
            "MM/DD/YYYY",
        ];
        return moment(dateString, formats).toDate();
    }

    return (
        <>
            <div
                data-cy="incident-btn"
                aria-hidden="true"
                className="nlt-button-inside-incident"
                onClick={ToggleModal}
            >
                <i className="icofont icofont-warning mr-2" />
                Incident
            </div>
            <Modal
                isOpen={modal}
                toggle={ToggleModal}
                className="modal-body"
                centered
            >
                <ModalHeader toggle={ToggleModal}>
                    <div className="ml-3 nlt-card-title">
                        Déclarer un incident
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Container
                        fluid
                        className="bd-example-row"
                    >
                        <h6 className="mt-3 mb-3">
                            Informations de l'appareil
                        </h6>

                        <li className="nlt-item-info">
                            <div className="nlt-item-info-label">
                                Nom de l'appareil
                            </div>
                            <div className="nlt-item-info-data">
                                {props.selectedDevice.reference}
                            </div>
                        </li>
                        <li className="nlt-item-info">
                            <div className="nlt-item-info-label">
                                Utilisateur affecté
                            </div>
                            <div className="nlt-item-info-data">
                                {props.selectedDevice.affectedUser
                                    ? ` ${props.selectedDevice?.affectedUser?.firstName} ${props.selectedDevice?.affectedUser?.lastName}`
                                    : " Aucun utilisateur"}
                            </div>
                        </li>

                        <h6 className="mt-3">Déclarez maintenant l'incident</h6>
                        <FormGroup className="mb-2">
                            <Input
                                className="form-control"
                                disabled={props.incident !== null}
                                onChange={(event) =>
                                    setNewReason(
                                        convertToIncidentReason(
                                            event.target.value!,
                                        ),
                                    )
                                }
                                defaultValue={
                                    props.incident?.reason || newReason
                                }
                                type="select"
                            >
                                {IncidentOptions}
                            </Input>
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <DatePicker
                                className="form-control digits"
                                dateFormat="dd/MM/yyyy"
                                locale={fr}
                                filterDate={(date) => date < new Date()}
                                disabled={props.incident !== null}
                                selected={
                                    props.incident?.startingDate
                                        ? parseDate(
                                              props.incident?.startingDate,
                                          )
                                        : newDate
                                }
                                placeholderText={"Date de l'incident"}
                                onChange={(e) => setNewDate(e ?? new Date())}
                            />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Input
                                className="form-control"
                                type="textarea"
                                disabled={props.incident !== null}
                                placeholder="Dans quelles circonstances est-ce arrivé ?"
                                onChange={(e) => setNewInfos(e.target.value)}
                                defaultValue={
                                    props.incident?.details || newInfos
                                }
                            />
                        </FormGroup>

                        {props.incident === null && (
                            <FormGroup className="mb-2">
                                <label className="nlt-file-upload">
                                    <Input
                                        name="fileUpload"
                                        type="file"
                                        multiple={false}
                                        onChange={(e) =>
                                            setNewFile(e.target?.files)
                                        }
                                    />
                                    <i className="fa fa-file nlt-button-icon-plus" />
                                    {newFile && newFile[0]
                                        ? newFile[0].name
                                        : "Joindre un fichier (photo, dépôt de plainte, ...)"}
                                </label>

                                <span
                                    className={
                                        errors?.proof
                                            ? "nlt-font-red"
                                            : "nlt-font-grey"
                                    }
                                >
                                    {newReason === IncidentReason.STOLEN &&
                                        "En cas de vol, un dépôt de plainte est obligatoire."}
                                </span>
                            </FormGroup>
                        )}
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <div className="mr-3">
                        {props.incident === null && (
                            <button
                                className={`${styles.button} ${
                                    loadingSendingIncident &&
                                    styles.buttonLoading
                                }`}
                                disabled={loadingSendingIncident}
                                onClick={DeclareIncident}
                                data-cy="incident-declare-button"
                            >
                                {loadingSendingIncident ? (
                                    <div className={styles.loader}>
                                        Chargement{" "}
                                        <CircularProgress
                                            color="primary"
                                            size={20}
                                        />
                                    </div>
                                ) : (
                                    "Déclarer un incident"
                                )}
                            </button>
                        )}
                        <div
                            aria-hidden="true"
                            color="btn btn-light"
                            className="ml-4 nlt-button-inside-modal pull-right"
                            onClick={ToggleModal}
                        >
                            Annuler
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Incident;
