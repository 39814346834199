import React from "react";

import "./data-card.scss";

interface DataCardProps {
    label: string;
    imageUrl: string;
    "data-cy": string;
    emission?: boolean;
}

export default function DataCard(props: DataCardProps) {
    return (
        <div
            data-cy={props["data-cy"]}
            className={`data-card ${
                props.emission ? "data-card-emission" : "data-card-saved"
            }`}
        >
            <p className="data-card__label">{props.label}</p>
            <img
                src={props.imageUrl}
                alt=""
                width="18"
                height="18"
            />
        </div>
    );
}

DataCard.defaultProps = { emission: false };
