import moment from "moment";

import { DeliveryTypeInput } from "graphql/order/model/apollo";

import { getEarliestShippingDateAvailable } from "../step-form/utils";

export default class ProductDeliveryDate {
    date: string;

    deliveryType: DeliveryTypeInput = DeliveryTypeInput.SOONEST;

    constructor(shippingDelay: number) {
        this.date = moment
            .utc(getEarliestShippingDateAvailable(shippingDelay))
            .toISOString();
    }
}
