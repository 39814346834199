import React from "react";

import { Controller, useForm } from "react-hook-form";

import Input from "components/common/input/Input/Input";
import Button from "components/common/layout/Button/Button";
import {
    refetchFindIntegrationsQuery,
    useCreateIntegrationMutation,
} from "graphql/management/model/apollo";
import displayAlertMessage from "utils/displayAlertMessage/displayAlertMessage";

import styles from "./BravasConfiguration.module.scss";

class BravasConfigurationFormField {
    fqdn: string = "";
}

interface BravasConfigurationProps {
    fqdn: string | null;
    isActive: boolean;
}

const BravasConfiguration = ({ fqdn, isActive }: BravasConfigurationProps) => {
    const [createIntegration] = useCreateIntegrationMutation();

    const { handleSubmit, control, watch, setValue, clearErrors } =
        useForm<BravasConfigurationFormField>({
            mode: "onChange",
            defaultValues: {
                fqdn: fqdn ?? "",
            },
        });

    const watchFqdn = watch("fqdn") ?? "";

    const activateBravas = async (form: BravasConfigurationFormField) => {
        await createIntegration({
            variables: {
                bravas: { fqdn: form.fqdn },
            },
            onCompleted: () => {
                displayAlertMessage(
                    "L'intégration a bien été activée.",
                    "success",
                );
            },
            onError: () => {
                displayAlertMessage(
                    "L'intégration n'a pas pu être activée.",
                    "error",
                );
            },
            refetchQueries: [refetchFindIntegrationsQuery()],
        });
    };

    const handleCancelButton = () => {
        setValue("fqdn", "");
        clearErrors();
    };

    return (
        <div>
            {isActive ? (
                <p className={styles.paragraph}>
                    Pour modifier votre nom de domaine, désactivez puis
                    réactivez l'intégration avec Bravas.
                </p>
            ) : (
                <p className={styles.paragraph}>
                    Pour activer Bravas, veuillez renseigner votre nom de
                    domaine. Pour trouver votre nom de domaine, récupérez le nom
                    de domaine que vous avez choisi sur Bravas en y ajoutant
                    <i>.bravas</i> puis <i>.cloud</i> ou <i>.us</i> selon le
                    cluster choisi. Exemple: <i>cleaq.bravas.cloud</i>
                </p>
            )}
            <form onSubmit={handleSubmit(activateBravas)}>
                <Controller
                    name="fqdn"
                    control={control}
                    rules={{
                        required: "Le nom de domaine est requis",
                    }}
                    disabled={isActive}
                    render={({ field, fieldState: { error } }) => (
                        <Input
                            {...field}
                            data-cy="bravas-configuration-input"
                            title="Nom de domaine"
                            placeholder="<Nom de domaine>.bravas.xxx"
                            errorMessage={error?.message}
                            isValid={
                                watchFqdn.length > 0 &&
                                error === undefined &&
                                !isActive
                            }
                        />
                    )}
                />
                {!isActive && watchFqdn && (
                    <div className={styles.buttonGroup}>
                        <Button
                            onClick={handleCancelButton}
                            type="reset"
                        >
                            Annuler
                        </Button>
                        <Button
                            type="submit"
                            theme="primary"
                        >
                            Enregistrer
                        </Button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default BravasConfiguration;
