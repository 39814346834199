import React, { useEffect, useState } from "react";

import { useKeycloak } from "@react-keycloak/web";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { Row } from "reactstrap";

import SelectAndFilterUsers from "components/business/user/select-and-filter-users";
import SelectedUsersActionsMenu from "components/business/user/selected-users-actions/selected-users-actions";
import UserList from "components/business/user/user-item/user-list";
import UserSidePanel from "components/business/user/user-sidepanel/user-sidepanel";
import SmallScreen from "components/common/layout/banner/small-screen";
import SkeletonItem from "components/common/layout/loader/skeleton-item";
import MultiActionsButton from "components/common/layout/multi-actions-button/multi-actions-button";
import SearchBar from "components/common/layout/searchbar/searchbar";
import {
    OrganisationFragment,
    useFindOrganisationsQuery,
    useFindUsersQuery,
    UserFragment,
} from "graphql/management/model/apollo";

export enum WithDevices {
    ALL = "all",
    WITH_DEVICES = "with",
    WITHOUT_DEVICES = "without",
}

const User = (props: any) => {
    const { initialized } = useKeycloak();
    const usersQuery = useFindUsersQuery();
    const instanceGroups = useFindOrganisationsQuery();
    const [selectedUser, setSelectedUser] = useState<UserFragment | null>();
    const [editable, setEditable] = useState(false);
    const [isCreateUser, setIsCreateUser] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const history = useHistory();
    const location = useLocation();
    const [saving, setSaving] = useState(false);

    const [activeTab] = useState("1");
    const [orgactiveTab, setorgActiveTab] = useState<OrganisationFragment[]>(
        [],
    );
    const [isWithDevices, setIsWithDevices] = useState<WithDevices>(
        WithDevices.ALL,
    );
    const [selectedItems, setSelectedItems] = useState<UserFragment[]>([]);

    const BuildUsers =
        usersQuery?.data &&
        usersQuery?.data.findUser
            ?.filter(
                (user) =>
                    (orgactiveTab?.length === 0 ||
                        orgactiveTab?.filter((activeTag) =>
                            user.organisations
                                ?.map((orgaItem) => orgaItem.id)
                                ?.includes(activeTag.id),
                        )?.length > 0) &&
                    (isWithDevices === WithDevices.ALL ||
                        (isWithDevices === WithDevices.WITH_DEVICES &&
                            user.devices?.length) ||
                        (isWithDevices === WithDevices.WITHOUT_DEVICES &&
                            !user.devices?.length)),
            )
            // eslint-disable-next-line array-callback-return, consistent-return
            ?.filter((user) => {
                if (searchTerm === "" || searchTerm.length < 2) {
                    return user;
                }
                if (
                    user.lastName
                        ?.toLocaleLowerCase()
                        ?.includes(searchTerm.toLocaleLowerCase()) ||
                    user.firstName
                        ?.toLocaleLowerCase()
                        ?.includes(searchTerm.toLocaleLowerCase()) ||
                    user.email
                        ?.toLocaleLowerCase()
                        ?.includes(searchTerm.toLocaleLowerCase())
                ) {
                    return user;
                }
            });

    const selectUser = () => {
        if (usersQuery.loading) {
            return;
        }
        if (props.location.state && props.location.state.mailSearch) {
            setSelectedUser(
                usersQuery?.data?.findUser?.find(
                    (user) => user.email === props.location.state.mailSearch,
                ),
            );
            setSearchTerm(props.location.state.mailSearch);
            history.replace({
                pathname: location.pathname,
                state: null,
            });
        }
        if (props.location.state && props.location.state.association) {
            setSelectedUser(null);
            setIsWithDevices(props.location.state.association);
            history.replace({
                pathname: location.pathname,
                state: null,
            });
        }
    };

    useEffect(() => {
        selectUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersQuery.loading, props?.location]);

    const OrganisationOptions = instanceGroups.data?.findOrganisations?.map(
        (organisation) => ({
            value: organisation.id,
            label: organisation.name,
        }),
    );

    const CancelEdit = () => {
        setEditable(false);
        setSaving(false);
        setIsCreateUser(false);
    };

    const handleSearchChange = (event: any) => {
        event.preventDefault();
        setSearchTerm(event.target.value);
        setSelectedUser(null);
    };

    return (
        <>
            <div className="nlt-container">
                <div className="nlt-container-scale email-wrap bookmark-wrap">
                    <div className="nlt-card-left-panel">
                        <div
                            className="nlt-card-header flex-sb"
                            style={{
                                alignItems: "center",
                                height: "50px",
                            }}
                        >
                            <span className="nlt-card-title align-title">
                                Utilisateurs
                            </span>
                            <div>
                                {selectedItems.length > 0 ? (
                                    <SelectedUsersActionsMenu
                                        selectedUsers={selectedItems}
                                        setSelectedUsers={setSelectedItems}
                                        OrganisationOptions={
                                            OrganisationOptions!
                                        }
                                    />
                                ) : (
                                    <MultiActionsButton
                                        title="Nouveaux utilisateurs"
                                        data-cy="multi-action-user"
                                        actions={[
                                            {
                                                title: "Créer un utilisateur",
                                                icon: (
                                                    <i className="fa fa-plus m-r-10" />
                                                ),
                                                onClick: () => {
                                                    setIsCreateUser(true);
                                                },
                                            },
                                            {
                                                title: "Importer un fichier",
                                                icon: (
                                                    <i className="icofont icofont-file-text m-r-10" />
                                                ),
                                                onClick: () => {
                                                    history.push({
                                                        pathname: `${process.env.PUBLIC_URL}/load-data`,
                                                        state: null,
                                                    });
                                                },
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                        <SearchBar
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            handleSearchChange={handleSearchChange}
                        />
                        <SelectAndFilterUsers
                            buildUsers={BuildUsers}
                            selectedUsers={selectedItems}
                            setSelectedUsers={setSelectedItems}
                            setSelectedUser={setSelectedUser}
                            setIsCreateUser={setIsCreateUser}
                            orgs={instanceGroups.data?.findOrganisations}
                            activeOrgs={orgactiveTab}
                            setActiveOrgs={setorgActiveTab}
                            setIsWithDevices={setIsWithDevices}
                        />
                        <div>
                            {!initialized ||
                            usersQuery.loading ||
                            instanceGroups.loading ? (
                                <SkeletonItem />
                            ) : (
                                <div className="email-body radius-left">
                                    <div className="pl-0">
                                        <Row
                                            className="list-persons"
                                            id="addcon"
                                        >
                                            <UserList
                                                activeTab={activeTab}
                                                buildUsers={BuildUsers}
                                                cancelEdit={CancelEdit}
                                                selectedUser={selectedUser}
                                                setSelectedUser={
                                                    setSelectedUser
                                                }
                                                selectedUsers={selectedItems}
                                                setSelectedUsers={
                                                    setSelectedItems
                                                }
                                                setIsCreateUser={
                                                    setIsCreateUser
                                                }
                                            />
                                            {(isCreateUser || selectedUser) && (
                                                <UserSidePanel
                                                    saving={saving}
                                                    setSaving={setSaving}
                                                    organisationOptions={
                                                        OrganisationOptions
                                                    }
                                                    editable={editable}
                                                    setEditable={setEditable}
                                                    selectedUser={selectedUser}
                                                    setSelectedUser={
                                                        setSelectedUser
                                                    }
                                                    isCreateUser={isCreateUser}
                                                    setIsCreateUser={
                                                        setIsCreateUser
                                                    }
                                                />
                                            )}
                                        </Row>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <SmallScreen />
        </>
    );
};

export default withRouter(User);
