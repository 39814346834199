import { createTheme } from "@material-ui/core";
import type { ThemeOptions } from "@material-ui/core";

const CleaqThemeOptions: ThemeOptions = {
    palette: {
        type: "light",
        primary: {
            main: "#ff625a",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#0c745e",
        },
        background: {
            default: "#fcf9f4",
        },
        warning: {
            main: "#FD7E14",
        },
        success: {
            main: "#28A745",
        },
    },
};

const CleaqTheme = createTheme(CleaqThemeOptions);

export default CleaqTheme;
