import React from "react";

import Product from "components/business/order/product/product";
import { DeviceProductSpecs } from "components/business/order/product/product-specs";
import AffectedUserLabel from "components/business/order/step-form/affected-user-label";
import translator, { formatMemory } from "utils/translator/translator";

import AccessorySpecs from "./accessory-specs/accessory-specs";

interface ProductItemInfoProps {
    product: Product;
}

export default function ProductItemInfo(props: ProductItemInfoProps) {
    const { product } = props;

    const ProductConfigTag = (config: string | null, unit?: string) => {
        if (config && config.length > 0) {
            return (
                <span className="specs-infos">
                    {translator.translate(config)}
                    {unit && ` ${unit}`}
                </span>
            );
        }
        return <></>;
    };

    const ProductItemSpecs = () => {
        if (product.type === "Variant") {
            const specsProduct = product.specs as DeviceProductSpecs;
            return (
                <>
                    {ProductConfigTag(specsProduct.processor)}
                    {ProductConfigTag(
                        formatMemory(parseFloat(specsProduct.ram ?? "0")),
                        "RAM",
                    )}
                    {ProductConfigTag(
                        formatMemory(parseFloat(specsProduct.storage ?? "0")),
                        "SSD",
                    )}
                </>
            );
        }
        return <AccessorySpecs accessory={product} />;
    };

    return (
        <div className="product-item__info">
            <div className="info__device">
                {product.photo && (
                    <img
                        src={product.photo}
                        alt={product.name}
                        className="device__image"
                    />
                )}
                <div className="device__name">
                    <h4>{product.name}</h4>
                    <p className="device__leasing-duration">
                        {`Renouvellement sur ${product.leasingDuration} mois`}
                    </p>
                    <div className="device__specs">
                        <ProductItemSpecs />
                    </div>
                    <AffectedUserLabel user={product.user} />
                </div>
            </div>
        </div>
    );
}
