import React from "react";

import { Row } from "reactstrap";

import MultipleItemSelector from "components/common/layout/multiple-item-selector/multiple-item-selector";
import MultipleSelectFilter from "components/common/layout/multiple-select-filter/multiple-select-filter";
import SingleSelectionFilter from "components/common/layout/single-selection-filter/single-selection-filter";
import {
    OrganisationFragment,
    UserFragment,
} from "graphql/management/model/apollo";

export enum WithDevices {
    ALL = "all",
    WITH_DEVICES = "with",
    WITHOUT_DEVICES = "without",
}

interface SelectUsersInterface {
    buildUsers: UserFragment[] | undefined;
    selectedUsers: UserFragment[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<UserFragment[]>>;
    setSelectedUser: React.Dispatch<
        React.SetStateAction<UserFragment | null | undefined>
    >;
    setIsCreateUser: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FilterUsersInterface {
    orgs: OrganisationFragment[] | undefined;
    activeOrgs: OrganisationFragment[];
    setActiveOrgs: React.Dispatch<React.SetStateAction<OrganisationFragment[]>>;
    setIsWithDevices: React.Dispatch<React.SetStateAction<WithDevices>>;
}

const SelectAndFilterUsers = (
    props: SelectUsersInterface & FilterUsersInterface,
) => {
    function toggleDeviceOrganisationTab(userOrg: OrganisationFragment) {
        if (props.activeOrgs.includes(userOrg)) {
            props.setActiveOrgs(
                props.activeOrgs.filter((org) => org !== userOrg),
            );
        } else {
            props.setActiveOrgs([...props.activeOrgs, userOrg]);
        }
    }

    const associationOptions = [
        {
            label: "Avec appareils",
            onClick: () => {
                props.setIsWithDevices(WithDevices.WITH_DEVICES);
                props.setSelectedUser(null);
            },
        },
        {
            label: "Sans appareil",
            onClick: () => {
                props.setIsWithDevices(WithDevices.WITHOUT_DEVICES);
                props.setSelectedUser(null);
            },
        },
    ];

    return (
        <Row
            style={{
                marginLeft: "-2%",
                width: "102%",
                marginBottom: "10px",
            }}
        >
            <div
                className="nlt-wide-header"
                style={{
                    marginLeft: "2.5%",
                    marginRight: "2.5%",
                }}
            >
                <div className="selectAll col-lg-2 col-xl-1">
                    <MultipleItemSelector
                        allElements={props.buildUsers?.length}
                        selectedElements={props.selectedUsers.length}
                        onClick={() => {
                            props.setSelectedUser(null);
                            props.setIsCreateUser(false);
                            if (
                                props.selectedUsers.length ===
                                props.buildUsers?.length
                            ) {
                                props.setSelectedUsers([]);
                            } else {
                                props.setSelectedUsers(props.buildUsers!);
                            }
                        }}
                    />
                </div>

                <div className="nlt-wide-filters">
                    {props.orgs && props.orgs.length > 0 && (
                        <MultipleSelectFilter
                            data-cy="user-tag-filter"
                            title="Tag"
                            selectedTitle="Tags"
                            options={props.orgs?.map((org) => ({
                                label: org.name,
                                onClick: () => {
                                    toggleDeviceOrganisationTab(org);
                                    props.setSelectedUser(null);
                                },
                            }))}
                            resetFilter={() => {
                                props.setActiveOrgs([]);
                                props.setSelectedUser(null);
                            }}
                        />
                    )}
                    <SingleSelectionFilter
                        data-cy="user-association-filter"
                        title="Association"
                        options={associationOptions}
                        resetFilter={() => {
                            props.setIsWithDevices(WithDevices.ALL);
                            props.setSelectedUser(null);
                        }}
                    />
                </div>
            </div>
        </Row>
    );
};

export default SelectAndFilterUsers;
