import React from "react";

const LeafIcon = () => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.5 16C7.56667 16 6.68734 15.854 5.862 15.562C5.03667 15.27 4.28267 14.8577 3.6 14.325L2.2 15.7C2.01667 15.8833 1.78334 15.975 1.5 15.975C1.21667 15.975 0.983336 15.8833 0.800002 15.7C0.616669 15.5167 0.525002 15.2833 0.525002 15C0.525002 14.7167 0.616669 14.4833 0.800002 14.3L2.175 12.925C1.64167 12.2417 1.229 11.4833 0.937002 10.65C0.645002 9.81667 0.499336 8.93333 0.500002 8C0.500002 5.76667 1.275 3.875 2.825 2.325C4.375 0.775 6.26667 0 8.5 0H16.5V8C16.5 10.2333 15.725 12.125 14.175 13.675C12.625 15.225 10.7333 16 8.5 16ZM8.5 14C10.1667 14 11.5833 13.4167 12.75 12.25C13.9167 11.0833 14.5 9.66667 14.5 8V2H8.5C6.83334 2 5.41667 2.58333 4.25 3.75C3.08334 4.91667 2.5 6.33333 2.5 8C2.5 8.65 2.6 9.271 2.8 9.863C3 10.455 3.275 10.9923 3.625 11.475L8.8 6.3C8.98334 6.11667 9.21667 6.025 9.5 6.025C9.78334 6.025 10.0167 6.11667 10.2 6.3C10.4 6.5 10.5 6.73767 10.5 7.013C10.5 7.28833 10.4 7.52567 10.2 7.725L5.025 12.9C5.50834 13.25 6.046 13.521 6.638 13.713C7.23 13.905 7.85067 14.0007 8.5 14Z"
            fill="#FF625A"
        />
    </svg>
);

export default LeafIcon;
