import React from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    OrganisationFragment,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    useDeleteOrganisationsMutation,
} from "graphql/management/model/apollo";

interface deleteTagsInterface {
    selectedTags: OrganisationFragment[];
    setSelectedTags: React.Dispatch<
        React.SetStateAction<OrganisationFragment[]>
    >;
}

const CleaqSwal = withReactContent(Swal);

const DeleteMultipleTags = (props: deleteTagsInterface) => {
    const [deleteOrganisations] = useDeleteOrganisationsMutation();

    const DeleteMultipleOrganisation = () => {
        CleaqSwal.fire({
            title: `Vous souhaitez supprimer ${props.selectedTags.length} tags !`,
            html: "Les utilisateurs de ces tags seront toujours disponibles",
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
            cancelButtonText: "Annuler",
            denyButtonText: "Confirmer suppression",
        }).then(async (result) => {
            if (result.isDenied) {
                await deleteOrganisations({
                    variables: {
                        ids: props.selectedTags.map((tag) => tag.id),
                    },
                    refetchQueries: [
                        refetchFindUsersQuery(),
                        refetchFindOrganisationsQuery(),
                        refetchFindDevicesQuery(),
                    ],
                    onCompleted: (response) => {
                        if (response.deleteOrganisations) {
                            CleaqSwal.fire({
                                title: "Confirmé !",
                                html: "Les tags ont été supprimés",
                                icon: "success",
                            });
                        } else {
                            CleaqSwal.fire({
                                title: "Oops, une erreur s'est produite !",
                                html: "Contactez-nous dans le chat !",
                                icon: "error",
                            });
                        }
                    },
                }).finally(() => {
                    props.setSelectedTags([]);
                });
            }
        });
    };

    return (
        <div
            aria-hidden="true"
            className="nlt-button-inside-delete float-right"
            onClick={DeleteMultipleOrganisation}
        >
            <i className="icofont icofont-bin" />
        </div>
    );
};

export default DeleteMultipleTags;
