/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from "react";

import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Form, FormGroup, Input, Row } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    UserFormData,
    OrganisationOption,
} from "components/business/user/types";
import CustomCheckbox from "components/common/input/custom-checkbox/custom-checkbox";
import {
    refetchFindAdminQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    useCreateUserMutation,
    UserFragment,
    UserRole,
} from "graphql/management/model/apollo";
import EmailRegExp from "utils/email-utils/email-utils";

interface CreateUserInterface {
    saving: boolean;
    setSaving: React.Dispatch<React.SetStateAction<boolean>>;
    setIsCreateUser: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedUser: React.Dispatch<
        React.SetStateAction<UserFragment | null | undefined>
    >;
    setEditable: React.Dispatch<React.SetStateAction<boolean>>;
    organisationOptions: OrganisationOption[] | undefined;
}

const CreateUserPanel = (props: CreateUserInterface) => {
    const { control, reset, handleSubmit } = useForm<UserFormData>({
        defaultValues: {
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            customNotes: "",
            administrateur: false,
            organisations: [],
        },
    });
    const CancelEdit = () => {
        reset();
        props.setEditable(false);
        props.setSaving(false);
        props.setIsCreateUser(false);
    };

    const [createUser] = useCreateUserMutation();

    const CleaqSwal = withReactContent(Swal);

    const CreateUserForm = async (dataPush: UserFormData) => {
        await props.setSaving(true);
        try {
            await createUser({
                variables: {
                    email: dataPush.email,
                    firstName: dataPush.firstName,
                    lastName: dataPush.lastName,
                    organisations: dataPush.organisations?.map(
                        (orgaItem: any) => ({ id: orgaItem?.value }),
                    ),
                    phoneNumber: dataPush.phoneNumber,
                    customNotes: dataPush.customNotes,
                    role:
                        dataPush?.administrateur === true
                            ? UserRole.OWNER
                            : UserRole.USER,
                },
                update: async (_, updatedUserResult) => {
                    if (updatedUserResult.data?.createUser) {
                        const updatedUser = updatedUserResult?.data?.createUser;
                        props.setSelectedUser(updatedUser);
                        props.setIsCreateUser(false);
                    }
                },
                refetchQueries: [
                    refetchFindOrganisationsQuery(),
                    refetchFindUsersQuery(),
                    refetchFindAdminQuery(),
                ],
            });
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html:
                    "Vérifiez l'adresse e-mail renseignée." +
                    "<br/>Si le problème persiste, contactez nous dans le chat",
                icon: "error",
            });
        }
        await props.setSaving(false);
    };

    return (
        <div className="profile-mail">
            <Form
                noValidate
                onSubmit={handleSubmit(CreateUserForm)}
                name="management-user"
                id="management-user"
            >
                <div className="media">
                    <div className="media-body mt-0">
                        {!props.saving ? (
                            <div className="nlt-card-title-buttons">
                                <button
                                    type="submit"
                                    className="nlt-button-inside-form-save"
                                >
                                    Enregistrer
                                </button>
                                <div
                                    onClick={CancelEdit}
                                    className="nlt-button-inside-form-cancel"
                                >
                                    Annuler
                                </div>
                            </div>
                        ) : (
                            <div className="nlt-loader-container">
                                <div className="nlt-loader-save" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="email-general">
                    <h6 className="mb-3">Informations</h6>
                    <ul>
                        <Row className="nlt-row-form-fix">
                            <div className="nlt-item-info-label-edit col-md-5 required">
                                Adress e-mail
                            </div>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (value) =>
                                        EmailRegExp.test(value),
                                }}
                                render={({
                                    field: { ref, ...rest },
                                    fieldState: { error },
                                }) => (
                                    <FormGroup className="col-md-7">
                                        {!error ? (
                                            <span className="mandatory-label-small" />
                                        ) : (
                                            <span className="nlt-mandatory-label-small nlt-font-red">
                                                L'adresse e-mail est invalide
                                            </span>
                                        )}
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="email"
                                            placeholder="laura.petit@cleaq.com"
                                            autoFocus
                                            innerRef={ref}
                                        />
                                    </FormGroup>
                                )}
                            />
                        </Row>
                        <Row className="nlt-row-form-fix">
                            <div className="nlt-item-info-label-edit col-md-5 required">
                                Nom
                            </div>
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (value) =>
                                        value?.trim()?.length > 0,
                                }}
                                render={({
                                    field: { ref, ...rest },
                                    fieldState: { error },
                                }) => (
                                    <FormGroup className="col-md-7">
                                        {!error ? (
                                            <span className="mandatory-label-small" />
                                        ) : (
                                            <span className="nlt-mandatory-label-small nlt-font-red">
                                                Le nom de famille est requis
                                            </span>
                                        )}
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="text"
                                            placeholder="Petit"
                                            innerRef={ref}
                                        />
                                    </FormGroup>
                                )}
                            />
                        </Row>
                        <Row className="nlt-row-form-fix">
                            <div className="nlt-item-info-label-edit col-md-5 required">
                                Prénom
                            </div>
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (value) =>
                                        value?.trim()?.length > 0,
                                }}
                                render={({
                                    field: { ref, ...rest },
                                    fieldState: { error },
                                }) => (
                                    <FormGroup className="col-md-7">
                                        {!error ? (
                                            <span className="mandatory-label-small" />
                                        ) : (
                                            <span className="nlt-mandatory-label-small nlt-font-red">
                                                Le prénom est requis
                                            </span>
                                        )}
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="text"
                                            placeholder="Laura"
                                            innerRef={ref}
                                        />
                                    </FormGroup>
                                )}
                            />
                        </Row>
                        <Row className="nlt-row-form-fix">
                            <div className="nlt-item-info-label-edit col-md-5">
                                N° téléphone
                            </div>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                render={({ field: { ref, ...rest } }) => (
                                    <FormGroup className="col-md-7">
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="tel"
                                            placeholder="06 01 02 03 04"
                                            innerRef={ref}
                                        />
                                    </FormGroup>
                                )}
                            />
                        </Row>
                        <Row className="nlt-row-form-fix">
                            <div className="nlt-item-info-label-edit col-md-5">
                                Administrateur
                            </div>
                            <FormGroup className="col-md-7">
                                <Controller
                                    name="administrateur"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                        <CustomCheckbox
                                            {...field}
                                            id="check-admin"
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Row>

                        <div className="nlt-device-sep" />
                        <h6 className="mb-3">Tags</h6>
                        <Row className="nlt-row-form-fix">
                            <FormGroup className="col-md-12">
                                <Controller
                                    control={control}
                                    name="organisations"
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={props.organisationOptions}
                                            placeholder="Tags..."
                                            noOptionsMessage={() => "Aucun tag"}
                                            isMulti
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Row>
                        <div className="nlt-device-sep" />
                        <h6 className="mb-3">Notes</h6>
                        <Row>
                            <FormGroup className="col-md-12">
                                <Controller
                                    name="customNotes"
                                    control={control}
                                    render={({ field: { ref, ...rest } }) => (
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="textarea"
                                            placeholder="Notes"
                                            innerRef={ref}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Row>
                    </ul>
                </div>
            </Form>
        </div>
    );
};

export default CreateUserPanel;
