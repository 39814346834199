import React from "react";

import type { UseFormReturn } from "react-hook-form";
import { FormGroup, Input, Row } from "reactstrap";

import type { FormData } from "utils/device/device-form";

interface deviceUpdateTextInterface {
    "data-cy": string;
    label: string;
    name: keyof FormData;
    placeHolder: string;
    defaultValue: string;
    deviceForm: UseFormReturn<FormData>;
    required?: boolean;
    disabled: boolean;
}

const DeviceUpdateText = (props: deviceUpdateTextInterface) => {
    const { deviceForm, required, name } = props;
    const { register } = deviceForm;
    const { ref, ...rest } = register(name, { required });
    return (
        <>
            <Row className="nlt-row-form-fix fix-padding">
                <div className="nlt-item-info-label-edit col-md-5">
                    {props.label}
                </div>
                <FormGroup className="col-md-7">
                    <Input
                        {...rest}
                        data-cy={props["data-cy"]}
                        className="form-control"
                        type="text"
                        placeholder={props.placeHolder}
                        defaultValue={props.defaultValue}
                        innerRef={ref}
                        disabled={props.disabled}
                    />
                </FormGroup>
            </Row>
        </>
    );
};
DeviceUpdateText.defaultProps = { required: false };

export default DeviceUpdateText;
