import React, { useEffect } from "react";

import { useKeycloak } from "@react-keycloak/web";
import { withRouter } from "react-router";
import { Link, useLocation } from "react-router-dom";

import useFeatureFlags, { feature } from "components/hooks/useFeatureFlags";
import settings from "config";
import { useFindCurrentUserQuery } from "graphql/management/model/apollo";
import { routes, sidebarLinks } from "routes/routes";
import { logout } from "utils/authentication/authentication";
import { isUser } from "utils/check-role/check-role";

import Icon from "../../icons/icon";
import Logo from "../../icons/logo";
import Initials from "../initials/initials";

import authenticateHubspotChat from "./hubspot-plugin";

import "./sidebar.scss";

function prefixLocalPath(path: string): string {
    const isLocal = settings.REACT_APP_ENV === "local";
    return isLocal ? `http://localhost:3001${path}` : path;
}

const Sidebar = () => {
    const { data: user, loading: userLoading } = useFindCurrentUserQuery();

    const { keycloak } = useKeycloak();
    const userFirstName: string | undefined =
        keycloak?.tokenParsed?.["given_name"];
    const userLastName: string | undefined =
        keycloak?.tokenParsed?.["family_name"];

    const { router: toggleRouter, loading: featureLoading } = useFeatureFlags();

    const location = useLocation();

    function linkIsActive(menuItem: any): boolean {
        const currentPath = location.pathname.split("/")[1];
        return menuItem.path.split("/")[1] === currentPath;
    }

    useEffect(() => {
        if (!userLoading && !featureLoading && user?.findCurrentUser) {
            authenticateHubspotChat(
                user.findCurrentUser,
                toggleRouter.featureIsEnabled(feature.HELP_WIDGET),
            );
        }
    }, [user, featureLoading, toggleRouter, userLoading]);

    const appRoutes = routes(keycloak, toggleRouter);
    const appSidebarLinks = sidebarLinks(keycloak, toggleRouter);

    function generatedLinks() {
        if (isUser(keycloak)) {
            return (
                <ul className="mt-40">
                    {appSidebarLinks.map((menuItem: any) => (
                        <li
                            className={linkIsActive(menuItem) ? "active" : ""}
                            key={menuItem.path}
                        >
                            <Link
                                key={menuItem.path}
                                to={menuItem.path}
                            >
                                <menuItem.icon />
                                <span>{menuItem.title}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            );
        }
        return (
            <div>
                {appSidebarLinks.map((menuCategory: any, i) => (
                    <div key={menuCategory.name}>
                        <p className="category-name">
                            <span>{menuCategory.name}</span>
                        </p>
                        <ul>
                            {menuCategory.items.map((menuItem: any) => {
                                if (
                                    toggleRouter.featureIsEnabled(
                                        feature.NEXT_CATALOG,
                                    ) &&
                                    menuItem.title === "Catalogue"
                                ) {
                                    return (
                                        <li key={menuItem.path}>
                                            <a
                                                href={prefixLocalPath(
                                                    "/order/catalog/laptop",
                                                )}
                                                target="_self"
                                            >
                                                <menuItem.icon />
                                                <span>{menuItem.title}</span>
                                            </a>
                                        </li>
                                    );
                                }
                                return (
                                    <li
                                        className={
                                            linkIsActive(menuItem)
                                                ? "active"
                                                : ""
                                        }
                                        key={menuItem.path}
                                    >
                                        <Link
                                            to={
                                                process.env.PUBLIC_URL +
                                                menuItem.path
                                            }
                                        >
                                            <menuItem.icon />
                                            <span>{menuItem.title}</span>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <>
            <aside className="sidebar-cleaq">
                <div>
                    <div className="logo">
                        <Link to={appRoutes[0]?.path}>
                            <Icon />
                            <div className="long">
                                <Logo />
                            </div>
                        </Link>
                    </div>
                    <nav className={isUser(keycloak) ? "user-sidebar" : ""}>
                        {generatedLinks()}
                    </nav>
                </div>
                {userFirstName && userLastName && (
                    <div className="account">
                        <div className="drop-up">
                            <Initials
                                firstName={userFirstName}
                                lastName={userLastName}
                            />
                            <div className="name-roles">
                                <p>{`${userFirstName} ${userLastName}`}</p>
                                <p>
                                    {isUser(keycloak)
                                        ? "Utilisateur "
                                        : "Admin "}
                                    <i className="middle fa fa-angle-down" />
                                </p>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <Link to={`${process.env.PUBLIC_URL}/account`}>
                                    Mon Compte
                                </Link>
                            </li>
                            <li
                                aria-hidden="true"
                                onClick={() => logout(keycloak)}
                            >
                                <span>Déconnexion</span>
                            </li>
                        </ul>
                    </div>
                )}
                <div id="sidebar-blind" />
            </aside>
        </>
    );
};

export default withRouter(Sidebar);
