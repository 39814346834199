import React from "react";

import CheckboxFilter from "components/business/catalog/filter/checkbox-filter/checkbox-filter";
import FilterValues from "components/business/catalog/filter/filter-values";
import { FilterNames } from "components/business/catalog/filter/filters";
import RangeCheckboxFilter from "components/business/catalog/filter/range-checkbox-filter/range-checkbox-filter";
import LoopIcon from "components/common/icons/LoopIcon";
import ClearSidebarFiltersAction from "components/provider/context/catalog-filters/actions/clear-sidebar-filters-actions";
import {
    useCatalogFilters,
    useCatalogFiltersDispatch,
} from "components/provider/context/catalog-filters/catalog-filters-provider";

import "./filters-sidebar.scss";

interface FiltersSidebarProps {
    filterLabels: FilterValues;
    isCatalogFilterDisabled: boolean;
}

const FiltersSidebar = (props: FiltersSidebarProps) => {
    const activeFilters = useCatalogFilters();
    const catalogFiltersDispatch = useCatalogFiltersDispatch();

    const sortFilters = (a: string, b: string): number =>
        parseInt(a, 10) - parseInt(b, 10);
    const sortFloatFilters = (a: string, b: string): number =>
        parseFloat(a) - parseFloat(b);

    if (props.isCatalogFilterDisabled) {
        return <></>;
    }

    return (
        <div className="filters-sidebar">
            <button
                className="clear-sidebar-filters"
                data-cy="clear-sidebar-filters"
                type="button"
                onClick={() => {
                    catalogFiltersDispatch(new ClearSidebarFiltersAction());
                }}
            >
                <LoopIcon /> Réinitialiser les filtres
            </button>
            {props.filterLabels.screenSize[0] !== undefined && (
                <CheckboxFilter
                    items={props.filterLabels.screenSize.sort(sortFloatFilters)}
                    type={FilterNames.screen}
                    values={activeFilters.screen}
                    // eslint-disable-next-line quotes
                    unit={'"'}
                />
            )}
            {props.filterLabels.processor[0] !== undefined && (
                <CheckboxFilter
                    items={props.filterLabels.processor.sort()}
                    values={activeFilters.processor}
                    type={FilterNames.processor}
                    unit=""
                />
            )}
            {props.filterLabels.storage[0] !== undefined && (
                <CheckboxFilter
                    items={props.filterLabels.storage.sort(sortFilters)}
                    type={FilterNames.storage}
                    values={activeFilters.storage}
                    unit="Go"
                />
            )}
            {props.filterLabels.storage[0] && (
                <CheckboxFilter
                    items={props.filterLabels.ram.sort(sortFilters)}
                    type={FilterNames.ram}
                    values={activeFilters.ram}
                    unit="Go"
                />
            )}
            {props.filterLabels.os[0] !== undefined && (
                <CheckboxFilter
                    items={props.filterLabels.os}
                    type={FilterNames.os}
                    values={activeFilters.os}
                    unit=""
                />
            )}

            {props.filterLabels.budget.length > 0 && (
                <RangeCheckboxFilter
                    valuesChecked={activeFilters.budget}
                    filters={props.filterLabels.budget}
                    type={FilterNames.budget}
                />
            )}
        </div>
    );
};

export default FiltersSidebar;
