import React from "react";

import LinkIcon from "components/common/icons/LinkIcon";

import styles from "./Link.module.scss";

interface LinkProps {
    url: string;
    hasIcon: boolean;
    label: string;
}

const Link = ({ url, hasIcon, label }: LinkProps) => {
    return (
        <div className={styles.displayLink}>
            {hasIcon && <LinkIcon size={12} />}
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
            >
                {label}
            </a>
        </div>
    );
};

export default Link;
