import React, { useState } from "react";

import styles from "./Tooltip.module.scss";

interface TooltipProps extends React.HTMLAttributes<HTMLLIElement> {
    children: React.ReactNode;
    text: string;
}

const Tooltip = (props: TooltipProps) => {
    const [hover, setHover] = useState(false);

    return (
        <div className={styles.tooltip}>
            <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={styles.icon}
            >
                {props.children}
            </div>
            {hover && <div className={styles.text}>{props.text}</div>}
        </div>
    );
};

export default Tooltip;
