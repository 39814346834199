import React, { useState } from "react";

import ConfigurePrice from "components/business/catalog/configuration/configure/ConfigurePrice";
import DurationInput from "components/business/catalog/configuration/DurationInput";
import ShippingDelayInfo from "components/business/catalog/shipping-delay-info/shipping-delay-info";
import AddProduct from "components/business/catalog/utils/add-product";
import { AccessoryType } from "components/business/catalog/utils/types";
import AddProductAction from "components/provider/context/cart/actions/add-product-action";
import { useCartDispatch } from "components/provider/context/cart/cart-provider";
import getSpecsFromAccessory from "utils/catalog/get-specs-from-accessory";

import "./AccessoryItemCatalogDetails.scss";

interface AccessoryItemCatalogDetailsProps {
    product: AccessoryType;
    instanceId: string;
    onClose: () => void;
}

const AccessoryItemCatalogDetails = (
    props: AccessoryItemCatalogDetailsProps,
) => {
    const { product, instanceId } = props;
    const cartDispatch = useCartDispatch();
    const [quantity, setQuantity] = useState<number>(1);
    const [currentLeasingDuration, setCurrentLeasingDuration] =
        useState<number>(36);

    const pricingInfo = new AddProduct(
        product,
        instanceId,
        quantity,
        currentLeasingDuration,
    ).buildPricingInfo();

    const handleIncrement = (increment: boolean) => {
        if (increment) {
            setQuantity(quantity + 1);
        } else if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };
    const handleAddToCart = () => {
        const addAccessory = new AddProduct(
            product,
            props.instanceId,
            quantity,
            currentLeasingDuration,
        );
        addAccessory.setProductName(product.name);
        cartDispatch(new AddProductAction([addAccessory]));
        props.onClose();
    };

    const specs = getSpecsFromAccessory(product);

    return (
        <div className="catalog-product-right">
            <div className="catalog-product-right-top">
                <div className="catalog-product-accessory-title">
                    <h4 className="product-title">{product.name}</h4>
                </div>
                <div
                    className="catalog-product-shipping-delay"
                    data-cy="shipping-delay"
                >
                    <ShippingDelayInfo shippingDelay={product.shippingDelay} />
                </div>
            </div>
            <div className="scroll">
                <div className="catalog-accessory-specs">
                    {Array.from(specs.keys()).map((key) => (
                        <div
                            className="catalog-accessory-spec"
                            key={key}
                        >
                            <span>{key}</span>
                            <span>{specs.get(key)}</span>
                        </div>
                    ))}
                </div>
                <DurationInput
                    product={product}
                    currentLeasingDuration={currentLeasingDuration}
                    setCurrentLeasingDuration={setCurrentLeasingDuration}
                />
            </div>
            <ConfigurePrice
                quantity={quantity}
                totalPrice={pricingInfo.totalPrice}
                negotiated={pricingInfo.negotiated}
                normalPrice={pricingInfo.normalPrice}
                handleIncrement={handleIncrement}
                handleAddToCart={handleAddToCart}
            />
        </div>
    );
};

export default AccessoryItemCatalogDetails;
