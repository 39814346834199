import React from "react";

function EyeIcon() {
    return (
        <svg
            width="20"
            height="13"
            viewBox="0 0 20 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 1.73333C11.6716 1.72804 13.3108 2.17255 14.7292 3.01576C16.1476 3.85898 17.2876 5.06667 18.0182 6.5C16.5182 9.42067 13.4545 11.2667 10 11.2667C6.54545 11.2667 3.48182 9.42067 1.98182 6.5C2.71238 5.06667 3.85239 3.85898 5.2708 3.01576C6.68921 2.17255 8.32842 1.72804 10 1.73333ZM10 0C5.45455 0 1.57273 2.69533 0 6.5C1.57273 10.3047 5.45455 13 10 13C14.5455 13 18.4273 10.3047 20 6.5C18.4273 2.69533 14.5455 0 10 0ZM10 4.33333C10.6028 4.33333 11.1808 4.56161 11.6071 4.96794C12.0333 5.37426 12.2727 5.92536 12.2727 6.5C12.2727 7.07464 12.0333 7.62574 11.6071 8.03206C11.1808 8.43839 10.6028 8.66667 10 8.66667C9.39724 8.66667 8.81916 8.43839 8.39294 8.03206C7.96672 7.62574 7.72727 7.07464 7.72727 6.5C7.72727 5.92536 7.96672 5.37426 8.39294 4.96794C8.81916 4.56161 9.39724 4.33333 10 4.33333ZM10 2.6C7.74545 2.6 5.90909 4.35067 5.90909 6.5C5.90909 8.64933 7.74545 10.4 10 10.4C12.2545 10.4 14.0909 8.64933 14.0909 6.5C14.0909 4.35067 12.2545 2.6 10 2.6Z"
                fill="#777772"
            />
        </svg>
    );
}

export default EyeIcon;
