/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from "react";

import moment from "moment";
import { Col, NavLink } from "reactstrap";

import CustomCheckbox from "components/common/input/custom-checkbox/custom-checkbox";
import Initials from "components/common/layout/initials/initials";
import { UserFragment, UserRole } from "graphql/management/model/apollo";

import UserItemDevices from "./UserItemDevices";

interface UserItemInterface {
    user: UserFragment;
    cancelEdit: () => void;
    selectedUser: UserFragment | null | undefined;
    setSelectedUser: React.Dispatch<
        React.SetStateAction<UserFragment | null | undefined>
    >;
    selectedUsers: UserFragment[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<UserFragment[]>>;
    setIsCreateUser: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserItem = (props: UserItemInterface) => {
    const ContactDetails = (user: any) => {
        props.cancelEdit();
        props.setSelectedUser(user);
    };

    function displayUserDate(date: string) {
        return moment.utc(date).format("DD/MM/YYYY");
    }

    return (
        <NavLink
            className={
                props.user.email === props.selectedUser?.email ? "active" : ""
            }
        >
            <div
                className="nlt-dev-4-md"
                onClick={() =>
                    props.selectedUsers.length === 0 &&
                    ContactDetails(props.user)
                }
            >
                <div
                    className={
                        props.user.email === props.selectedUser?.email
                            ? "nlt-list-item-selector-active"
                            : "nlt-list-item-selector"
                    }
                />
                <Col
                    xl={1}
                    md={1}
                    className="nlt-device-icon-fix mw-100"
                >
                    <Initials
                        customStyle={
                            props.selectedUsers.includes(props.user)
                                ? { display: "none" }
                                : {
                                      display: "flex",
                                      margin: "auto",
                                  }
                        }
                        sizeImage={60}
                        firstName={props.user.firstName}
                        lastName={props.user.lastName}
                    />
                    <CustomCheckbox
                        className="nlt-wide-device-icon"
                        style={
                            props.selectedUsers.includes(props.user)
                                ? {
                                      display: "flex",
                                      width: "54px",
                                  }
                                : {
                                      display: "none",
                                      width: "54px",
                                  }
                        }
                        checked={props.selectedUsers.includes(props.user)}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                            props.setSelectedUser(null);
                            props.setIsCreateUser(false);
                            if (props.selectedUsers.includes(props.user)) {
                                const newList = props.selectedUsers.filter(
                                    (item) => item !== props.user,
                                );
                                props.setSelectedUsers(newList);
                            } else {
                                props.setSelectedUsers([
                                    ...props.selectedUsers,
                                    props.user,
                                ]);
                            }
                        }}
                    />
                </Col>
                <Col
                    xl={4}
                    md={4}
                >
                    <div className="media-body">
                        <h6>
                            <span className="first_name_0">
                                {`${props.user.firstName} ${props.user.lastName}`}
                            </span>
                        </h6>
                        <div>
                            <span className="nlt-wide-device-info">
                                {props.user.email}
                            </span>
                        </div>
                        <div>
                            <span className="nlt-wide-device-info">
                                {`Ajouté le ${displayUserDate(
                                    props.user.createdAt!,
                                )}`}
                            </span>
                        </div>
                    </div>
                </Col>
                <Col
                    xl={2}
                    md={2}
                >
                    <div>
                        <div className="nlt-device-item-title-info-list">
                            Rôle
                        </div>
                        <span className="nlt-device-item-data-info">
                            {props.user.role === UserRole.OWNER
                                ? "Administrateur"
                                : "Utilisateur"}
                        </span>
                    </div>
                </Col>
                <Col
                    xl={3}
                    md={3}
                >
                    <div>
                        <div className="nlt-device-item-title-info-list">
                            Appareils
                        </div>
                    </div>
                    {props.user.devices?.length &&
                    props.user.devices?.length > 0 ? (
                        <UserItemDevices devices={props.user.devices} />
                    ) : (
                        <div className="nlt-device-item-data-info">
                            Aucun appareil
                        </div>
                    )}
                </Col>
                <div className="nlt-wide-card-tag-ls-sm">
                    {props.user.organisations?.map((organisation) => (
                        <div
                            key={organisation.id!}
                            style={{
                                backgroundColor: organisation.hexaColor!,
                                color: "white",
                            }}
                            className="nlt-wide-card-tag-item-sm"
                        >
                            {organisation.name}
                        </div>
                    ))}
                </div>
            </div>
        </NavLink>
    );
};

export default UserItem;
