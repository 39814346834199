import React from "react";

export default function CartIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 19"
            width="20"
            height="19"
        >
            <path
                d="m16.52,18.37c.93,0,1.68-.75,1.68-1.68s-.75-1.68-1.68-1.68-1.68.75-1.68,1.68.75,1.68,1.68,1.68Z"
                fill="#FF625A"
            />
            <path
                d="m7.25,18.37c.93,0,1.68-.75,1.68-1.68s-.75-1.68-1.68-1.68-1.68.75-1.68,1.68.75,1.68,1.68,1.68Z"
                fill="#FF625A"
            />
            <path
                d="m.83,0c-.46,0-.83.37-.83.83s.37.83.83.83h2.65l.69,3.46s0,.05.01.07l1.39,6.95h0c.11.58.43,1.09.88,1.46.45.37,1.02.56,1.61.55h8.09c.58,0,1.15-.19,1.61-.55.46-.37.77-.88.88-1.46h0s1.33-7,1.33-7c.05-.24-.02-.5-.18-.69-.16-.19-.39-.3-.64-.3H5.68l-.7-3.5c-.08-.39-.42-.67-.82-.67H.83Z"
                fill="#FF625A"
            />
        </svg>
    );
}
