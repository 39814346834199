export default function ShippingAddressIcon() {
    return (
        <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.7327 6.73271C12.1291 6.33633 12.1291 5.69367 11.7327 5.29729C11.3363 4.9009 10.6937 4.9009 10.2973 5.29729L7.015 8.57957L5.73271 7.29729C5.33633 6.9009 4.69367 6.9009 4.29729 7.29729C3.9009 7.69367 3.9009 8.33633 4.29729 8.73271L5.80231 10.2377C6.47206 10.9075 7.55794 10.9075 8.22769 10.2377L11.7327 6.73271Z"
                fill="#353535"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.015 0C4.8889 0 2.83453 1.31031 1.59442 3.01377C0.391528 4.66609 0 6.61952 0 7.93808C0 10.5234 1.48375 14.8912 6.74888 19.2935C7.48146 19.906 8.54854 19.906 9.28112 19.2935C14.5462 14.8912 16.03 10.5234 16.03 7.93808C16.03 6.61952 15.6385 4.66609 14.4356 3.01377C13.1955 1.31031 11.1411 0 8.015 0ZM2.03 7.93808C2.03 6.94894 2.33847 5.44083 3.23558 4.20854C4.09547 3.02738 5.54111 2.03 8.015 2.03C10.4889 2.03 11.9345 3.02738 12.7944 4.20854C13.6915 5.44083 14 6.94894 14 7.93808C14 9.78979 12.8961 13.6106 8.015 17.706C3.13387 13.6106 2.03 9.78979 2.03 7.93808Z"
                fill="#353535"
            />
        </svg>
    );
}
