import React from "react";

import getIconColor from "./utils";

interface AccessoryIconProps {
    isActive?: boolean;
}

export default function AccessoryIcon(props: AccessoryIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 34 23"
            width="34"
            height="23"
        >
            <defs>
                <clipPath id="clippath">
                    <rect
                        width="32.67"
                        height="23.33"
                    />
                </clipPath>
                <clipPath id="clippath-1">
                    <rect
                        x="18.67"
                        y="5.83"
                        width="15.17"
                        height="17.5"
                    />
                </clipPath>
            </defs>
            <rect
                y="4.67"
                width="23.33"
                height="14"
                rx="2.33"
                ry="2.33"
                fill="#eae7e1"
            />
            <path
                d="m0,7c0-1.28,1.05-2.33,2.33-2.33h18.67c.62,0,1.21.25,1.65.68.44.44.68,1.03.68,1.65v9.33c0,.62-.25,1.21-.68,1.65-.44.44-1.03.68-1.65.68H2.33c-.62,0-1.21-.25-1.65-.68-.44-.44-.68-1.03-.68-1.65V7Zm2.33,0v2.33h2.33v-2.33h-2.33Zm1.17,3.5v2.33h2.33v-2.33h-2.33Zm-1.17,3.5v2.33h2.33v-2.33h-2.33Zm3.5,0v2.33h11.67v-2.33H5.83Zm12.83,0v2.33h2.33v-2.33h-2.33Zm-11.67-3.5v2.33h2.33v-2.33h-2.33Zm3.5,0v2.33h2.33v-2.33h-2.33Zm3.5,0v2.33h2.33v-2.33h-2.33Zm3.5,0v2.33h2.33v-2.33h-2.33Zm-11.67-3.5v2.33h2.33v-2.33h-2.33Zm3.5,0v2.33h2.33v-2.33h-2.33Zm3.5,0v2.33h2.33v-2.33h-2.33Zm3.5,0v2.33h4.67v-2.33h-4.67Z"
                fill={getIconColor(props.isActive ?? false)}
            />
            <path
                d="m26.88,8.14v4.26h3.16c-.01-1.02-.33-2.01-.91-2.79-.58-.78-1.37-1.3-2.25-1.47Zm-1.26,0c-1.78.34-3.13,2.11-3.16,4.26h3.16v-4.26Zm0,5.72h-3.16v2.92c0,2.41,1.7,4.38,3.79,4.38s3.79-1.96,3.79-4.38v-2.92h-4.42Z"
                fill="#eae7e1"
            />
            <path
                d="m31.31,12.4c-.03-3.2-2.27-5.78-5.06-5.78s-5.03,2.58-5.06,5.78v4.38c0,3.22,2.26,5.83,5.06,5.83s5.06-2.61,5.06-5.83v-4.38Zm-4.42-4.26c1.78.34,3.13,2.11,3.16,4.26h-3.16v-4.26Zm-1.26,0v4.26h-3.16c.01-1.02.33-2.01.91-2.79.58-.78,1.37-1.3,2.25-1.47Zm4.42,8.63c0,2.41-1.7,4.38-3.79,4.38s-3.79-1.96-3.79-4.38v-2.92h7.58v2.92Z"
                fill={getIconColor(props.isActive ?? false)}
            />
        </svg>
    );
}

AccessoryIcon.defaultProps = {
    isActive: false,
};
