import React, { useEffect, useState } from "react";

import { Collapse } from "reactstrap";

import {
    IncidentReason,
    useFindDeviceIncidentQuery,
} from "graphql/management/model/apollo";

import SkeletonIncidentList from "./skeleton-incident-list/skeleton-incident-list";

export const IncidentLables = new Map([
    [IncidentReason.BROKEN, "Casse"],
    [IncidentReason.BREAKDOWN, "Panne"],
    [IncidentReason.STOLEN, "Vol"],
]);

const IncidentList = () => {
    const incidentsQuery = useFindDeviceIncidentQuery();
    const [showIncident, setShowIncident] = useState<string | null>(null);

    useEffect(() => {
        incidentsQuery.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function toggleIncident(incidentId: string) {
        if (incidentId === showIncident) {
            setShowIncident(null);
        } else {
            setShowIncident(incidentId);
        }
    }

    function shouldToggle(incidentId: string): boolean {
        return incidentId === showIncident;
    }

    const IncidentsAccordion = incidentsQuery.data?.findDeviceIncident?.map(
        (incident) => (
            <div key={incident.id!}>
                <span className="nlt-card-title-buttons">
                    <i
                        aria-hidden="true"
                        className="fa fa-chevron-right nlt-card-toggle-button"
                        onClick={() => toggleIncident(incident.id!)}
                        style={
                            shouldToggle(incident.id!)
                                ? { transform: "rotate(90deg)" }
                                : { transform: "rotate(0deg)" }
                        }
                    />
                </span>
                <div className="nlt-incident-item">
                    <div className="nlt-list-item-device-name pull-left">
                        {incident.deviceName} ({incident.serialNumber})
                    </div>
                    <div className="nlt-list-item-device-state pull-left">
                        {IncidentLables.get(incident.reason!)}
                    </div>
                </div>

                <Collapse
                    isOpen={shouldToggle(incident.id!)}
                    className="nlt-incident-text"
                >
                    {incident.details || "Aucun détail de l'incident"}
                </Collapse>

                <div className="nlt-incident-sep" />
            </div>
        ),
    );

    const NoIncident = (
        <div className="nlt-document-placeholder">
            <div className="nlt-document-placeholder-icon">
                <i className="fa fa-warning" />
            </div>
            <div className="nlt-document-info">
                <div className="nlt-document-title">Aucun Incident</div>
                <div className="nlt-document-date">
                    Retrouvez vos incidents ici
                </div>
            </div>
        </div>
    );

    const displayIncidents = () => {
        if (incidentsQuery.data?.findDeviceIncident.length) {
            return IncidentsAccordion;
        }
        return NoIncident;
    };

    return (
        <>
            <div className="nlt-card-header">
                <div className="nlt-card-title">Mes incidents en cours</div>
                <div className="card-header-right-icon" />
            </div>
            <div>
                <div className="nlt-list-item-incident-container">
                    {incidentsQuery.loading ? (
                        <SkeletonIncidentList />
                    ) : (
                        displayIncidents()
                    )}
                </div>
            </div>
        </>
    );
};

export default IncidentList;
