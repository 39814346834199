import { TempCart } from "components/provider/context/temp-cart/types";

import TempCartAction from "./temp-cart-action";

class UpdateQuantityTempCartAction implements TempCartAction {
    payload: {
        increment: boolean;
    };

    constructor(increment: boolean) {
        this.payload = {
            increment,
        };
    }

    apply(tempCart: TempCart) {
        return {
            ...tempCart,
            quantity: tempCart.quantity + (this.payload.increment ? 1 : -1),
        };
    }
}

export default UpdateQuantityTempCartAction;
