import React from "react";

import CheckIcon from "components/common/icons/CheckIcon";

import styles from "./Card.module.scss";

export interface CardProps {
    img: React.ReactNode;
    title: string;
    subtitle: React.ReactElement;
    description: React.ReactElement;
    active?: boolean;
    cardFunction: () => void;
}

const Card = ({
    img,
    title,
    description,
    subtitle,
    active,
    cardFunction,
}: CardProps) => {
    const dataCy = `card-${title.replaceAll(" ", "-")}`;

    return (
        <button
            key={title}
            type="button"
            className={styles.card}
            data-cy={dataCy}
            onClick={cardFunction}
        >
            <div className={styles.image}>{img}</div>
            <div className={styles.infos}>
                <h4
                    className={styles.title}
                    data-cy={`card-${title}`}
                >
                    {title}
                </h4>
                <div className={styles.subtitle}>{subtitle}</div>
                {active && (
                    <div className={styles.active}>
                        <p className={styles.activeText}>Active</p>
                        <div className={styles.activeIcon}>
                            <CheckIcon color={"#28A745"} />
                        </div>
                    </div>
                )}
                {description}
            </div>
        </button>
    );
};

export default Card;
