import React, { useEffect, useState } from "react";

import { Home as HomeButton, MessageCircle, Bell, X } from "react-feather";

import CrossIcon from "components/common/icons/CrossIcon";
import useLocalUnreadConversation from "utils/useLocalUnreadConversation/useLocalUnreadConversation";

import Changelog from "./changelog";
import Home from "./home";
import styles from "./widget.module.scss";
import "./hubspotChat.scss";

type conversationCountPayload = {
    unreadCount: number;
};

const Widget = () => {
    const { localUnreadConversation, updateLocalUnreadConversation } =
        useLocalUnreadConversation();
    const [showModal, setShowModal] = useState(false);
    const [clickedButton, setClickedButton] = useState<string>("homeButton");
    const [lastContent, setLastContent] = useState<string>("home");
    const [isLoading, setLoading] = useState(false);

    const handleToggleModal = () => {
        setShowModal(!showModal);
    };

    const handleButtonClick = (buttonId: string) => {
        if (buttonId === "chatButton") {
            setLoading(true);
            setClickedButton(buttonId);

            window.HubSpotConversations.widget.load();

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } else {
            setClickedButton(buttonId);

            if (buttonId === "changelogButton") {
                setLastContent("changelog");
            } else if (buttonId === "homeButton") {
                setLastContent("home");
            }
        }
    };

    const renderLastComponent = () => {
        if (lastContent === "home") {
            return <Home />;
        } else if (lastContent === "changelog") {
            return <Changelog />;
        }
    };

    const widgetContent = () => {
        if (clickedButton === "homeButton") {
            return <Home />;
        } else if (clickedButton === "changelogButton") {
            return <Changelog />;
        } else if (clickedButton === "chatButton") {
            return renderLastComponent();
        }
    };

    useEffect(() => {
        const handleUnreadConversation = (unreadCount: number) => {
            updateLocalUnreadConversation(unreadCount);
        };
        window.hsConversationsOnReady = [
            () => {
                window.HubSpotConversations.on(
                    "unreadConversationCountChanged",
                    (payload: conversationCountPayload) => {
                        handleUnreadConversation(payload.unreadCount);
                    },
                );
            },
        ];
        return () => {
            if (window.HubSpotConversations) {
                window.HubSpotConversations.off(
                    "unreadConversationCountChanged",
                    handleUnreadConversation,
                );
            }
        };
    }, [updateLocalUnreadConversation]);

    return (
        <div className={styles.container}>
            <button
                onClick={() => {
                    handleToggleModal();
                }}
                className={styles.bubble}
            >
                {showModal ? <X /> : "?"}
            </button>
            <div
                className={`${styles.modal} ${
                    showModal ? styles.showModal : ""
                }`}
            >
                <button
                    onClick={handleToggleModal}
                    className={styles.closeButton}
                >
                    <CrossIcon />
                </button>
                {widgetContent()}
                <footer className={styles.footer}>
                    <button
                        onClick={() => {
                            handleButtonClick("homeButton");
                        }}
                        className={`${styles.buttonFooter} ${
                            clickedButton === "homeButton" &&
                            styles.selectedButton
                        }`}
                    >
                        <HomeButton />
                    </button>
                    <button
                        onClick={() => {
                            handleButtonClick("chatButton");
                        }}
                        className={`${styles.buttonFooter} ${
                            clickedButton === "chatButton" &&
                            styles.selectedButton
                        }`}
                        disabled={isLoading}
                    >
                        <MessageCircle />
                        {localUnreadConversation > 0 && (
                            <span className={styles.notification}>
                                {localUnreadConversation}
                            </span>
                        )}
                    </button>
                    <button
                        onClick={() => {
                            handleButtonClick("changelogButton");
                        }}
                        className={`${styles.buttonFooter} ${
                            clickedButton === "changelogButton" &&
                            styles.selectedButton
                        }`}
                    >
                        <Bell />
                    </button>
                </footer>
            </div>
            <div
                id="hubspot-chat"
                style={{
                    display:
                        clickedButton === "chatButton" && showModal
                            ? "block"
                            : "none",
                }}
            ></div>
            {localUnreadConversation > 0 && !showModal && (
                <span className={styles.notificationBubble}>
                    {localUnreadConversation}
                </span>
            )}
        </div>
    );
};

export default Widget;
