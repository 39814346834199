import React, { useEffect } from "react";

import { TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";

import Product from "components/business/order/product/product";
import ProductUser from "components/business/order/product/product-user";
import FormErrorMessage from "components/common/layout/error/form-error-message/form-error-message";
import SetProductUserAction from "components/provider/context/cart/actions/set-product-user-action";
import { useCartDispatch } from "components/provider/context/cart/cart-provider";
import {
    refetchFindUsersQuery,
    UserRole,
    useCreateUserMutation,
} from "graphql/management/model/apollo";
import displayAlertMessage from "utils/displayAlertMessage/displayAlertMessage";
import EmailRegExp from "utils/email-utils/email-utils";

import "./create-user.scss";

interface CreateUserProps {
    onClose: () => void;
    selectedProduct: Product | null;
}

class UserForm {
    firstName: string = "";

    lastName: string = "";

    email: string = "";

    phoneNumber: string = "";
}

const CreateUserOrder = (props: CreateUserProps) => {
    const cartDispatch = useCartDispatch();

    const {
        formState: { errors },
        handleSubmit,
        control,
    } = useForm<UserForm>({
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
        },
    });

    const [addUser] = useCreateUserMutation();
    const CreateUser = async (form: UserForm) => {
        try {
            await addUser({
                variables: {
                    firstName: form.firstName,
                    lastName: form.lastName,
                    email: form.email,
                    phoneNumber: form.phoneNumber || "",
                    role: UserRole.USER,
                    organisations: null,
                },
                update: async (_, updatedUserResult) => {
                    if (updatedUserResult.data?.createUser) {
                        const createdUser = updatedUserResult.data.createUser;
                        const newUser = new ProductUser(
                            createdUser.firstName!,
                            createdUser.lastName!,
                            createdUser.email!,
                        );

                        if (props.selectedProduct) {
                            cartDispatch(
                                new SetProductUserAction(
                                    props.selectedProduct,
                                    newUser,
                                ),
                            );
                        }

                        await displayAlertMessage(
                            `L'utilisateur ${createdUser.firstName} ${createdUser.lastName} a bien été créé`,
                            "success",
                        );
                    }
                },
                refetchQueries: [refetchFindUsersQuery()],
            });
        } catch (e: any) {
            await displayAlertMessage(
                "Vérifiez l'adresse e-mail renseignée.<br/>Si le problème persiste, contactez nous dans le chat",
                "error",
            );
        }

        props.onClose();
    };

    useEffect(() => {
        const handleOnKeydown = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                props.onClose();
            }
        };
        document.addEventListener("keydown", handleOnKeydown);
        return () => document.removeEventListener("keydown", handleOnKeydown);
    }, [props]);

    return (
        <div className="pop-up-wrapper">
            <div className="modal-large create-user-modal">
                <div
                    aria-hidden="true"
                    className="nlt-button-inside-close absolute-close"
                    onClick={props.onClose}
                >
                    <i className="icofont icofont-close" />
                </div>
                <form
                    onSubmit={handleSubmit(CreateUser)}
                    id="order-createUser"
                    name="order-createUser"
                >
                    <div className="create-user-modal-title">
                        Ajouter un utilisateur
                    </div>
                    <div className="create-user-modal-container">
                        <div className="create-user-modal-row">
                            <div className="create-user-modal-input">
                                <label
                                    className="required"
                                    htmlFor="lastName"
                                >
                                    Nom
                                </label>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    rules={{
                                        required: "Le nom est requis.",
                                    }}
                                    render={({
                                        field,
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            id="lastName"
                                            data-cy="lastName"
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            name={field.name}
                                            inputRef={field.ref}
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Petit"
                                            inputProps={{
                                                "aria-label": field.name,
                                            }}
                                            helperText={
                                                <FormErrorMessage
                                                    data-cy={field.name}
                                                    testId={field.name}
                                                    message={
                                                        error?.message ?? ""
                                                    }
                                                />
                                            }
                                            error={error !== undefined}
                                        />
                                    )}
                                />
                            </div>
                            <div className="create-user-modal-input">
                                <label
                                    className="required"
                                    htmlFor="firstName"
                                >
                                    Prénom
                                </label>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    rules={{
                                        required: "Le prénom est requis.",
                                    }}
                                    render={({
                                        field,
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            id="firstName"
                                            data-cy="firstName"
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            name={field.name}
                                            inputRef={field.ref}
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Laura"
                                            inputProps={{
                                                "aria-label": field.name,
                                            }}
                                            helperText={
                                                <FormErrorMessage
                                                    data-cy={field.name}
                                                    testId={field.name}
                                                    message={
                                                        error?.message ?? ""
                                                    }
                                                />
                                            }
                                            error={error !== undefined}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="create-user-modal-row">
                            <div className="create-user-modal-input">
                                <label
                                    className="required"
                                    htmlFor="email"
                                >
                                    Adresse e-mail
                                </label>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required:
                                            "L'adresse e-mail est requise.",
                                        pattern: {
                                            value: EmailRegExp,
                                            message:
                                                "Le format de l'adresse e-mail est invalide",
                                        },
                                    }}
                                    render={({
                                        field,
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            id="email"
                                            data-cy="email"
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            name={field.name}
                                            inputRef={field.ref}
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                            placeholder="laura.petit@cleaq.com"
                                            inputProps={{
                                                "aria-label": field.name,
                                            }}
                                            helperText={
                                                <FormErrorMessage
                                                    data-cy={field.name}
                                                    testId={field.name}
                                                    message={
                                                        error?.message ?? ""
                                                    }
                                                />
                                            }
                                            error={error !== undefined}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="create-user-modal-row">
                            <div className="create-user-modal-input">
                                <label htmlFor="phoneNumber">Téléphone</label>
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                        <>
                                            <PhoneInput
                                                {...field}
                                                id="phoneNumber"
                                                data-cy="phone"
                                                inputRef={ref}
                                                placeholder="0601020304"
                                                defaultCountry="FR"
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="cleaq-button"
                        id="submitUser"
                        disabled={
                            errors !== undefined &&
                            Object.keys(errors).length > 0
                        }
                    >
                        Ajouter
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateUserOrder;
