import React, { useState } from "react";

import { CircularProgress } from "@material-ui/core";

import Product from "components/business/order/product/product";
import ProductAddress from "components/business/order/product/product-address";
import CustomCheckbox from "components/common/input/custom-checkbox/custom-checkbox";
import { useCart } from "components/provider/context/cart/cart-provider";
import { formatPrice } from "utils/translator/translator";

import SummaryItem from "./summary-item/summary-item";

import "./summary.scss";

interface CreateOrderProps {
    onCreateOrder: () => void;
    loading?: boolean;
}

const OrderSummary = (props: CreateOrderProps) => {
    const [agree, setAgree] = useState<boolean>(false);
    const [warn, setWarn] = useState<boolean>(false);
    const cart = useCart();

    const submit = () => {
        if (agree) {
            props.onCreateOrder();
        } else {
            setWarn(true);
        }
    };

    const cartSum = cart.reduce((acc, product) => acc + product.pricing, 0);

    const normalPrice = () =>
        cart.length > 0
            ? cart.map((product) => product.normalPrice).reduce((a, b) => a + b)
            : 0;

    const savings = () => normalPrice() - cartSum;

    const addressGroup = () => {
        const addressGroup = [] as Product[][];
        const addresses = new Set(cart.map((product) => product.address!!.id));
        addresses.forEach((address) => {
            addressGroup.push(
                cart.filter((product) => product.address!!.id === address),
            );
        });
        return addressGroup;
    };

    const AddressInfo = ({ label, name, zipcode, cityName }: ProductAddress) =>
        `${label} - ${name.toUpperCase()} - ${zipcode.toUpperCase()} ${cityName.toUpperCase()}`;

    return (
        <div
            className="products-container"
            style={{ paddingBottom: "400px" }}
        >
            <h3>Rappel de votre commande</h3>
            <div className="summary">
                <ul>
                    {addressGroup().map((addressGroup) => (
                        <React.Fragment key={addressGroup[0].address?.id}>
                            {addressGroup[0].address && (
                                <div className="summary-address">
                                    <div>
                                        {AddressInfo(addressGroup[0].address)}
                                    </div>
                                </div>
                            )}

                            {addressGroup.map((product) => (
                                <SummaryItem
                                    product={product}
                                    key={product.innerId}
                                />
                            ))}
                        </React.Fragment>
                    ))}
                </ul>
                <div className="agree">
                    <CustomCheckbox
                        id="agree"
                        name="agree"
                        onClick={() => setAgree(!agree)}
                        size="small"
                        checked={agree}
                        data-cy="agree-checkbox"
                    />
                    <label
                        htmlFor="agree"
                        className={`m-b-0 ${warn && "warn"} checkbox-text`}
                    >
                        Je confirme ma commande et je souhaite déclencher sa
                        préparation. Je suis informé(e) que ma commande ne sera
                        totalement validée qu'à signature des documents reçus
                        par voie électronique.
                    </label>
                </div>
                <div className="total">
                    <div>
                        <span className="title">Total de la commande</span>
                    </div>

                    <div>
                        <span className="quantity m-r-5">{cart.length}</span>
                        produits
                    </div>
                    <div>
                        <span className="quantity m-r-5">
                            {formatPrice(cartSum)}
                        </span>
                        HT / mois
                    </div>
                    {savings() > 0 && (
                        <>
                            <div className="negotiated-price recap">
                                Au lieu de{" "}
                                <strong>{formatPrice(normalPrice())}</strong> HT
                                / mois
                            </div>
                        </>
                    )}
                    {props.loading ? (
                        <>
                            <div
                                aria-hidden="true"
                                className="cleaq-button disabled"
                            >
                                <CircularProgress
                                    size={24}
                                    thickness={2}
                                />{" "}
                                Validation en cours
                            </div>
                        </>
                    ) : (
                        <div
                            aria-hidden="true"
                            data-cy="confirm-order"
                            className={`cleaq-button ${
                                !agree ? "disabled" : ""
                            }`}
                            onClick={submit}
                        >
                            Valider la commande
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

OrderSummary.defaultProps = { loading: false };

export default OrderSummary;
