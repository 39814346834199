import React from "react";

import Product from "components/business/order/product/product";
import ProductAddress from "components/business/order/product/product-address";
import AddressItemOrder from "components/business/order/step-form/AssignAddress/AddressItemOrder/AddressItemOrder";
import ProductItem from "components/business/order/step-form/AssignAddress/product-item/product-item";
import InstructionEditIcon from "components/common/icons/InstructionEditIcon";
import ShippingAddressIcon from "components/common/icons/ShippingAddressIcon";
import ShippingDateIcon from "components/common/icons/ShippingDateIcon";
import SetProductAddressAction from "components/provider/context/cart/actions/set-product-address-action";
import {
    useCart,
    useCartDispatch,
} from "components/provider/context/cart/cart-provider";
import { useFindInstanceQuery } from "graphql/management/model/apollo";

import "./assign-address-form.scss";

interface AssignAddressFormProps {
    onToggleAddressForm: () => void;
    setSelectedProduct: React.Dispatch<React.SetStateAction<Product | null>>;
    setCurrentAddress: React.Dispatch<
        React.SetStateAction<ProductAddress | null>
    >;
}

const AssignAddressForm = (props: AssignAddressFormProps) => {
    const currentInstance = useFindInstanceQuery();
    const cart = useCart();
    const cartDispatch = useCartDispatch();

    function selectAll(address: ProductAddress | undefined) {
        cart.forEach((product) => {
            const updatedProduct = product;
            const updatedAddress = address === undefined ? null : address;
            cartDispatch(
                new SetProductAddressAction(updatedProduct, updatedAddress),
            );
        });
    }

    const mainAddress = new ProductAddress(
        currentInstance.data?.currentInstance?.shippingAddress?.id ||
            "000000000000000000000000",
        currentInstance.data?.currentInstance?.shippingAddress?.addressName ||
            "",
        currentInstance.data?.currentInstance?.shippingAddress?.recipient || "",
        "Adresse de facturation",
        currentInstance.data?.currentInstance?.shippingAddress?.zipCode || "",
        currentInstance.data?.currentInstance?.shippingAddress?.cityName || "",
        currentInstance.data?.currentInstance?.shippingAddress?.country || "",
        currentInstance.data?.currentInstance?.shippingAddress?.instructions ||
            "",
        currentInstance.data?.currentInstance?.shippingAddress?.phoneNumber ||
            "",
    );

    const Addresses = (): ProductAddress[] => {
        const addresses: ProductAddress[] = [];

        if (mainAddress.name !== "") {
            addresses.push(mainAddress);
        }
        // eslint-disable-next-line no-unused-expressions
        currentInstance.data?.currentInstance?.secondaryAddresses?.forEach(
            (address) =>
                addresses.push(
                    new ProductAddress(
                        address.id,
                        address.addressName,
                        address.recipient,
                        address.label || "",
                        address.zipCode,
                        address.cityName,
                        address.country,
                        address.instructions || "",
                        address.phoneNumber || "",
                    ),
                ),
        );

        return addresses;
    };

    const isAddressUsedByAll = (cart: Product[], addressId: string): boolean =>
        cart.filter((p) => p.address?.id === addressId).length === cart.length;

    return (
        <div>
            <div className="address-container-wrapper">
                <div className="address-container">
                    <button
                        type="button"
                        className="address add"
                        onClick={() => {
                            props.setCurrentAddress(null);
                            props.onToggleAddressForm();
                        }}
                        id="createAddress"
                    >
                        <div>
                            <i className="icofont icofont-plus-circle" />
                            <div>Nouvelle Adresse</div>
                        </div>
                    </button>
                    {Addresses()?.map((address) => (
                        <AddressItemOrder
                            handleEdit={() => {
                                props.setCurrentAddress(address);
                                props.onToggleAddressForm();
                            }}
                            address={address}
                            key={address.id}
                            onSelect={(address: ProductAddress | undefined) => {
                                selectAll(address);
                            }}
                            selected={isAddressUsedByAll(cart, address.id)}
                        />
                    ))}
                </div>
            </div>
            <div className="product-items-wrapper">
                <div className="product-items__header">
                    <div className="header__products">
                        <h4>Vos produits</h4>
                    </div>
                    <div className="header__address">
                        <h4>
                            <ShippingAddressIcon />
                            Adresse de livraison
                        </h4>
                    </div>
                    <div className="header__date">
                        <h4>
                            <ShippingDateIcon />
                            Date de livraison
                        </h4>
                        <h4>
                            <InstructionEditIcon />
                            Demande spécifique
                        </h4>
                    </div>
                </div>
                <div className="product-items__body">
                    {cart.map((product) => (
                        <ProductItem
                            key={product.innerId}
                            product={product}
                            setSelectedProduct={props.setSelectedProduct}
                            addresses={Addresses()}
                            onCreateAddress={props.onToggleAddressForm}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AssignAddressForm;
