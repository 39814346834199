/* eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }] */
import React from "react";

import { useHistory } from "react-router";

import {
    AccessorySpecsType,
    ProductSpecsType,
} from "components/business/order/order-history-list/utils/type";
import {
    OrderStatus,
    ProductAddressFragment,
    ProductUserFragment,
    ProductAudioSpecFragment,
    ProductDockstationSpecFragment,
    ProductKeyboardSpecFragment,
    ProductMouseSpecFragment,
    ProductScreenSpecFragment,
    UniqueAddressFragment,
} from "graphql/order/model/apollo";
import translator, {
    formatMemory,
    formatPrice,
} from "utils/translator/translator";
import translatorConnectivity from "utils/translator/translator-connectivity";

import STATUSES from "./statuses";

import "./product-by-address.scss";

interface ProductByAddressProps {
    uniqueAddresses: UniqueAddressFragment[];
    index: number;
    orderStatus: OrderStatus;
}

const ProductByAddress = (props: ProductByAddressProps) => {
    const history = useHistory();
    const Redirect = (redirect: any, search: any) => {
        history.push({
            pathname: redirect,
            state: {
                mailSearch: search,
                serialSearch: search,
            },
        });
    };

    function getDeliveryStatus(status: string | null): string {
        if (status && STATUSES[status] !== undefined) {
            return STATUSES[status].label;
        }
        return "";
    }

    function getStyleFromDeliveryStatus(status: string | null) {
        if (status && STATUSES[status] !== undefined) {
            return STATUSES[status].cssClass;
        }
        return "";
    }

    const deviceType: { [key: string]: string } = {
        LAPTOP: "icofont icofont-laptop-alt",
        TABLET: "icofont icofont-surface-tablet",
        SMARTPHONE: "icofont icofont-smart-phone",
        DESKTOP: "icofont icofont-computer",
        OTHER: "icofont icofont-responsive",
    };

    const ProductAddressInfo = ({
        label,
        name,
        zipcode,
        cityName,
    }: ProductAddressFragment) => (
        <p>{`${label} - ${name.toUpperCase()} - ${zipcode} ${cityName.toUpperCase()}`}</p>
    );

    const ProductUserName = ({ firstName, lastName }: ProductUserFragment) => (
        <p>{`${firstName} ${lastName}`}</p>
    );

    const ProductSpecs = ({
        memorySize,
        processor,
        storageSize,
    }: ProductSpecsType) => (
        <div className="product-specs">
            {processor && (
                <div>
                    <span
                        className="specs-infos"
                        data-cy="specs-infos"
                    >
                        {`${translator.translate(processor)}`}
                    </span>
                </div>
            )}
            {memorySize && (
                <div>
                    <span
                        className="specs-infos"
                        data-cy="specs-infos"
                    >
                        {formatMemory(parseFloat(memorySize))} RAM
                    </span>
                </div>
            )}
            {storageSize && (
                <div>
                    <span
                        className="specs-infos"
                        data-cy="specs-infos"
                    >
                        {formatMemory(parseFloat(storageSize))} SSD
                    </span>
                </div>
            )}
        </div>
    );

    interface AccessorySpecsProps {
        accessorySpecs: AccessorySpecsType;
    }
    const AccessorySpecs = (props: AccessorySpecsProps) => {
        const { accessorySpecs } = props;

        if (accessorySpecs.__typename === "ProductAudioSpecsOutput") {
            const specs = accessorySpecs as ProductAudioSpecFragment;
            return (
                <div className="product-specs">
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {specs.connectivity
                                .map((connectivity) =>
                                    translatorConnectivity.translate(
                                        connectivity,
                                    ),
                                )
                                .join(", ")}
                        </span>
                    </div>
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {`${
                                specs.noiseCancellation ? "Avec" : "Sans"
                            } réduction de bruit`}
                        </span>
                    </div>
                </div>
            );
        }

        if (accessorySpecs.__typename === "ProductKeyboardSpecsOutput") {
            const specs = accessorySpecs as ProductKeyboardSpecFragment;
            return (
                <div className="product-specs">
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {translator.translate(specs.compatibility)}
                        </span>
                    </div>
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {`${specs.numPad ? "Avec" : "Sans"} pavé numérique`}
                        </span>
                    </div>
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {specs.connectivity
                                .map((connectivity) =>
                                    translatorConnectivity.translate(
                                        connectivity,
                                    ),
                                )
                                .join(", ")}
                        </span>
                    </div>
                </div>
            );
        }

        if (accessorySpecs.__typename === "ProductMouseSpecsOutput") {
            const specs = accessorySpecs as ProductMouseSpecFragment;
            return (
                <div className="product-specs">
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {translator.translate(specs.compatibility)}
                        </span>
                    </div>
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {specs.connectivity
                                .map((connectivity) =>
                                    translatorConnectivity.translate(
                                        connectivity,
                                    ),
                                )
                                .join(", ")}
                        </span>
                    </div>
                </div>
            );
        }

        if (accessorySpecs.__typename === "ProductScreenSpecsOutput") {
            const specs = accessorySpecs as ProductScreenSpecFragment;
            // eslint-disable-next-line quotes
            const size = specs.size.toString().concat('"');
            return (
                <div className="product-specs">
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {size}
                        </span>
                    </div>
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {specs.resolution}
                        </span>
                    </div>
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {specs.connectivity
                                .map((connectivity) =>
                                    translatorConnectivity.translate(
                                        connectivity,
                                    ),
                                )
                                .join(", ")}
                        </span>
                    </div>
                </div>
            );
        }

        if (accessorySpecs.__typename === "ProductDockstationSpecsOutput") {
            const specs = accessorySpecs as ProductDockstationSpecFragment;
            return (
                <div className="product-specs">
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {translatorConnectivity.translate(specs.connection)}
                        </span>
                    </div>
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {translator.translate(specs.compatibility)}
                        </span>
                    </div>
                    <div>
                        <span
                            className="specs-infos"
                            data-cy="specs-infos"
                        >
                            {specs.connectivity
                                .map((connectivity) =>
                                    translatorConnectivity.translate(
                                        connectivity,
                                    ),
                                )
                                .join(", ")}
                        </span>
                    </div>
                </div>
            );
        }

        return <></>;
    };

    const isProductStatusVisible =
        props.orderStatus === OrderStatus.IN_PROGRESS ||
        props.orderStatus === OrderStatus.PENDING_DELIVERY ||
        props.orderStatus === OrderStatus.PENDING_BILLING;

    return (
        <>
            {props.uniqueAddresses.map(
                (uniqueAddress: UniqueAddressFragment) => (
                    <div key={`${uniqueAddress.address.id}-${props.index + 1}`}>
                        <div className="address-and-status">
                            <ProductAddressInfo {...uniqueAddress.address} />
                            {uniqueAddress.address.recipient && (
                                <p data-cy="recipient">
                                    - {uniqueAddress.address.recipient}
                                </p>
                            )}
                        </div>
                        <div className="address-and-products">
                            <div className="order-list-product">
                                {uniqueAddress.products.map((product, i) => (
                                    <div
                                        key={product.id}
                                        className="order-list-product-info"
                                    >
                                        <div className="product-infos">
                                            {product.commonSpecs.imageUrl ? (
                                                <div className="product-img">
                                                    <img
                                                        src={
                                                            product.commonSpecs
                                                                .imageUrl
                                                        }
                                                        alt={product.reference}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="nlt-document-placeholder-icon">
                                                    <i
                                                        className={
                                                            deviceType[
                                                                product
                                                                    .commonSpecs
                                                                    .deviceType
                                                            ]
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className="name-serial"
                                                data-cy="name-serial"
                                            >
                                                <div className="order-card-name-user">
                                                    <p className="order-list-product-name">
                                                        {product.reference}
                                                    </p>
                                                </div>
                                                <p>{`Renouvellement sur ${product.leasingData.leasingMonthsDuration} mois`}</p>
                                                {!product.serialNumber ? (
                                                    <p>
                                                        Numéro de série non
                                                        renseigné
                                                    </p>
                                                ) : (
                                                    <p>{`Numéro de série : ${product.serialNumber}`}</p>
                                                )}
                                                {props.orderStatus ===
                                                OrderStatus.BILLED ? (
                                                    <div className="m-t-5">
                                                        <button
                                                            type="button"
                                                            aria-hidden="true"
                                                            className="redirection"
                                                            onClick={() =>
                                                                Redirect(
                                                                    `${process.env.PUBLIC_URL}/devices`,
                                                                    product.serialNumber,
                                                                )
                                                            }
                                                        >
                                                            Plus d'informations
                                                        </button>
                                                    </div>
                                                ) : (
                                                    product.trackingUrl && (
                                                        <div className="container-status-delivery">
                                                            <a
                                                                className="shipping-link"
                                                                href={
                                                                    product.trackingUrl
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Suivre ma
                                                                livraison
                                                            </a>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            {product.specs.__typename ===
                                            "ProductDeviceSpecsOutput" ? (
                                                <ProductSpecs
                                                    {...product.specs}
                                                />
                                            ) : (
                                                <AccessorySpecs
                                                    accessorySpecs={
                                                        product.specs
                                                    }
                                                />
                                            )}
                                        </div>
                                        <div
                                            className="user-and-price"
                                            data-cy="user-and-price"
                                        >
                                            {product.user ? (
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        Redirect(
                                                            `${process.env.PUBLIC_URL}/users`,
                                                            product.user?.email,
                                                        )
                                                    }
                                                    className="alert-background-color order-list-affected-user"
                                                >
                                                    <ProductUserName
                                                        {...product.user}
                                                    />
                                                </button>
                                            ) : (
                                                <div className="alert-background-color">
                                                    <p>Aucun utilisateur</p>
                                                </div>
                                            )}
                                            {isProductStatusVisible && (
                                                <div
                                                    className={`delivery-status ${getStyleFromDeliveryStatus(
                                                        product.deliveryStatus,
                                                    )}`}
                                                    data-cy={`product-status-${i}`}
                                                >
                                                    <p>
                                                        {getDeliveryStatus(
                                                            product.deliveryStatus,
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                            <div className="status-price">
                                                <div className="order-list-price">
                                                    <p>
                                                        <strong>
                                                            {formatPrice(
                                                                product
                                                                    .leasingData
                                                                    .leasingPrice,
                                                            )}
                                                        </strong>{" "}
                                                        HT / mois
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ),
            )}
        </>
    );
};

export default ProductByAddress;
