import type { hexColor, rgbColor } from "./types";

const hexToRgb = (color: hexColor): rgbColor | undefined => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const fullHexColor = color.replace(shorthandRegex, function (r, g, b) {
        return "#" + r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        fullHexColor,
    );
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : undefined;
};

const isDark = (color: hexColor | rgbColor) => {
    if (typeof color !== "object") {
        const tmpColor = hexToRgb(color);
        if (tmpColor === undefined) {
            return false;
        }
        color = tmpColor;
    }

    const luminance =
        (0.299 * color.r + 0.587 * color.g + 0.114 * color.b) / 255;

    if (luminance > 0.5) {
        return false;
    }

    return true;
};

export default isDark;
