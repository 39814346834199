/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { Checkbox, withStyles } from "@material-ui/core";
import { CheckboxProps } from "@material-ui/core/Checkbox/Checkbox";

const CustomCheckbox = withStyles({
    root: {
        color: "#FF625A",
    },
    checked: {},
})((props: CheckboxProps) => (
    <Checkbox
        color="default"
        {...props}
    />
));

export default CustomCheckbox;
