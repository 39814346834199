import React from "react";

import { Mail } from "react-feather";

import styles from "./BillingContactPlaceholder.module.scss";

const BillingContactPlaceholder = () => (
    <div
        className={styles.container}
        data-cy="billing-contact-placeholder"
    >
        <div className={styles.icon}>
            <Mail />
        </div>
        <div className={styles.info}>
            <div className={styles.text}>Aucun contact de facturation</div>
            <div className={styles.text}>
                Tous vos emails de facturation seront envoyés à vos utilisateurs
                administrateurs
            </div>
        </div>
    </div>
);

export default BillingContactPlaceholder;
