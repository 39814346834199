import CURRENT_STORAGE_VERSION from "components/business/catalog/cart/globalVars";
import Product from "components/business/order/product/product";

import CartAction from "./cart-action";

class SetProductInstructionsAction implements CartAction {
    payload: {
        product: Product;
        instructions: string;
    };

    constructor(product: Product, instructions: string) {
        this.payload = {
            product,
            instructions,
        };
    }

    apply(cart: Product[]) {
        const updatedCart = [...cart];
        const productIndex = cart.indexOf(this.payload.product);
        if (productIndex >= 0) {
            const updatedCart = cart;
            updatedCart[productIndex].instructions = this.payload.instructions;
        }
        localStorage.setItem(
            CURRENT_STORAGE_VERSION,
            JSON.stringify(updatedCart),
        );
        return updatedCart;
    }
}

export default SetProductInstructionsAction;
