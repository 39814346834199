import { DeviceOs, DeviceType } from "graphql/management/model/apollo";

export default function deviceOs(selectedDeviceType: DeviceType) {
    const availableOs: DeviceOs[] = [];

    if ([DeviceType.LAPTOP, DeviceType.DESKTOP].includes(selectedDeviceType)) {
        availableOs.push(
            DeviceOs.MAC,
            DeviceOs.WINDOWS,
            DeviceOs.CHROME,
            DeviceOs.LINUX,
            DeviceOs.OTHER,
        );
    }
    if ([DeviceType.PHONE, DeviceType.TABLET].includes(selectedDeviceType)) {
        availableOs.push(
            DeviceOs.IOS,
            DeviceOs.ANDROID,
            DeviceOs.WINDOWS,
            DeviceOs.OTHER,
        );
    }
    if (selectedDeviceType === DeviceType.OTHER) {
        availableOs.push(
            DeviceOs.MAC,
            DeviceOs.WINDOWS,
            DeviceOs.CHROME,
            DeviceOs.LINUX,
            DeviceOs.IOS,
            DeviceOs.ANDROID,
            DeviceOs.OTHER,
        );
    }

    return availableOs;
}
