import React from "react";

import PaperPlaneIcon from "components/common/icons/navbar/paper-plane-icon";
import UserIcon from "components/common/icons/navbar/user-icon";
import ValidationIcon from "components/common/icons/navbar/validation-icon";
import SignatoryIcon from "components/common/icons/SignatoryIcon";

import getIconStyleFromStep from "./utils/get-icon-style-from-step";

import "./nav.scss";

interface OrderNavProps {
    step: number;
    setStep: (step: number) => void;
    orderCreated: boolean;
}

const OrderNav = (props: OrderNavProps) => {
    const setStep = (step: number) => {
        if (step < props.step && !props.orderCreated) {
            props.setStep(step);
        }
    };

    const getStyleFromStep = (step: number): string => {
        if (props.orderCreated) {
            return "order-step-wrapper order-step-deactivate order-step-passed";
        }
        if (props.step === step) {
            return "order-step-wrapper order-step-active";
        }
        if (props.step < step) {
            return "order-step-wrapper";
        }
        return "order-step-wrapper order-step-passed";
    };

    return (
        <div className="order-lateral">
            <div
                aria-hidden="true"
                className={getStyleFromStep(2)}
                onClick={() => setStep(2)}
            >
                <div>
                    <div
                        className={`step ${
                            getIconStyleFromStep(2, props.step).cssClass
                        }`}
                    >
                        <UserIcon
                            color={getIconStyleFromStep(2, props.step).label}
                        />
                    </div>
                </div>
                <div className="order-step-title">
                    <h2>Utilisateurs</h2>
                </div>
            </div>

            <div
                aria-hidden="true"
                className={getStyleFromStep(3)}
                onClick={() => setStep(3)}
            >
                <div>
                    <div
                        className={`step ${
                            getIconStyleFromStep(3, props.step).cssClass
                        }`}
                    >
                        <PaperPlaneIcon
                            color={getIconStyleFromStep(3, props.step).label}
                        />
                    </div>
                </div>
                <div className="order-step-title">
                    <h2>Livraison</h2>
                </div>
            </div>

            <div className={getStyleFromStep(4)}>
                <div>
                    <div
                        className={`step ${
                            getIconStyleFromStep(4, props.step).cssClass
                        }`}
                    >
                        <SignatoryIcon
                            color={getIconStyleFromStep(4, props.step).label}
                        />
                    </div>
                </div>
                <div className="order-step-title">
                    <h2>Prélèvement</h2>
                </div>
            </div>
            <div className={getStyleFromStep(5)}>
                <div>
                    <div
                        className={`step ${
                            getIconStyleFromStep(5, props.step).cssClass
                        }`}
                    >
                        <ValidationIcon
                            color={getIconStyleFromStep(5, props.step).label}
                        />
                    </div>
                </div>
                <div className="order-step-title">
                    <h2>Validation</h2>
                </div>
            </div>
        </div>
    );
};

export default OrderNav;
