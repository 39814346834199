import React, { useEffect, useState, useTransition } from "react";

import { Dialog } from "@cleaq/design-system";

import Product from "components/business/order/product/product";
import { DeviceProductSpecs } from "components/business/order/product/product-specs";
import AccessorySpecs from "components/business/order/step-form/AssignAddress/product-item/product-item-info/accessory-specs/accessory-specs";
import RemoveProductAction from "components/provider/context/cart/actions/remove-product-action";
import {
    useCart,
    useCartDispatch,
} from "components/provider/context/cart/cart-provider";
import {
    EntryCollectionFragment,
    useGetTotalEntriesByIdLazyQuery,
} from "graphql/catalog/model/apollo";
import ApiClientNames from "graphql/consts";
import translator, { formatMemory } from "utils/translator/translator";

import "components/business/order/step-form/AssignAddress/product-item/product-item.scss";
import "./cart-unavailable-product.scss";

// copied from src/components/business/order/step-form/AssignAddress/product-item/product-item-info/product-item-info.tsx
// because methods are encapsulated in ProductItemInfo component
const ProductConfigTag = (config: string | null, unit?: string) => {
    if (config && config.length > 0) {
        return (
            <span className="specs-infos">
                {translator.translate(config)}
                {unit && ` ${unit}`}
            </span>
        );
    }
    return <></>;
};

const ProductItemSpecs = ({ product }: { product: Product }) => {
    if (product.type === "Variant") {
        const specsProduct = product.specs as DeviceProductSpecs;
        return (
            <>
                {ProductConfigTag(specsProduct.processor)}
                {ProductConfigTag(
                    formatMemory(parseFloat(specsProduct.ram ?? "0")),
                    "RAM",
                )}
                {ProductConfigTag(
                    formatMemory(parseFloat(specsProduct.storage ?? "0")),
                    "SSD",
                )}
            </>
        );
    }
    return <AccessorySpecs accessory={product} />;
};

const CartUnavailableProduct = () => {
    const cartDispatch = useCartDispatch();
    const cart = useCart();
    const entryIds = cart.map((product) => product.variantId);
    const [missingEntries, setMissingEntries] = useState<Product[]>([]);
    const [isPending, startTransition] = useTransition();
    const dialogOpen = missingEntries.length > 0;

    const [entriesQuery] = useGetTotalEntriesByIdLazyQuery({
        fetchPolicy: "network-only",
        context: {
            clientName: ApiClientNames.CATALOG,
        },
        onCompleted: (data) => {
            const { items, total } =
                data.entryCollection as EntryCollectionFragment;
            // all variants found, stop here
            if (total === entryIds.length) return;
            const foundIds = items.map((item) => item?.sys.id ?? "");
            const missings = cart.filter(
                ({ variantId }) => !foundIds.includes(variantId),
            );
            setMissingEntries(missings);
        },
    });

    useEffect(() => {
        if (!entryIds.length) return;
        entriesQuery({
            variables: {
                id_in: entryIds,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnClose = () => {
        startTransition(() => {
            missingEntries.forEach((product) => {
                cartDispatch(
                    new RemoveProductAction(
                        product.variantId,
                        product.leasingDuration,
                    ),
                );
            });
            setMissingEntries([]);
        });
    };

    const buttonLabel = isPending ? "Chargement..." : "C’est noté";
    const bodyText =
        missingEntries.length > 1
            ? "Les produits suivants ne sont plus disponibles et vont être retirés de votre panier :"
            : "Le produit suivant n'est plus disponible et va être retiré de votre panier :";

    return (
        <Dialog
            className="cart-unavailable-product__dialog"
            open={dialogOpen}
            role="alertdialog"
            width="xlarge"
            title="Mise à jour de votre panier"
            footerButtons={[
                {
                    variant: "primary",
                    children: buttonLabel,
                    block: true,
                    onClick: handleOnClose,
                },
            ]}
        >
            <p className="cart-unavailable-product__dialog__intro">
                {bodyText}
            </p>
            <div className="cart-unavailable-product__dialog__list">
                {missingEntries.map((product) => (
                    <div
                        className="product-item__info"
                        key={`missing-variants__${product.variantId}`}
                    >
                        <div className="info__device">
                            {product.photo && (
                                <img
                                    src={product.photo}
                                    alt={product.name}
                                    className="device__image"
                                />
                            )}
                            <div className="device__name">
                                <h4>{product.name}</h4>
                                <div className="device__specs">
                                    <ProductItemSpecs product={product} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Dialog>
    );
};

export default CartUnavailableProduct;
