import React from "react";

import "../file-manager.scss";

const DocumentPlaceholder = () => (
    <div className="nlt-document-placeholder">
        <div className="nlt-document-placeholder-icon">
            <i className="fa fa-file-o" />
        </div>
        <div className="document-info">
            <div className="document-name">Aucun document</div>
            <div className="document-type">Retrouvez vos documents ici</div>
        </div>
    </div>
);

export default DocumentPlaceholder;
