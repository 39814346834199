import React, { useState } from "react";

import { Combobox } from "@headlessui/react";
import classnames from "classnames";

import CrossIcon from "components/common/icons/CrossIcon";

import styles from "./TypeAheadInput.module.scss";

interface TypeAheadInputProps {
    label?: string;
    typeAheadValues: string[];
    placeholder?: string;
    fieldName: string;
    fieldValue: string;
    errorMessage?: string;
    onChange: (value: string) => void;
    onReset: () => void;
}

const TypeAheadInput = (props: TypeAheadInputProps) => {
    const [currentValue, setCurrentValue] = useState<string>("");

    const filteredValues =
        currentValue === ""
            ? props.typeAheadValues
            : props.typeAheadValues.filter((value) =>
                  value.toLowerCase().includes(currentValue.toLowerCase()),
              );

    return (
        <div className={styles.container}>
            <div className={styles.typeAheadInput}>
                <Combobox
                    name={props.fieldName}
                    value={props.fieldValue}
                    onChange={(event) => {
                        props.onChange(event);
                        setCurrentValue(event);
                    }}
                >
                    {({ open }) => (
                        <>
                            {props.label && (
                                <Combobox.Label className={styles.inputTitle}>
                                    {props.label}
                                </Combobox.Label>
                            )}
                            <div className={styles.inputContainer}>
                                <Combobox.Input
                                    displayValue={() => props.fieldValue}
                                    onChange={(event) => {
                                        props.onChange(event.target.value);
                                        setCurrentValue(event.target.value);
                                    }}
                                    className={classnames(styles.input, {
                                        [styles.inputError]:
                                            props.errorMessage !== undefined,
                                    })}
                                    placeholder={props.placeholder}
                                />

                                {open && filteredValues.length > 0 && (
                                    <Combobox.Options
                                        className={styles.list}
                                        static
                                    >
                                        {filteredValues.map((value) => (
                                            <Combobox.Option
                                                key={value}
                                                value={value}
                                                className={styles.listItem}
                                            >
                                                {value}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                )}
                            </div>
                        </>
                    )}
                </Combobox>
            </div>

            {currentValue !== "" && (
                <button
                    className={styles.resetButton}
                    aria-label="reset input"
                    onClick={() => {
                        props.onReset();
                        setCurrentValue("");
                    }}
                >
                    <CrossIcon />
                </button>
            )}

            <span className={styles.errorMessage}>
                {props.errorMessage ?? ""}
            </span>
        </div>
    );
};

TypeAheadInput.defaultProps = {
    label: undefined,
    placeholder: undefined,
    errorMessage: undefined,
};

export default TypeAheadInput;
