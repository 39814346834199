import React, { useEffect, useState } from "react";

import { Paper, TextField, FormGroup } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import Product from "components/business/order/product/product";
import ProductUser from "components/business/order/product/product-user";
import ProductsSpecs from "components/common/layout/products-specs/products-specs";
import SetProductUserAction from "components/provider/context/cart/actions/set-product-user-action";
import { useCartDispatch } from "components/provider/context/cart/cart-provider";
import {
    useFindUsersQuery,
    UserFragment,
} from "graphql/management/model/apollo";

import "./AssignUserCard.scss";

interface ItemProps {
    product: Product;
    onCreateUser: () => void;
}

interface AddUserProps {
    children: React.ReactNode;
    other: any;
}

const AssignUserCard = (props: ItemProps) => {
    const [selectedUser, setSelectedUser] = useState<ProductUser | null>(
        props.product.user,
    );
    const usersQuery = useFindUsersQuery();
    const cartDispatch = useCartDispatch();

    function buildUserName(user: ProductUser | UserFragment): string {
        return `${user.firstName} ${user.lastName}`;
    }

    const selectUser = (user: ProductUser | null) => {
        cartDispatch(new SetProductUserAction(props.product, user));
        setSelectedUser(user);
    };

    useEffect(() => {
        setSelectedUser(props.product.user);
    }, [props.product.user]);

    const Users = usersQuery?.data?.findUser.map(
        (user) =>
            new ProductUser(
                user.firstName || "",
                user.lastName || "",
                user.email || "",
            ),
    );

    const AddUser = ({ children, ...other }: AddUserProps) => (
        <Paper {...other}>
            {children}
            <div
                className="select-inner-button"
                style={{ margin: "10px" }}
            >
                <div
                    aria-hidden="true"
                    className="select-inner-button-button"
                    onMouseDown={props.onCreateUser}
                >
                    <i className="icofont icofont-plus" />

                    <span>Ajouter un utilisateur</span>
                </div>
            </div>
        </Paper>
    );

    return (
        <div className="product-wrapper">
            <img
                src={props.product.photo || undefined}
                alt="not found"
                className="product-image"
            />
            <div className="product">
                <div className="product-header">
                    <h4 className="title">{props.product?.name}</h4>
                    <div
                        className="product-assign-user-leasing-duration"
                        data-cy="product-assign-user-leasing-duration"
                    >
                        {`Renouvellement sur ${props.product.leasingDuration} mois`}
                    </div>
                </div>
                <div className="configuration">
                    <ProductsSpecs
                        product={props.product}
                        isCart={false}
                    />
                </div>
                <div
                    className={`choose-item ${
                        props.product.user ? "assigned" : "unassigned"
                    }`}
                >
                    <div className="type mandatory">
                        <span>Utilisateur</span>
                    </div>
                    <FormGroup>
                        <Autocomplete
                            id="autocomplete"
                            getOptionLabel={(user) => buildUserName(user)}
                            onChange={(
                                e: any,
                                data: ProductUser | null,
                                reason: string,
                            ) => {
                                if (reason === "clear") {
                                    selectUser(null);
                                } else {
                                    selectUser(data);
                                }
                            }}
                            options={Users || []}
                            value={selectedUser}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="normal"
                                    color="secondary"
                                    variant="outlined"
                                    placeholder="Laura Petit"
                                    fullWidth
                                />
                            )}
                            /* @ts-ignore */
                            PaperComponent={AddUser}
                        />
                    </FormGroup>
                </div>
            </div>
        </div>
    );
};

export default AssignUserCard;
