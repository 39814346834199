class TranslatorAccessoryType {
    private dictionary = new Map([
        ["earbuds", "Ecouteurs"],
        ["headphones", "Casque"],
        ["mouse", "Souris"],
        ["trackpad", "Trackpad"],
        ["rechargeable battery", "Batterie rechargeable"],
        ["battery", "Piles"],
    ]);

    translate = (value: string | null, withSpace: boolean = false) => {
        if (value === null) {
            return "";
        }

        const space = withSpace ? " " : "";

        return (
            (this.dictionary.has(value.toString())
                ? this.dictionary.get(value.toString())
                : value.toString()) + space
        );
    };
}

const translatorAccessoryType = new TranslatorAccessoryType();

export default translatorAccessoryType;
