import React, { useEffect } from "react";

import { TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Product from "components/business/order/product/product";
import ProductAddress from "components/business/order/product/product-address";
import InformationIcon from "components/common/icons/InformationIcon";
import FormErrorMessage from "components/common/layout/error/form-error-message/form-error-message";
import Tooltip from "components/common/layout/Tooltip/Tooltip";
import SetProductAddressAction from "components/provider/context/cart/actions/set-product-address-action";
import {
    useCart,
    useCartDispatch,
} from "components/provider/context/cart/cart-provider";
import { COUNTRIES_LISTS } from "config";
import {
    refetchFindInstanceQuery,
    useCreateShippingAddressMutation,
    useUpdateAddressMutation,
} from "graphql/management/model/apollo";

import "./AddressModal.scss";

interface AddressModalProps {
    address: ProductAddress | null;
    onToggleAddressForm: () => void;
    selectedProduct: Product | null;
}

class AddressForm {
    country: string = "";

    instructions: string = "";

    address: string = "";

    recipient: string = "";

    zip: string = "";

    city: string = "";

    label: string = "";

    phoneNumber: string = "";
}

export default function AddressModal(props: AddressModalProps) {
    const cart = useCart();
    const cartDispatch = useCartDispatch();
    const {
        formState: { errors },
        handleSubmit,
        control,
    } = useForm<AddressForm>({
        defaultValues: {
            country: props.address?.country ?? "FR",
            recipient: props.address?.recipient ?? "",
            instructions: props.address?.instructions ?? "",
            address: props.address?.name ?? "",
            zip: props.address?.zipcode ?? "",
            city: props.address?.cityName ?? "",
            label: props.address?.label ?? "",
            phoneNumber: props.address?.phoneNumber ?? "",
        },
    });

    const CleaqSwal = withReactContent(Swal);

    useEffect(() => {
        const handleOnKeydown = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                props.onToggleAddressForm();
            }
        };
        document.addEventListener("keydown", handleOnKeydown);
        return () => document.removeEventListener("keydown", handleOnKeydown);
    }, [props]);

    const [addAddress] = useCreateShippingAddressMutation();
    const [updateAddress] = useUpdateAddressMutation();

    const createAddressQuery = async (form: AddressForm) => {
        await addAddress({
            variables: {
                inputAddress: {
                    addressName: form.address,
                    recipient: form.recipient,
                    label: form.label,
                    zipCode: form.zip,
                    cityName: form.city,
                    country: form.country,
                    instructions: form.instructions,
                    phoneNumber: form.phoneNumber,
                },
            },
            update: async (cache, updatedAddressResult) => {
                if (updatedAddressResult.data?.createShippingAddress) {
                    const createdShippingAddress =
                        updatedAddressResult.data.createShippingAddress;
                    const newAddress = new ProductAddress(
                        createdShippingAddress.id!,
                        createdShippingAddress.addressName!,
                        createdShippingAddress.recipient!,
                        createdShippingAddress.label!,
                        createdShippingAddress.zipCode!,
                        createdShippingAddress.cityName!,
                        createdShippingAddress.country!,
                        createdShippingAddress.instructions!,
                        createdShippingAddress.phoneNumber!,
                    );

                    if (props.selectedProduct) {
                        cartDispatch(
                            new SetProductAddressAction(
                                props.selectedProduct,
                                newAddress,
                            ),
                        );
                    }

                    await CleaqSwal.fire({
                        title: "Confirmé !",
                        html: "L'adresse a bien été créée.",
                        icon: "success",
                    });
                }
            },
            refetchQueries: [refetchFindInstanceQuery()],
        });

        props.onToggleAddressForm();
    };

    const updateAddressQuery = async (form: AddressForm) => {
        if (props.address === null) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Si le problème persiste, contactez nous dans le chat",
                icon: "error",
            });

            return;
        }

        const updatedAddressId = props.address.id;

        await updateAddress({
            variables: {
                inputAddress: {
                    id: props.address.id,
                    addressName: form.address,
                    recipient: form.recipient,
                    label: form.label,
                    zipCode: form.zip,
                    cityName: form.city,
                    country: form.country,
                    instructions: form.instructions,
                    phoneNumber: form.phoneNumber,
                },
            },
            update: async (cache, updatedAddressResult) => {
                if (updatedAddressResult.data?.updateAddress) {
                    const productsUpdated = cart.filter((product) => {
                        return product.address?.id === updatedAddressId;
                    });

                    productsUpdated.forEach((product) => {
                        cartDispatch(
                            new SetProductAddressAction(
                                product,
                                new ProductAddress(
                                    updatedAddressId,
                                    form.address,
                                    form.recipient,
                                    form.label,
                                    form.zip,
                                    form.city,
                                    form.country,
                                    form.instructions,
                                    form.phoneNumber,
                                ),
                            ),
                        );
                    });

                    CleaqSwal.fire({
                        title: "Confirmé !",
                        html: "L'adresse a bien été modifiée.",
                        icon: "success",
                    });
                }
            },
            refetchQueries: [refetchFindInstanceQuery()],
        });

        props.onToggleAddressForm();
    };

    const isCreationModal = props.address === null;
    const modalTitle = isCreationModal
        ? "Ajouter une adresse"
        : "Modifier l'adresse";
    const submitButtonText = isCreationModal ? "Ajouter" : "Modifier";
    const onSubmit = isCreationModal
        ? handleSubmit(createAddressQuery)
        : handleSubmit(updateAddressQuery);

    const renderLabel = (tooltipText: string) => {
        return (
            <Tooltip
                style={{ marginBottom: "0.5rem" }}
                text={tooltipText}
            >
                <InformationIcon />
            </Tooltip>
        );
    };

    const tooltipText =
        "Renseignez le bon numéro pour garantir une livraison sans accroc.";

    return (
        <div className="backdrop-modal">
            <div className="create-address-modal">
                <button
                    type="button"
                    className="nlt-button-inside-close absolute-close"
                    onClick={() => props.onToggleAddressForm()}
                >
                    <i className="icofont icofont-close" />
                </button>
                <form onSubmit={onSubmit}>
                    <div className="address-modal-title">{modalTitle}</div>
                    <div
                        className="address-modal-body"
                        data-cy="address-form-container"
                    >
                        <div className="create-modal-form">
                            <div>
                                <div className="long-required">
                                    <label
                                        className="required"
                                        htmlFor="label"
                                    >
                                        Nom de l'adresse
                                    </label>
                                </div>
                                <Controller
                                    name="label"
                                    control={control}
                                    rules={{
                                        required:
                                            "Le label de l'adresse est requis.",
                                    }}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            id="label"
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Laura Petit, Bureau Cleaq"
                                            inputProps={{
                                                "aria-label": field.name,
                                            }}
                                            disabled={
                                                props.address?.id ===
                                                "000000000000000000000000"
                                            }
                                            data-cy="input-label-address"
                                            helperText={
                                                <FormErrorMessage
                                                    data-cy={field.name}
                                                    testId={field.value}
                                                    message={
                                                        error?.message ?? ""
                                                    }
                                                />
                                            }
                                            error={error !== undefined}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <div className="long-required">
                                    <label
                                        className="required"
                                        htmlFor="label"
                                    >
                                        À l'attention de
                                    </label>
                                </div>
                                <Controller
                                    name="recipient"
                                    control={control}
                                    rules={{
                                        required:
                                            "Le destinataire de l'adresse est requis.",
                                    }}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            id="recipient"
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Laura Petit"
                                            inputProps={{
                                                "aria-label": field.name,
                                            }}
                                            data-cy="input-recipient-address"
                                            helperText={
                                                <FormErrorMessage
                                                    data-cy={field.name}
                                                    testId={field.value}
                                                    message={
                                                        error?.message ?? ""
                                                    }
                                                />
                                            }
                                            error={error !== undefined}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <div className="long-required">
                                    <label
                                        className="required"
                                        htmlFor="address"
                                    >
                                        Adresse
                                    </label>
                                </div>
                                <Controller
                                    name="address"
                                    control={control}
                                    rules={{
                                        required: "L'adresse est requise.",
                                    }}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            id="address"
                                            fullWidth
                                            {...field}
                                            inputRef={ref}
                                            size="small"
                                            variant="outlined"
                                            placeholder="5 Rue Du Havre"
                                            inputProps={{
                                                "aria-label": field.name,
                                            }}
                                            data-cy="input-address"
                                            helperText={
                                                <FormErrorMessage
                                                    data-cy={field.name}
                                                    testId={field.value}
                                                    message={
                                                        error?.message ?? ""
                                                    }
                                                />
                                            }
                                            error={error !== undefined}
                                        />
                                    )}
                                />
                            </div>
                            <div className="long-row">
                                <div className="long-row-container">
                                    <div className="long-required">
                                        <label
                                            className="required"
                                            htmlFor="zip"
                                        >
                                            Code Postal
                                        </label>
                                    </div>
                                    <Controller
                                        name="zip"
                                        control={control}
                                        rules={{
                                            required:
                                                "Le code postal est requis.",
                                        }}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                fullWidth
                                                id="zip"
                                                {...field}
                                                inputRef={ref}
                                                size="small"
                                                variant="outlined"
                                                placeholder="75015"
                                                inputProps={{
                                                    "aria-label": field.name,
                                                }}
                                                data-cy="input-zipcode"
                                                helperText={
                                                    <FormErrorMessage
                                                        data-cy={field.name}
                                                        testId={field.value}
                                                        message={
                                                            error?.message ?? ""
                                                        }
                                                    />
                                                }
                                                error={error !== undefined}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="long-row-container">
                                    <div className="long-required">
                                        <label
                                            className="required"
                                            htmlFor="city"
                                        >
                                            Ville
                                        </label>
                                    </div>
                                    <Controller
                                        name="city"
                                        control={control}
                                        rules={{
                                            required: "La ville est requise.",
                                        }}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                id="city"
                                                fullWidth
                                                {...field}
                                                inputRef={ref}
                                                size="small"
                                                variant="outlined"
                                                placeholder="Paris"
                                                inputProps={{
                                                    "aria-label": field.name,
                                                }}
                                                data-cy="input-city"
                                                helperText={
                                                    <FormErrorMessage
                                                        data-cy={field.name}
                                                        testId={field.value}
                                                        message={
                                                            error?.message ?? ""
                                                        }
                                                    />
                                                }
                                                error={error !== undefined}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="long-required">
                                    <label
                                        className="required"
                                        htmlFor="country"
                                    >
                                        Pays
                                    </label>
                                </div>
                                <Controller
                                    name="country"
                                    control={control}
                                    rules={{
                                        required: "Le pays est requis.",
                                    }}
                                    render={({
                                        field: { value, ...field },
                                        fieldState: { error },
                                    }) => {
                                        const countryValue = value
                                            ? COUNTRIES_LISTS.find(
                                                  (country) =>
                                                      country.value === value,
                                              )
                                            : undefined;
                                        return (
                                            <>
                                                <Select
                                                    {...field}
                                                    defaultValue={
                                                        props.address?.country
                                                            ? {
                                                                  value: props
                                                                      .address
                                                                      ?.country,
                                                                  label: props
                                                                      .address
                                                                      ?.country,
                                                              }
                                                            : undefined
                                                    }
                                                    value={countryValue}
                                                    onChange={(e) => {
                                                        if (
                                                            e &&
                                                            e.value !== value
                                                        ) {
                                                            field.onChange(
                                                                e.value,
                                                            );
                                                        }
                                                    }}
                                                    id="country"
                                                    data-cy="input-country"
                                                    aria-label={field.name}
                                                    options={COUNTRIES_LISTS}
                                                    placeholder="France"
                                                    noOptionsMessage={() =>
                                                        "Aucune option"
                                                    }
                                                    styles={{
                                                        placeholder: (
                                                            baseStyles,
                                                        ) => ({
                                                            ...baseStyles,
                                                            color: "#9ea6ae",
                                                        }),
                                                        container: (
                                                            baseStyles,
                                                        ) => ({
                                                            ...baseStyles,
                                                            width: "100%",
                                                        }),
                                                        control: (
                                                            baseStyles,
                                                            state,
                                                        ) => ({
                                                            ...baseStyles,
                                                            borderColor:
                                                                state.isFocused
                                                                    ? "#FF625A"
                                                                    : "#CCCCCC",
                                                            color: "#23262a",
                                                            boxShadow:
                                                                state.isFocused
                                                                    ? "0 0 0 1px #FF625A"
                                                                    : undefined,
                                                            "&:hover": {
                                                                borderColor:
                                                                    state.isFocused
                                                                        ? "#FF625A"
                                                                        : "#00000076",
                                                            },
                                                        }),
                                                        menuList: (
                                                            baseStyles,
                                                        ) => ({
                                                            ...baseStyles,
                                                            maxHeight: 160,
                                                        }),
                                                    }}
                                                />
                                                <p
                                                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                                                    id="country-helper-text"
                                                >
                                                    <FormErrorMessage
                                                        data-cy={field.name}
                                                        testId={value}
                                                        message={
                                                            error?.message ?? ""
                                                        }
                                                    />
                                                </p>
                                            </>
                                        );
                                    }}
                                />
                            </div>
                            <div className="address-modal-phone-input">
                                <div className="address-modal-phone-title">
                                    <div className="long-required">
                                        <label
                                            className="required"
                                            htmlFor="phoneNumber"
                                        >
                                            Téléphone
                                        </label>
                                    </div>
                                    {renderLabel(tooltipText)}
                                </div>
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    rules={{
                                        required: "Le téléphone est requis.",
                                    }}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            <PhoneInput
                                                {...field}
                                                id="phoneNumber"
                                                inputRef={ref}
                                                placeholder="0601020304"
                                                data-cy="input-phoneNumber"
                                                defaultCountry="FR"
                                                required={error !== undefined}
                                            />
                                            <p className="form-error-phone">
                                                <FormErrorMessage
                                                    data-cy={"phone"}
                                                    message={
                                                        error?.message ?? ""
                                                    }
                                                />
                                            </p>
                                        </>
                                    )}
                                />
                            </div>
                            <div>
                                <div className="long">
                                    <label htmlFor="instructions">
                                        Instructions
                                    </label>
                                </div>
                                <Controller
                                    name="instructions"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                        <TextField
                                            id="instructions"
                                            fullWidth
                                            {...field}
                                            inputRef={ref}
                                            multiline
                                            minRows={3}
                                            inputProps={{
                                                "aria-label": field.name,
                                            }}
                                            data-cy="input-instructions"
                                            placeholder="Bâtiment, Etage ..."
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="cleaq-button"
                        id="submitAddress"
                        data-cy="submit-address"
                        disabled={
                            errors !== undefined &&
                            Object.keys(errors).length > 0
                        }
                    >
                        {submitButtonText}
                    </button>
                </form>
            </div>
        </div>
    );
}
