import React from "react";

interface OptimizeCardInfo {
    icon: string;
    redirectFunction: () => void;
    subtitle: string;
    title: string;
    count: number | undefined;
}

const OptimizeCard = (props: OptimizeCardInfo) => (
    <div
        aria-hidden="true"
        className="nlt-optimize-action"
        style={{
            float: "left",
            marginRight: "auto",
        }}
        onClick={props.redirectFunction}
    >
        <div className="nlt-optimize-icon">
            <i className={props.icon} />
        </div>
        <div className="nlt-optimize-info">
            <div className="nlt-document-title">
                {props.count} {props.title}
            </div>
            <div className="nlt-document-date">{props.subtitle}</div>
        </div>
        <div className="nlt-optimize-arrow">
            <i className="fa fa-arrow-right nlt-font-red" />
        </div>
    </div>
);
export default OptimizeCard;
