import React from "react";

import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Form, Row } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Input from "components/common/input/Input/Input";
import { COUNTRIES_LISTS } from "config";
import {
    InstanceFragment,
    refetchFindInstanceQuery,
    useUpdateInstanceMutation,
} from "graphql/management/model/apollo";

import "./company-details.scss";

interface CompanyDetailsFormProps {
    instance: InstanceFragment;
    setEditable: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormContent {
    representativeName: string;
    phoneNumber: string;
    email: string;
    shippingCountry: string;
    shippingInstructions: string;
    shippingAddress: string;
    shippingZip: string;
    shippingCity: string;
    shippingRecipient: string;
}

const CleaqSwal = withReactContent(Swal);

export default function CompanyDetailsForm({
    instance: currentInstance,
    setEditable,
}: CompanyDetailsFormProps) {
    const shippingAddress = currentInstance?.shippingAddress;

    const [updateInstance] = useUpdateInstanceMutation();
    const { handleSubmit, control } = useForm<FormContent>({
        defaultValues: {
            representativeName: currentInstance?.representativeName ?? "",
            phoneNumber: shippingAddress?.phoneNumber ?? "",
            email: currentInstance?.email ?? "",
            shippingCountry: shippingAddress?.country ?? "",
            shippingInstructions: shippingAddress?.instructions ?? "",
            shippingAddress: shippingAddress?.addressName ?? "",
            shippingZip: shippingAddress?.zipCode ?? "",
            shippingCity: shippingAddress?.cityName ?? "",
            shippingRecipient: shippingAddress?.recipient ?? "",
        },
    });

    const UpdateInstanceForm = (dataPush: FormContent) => {
        updateInstance({
            variables: {
                email: dataPush.email,
                representativeName: dataPush.representativeName,
                shippingAddress: {
                    addressName: dataPush.shippingAddress,
                    recipient: dataPush.shippingRecipient,
                    cityName: dataPush.shippingCity,
                    country: dataPush.shippingCountry,
                    zipCode: dataPush.shippingZip,
                    label: "",
                    instructions: dataPush.shippingInstructions,
                    phoneNumber: dataPush.phoneNumber,
                },
            },
            refetchQueries: [refetchFindInstanceQuery()],
        }).catch((error: any) => {
            CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Contactez-nous dans le chat !",
                icon: "error",
            });
        });
        setEditable(false);
    };

    return (
        <Row className="m-b-12">
            <div className="col-md-12">
                <div className="col-12">
                    <Form
                        id="company-form"
                        className="w-100"
                        onSubmit={handleSubmit(UpdateInstanceForm)}
                    >
                        <h5 className="mb-3">Adresse de facturation</h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="mb-2 field-group">
                                    <div className="nlt-item-info-label">
                                        SIREN
                                    </div>
                                    <input
                                        disabled
                                        className="form-control pull-right"
                                        type="text"
                                        style={{
                                            width: "100%",
                                            marginTop: "-6px",
                                        }}
                                        value={currentInstance.siren ?? ""}
                                    />
                                </div>
                                <div
                                    style={{ display: "flex" }}
                                    className="mb-2 field-group"
                                >
                                    <div className="nlt-item-info-label">
                                        Nom du contact
                                    </div>
                                    <Controller
                                        name="representativeName"
                                        control={control}
                                        rules={{
                                            // prettier-ignore
                                            required: "Le nom du contact est requis.",
                                        }}
                                        render={({
                                            field,
                                            fieldState: { error },
                                        }) => (
                                            <Input
                                                {...field}
                                                className="form-control pull-right"
                                                type="text"
                                                placeholder="Laura Petit"
                                                style={{
                                                    width: "100%",
                                                    marginTop: "-6px",
                                                }}
                                                errorMessage={error?.message}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="mb-2 field-group">
                                    <div className="nlt-item-info-label">
                                        N° de téléphone
                                    </div>
                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        rules={{
                                            // prettier-ignore
                                            required: "Le téléphone est requis.",
                                        }}
                                        render={({
                                            field,
                                            fieldState: { error },
                                        }) => (
                                            <Input
                                                {...field}
                                                className="form-control pull-right"
                                                type="text"
                                                placeholder="06 01 02 03 04"
                                                style={{
                                                    width: "100%",
                                                    marginTop: "-6px",
                                                }}
                                                errorMessage={error?.message}
                                                data-cy="input-phoneNumber"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="mb-2 field-group">
                                    <div className="nlt-item-info-label">
                                        Adresse e-mail
                                    </div>
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{
                                            // prettier-ignore
                                            required: "L'adresse email est requise.",
                                        }}
                                        render={({
                                            field,
                                            fieldState: { error },
                                        }) => (
                                            <Input
                                                {...field}
                                                className="form-control pull-right"
                                                type="text"
                                                placeholder="laura.petit@cleaq.com"
                                                errorMessage={error?.message}
                                                style={{
                                                    width: "100%",
                                                    marginTop: "-6px",
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="mb-2 field-group">
                                    <div className="email-general instance-links">
                                        <div>
                                            <a
                                                href={`http://files.cleaq.com/cg/CGS_SaaS-Cleaq.pdf?date=${moment().format(
                                                    "YYYY-MM-DD",
                                                )}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Voir les conditions générales
                                                d'utilisation de l'App Cleaq
                                            </a>
                                        </div>
                                        <div>
                                            <a
                                                href="https://cleaq.notion.site/cleaq/Conditions-G-n-rales-de-Location-de-Fourniture-et-de-Services-10ca3bb6867e407d8249d21095af3f88"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Voir les conditions générales de
                                                mes contrats de location
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-2 field-group">
                                    <div className="nlt-item-info-label">
                                        À l'attention de
                                    </div>
                                    <Controller
                                        name="shippingRecipient"
                                        control={control}
                                        rules={{
                                            // prettier-ignore
                                            required: "La destinataire est requis.",
                                        }}
                                        render={({
                                            field,
                                            fieldState: { error },
                                        }) => (
                                            <Input
                                                {...field}
                                                className="form-control pull-right"
                                                type="text"
                                                placeholder="Laura Petit"
                                                style={{
                                                    width: "100%",
                                                    marginTop: "-6px",
                                                }}
                                                errorMessage={error?.message}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="mb-2 field-group">
                                    <div className="nlt-item-info-label">
                                        Adresse
                                    </div>
                                    <Controller
                                        name="shippingAddress"
                                        control={control}
                                        rules={{
                                            // prettier-ignore
                                            required: "L'adresse est requise.",
                                        }}
                                        render={({
                                            field,
                                            fieldState: { error },
                                        }) => (
                                            <Input
                                                {...field}
                                                className="form-control pull-right"
                                                type="text"
                                                placeholder="10 rue la Boétie"
                                                style={{
                                                    width: "100%",
                                                    marginTop: "-6px",
                                                }}
                                                errorMessage={
                                                    // prettier-ignore
                                                    error?.message
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div
                                    style={{ display: "flex" }}
                                    className="mb-2 field-group"
                                >
                                    <div className="nlt-item-info-label">
                                        Code Postal
                                    </div>
                                    <Controller
                                        name="shippingZip"
                                        control={control}
                                        rules={{
                                            // prettier-ignore
                                            required: "Le code postal est requis.",
                                        }}
                                        render={({
                                            field,
                                            fieldState: { error },
                                        }) => (
                                            <Input
                                                {...field}
                                                className="form-control pull-right"
                                                type="text"
                                                placeholder="75015"
                                                style={{
                                                    width: "100%",
                                                    marginTop: "-6px",
                                                }}
                                                errorMessage={error?.message}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="mb-2 field-group">
                                    <div className="nlt-item-info-label">
                                        Commune
                                    </div>
                                    <Controller
                                        name="shippingCity"
                                        control={control}
                                        rules={{
                                            // prettier-ignore
                                            required: "La ville est requise.",
                                        }}
                                        render={({
                                            field,
                                            fieldState: { error },
                                        }) => (
                                            <Input
                                                {...field}
                                                className="form-control pull-right"
                                                type="text"
                                                placeholder="Paris"
                                                style={{
                                                    width: "100%",
                                                    marginTop: "-6px",
                                                }}
                                                errorMessage={error?.message}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="mb-2 field-group">
                                    <div className="nlt-item-info-label">
                                        Pays
                                    </div>
                                    <Controller
                                        name="shippingCountry"
                                        control={control}
                                        rules={{
                                            // prettier-ignore
                                            required: "Le pays est requis.",
                                        }}
                                        render={({
                                            field: { value, ...field },
                                        }) => {
                                            const countryValue =
                                                COUNTRIES_LISTS.find(
                                                    (country) =>
                                                        country.value === value,
                                                );
                                            return (
                                                <Select
                                                    {...field}
                                                    value={countryValue}
                                                    onChange={(e) => {
                                                        if (e && e.value) {
                                                            field.onChange(
                                                                e.value,
                                                            );
                                                        }
                                                    }}
                                                    id="shippingCountry"
                                                    aria-label="shippingCountry"
                                                    options={COUNTRIES_LISTS}
                                                    noOptionsMessage={() =>
                                                        "Aucune option"
                                                    }
                                                    styles={{
                                                        placeholder: (
                                                            baseStyles,
                                                        ) => ({
                                                            ...baseStyles,
                                                            color: "#9ea6ae",
                                                        }),
                                                        container: (
                                                            baseStyles,
                                                        ) => ({
                                                            ...baseStyles,
                                                            width: "100%",
                                                        }),
                                                        menuList: (
                                                            baseStyles,
                                                        ) => ({
                                                            ...baseStyles,
                                                            maxHeight: 160,
                                                        }),
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <div className="mb-2 field-group">
                                    <div className="nlt-item-info-label">
                                        Instructions de livraison
                                    </div>
                                    <Controller
                                        name="shippingInstructions"
                                        control={control}
                                        render={({ field }) => (
                                            <textarea
                                                {...field}
                                                className="form-control"
                                                style={{
                                                    width: "100%",
                                                    maxHeight: "70px",
                                                    minHeight: "70px",
                                                }}
                                                placeholder="Batiment, Etage ..."
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Row>
    );
}
