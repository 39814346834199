/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState } from "react";

import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useKeycloak } from "@react-keycloak/web";
import { Typeahead } from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom";
import {
    Container,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";

import Loader from "components/common/layout/loader/loader";
import {
    DeviceFragment,
    DeviceOs,
    DeviceStatus,
    DeviceType,
    refetchComputeDashboardDataQuery,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    useAffectUserDeviceMutation,
    useCreateDeviceMutation,
    useDeleteDevicesAffectationMutation,
    useFindDevicesQuery,
    useFindUsersLazyQuery,
    UserDeviceFragment,
    UserFragment,
} from "graphql/management/model/apollo";
import iconDeviceLink from "utils/device/device-icons-links";
import { osLabels, typesLabels } from "utils/device/device-labels";
import {
    convertToOsType,
    convertToDeviceType,
} from "utils/device/device-types-converter";
import displayAlertMessage from "utils/displayAlertMessage/displayAlertMessage";

import "./AssignUserManagement.scss";

type AssignDeviceProps = {
    selectedUser: UserFragment;
    setSelectedUser: React.Dispatch<
        React.SetStateAction<UserFragment | null | undefined>
    >;
};

interface ErrorAssignDevices {
    serialNumber: boolean;
    name: boolean;
}

const AssignDevice = (props: AssignDeviceProps) => {
    const { initialized } = useKeycloak();
    const devicesQuery = useFindDevicesQuery();
    const [usersQuery] = useFindUsersLazyQuery();
    const [saving, setSaving] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
    const [errors, setErrors] = useState<ErrorAssignDevices | null>();
    const [newSerial, setNewSerial] = useState("");
    const [newName, setNewName] = useState("");
    const [newType, setNewType] = useState<DeviceType>();
    const [newOs, setNewOs] = useState<DeviceOs>();
    const [createDevice] = useCreateDeviceMutation();
    const [affectUserDevice] = useAffectUserDeviceMutation();
    const [deleteDevicesAffectation] = useDeleteDevicesAffectationMutation();
    const history = useHistory();

    if (!initialized || devicesQuery.loading) return <Loader />;

    function buildDeviceName(
        device:
            | DeviceFragment
            | UserDeviceFragment
            | {
                  reference: string;
                  os: DeviceOs;
                  serialNumber: string;
                  type: DeviceType;
              },
    ): string {
        return `${device.reference} (${device.serialNumber})`;
    }

    const ResetEdit = () => {
        setNewSerial("");
        setNewName("");
        setErrors(null);
        setNewType(DeviceType.LAPTOP);
        setNewOs(DeviceOs.MAC);
        setSaving(false);
    };

    const ToggleModal = () => {
        setModal(!modal);
        setSelectedDevices(
            props.selectedUser?.devices
                ? props.selectedUser?.devices?.map((device) =>
                      buildDeviceName(device),
                  )
                : [],
        );
        ResetEdit();
    };

    function addDevice(devices: string[]) {
        setSelectedDevices(selectedDevices.concat(devices));
    }

    function deleteDevice(device: string) {
        setSelectedDevices(
            selectedDevices.filter((deviceItem) => deviceItem !== device),
        );
    }

    async function refreshSelectedUser() {
        const { data: users } = await usersQuery();
        const updatedUser = users?.findUser.find(
            (user) => user.email === props.selectedUser.email,
        );
        props.setSelectedUser(updatedUser);
    }

    const AffectDevice = async () => {
        setSaving(true);
        const serials: string[] = selectedDevices
            .map((deviceString) => deviceString.split("(").pop()?.split(")")[0])
            .filter((serial) => serial !== undefined) as string[];

        try {
            if (props.selectedUser.devices) {
                const serialsToUnaffect = props.selectedUser.devices
                    .map((device) => device.serialNumber ?? "")
                    .filter((serial) => !serials.includes(serial));
                const response = await deleteDevicesAffectation({
                    variables: {
                        serials: serialsToUnaffect,
                    },
                });
                if (!response) {
                    throw new Error("DeleteUserDevice failed");
                }
            }

            await affectUserDevice({
                variables: {
                    serials,
                    email: props.selectedUser.email,
                },
                refetchQueries: [
                    refetchFindDevicesQuery(),
                    refetchFindUsersQuery(),
                    refetchFindOrganisationsQuery(),
                    refetchComputeDashboardDataQuery(),
                ],
            });

            await displayAlertMessage(
                "L'utilisateur a bien été modifié.",
                "success",
                ResetEdit,
            );
        } catch (error) {
            const message =
                "Vérifiez le numéro de série renseigné." +
                "<br/>Si le problème persiste, contactez nous dans le chat";

            await displayAlertMessage(message, "error", ResetEdit);
            return;
        } finally {
            await refreshSelectedUser();
            ToggleModal();
        }
    };

    async function addCreateDevice() {
        setErrors(null);

        if (
            newOs &&
            newType &&
            newSerial.trim().length > 0 &&
            newName.trim().length > 0
        ) {
            const newDevice = {
                os: newOs,
                type: newType,
                serialNumber: newSerial,
                reference: newName,
            };

            try {
                await createDevice({
                    variables: {
                        reference: newDevice.reference,
                        specs: {
                            deviceOs: newDevice.os,
                            deviceType: newDevice.type,
                        },
                        serialNumber: newDevice.serialNumber,
                        status: DeviceStatus.OTHER,
                    },
                    refetchQueries: [
                        refetchFindDevicesQuery(),
                        refetchComputeDashboardDataQuery(),
                    ],
                });

                setNewSerial("");
                setNewName("");
                setNewType(DeviceType.LAPTOP);
                setNewOs(DeviceOs.MAC);
                addDevice([buildDeviceName(newDevice)]);

                await displayAlertMessage(
                    "L'appareil a bien été créé !",
                    "success",
                    ResetEdit,
                );
            } catch (e) {
                const message =
                    "Vérifiez le numéro de série renseigné." +
                    "<br/>Si le problème persiste, contactez nous dans le chat";

                await displayAlertMessage(message, "error", ResetEdit);
            }
        } else {
            setErrors({
                serialNumber: !(
                    newSerial.trim().length && newSerial.trim().length > 0
                ),
                name: !(newName.trim().length && newName.trim().length > 0),
            });
        }
    }

    function availableOs(deviceType: DeviceType): DeviceOs[] {
        const availableOs: DeviceOs[] = [];

        if ([DeviceType.LAPTOP, DeviceType.DESKTOP].includes(deviceType)) {
            availableOs.push(
                DeviceOs.MAC,
                DeviceOs.WINDOWS,
                DeviceOs.CHROME,
                DeviceOs.LINUX,
                DeviceOs.OTHER,
            );
        }
        if ([DeviceType.PHONE, DeviceType.TABLET].includes(deviceType)) {
            availableOs.push(
                DeviceOs.IOS,
                DeviceOs.ANDROID,
                DeviceOs.WINDOWS,
                DeviceOs.OTHER,
            );
        }
        if (deviceType === DeviceType.OTHER) {
            availableOs.push(
                DeviceOs.MAC,
                DeviceOs.WINDOWS,
                DeviceOs.CHROME,
                DeviceOs.LINUX,
                DeviceOs.IOS,
                DeviceOs.ANDROID,
                DeviceOs.OTHER,
            );
        }
        return availableOs;
    }

    function deviceOs(selectedDeviceType: DeviceType) {
        return availableOs(selectedDeviceType).map((os) => (
            <option
                key={os}
                value={os}
            >
                {osLabels.get(os)}
            </option>
        ));
    }

    function deviceTypes() {
        const options: any = [];
        typesLabels.forEach((value: string, key: string) => {
            options.push(
                <option
                    key={key.valueOf()}
                    value={key}
                >
                    {value}
                </option>,
            );
        });
        return options;
    }

    const DevicesNames =
        devicesQuery.data?.findDevices
            .filter(
                (device) =>
                    !selectedDevices.find((selectedDevice) =>
                        selectedDevice.includes(device.serialNumber),
                    ),
            )
            .map((device) => buildDeviceName(device)) || [];

    const DevicesAutocompleteNames = Array.from(
        new Set(
            devicesQuery.data?.findDevices.map((device) => device.reference!),
        ),
    );

    const Redirect = (redirect: any, serial: string) => {
        history.push({
            pathname: redirect,
            state: { serialSearch: serial },
        });
    };

    return (
        <>
            <div className="float-right">
                <a
                    aria-hidden="true"
                    className="nlt-button-inside-form"
                    onClick={ToggleModal}
                >
                    <i className="icofont icofont-layers m-r-5" />
                    Gérer
                </a>
            </div>
            <h6 className="mb-4">Appareils rattachés</h6>
            <Modal
                isOpen={modal}
                toggle={ToggleModal}
                className="modal-body"
                centered
            >
                <ModalHeader toggle={ToggleModal}>
                    Quel(s) appareil(s) utilise {props.selectedUser.firstName} ?
                </ModalHeader>
                <ModalBody>
                    <Container
                        fluid
                        className="bd-example-row"
                    >
                        <h6>Choisissez parmi vos appareils existants :</h6>
                        <FormGroup>
                            {(() => {
                                // https://github.com/ericgio/react-bootstrap-typeahead/issues/703
                                let ref: Typeahead<string> | null;
                                return (
                                    <Typeahead
                                        id="basic-typeahead"
                                        multiple={false}
                                        emptyLabel="Aucun résultat"
                                        paginationText="Plus de résultats..."
                                        selected={[]}
                                        onChange={(e) => addDevice(e)}
                                        options={DevicesNames || []}
                                        placeholder="Choisir des appareils..."
                                        onBlur={() => ref?.hideMenu()}
                                        ref={(el) => (ref = el)}
                                    />
                                );
                            })()}
                        </FormGroup>

                        <h6 className="m-t-20">
                            Ou créez un nouvel appareil :
                        </h6>
                        <Form className="assign-device-form needs-validation">
                            <Row className="nlt-row-form-fix">
                                <div className="nlt-item-info-label-edit col-md-3 required">
                                    Nom
                                </div>
                                <FormGroup className="col-md-7">
                                    {!errors?.name && (
                                        <span className="mandatory-label-small m-t-0" />
                                    )}
                                    <span className="nlt-mandatory-label-small nlt-font-red m-t-0">
                                        {errors?.name &&
                                            "Le nom de l'appareil est requis."}
                                    </span>
                                    <Autocomplete
                                        id="autocomplete"
                                        inputValue={newName}
                                        onInputChange={(e: any, data: string) =>
                                            setNewName(data)
                                        }
                                        onChange={(
                                            e: any,
                                            data: string | null,
                                        ) =>
                                            setNewName(
                                                data && data.length > 0
                                                    ? data
                                                    : "",
                                            )
                                        }
                                        freeSolo
                                        options={DevicesAutocompleteNames!}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                margin="normal"
                                                variant="outlined"
                                                placeholder='MacBook Pro 15"'
                                                className="nlt-form-fix"
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </Row>
                            <Row className="nlt-row-form-fix">
                                <div className="nlt-item-info-label-edit col-md-3 required">
                                    N° Série
                                </div>
                                <FormGroup className="col-md-7">
                                    {!errors?.serialNumber && (
                                        <span className="mandatory-label-small" />
                                    )}
                                    <span className="nlt-mandatory-label-small nlt-font-red">
                                        {errors?.serialNumber &&
                                            "Le numéro de série est requis."}
                                    </span>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="ABCD234GF"
                                        onChange={(e) =>
                                            setNewSerial(e.target.value)
                                        }
                                        value={newSerial}
                                    />
                                </FormGroup>
                            </Row>
                            <Row className="nlt-row-form-fix">
                                <div className="nlt-item-info-label-edit col-md-3">
                                    Type
                                </div>
                                <FormGroup className="col-md-7">
                                    <Input
                                        className="form-control"
                                        id="device-type"
                                        onChange={(event) => {
                                            const type = convertToDeviceType(
                                                event.target.value!,
                                            );
                                            setNewType(type);
                                            setNewOs(availableOs(type)[0]);
                                        }}
                                        type="select"
                                        value={newType}
                                    >
                                        {deviceTypes()}
                                    </Input>
                                </FormGroup>
                            </Row>

                            <Row className="nlt-row-form-fix">
                                <div className="nlt-item-info-label-edit col-md-3">
                                    OS
                                </div>
                                <FormGroup className="col-md-7">
                                    <Input
                                        className="form-control"
                                        id="device-os"
                                        onChange={(event) =>
                                            setNewOs(
                                                convertToOsType(
                                                    event.target.value!,
                                                ),
                                            )
                                        }
                                        type="select"
                                        value={newOs}
                                    >
                                        {deviceOs(newType!)}
                                    </Input>
                                </FormGroup>
                            </Row>
                            <Row className="nlt-row-form-fix">
                                <div
                                    aria-hidden="true"
                                    className="nlt-button-add-sm"
                                    onClick={addCreateDevice}
                                >
                                    <i className="fa fa-plus nlt-button-icon-plus" />
                                    Ajouter
                                </div>
                            </Row>

                            {selectedDevices && selectedDevices?.length > 0 && (
                                <>
                                    <div className="nlt-device-sep" />
                                    <h6>Appareils affectés</h6>
                                    <table className="table table-bordernone">
                                        <tbody>
                                            {selectedDevices.map((device) => (
                                                <tr key={device}>
                                                    <div className="nlt-contact-inside-form-light">
                                                        {device}
                                                    </div>
                                                    <div
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                            deleteDevice(device)
                                                        }
                                                        className="float-right form-remove-element"
                                                    >
                                                        <i className="icofont icofont-bin m-r-5" />
                                                    </div>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </Form>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    {!saving ? (
                        <>
                            <button
                                type="button"
                                className="nlt-button-inside-form-cancel"
                                onClick={ToggleModal}
                            >
                                Annuler
                            </button>
                            <button
                                type="button"
                                className="nlt-button-inside-form-save"
                                onClick={AffectDevice}
                            >
                                Valider
                            </button>
                        </>
                    ) : (
                        <div className="nlt-loader-container-modal">
                            <div className="nlt-loader-save" />
                        </div>
                    )}
                </ModalFooter>
            </Modal>

            {props.selectedUser.devices &&
            props.selectedUser.devices.length > 0 ? (
                <table className="table table-bordernone">
                    <tbody>
                        {props.selectedUser.devices.map((device) => (
                            <tr key={device.serialNumber}>
                                <div className="nlt-contact-img-inside p-t-5">
                                    <img
                                        className="nlt-ranked-device-icon"
                                        src={iconDeviceLink(
                                            device.deviceType?.toString() || "",
                                        )}
                                        alt={
                                            device.deviceType?.toString() || ""
                                        }
                                    />
                                </div>
                                <div className="nlt-contact-inside-form">
                                    {device.reference}
                                </div>
                                <div
                                    aria-hidden="true"
                                    className="nlt-button-inside-user"
                                    onClick={() =>
                                        Redirect(
                                            `${process.env.PUBLIC_URL}/devices`,
                                            device.serialNumber!,
                                        )
                                    }
                                >
                                    Voir
                                </div>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <li>Aucun appareil associé !</li>
            )}
        </>
    );
};

export default AssignDevice;
