import React from "react";

import getIconColor from "./utils";

interface SmartphoneIconProps {
    isActive?: boolean;
}

export default function SmartphoneIcon(props: SmartphoneIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 24"
            width="15"
            height="24"
        >
            <rect
                width="15"
                height="24"
                rx="1.75"
                ry="1.75"
                fill={getIconColor(props.isActive ?? false)}
            />
            <path
                d="m6.25,21.82c0-.34.13-.66.37-.89.23-.24.55-.37.88-.37s.65.13.88.37c.23.24.37.56.37.89s-.13.66-.37.89c-.23.24-.55.37-.88.37s-.65-.13-.88-.37c-.23-.24-.37-.56-.37-.89Z"
                fill="#eae7e1"
            />
            <path
                d="m1.91,2.15h11.18s0,16.38,0,16.38H1.91V2.15Z"
                fill="#eae7e1"
            />
        </svg>
    );
}

SmartphoneIcon.defaultProps = {
    isActive: false,
};
