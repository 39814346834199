import React from "react";

import { Controller, useForm } from "react-hook-form";

import TypeAheadInput from "components/common/input/TypeAheadInput/TypeAheadInput";
import Button from "components/common/layout/Button/Button";
import Modal from "components/common/layout/Modal/Modal";
import {
    refetchFindBillingContactsQuery,
    useCreateBillingContactsMutation,
    useFindUsersQuery,
    UserFragment,
} from "graphql/management/model/apollo";
import EmailRegExp from "utils/email-utils/email-utils";

import styles from "./AddBillingContact.module.scss";

interface AddContactProps {
    onClose: () => void;
}

class BillingContactForm {
    email: string = "";
}

const AddBillingContact = (props: AddContactProps) => {
    const usersQuery = useFindUsersQuery();
    const [CreateBillingContactsMutation] = useCreateBillingContactsMutation();

    const { handleSubmit, control, clearErrors, resetField } =
        useForm<BillingContactForm>();

    const userEmails =
        usersQuery.data?.findUser.map((user: UserFragment) => user.email) ?? [];

    const createBillingContact = async (form: BillingContactForm) => {
        await CreateBillingContactsMutation({
            variables: {
                emails: [form.email],
            },
            refetchQueries: [refetchFindBillingContactsQuery()],
        });
        props.onClose();
    };

    return (
        <form onSubmit={handleSubmit(createBillingContact)}>
            <Modal
                title="Ajouter un contact de facturation"
                onClose={() => {
                    props.onClose();
                }}
            >
                <>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: "L'adresse e-mail est requise.",
                            pattern: {
                                value: EmailRegExp,
                                message:
                                    "Le format de l'adresse e-mail est invalide",
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TypeAheadInput
                                label="Saisir une adresse e-mail"
                                typeAheadValues={userEmails}
                                placeholder="laura.petit@cleaq.com"
                                fieldName={field.name}
                                fieldValue={field.value}
                                errorMessage={error?.message}
                                onChange={(event) => field.onChange(event)}
                                onReset={() => {
                                    resetField("email");
                                    clearErrors("email");
                                }}
                            />
                        )}
                    />
                    <div className={styles.footer}>
                        <Button
                            type="button"
                            onClick={props.onClose}
                        >
                            Annuler
                        </Button>
                        <Button
                            type="submit"
                            theme="secondary"
                        >
                            Valider
                        </Button>
                    </div>
                </>
            </Modal>
        </form>
    );
};

export default AddBillingContact;
