import React from "react";

import { FilterNames } from "components/business/catalog/filter/filters";
import SetCatalogFiltersAction from "components/provider/context/catalog-filters/actions/set-catalog-filters-action";
import {
    useCatalogFilters,
    useCatalogFiltersDispatch,
} from "components/provider/context/catalog-filters/catalog-filters-provider";

import "./filter-searchbar.scss";

const FilterSearchBar = () => {
    const activeFilters = useCatalogFilters();
    const catalogFiltersDispatch = useCatalogFiltersDispatch();

    return (
        <div className="searchbar">
            <input
                value={activeFilters.searchTerm}
                onChange={(e) => {
                    catalogFiltersDispatch(
                        new SetCatalogFiltersAction(
                            FilterNames.searchTerm,
                            e.target.value,
                        ),
                    );
                }}
                placeholder="Rechercher un produit"
                data-cy="searchbar-catalog"
            />
            {activeFilters.searchTerm && activeFilters.searchTerm?.length > 0 && (
                <i
                    aria-hidden="true"
                    onClick={() =>
                        catalogFiltersDispatch(
                            new SetCatalogFiltersAction(
                                FilterNames.searchTerm,
                                "",
                            ),
                        )
                    }
                    className="fa fa-times search-reset"
                    data-cy="search-reset"
                />
            )}
        </div>
    );
};

export default FilterSearchBar;
