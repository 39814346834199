import React from "react";

import { useHistory } from "react-router";

import CalendarIcon from "components/common/icons/CalendarIcon";
import ApiClientNames from "graphql/consts";
import {
    OrderStatus,
    UniqueShippingDateFragment,
    useFindOrderQuery,
} from "graphql/order/model/apollo";
import displayDate from "utils/date/display-date";

import "./product-by-shipping-date.scss";
import ProductByAddress from "./product-by-address";

interface ProductByShippingDateProps {
    crmId: string;
}

const ProductByShippingDate = (props: ProductByShippingDateProps) => {
    const displayOrder = useFindOrderQuery({
        context: { clientName: ApiClientNames.ORDER },
        variables: { crmId: props.crmId },
    });

    const history = useHistory();
    const Redirect = (redirect: any, search: any) => {
        history.push({
            pathname: redirect,
            state: {
                mailSearch: search,
                serialSearch: search,
            },
        });
    };

    if (!displayOrder.data?.findOrder) {
        Redirect(`${process.env.PUBLIC_URL}/not-found`, "");
        return <></>;
    }

    const orderStatus =
        displayOrder.data.findOrder?.status ?? OrderStatus.IN_PROGRESS;

    return (
        <div
            className="list-products-by-shipping-date"
            data-cy="list-products-by-shipping-date"
        >
            {!displayOrder.loading &&
                displayOrder.data.findOrder.uniqueShippingDates.map(
                    (
                        productsByDates: UniqueShippingDateFragment,
                        index: number,
                    ) =>
                        productsByDates.shippingDate === null ? (
                            <ProductByAddress
                                uniqueAddresses={
                                    productsByDates.uniqueAddresses
                                }
                                index={index}
                                orderStatus={orderStatus}
                            />
                        ) : (
                            <div key={productsByDates.shippingDate}>
                                <div className="shipping-date-title">
                                    <CalendarIcon
                                        key={`${productsByDates.shippingDate}-${
                                            index + 1
                                        }`}
                                    />
                                    <h6 data-cy="shipping-date">
                                        {displayDate(
                                            productsByDates.shippingDate,
                                        )}
                                    </h6>
                                </div>
                                <ProductByAddress
                                    uniqueAddresses={
                                        productsByDates.uniqueAddresses
                                    }
                                    index={index}
                                    orderStatus={orderStatus}
                                />
                            </div>
                        ),
                )}
        </div>
    );
};

export default ProductByShippingDate;
