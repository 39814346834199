import { CatalogIntegrationFragment } from "graphql/catalog/model/apollo";

function ensureNotNull<T>(item: T, message?: string): NonNullable<T> {
    if (item == null) {
        throw new Error(message ?? "item is null or undefined");
    }
    return item;
}

type IntegrationConfigurations = BravasConfiguration;

type BravasConfiguration = {
    fqdn: string;
};

class Integration {
    readonly displayName: string;
    readonly name: string;
    readonly logoUrl: string;
    readonly category: string;
    readonly shortDescription: string;
    readonly longDescription: any;
    readonly about?: any;
    readonly links: string[];
    readonly configuration: IntegrationConfigurations;

    constructor(
        displayName: string,
        name: string,
        logoUrl: string,
        category: string,
        shortDescription: string,
        longDescription: any,
        links: string[],
        configuration: IntegrationConfigurations,
        about?: any,
    ) {
        this.displayName = displayName;
        this.name = name;
        this.logoUrl = logoUrl;
        this.category = category;
        this.shortDescription = shortDescription;
        this.longDescription = longDescription;
        this.links = links;
        this.configuration = configuration;
        this.about = about;
    }

    static fromContentful(
        cfIntegration: CatalogIntegrationFragment,
    ): Integration | undefined {
        try {
            const displayName = ensureNotNull(
                cfIntegration.displayName,
                "Integration display name is null or undefined",
            );
            const name = ensureNotNull(
                cfIntegration.name,
                "Integration name is null or undefined",
            );
            const logoUrl = ensureNotNull(
                cfIntegration.logo?.url,
                "Integration logo URL is null or undefined",
            );
            const category = ensureNotNull(
                cfIntegration.category,
                "Integration category is null or undefined",
            );
            const shortDescription = ensureNotNull(
                cfIntegration.shortDescription,
                "Integration short description is null or undefined",
            );
            const links = ensureNotNull(
                cfIntegration.links,
                "Integration link list  is null or undefined",
            ).map((link) => ensureNotNull(link));

            return new Integration(
                displayName,
                name,
                logoUrl,
                category,
                shortDescription,
                cfIntegration.longDescription?.json,
                links,
                cfIntegration.configuration,
                cfIntegration.about?.json ?? undefined,
            );
        } catch (e) {}
    }
}

export default Integration;
