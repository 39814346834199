import React, { useState } from "react";

import { useKeycloak } from "@react-keycloak/web";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    currentUserFragment,
    refetchFindAdminQuery,
    refetchFindCurrentUserQuery,
    refetchFindDevicesQuery,
    refetchFindUsersQuery,
    useDeleteUserMutation,
    useFindAdminLazyQuery,
    UserOrganisationFragment,
    UserRole,
    useUpdateUserMutation,
} from "graphql/management/model/apollo";
import { logout } from "utils/authentication/authentication";

import AccountFormDetails from "./form-details/account-form-details";
import AccountFormEdit from "./form-edit/account-form-edit";
import AccountFormPassword from "./form-password/account-form-password";

import "./account-form.scss";

const CleaqSwal = withReactContent(Swal);

interface DataInterface {
    user: currentUserFragment;
}

export interface FormContent {
    phoneNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    organisations: any | null;
    fileUpload: FileList | null;
    administrateur: boolean;
}

const AccountForm = (props: DataInterface) => {
    const { user } = props;
    const [editable, setEditable] = useState(false);
    const [saving, setSaving] = useState(false);
    const { keycloak } = useKeycloak();

    const { reset } = useForm<FormContent>();

    const [executeAdminQuery] = useFindAdminLazyQuery();

    const [updateUser] = useUpdateUserMutation();
    const [deleteUserMutation] = useDeleteUserMutation();

    const cancelEdit = () => {
        reset();
        setEditable(false);
        setSaving(false);
    };

    const updateUserForm = async (dataPush: FormContent) => {
        setSaving(true);
        try {
            await updateUser({
                variables: {
                    email: user?.email!,
                    firstName: dataPush.firstName,
                    lastName: dataPush.lastName,
                    organisations: user?.organisations?.map(
                        (orgaItem: UserOrganisationFragment) => ({
                            id: orgaItem.id!,
                        }),
                    ),
                    phoneNumber: dataPush.phoneNumber,
                    role: user?.role || UserRole.USER,
                },
                refetchQueries: [
                    refetchFindDevicesQuery(),
                    refetchFindCurrentUserQuery(),
                    refetchFindUsersQuery(),
                    refetchFindAdminQuery(),
                ],
            });
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Contactez-nous dans le chat !",
                icon: "error",
            });
        }
        cancelEdit();
    };

    const deleteUser = async () => {
        const adminQuery = await executeAdminQuery();
        if (
            keycloak.hasRealmRole("OWNER") &&
            adminQuery.data?.findUser.length &&
            adminQuery.data?.findUser.length === 1
        ) {
            CleaqSwal.fire({
                title: "Suppression impossible !",
                html: "Vous êtes le seul administrateur de l'entreprise.\nSupprimer votre entreprise dans l'onglet \n \"Mon entreprise\"",
                icon: "error",
            });
        } else {
            CleaqSwal.fire({
                title: "Vous souhaitez supprimer votre compte utilisateur !",
                html: "Êtes-vous sûr de vouloir supprimer votre compte ?",
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
                cancelButtonText: "Annuler",
                denyButtonText: "Confirmer suppression",
            }).then((result) => {
                if (result.isDenied) {
                    deleteUserMutation({
                        variables: {
                            email: user?.email!,
                        },
                    })
                        .then((response: any) => {
                            CleaqSwal.fire({
                                title: "Confirmé !",
                                html: "L'utilisateur a été supprimé",
                                icon: "success",
                            }).then((result) => {
                                logout(keycloak);
                            });
                        })
                        .catch((error: any) => {
                            CleaqSwal.fire({
                                title: "Oops, une erreur s'est produite !",
                                html: "Contactez-nous dans le chat !",
                                icon: "error",
                            });
                        });
                }
            });
        }
    };

    return (
        <>
            {!editable ? (
                <AccountFormDetails
                    user={user}
                    setEditable={setEditable}
                />
            ) : (
                <AccountFormEdit
                    updateUserForm={updateUserForm}
                    user={user}
                    deleteUser={deleteUser}
                    saving={saving}
                    cancelEdit={cancelEdit}
                />
            )}

            <div className="nlt-ranked-device-sep" />
            <AccountFormPassword />
        </>
    );
};
export default AccountForm;
