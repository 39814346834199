import React from "react";

import clsx from "clsx";
import { UseFormReset } from "react-hook-form";
import { Col } from "reactstrap";

import { TagType } from "components/business/tag/types";
import CustomCheckbox from "components/common/input/custom-checkbox/custom-checkbox";
import { OrganisationFragment } from "graphql/management/model/apollo";

interface tagItemInterface {
    resetForm: UseFormReset<TagType>;
    tag: OrganisationFragment;
    selectedTag: OrganisationFragment | null | undefined;
    setSelectedTag: React.Dispatch<
        React.SetStateAction<OrganisationFragment | null | undefined>
    >;
    selectedTags: OrganisationFragment[];
    setSelectedTags: React.Dispatch<
        React.SetStateAction<OrganisationFragment[]>
    >;
    setIsCreateOrg: React.Dispatch<React.SetStateAction<boolean>>;
    cancelEdit: () => void;
}

const TagItem = (props: tagItemInterface) => {
    const OrganisationDetails = (organisation: OrganisationFragment) => {
        props.cancelEdit();
        props.resetForm({
            name: organisation.name,
            hexaColor: organisation.hexaColor,
        });
        props.setSelectedTag(organisation);
    };

    return (
        <li
            className={clsx("nav-link", {
                active: props.tag.id === props.selectedTag?.id,
            })}
        >
            <div
                aria-hidden="true"
                className="nlt-dev-4-sm"
                onClick={() =>
                    props.selectedTags.length === 0 &&
                    OrganisationDetails(props.tag)
                }
            >
                <div
                    className={
                        props.tag.id === props.selectedTag?.id
                            ? "nlt-list-item-selector-active"
                            : "nlt-list-item-selector"
                    }
                />
                <Col
                    xl={1}
                    md={1}
                    className="nlt-device-icon-fix"
                >
                    <div
                        className="color-pick-square-lg"
                        style={
                            props.selectedTags.includes(props.tag)
                                ? { display: "none" }
                                : {
                                      display: "flex",
                                      backgroundColor: props.tag.hexaColor!,
                                  }
                        }
                    />
                    <CustomCheckbox
                        className="nlt-wide-device-icon"
                        style={
                            props.selectedTags.includes(props.tag)
                                ? {
                                      display: "flex",
                                      width: "54px",
                                  }
                                : {
                                      display: "none",
                                      width: "54px",
                                  }
                        }
                        checked={props.selectedTags.includes(props.tag)}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                            props.setSelectedTag(null);
                            props.setIsCreateOrg(false);
                            if (props.selectedTags.includes(props.tag)) {
                                const newList = props.selectedTags.filter(
                                    (item) => item !== props.tag,
                                );
                                props.setSelectedTags(newList);
                            } else {
                                props.setSelectedTags([
                                    ...props.selectedTags,
                                    props.tag,
                                ]);
                            }
                        }}
                    />
                </Col>
                <Col
                    xl={3}
                    md={3}
                    className="flex-ai-c"
                >
                    <div className="media-body">
                        <h6>
                            <span className="first_name_0">
                                {props.tag.name}
                            </span>
                        </h6>
                    </div>
                </Col>
                <Col
                    xl={4}
                    md={4}
                >
                    <div>
                        <div className="nlt-device-item-title-info-list">
                            Membres
                        </div>
                    </div>
                    {props.tag?.users?.length &&
                    props.tag?.users?.length > 0 ? (
                        <div className="nlt-device-item-data-info">
                            {`${props.tag?.users?.length} membres`}
                        </div>
                    ) : (
                        <div className="nlt-device-item-data-info">
                            Aucun membre
                        </div>
                    )}
                </Col>
                <Col
                    xl={4}
                    md={4}
                >
                    <div>
                        <div className="nlt-device-item-title-info-list">
                            Appareils
                        </div>
                    </div>
                    {props.tag?.devices?.length &&
                    props.tag?.devices?.length > 0 ? (
                        <div className="nlt-device-item-data-info">
                            {`${props.tag?.devices?.length} appareils`}
                        </div>
                    ) : (
                        <div className="nlt-device-item-data-info">
                            Aucun appareil
                        </div>
                    )}
                </Col>
            </div>
        </li>
    );
};

export default TagItem;
