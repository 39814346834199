/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

import { useKeycloak } from "@react-keycloak/web";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    refetchComputeDashboardDataQuery,
    refetchFindAdminQuery,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    UserRole,
    useDeleteUserMutation,
    useFindAdminLazyQuery,
    UserFragment,
} from "graphql/management/model/apollo";
import { logout } from "utils/authentication/authentication";

interface DeleteMultipleUsersInterface {
    selectedUsers: UserFragment[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<UserFragment[]>>;
}

const CleaqSwal = withReactContent(Swal);

const DeleteMultipleUsers = (props: DeleteMultipleUsersInterface) => {
    const { keycloak } = useKeycloak();

    const [deleteUserMutation] = useDeleteUserMutation();

    const [executeAdminQuery] = useFindAdminLazyQuery();

    const deleteMultipleUser = async () => {
        const adminQuery = await executeAdminQuery();
        if (
            adminQuery?.data?.findUser.length &&
            adminQuery?.data?.findUser.length <=
                props.selectedUsers.filter(
                    (item) => item.role === UserRole.OWNER,
                ).length
        ) {
            CleaqSwal.fire({
                title: "Suppression impossible !",
                html:
                    "Il doit rester au moins un administrateur.\n" +
                    // eslint-disable-next-line quotes
                    'Supprimer votre entreprise dans l\'onglet \n "Mon entreprise"',
                icon: "error",
            });
        } else {
            CleaqSwal.fire({
                title: `Vous souhaitez supprimer ${props.selectedUsers.length} utilisateurs !`,
                html: "Les utilisateurs pourront toujours se connecter mais n'auront plus accès aux informations de votre entreprise !",
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
                cancelButtonText: "Annuler",
                denyButtonText: "Confirmer suppression",
            }).then(async (result) => {
                if (result.isDenied) {
                    let countDeleted = 0;
                    // eslint-disable-next-line no-restricted-syntax
                    for (const item of props.selectedUsers) {
                        // eslint-disable-next-line no-await-in-loop
                        await deleteUserMutation({
                            variables: {
                                email: item.email,
                            },
                            refetchQueries: [
                                refetchFindDevicesQuery(),
                                refetchFindOrganisationsQuery(),
                                refetchComputeDashboardDataQuery(),
                                refetchFindUsersQuery(),
                                refetchFindAdminQuery(),
                            ],
                            // eslint-disable-next-line no-loop-func
                            onCompleted: () => {
                                countDeleted += 1;
                            },
                        }).catch((error: any) => {
                            // eslint-disable-next-line no-console
                            console.log(error);
                        });
                    }

                    if (props.selectedUsers.length === countDeleted) {
                        await CleaqSwal.fire({
                            title: "Confirmé !",
                            html: "Les utilisateurs ont été supprimés",
                            icon: "success",
                        }).then(() => {
                            if (
                                props.selectedUsers
                                    .map((user) => user.email)
                                    .includes(keycloak.profile?.email ?? "")
                            ) {
                                logout(keycloak);
                            }
                        });
                    } else {
                        await CleaqSwal.fire({
                            title: "Oops, une erreur s'est produite !",
                            html: "Contactez-nous dans le chat !",
                            icon: "error",
                        });
                    }
                    props.setSelectedUsers([]);
                }
            });
        }
    };

    return (
        <div
            className="nlt-button-inside-delete"
            onClick={() => deleteMultipleUser()}
        >
            <i className="icofont icofont-bin" />
        </div>
    );
};

export default DeleteMultipleUsers;
