import type { TempCart } from "components/provider/context/temp-cart/types";
import { VariantFragment } from "graphql/catalog/model/apollo";

import TempCartAction from "./temp-cart-action";

class UpdateVariantTempCartAction implements TempCartAction {
    payload: {
        newVariant: VariantFragment;
    };

    constructor(newVariant: VariantFragment) {
        this.payload = {
            newVariant,
        };
    }

    apply(tempCart: TempCart) {
        return {
            ...tempCart,
            variant: this.payload.newVariant,
        };
    }
}

export default UpdateVariantTempCartAction;
