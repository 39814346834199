import React from "react";

import { withRouter } from "react-router-dom";
import { Row } from "reactstrap";

import IncidentList from "components/business/device/incident-list";
import Admins from "components/business/instance/admins/admin-list";
import Company from "components/business/instance/company/company";
import SkeletonCompany from "components/business/instance/company/skeleton-company/skeleton-company";
import ShippingAddress from "components/business/instance/shipping-address/shipping-address";
import SmallScreen from "components/common/layout/banner/small-screen";
import {
    InstanceFragment,
    useFindAdminQuery,
    useFindInstanceQuery,
} from "graphql/management/model/apollo";

import "./instance.scss";

export function generateFileCode(
    suffix: string | undefined,
    instance: InstanceFragment | null | undefined,
): string {
    return `${(instance?.name || "") + (instance?.shareId || "")}/${suffix}`;
}

const Instance = () => {
    const instanceResponse = useFindInstanceQuery();
    const adminResponse = useFindAdminQuery();

    return (
        <>
            <div
                className="nlt-container pad-20"
                id="instance"
            >
                <div className="nlt-container-scale bookmark-wrap">
                    <Row className="size-column mb-28">
                        <div className="col-md-8">
                            <div className="custom-card col-12">
                                {instanceResponse.loading ||
                                instanceResponse.data?.currentInstance ==
                                    null ? (
                                    <SkeletonCompany />
                                ) : (
                                    <Company
                                        instance={
                                            instanceResponse.data
                                                .currentInstance
                                        }
                                    />
                                )}
                            </div>
                        </div>

                        <Admins
                            admins={adminResponse.data?.findUser}
                            loading={adminResponse.loading}
                        />
                    </Row>
                    <Row className="size-column second-row">
                        <div className="col-md-8">
                            <div className="custom-card col-12">
                                <IncidentList />
                            </div>
                        </div>
                        <ShippingAddress
                            instance={instanceResponse.data?.currentInstance}
                            loading={instanceResponse.loading}
                        />
                    </Row>
                </div>
            </div>
            <SmallScreen />
        </>
    );
};

export default withRouter(Instance);
