import React from "react";

import FilterSearchBar from "./filter-searchbar/filter-searchbar";
import FiltersProductType from "./filters-product-type/filters-product-type";

import "./filter-navbar.scss";

interface FiltersNavbarProps {
    setResetCatalogDisplay: React.Dispatch<React.SetStateAction<boolean>>;
    isFiltersProductTypeVisible: boolean;
}

const FiltersNavbar = (props: FiltersNavbarProps) => (
    <div className="filters-navbar">
        {props.isFiltersProductTypeVisible && (
            <FiltersProductType
                setResetCatalogDisplay={props.setResetCatalogDisplay}
            />
        )}
        <FilterSearchBar />
    </div>
);

export default FiltersNavbar;
