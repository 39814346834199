import React from "react";

import { FormControlLabel, FormGroup } from "@material-ui/core";

import { FilterNames } from "components/business/catalog/filter/filters";
import CustomCheckbox from "components/common/input/custom-checkbox/custom-checkbox";
import SetCatalogFiltersAction from "components/provider/context/catalog-filters/actions/set-catalog-filters-action";
import { useCatalogFiltersDispatch } from "components/provider/context/catalog-filters/catalog-filters-provider";
import translator from "utils/translator/translator";

export interface CheckboxFilterProps {
    type: FilterNames;
    values: string[] | number[];
    items: string[] | number[] | null[];
    unit: string | null;
}

const CheckboxFilter = (props: CheckboxFilterProps) => {
    const catalogFiltersDispatch = useCatalogFiltersDispatch();

    const getFilterLabel = () => {
        switch (props.type) {
            case FilterNames.os:
                return "OS d'appareil";
            case FilterNames.ram:
                return "Mémoire";
            case FilterNames.storage:
                return "Stockage";
            case FilterNames.processor:
                return "Processeur";
            case FilterNames.screen:
                return "Taille d'écran";
            default:
                return "";
        }
    };

    return (
        <div className="filter">
            <div
                aria-hidden="true"
                className="filter-title"
            >
                <h6>{getFilterLabel()}</h6>
            </div>

            <div className="filter-options">
                <FormGroup>
                    {props.items.length > 0 &&
                        props.items.map(
                            (filterName) =>
                                filterName && (
                                    <FormControlLabel
                                        key={filterName}
                                        control={
                                            <CustomCheckbox
                                                onChange={(event) => {
                                                    catalogFiltersDispatch(
                                                        new SetCatalogFiltersAction(
                                                            props.type,
                                                            event.target.value,
                                                        ),
                                                    );
                                                }}
                                                value={filterName}
                                                size="small"
                                                checked={new Array(
                                                    ...props.values,
                                                ).includes(filterName)}
                                            />
                                        }
                                        label={`${translator.translate(
                                            filterName.toString(),
                                        )}${props.unit}`}
                                    />
                                ),
                        )}
                </FormGroup>
            </div>
        </div>
    );
};

export default CheckboxFilter;
