import React from "react";

import { useHistory } from "react-router";

interface AdminItemProps {
    firstName: string;
    lastName: string;
    mail: string;
}

const AdminList = (props: AdminItemProps) => {
    const history = useHistory();
    const { firstName, lastName, mail } = props;

    const redirectToUser = (email: string) => {
        history.push({
            pathname: `${process.env.PUBLIC_URL}/users`,
            state: { mailSearch: email },
        });
    };

    return (
        <div className="nlt-admin-container">
            <div className="nlt-admin">
                <div className="nlt-admin-name">
                    {firstName} {lastName}
                </div>
                <div className="nlt-admin-mail">{mail}</div>
            </div>
            <div className="text-right">
                <div
                    aria-hidden="true"
                    className="nlt-admin-button"
                    onClick={() => redirectToUser(mail)}
                >
                    Voir
                </div>
            </div>
        </div>
    );
};

export default AdminList;
