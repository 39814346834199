import React, { useEffect, useState } from "react";

import { v4 as uuid } from "uuid";

import LostCleaqeeImg from "components/common/images/lost-cleaqee.svg";
import PageError from "components/common/layout/error/page-error/page-error";
import ClearCatalogFiltersAction from "components/provider/context/catalog-filters/actions/clear-catalog-filters-actions";
import {
    useCatalogFilters,
    useCatalogFiltersDispatch,
} from "components/provider/context/catalog-filters/catalog-filters-provider";
import {
    ProductFilter,
    ProductFragment,
    useFindAudioAccessoriesLazyQuery,
    useFindDockstationsLazyQuery,
    useFindKeyboardsLazyQuery,
    useFindMousesLazyQuery,
    useFindProductsLazyQuery,
    useFindScreensLazyQuery,
    useFindVariantsLazyQuery,
    VariantFragment,
} from "graphql/catalog/model/apollo";
import ApiClientNames from "graphql/consts";
import { InstanceFragment } from "graphql/management/model/apollo";

import CatalogItems from "./catalog-items/catalog-items";
import FilterValues from "./filter/filter-values";
import FiltersSidebar from "./filter/filters-sidebar/filters-sidebar";
import SkeletonFiltersSidebar from "./filter/filters-sidebar/skeleton-filters-sidebar/skeleton-filters-sidebar";
import SkeletonCatalogItem from "./product/skeleton-catalog";
import { AccessoryType } from "./utils/types";

import "./catalog.scss";

interface CatalogProps {
    instance: InstanceFragment;
    resetCatalogDisplay: boolean;
    setResetCatalogDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}

const Catalog = (props: CatalogProps) => {
    const [variants, setVariants] = useState<Map<string, VariantFragment[]>>();
    const [currentCatalogItems, setCurrentCatalogItems] = useState<
        (AccessoryType | ProductFragment)[]
    >([]);

    const updateCurrentCatalogItems = (
        items: (AccessoryType | ProductFragment | null)[],
    ) => {
        const itemsNotNull = items.flatMap((item) => (item ? [item] : []));
        setCurrentCatalogItems(itemsNotNull);
    };

    const [productQuery] = useFindProductsLazyQuery({
        fetchPolicy: "network-only",
        context: { clientName: ApiClientNames.CATALOG },
        onCompleted: (data) => {
            updateCurrentCatalogItems([
                ...(data.productCollection?.items ?? []),
            ]);
        },
    });

    const [keyboardQuery] = useFindKeyboardsLazyQuery({
        fetchPolicy: "network-only",
        context: { clientName: ApiClientNames.CATALOG },
        onCompleted: (data) => {
            updateCurrentCatalogItems([
                ...(data.keyboardCollection?.items ?? []),
            ]);
        },
    });

    const [mouseQuery] = useFindMousesLazyQuery({
        fetchPolicy: "network-only",
        context: { clientName: ApiClientNames.CATALOG },
        onCompleted: (data) => {
            updateCurrentCatalogItems([...(data.mouseCollection?.items ?? [])]);
        },
    });

    const [screenQuery] = useFindScreensLazyQuery({
        fetchPolicy: "network-only",
        context: { clientName: ApiClientNames.CATALOG },
        onCompleted: (data) => {
            updateCurrentCatalogItems([
                ...(data.screenCollection?.items ?? []),
            ]);
        },
    });

    const [dockstationQuery] = useFindDockstationsLazyQuery({
        fetchPolicy: "network-only",
        context: { clientName: ApiClientNames.CATALOG },
        onCompleted: (data) => {
            updateCurrentCatalogItems([
                ...(data.dockstationCollection?.items ?? []),
            ]);
        },
    });

    const [audioQuery] = useFindAudioAccessoriesLazyQuery({
        fetchPolicy: "network-only",
        context: { clientName: ApiClientNames.CATALOG },
        onCompleted: (data) => {
            updateCurrentCatalogItems([...(data.audioCollection?.items ?? [])]);
        },
    });

    const [filterLabels, setFilterLabels] = useState<FilterValues>(
        new FilterValues([], [], [], [], [], []),
    );

    const createFilter = (variants: any[]) =>
        variants.length > 0 ? Array.from(new Set(variants)) : [];

    const notNull = (value: any) => value !== null && value !== undefined;

    const activeFilters = useCatalogFilters();
    const catalogFiltersDispatch = useCatalogFiltersDispatch();

    const [variantQuery] = useFindVariantsLazyQuery({
        fetchPolicy: "network-only",
        context: { clientName: ApiClientNames.CATALOG },
        onCompleted: (data) => {
            setVariants(
                data?.variantCollection?.items.reduce((result, element) => {
                    if (element?.product?.sys?.id) {
                        result.set(element.product.sys.id, [
                            ...(result.get(element.product.sys.id) || []),
                            element,
                        ]);
                    }
                    return result;
                }, new Map()),
            );
        },
    });

    const getBudgetInterval = () => {
        const budgetInterval = [];
        const priceLaptopAndDesktop = [999999, 0];
        const priceSmartphoneAndTablet = [999999, 0];

        const array = Array.from(variants!!.values()).flat();
        array.forEach((variant) => {
            if (variant.product!.type === "smartphone") {
                priceSmartphoneAndTablet[0] =
                    priceSmartphoneAndTablet[0] > variant.price24!
                        ? variant.price24!
                        : priceSmartphoneAndTablet[0];
                priceSmartphoneAndTablet[1] =
                    priceSmartphoneAndTablet[1] < variant.price24!
                        ? variant.price24!
                        : priceSmartphoneAndTablet[1];
            } else {
                priceLaptopAndDesktop[0] =
                    priceLaptopAndDesktop[0] > variant.price36!
                        ? variant.price36!
                        : priceLaptopAndDesktop[0];
                priceLaptopAndDesktop[1] =
                    priceLaptopAndDesktop[1] < variant.price36!
                        ? variant.price36!
                        : priceLaptopAndDesktop[1];
            }
        });

        if (!activeFilters.type.includes("smartphone")) {
            for (let i = 0; i * 50 < priceLaptopAndDesktop[1]; i += 1) {
                if (i === 0) {
                    budgetInterval.push([priceLaptopAndDesktop[0], 50]);
                } else if (i * 50 + 50 < priceLaptopAndDesktop[1]) {
                    if (priceLaptopAndDesktop[1] - (i * 50 + 50) < 10) {
                        budgetInterval.push([i * 50, priceLaptopAndDesktop[1]]);
                        break;
                    }

                    budgetInterval.push([i * 50, i * 50 + 50]);
                } else {
                    budgetInterval.push([i * 50, priceLaptopAndDesktop[1]]);
                    break;
                }
            }
        } else {
            for (let i = 0; i * 20 < priceSmartphoneAndTablet[1]; i += 1) {
                if (i === 0) {
                    budgetInterval.push([priceSmartphoneAndTablet[0], 20]);
                } else if (i * 20 + 20 < priceSmartphoneAndTablet[1]) {
                    if (priceSmartphoneAndTablet[1] - (i * 20 + 20) < 10) {
                        budgetInterval.push([
                            i * 20,
                            priceSmartphoneAndTablet[1],
                        ]);
                        break;
                    }
                    budgetInterval.push([i * 20, i * 20 + 20]);
                } else {
                    budgetInterval.push([i * 20, priceSmartphoneAndTablet[1]]);
                    break;
                }
            }
        }

        return budgetInterval;
    };

    const loadFilters = () => {
        const array = Array.from(variants?.values() ?? []).flat();
        setFilterLabels(
            new FilterValues(
                createFilter(
                    array
                        .filter(notNull)
                        .map(
                            (variant) =>
                                variant.product?.screenSizeValue?.toString()!!,
                        ),
                ),
                createFilter(
                    array
                        .filter(notNull)
                        .map((variant) => variant.processorValue?.toString()!!),
                ),
                createFilter(
                    array
                        .filter(notNull)
                        .map((variant) => variant.storageValue?.toString()!!),
                ),
                createFilter(
                    array
                        .filter(notNull)
                        .map((variant) => variant.ramValue?.toString()!!),
                ),
                createFilter(
                    array
                        .filter(notNull)
                        .map((variant) => variant.product?.os!!.toString()!!),
                ),
                createFilter(getBudgetInterval()),
            ),
        );
    };

    const refetchWithFilters = async () => {
        if (activeFilters.type.includes("keyboard")) {
            await keyboardQuery({
                variables: {
                    whereKeyboards: {
                        name_contains:
                            activeFilters.searchTerm.length > 0
                                ? activeFilters.searchTerm
                                : null,
                    },
                },
            });
            props.setResetCatalogDisplay(false);
        } else if (activeFilters.type.includes("mouse")) {
            await mouseQuery({
                variables: {
                    whereMouse: {
                        name_contains:
                            activeFilters.searchTerm.length > 0
                                ? activeFilters.searchTerm
                                : null,
                    },
                },
            });
            props.setResetCatalogDisplay(false);
        } else if (activeFilters.type.includes("screen")) {
            await screenQuery({
                variables: {
                    whereScreen: {
                        name_contains:
                            activeFilters.searchTerm.length > 0
                                ? activeFilters.searchTerm
                                : null,
                    },
                },
            });
            props.setResetCatalogDisplay(false);
        } else if (activeFilters.type.includes("dockstation")) {
            await dockstationQuery({
                variables: {
                    whereDockstations: {
                        name_contains:
                            activeFilters.searchTerm.length > 0
                                ? activeFilters.searchTerm
                                : null,
                    },
                },
            });
            props.setResetCatalogDisplay(false);
        } else if (activeFilters.type.includes("audio")) {
            await audioQuery({
                variables: {
                    whereAudioAccessories: {
                        name_contains:
                            activeFilters.searchTerm.length > 0
                                ? activeFilters.searchTerm
                                : null,
                    },
                },
            });
            props.setResetCatalogDisplay(false);
        } else {
            const productFilters = (): ProductFilter => {
                const filters: ProductFilter = {
                    name_contains:
                        activeFilters.searchTerm.length > 0
                            ? activeFilters.searchTerm
                            : null,
                };
                const catalogs = props.instance.affectedCatalogs;

                if (props.instance.affectedCatalogs.includes("mcdo")) {
                    filters.os_in =
                        activeFilters.os.length > 0 ? activeFilters.os : null;
                    filters.catalog_contains_all = catalogs.filter(
                        (catalog) => catalog !== "general",
                    );
                } else {
                    filters.os_in =
                        activeFilters.os.length > 0 ? activeFilters.os : null;
                    filters.type_in =
                        activeFilters.type.length > 0
                            ? activeFilters.type
                            : null;
                    filters.screenSizeValue_in =
                        activeFilters.screen.length > 0
                            ? activeFilters.screen.map((e) => parseFloat(e))
                            : null;

                    const uniqueCatalogs = new Set(catalogs.concat("general"));
                    filters.catalog_contains_some = Array.from(
                        uniqueCatalogs.values(),
                    );
                }
                return filters;
            };

            const result = await productQuery({
                variables: {
                    whereProduct: productFilters(),
                },
            });

            await variantQuery({
                variables: {
                    whereVariant: {
                        ramValue_in:
                            activeFilters.ram.length > 0
                                ? activeFilters.ram.map((e) => parseFloat(e))
                                : null,
                        storageValue_in:
                            activeFilters.storage.length > 0
                                ? activeFilters.storage.map((e) =>
                                      parseFloat(e),
                                  )
                                : null,
                        processorValue_in:
                            activeFilters.processor.length > 0
                                ? activeFilters.processor
                                : null,
                        OR:
                            activeFilters.budget.length > 0
                                ? activeFilters.budget.map((interval) => {
                                      if (
                                          activeFilters.type.includes(
                                              "smartphone",
                                          )
                                      ) {
                                          return {
                                              price24_gte: interval[0]
                                                  ? interval[0]
                                                  : null,
                                              price24_lte: interval[1]
                                                  ? interval[1]
                                                  : null,
                                          };
                                      }
                                      return {
                                          price36_gte: interval[0]
                                              ? interval[0]
                                              : null,
                                          price36_lte: interval[1]
                                              ? interval[1]
                                              : null,
                                      };
                                  })
                                : null,
                        product: {
                            sys: {
                                id_in:
                                    result?.data?.productCollection?.items?.map(
                                        (element) => element!!.sys.id,
                                    ) || [],
                            },
                        },
                    },
                },
            });
        }
    };

    const initFilterCallback = async () => {
        if (
            variants &&
            Array.from(variants).length > 0 &&
            props.resetCatalogDisplay
        ) {
            loadFilters();
            props.setResetCatalogDisplay(false);
        }
    };

    const isCatalogFilterTypeAccessory = () =>
        activeFilters.type.includes("keyboard") ||
        activeFilters.type.includes("mouse") ||
        activeFilters.type.includes("screen") ||
        activeFilters.type.includes("dockstation") ||
        activeFilters.type.includes("audio");

    const isCatalogFilterDisabled = () =>
        props.instance.affectedCatalogs.includes("mcdo") ||
        isCatalogFilterTypeAccessory();

    useEffect(() => {
        if (activeFilters !== undefined && activeFilters !== null) {
            refetchWithFilters();
        } else {
            catalogFiltersDispatch(new ClearCatalogFiltersAction());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters]);

    useEffect(
        (initFilter = initFilterCallback) => {
            initFilter();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [variants],
    );

    const hasAccessory =
        currentCatalogItems.length > 0 && isCatalogFilterTypeAccessory();
    const hasProduct =
        variants &&
        Array.from(variants).length > 0 &&
        !isCatalogFilterTypeAccessory();

    return (
        <div className="catalog">
            {props.resetCatalogDisplay ? (
                <>
                    {!isCatalogFilterDisabled() && <SkeletonFiltersSidebar />}
                    <div className="catalog-container">
                        {[...Array(15)].map(() => (
                            <SkeletonCatalogItem key={uuid()} />
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <FiltersSidebar
                        filterLabels={filterLabels}
                        isCatalogFilterDisabled={isCatalogFilterDisabled()}
                    />
                    {hasAccessory || hasProduct ? (
                        <div className="catalog-container">
                            <CatalogItems
                                instanceId={props.instance.id}
                                products={currentCatalogItems}
                                variants={variants ?? null}
                                isCatalogFilterTypeAccessory={
                                    isCatalogFilterTypeAccessory
                                }
                            />
                        </div>
                    ) : (
                        <PageError
                            image={{
                                src: LostCleaqeeImg,
                                alt: "Image d'un robot cherchant les produits",
                            }}
                            title="Aucun produit trouvé"
                        >
                            <p className="catalog-error-message">
                                Votre recherche ne correspond à aucun produit.
                                Veuillez réessayer.
                            </p>
                        </PageError>
                    )}
                </>
            )}
        </div>
    );
};

export default Catalog;
