import React from "react";

import { CircularProgress } from "@material-ui/core";
import { friendlyFormatIBAN, isValidBIC, isValidIBAN } from "ibantools";
import { Controller, useForm } from "react-hook-form";

import Input from "components/common/input/Input/Input";
import Button from "components/common/layout/Button/Button";
import CardWithTitle from "components/common/layout/CardWithTitle/CardWithTitle";
import {
    BankDetailsFragment,
    refetchFindBankDetailsQuery,
    useUpdateBankDetailsMutation,
} from "graphql/management/model/apollo";
import displayAlertMessage from "utils/displayAlertMessage/displayAlertMessage";
import { trimWhiteSpace } from "utils/string/customTrim";

import styles from "./BankDetailsForm.module.scss";

class BankDetailsFormFields {
    iban: string = "";

    bic: string = "";
}

interface BankDetailsFormProps {
    defaultBankDetails: BankDetailsFragment;
}

const BankDetailsForm = (props: BankDetailsFormProps) => {
    const defaultBankDetails = {
        iban: friendlyFormatIBAN(props.defaultBankDetails.iban ?? "") ?? "",
        bic: props.defaultBankDetails.bic ?? "",
    };

    const ibanToolTipText =
        "L'IBAN permet d'identifier votre compte bancaire. Composé de 27 caractères pour les comptes français, vous pouvez le retrouver sur votre RIB.";

    const bicToolTipText =
        "Le code BIC permet d'identifier votre banque. Il figure sur votre relevé d'identité bancaire à côté ou en dessous de l'IBAN.";

    const {
        formState: { errors },
        handleSubmit,
        control,
        watch,
        setValue,
        clearErrors,
    } = useForm<BankDetailsFormFields>({
        mode: "onChange",
        defaultValues: {
            iban: defaultBankDetails.iban,
            bic: defaultBankDetails.bic,
        },
    });

    const [updateBankDetails, updateBankDetailsStatus] =
        useUpdateBankDetailsMutation();

    const watchIban = watch("iban");
    const watchBic = watch("bic");

    const submitBankDetails = async (form: BankDetailsFormFields) => {
        const IBAN = form.iban.replace(/ /g, "");
        const BIC = form.bic.replace(/ /g, "");

        await updateBankDetails({
            variables: {
                iban: IBAN,
                bic: BIC,
            },
            onCompleted: () => {
                displayAlertMessage(
                    "Les informations bancaires ont bien été mises à jour.",
                    "success",
                );
            },
            onError: () => {
                displayAlertMessage(
                    "Les informations bancaires n'ont pas pu être mises à jour.",
                    "error",
                );
            },
            refetchQueries: [refetchFindBankDetailsQuery()],
        });
    };

    const handleCancelButton = () => {
        setValue("iban", defaultBankDetails.iban);
        setValue("bic", defaultBankDetails.bic);
        clearErrors();
    };

    const isButtonGroupDisplay = () =>
        trimWhiteSpace(defaultBankDetails.iban) !== trimWhiteSpace(watchIban) ||
        trimWhiteSpace(defaultBankDetails.bic) !== trimWhiteSpace(watchBic);

    const ibanErrorMessage = ((): string | undefined => {
        if (errors.iban) {
            switch (errors.iban.type) {
                case "ibanValidation":
                    return "L'IBAN est invalide";
                default:
                    return errors.iban.message;
            }
        }
        return undefined;
    })();

    const bicErrorMessage = ((): string | undefined => {
        if (errors.bic) {
            switch (errors.bic.type) {
                case "bicValidation":
                    return "Le format du BIC est invalide";
                default:
                    return errors.bic.message;
            }
        }
        return undefined;
    })();

    return (
        <div>
            <CardWithTitle
                title="RIB"
                description="Votre compte bancaire sera débité à réception de votre matériel."
            >
                <form
                    onSubmit={handleSubmit(submitBankDetails)}
                    data-cy="bank-details-form"
                >
                    <Controller
                        name="iban"
                        control={control}
                        rules={{
                            required: "L'IBAN est requis.",
                            validate: {
                                ibanValidation: (v) =>
                                    isValidIBAN(v.replace(/ /g, "")),
                            },
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                value={friendlyFormatIBAN(field.value) ?? ""}
                                data-cy="iban-input"
                                title="IBAN"
                                placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX"
                                errorMessage={ibanErrorMessage}
                                isValid={
                                    watchIban.length > 0 &&
                                    errors.iban === undefined
                                }
                                tooltipText={ibanToolTipText}
                            />
                        )}
                    />
                    <Controller
                        name="bic"
                        control={control}
                        rules={{
                            required: "Le BIC est requis.",
                            validate: {
                                bicValidation: (v) => isValidBIC(v),
                            },
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                data-cy="bic-input"
                                title="BIC"
                                placeholder="CXXXXXXX"
                                errorMessage={bicErrorMessage}
                                isValid={
                                    watchBic.length > 0 &&
                                    errors.bic === undefined
                                }
                                tooltipText={bicToolTipText}
                            />
                        )}
                    />
                    <div className={styles.btnGroup}>
                        {isButtonGroupDisplay() && (
                            <>
                                <Button
                                    onClick={handleCancelButton}
                                    type="reset"
                                >
                                    Annuler
                                </Button>
                                {updateBankDetailsStatus.loading ? (
                                    <Button
                                        disabled
                                        theme="disabledButton"
                                    >
                                        <div className={styles.inProgress}>
                                            <CircularProgress
                                                size={24}
                                                thickness={4}
                                            />{" "}
                                            Validation en cours
                                        </div>
                                    </Button>
                                ) : (
                                    <Button
                                        data-cy="bank-details-submit"
                                        disabled={
                                            errors.iban !== undefined ||
                                            errors.bic?.message !== undefined
                                        }
                                        theme="primary"
                                    >
                                        Enregistrer
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                </form>
            </CardWithTitle>
        </div>
    );
};

export default BankDetailsForm;
