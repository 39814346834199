import { useEffect, useState } from "react";

import UNREAD_CONVERSATION_COUNT from "globalVars";

interface LocalUnreadConversation {
    localUnreadConversation: number;
    updateLocalUnreadConversation: (unreadCount: number) => void;
}

export default function useLocalUnreadConversation(): LocalUnreadConversation {
    const [localUnreadConversation, setlocalUnreadConversation] =
        useState<number>(0);

    const initialize = () => {
        try {
            const item = window.localStorage.getItem(UNREAD_CONVERSATION_COUNT);
            return item ? JSON.parse(item) : 0;
        } catch (error) {
            return 0;
        }
    };

    useEffect(() => {
        setlocalUnreadConversation(initialize());
    }, []);

    const updateLocalUnreadConversation = (unreadCount: number) => {
        localStorage.setItem(
            UNREAD_CONVERSATION_COUNT,
            JSON.stringify(unreadCount),
        );
        setlocalUnreadConversation(unreadCount);
    };

    return {
        localUnreadConversation,
        updateLocalUnreadConversation,
    };
}
