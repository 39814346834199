export default function BillingIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.3859 16.5C11.1972 16.5011 10.031 16.1751 9.01508 15.5578C7.99916 14.9405 7.17263 14.0556 6.62594 13H11.7659C12.1459 13 12.4959 12.79 12.6559 12.45C12.9859 11.79 12.5059 11 11.7659 11H5.96594C5.91594 10.67 5.88594 10.34 5.88594 10C5.88594 9.66 5.91594 9.33 5.96594 9H11.7659C12.1459 9 12.4959 8.79 12.6559 8.45C12.7344 8.29797 12.7724 8.12825 12.766 7.95727C12.7597 7.78628 12.7094 7.61982 12.6199 7.474C12.5304 7.32818 12.4047 7.20793 12.2551 7.12489C12.1055 7.04185 11.937 6.99883 11.7659 7H6.62594C7.1736 5.94518 8.00035 5.06098 9.01606 4.4438C10.0318 3.82661 11.1974 3.50015 12.3859 3.5C13.6359 3.5 14.8059 3.86 15.8059 4.47C16.3059 4.78 16.9559 4.73 17.3759 4.31C17.9559 3.73 17.8259 2.78 17.1259 2.35C15.7016 1.46938 14.0605 1.00199 12.3859 1C8.46594 1 5.14594 3.51 3.90594 7H1.00594C0.625938 7 0.275938 7.21 0.105938 7.55C-0.224062 8.22 0.255938 9 1.00594 9H3.44594C3.36495 9.66421 3.36495 10.3358 3.44594 11H1.00594C0.625938 11 0.275938 11.21 0.115938 11.55C-0.224062 12.22 0.255938 13 1.00594 13H3.90594C5.14594 16.49 8.46594 19 12.3859 19C14.1259 19 15.7459 18.51 17.1259 17.65C17.8159 17.22 17.9459 16.26 17.3659 15.68C16.9459 15.26 16.2959 15.21 15.7959 15.53C14.8059 16.15 13.6459 16.5 12.3859 16.5Z"
                fill="#353535"
            />
        </svg>
    );
}
