import React, { useState } from "react";

import moment from "moment";
import { Row } from "reactstrap";

import SirenForm from "components/business/order/step-form/Signatory/SirenForm/SirenForm";
import Dialog from "components/common/layout/Dialog/Dialog";
import { InstanceFragment } from "graphql/management/model/apollo";

import styles from "./company.module.scss";
import CompanyDetailsForm from "./details/company-details-form";
import CompanyDetailsView from "./details/company-details-view";

interface CompanyProps {
    instance: InstanceFragment;
}

export default function Company({ instance }: CompanyProps) {
    const [editable, setEditable] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>
            <div className="nlt-card-header mb-3">
                <Row>
                    <div className="col-md-6 pull-left d-flex">
                        <div className="icon-company">
                            <i className="icofont icofont-building-alt" />
                        </div>
                        <div className="nlt-card-title-company">
                            {instance?.name}
                        </div>
                    </div>
                    <div className="col-md-6 pull-right">
                        <div className="d-flex pull-right">
                            <div
                                aria-hidden="true"
                                className="nlt-button-inside-company pull-right"
                                onClick={() => setOpenDialog(true)}
                                hidden={editable}
                                data-cy="company-edit"
                            >
                                <i className="icofont icofont-pencil-alt-2 mr-2" />
                                Modification{" "}
                                <span className={styles.uppercase}>siren</span>
                            </div>
                            <div
                                aria-hidden="true"
                                className="nlt-button-inside-company pull-right"
                                onClick={() => setEditable(true)}
                                hidden={editable}
                                data-cy="company-edit"
                            >
                                <i className="icofont icofont-pencil-alt-2 mr-2" />
                                Editer
                            </div>
                        </div>
                        <div className="nlt-card-title-buttons pull-right">
                            <button
                                type="submit"
                                form="company-form"
                                className="nlt-button-inside-form-save"
                                hidden={!editable}
                                data-cy="company-save"
                            >
                                Enregistrer
                            </button>
                            <div
                                aria-hidden="true"
                                className="nlt-button-inside-form-cancel"
                                onClick={() => {
                                    setEditable(false);
                                }}
                                hidden={!editable}
                            >
                                Annuler
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
            <div className="nlt-company-container">
                <div className="list-persons ">
                    <div>
                        {editable && instance ? (
                            <CompanyDetailsForm
                                instance={instance}
                                setEditable={setEditable}
                            />
                        ) : (
                            <>
                                <CompanyDetailsView instance={instance} />
                                <div className="email-general instance-links">
                                    <div>
                                        <a
                                            href={`http://files.cleaq.com/cg/CGS_SaaS-Cleaq.pdf?date=${moment().format(
                                                "YYYY-MM-DD",
                                            )}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Voir les conditions générales
                                            d'utilisation de l'App Cleaq
                                        </a>
                                    </div>
                                    <div>
                                        <a
                                            href="https://cleaq.notion.site/cleaq/Conditions-G-n-rales-de-Location-de-Fourniture-et-de-Services-10ca3bb6867e407d8249d21095af3f88"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Voir les conditions générales de mes
                                            contrats de location
                                        </a>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Dialog
                open={openDialog}
                title="Demande de modification du SIREN"
                onClose={() => {
                    setOpenDialog(false);
                }}
            >
                <div className={styles.dialog}>
                    <SirenForm siren={instance.siren ?? ""} />
                </div>
            </Dialog>
        </>
    );
}
