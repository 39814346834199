import React from "react";

import { withRouter } from "react-router-dom";

import SmallScreen from "components/common/layout/banner/small-screen";
import FileManager from "components/common/layout/file-manager/file-manager";
import {
    FileType,
    useFindInstanceQuery,
} from "graphql/management/model/apollo";

import "./documents.scss";

const Documents = () => {
    const instanceQuery = useFindInstanceQuery();

    const documents = instanceQuery?.data?.currentInstance?.files?.filter(
        (file) =>
            file.fileType !== FileType.BILLING &&
            file.fileType !== FileType.SUBSCRIPTION_INVOICE,
    );

    return (
        <>
            <div className="nlt-container document-page">
                <div className="files-container-row">
                    <FileManager
                        files={documents}
                        loading={instanceQuery.loading}
                    />
                </div>
            </div>
            <SmallScreen />
        </>
    );
};

export default withRouter(Documents);
