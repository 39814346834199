import React from "react";

import { useKeycloak } from "@react-keycloak/web";
import { useHistory } from "react-router-dom";

import RightChevronIcon from "components/common/icons/RightChevronIcon";
import SearchBar from "components/common/layout/searchbar/searchbar";
import { useFindPageContentQuery } from "graphql/catalog/model/apollo";
import ApiClientNames from "graphql/consts";

import styles from "./home.module.scss";

interface HelpUrl {
    question: string;
    URL: string;
}

const Home = () => {
    const history = useHistory();
    let pathname = history.location.pathname;

    // Only get the first part of the pathname
    // ie /order/catalog/laptop -> /order
    pathname = "/" + pathname.split("/")[1];

    const { keycloak } = useKeycloak();
    const userFirstName: string = keycloak?.tokenParsed?.["given_name"];

    const { data: currentPageContent } = useFindPageContentQuery({
        context: { clientName: ApiClientNames.CATALOG },
        variables: { wherePageContent: { slug: pathname } },
    });

    const { data: defaultContent } = useFindPageContentQuery({
        context: { clientName: ApiClientNames.CATALOG },
        variables: { wherePageContent: { slug: "/dashboard" } },
    });

    const pageContents = currentPageContent?.pageContentCollection?.items ?? [];
    const defaultContents = defaultContent?.pageContentCollection?.items ?? [];

    const contentToDisplay =
        pageContents.length > 0 ? pageContents : defaultContents;

    return (
        <div className={styles.modalContent}>
            <div className={styles.title}>Bonjour {userFirstName} 👋</div>
            <SearchBar
                searchTerm={""}
                setSearchTerm={() => {}}
                handleSearchChange={() => {}}
                dataCy="search-field-widget"
            />
            <div className={styles.help}>
                <div className={styles.helpTitle}>Besoin d'aide ?</div>
                {contentToDisplay.map((pageContent) =>
                    pageContent ? (
                        <div
                            className={styles.questions}
                            key={pageContent.name}
                        >
                            {pageContent.helpUrlsJson.map(
                                (helpUrl: HelpUrl) =>
                                    helpUrl && (
                                        <button
                                            className={styles.question}
                                            onClick={() => {}}
                                            key={helpUrl.URL}
                                        >
                                            {helpUrl.question}
                                            <RightChevronIcon />
                                        </button>
                                    ),
                            )}
                        </div>
                    ) : null,
                )}
            </div>
        </div>
    );
};

export default Home;
