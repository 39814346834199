import { AccessoryType } from "components/business/catalog/utils/types";
import { TempCart } from "components/provider/context/temp-cart/types";

import TempCartAction from "./temp-cart-action";

class UpdateAccessoryTempCartAction implements TempCartAction {
    payload: {
        accessory: AccessoryType;
    };

    constructor(accessory: AccessoryType) {
        this.payload = {
            accessory,
        };
    }

    apply(tempCart: TempCart) {
        const accessoryExists = tempCart.suggestedAccessories.find(
            (accessory) => accessory.sys.id === this.payload.accessory.sys.id,
        );

        if (accessoryExists) {
            return {
                ...tempCart,
                suggestedAccessories: tempCart.suggestedAccessories.filter(
                    (accessory) =>
                        accessory.sys.id !== this.payload.accessory.sys.id,
                ),
            };
        }

        return {
            ...tempCart,
            suggestedAccessories: [
                ...tempCart.suggestedAccessories,
                this.payload.accessory,
            ],
        };
    }
}

export default UpdateAccessoryTempCartAction;
