import Product from "components/business/order/product/product";
import ProductAddress from "components/business/order/product/product-address";

import CartAction from "./cart-action";

class SetProductAddressAction implements CartAction {
    payload: {
        product: Product;
        address: ProductAddress | null;
    };

    constructor(product: Product, address: ProductAddress | null) {
        this.payload = {
            product,
            address,
        };
    }

    apply(cart: Product[]) {
        const productIndex = cart.indexOf(this.payload.product);
        const updatedCart = [...cart];
        if (productIndex >= 0) {
            updatedCart[productIndex].address = this.payload.address;
        }
        return updatedCart;
    }
}

export default SetProductAddressAction;
