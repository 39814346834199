import React, { SetStateAction, useState } from "react";

import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

import MultipleSelectFilter from "components/common/layout/multiple-select-filter/multiple-select-filter";
import { OrderStatus } from "graphql/order/model/apollo";
import { OrderStatusFilterValues } from "utils/order-status/order-status";

import "./filters.scss";

interface SelectedOrder {
    filteredOrderStatus: OrderStatus[];
    setFilteredOrderStatus: React.Dispatch<SetStateAction<OrderStatus[]>>;
    setToggleSortDate: React.Dispatch<SetStateAction<boolean>>;
    sortDateValue: boolean;
}

const Filters = (props: SelectedOrder) => {
    const [dateToggle, setDateToggle] = useState(false);

    function filterOrderStatusTab(
        orderStatutsList: OrderStatus[],
        orderStatus: OrderStatus,
    ): OrderStatus[] {
        if (orderStatutsList.includes(orderStatus)) {
            return orderStatutsList.filter((type) => type !== orderStatus);
        }
        return [...orderStatutsList, orderStatus];
    }

    const orderStatusOptions = Array.from(OrderStatusFilterValues).map(
        ([key, value]) => ({
            label: value,
            onClick: () => {
                props.setFilteredOrderStatus(
                    filterOrderStatusTab(props.filteredOrderStatus, key),
                );
            },
        }),
    );

    return (
        <div>
            <div className="order-list-filters">
                <MultipleSelectFilter
                    data-cy="order-status-filter"
                    title="Statut de la commande"
                    selectedTitle="Statuts"
                    options={orderStatusOptions}
                    resetFilter={() => {
                        props.setFilteredOrderStatus([]);
                    }}
                />

                <Dropdown
                    isOpen={dateToggle}
                    toggle={() => setDateToggle(!dateToggle)}
                >
                    <DropdownToggle
                        tag="div"
                        className="nlt-button-inside fix-button filter-no-checkbox"
                    >
                        Trier la date par{" "}
                        <i className="icofont icofont-caret-down m-l-5" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-content">
                        <DropdownItem
                            toggle={false}
                            key="croissant"
                            href="#"
                            onClick={() => props.setToggleSortDate(false)}
                        >
                            Ordre croissant
                            {props.sortDateValue === false && (
                                <i className="fa fa-check m-l-5" />
                            )}
                        </DropdownItem>
                        <DropdownItem
                            toggle={false}
                            key="decroissant"
                            href="#"
                            onClick={() => props.setToggleSortDate(true)}
                        >
                            Ordre décroissant
                            {props.sortDateValue === true && (
                                <i className="fa fa-check m-l-5" />
                            )}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};

export default Filters;
