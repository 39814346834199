import React from "react";

import { Button } from "@cleaq/design-system";

import BravasIcon from "components/common/icons/BravasIcon";

interface DeviceDetailsMDMProps {
    serialNumber: string;
    bravasFQDN: string[][] | undefined;
}

const DeviceDetailsMDM = ({
    serialNumber,
    bravasFQDN,
}: DeviceDetailsMDMProps) => {
    return (
        <>
            <h6 className="mb-3">Pilotage MDM de ma flotte</h6>

            <Button
                size="small"
                variant="secondary"
                as="a"
                href={`https://${bravasFQDN}/admin/operate/devices/${serialNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                leadingVisual={<BravasIcon size={13} />}
            >
                Gérer ce terminal avec Bravas
            </Button>

            <div className="nlt-device-sep" />
        </>
    );
};

export default DeviceDetailsMDM;
