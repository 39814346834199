/* eslint-disable prefer-destructuring */
import { DeviceFragment, DeviceStatus } from "graphql/management/model/apollo";

import { statusList } from "./device-labels";

interface DeviceState {
    status: string;
    color: string;
}

export default function computeDeviceStatus(
    selectedDevice: DeviceFragment,
): DeviceState {
    let status = statusList[0];
    let color = "nlt-font-red";
    if (selectedDevice.status === DeviceStatus.SPARE) {
        status = statusList[1];
        color = "nlt-font-grey";
    }
    if (selectedDevice.affectedUser !== null) {
        status = statusList[2];
        color = "nlt-font-purple";
    }
    if (selectedDevice.status === DeviceStatus.MAINTENANCE) {
        status = statusList[4];
        color = "nlt-font-yellow";
    }
    if (selectedDevice.incident !== null) {
        status = statusList[3];
        color = "nlt-font-orange";
    }
    return {
        status,
        color,
    };
}
