/* eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }] */
import { AccessoryType } from "components/business/catalog/utils/types";
import translator from "utils/translator/translator";
import translatorAccessoryType from "utils/translator/translator-accessory-type";
import translatorConnectivity from "utils/translator/translator-connectivity";

function getSpecsFromAccessory(
    accessory: AccessoryType,
    extendedSpecs: boolean = false,
): Map<string, string> {
    const specsMap = new Map<string, string>();
    switch (accessory.__typename) {
        case "Audio":
            specsMap.set(
                "Type :",
                translatorAccessoryType.translate(accessory.type),
            );
            specsMap.set(
                "Réduction du bruit :",
                accessory.noiseCancellation ? "Avec" : "Sans",
            );
            break;

        case "Dockstation":
            if (accessory.connection) {
                specsMap.set(
                    "Connexion :",
                    translatorConnectivity.translate(accessory.connection),
                );
            }
            if (accessory.compatibility) {
                specsMap.set(
                    "Compatibilité :",
                    translator.translate(accessory.compatibility),
                );
            }
            break;

        case "Keyboard":
            if (accessory.compatibility) {
                specsMap.set(
                    "Compatibilité :",
                    translator.translate(accessory.compatibility),
                );
            }
            specsMap.set(
                "Pavé numérique :",
                accessory.numPad ? "Avec" : "Sans",
            );
            break;

        case "Mouse":
            specsMap.set(
                "Batterie :",
                translatorAccessoryType.translate(accessory.power),
            );
            if (accessory.compatibility) {
                specsMap.set(
                    "Compatibilité :",
                    translator.translate(accessory.compatibility),
                );
            }
            break;

        case "Screen":
            if (accessory.size) {
                // eslint-disable-next-line quotes
                const size = accessory.size.toString().concat('"');
                specsMap.set("Taille :", size);
            }
            if (accessory.resolution) {
                specsMap.set("Résolution :", accessory.resolution);
            }
            specsMap.set(
                "Caméra intégrée :",
                accessory.integratedCamera ? "Avec" : "Sans",
            );
            break;

        default:
    }

    if (accessory.connectivity) {
        specsMap.set(
            "Connectivité :",
            accessory.connectivity
                .map((value) => translatorConnectivity.translate(value))
                .join(", "),
        );
    }

    if (extendedSpecs) {
        switch (accessory.__typename) {
            case "Audio":
                if (accessory.type) {
                    specsMap.set(
                        "Type :",
                        translatorAccessoryType.translate(accessory.type),
                    );
                }
                break;

            case "Keyboard":
                if (accessory.brand) {
                    specsMap.set(
                        "Marque :",
                        translator.translate(accessory.brand),
                    );
                }
                break;

            case "Mouse":
                if (accessory.type) {
                    specsMap.set(
                        "Type :",
                        translatorAccessoryType.translate(accessory.type),
                    );
                }
                if (accessory.power) {
                    specsMap.set(
                        "Batterie :",
                        translatorAccessoryType.translate(accessory.power),
                    );
                }
                if (accessory.brand) {
                    specsMap.set(
                        "Marque :",
                        translator.translate(accessory.brand),
                    );
                }
                break;

            case "Screen":
                if (accessory.refreshRate) {
                    specsMap.set(
                        "Fréquence de rafraîchissement :",
                        accessory.refreshRate?.toString().concat("Hz"),
                    );
                }
                specsMap.set(
                    "Caméra intégrée :",
                    accessory.integratedCamera ? "Avec" : "Sans",
                );
                if (accessory.brand) {
                    specsMap.set(
                        "Marque :",
                        translator.translate(accessory.brand),
                    );
                }
                break;

            default:
        }
    }
    return specsMap;
}

export default getSpecsFromAccessory;
