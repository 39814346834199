import React from "react";

import CrossIcon from "components/common/icons/CrossIcon";

import styles from "./Modal.module.scss";

interface ModalProps {
    title: string;
    children: React.ReactNode;
    onClose: () => void;
}

const Modal = (props: ModalProps) => (
    <div className={styles.container}>
        <div className={styles.modal}>
            <div className={styles.header}>
                <div className={styles.content}>
                    <h5 className={styles.title}>{props.title}</h5>
                    <button
                        className={styles.closeModal}
                        onClick={() => {
                            props.onClose();
                        }}
                    >
                        <CrossIcon />
                    </button>
                </div>
            </div>

            <div className={styles.body}>{props.children}</div>
        </div>
    </div>
);

export default Modal;
