export default function ContractIcon() {
    return (
        <svg
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.77075 1.75098H2.93742C2.49539 1.75098 2.07147 1.92657 1.75891 2.23913C1.44635 2.55169 1.27075 2.97562 1.27075 3.41764V16.751C1.27075 17.193 1.44635 17.6169 1.75891 17.9295C2.07147 18.242 2.49539 18.4176 2.93742 18.4176H12.9374C13.3794 18.4176 13.8034 18.242 14.1159 17.9295C14.4285 17.6169 14.6041 17.193 14.6041 16.751V7.58431L8.77075 1.75098Z"
                stroke="#353535"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.77075 1.75098V7.58431H14.6041"
                stroke="#353535"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
