import React from "react";

import Initials from "components/common/layout/initials/initials";
import { currentUserFragment } from "graphql/management/model/apollo";

interface ProfilePicProps {
    currentUser: currentUserFragment;
}

const ProfilePic = (props: ProfilePicProps) => {
    if (!props.currentUser) return <div />;

    return (
        <Initials
            firstName={props.currentUser.firstName}
            lastName={props.currentUser.lastName}
            sizeImage={80}
        />
    );
};

export default ProfilePic;
