import React, { createContext, useContext, useReducer } from "react";

import Filters from "components/business/catalog/filter/filters";

import CatalogFiltersAction from "./actions/catalog-filters-action";
import catalogFiltersReducer from "./catalog-filters-reducer";

export const emptyFilters = new Filters([], [], [], ["laptop"], [], [], [], "");

const CatalogFiltersContext = createContext<Filters>(emptyFilters);
const CatalogFiltersDispatchContext = createContext<
    React.Dispatch<CatalogFiltersAction>
>(() => {});

interface CatalogFilterProviderProps {
    children: React.ReactNode;
}

const CatalogFiltersProvider = (props: CatalogFilterProviderProps) => {
    const [catalogFilters, dispatch] = useReducer(
        catalogFiltersReducer,
        emptyFilters,
    );

    return (
        <CatalogFiltersContext.Provider value={catalogFilters}>
            <CatalogFiltersDispatchContext.Provider value={dispatch}>
                {props.children}
            </CatalogFiltersDispatchContext.Provider>
        </CatalogFiltersContext.Provider>
    );
};

export function useCatalogFilters(): Filters {
    return useContext(CatalogFiltersContext);
}

export function useCatalogFiltersDispatch(): React.Dispatch<CatalogFiltersAction> {
    return useContext(CatalogFiltersDispatchContext);
}

export default CatalogFiltersProvider;
