import { TempCart } from "components/provider/context/temp-cart/types";

import TempCartAction from "./actions/temp-cart-action";

const tempCartReducer = (tempCart: TempCart, action: TempCartAction) => {
    const updatedTempCart = action.apply(tempCart);
    return updatedTempCart;
};

export default tempCartReducer;
