import React, { useState } from "react";

import { Dropdown, DropdownToggle } from "reactstrap";

import { UserFragment } from "graphql/management/model/apollo";

import AssignMultipleTag from "./assign-multiple-tag";
import DeleteMultipleUsers from "./delete-multiple-users";

interface SelectedUsersInterface {
    selectedUsers: UserFragment[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<UserFragment[]>>;
    OrganisationOptions: {
        value: string;
        label: string;
    }[];
}

const SelectedUsersActionsMenu = (props: SelectedUsersInterface) => {
    const [selectedItemsToggle, setSelectedItemsToggle] =
        useState<boolean>(false);

    return (
        <div className="float-right d-flex">
            <DeleteMultipleUsers
                selectedUsers={props.selectedUsers}
                setSelectedUsers={props.setSelectedUsers}
            />
            <Dropdown
                isOpen={selectedItemsToggle}
                toggle={() => setSelectedItemsToggle(!selectedItemsToggle)}
            >
                <DropdownToggle
                    tag="div"
                    className="nlt-button-manage"
                >
                    Gérer
                    <i className="icofont icofont-caret-down m-l-5 float-right" />
                </DropdownToggle>
                <AssignMultipleTag
                    selectedUser={props.selectedUsers}
                    setSelectedUser={props.setSelectedUsers}
                    OrganisationOptions={props.OrganisationOptions!}
                />
            </Dropdown>
        </div>
    );
};

export default SelectedUsersActionsMenu;
