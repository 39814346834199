import moment from "moment/moment";

import Product from "components/business/order/product/product";
import { DeliveryTypeInput } from "graphql/order/model/apollo";

import CartAction from "./cart-action";

class SetProductDeliveryDateAction implements CartAction {
    payload: {
        product: Product;
        date: Date;
        deliveryType: DeliveryTypeInput;
    };

    constructor(product: Product, date: Date, deliveryType: DeliveryTypeInput) {
        this.payload = {
            product,
            date,
            deliveryType,
        };
    }

    apply(cart: Product[]) {
        const updatedCart = [...cart];
        const productIndex = cart.indexOf(this.payload.product);
        if (productIndex >= 0) {
            updatedCart[productIndex].deliveryDate.date = moment
                .utc(this.payload.date)
                .toISOString();
            updatedCart[productIndex].deliveryDate.deliveryType =
                this.payload.deliveryType;
        }

        return updatedCart;
    }
}
export default SetProductDeliveryDateAction;
