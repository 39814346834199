export default function CrossIcon() {
    return (
        <svg
            width="10"
            height="10"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.1455 15.6818L0.318191 1.85455C-0.106064 1.4303 -0.106064 0.742446 0.318191 0.318191C0.742446 -0.106064 1.4303 -0.106064 1.85455 0.318191L8.76818 7.23182L15.6818 14.1455C16.1061 14.5697 16.1061 15.2576 15.6818 15.6818C15.2576 16.1061 14.5697 16.1061 14.1455 15.6818Z"
                fill="#353535"
            />
            <path
                d="M1.85455 15.6818L15.6818 1.85455C16.1061 1.4303 16.1061 0.742446 15.6818 0.318191C15.2576 -0.106064 14.5697 -0.106064 14.1455 0.318191L0.318191 14.1455C-0.106064 14.5697 -0.106063 15.2576 0.318191 15.6818C0.742446 16.1061 1.4303 16.1061 1.85455 15.6818Z"
                fill="#353535"
            />
        </svg>
    );
}
