import React, { useEffect, useState } from "react";

import { useKeycloak } from "@react-keycloak/web";
import { useForm } from "react-hook-form";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { Dropdown, DropdownToggle, Row } from "reactstrap";

import AssignMultipleUser from "components/business/device/assign-multiple-user";
import CreateDeviceComponent from "components/business/device/create/create-device";
import DeleteMultipleDevices from "components/business/device/delete/delete-multiple-devices";
import DeviceDetails from "components/business/device/details/device-details";
import DeviceItem from "components/business/device/items/device/device-item";
import DevicePlaceHolder from "components/business/device/items/placeholder/device-placeholder";
import DeviceList from "components/business/device/layout/list/device-list";
import DeviceSidePanel from "components/business/device/layout/sidepanel/device-sidepanel";
import SmallScreen from "components/common/layout/banner/small-screen";
import SkeletonItem from "components/common/layout/loader/skeleton-item";
import MultiActionsButton from "components/common/layout/multi-actions-button/multi-actions-button";
import MultipleItemSelector from "components/common/layout/multiple-item-selector/multiple-item-selector";
import MultipleSelectFilter from "components/common/layout/multiple-select-filter/multiple-select-filter";
import SearchBar from "components/common/layout/searchbar/searchbar";
import ToggleFilter from "components/common/layout/toggle-filter/toggle-filter";
import {
    DeviceFragment,
    DeviceOs,
    DeviceSource,
    DeviceType,
    useFindDevicesQuery,
} from "graphql/management/model/apollo";
import { isUser } from "utils/check-role/check-role";
import computeDeviceStatus from "utils/device/device-compute-status";
import type { FormData } from "utils/device/device-form";
import {
    computedStatusLabels,
    osLabels,
    statusList,
    typesLabels,
} from "utils/device/device-labels";

const Device = (props: any) => {
    const { keycloak, initialized } = useKeycloak();
    const history = useHistory();
    const devicesQuery = useFindDevicesQuery();
    const [selectedDevice, setSelectedDevice] =
        useState<DeviceFragment | null>();
    const [editable, setEditable] = useState(false);
    const [isCreateDevice, setIsCreateDevice] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedDeviceType, setSelectedDeviceType] = useState<DeviceType>(
        DeviceType.LAPTOP,
    );
    const [searchTerm, setSearchTerm] = useState("");
    const [nameValue, setNameValue] = useState("");

    const [deviceTypeTab, setDeviceTypeTab] = useState<DeviceType[]>([]);
    const [deviceStatusTab, setDeviceStatusTab] = useState<string[]>([]);
    const [deviceOsTab, setDeviceOsTab] = useState<DeviceOs[]>([]);
    const deviceForm = useForm<FormData>(); // initialise the hook
    const location = useLocation();

    const [selectedItems, setSelectedItems] = useState<DeviceFragment[]>([]);
    const [selectedItemsToggle, setSelectedItemsToggle] =
        useState<boolean>(false);

    const [selected, setSelected] = React.useState(false);
    const [fromCleaqToggle, setFromCleaqToggle] = useState(false);

    const BuildDevices =
        devicesQuery?.data &&
        devicesQuery?.data.findDevices
            ?.filter(
                (device) =>
                    (deviceStatusTab?.length === 0 ||
                        deviceStatusTab?.includes(
                            computeDeviceStatus(device).status,
                        )) &&
                    (deviceTypeTab?.length === 0 ||
                        deviceTypeTab?.includes(device.specs.deviceType!)) &&
                    (deviceOsTab?.length === 0 ||
                        deviceOsTab?.includes(device.specs.deviceOs!)),
            )
            // eslint-disable-next-line array-callback-return, consistent-return
            ?.filter((device) => {
                if (searchTerm === "" || searchTerm.length < 2) {
                    return device;
                }
                if (
                    device.serialNumber
                        ?.toLocaleLowerCase()
                        ?.includes(searchTerm.toLocaleLowerCase()) ||
                    device.reference
                        ?.toLocaleLowerCase()
                        ?.includes(searchTerm.toLocaleLowerCase())
                ) {
                    return device;
                }
            })
            // eslint-disable-next-line array-callback-return, consistent-return
            ?.filter((device) => {
                if (fromCleaqToggle === true) {
                    if (
                        device.source === DeviceSource.CLEAQ &&
                        device.leasingData?.leasingStart
                    ) {
                        return device;
                    }
                } else {
                    return device;
                }
            });

    const DeviceNames = Array.from(
        new Set(
            devicesQuery?.data &&
                devicesQuery?.data.findDevices?.map(
                    (device) => device.reference!,
                ),
        ),
    );

    useEffect(() => {
        if (devicesQuery.loading) {
            return;
        }
        if (props.location.state && props.location.state.serialSearch) {
            setSelectedDevice(
                devicesQuery?.data?.findDevices?.find(
                    (device) =>
                        device.serialNumber! ===
                        props.location.state.serialSearch,
                ),
            );
            setSearchTerm(props.location.state.serialSearch);
            history.replace({
                pathname: location.pathname,
                state: null,
            });
        } else if (props.location.state && props.location.state.statusSearch) {
            setDeviceStatusTab([
                computedStatusLabels.get(props.location.state.statusSearch)!,
            ]);
            setSelectedDevice(null);
            history.replace({
                pathname: location.pathname,
                state: null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devicesQuery, props?.location]);

    const handleSearchChange = (event: any) => {
        event.preventDefault();
        setSearchTerm(event.target.value);
        setSelectedDevice(null);
    };

    const CancelEdit = () => {
        deviceForm.reset();
        setEditable(false);
        setSaving(false);
        setNameValue("");
        setIsCreateDevice(false);
        setSelectedDeviceType(DeviceType.LAPTOP);
    };

    function toggleDeviceTypeTab(deviceType: DeviceType) {
        if (deviceTypeTab.includes(deviceType)) {
            setDeviceTypeTab(
                deviceTypeTab.filter((type) => type !== deviceType),
            );
        } else {
            setDeviceTypeTab([...deviceTypeTab, deviceType]);
        }
    }

    function toggleDeviceOsTab(deviceOs: DeviceOs) {
        if (deviceOsTab.includes(deviceOs)) {
            setDeviceOsTab(deviceOsTab.filter((os) => os !== deviceOs));
        } else {
            setDeviceOsTab([...deviceOsTab, deviceOs]);
        }
    }

    function toggleDeviceStatusTab(deviceStatus: string) {
        if (deviceStatusTab.includes(deviceStatus)) {
            setDeviceStatusTab(
                deviceStatusTab.filter((status) => status !== deviceStatus),
            );
        } else {
            setDeviceStatusTab([...deviceStatusTab, deviceStatus]);
        }
    }

    const StatusOptions = statusList.map((status) => ({
        label: status,
        onClick: () => {
            toggleDeviceStatusTab(status);
            setSelectedDevice(null);
        },
    }));

    const TypeOptions = [
        DeviceType.LAPTOP,
        DeviceType.DESKTOP,
        DeviceType.TABLET,
        DeviceType.PHONE,
        DeviceType.OTHER,
    ].map((type) => ({
        label: typesLabels.get(type) || null,
        onClick: () => {
            toggleDeviceTypeTab(type);
            setSelectedDevice(null);
        },
    }));

    const OsOptions = [
        DeviceOs.MAC,
        DeviceOs.WINDOWS,
        DeviceOs.CHROME,
        DeviceOs.LINUX,
        DeviceOs.IOS,
        DeviceOs.ANDROID,
        DeviceOs.OTHER,
    ].map((os) => ({
        label: osLabels.get(os) || null,
        onClick: () => {
            toggleDeviceOsTab(os);
            setSelectedDevice(null);
        },
    }));

    function toggleIsCleaq() {
        if (fromCleaqToggle === true) {
            setFromCleaqToggle(false);
        } else {
            setFromCleaqToggle(true);
        }
    }

    const containsCleaqDevice = (): boolean => {
        if (
            selectedItems.find((source) => source.source === DeviceSource.CLEAQ)
        ) {
            return true;
        }
        return false;
    };

    return (
        <>
            <div className="nlt-container">
                <div className="nlt-container-scale email-wrap bookmark-wrap">
                    <div className="nlt-card-left-panel">
                        <div className="nlt-card-header">
                            <div className="flex-sb h50-ai-c">
                                <span className="nlt-card-title align-title">
                                    Appareils
                                </span>
                                {!isUser(keycloak) &&
                                selectedItems.length > 0 ? (
                                    <div className="float-right d-flex">
                                        <>
                                            {!containsCleaqDevice() && (
                                                <DeleteMultipleDevices
                                                    selectedDevices={
                                                        selectedItems
                                                    }
                                                />
                                            )}
                                        </>
                                        <Dropdown
                                            isOpen={selectedItemsToggle}
                                            toggle={() =>
                                                setSelectedItemsToggle(
                                                    !selectedItemsToggle,
                                                )
                                            }
                                        >
                                            <DropdownToggle
                                                tag="div"
                                                className="nlt-button-manage"
                                                data-cy="button-manage"
                                            >
                                                Gérer
                                                <i className="icofont icofont-caret-down m-l-5 float-right" />
                                            </DropdownToggle>
                                            <AssignMultipleUser
                                                selectedDevice={selectedItems}
                                                setSelectedDevice={
                                                    setSelectedItems
                                                }
                                            />
                                        </Dropdown>
                                    </div>
                                ) : (
                                    !isUser(keycloak) && (
                                        <MultiActionsButton
                                            title="Nouveaux appareils"
                                            data-cy="multi-action-device"
                                            actions={[
                                                {
                                                    title: "Créer un appareil",
                                                    icon: (
                                                        <i className="fa fa-plus m-r-10" />
                                                    ),
                                                    onClick: () => {
                                                        setIsCreateDevice(true);
                                                    },
                                                },
                                                {
                                                    title: "Importer un fichier",
                                                    icon: (
                                                        <i className="icofont icofont-file-text m-r-10" />
                                                    ),
                                                    onClick: () => {
                                                        history.push({
                                                            pathname: `${process.env.PUBLIC_URL}/load-data`,
                                                            state: null,
                                                        });
                                                    },
                                                },
                                            ]}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                        <SearchBar
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            handleSearchChange={handleSearchChange}
                        />
                        <Row style={{ marginBottom: "10px" }}>
                            <div className="nlt-wide-filters">
                                {!isUser(keycloak) && (
                                    <div className="selectAll col-lg-2 col-xl-1">
                                        <MultipleItemSelector
                                            allElements={BuildDevices?.length}
                                            selectedElements={
                                                selectedItems.length
                                            }
                                            onClick={() => {
                                                setSelectedDevice(null);
                                                setIsCreateDevice(false);
                                                if (
                                                    selectedItems.length ===
                                                    BuildDevices?.length
                                                ) {
                                                    setSelectedItems([]);
                                                } else {
                                                    setSelectedItems(
                                                        BuildDevices!,
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                )}

                                <MultipleSelectFilter
                                    data-cy="device-type-filter"
                                    title="Type d'appareil"
                                    selectedTitle="Types"
                                    options={TypeOptions}
                                    resetFilter={() => {
                                        setDeviceTypeTab([]);
                                    }}
                                />

                                <MultipleSelectFilter
                                    data-cy="device-os-filter"
                                    title="OS d'appareil"
                                    selectedTitle="OS"
                                    options={OsOptions}
                                    resetFilter={() => {
                                        setDeviceOsTab([]);
                                    }}
                                />

                                <MultipleSelectFilter
                                    data-cy="device-status-filter"
                                    title="Statut d'appareil"
                                    selectedTitle="Statuts"
                                    options={StatusOptions}
                                    resetFilter={() => {
                                        setDeviceStatusTab([]);
                                    }}
                                />

                                <ToggleFilter
                                    aria-hidden="true"
                                    onClick={() => {
                                        setSelected(!selected);
                                        toggleIsCleaq();
                                    }}
                                >
                                    <p>
                                        Loué chez Clea
                                        <span className="q-cleaq">q</span>
                                    </p>
                                </ToggleFilter>
                            </div>
                        </Row>

                        <div>
                            <div className="email-body radius-left">
                                {!initialized || devicesQuery.loading ? (
                                    <SkeletonItem />
                                ) : (
                                    <div className="pl-0">
                                        <Row className="list-persons">
                                            <DeviceList
                                                isKeycloakUser={isUser(
                                                    keycloak,
                                                )}
                                            >
                                                <>
                                                    {BuildDevices &&
                                                    BuildDevices.length > 0 ? (
                                                        BuildDevices.map(
                                                            (device) => (
                                                                <DeviceItem
                                                                    key={
                                                                        device.serialNumber
                                                                    }
                                                                    device={
                                                                        device
                                                                    }
                                                                    selectedItems={
                                                                        selectedItems
                                                                    }
                                                                    setSelectedItems={
                                                                        setSelectedItems
                                                                    }
                                                                    selectedDevice={
                                                                        selectedDevice
                                                                    }
                                                                    setSelectedDevice={
                                                                        setSelectedDevice
                                                                    }
                                                                    setIsCreateDevice={
                                                                        setIsCreateDevice
                                                                    }
                                                                    cancelEdit={
                                                                        CancelEdit
                                                                    }
                                                                />
                                                            ),
                                                        )
                                                    ) : (
                                                        <DevicePlaceHolder />
                                                    )}
                                                </>
                                            </DeviceList>
                                            {(isCreateDevice ||
                                                selectedDevice) && (
                                                <DeviceSidePanel>
                                                    {selectedDevice && (
                                                        <DeviceDetails
                                                            deviceForm={
                                                                deviceForm
                                                            }
                                                            saving={saving}
                                                            setSaving={
                                                                setSaving
                                                            }
                                                            editable={editable}
                                                            setEditable={
                                                                setEditable
                                                            }
                                                            isCreateDevice={
                                                                isCreateDevice
                                                            }
                                                            isKeycloakUser={isUser(
                                                                keycloak,
                                                            )}
                                                            deviceNames={
                                                                DeviceNames
                                                            }
                                                            selectedDevice={
                                                                selectedDevice
                                                            }
                                                            setSelectedDevice={
                                                                setSelectedDevice
                                                            }
                                                            selectedDeviceType={
                                                                selectedDeviceType
                                                            }
                                                            setSelectedDeviceType={
                                                                setSelectedDeviceType
                                                            }
                                                            nameValue={
                                                                nameValue
                                                            }
                                                            setNameValue={
                                                                setNameValue
                                                            }
                                                            cancelEdit={
                                                                CancelEdit
                                                            }
                                                        />
                                                    )}
                                                    {isCreateDevice && (
                                                        <CreateDeviceComponent
                                                            devicesNames={
                                                                DeviceNames
                                                            }
                                                            saving={saving}
                                                            setSaving={
                                                                setSaving
                                                            }
                                                            selectedDeviceType={
                                                                selectedDeviceType
                                                            }
                                                            setSelectedDevice={
                                                                setSelectedDevice
                                                            }
                                                            setIsCreateDevice={
                                                                setIsCreateDevice
                                                            }
                                                            cancelEdit={
                                                                CancelEdit
                                                            }
                                                        />
                                                    )}
                                                </DeviceSidePanel>
                                            )}
                                        </Row>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SmallScreen />
        </>
    );
};

export default withRouter(Device);
