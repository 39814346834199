import React from "react";

import { Button } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router";

import CalendarIcon from "components/common/icons/CalendarIcon";
import SmallScreen from "components/common/layout/banner/small-screen";

import "./skeleton-order-details.scss";

const SkeletonOrderDetails = () => {
    const history = useHistory();
    const ReturnOnHistoryOrder = () =>
        history.push(`${process.env.PUBLIC_URL}/orders`);
    return (
        <div className="skeleton-order-list-side-panel">
            <div className="skeleton-side-panel-elements">
                <div className="skeleton-order-details-container">
                    <div className="visible">
                        <Button
                            variant="outlined"
                            onClick={() => ReturnOnHistoryOrder()}
                            startIcon={<ChevronLeft />}
                        >
                            Retour
                        </Button>
                    </div>
                    <div className="skeleton-order-header-infos">
                        <div className="skeleton-title">
                            <Skeleton width={300} />
                        </div>
                        <div>
                            <Skeleton width={90} />
                        </div>
                        <div className="skeleton-order-status">
                            <Skeleton width={170} />
                        </div>
                    </div>
                    <div className="skeleton-orderby">
                        <div>Commande passée par</div>
                        <div className="skeleton-ordered-by">
                            <Skeleton width={90} />
                        </div>
                    </div>
                </div>
                <div className="skeleton-pricesum-container">
                    <div className="skeleton-pricesum">
                        <p>Montant total</p>
                        <span>
                            <Skeleton
                                variant="rect"
                                width={120}
                                height={20}
                            />
                        </span>
                    </div>
                </div>
                <div className="skeleton-item">
                    <div className="skeleton-date-title">
                        <CalendarIcon />
                        <Skeleton width={95} />
                    </div>
                    <div>
                        <div className="skeleton-address-and-status">
                            <Skeleton width={300} />
                        </div>
                        <div className="skeleton-address-and-products">
                            <div className="skeleton-order-list-product">
                                <div className="skeleton-order-list-product-info">
                                    <div className="skeleton-product-infos">
                                        <div className="skeleton-img">
                                            <Skeleton
                                                variant="rect"
                                                width={40}
                                                height={35}
                                            />
                                        </div>
                                        <div className="skeleton-name-serial">
                                            <div>
                                                <Skeleton width={140} />
                                            </div>
                                            <div>
                                                <Skeleton width={150} />
                                            </div>
                                        </div>
                                        <div className="skeleton-specs">
                                            <div>
                                                <Skeleton width={80} />
                                            </div>
                                            <div>
                                                <Skeleton width={80} />
                                            </div>
                                            <div>
                                                <Skeleton width={80} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="skeleton-user-and-price">
                                        <div className="skeleton-alert-background-color">
                                            <Skeleton width={40} />
                                            <Skeleton width={50} />
                                        </div>
                                        <div className="skeleton-alert-background-color">
                                            <Skeleton width={80} />
                                        </div>
                                        <div className="skeleton-status-price">
                                            <div className="skeleton-order-list-price">
                                                <Skeleton width={90} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SmallScreen />
        </div>
    );
};

export default SkeletonOrderDetails;
