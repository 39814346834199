import React from "react";

import { ProductFragment, VariantFragment } from "graphql/catalog/model/apollo";
import translator, {
    formatMemory,
    formatPrice,
} from "utils/translator/translator";

import "./product.scss";

interface ProductProps {
    product: ProductFragment;
    variants: VariantFragment[];
    instanceId: string;
}

const Product = (props: ProductProps) => {
    function onlyUnique(value: any, index: any, self: any) {
        return self.indexOf(value) === index;
    }

    const orderedVariants = props.variants.sort((vA, vB) => {
        if (vA.product?.type === "smartphone") {
            return (vA.price24 ?? 0) - (vB.price24 ?? 0);
        }
        return (vA.price36 ?? 0) - (vB.price36 ?? 0);
    });

    const type = orderedVariants[0].product?.type;

    const negotiatedVariants = orderedVariants.filter(
        (variant) => variant.companyCoefficient?.[props.instanceId],
    );

    const negotiated = (): boolean => negotiatedVariants.length > 0;

    const lowestPrice = (): number => {
        const lowestFirst = (a: number, b: number): number => a - b;

        if (negotiated()) {
            const negociatedPrices = negotiatedVariants.map(
                (variant) => variant.companyCoefficient?.[props.instanceId],
            );

            return type === "smartphone"
                ? negociatedPrices
                      .map(
                          (negotiatedPrice) =>
                              (negotiatedPrice.price24 as number) ?? 0,
                      )
                      .sort(lowestFirst)[0]
                : negociatedPrices
                      .map(
                          (negotiatedPrice) =>
                              (negotiatedPrice.price36 as number) ?? 0,
                      )
                      .sort(lowestFirst)[0];
        }

        return type === "smartphone"
            ? orderedVariants.map((variant) => variant.price24 ?? 0)[0]
            : orderedVariants.map((variant) => variant.price36 ?? 0)[0];
    };

    const processor = orderedVariants.map((a) => a.processorValue);

    const ram = orderedVariants.map((a) => a.ramValue);

    const storage = orderedVariants.map((a) => a.storageValue);

    const trimMemoryConfigs = (
        values: (number | null | string)[],
        maxValues: number,
        formatter: (arg0: any) => string,
    ): string => {
        values = values.filter(onlyUnique);

        if (values.length > maxValues) {
            return `${values.length} configurations`;
        }

        return values.map((a) => formatter(a)).join(" / ");
    };

    return (
        <>
            {props.product.image?.url && (
                <img
                    src={props.product.image.url}
                    alt={props.product.image.title || props.product.name || ""}
                    className="product-image"
                />
            )}
            <div
                className="product-infos"
                key={props.product.sys.id}
                data-cy="catalog-product"
            >
                <h4 className="product-name">{props.product.name}</h4>
                <div className="price">
                    <p>
                        à partir de{" "}
                        <strong>{formatPrice(lowestPrice() || 0)}</strong> HT /
                        mois
                        {negotiated() && <i className="icofont icofont-star" />}
                    </p>
                </div>
                <div className="product-details">
                    <div className="details">
                        <div className="product-spec">
                            <span>Processeurs :</span>
                            <span>
                                {trimMemoryConfigs(
                                    processor,
                                    2,
                                    translator.translate,
                                )}
                            </span>
                        </div>
                        <div className="product-spec">
                            <span>Mémoire RAM :</span>
                            <span>
                                {trimMemoryConfigs(ram, 4, formatMemory)}
                            </span>
                        </div>
                        <div className="product-spec">
                            <span>Stockage :</span>
                            <span>
                                {trimMemoryConfigs(storage, 5, formatMemory)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Product;
