import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import "components/business/dashboard/dashboard-optimize/skeleton-optimize-card/skeleton-optimize-card.scss";

const SkeletonOptimizeCard = () => (
    <div
        className="nlt-optimize-action"
        style={{
            float: "left",
            marginRight: "auto",
        }}
    >
        <div className="nlt-optimize-icon skeleton-optimize-icon">
            <Skeleton
                variant="circle"
                height={33}
                width={33}
            />
        </div>
        <div className="nlt-optimize-info">
            <div className="nlt-document-title">
                <Skeleton width={100} />
            </div>
            <div className="nlt-document-date">
                <Skeleton width={70} />
            </div>
        </div>
        <div className="nlt-optimize-arrow" />
    </div>
);

export default SkeletonOptimizeCard;
