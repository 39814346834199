import React from "react";

import "./form-error-message.scss";

interface FormErrorMessageProps {
    "data-cy"?: string;
    testId?: string;
    message: string;
}

const FormErrorMessage = (props: FormErrorMessageProps) => (
    <span data-cy={`form-error-${props["data-cy"]}`}>{props.message}</span>
);

FormErrorMessage.defaultProps = {
    "data-cy": "",
    testId: "",
};

export default FormErrorMessage;
