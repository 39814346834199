import React from "react";

import { CirclePicker } from "react-color";
import { Controller } from "react-hook-form";
import type { UseFormReturn } from "react-hook-form";
import { FormGroup, Row } from "reactstrap";

import type { TagType } from "components/business/tag/types";

interface colorPickerInterface {
    title: string;
    form: UseFormReturn<TagType>;
    colorValue: string;
    setColorValue: React.Dispatch<React.SetStateAction<string>>;
}

const AvailableColors = [
    "#75E6E6",
    "#758EE6",
    "#A775E6",
    "#38A550",
    "#FAD7D7",
    "#049B9B",
    "#F5A6DE",
    "#C69EC7",
    "#CAAE1A",
    "#D81D1D",
    "#D98522",
    "#4f4F4F",
    "#75E680",
    "#005AE1",
    "#CA9564",
    "#6721AD",
    "#80BCE7",
    "#F3DE70",
    "#F7B87D",
    "#48D1B4",
    "#E78080",
    "#D838CB",
    "#FF7C47",
    "#412234",
    "#FFC857",
    "#E75F37",
    "#883110",
    "#F9E900",
    "#AAD2BA",
    "#4B3F72",
    "#B84A62",
];

const ColorPicker = (props: colorPickerInterface) => (
    <Row className="nlt-row-form-fix">
        <div className="nlt-item-info-label-edit col-md-12 required">
            {props.title}
        </div>
        <Controller
            name="hexaColor"
            control={props.form.control}
            rules={{
                validate: (value) =>
                    value.length > 0 && value.trim().length > 0,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormGroup className="col-md-12">
                    {!error ? (
                        <span className="mandatory-label-small m-b-15" />
                    ) : (
                        <span className="nlt-mandatory-label-small nlt-font-red m-b-15">
                            La couleur est requise.
                        </span>
                    )}
                    <CirclePicker
                        width="100%"
                        onChangeComplete={(color) => {
                            onChange(color.hex);
                        }}
                        colors={AvailableColors}
                        color={value}
                    />
                </FormGroup>
            )}
        />
    </Row>
);

export default ColorPicker;
