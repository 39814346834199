import React from "react";

import type { UseFormReturn } from "react-hook-form";
import { FormGroup, Input, Row } from "reactstrap";

import type { FormData } from "utils/device/device-form";

interface deviceUpdateSelectInterface {
    "data-cy": string;
    label: string;
    id: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    name: keyof FormData;
    defaultValue: string;
    deviceForm: UseFormReturn<FormData>;
    required?: boolean;
    children: JSX.Element;
    disabled?: boolean;
}

const DeviceUpdateSelect = (props: deviceUpdateSelectInterface) => {
    const { deviceForm, required, name } = props;
    const { register } = deviceForm;
    const { ref, ...rest } = register(name, { required });
    return (
        <Row className="nlt-form-spacing-fix fix-padding">
            <div className="nlt-item-info-label-edit col-md-5">
                {props.label}
            </div>
            <FormGroup className="col-md-7">
                <Input
                    {...rest}
                    data-cy={props["data-cy"]}
                    className="form-control"
                    id={props.id}
                    onChange={props.onChange}
                    defaultValue={props.defaultValue}
                    type="select"
                    innerRef={ref}
                    disabled={props.disabled}
                >
                    {props.children}
                </Input>
            </FormGroup>
        </Row>
    );
};

DeviceUpdateSelect.defaultProps = {
    onChange: undefined,
    required: false,
    disabled: false,
};

export default DeviceUpdateSelect;
