import settings from "config";
import { currentUserFragment } from "graphql/management/model/apollo";

export default function authenticateHubspotChat(
    currentUser: currentUserFragment,
    inWidget: boolean,
) {
    const hsConfig: HubSpotConversationsSettings = {
        loadImmediately: true,
    };
    if (settings.REACT_APP_ENV === "prod") {
        hsConfig.identificationEmail = currentUser.email;
        hsConfig.identificationToken = currentUser.token ?? "";
    }
    if (inWidget) {
        hsConfig.inlineEmbedSelector = "#hubspot-chat";
    }
    window.hsConversationsSettings = hsConfig;

    const scriptSrc =
        settings.REACT_APP_ENV === "prod"
            ? "https://js.hs-scripts.com/6471233.js"
            : "https://js-eu1.hs-scripts.com/25680126.js";

    (function hsScriptLoader(d) {
        const s = "script";
        const t = d.getElementsByTagName(s)[0];
        const e = d.createElement(s);
        e.async = true;
        e.defer = true;
        e.src = scriptSrc;
        e.id = "hs-script-loader";
        t.parentNode?.insertBefore(e, t);
    })(document);
}
