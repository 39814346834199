import React from "react";

import { useForm, Controller } from "react-hook-form";
import { Form, FormGroup, Input, Row } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { useUpdateUserPasswordMutation } from "graphql/management/model/apollo";

type PasswordData = {
    newPassword: string;
    confirmPassword: string;
};

const CleaqSwal = withReactContent(Swal);

const AccountFormPassword = () => {
    const [updatePassword] = useUpdateUserPasswordMutation();
    const {
        control,
        reset,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<PasswordData>({ mode: "onBlur" });

    const updatePasswordForm = async (dataPush: PasswordData) => {
        try {
            let isPwdUpdated = null;
            if (dataPush.newPassword === dataPush.confirmPassword) {
                isPwdUpdated = await updatePassword({
                    variables: {
                        newPassword: dataPush.newPassword,
                    },
                });
            }
            if (!isPwdUpdated) {
                await CleaqSwal.fire({
                    title: "Oops, une erreur s'est produite !",
                    html: "Le mot de passe n'a pas été mis à jour",
                    icon: "error",
                });
            } else {
                await CleaqSwal.fire({
                    title: "Mot de passe à jour !",
                    html: "Vous pouvez utiliser votre mot de passe à votre prochaine connexion",
                    icon: "success",
                });
            }
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Contactez-nous dans le chat !",
                icon: "error",
            });
        }
        await reset();
    };

    return (
        <Form
            onSubmit={handleSubmit(updatePasswordForm)}
            className="passwordForm"
        >
            <h6 className="title6">Changer de mot de passe</h6>
            {errors?.newPassword && (
                <div className="nlt-input-error col-md-7 nlt-font-red ml-auto">
                    {errors?.newPassword &&
                    errors?.newPassword.type === "validate"
                        ? "Ce champ est requis"
                        : errors?.newPassword.message}
                </div>
            )}
            <Row className="nlt-row-form-fix no-height">
                <div className="nlt-item-info-label-edit col-md-5">
                    Nouveau mot de passe
                </div>
                <FormGroup className="col-md-7">
                    <Controller
                        name="newPassword"
                        control={control}
                        rules={{
                            validate: (value) => value?.length > 0,
                            pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                                message:
                                    "Le nouveau mot de passe doit contenir un minimum de 8 caractères incluant 1 chiffre, 1 caractère spécial, 1 minuscule et 1 majuscule",
                            },
                        }}
                        render={({ field: { ref, ...rest } }) => (
                            <Input
                                {...rest}
                                className="form-control"
                                type="password"
                                innerRef={ref}
                            />
                        )}
                    />
                </FormGroup>
            </Row>
            <Row className="nlt-row-form-fix no-height">
                <div className="nlt-item-info-label-edit col-md-5">
                    Confirmer le mot de passe
                </div>
                <FormGroup className="col-md-7">
                    {!errors?.confirmPassword ? (
                        <span className="mandatory-label-small" />
                    ) : (
                        <span className="nlt-mandatory-label-small nlt-font-red">
                            {errors?.confirmPassword && (
                                <span>
                                    {errors?.confirmPassword.type === "validate"
                                        ? "Les mots de passe ne sont pas identiques"
                                        : "Ce champ est requis"}
                                </span>
                            )}
                        </span>
                    )}
                    <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{
                            required: true,
                            validate: (value) =>
                                value === getValues("newPassword"),
                        }}
                        render={({ field: { ref, ...rest } }) => (
                            <Input
                                {...rest}
                                className="form-control"
                                type="password"
                                innerRef={ref}
                            />
                        )}
                    />
                </FormGroup>
            </Row>
            <Row>
                <button
                    type="submit"
                    className="nlt-button-inside-form-save password"
                >
                    Mettre à jour
                </button>
            </Row>
        </Form>
    );
};

export default AccountFormPassword;
