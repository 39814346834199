import Product from "components/business/order/product/product";
import ProductDeliveryDate from "components/business/order/product/product-delivery-date";

import CURRENT_STORAGE_VERSION from "./globalVars";

const removeObsoleteStorage = () => {
    const obsoleteStorages = ["cleaq-cart", "cleaq-cart-v2", "cleaq-cart-v3"];

    obsoleteStorages.forEach((storageVersion) => {
        if (localStorage.getItem(storageVersion)) {
            localStorage.removeItem(storageVersion);
        }
    });
};

export const ClearStorageProducts = () => {
    localStorage.removeItem(CURRENT_STORAGE_VERSION);
};

export const StorageProducts = (): Product[] => {
    let cart: Product[] = [];

    removeObsoleteStorage();

    const localCart = localStorage.getItem(CURRENT_STORAGE_VERSION);
    if (!localCart) {
        return cart;
    }

    try {
        cart = JSON.parse(localCart).map((product: Product) => ({
            ...product,
            deliveryDate: new ProductDeliveryDate(product.shippingDelay),
        }));
    } catch (exception) {
        return [];
    }
    return cart;
};
