import React, { useState } from "react";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

import FiltersTypeEnum from "components/business/catalog/filter/filter-navbar/filters-product-type/filters-type-enum";
import AccessoryIcon from "components/common/icons/catalog/accessory-icon";
import CheckIcon from "components/common/icons/CheckIcon";

import "./filters-accessory-type.scss";

const accessoryList = [
    {
        label: "Écrans",
        type: FiltersTypeEnum.screen,
        "data-cy": "screen",
    },
    {
        label: "Claviers",
        type: FiltersTypeEnum.keyboard,
        "data-cy": "keyboard",
    },
    {
        label: "Souris",
        type: FiltersTypeEnum.mouse,
        "data-cy": "mouse",
    },
    {
        label: "Docks",
        type: FiltersTypeEnum.dockstation,
        "data-cy": "dockstation",
    },
    {
        label: "Audio",
        type: FiltersTypeEnum.audio,
        "data-cy": "audio",
    },
];

interface FiltersAccessoriesTypeProps {
    activeFilter: FiltersTypeEnum;
    toggleFilter: (filter: FiltersTypeEnum) => void;
}

const FiltersAccessoryType = (props: FiltersAccessoriesTypeProps) => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const isActive = () =>
        props.activeFilter === FiltersTypeEnum.screen ||
        props.activeFilter === FiltersTypeEnum.keyboard ||
        props.activeFilter === FiltersTypeEnum.mouse ||
        props.activeFilter === FiltersTypeEnum.dockstation ||
        props.activeFilter === FiltersTypeEnum.audio;

    return (
        <Dropdown
            isOpen={open}
            toggle={handleToggle}
            className={`filters-accessory-type ${
                isActive() ? "active" : "inactive"
            } ${open && "open"}`}
            data-cy={`filters-accessory-type-${
                isActive() ? "active" : "inactive"
            }`}
        >
            <DropdownToggle
                tag="div"
                className="filters-accessory-type-label"
            >
                <AccessoryIcon isActive={isActive()} />
                <span>Accessoires</span>
                <div className="filters-accessory-arrow">
                    {open ? (
                        <ArrowDropUp data-cy="filters-accessory-fold" />
                    ) : (
                        <ArrowDropDown data-cy="filters-accessory-unfold" />
                    )}
                </div>
            </DropdownToggle>
            <DropdownMenu className="dropdown-content">
                {accessoryList.map((accessory) => (
                    <DropdownItem
                        toggle={false}
                        key={accessory.type}
                        href="#"
                        onClick={() => {
                            props.toggleFilter(accessory.type);
                            handleToggle();
                        }}
                        data-cy={`filters-accessory-${accessory["data-cy"]}-select`}
                    >
                        <div className="accessory-dropdown">
                            {accessory.label}
                            <div
                                data-cy={`check-icon-${accessory.label.toLowerCase()}`}
                            >
                                {props.activeFilter === accessory.type && (
                                    <CheckIcon color="#6f6c6c" />
                                )}
                            </div>
                        </div>
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default FiltersAccessoryType;
