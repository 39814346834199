import React from "react";

import { NavLink } from "react-router-dom";

import { OrderSummaryFragment } from "graphql/order/model/apollo";
import displayDate from "utils/date/display-date";
import { defineAddressStatus } from "utils/order-status/order-status";
import { formatPrice } from "utils/translator/translator";

import OrderHistoryItem from "./item/OrderHistoryItem";
import PlaceHolder from "./placeholder/placeholder";

import "./history-items-list.scss";

interface ItemsList {
    orderList: OrderSummaryFragment[] | null | undefined;
    filteredOrderStatus: string[];
    sortOrderByDateAsc: boolean;
    searchTerm: string;
}

const HistoryItemsList = (props: ItemsList) => {
    const filteredOrders = props.orderList
        ?.filter((order) => {
            if (props.filteredOrderStatus?.length === 0) {
                return true;
            }

            return (
                order.subOrders.filter((suborder) => {
                    return props.filteredOrderStatus?.includes(
                        defineAddressStatus(suborder.status).key,
                    );
                }).length > 0
            );
        })
        ?.filter(
            (order) =>
                props.searchTerm === "" ||
                props.searchTerm.length < 2 ||
                order.name
                    ?.toLocaleLowerCase()
                    ?.includes(props.searchTerm.toLocaleLowerCase()) ||
                order.createdAt
                    ?.toLocaleLowerCase()
                    ?.includes(props.searchTerm.toLocaleLowerCase()),
        );

    const sortedOrders = props.sortOrderByDateAsc
        ? filteredOrders
        : filteredOrders?.reverse();

    return (
        <div>
            <div className="box">
                <div className="order-item-list">
                    {sortedOrders && sortedOrders.length > 0 ? (
                        sortedOrders?.map((order, idx) => (
                            <div
                                data-cy={`order-${idx + 1}-item`}
                                key={order.name}
                            >
                                <div
                                    className="order-item-title"
                                    data-cy={`order-${idx + 1}-item-title`}
                                >
                                    <h6 key={order.name}>
                                        Commande {order.name} - Effectuée le{" "}
                                        {displayDate(order.createdAt)}
                                    </h6>
                                    <span data-cy="order-price">
                                        Montant total :{" "}
                                        {formatPrice(order.priceSum)}
                                    </span>
                                </div>
                                <div
                                    className="suborder-items"
                                    data-cy={`order-${idx + 1}-suborder-items`}
                                >
                                    {order.subOrders.map((subOrder) => (
                                        <NavLink
                                            to={`/orders/${subOrder.crmId}`}
                                            className="order-item-link"
                                            key={subOrder.crmId}
                                        >
                                            <OrderHistoryItem
                                                subOrder={subOrder}
                                            />
                                        </NavLink>
                                    ))}
                                </div>
                            </div>
                        ))
                    ) : (
                        <PlaceHolder />
                    )}
                </div>
            </div>
        </div>
    );
};

export default HistoryItemsList;
