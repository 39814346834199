/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useKeycloak } from "@react-keycloak/web";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
    Container,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";

import FormErrorMessage from "components/common/layout/error/form-error-message/form-error-message";
import Initials from "components/common/layout/initials/initials";
import Loader from "components/common/layout/loader/loader";
import {
    DeviceFragment,
    refetchComputeDashboardDataQuery,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    UserRole,
    useAffectUserDeviceMutation,
    useCreateUserMutation,
    useDeleteDevicesAffectationMutation,
    useFindDevicesLazyQuery,
    useFindUsersQuery,
} from "graphql/management/model/apollo";
import displayAlertMessage from "utils/displayAlertMessage/displayAlertMessage";
import EmailRegExp from "utils/email-utils/email-utils";

type AssignUserProps = {
    selectedDevice: DeviceFragment;
    setSelectedDevice: React.Dispatch<
        React.SetStateAction<DeviceFragment | null | undefined>
    >;
};

interface UserForm {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

const AssignUser = (props: AssignUserProps) => {
    const { initialized } = useKeycloak();
    const usersQuery = useFindUsersQuery();
    const [devicesQuery] = useFindDevicesLazyQuery();
    const [saving, setSaving] = useState(false);
    const [modal, setModal] = useState(false);
    const [currentUser, setCurrentUser] = useState<string>("");
    const [createUser] = useCreateUserMutation();

    const [affectUserDevice] = useAffectUserDeviceMutation();
    const [deleteDevicesAffectation] = useDeleteDevicesAffectationMutation();
    const affectRefetches = [
        refetchFindDevicesQuery(),
        refetchFindUsersQuery(),
        refetchFindOrganisationsQuery(),
        refetchComputeDashboardDataQuery(),
    ];

    const history = useHistory();
    const { handleSubmit, control } = useForm<UserForm>({
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
        },
    });

    if (!initialized || usersQuery.loading) return <Loader />;

    function buildPropName(): string {
        if (!props.selectedDevice.affectedUser) {
            return "";
        }
        return `${props.selectedDevice.affectedUser.firstName} ${props.selectedDevice.affectedUser.lastName} (${props.selectedDevice.affectedUser.email})`;
    }

    const ToggleModal = () => {
        setModal(!modal);
        setCurrentUser("");
        if (props.selectedDevice.affectedUser) {
            setCurrentUser(buildPropName());
        }
        setSaving(false);
    };

    const Redirect = (redirect: any) => {
        history.push({
            pathname: redirect,
            state: { mailSearch: props.selectedDevice.affectedUser?.email },
        });
    };

    const refreshSelectedDevice = async () => {
        const { data: devices } = await devicesQuery();
        const updatedDevice = devices?.findDevices.find(
            (device) =>
                device.serialNumber === props.selectedDevice.serialNumber,
        );
        props.setSelectedDevice(updatedDevice);
    };

    const AffectUser = async () => {
        setSaving(true);
        const email: string =
            currentUser.length > 0
                ? currentUser.split("(")[1]?.split(")")[0]
                : "";

        try {
            const affectedUser = usersQuery?.data?.findUser.find(
                (user) => user.email === email,
            );
            if (email?.length > 0 && affectedUser) {
                await affectUserDevice({
                    variables: {
                        serials: props.selectedDevice.serialNumber,
                        email,
                    },
                    refetchQueries: affectRefetches,
                });
                await displayAlertMessage(
                    "L'appareil a bien été affecté.",
                    "success",
                );
            }
        } catch (error) {
            await displayAlertMessage(
                "Vérifiez l'adresse e-mail renseignée.<br/>Si le problème persiste, contactez nous dans le chat",
                "error",
            );
            setSaving(false);
            return;
        } finally {
            await refreshSelectedDevice();
            ToggleModal();
        }
    };

    const addCreateUser = async (data: UserForm) => {
        try {
            await createUser({
                variables: {
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    organisations: null,
                    phoneNumber: "",
                    role: UserRole.USER,
                },
                refetchQueries: [
                    refetchFindUsersQuery(),
                    refetchFindOrganisationsQuery(),
                ],
            });

            setCurrentUser(
                `${data.firstName} ${data.lastName} (${data.email})`,
            );

            await displayAlertMessage(
                "L'utilisateur a bien été créé !",
                "success",
            );
        } catch (e) {
            const message =
                "Vérifiez les informations renseignées." +
                "<br/>Si le problème persiste, contactez nous dans le chat";

            await displayAlertMessage(message, "error");
        }
    };

    const DeleteUserDevice = async () => {
        await deleteDevicesAffectation({
            variables: {
                serials: [props.selectedDevice.serialNumber],
            },
            refetchQueries: affectRefetches,
        });
        await displayAlertMessage(
            "L'appareil a bien été désaffecté.",
            "success",
        );
        await refreshSelectedDevice();
        ToggleModal();
    };

    const UserNames =
        usersQuery.data &&
        usersQuery.data.findUser.map(
            (user) => `${user.firstName} ${user.lastName} (${user.email})`,
        );

    return (
        <>
            <div className="float-right">
                <a
                    aria-hidden="true"
                    className="nlt-button-inside-form"
                    onClick={ToggleModal}
                >
                    <i className="icofont icofont-layers m-r-5" />
                    Gérer
                </a>
                <Modal
                    isOpen={modal}
                    toggle={ToggleModal}
                    className="modal-body"
                    centered
                >
                    <ModalHeader toggle={ToggleModal}>
                        Qui utilise cet appareil ?
                    </ModalHeader>
                    <ModalBody>
                        <Container
                            fluid
                            className="bd-example-row"
                        >
                            <h6 className="mb-3">
                                Choisissez parmi vos utilisateurs :
                            </h6>
                            <FormGroup>
                                <Autocomplete
                                    id="autocomplete"
                                    onChange={(
                                        _: React.ChangeEvent<{}>,
                                        data: string | null,
                                        reason: string,
                                    ) => {
                                        if (reason === "clear") {
                                            setCurrentUser("");
                                        } else {
                                            setCurrentUser(data ?? "");
                                        }
                                    }}
                                    options={UserNames || []}
                                    value={
                                        currentUser === "" ? null : currentUser
                                    }
                                    getOptionSelected={(option, value) =>
                                        option === value
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            color="secondary"
                                            variant="outlined"
                                            placeholder="Choisir un utilisateur..."
                                            fullWidth
                                        />
                                    )}
                                />
                            </FormGroup>
                            <h6 className="mb-3">
                                Ou créez un nouvel utilisateur :
                            </h6>
                            <form
                                className="assign-user-form needs-validation"
                                id="management-assignUser"
                                onSubmit={handleSubmit(addCreateUser)}
                            >
                                <div className="form-group">
                                    <label
                                        htmlFor="email"
                                        className="required"
                                    >
                                        Adresse e-mail
                                    </label>
                                    <div className="input-create-new-user">
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{
                                                required:
                                                    "L'adresse e-mail est requise.",
                                                pattern: {
                                                    value: EmailRegExp,
                                                    message:
                                                        "Le format de l'adresse e-mail est invalide.",
                                                },
                                            }}
                                            render={({
                                                field,
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    {...field}
                                                    data-cy="email"
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="laura.petit@cleaq.com"
                                                    helperText={
                                                        <FormErrorMessage
                                                            message={
                                                                error?.message ??
                                                                ""
                                                            }
                                                        />
                                                    }
                                                    error={
                                                        error?.message !==
                                                            undefined &&
                                                        error?.message?.length >
                                                            0
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor="lastName"
                                        className="required"
                                    >
                                        Nom
                                    </label>
                                    <div className="input-create-new-user">
                                        <Controller
                                            name="lastName"
                                            control={control}
                                            rules={{
                                                required: "Le nom est requis.",
                                            }}
                                            render={({
                                                field,
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    {...field}
                                                    data-cy="lastName"
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Petit"
                                                    helperText={
                                                        <FormErrorMessage
                                                            message={
                                                                error?.message ??
                                                                ""
                                                            }
                                                        />
                                                    }
                                                    error={error !== undefined}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor="firstName"
                                        className="required"
                                    >
                                        Prénom
                                    </label>
                                    <div className="input-create-new-user">
                                        <Controller
                                            name="firstName"
                                            control={control}
                                            rules={{
                                                required:
                                                    "Le prénom est requis.",
                                            }}
                                            render={({
                                                field,
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    {...field}
                                                    data-cy="firstName"
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Laura"
                                                    helperText={
                                                        <FormErrorMessage
                                                            message={
                                                                error?.message ??
                                                                ""
                                                            }
                                                        />
                                                    }
                                                    error={
                                                        error?.message !==
                                                            undefined &&
                                                        error.message.length > 0
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="nlt-button-add-sm"
                                    >
                                        <i className="fa fa-plus nlt-button-icon-plus" />
                                        Ajouter
                                    </button>
                                </div>
                            </form>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        {!saving ? (
                            <>
                                <button
                                    type="button"
                                    className="nlt-button-inside-form-cancel"
                                    onClick={ToggleModal}
                                >
                                    Annuler
                                </button>
                                {props.selectedDevice &&
                                props.selectedDevice.affectedUser &&
                                currentUser === buildPropName() ? (
                                    <button
                                        type="button"
                                        className="nlt-button-inside-form-delete"
                                        onClick={DeleteUserDevice}
                                    >
                                        Retirer l'utilisateur
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="nlt-button-inside-form-save"
                                        onClick={AffectUser}
                                        disabled={currentUser === ""}
                                    >
                                        Affecter l'utilisateur
                                    </button>
                                )}
                            </>
                        ) : (
                            <div className="nlt-loader-container-modal">
                                <div className="nlt-loader-save" />
                            </div>
                        )}
                    </ModalFooter>
                </Modal>
            </div>
            <h6 className="mb-4">Utilisateur associé</h6>
            {props.selectedDevice.affectedUser ? (
                <div
                    className="table table-bordernone"
                    style={{ display: "table" }}
                >
                    <div style={{ display: "table-row-group" }}>
                        <div
                            style={{ display: "table-row" }}
                            key={props.selectedDevice.affectedUser.email}
                        >
                            <Initials
                                firstName={
                                    props.selectedDevice.affectedUser.firstName
                                }
                                lastName={
                                    props.selectedDevice.affectedUser.lastName
                                }
                                sizeImage={30}
                                customStyle={{ float: "left" }}
                            />
                            <div className="nlt-contact-inside-form">
                                {props.selectedDevice.affectedUser.firstName}{" "}
                                {props.selectedDevice.affectedUser.lastName}
                            </div>
                            <div
                                aria-hidden="true"
                                className="nlt-button-inside-user"
                                onClick={() =>
                                    Redirect(`${process.env.PUBLIC_URL}/users`)
                                }
                            >
                                Voir
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <li>Aucun utilisateur associé !</li>
            )}
        </>
    );
};

export default AssignUser;
