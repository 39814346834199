/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
    Container,
    DropdownItem,
    DropdownMenu,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { OrganisationOption } from "components/business/user/types";
import {
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    useAffectUsersOrganisationsMutation,
    useDeleteUsersOrganisationsMutation,
    UserFragment,
} from "graphql/management/model/apollo";

type AssignMultipleTagProps = {
    selectedUser: UserFragment[];
    setSelectedUser: React.Dispatch<React.SetStateAction<UserFragment[]>>;
    OrganisationOptions: OrganisationOption[];
};

type FormContent = {
    organisations: OrganisationOption[];
};

const CleaqSwal = withReactContent(Swal);

const AssignMultipleTag = (props: AssignMultipleTagProps) => {
    const { handleSubmit, control } = useForm<FormContent>({
        defaultValues: {
            organisations: [],
        },
    });

    const organisationRefetches = [
        refetchFindUsersQuery(),
        refetchFindOrganisationsQuery(),
        refetchFindDevicesQuery(),
    ];
    const [affectUsersOrganisations] = useAffectUsersOrganisationsMutation({
        refetchQueries: organisationRefetches,
    });
    const [deleteUsersOrganisations] = useDeleteUsersOrganisationsMutation({
        refetchQueries: organisationRefetches,
    });

    const [tagAffectationModal, setTagAffectationModal] =
        useState<boolean>(false);
    const [tagRemovingModal, setTagRemovingModal] = useState<boolean>(false);

    const [saving, setSaving] = useState<boolean>(false);

    const ToggleTagAffectationModal = () => {
        setTagAffectationModal(!tagAffectationModal);
    };

    const ToggleTagRemovingModal = () => {
        setTagRemovingModal(!tagRemovingModal);
    };

    const userEmails = props.selectedUser.map((user) => user.email);

    const AffectTag = async (dataPush: FormContent) => {
        setSaving(true);
        const orgIds = dataPush.organisations.map((orga) => orga.value);
        try {
            await affectUsersOrganisations({
                variables: {
                    emails: userEmails,
                    organisationsIds: orgIds,
                },
            });
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Contactez-nous dans le chat !",
                icon: "error",
            });
        }
        setSaving(false);
        props.setSelectedUser([]);
        ToggleTagAffectationModal();
    };

    const RemovingTag = async (dataPush: FormContent) => {
        setSaving(true);
        const orgIds = dataPush.organisations.map((orga) => orga.value);
        try {
            await deleteUsersOrganisations({
                variables: {
                    emails: userEmails,
                    organisationsIds: orgIds,
                },
            });
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Contactez-nous dans le chat !",
                icon: "error",
            });
        }
        setSaving(false);
        props.setSelectedUser([]);
        ToggleTagRemovingModal();
    };

    return (
        <>
            <DropdownMenu className="dropdown-content">
                <DropdownItem onClick={ToggleTagAffectationModal}>
                    Affecter des tags
                </DropdownItem>
                <DropdownItem onClick={ToggleTagRemovingModal}>
                    Retirer des tags
                </DropdownItem>
            </DropdownMenu>
            <Modal
                isOpen={tagAffectationModal}
                toggle={ToggleTagAffectationModal}
                className="modal-body"
                centered
            >
                <Form onSubmit={handleSubmit(AffectTag)}>
                    <ModalHeader toggle={ToggleTagAffectationModal}>
                        A quel(s) tag(s) appartiennent ces utilisateurs ?
                    </ModalHeader>
                    <ModalBody>
                        <Container
                            fluid
                            className="bd-example-row"
                        >
                            <h6 className="mb-3">
                                Choisissez parmi vos tags :
                            </h6>
                            <FormGroup className="col-md-12">
                                <Controller
                                    control={control}
                                    name="organisations"
                                    render={({ field }) => (
                                        <Select
                                            options={props.OrganisationOptions}
                                            {...field}
                                            placeholder="Tags..."
                                            noOptionsMessage={() => "Aucun tag"}
                                            isMulti
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        {!saving ? (
                            <>
                                <button
                                    className="nlt-button-inside-form-cancel"
                                    type="button"
                                    onClick={ToggleTagAffectationModal}
                                >
                                    Annuler
                                </button>
                                <button
                                    className="nlt-button-inside-form-save"
                                    type="submit"
                                >
                                    Affecter le/les tag(s)
                                </button>
                            </>
                        ) : (
                            <div className="nlt-loader-container-modal">
                                <div className="nlt-loader-save" />
                            </div>
                        )}
                    </ModalFooter>
                </Form>
            </Modal>
            <Modal
                isOpen={tagRemovingModal}
                toggle={ToggleTagRemovingModal}
                className="modal-body"
                centered
            >
                <Form onSubmit={handleSubmit(RemovingTag)}>
                    <ModalHeader toggle={ToggleTagRemovingModal}>
                        A quel(s) tag(s) n'appartiennent pas ces utilisateurs ?
                    </ModalHeader>
                    <ModalBody>
                        <Container
                            fluid
                            className="bd-example-row"
                        >
                            <h6 className="mb-3">
                                Choisissez parmi vos tags :
                            </h6>
                            <FormGroup className="col-md-12">
                                <Controller
                                    control={control}
                                    name="organisations"
                                    render={({ field }) => (
                                        <Select
                                            options={props.OrganisationOptions}
                                            {...field}
                                            placeholder="Tags..."
                                            noOptionsMessage={() => "Aucun tag"}
                                            isMulti
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        {!saving ? (
                            <>
                                <button
                                    className="nlt-button-inside-form-cancel"
                                    type="button"
                                    onClick={ToggleTagRemovingModal}
                                >
                                    Annuler
                                </button>
                                <button
                                    className="nlt-button-inside-form-save"
                                    type="submit"
                                >
                                    Retirer le/les tag(s)
                                </button>
                            </>
                        ) : (
                            <div className="nlt-loader-container-modal">
                                <div className="nlt-loader-save" />
                            </div>
                        )}
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};

export default AssignMultipleTag;
