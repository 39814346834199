import React from "react";

const MagnifyingGlassIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.2132 18.2764C17.5611 18.6178 18.125 18.6178 18.4728 18.2764C18.8206 17.9351 18.8206 17.3817 18.4728 17.0403L17.2132 18.2764ZM13.3888 7.7814C13.3888 10.8551 10.85 13.3469 7.71814 13.3469V15.0951C11.8337 15.0951 15.17 11.8206 15.17 7.7814H13.3888ZM7.71814 13.3469C4.58632 13.3469 2.04748 10.8551 2.04748 7.7814H0.266235C0.266235 11.8206 3.60257 15.0951 7.71814 15.0951V13.3469ZM2.04748 7.7814C2.04748 4.7077 4.58632 2.21597 7.71814 2.21597V0.467773C3.60257 0.467773 0.266235 3.74219 0.266235 7.7814H2.04748ZM7.71814 2.21597C10.85 2.21597 13.3888 4.7077 13.3888 7.7814H15.17C15.17 3.74219 11.8337 0.467773 7.71814 0.467773V2.21597ZM11.1567 12.3323L17.2132 18.2764L18.4728 17.0403L12.4162 11.0961L11.1567 12.3323Z"
                fill="#494949"
            />
        </svg>
    );
};

export default MagnifyingGlassIcon;
