import { AccessoryType } from "components/business/catalog/utils/types";
import { VariantFragment } from "graphql/catalog/model/apollo";

export default class TempCart {
    variant: VariantFragment;

    suggestedAccessories: AccessoryType[];

    quantity: number;

    leasingDuration: number;

    constructor(
        variant: VariantFragment,
        suggestedAccessories: AccessoryType[],
        quantity: number,
        leasingDuration: number,
    ) {
        this.suggestedAccessories = suggestedAccessories;
        this.variant = variant;
        this.quantity = quantity;
        this.leasingDuration = leasingDuration;
    }
}
