/* eslint-disable react/button-has-type */
import React, { useState } from "react";

import { useKeycloak } from "@react-keycloak/web";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { Row } from "reactstrap";

import CreateTag from "components/business/tag/create/create-tag";
import DeleteMultipleTags from "components/business/tag/delete/delete-multiple-tags";
import TagDetails from "components/business/tag/details/tag-details";
import TagPlaceHolder from "components/business/tag/items/placeholder/tag-placeholder";
import TagItem from "components/business/tag/items/tag/tag-item";
import TagList from "components/business/tag/layout/list/tag-list";
import type { TagType } from "components/business/tag/types";
import SmallScreen from "components/common/layout/banner/small-screen";
import SkeletonItem from "components/common/layout/loader/skeleton-item";
import MultipleItemSelector from "components/common/layout/multiple-item-selector/multiple-item-selector";
import SearchBar from "components/common/layout/searchbar/searchbar";
import {
    OrganisationFragment,
    useFindOrganisationsQuery,
} from "graphql/management/model/apollo";

const Tag = () => {
    const { initialized } = useKeycloak();
    const instanceGroups = useFindOrganisationsQuery();

    const [selectedOrganisation, setSelectedOrganisation] =
        useState<OrganisationFragment | null>();
    const [editable, setEditable] = useState(false);
    const [isCreateOrg, setIsCreateOrg] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [saving, setSaving] = useState(false);

    const tagForm = useForm<TagType>({
        defaultValues: {
            name: "",
            hexaColor: "",
        },
    }); // initialise the hook
    const { reset } = tagForm;
    const [colorValue, setColorValue] = useState("");
    const [selectedItems, setSelectedItems] = useState<OrganisationFragment[]>(
        [],
    );

    const BuildOrganisations =
        instanceGroups?.data &&
        instanceGroups?.data.findOrganisations
            // eslint-disable-next-line array-callback-return, consistent-return
            ?.filter((organisation) => {
                if (searchTerm === "" || searchTerm.length < 2) {
                    return organisation;
                }
                if (
                    organisation?.name
                        ?.toLocaleLowerCase()
                        ?.includes(searchTerm.toLocaleLowerCase())
                ) {
                    return organisation;
                }
            });

    const handleSearchChange = (event: any) => {
        event.preventDefault();
        setSearchTerm(event.target.value);
        setSelectedOrganisation(null);
    };

    const CancelEdit = () => {
        reset();
        setEditable(false);
        setIsCreateOrg(false);
        setColorValue("");
        setSaving(false);
    };

    return (
        <>
            <div className="nlt-container">
                <div className="nlt-container-scale email-wrap bookmark-wrap">
                    <div className="nlt-card-left-panel">
                        <div
                            className="nlt-card-header flex-sb"
                            style={{ height: "50px" }}
                        >
                            <span className="nlt-card-title align-title">
                                Tags
                            </span>
                            <div>
                                {selectedItems.length > 0 ? (
                                    <DeleteMultipleTags
                                        selectedTags={selectedItems}
                                        setSelectedTags={setSelectedItems}
                                    />
                                ) : (
                                    <button
                                        className="nlt-button-add"
                                        onClick={() => {
                                            reset({
                                                name: "",
                                                hexaColor: "",
                                            });
                                            setEditable(false);
                                            setIsCreateOrg(true);
                                            setColorValue("");
                                            setSelectedOrganisation(null);
                                        }}
                                    >
                                        <i className="fa fa-plus nlt-button-icon-plus" />
                                        Nouveau tag
                                    </button>
                                )}
                            </div>
                        </div>
                        <SearchBar
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            handleSearchChange={handleSearchChange}
                        />
                        <Row
                            style={{
                                marginLeft: "-2%",
                                width: "102%",
                                marginBottom: "10px",
                            }}
                        >
                            <div
                                className="nlt-wide-header"
                                style={{
                                    marginLeft: "2.5%",
                                    marginRight: "2.5%",
                                }}
                            >
                                <div
                                    style={{
                                        width: "8.33333%",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginLeft: "11px",
                                    }}
                                >
                                    <MultipleItemSelector
                                        allElements={BuildOrganisations?.length}
                                        selectedElements={selectedItems.length}
                                        onClick={() => {
                                            setSelectedOrganisation(null);
                                            setIsCreateOrg(false);
                                            if (
                                                selectedItems.length ===
                                                BuildOrganisations?.length
                                            ) {
                                                setSelectedItems([]);
                                            } else {
                                                setSelectedItems(
                                                    BuildOrganisations!,
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </Row>

                        <div className="email-body radius-left">
                            <div className="p-0">
                                {!initialized || instanceGroups.loading ? (
                                    <SkeletonItem />
                                ) : (
                                    <Row
                                        className="list-persons"
                                        id="addcon"
                                    >
                                        <TagList>
                                            {BuildOrganisations &&
                                            BuildOrganisations.length > 0 ? (
                                                BuildOrganisations?.map(
                                                    (organisation) => (
                                                        <TagItem
                                                            tag={organisation}
                                                            key={
                                                                organisation.id
                                                            }
                                                            selectedTag={
                                                                selectedOrganisation
                                                            }
                                                            setSelectedTag={
                                                                setSelectedOrganisation
                                                            }
                                                            selectedTags={
                                                                selectedItems
                                                            }
                                                            setSelectedTags={
                                                                setSelectedItems
                                                            }
                                                            setIsCreateOrg={
                                                                setIsCreateOrg
                                                            }
                                                            cancelEdit={
                                                                CancelEdit
                                                            }
                                                            resetForm={reset}
                                                        />
                                                    ),
                                                )
                                            ) : (
                                                <TagPlaceHolder />
                                            )}
                                        </TagList>
                                        {selectedOrganisation && (
                                            <TagDetails
                                                tagForm={tagForm}
                                                editable={editable}
                                                setEditable={setEditable}
                                                saving={saving}
                                                setSaving={setSaving}
                                                colorValue={colorValue}
                                                setColorValue={setColorValue}
                                                selectedTag={
                                                    selectedOrganisation
                                                }
                                                setSelectedTag={
                                                    setSelectedOrganisation
                                                }
                                                cancelEdit={CancelEdit}
                                            />
                                        )}
                                        {isCreateOrg && (
                                            <CreateTag
                                                tagForm={tagForm}
                                                saving={saving}
                                                setSaving={setSaving}
                                                colorValue={colorValue}
                                                setColorValue={setColorValue}
                                                setSelectedTag={
                                                    setSelectedOrganisation
                                                }
                                                setIsCreateOrg={setIsCreateOrg}
                                                cancelEdit={CancelEdit}
                                            />
                                        )}
                                    </Row>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SmallScreen />
        </>
    );
};

export default withRouter(Tag);
