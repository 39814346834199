import React, { useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory, useParams } from "react-router";
import { withRouter } from "react-router-dom";

import Cart from "components/business/catalog/cart/cart";
import CartUnavailableProduct from "components/business/catalog/cart/cart-unavailable-product/cart-unavailable-product";
import { StorageProducts } from "components/business/catalog/cart/utils";
import Catalog from "components/business/catalog/catalog";
import FiltersNavbar from "components/business/catalog/filter/filter-navbar/filter-navbar";
import { FilterNames } from "components/business/catalog/filter/filters";
import AssignAddress from "components/business/order/step-form/AssignAddress/AssignAddress";
import AssignUser from "components/business/order/step-form/AssignUserOrder/AssignUserOrder";
import OrderNav from "components/business/order/step-form/nav/nav";
import OrderValidation, {
    ValidateType,
} from "components/business/order/step-form/order-validation/order-validation";
import Signatory from "components/business/order/step-form/Signatory/Signatory";
import OrderSummary from "components/business/order/step-form/summary/summary";
import SmallScreen from "components/common/layout/banner/small-screen";
import InitCart from "components/provider/context/cart/actions/init-cart";
import CartProvider, {
    useCart,
    useCartDispatch,
} from "components/provider/context/cart/cart-provider";
import SetCatalogFiltersAction from "components/provider/context/catalog-filters/actions/set-catalog-filters-action";
import CatalogFiltersProvider, {
    useCatalogFiltersDispatch,
} from "components/provider/context/catalog-filters/catalog-filters-provider";
import ApiClientNames from "graphql/consts";
import {
    BankDetailsFragment,
    useFindBankDetailsQuery,
    useFindCurrentUserQuery,
    useFindInstanceQuery,
} from "graphql/management/model/apollo";
import {
    DeliveryDateInput,
    LeasingDurationInput,
    ProductInput,
    useCreateOrderMutation,
    useFindOrdersLazyQuery,
} from "graphql/order/model/apollo";
import hasMissingAddress from "utils/missing-address/missing-address";
import useEffectOnce from "utils/use-effect-once/use-effect-once";

import "./create-order.scss";

interface NewOrderUrlParams {
    stage: string;
}

const NewOrder = (props: any) => {
    const cart = useCart();
    const cartDispatch = useCartDispatch();
    const catalogFiltersDispatch = useCatalogFiltersDispatch();
    const [step, setStep] = useState<number>(1);
    const [resetCatalogDisplay, setResetCatalogDisplay] =
        useState<boolean>(true);
    const [orderCreated, setOrderCreated] = useState<boolean>(false);
    const currentUser = useFindCurrentUserQuery();
    const currentInstance = useFindInstanceQuery();
    const [createOrderMutation, createOrderStatus] = useCreateOrderMutation({
        context: { clientName: ApiClientNames.ORDER },
    });
    const [findOrdersQuery] = useFindOrdersLazyQuery({
        context: { clientName: ApiClientNames.ORDER },
    });
    const urlParams: NewOrderUrlParams = useParams();

    const history = useHistory();

    useEffectOnce(() => {
        cartDispatch(new InitCart());
    });

    const bankDetailsQuery = useFindBankDetailsQuery();

    const defaultBankDetails =
        bankDetailsQuery.data === undefined ||
        bankDetailsQuery.data.currentInstance === null ||
        bankDetailsQuery.data.currentInstance.bankDetails === null
            ? ({
                  iban: "",
                  bic: "",
              } as BankDetailsFragment)
            : bankDetailsQuery.data.currentInstance.bankDetails;

    const hasMissingBankDetails: boolean =
        defaultBankDetails.iban === "" || defaultBankDetails.bic === "";

    useEffect(() => {
        if (StorageProducts().length > 0) {
            switch (urlParams.stage) {
                case "catalog":
                    setStep(1);
                    break;
                case "user":
                    setStep(2);
                    break;
                case "address":
                    setStep(3);
                    break;
                case "signatory":
                    setStep(4);
                    break;
                case "review":
                    if (hasMissingAddress(StorageProducts())) {
                        setStep(3);
                    } else if (hasMissingBankDetails) {
                        setStep(4);
                    } else {
                        setStep(5);
                    }

                    break;
                default:
                    setStep(1);
            }
        } else if (StorageProducts().length < 1) {
            window.history.pushState(null, "", "catalog");
            setStep(1);
        }
        // TODO: stop mixing native and react router history
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.history.state]);

    useEffect(() => {
        switch (step) {
            case 2:
                window.history.pushState(null, "", "user");
                break;
            case 3:
                window.history.pushState(null, "", "address");
                break;
            case 4:
                window.history.pushState(null, "", "signatory");
                break;
            case 5:
                window.history.pushState(null, "", "review");
                break;
            case 6:
                window.history.pushState(null, "", "validation");
                break;
            default:
                window.history.pushState(null, "", "catalog");
                setResetCatalogDisplay(true);
                setStep(1);
        }
    }, [step]);

    const intToLeasingDurationInput = (
        duration: number,
    ): LeasingDurationInput => {
        switch (duration) {
            case 24:
                return LeasingDurationInput.MONTHS_24;

            case 36:
                return LeasingDurationInput.MONTHS_36;

            case 48:
                return LeasingDurationInput.MONTHS_48;

            default:
                return LeasingDurationInput.MONTHS_36;
        }
    };

    const createOrder = async () => {
        try {
            await createOrderMutation({
                variables: {
                    client: {
                        companyId: currentInstance.data?.currentInstance?.id!,
                        companyName:
                            currentInstance.data?.currentInstance?.name ||
                            "No Name",
                        userEmail: currentUser.data?.findCurrentUser?.email!,
                        userName: `${currentUser.data?.findCurrentUser?.firstName} ${currentUser.data?.findCurrentUser?.lastName}`,
                        companyCrmId:
                            currentInstance.data?.currentInstance?.crmId,
                        affectedCatalogs:
                            currentInstance.data?.currentInstance
                                ?.affectedCatalogs,
                    },
                    products: cart.map(
                        (product) =>
                            ({
                                variantId: product.variantId,
                                leasingDuration: intToLeasingDurationInput(
                                    product.leasingDuration,
                                ),
                                reference: product.name,
                                user: product.user
                                    ? {
                                          email: product.user?.email,
                                          firstName: product.user?.firstName,
                                          lastName: product.user?.lastName,
                                      }
                                    : null,
                                address: {
                                    id: product.address?.id,
                                    name: product.address?.name,
                                    recipient: product.address?.recipient,
                                    label: product.address?.label,
                                    zipcode: product.address?.zipcode,
                                    cityName: product.address?.cityName,
                                    country: product.address?.country,
                                    instructions:
                                        product.address?.instructions || "",
                                    phoneNumber: product.address?.phoneNumber,
                                },
                                instructions: product.instructions || "",
                                deliveryDate: {
                                    date: product.deliveryDate.date,
                                    deliveryType:
                                        product.deliveryDate.deliveryType,
                                } as DeliveryDateInput,
                            } as ProductInput),
                    ),
                },
            });
            setOrderCreated(true);
        } catch (error) {
            setOrderCreated(false);
        } finally {
            await findOrdersQuery();
            setStep(6);
        }
    };

    const redirectTo = (redirect: any, search: any) => {
        history.push({
            pathname: redirect,
            state: { mailSearch: search },
        });
    };

    const backToFirstStep = () => {
        if (cart.length === 0 && (step === 2 || step === 3)) {
            setStep(0);
            setResetCatalogDisplay(true);
        }
    };

    useEffect(() => {
        backToFirstStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);

    // MVP Mcdo
    const isMcdoCatalog = () => {
        const affectedCatalogs =
            currentInstance.data?.currentInstance?.affectedCatalogs ?? [];

        return affectedCatalogs.includes("mcdo");
    };

    return (
        <>
            <div className="order-step-form-container">
                {step > 0 && (
                    <div
                        className={`order-header ${
                            step === 1 && "order-header-catalog"
                        }`}
                    >
                        {!orderCreated && (
                            <div
                                id="retour"
                                className={
                                    step > 1 && step < 6 ? "visible" : "hidden"
                                }
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        catalogFiltersDispatch(
                                            new SetCatalogFiltersAction(
                                                FilterNames.type,
                                                "laptop",
                                            ),
                                        );
                                        setStep(step - 1);
                                        setResetCatalogDisplay(true);
                                    }}
                                    startIcon={<ChevronLeft />}
                                    disabled={step < 2}
                                >
                                    Retour
                                </Button>
                            </div>
                        )}

                        {step >= 2 && step <= 6 ? (
                            <OrderNav
                                step={step}
                                setStep={setStep}
                                orderCreated={orderCreated}
                            />
                        ) : (
                            <FiltersNavbar
                                isFiltersProductTypeVisible={!isMcdoCatalog()}
                                setResetCatalogDisplay={setResetCatalogDisplay}
                            />
                        )}
                        {step > 0 &&
                            step !== 4 &&
                            step !== 5 &&
                            step !== 6 &&
                            cart.length > 0 && (
                                <Cart
                                    onValidate={() => {
                                        catalogFiltersDispatch(
                                            new SetCatalogFiltersAction(
                                                FilterNames.type,
                                                "laptop",
                                            ),
                                        );
                                        setStep(2);
                                    }}
                                    catalog={step === 1}
                                />
                            )}
                    </div>
                )}
                <CartUnavailableProduct />
                <div className="order-container">
                    <div>
                        {step === 0 && (
                            <div className="products-container">
                                <div className="no-products">
                                    <div style={{ fontSize: "20px" }}>
                                        Vous n'avez rien dans votre panier !
                                    </div>
                                    <div
                                        aria-hidden="true"
                                        className="cleaq-button"
                                        id="toCatalogButton"
                                        onClick={() => setStep(1)}
                                    >
                                        Retour au catalogue
                                    </div>
                                </div>
                            </div>
                        )}
                        {step === 1 &&
                            !currentInstance.loading &&
                            currentInstance.data?.currentInstance && (
                                <Catalog
                                    instance={
                                        currentInstance.data?.currentInstance
                                    }
                                    resetCatalogDisplay={resetCatalogDisplay}
                                    setResetCatalogDisplay={
                                        setResetCatalogDisplay
                                    }
                                />
                            )}
                        {step === 2 && (
                            <>
                                <AssignUser />
                                <Button
                                    type="button"
                                    id="nextButton"
                                    className="cleaq-button"
                                    onClick={() => setStep(step + 1)}
                                    data-cy="continue-to-address-step"
                                >
                                    Continuer
                                </Button>
                            </>
                        )}
                        {step === 3 && (
                            <>
                                <AssignAddress />
                                <Button
                                    id="nextButton"
                                    type="button"
                                    className={`cleaq-button ${
                                        hasMissingAddress(cart) && "disabled"
                                    }`}
                                    onClick={() => {
                                        if (!hasMissingAddress(cart)) {
                                            setStep(step + 1);
                                        }
                                    }}
                                    data-cy="continue-to-signatory-step"
                                >
                                    Continuer
                                </Button>
                            </>
                        )}
                        {step === 4 && (
                            <>
                                <Signatory
                                    defaultBankDetails={defaultBankDetails}
                                />
                                <Button
                                    id="nextButton"
                                    type="button"
                                    className={`cleaq-button ${
                                        hasMissingBankDetails && "disabled"
                                    }`}
                                    onClick={() => {
                                        if (!hasMissingBankDetails) {
                                            setStep(step + 1);
                                        }
                                    }}
                                    data-cy="continue-to-order-summary-step"
                                >
                                    Continuer
                                </Button>
                            </>
                        )}
                        {step === 5 && (
                            <OrderSummary
                                onCreateOrder={createOrder}
                                loading={createOrderStatus.loading}
                            />
                        )}
                        {step === 6 && !props.orderCreated && (
                            <OrderValidation
                                type={
                                    orderCreated
                                        ? ValidateType.SUCCESS
                                        : ValidateType.ERROR
                                }
                                setStep={setStep}
                                onReturnCatalog={() =>
                                    redirectTo(
                                        `${process.env.PUBLIC_URL}/orders`,
                                        "",
                                    )
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <SmallScreen />
        </>
    );
};

const NewOrderWrapper = (props: any) => (
    <CartProvider>
        <CatalogFiltersProvider>
            <NewOrder {...props} />
        </CatalogFiltersProvider>
    </CartProvider>
);

export default withRouter(NewOrderWrapper);
