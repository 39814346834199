import React from "react";

const AddressPlaceholder = () => (
    <>
        <div className="address-wrapper add-address">
            <div>
                <div className="address-list-wrapper" />
                <div
                    className="nlt-document-placeholder"
                    style={{ marginLeft: "20px" }}
                >
                    <div className="nlt-document-placeholder-icon">
                        <i className="fa fa-crosshairs" />
                    </div>
                    <div className="nlt-document-info">
                        <div className="nlt-document-title">Aucune Adresse</div>
                        <div className="nlt-document-date">
                            Retrouvez vos adresses de livraison ici
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default AddressPlaceholder;
