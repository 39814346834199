import React from "react";

const InformationIcon = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 0.125C6.44248 0.125 4.91992 0.586861 3.62489 1.45218C2.32985 2.31749 1.32049 3.5474 0.724452 4.98637C0.128412 6.42534 -0.0275391 8.00874 0.276319 9.53634C0.580178 11.0639 1.3302 12.4671 2.43154 13.5685C3.53288 14.6698 4.93607 15.4198 6.46367 15.7237C7.99127 16.0275 9.57467 15.8716 11.0136 15.2756C12.4526 14.6795 13.6825 13.6702 14.5478 12.3751C15.4131 11.0801 15.875 9.55753 15.875 8C15.875 5.91142 15.0453 3.90838 13.5685 2.43153C12.0916 0.954685 10.0886 0.125 8 0.125ZM8 3.5C8.16688 3.5 8.33001 3.54948 8.46877 3.6422C8.60752 3.73491 8.71566 3.86669 8.77953 4.02086C8.84339 4.17504 8.8601 4.34469 8.82754 4.50836C8.79498 4.67203 8.71463 4.82237 8.59662 4.94037C8.47862 5.05837 8.32828 5.13873 8.16461 5.17129C8.00094 5.20384 7.83129 5.18713 7.67711 5.12327C7.52294 5.05941 7.39116 4.95127 7.29845 4.81251C7.20574 4.67376 7.15625 4.51063 7.15625 4.34375C7.15625 4.11997 7.24515 3.90536 7.40338 3.74713C7.56162 3.58889 7.77623 3.5 8 3.5ZM10.25 12.5703H5.75V11.3047H7.36719V8.07031H6.3125V6.80469H8.63282V11.3047H10.25V12.5703Z"
            fill="#1D1D1B"
        />
    </svg>
);

export default InformationIcon;
