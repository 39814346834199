class TranslatorConnectivity {
    private dictionary = new Map([
        ["wired", "Filaire"],
        ["wireless", "Sans fil"],
        ["bluetooth", "Bluetooth"],
        ["hdmi", "HDMI"],
        ["display", "Display Port"],
        ["vga", "VGA"],
        ["usb", "USB"],
        ["usb 2.0", "USB 2.0"],
        ["usb 3.0", "USB 3.0"],
        ["usb-c", "USB-C"],
        ["rj-45", "Ethernet RJ-45"],
        ["ethernet", "Ethernet RJ-45"],
        ["sd card", "Carte SD"],
        ["microsd card", "Carte MicroSD"],
        ["audio output", "Sortie audio"],
        ["jack", "Prise jack"],
    ]);

    translate = (value: string | null, withSpace: boolean = false) => {
        if (value === null) {
            return "";
        }

        const space = withSpace ? " " : "";

        return (
            (this.dictionary.has(value.toString())
                ? this.dictionary.get(value.toString())
                : value.toString()) + space
        );
    };
}

const translatorConnectivity = new TranslatorConnectivity();

export default translatorConnectivity;
