import React from "react";

import { Nav, TabContent, TabPane } from "reactstrap";

import { UserFragment } from "graphql/management/model/apollo";

import UserItem from "./item/user-item";
import UserPlaceHolder from "./placeholder/user-placeholder";

interface UserListInterface {
    activeTab: string;
    buildUsers: UserFragment[] | undefined;
    cancelEdit: () => void;
    selectedUser: UserFragment | null | undefined;
    setSelectedUser: React.Dispatch<
        React.SetStateAction<UserFragment | null | undefined>
    >;
    selectedUsers: UserFragment[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<UserFragment[]>>;
    setIsCreateUser: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserList = (props: UserListInterface) => (
    <div className="nlt-wide-devices">
        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="1">
                <div className="nlt-dev-1">
                    <div className="nlt-dev-2">
                        <Nav className="nlt-dev-3">
                            {props.buildUsers && props.buildUsers.length > 0 ? (
                                props.buildUsers?.map((user) => (
                                    <UserItem
                                        user={user}
                                        key={user.email}
                                        cancelEdit={props.cancelEdit}
                                        selectedUser={props.selectedUser}
                                        setSelectedUser={props.setSelectedUser}
                                        selectedUsers={props.selectedUsers}
                                        setSelectedUsers={
                                            props.setSelectedUsers
                                        }
                                        setIsCreateUser={props.setIsCreateUser}
                                    />
                                ))
                            ) : (
                                <UserPlaceHolder />
                            )}
                        </Nav>
                    </div>
                </div>
            </TabPane>
        </TabContent>
    </div>
);

export default UserList;
