/* eslint-disable max-len */
import React from "react";

const Logo = () => (
    <svg
        height="40"
        viewBox="0 0 233 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M230.882 49.874C232.262 46.8064 232.952 43.5336 232.952 40.0651C232.952 35.6362 231.982 31.7201 230.033 28.3168C228.094 24.9136 225.371 22.2562 221.884 20.3541C218.397 18.452 214.397 17.501 209.884 17.501C205.371 17.501 201.371 18.452 197.884 20.3541C194.397 22.2562 191.684 24.9229 189.735 28.3448C187.796 31.7761 186.817 35.6828 186.817 40.0651C186.817 44.4474 187.786 48.2889 189.735 51.6922C191.674 55.0954 194.397 57.7621 197.884 59.6828C201.371 61.6036 205.353 62.564 209.828 62.564C210.248 62.564 210.705 62.5173 211.208 62.4428L225.064 71.5337L232.961 65.3891V63.0115L224.933 57.7434C227.525 55.5709 229.511 52.9509 230.891 49.874H230.882ZM218.239 41.1374C218.127 43.3099 217.278 45.1933 215.693 46.7784C214.108 48.3635 212.197 49.156 209.968 49.156C207.74 49.156 205.707 48.3542 204.038 46.7318C202.369 45.1187 201.53 43.142 201.53 40.8017C201.53 38.4614 202.35 36.5593 203.992 34.9089C205.633 33.2679 207.628 32.4474 209.968 32.4474C212.085 32.4474 213.968 33.2306 215.609 34.7877C217.25 36.3448 218.127 38.2469 218.239 40.466V41.1374Z"
            fill="#FF625A"
        />
        <path
            d="M58.2849 57.4919C55.6089 54.4056 54.2662 50.1818 54.2662 44.8205V4.4382L72.5133 1.76221V43.5245C72.5133 45.1376 72.905 46.3031 73.6789 47.0303C74.4527 47.7483 75.8234 48.1119 77.7907 48.1119C79.2266 48.1119 80.6439 47.7669 82.0238 47.0769L82.6299 59.1889C78.7698 61.1469 74.4434 62.1259 69.6602 62.1259C64.877 62.1259 60.9702 60.5874 58.2849 57.5012V57.4919Z"
            fill="#FF625A"
        />
        <path
            d="M94.6024 59.226C90.8821 57.2959 87.9824 54.6385 85.9124 51.2726C83.8332 47.8973 82.7982 44.0838 82.7982 39.8134C82.7982 35.543 83.8611 31.5429 85.9963 28.1397C88.1316 24.7364 91.0127 22.1164 94.649 20.2702C98.2854 18.4241 102.313 17.501 106.761 17.501C111.209 17.501 114.677 18.4427 117.698 20.3075C120.728 22.1816 122.985 24.7178 124.486 27.9159C125.987 31.114 126.733 34.7038 126.733 38.6852C126.733 39.7201 126.677 40.4754 126.556 40.9322L100.616 44.3075C101.306 46.3308 102.435 47.7854 103.992 48.6712C105.549 49.5663 107.591 50.0138 110.136 50.0138C114.052 50.0138 118.295 49.0068 122.845 46.9835L125.008 58.5733C119.647 60.9416 113.763 62.1164 107.367 62.1164C102.584 62.1164 98.332 61.156 94.6117 59.2166L94.6024 59.226ZM113.325 33.4078C112.402 29.7714 110.211 27.9625 106.752 27.9625C104.672 27.9625 103.031 28.7364 101.819 30.2935C100.607 31.8506 99.9731 33.8646 99.9171 36.3448L113.325 33.4078Z"
            fill="#FF625A"
        />
        <path
            d="M139.488 59.142C136.234 57.156 133.624 54.4427 131.656 51.0115C129.698 47.5803 128.719 43.7667 128.719 39.5523C128.719 35.3378 129.698 31.6362 131.656 28.2702C133.614 24.8949 136.244 22.2562 139.526 20.3541C142.817 18.452 146.304 17.501 149.987 17.501C152.99 17.501 155.768 18.3401 158.332 20.0091C160.896 21.6781 162.845 24.1863 164.169 27.5336V17.501H182.332V61.4358H164.169V53.3891C161.288 59.2166 156.561 62.1257 149.987 62.1257C146.239 62.1257 142.733 61.1281 139.479 59.142H139.488ZM161.54 45.9952C163.181 44.3542 164.057 42.4054 164.178 40.1583V39.4684C164.066 37.1653 163.153 35.198 161.456 33.5849C159.759 31.9719 157.81 31.1607 155.619 31.1607C153.195 31.1607 151.134 32.0092 149.437 33.7154C147.74 35.4124 146.882 37.4451 146.882 39.8134C146.882 42.1817 147.749 44.2796 149.474 45.9579C151.199 47.6269 153.251 48.466 155.619 48.466C157.987 48.466 159.899 47.6455 161.54 46.0045V45.9952Z"
            fill="#FF625A"
        />
        <path
            d="M40.2231 46.226C38.6609 46.5769 36.9758 46.7436 35.1767 46.7436C30.4814 46.7436 26.8129 45.3664 24.18 42.6122C21.5471 39.8579 20.2218 36.0247 20.2218 31.1039C20.2218 26.1831 21.4681 22.2008 23.9606 19.5079C27.3219 15.8765 31.9997 15.6572 34.6501 15.4642C40.4249 15.0607 44.8745 17.1484 46.8843 18.2624C48.2885 14.0432 49.6927 9.82413 51.1057 5.59624C49.2276 4.4998 45.1641 2.2806 40.5653 1.12276C38.7223 0.657866 36.8881 0.394719 36.7476 0.377176C34.966 0.122802 33.1669 0 31.3502 0C25.5578 0 20.3359 1.35082 15.702 4.04368C11.0681 6.73655 7.4172 10.4557 4.7492 15.2099C2.08998 19.9553 0.755981 25.2796 0.755981 31.1916C0.755981 37.1036 2.13386 42.2701 4.88963 47.0506C7.64539 51.8311 11.3753 55.594 16.1058 58.3483C20.8274 61.1026 26.0844 62.4709 31.8856 62.4709C37.6867 62.4709 43.365 60.9973 48.4026 58.0413L45.9715 44.3138C43.7073 45.2436 41.8028 45.8752 40.2318 46.226H40.2231Z"
            fill="#FF625A"
        />
    </svg>
);

export default Logo;
