const preparing = {
    label: "En preparation",
    cssClass: "preparing-status",
};

const sent = {
    label: "En cours de livraison",
    cssClass: "in-progress-status",
};

const delivered = {
    label: "Livré",
    cssClass: "delivered-status",
};

const STATUSES: { [key: string]: { [key: string]: string } } = {
    TO_ORDER: preparing,
    ORDERED: preparing,
    RECEIVED: preparing,
    SENT: sent,
    DELIVERED: delivered,
};

export default STATUSES;
