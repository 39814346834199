import React, { useState } from "react";

import { Button } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { createPortal } from "react-dom";

import Details from "components/business/catalog/configuration/details/Details";
import AccessoryItemCatalogDetails from "components/business/catalog/configuration/ProductDetails/AccessoryItemCatalogDetails";
import DeviceItemCatalogDetails from "components/business/catalog/configuration/ProductDetails/DeviceItemCatalogDetails";
import { AccessoryType } from "components/business/catalog/utils/types";
import HeadphonesIcon from "components/common/icons/HeadphonesIcon";
import LeafIcon from "components/common/icons/LeafIcon";
import SmallScreen from "components/common/layout/banner/small-screen";
import { ProductFragment, VariantFragment } from "graphql/catalog/model/apollo";

import "./ProductItemCatalog.scss";

export interface ProductPageProps {
    instanceId: string;
    product: ProductFragment | AccessoryType;
    variants: VariantFragment[];
}

interface ProductItemCatalogProps extends ProductPageProps {
    onClose: () => void;
}

export const ProductItemCatalog = ({
    instanceId,
    product,
    variants,
    onClose,
}: ProductItemCatalogProps) => {
    const [displayDetails, setDisplayDetails] = useState<boolean>(false);

    const devices = product as ProductFragment;
    const accessoryType = product as AccessoryType;

    return (
        <>
            <div className="product-page">
                <div className="catalog-product">
                    <div className="catalog-product-left">
                        <div className="catalog-product-left-return-button">
                            <Button
                                variant="outlined"
                                onClick={onClose}
                                startIcon={<ChevronLeft />}
                            >
                                Retour
                            </Button>
                        </div>
                        {product.image?.url && (
                            <img
                                src={product.image.url}
                                alt={product.image.title || product.name || ""}
                                className="catalog-product-image"
                            />
                        )}
                        <button
                            type="button"
                            className="catalog-product-details-button"
                            data-cy="product-details-button"
                            onClick={() => {
                                setDisplayDetails(true);
                            }}
                        >
                            Spécifications techniques
                        </button>
                        {variants.length > 0
                            ? displayDetails &&
                              createPortal(
                                  <Details
                                      product={devices}
                                      onClose={() => {
                                          setDisplayDetails(false);
                                      }}
                                  />,
                                  document.getElementById(
                                      "portal",
                                  ) as HTMLElement,
                              )
                            : displayDetails &&
                              createPortal(
                                  <Details
                                      product={accessoryType}
                                      onClose={() => {
                                          setDisplayDetails(false);
                                      }}
                                  />,
                                  document.getElementById(
                                      "portal",
                                  ) as HTMLElement,
                              )}
                        <div className="catalog-product-guarantees">
                            <p className="catalog-product-guarantees-title">
                                Les garanties Cleaq
                            </p>
                            <div className="catalog-product-guarantees-text">
                                <div>
                                    <HeadphonesIcon />
                                    <p>
                                        Support illimité en moins de 10 minutes
                                    </p>
                                </div>
                                <div>
                                    <LeafIcon />
                                    <p>Reconditionnement en fin de vie</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {variants.length > 0 ? (
                        <DeviceItemCatalogDetails
                            instanceId={instanceId}
                            variants={variants}
                            product={product as ProductFragment}
                            onClose={onClose}
                        />
                    ) : (
                        <AccessoryItemCatalogDetails
                            product={product as AccessoryType}
                            instanceId={instanceId}
                            onClose={onClose}
                        />
                    )}
                </div>
            </div>
            <SmallScreen />
        </>
    );
};
