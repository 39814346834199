import React from "react";

const Icon = () => (
    <svg
        height="40"
        viewBox="0 0 44 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M35.7483 19.9393L35.752 19.9316C37.2614 16.798 35.9448 13.0342 32.8112 11.5248L16.4434 3.6406C13.3099 2.13121 9.54606 3.44783 8.03667 6.58137L8.03296
            6.58905C6.52357 9.72258 7.84019 13.4864 10.9737 14.9958L27.3415 22.88C30.4751 24.3894 34.2389 23.0728 35.7483 19.9393Z"
            fill="#FF625A"
        />
        <path
            d="M5.83579 30.1763C6.24052 26.7355 9.38384 24.251 12.8257 24.6472L24.4287 26.0066C27.8696 26.4114 30.3541 29.5547 29.9578 32.9966C29.5531 36.4374 26.4098
            38.9219 22.9679 38.5257L11.3649 37.1663C7.92402 36.7615 5.43954 33.6182 5.83579 30.1763Z"
            fill="#FF625A"
        />
        <path
            d="M25.1927 58.6383C22.3654 56.6313 21.6976 52.6748 23.7131 49.8486L27.3581 43.6169C29.3651 40.7896 33.3216 40.1218 36.1478 42.1372C38.9751 44.1443 39.6429
            48.1007 37.6274 50.927L33.9824 57.1587C31.9754 59.9859 28.0189 60.6538 25.1927 58.6383Z"
            fill="#FF625A"
        />
    </svg>
);

export default Icon;
