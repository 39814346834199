import React from "react";

import { Tooltip } from "@material-ui/core";

import { formatPrice } from "utils/translator/translator";

import "./ConfigurePrice.scss";

interface ConfigurePriceProps {
    quantity: number;
    accessoriesSubtotal?: string | null;
    variantSubtotal?: string | null;
    totalPrice: number;
    negotiated: boolean;
    normalPrice: number;
    handleIncrement: (increment: boolean) => void;
    handleAddToCart: () => void;
}

const ConfigurePrice = (props: ConfigurePriceProps) => {
    const {
        quantity,
        accessoriesSubtotal,
        variantSubtotal,
        totalPrice,
        negotiated,
        normalPrice,
        handleIncrement,
        handleAddToCart,
    } = props;

    return (
        <div className="catalog-product-footer">
            <div className="quantity-select">
                <div>
                    <div>
                        <button
                            type="button"
                            className={`round-button-quantity ${
                                quantity === 1 && "disabled"
                            }`}
                            disabled={quantity <= 1}
                            onClick={() => {
                                handleIncrement(false);
                            }}
                            data-cy="decrement-quantity-button"
                        >
                            <i className="icofont icofont-minus" />
                        </button>
                    </div>
                    <div>
                        <span className="catalog-product-quantity">
                            {quantity}
                        </span>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="round-button-quantity"
                            onClick={() => {
                                handleIncrement(true);
                            }}
                            data-cy="increment-quantity-button"
                        >
                            <i className="icofont icofont-plus" />
                        </button>
                    </div>
                </div>
                <div
                    className="catalog-product-price"
                    data-cy="catalog-product-price"
                >
                    <div className="subtotal-prices">
                        {accessoriesSubtotal &&
                            `(${accessoriesSubtotal} + ${variantSubtotal})`}
                    </div>
                    <span>
                        <strong>{formatPrice(totalPrice)}</strong> HT / mois
                        {negotiated && (
                            <Tooltip
                                title={`Au lieu de ${formatPrice(
                                    normalPrice * quantity,
                                )}`}
                                placement="right"
                            >
                                <i className="icofont icofont-star" />
                            </Tooltip>
                        )}
                    </span>
                </div>
            </div>
            <button
                type="button"
                className="cleaq-button add-to-cart-button"
                data-cy="add-to-cart-button"
                onClick={handleAddToCart}
            >
                Ajouter au panier
            </button>
        </div>
    );
};

ConfigurePrice.defaultProps = {
    accessoriesSubtotal: null,
    variantSubtotal: null,
};

export default ConfigurePrice;
