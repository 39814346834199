/* eslint-disable no-unused-expressions */
import React from "react";

import { Row } from "reactstrap";
import { v4 as uuid } from "uuid";

import SkeletonDashboardDeviceList from "components/business/dashboard/dashboard-devices/devices-list/skeleton-device-list/skeleton-dashboard-device-list";
import {
    DashboardDeviceNameCountFragment,
    DashboardDeviceOsCountFragment,
    DashboardDeviceTypeCountFragment,
    InstanceDashboardFragment,
} from "graphql/management/model/apollo";
import { osLabels, typesLabels } from "utils/device/device-labels";

import DashboardDevicesList from "./devices-list/devices-list";

interface Item {
    itemName: string | undefined;
    itemCount: number | undefined;
}

interface DashboardDevicesInfo {
    instanceDashboard: InstanceDashboardFragment | null | undefined;
    dashboardLoading: boolean;
}

const DashboardDevices = (props: DashboardDevicesInfo) => {
    const defineItemListNames = (
        deviceNameCountList:
            | DashboardDeviceNameCountFragment[]
            | null
            | undefined,
    ) => {
        let Item = {} as Item;
        let ItemList = [] as Item[];
        deviceNameCountList?.forEach((deviceNameCountItem) => {
            Item = {
                itemName: deviceNameCountItem.deviceName,
                itemCount: deviceNameCountItem.count,
            };
            ItemList = [...ItemList, Item];
        });
        return ItemList;
    };

    const defineItemListTypes = (
        deviceNameCountList:
            | DashboardDeviceTypeCountFragment[]
            | null
            | undefined,
    ) => {
        let Item = {} as Item;
        let ItemList = [] as Item[];
        deviceNameCountList?.forEach((deviceTypesCountItem) => {
            Item = {
                itemName: typesLabels.get(deviceTypesCountItem.productType),
                itemCount: deviceTypesCountItem.count,
            };
            ItemList = [...ItemList, Item];
        });
        return ItemList;
    };

    const defineItemListOs = (
        deviceNameCountList:
            | DashboardDeviceOsCountFragment[]
            | null
            | undefined,
    ) => {
        let Item = {} as Item;
        let ItemList = [] as Item[];
        deviceNameCountList?.forEach((deviceOsCountItem) => {
            Item = {
                itemName: osLabels.get(deviceOsCountItem.productOs),
                itemCount: deviceOsCountItem.count,
            };
            ItemList = [...ItemList, Item];
        });
        return ItemList;
    };

    return (
        <div className="nlt-card-rank">
            <div className="nlt-card-header">
                <div className="nlt-card-title">
                    Classement de mes appareils
                </div>
            </div>
            <div className="appointment-table table-responsive nlt-dashboard-scale">
                <Row className="nlt-ranks">
                    {props.dashboardLoading ? (
                        [...Array(3)].map(() => (
                            <SkeletonDashboardDeviceList key={uuid()} />
                        ))
                    ) : (
                        <>
                            <DashboardDevicesList
                                title="Nom"
                                capitalise
                                listItem={defineItemListNames(
                                    props.instanceDashboard?.deviceNameCount,
                                )}
                            />
                            <DashboardDevicesList
                                title="Type"
                                capitalise={false}
                                listItem={defineItemListTypes(
                                    props.instanceDashboard?.deviceTypeCount,
                                )}
                            />
                            <DashboardDevicesList
                                title="Os"
                                capitalise={false}
                                listItem={defineItemListOs(
                                    props.instanceDashboard?.deviceOsCount,
                                )}
                            />
                        </>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default DashboardDevices;
