import React from "react";

import moment from "moment";
import { Col, NavLink } from "reactstrap";

import CustomCheckbox from "components/common/input/custom-checkbox/custom-checkbox";
import { DeviceFragment, DeviceSource } from "graphql/management/model/apollo";
import computeDeviceStatus from "utils/device/device-compute-status";
import iconDeviceLink from "utils/device/device-icons-links";
import { typesLabels } from "utils/device/device-labels";

interface deviceItemInterface {
    device: DeviceFragment;
    selectedItems: DeviceFragment[];
    setSelectedItems: React.Dispatch<React.SetStateAction<DeviceFragment[]>>;
    selectedDevice: DeviceFragment | null | undefined;
    setSelectedDevice: React.Dispatch<
        React.SetStateAction<DeviceFragment | null | undefined>
    >;
    setIsCreateDevice: React.Dispatch<React.SetStateAction<boolean>>;
    cancelEdit: () => void;
}

const DeviceItem = (props: deviceItemInterface) => {
    const DeviceDetails = (device: any) => {
        props.cancelEdit();
        props.setSelectedDevice(device);
    };

    function displayDeviceDate(deviceDate: string) {
        return moment.utc(deviceDate).format("DD/MM/YYYY");
    }

    function spanDeviceStatus(selectedDevice: DeviceFragment) {
        return (
            <span className={computeDeviceStatus(selectedDevice).color}>
                {computeDeviceStatus(selectedDevice).status}
            </span>
        );
    }

    return (
        <NavLink
            className={
                props.device.serialNumber === props.selectedDevice?.serialNumber
                    ? "active"
                    : ""
            }
            key={props.device.serialNumber}
        >
            <div
                data-cy="device-item"
                aria-hidden="true"
                className="nlt-dev-4"
                onClick={() =>
                    props.selectedItems.length === 0 &&
                    DeviceDetails(props.device)
                }
            >
                <div
                    className={
                        props.device.serialNumber ===
                        props.selectedDevice?.serialNumber
                            ? "nlt-list-item-selector-active"
                            : "nlt-list-item-selector"
                    }
                />
                <Col
                    xl={1}
                    lg={2}
                    className="nlt-device-icon-fix"
                >
                    <img
                        className="nlt-wide-device-icon"
                        src={iconDeviceLink(
                            props.device.specs.deviceType ?? "",
                        )}
                        alt={props.device.specs.deviceType ?? ""}
                        style={
                            props.selectedItems.includes(props.device)
                                ? { display: "none" }
                                : { display: "block" }
                        }
                    />
                    <CustomCheckbox
                        className="nlt-wide-device-icon"
                        style={
                            props.selectedItems.includes(props.device)
                                ? {
                                      display: "flex",
                                      width: "54px",
                                  }
                                : {
                                      display: "none",
                                      width: "54px",
                                  }
                        }
                        checked={props.selectedItems.includes(props.device)}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                            props.setSelectedDevice(null);
                            props.setIsCreateDevice(false);
                            if (props.selectedItems.includes(props.device)) {
                                const newList = props.selectedItems.filter(
                                    (item) => item !== props.device,
                                );
                                props.setSelectedItems(newList);
                            } else {
                                props.setSelectedItems([
                                    ...props.selectedItems,
                                    props.device,
                                ]);
                            }
                        }}
                    />
                </Col>
                <Col
                    xl={4}
                    lg={4}
                >
                    <div className="media-body">
                        <h6>
                            <span className="nlt-wide-device-title">
                                {props.device.reference}
                            </span>
                        </h6>
                        <div>
                            <span className="nlt-wide-device-info">
                                {props.device.serialNumber}
                            </span>
                        </div>
                        <div>
                            <span className="nlt-wide-device-info">
                                {typesLabels.get(
                                    props.device.specs.deviceType!,
                                )}
                            </span>
                        </div>
                        <div>
                            {props.device.source === DeviceSource.CLEAQ &&
                                props.device.leasingData && (
                                    <div
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0C745E",
                                        }}
                                        className="nlt-wide-card-tag-item-sm"
                                    >
                                        <p>
                                            Loué chez Clea
                                            <span className="q-cleaq">q</span>
                                        </p>
                                    </div>
                                )}
                        </div>
                    </div>
                </Col>
                <Col
                    xl={2}
                    lg={2}
                >
                    <div>
                        <div className="nlt-device-item-title-info-list">
                            Statut
                        </div>
                        <p className="email_add_0">
                            {spanDeviceStatus(props.device)}
                        </p>
                    </div>
                </Col>
                <Col
                    xl={3}
                    lg={4}
                >
                    {props.device.affectedUser ? (
                        <>
                            <div>
                                <div className="nlt-device-item-title-info-list">
                                    Utilisateur
                                </div>
                            </div>
                            <div>
                                <ul className="nlt-device-item-data-info">
                                    <li>{`${props.device.affectedUser?.firstName} ${props.device.affectedUser?.lastName}`}</li>
                                    <li>
                                        {props.device.affectedDate &&
                                            `Depuis le ${displayDeviceDate(
                                                props.device.affectedDate,
                                            )}`}
                                    </li>
                                </ul>
                            </div>
                        </>
                    ) : (
                        <div>
                            <div className="nlt-device-item-title-info-list">
                                Utilisateur
                            </div>
                            <p className="email_add_0">Aucun utilisateur</p>
                        </div>
                    )}
                </Col>
                <div className="nlt-wide-card-tag-ls-sm">
                    {props.device.affectedUser?.organisations?.map(
                        (organisation) => (
                            <div
                                key={organisation.id!}
                                style={{
                                    backgroundColor: organisation.hexaColor!,
                                    color: "white",
                                }}
                                className="nlt-wide-card-tag-item-sm"
                            >
                                {organisation.name}
                            </div>
                        ),
                    )}
                </div>
            </div>
        </NavLink>
    );
};

export default DeviceItem;
