import React, { forwardRef } from "react";

import classnames from "classnames";

import CheckIcon from "components/common/icons/CheckIcon";
import CrossIcon from "components/common/icons/CrossIcon";
import ErrorIcon from "components/common/icons/ErrorIcon";
import InformationIcon from "components/common/icons/InformationIcon";
import Tooltip from "components/common/layout/Tooltip/Tooltip";

import styles from "./Input.module.scss";

interface InputProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onReset">,
        Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, "onReset"> {
    errorMessage?: string;
    isValid?: boolean;
    tooltipText?: string;
    "data-cy"?: string;
    icon?: React.ReactElement;
}

const renderLabel = (
    title: string | undefined,
    tooltipText: string | undefined,
) => {
    if (title) {
        return (
            <label className={styles.inputTitle}>
                {title}
                {tooltipText && (
                    <Tooltip text={tooltipText}>
                        <InformationIcon />
                    </Tooltip>
                )}
            </label>
        );
    }
    return null;
};

const Input = forwardRef((props: InputProps, ref) => (
    <div className={styles.container}>
        <div className={styles.inputContainer}>
            {renderLabel(props.title, props.tooltipText)}
            {props.icon && <div className={styles.icon}>{props.icon}</div>}
            <input
                style={props.style}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
                className={classnames(
                    props.icon ? styles.inputWithIcon : styles.input,
                    {
                        [styles.inputError]: props.errorMessage !== undefined,
                    },
                )}
                data-cy={props["data-cy"]}
                disabled={props.disabled}
            />
        </div>
        {props.errorMessage && (
            <>
                <div className={styles.errorIcon}>
                    <ErrorIcon color="#FF625A" />
                </div>
                <span
                    className={classnames([
                        "invalid-field-warning",
                        styles.errorMessage,
                    ])}
                >
                    {props.errorMessage ?? ""}
                </span>
            </>
        )}
        {props.isValid && props.value !== "" && (
            <div className={styles.successIcon}>
                <CheckIcon color="#28A745" />
            </div>
        )}
        {props.onReset !== undefined && props.value !== "" && (
            <button
                onClick={props.onReset}
                className={styles.resetButton}
                aria-label="reset input"
            >
                <CrossIcon />
            </button>
        )}
    </div>
));

Input.defaultProps = {
    errorMessage: undefined,
    isValid: undefined,
    tooltipText: undefined,
    "data-cy": undefined,
    icon: undefined,
};

export default Input;
