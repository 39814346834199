import React, { useState } from "react";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import CrossIcon from "components/common/icons/CrossIcon";
import RightChevronIcon from "components/common/icons/RightChevronIcon";
import { useFindChangelogQuery } from "graphql/catalog/model/apollo";
import ApiClientNames from "graphql/consts";

import styles from "./changelog.module.scss";

const Changelog = () => {
    const changelogQuery = useFindChangelogQuery({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        context: { clientName: ApiClientNames.CATALOG },
    });
    const [showContent, setShowContent] = useState(false);
    const [lastClickedIndex, setLastClickedIndex] = useState<number | null>(
        null,
    );

    const handleToggleModal = () => {
        setShowContent(false);
    };

    const handleClick = (index: number) => {
        if (lastClickedIndex === index) {
            setShowContent(!showContent);
        } else {
            setLastClickedIndex(index);
            setShowContent(true);
        }
    };

    const changelogs = changelogQuery.data?.changelogCollection;

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <p className={styles.title}>Les nouveautés de l'app</p>
            </div>
            <div className={styles.changelogs}>
                {changelogs &&
                    changelogs.items.map((changelog, index) =>
                        changelog ? (
                            <React.Fragment key={changelog.title}>
                                <button
                                    className={styles.changelog}
                                    onClick={() => handleClick(index)}
                                >
                                    <div
                                        className={
                                            styles.changelogTitleContainer
                                        }
                                    >
                                        <div className={styles.changelogTitle}>
                                            {changelog.title}
                                        </div>
                                        <RightChevronIcon />
                                    </div>
                                    <div className={styles.description}>
                                        {changelog.shortDescription}
                                    </div>
                                </button>
                                {lastClickedIndex === index && (
                                    <div
                                        className={`${styles.content} ${
                                            showContent
                                                ? styles.open
                                                : styles.close
                                        }`}
                                    >
                                        <button
                                            onClick={handleToggleModal}
                                            className={styles.closeButton}
                                        >
                                            <CrossIcon />
                                        </button>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: documentToHtmlString(
                                                    changelog.content?.json,
                                                    {
                                                        preserveWhitespace:
                                                            true,
                                                    },
                                                ),
                                            }}
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        ) : null,
                    )}
            </div>
        </div>
    );
};

export default Changelog;
