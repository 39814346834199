import React from "react";

import { Controller } from "react-hook-form";
import type { Control } from "react-hook-form";
import Select from "react-select";
import { FormGroup, Input, Row } from "reactstrap";

import {
    UserFormData,
    OrganisationOption,
} from "components/business/user/types";
import CustomCheckbox from "components/common/input/custom-checkbox/custom-checkbox";
import {
    useFindAdminQuery,
    UserFragment,
    UserRole,
} from "graphql/management/model/apollo";

import "./user-details.scss";

interface EditUserDetailsInterface {
    formControl: Control<UserFormData>;
    selectedUser: UserFragment;
    organisationOptions: OrganisationOption[] | undefined;
}

const EditUserDetails = (props: EditUserDetailsInterface) => {
    const adminQuery = useFindAdminQuery();

    return (
        <>
            <Row className="nlt-row-form-fix fix-padding">
                <div className="nlt-item-info-label-edit col-md-5 required">
                    Nom
                </div>
                <Controller
                    name="lastName"
                    control={props.formControl}
                    rules={{
                        required: true,
                        validate: (value) => value?.trim()?.length > 0,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <FormGroup className="col-md-7">
                            {!error ? (
                                <span className="mandatory-label-small" />
                            ) : (
                                <span className="nlt-mandatory-label-small nlt-font-red">
                                    Le nom de famille est requis.
                                </span>
                            )}
                            <Input
                                {...field}
                                className="form-control"
                                type="text"
                                placeholder="Petit"
                            />
                        </FormGroup>
                    )}
                />
            </Row>
            <Row className="nlt-row-form-fix fix-padding">
                <div className="nlt-item-info-label-edit col-md-5 required">
                    Prénom
                </div>
                <Controller
                    name="firstName"
                    control={props.formControl}
                    rules={{
                        required: true,
                        validate: (value) => value?.trim()?.length > 0,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <FormGroup className="col-md-7">
                            {!error ? (
                                <span className="mandatory-label-small" />
                            ) : (
                                <span className="nlt-mandatory-label-small nlt-font-red">
                                    Le prénom est requis.
                                </span>
                            )}
                            <Input
                                {...field}
                                className="form-control"
                                type="text"
                                placeholder="Laura"
                            />
                        </FormGroup>
                    )}
                />
            </Row>
            <Row className="nlt-row-form-fix fix-padding">
                <div className="nlt-item-info-label-edit col-md-5">
                    N° téléphone
                </div>
                <FormGroup className="col-md-7">
                    <Controller
                        name="phoneNumber"
                        control={props.formControl}
                        render={({ field }) => (
                            <Input
                                {...field}
                                className="form-control"
                                type="tel"
                                placeholder="06 01 02 03 04"
                            />
                        )}
                    />
                </FormGroup>
            </Row>
            <Row className="nlt-row-form-fix">
                <div className="nlt-item-info-label-edit col-md-5">
                    Administrateur
                </div>
                <FormGroup className="col-md-7">
                    <Controller
                        name="administrateur"
                        control={props.formControl}
                        render={({ field: { ref, ...field } }) => (
                            <CustomCheckbox
                                {...field}
                                checked={field.value}
                                id="check-admin"
                                disabled={
                                    props.selectedUser.role ===
                                        UserRole.OWNER &&
                                    adminQuery.data?.findUser?.length === 1
                                }
                            />
                        )}
                    />
                </FormGroup>
            </Row>
            <div className="nlt-device-sep" />
            <h6 className="mb-3">Tags</h6>
            <Row className="nlt-row-form-fix">
                <FormGroup className="col-md-12">
                    <Controller
                        control={props.formControl}
                        name="organisations"
                        render={({ field }) => (
                            <Select
                                {...field}
                                options={props.organisationOptions}
                                placeholder="Tags..."
                                noOptionsMessage={() => "Aucun tag"}
                                isMulti
                            />
                        )}
                    />
                </FormGroup>
            </Row>
            <div className="nlt-device-sep" />
            <h6 className="mb-3">Notes</h6>
            <Row>
                <FormGroup className="col-md-12">
                    <Controller
                        control={props.formControl}
                        name="customNotes"
                        render={({ field: { ref, ...rest } }) => (
                            <Input
                                {...rest}
                                innerRef={ref}
                                className="form-control"
                                type="textarea"
                                placeholder="Notes"
                                data-cy="user-edit-notes"
                            />
                        )}
                    />
                </FormGroup>
            </Row>
        </>
    );
};

export default EditUserDetails;
