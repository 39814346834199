import React from "react";

const SmallScreen = () => (
    <div className="nlt-responsive-security">
        <div>
            Désolé, Cleaq n'est actuellement pas disponible sur petits écrans.
        </div>
    </div>
);

export default SmallScreen;
