import { cleaqParsedToken } from "../authentication/authentication";

export const tokenLogger = (tokens: any) => {
    if (tokens.token) {
        localStorage.setItem("bearerToken", tokens.token);
        localStorage.setItem(
            "parsedToken",
            JSON.stringify(cleaqParsedToken(tokens.token)),
        );
    }
};
