import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import "./skeleton.scss";

const SkeletonItem = () => (
    <div className="skeleton-item">
        <div className="skeleton-item-row">
            <Skeleton
                className="skeleton-item-icon"
                height={60}
                width={60}
                variant="circle"
            />
            <div className="skeleton-item-container">
                <div className="skeleton-item-paragraph">
                    <Skeleton className="skeleton-item-name" />
                    <Skeleton className="skeleton-item-text" />
                    <Skeleton
                        className="skeleton-item-text"
                        width={150}
                    />
                </div>
                <div className="skeleton-item-role">
                    <Skeleton className="skeleton-item-title" />
                    <Skeleton className="skeleton-item-roletext" />
                </div>
                <div className="skeleton-item-device">
                    <Skeleton className="skeleton-item-devicetitle" />
                    <Skeleton className="skeleton-item-devicename" />
                </div>
                <div className="skeleton-item-tag">
                    <Skeleton className="skeleton-item-tagname" />
                </div>
            </div>
        </div>
    </div>
);

export default SkeletonItem;
