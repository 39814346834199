import React from "react";

import { Tooltip } from "@material-ui/core";

import Product from "components/business/order/product/product";
import ProductsSpecs from "components/common/layout/products-specs/products-specs";
import AddProductQuantityAction from "components/provider/context/cart/actions/add-product-quantity-action";
import ReduceProductQuantityAction from "components/provider/context/cart/actions/reduce-product-quantity-action";
import RemoveProductAction from "components/provider/context/cart/actions/remove-product-action";
import { useCartDispatch } from "components/provider/context/cart/cart-provider";
import { formatPrice } from "utils/translator/translator";

import "./cart-product.scss";

interface CartProductProps {
    productList: Product[];
}

const CartProduct = (props: CartProductProps) => {
    const cartDispatch = useCartDispatch();

    if (props.productList.length === 0) {
        return <></>;
    }

    const product = props.productList[0];

    return (
        <div className="cart-item">
            <div className="cart-item-body">
                <div className="top">
                    <div>
                        <div className="cart-item-title">{product.name}</div>
                        <p className="cart-item-leasing-duration">
                            {`Renouvellement sur ${product.leasingDuration} mois`}
                        </p>
                        <div className="cart-assurance-insurance">
                            Assurance et assistance incluses
                        </div>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="round-button"
                            data-cy="remove-from-cart"
                            onClick={() => {
                                cartDispatch(
                                    new RemoveProductAction(
                                        product.variantId,
                                        product.leasingDuration,
                                    ),
                                );
                            }}
                        >
                            <i className="icofont icofont-trash" />
                        </button>
                    </div>
                </div>
                <div className="bottom">
                    <ProductsSpecs
                        product={product}
                        isCart
                    />
                    <div className="cart-item-price">
                        <div className="cart-item-quantity">
                            <button
                                type="button"
                                className="round-button"
                                data-cy="reduce-product-quantity-from-cart"
                                onClick={() => {
                                    cartDispatch(
                                        new ReduceProductQuantityAction(
                                            product.variantId,
                                            product.leasingDuration,
                                        ),
                                    );
                                }}
                            >
                                <i className="icofont icofont-minus" />
                            </button>
                            <p>{props.productList.length}x</p>
                            <button
                                type="button"
                                className="round-button"
                                data-cy="add-product-quantity-from-cart"
                                onClick={() => {
                                    cartDispatch(
                                        new AddProductQuantityAction(
                                            product.variantId,
                                            product.leasingDuration,
                                        ),
                                    );
                                }}
                            >
                                <i className="icofont icofont-plus" />
                            </button>
                        </div>
                        <div>
                            <span className="cart-price">
                                {formatPrice(
                                    product.pricing * props.productList.length,
                                )}
                            </span>
                            <span className="cart-price_whitespace">
                                {" "}
                                HT / mois
                                {product.negotiated && (
                                    <Tooltip
                                        title={`Au lieu de ${formatPrice(
                                            product.normalPrice *
                                                props.productList.length,
                                        )}`}
                                        placement="right"
                                    >
                                        <i className="icofont icofont-star" />
                                    </Tooltip>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {product.photo && (
                <img
                    src={product.photo}
                    alt={product.name || ""}
                />
            )}
        </div>
    );
};

export default CartProduct;
