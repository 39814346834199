import React from "react";

interface ValidationIconProps {
    color?: string;
}

export default function ValidationIcon({
    color = "black",
}: ValidationIconProps) {
    return (
        <svg
            width="25"
            height="26"
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.4831 13.0218C23.4831 19.0318 18.6131 23.9118 12.5931 23.9118C6.57313 23.9118 1.70312 19.0318 1.70312 13.0218C1.70312 7.01184 6.58313 2.13184 12.5931 2.13184C13.5631 2.13184 14.5131 2.26184 15.4031 2.49184"
                stroke={color}
                strokeWidth="2.8"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M8.01306 11.2919L12.5931 15.7719L23.4831 4.69189"
                stroke={color}
                strokeWidth="2.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
