import React from "react";

import Product from "components/business/order/product/product";
import {
    AudioProductSpecs,
    DockstationProductSpecs,
    KeyboardsProductSpecs,
    MousesProductSpecs,
    ScreensProductSpecs,
} from "components/business/order/product/product-specs";
import translator from "utils/translator/translator";
import translatorConnectivity from "utils/translator/translator-connectivity";

import AccessoryConfigTag from "./accessory-config-tag/accessory-config-tag";

interface AccessorySpecsProps {
    accessory: Product;
}

const AccessorySpecs = (props: AccessorySpecsProps) => {
    const { accessory } = props;

    if (accessory.type === "Audio") {
        const specsAudio = accessory.specs as AudioProductSpecs;
        return (
            <>
                <AccessoryConfigTag
                    config={`${
                        specsAudio.noiseCancellation ? "Avec" : "Sans"
                    } réduction de bruit`}
                />
                <AccessoryConfigTag
                    config={specsAudio.connectivity.map((connectivity) =>
                        translatorConnectivity.translate(connectivity),
                    )}
                />
            </>
        );
    }

    if (accessory.type === "Dockstation") {
        const specsDockstation = accessory.specs as DockstationProductSpecs;
        return (
            <>
                <AccessoryConfigTag
                    config={translatorConnectivity.translate(
                        specsDockstation.connection,
                    )}
                />
                <AccessoryConfigTag
                    config={translator.translate(
                        specsDockstation.compatibility,
                    )}
                />
                <AccessoryConfigTag
                    config={specsDockstation.connectivity.map((connectivity) =>
                        translatorConnectivity.translate(connectivity),
                    )}
                />
            </>
        );
    }

    if (accessory.type === "Keyboard") {
        const specsKeyboard = accessory.specs as KeyboardsProductSpecs;
        return (
            <>
                <AccessoryConfigTag
                    config={translator.translate(specsKeyboard.compatibility)}
                />
                <AccessoryConfigTag
                    config={`${
                        specsKeyboard.numPad ? "Avec" : "Sans"
                    } pavé numérique`}
                />
                <AccessoryConfigTag
                    config={specsKeyboard.connectivity.map((connectivity) =>
                        translatorConnectivity.translate(connectivity),
                    )}
                />
            </>
        );
    }

    if (accessory.type === "Mouse") {
        const specsMouse = accessory.specs as MousesProductSpecs;
        return (
            <>
                <AccessoryConfigTag
                    config={translator.translate(specsMouse.compatibility)}
                />
                <AccessoryConfigTag
                    config={specsMouse.connectivity.map((connectivity) =>
                        translatorConnectivity.translate(connectivity),
                    )}
                />
            </>
        );
    }

    if (accessory.type === "Screen") {
        const specsScreen = accessory.specs as ScreensProductSpecs;
        // eslint-disable-next-line quotes
        const size = specsScreen.size.toString().concat('"');
        return (
            <>
                <AccessoryConfigTag config={size} />
                <AccessoryConfigTag config={specsScreen.resolution} />
                <AccessoryConfigTag
                    config={specsScreen.connectivity.map((connectivity) =>
                        translatorConnectivity.translate(connectivity),
                    )}
                />
            </>
        );
    }
    return <></>;
};

export default AccessorySpecs;
