import React from "react";

const LeftChevronIcon = () => {
    return (
        <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.84163 1.425L5.66663 0.25L0.666626 5.25L5.66663 10.25L6.84163 9.075L3.02496 5.25L6.84163 1.425Z"
                fill="black"
                fillOpacity="0.87"
            />
        </svg>
    );
};

export default LeftChevronIcon;
