const getIconStyleFromStep = (
    step: number,
    activeStep: number,
): { [key: string]: string } => {
    if (step === activeStep) {
        return {
            label: "white",
            cssClass: "step-active",
        };
    }

    if (step < activeStep) {
        return {
            label: "white",
            cssClass: "step-passed",
        };
    }

    if (step > activeStep) {
        return {
            label: "black",
            cssClass: "step-future",
        };
    }

    return {};
};

export default getIconStyleFromStep;
