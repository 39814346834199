import React, { useState } from "react";

import Product from "components/business/order/product/product";
import { useCart } from "components/provider/context/cart/cart-provider";

import AssignUserCard from "./AssignUserCard/AssignUserCard";
import CreateUserOrder from "./create-user/create-user";

import "./AssignUserOrder.scss";

const AssignUser = () => {
    const [createUser, setCreateUser] = useState<Boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(
        null,
    );
    const cart = useCart();

    return (
        <>
            <div className="assign-user">
                {cart.map((product) => (
                    <AssignUserCard
                        product={product}
                        key={product.innerId}
                        onCreateUser={() => {
                            setSelectedProduct(product);
                            setCreateUser(!createUser);
                        }}
                    />
                ))}
            </div>
            {createUser && (
                <CreateUserOrder
                    onClose={() => {
                        setCreateUser(false);
                        setSelectedProduct(null);
                    }}
                    selectedProduct={selectedProduct}
                />
            )}
        </>
    );
};

export default AssignUser;
