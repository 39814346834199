import Keycloak from "keycloak-js";
import {
    File,
    List,
    Home,
    Monitor,
    Settings,
    ShoppingCart,
    CreditCard,
    Tag as TagIcon,
    User as UserIcon,
} from "react-feather";
import type { Icon } from "react-feather";

import PlugIcon from "components/common/icons/PlugIcon";
import {
    featureToggleRouterType,
    feature,
} from "components/hooks/useFeatureFlags";
import Account from "pages/account/account";
import Billing from "pages/Billing/Billing";
import ProductPage from "pages/catalog/ProductPage";
import NewOrderWrapper from "pages/create-order/create-order";
import CsvUpload from "pages/csv-file-upload/csv-file-upload";
import Dashboard from "pages/dashboard/dashboard";
import Device from "pages/device/device";
import DeviceGateway from "pages/device/gateway";
import Documents from "pages/documents/documents";
import Instance from "pages/instance/instance";
import Integration from "pages/Integration/Integration";
import Integrations from "pages/Integrations/Integrations";
import OrderDetails from "pages/order-details/order-details";
import OrderHistory from "pages/order-history/order-history";
import Tag from "pages/tag/tag";
import User from "pages/user/user";
import { isUser } from "utils/check-role/check-role";

type LinkType = {
    path: string;
    params?: string;
    icon?: Icon;
    title?: string;
    Component: any;
};

// Links list
const dashboard: LinkType = {
    path: "/dashboard",
    icon: Home,
    title: "Dashboard",
    Component: Dashboard,
};

const user: LinkType = {
    path: "/users",
    icon: UserIcon,
    title: "Mes utilisateurs",
    Component: User,
};

const device: LinkType = {
    path: "/devices",
    icon: Monitor,
    title: "Mes appareils",
    Component: Device,
};

const gateway: LinkType = {
    path: "/devices/:serialNumber(.*)",
    Component: DeviceGateway,
};

const tag: LinkType = {
    path: "/tags",
    icon: TagIcon,
    title: "Mes tags",
    Component: Tag,
};

const company: LinkType = {
    path: "/company",
    icon: Settings,
    title: "Mon entreprise",
    Component: Instance,
};

const account: LinkType = {
    path: "/account",
    icon: UserIcon,
    title: "Mon compte",
    Component: Account,
};

const catalog: LinkType = {
    path: "/order/new/catalog",
    params: "/order/new/:stage",
    icon: ShoppingCart,
    title: "Catalogue",
    Component: NewOrderWrapper,
};

const catalogitem: LinkType = {
    path: "/order/new/catalog",
    params: "/order/new/catalog/:productId",
    Component: ProductPage,
};

const history: LinkType = {
    path: "/orders",
    icon: List,
    title: "Mes commandes",
    Component: OrderHistory,
};

const csv: LinkType = {
    path: "/load-data",
    Component: CsvUpload,
};

const historyitem: LinkType = {
    path: "/orders",
    params: "/orders/:crmId",
    title: "Ma commande",
    Component: OrderDetails,
};

const documents: LinkType = {
    path: "/documents",
    icon: File,
    title: "Mes documents",
    Component: Documents,
};

const billing: LinkType = {
    path: "/billing",
    icon: CreditCard,
    title: "Facturation",
    Component: Billing,
};

const integrations: LinkType = {
    path: "/integrations",
    icon: PlugIcon,
    title: "Intégrations",
    Component: Integrations,
};

const integration: LinkType = {
    path: "/integrations",
    params: "/integrations/:integrationName",
    title: "Intégration",
    Component: Integration,
};

export function routes(
    currentUser: Keycloak,
    toggleRouter: featureToggleRouterType,
): LinkType[] {
    let routes: LinkType[] = [device, account];

    if (currentUser.hasRealmRole("OWNER")) {
        routes = [
            dashboard,
            ...routes,
            company,
            user,
            tag,
            csv,
            catalog,
            history,
            historyitem,
            documents,
            billing,
            gateway,
        ];

        if (!toggleRouter.featureIsEnabled(feature.NEXT_CATALOG)) {
            routes = [...routes, catalogitem];
        }

        if (toggleRouter.featureIsEnabled(feature.INTEGRATIONS_PAGE)) {
            routes = [...routes, integrations, integration];
        }
    }

    return routes;
}

type SidebarLinkType = {
    name: string;
    items: LinkType[];
};

export function sidebarLinks(
    currentUser: Keycloak,
    toggleRouter: featureToggleRouterType,
): SidebarLinkType[] | LinkType[] {
    if (isUser(currentUser)) {
        return [device, account];
    }

    let adminLinks: LinkType[] = [company, documents, billing];

    if (toggleRouter.featureIsEnabled(feature.INTEGRATIONS_PAGE)) {
        adminLinks = [...adminLinks, integrations];
    }

    return [
        {
            name: "Ma flotte",
            items: [dashboard, user, device, tag],
        },
        {
            name: "Commande",
            items: [catalog, history],
        },
        {
            name: "Administration",
            items: adminLinks,
        },
    ];
}
