import React from "react";

import { Nav, TabContent, TabPane } from "reactstrap";

const TagList = (props: any) => (
    <div className="nlt-wide-devices">
        <TabContent activeTab="1">
            <TabPane tabId="1">
                <div className="nlt-dev-1">
                    <div className="nlt-dev-2">
                        <Nav className="nlt-dev-3">{props.children}</Nav>
                    </div>
                </div>
            </TabPane>
        </TabContent>
    </div>
);

export default TagList;
