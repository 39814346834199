import React, { createContext, useContext, useReducer } from "react";

import { StorageProducts } from "components/business/catalog/cart/utils";
import Product from "components/business/order/product/product";

import CartAction from "./actions/cart-action";
import cartReducer from "./cart-reducer";

const CartContext = createContext<Product[]>(StorageProducts());
const CartDispatchContext = createContext<React.Dispatch<CartAction>>(() => {});

interface CartProviderProps {
    children: React.ReactNode;
}

const CartProvider = (props: CartProviderProps) => {
    const [cart, dispatch] = useReducer(cartReducer, StorageProducts());

    return (
        <CartContext.Provider value={cart}>
            <CartDispatchContext.Provider value={dispatch}>
                {props.children}
            </CartDispatchContext.Provider>
        </CartContext.Provider>
    );
};

export function useCart(): Product[] {
    return useContext(CartContext);
}

export function useCartDispatch(): React.Dispatch<CartAction> {
    return useContext(CartDispatchContext);
}

export default CartProvider;
