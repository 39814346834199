import Filters from "components/business/catalog/filter/filters";

import CatalogFiltersAction from "./catalog-filters-action";

class ClearSidebarFiltersAction implements CatalogFiltersAction {
    private emptySidebarFilters = {
        storage: [],
        os: [],
        ram: [],
        processor: [],
        screen: [],
        budget: [],
    };

    apply(filters: Filters) {
        return {
            ...filters,
            storage: this.emptySidebarFilters.storage,
            os: this.emptySidebarFilters.os,
            ram: this.emptySidebarFilters.ram,
            processor: this.emptySidebarFilters.processor,
            screen: this.emptySidebarFilters.screen,
            budget: this.emptySidebarFilters.budget,
        };
    }
}

export default ClearSidebarFiltersAction;
