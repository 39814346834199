import Integration from "components/business/Integration/Entity/Integration";
import {
    FindCatalogIntegrationsQueryHookResult,
    IntegrationFilter,
    useFindCatalogIntegrationsQuery,
} from "graphql/catalog/model/apollo";
import ApiClientNames from "graphql/consts";

interface FindCatalogIntegrationsResult
    extends Omit<FindCatalogIntegrationsQueryHookResult, "data"> {
    data: Integration[];
}

function useFindCatalogIntegrations(
    filter?: IntegrationFilter,
): FindCatalogIntegrationsResult {
    const integrationsQuery = useFindCatalogIntegrationsQuery({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        context: { clientName: ApiClientNames.CATALOG },
        variables: { whereIntegrations: filter },
    });

    const integrations =
        integrationsQuery.data?.integrationCollection?.items
            .map((item) => {
                if (item == null) {
                    return item;
                }
                return Integration.fromContentful(item);
            })
            .flatMap((item) => (item ? [item] : [])) ?? [];

    return {
        ...integrationsQuery,
        data: integrations,
    };
}

export default useFindCatalogIntegrations;
