import CURRENT_STORAGE_VERSION from "components/business/catalog/cart/globalVars";
import Product, {
    cloneProduct,
} from "components/business/order/product/product";

import CartAction from "./cart-action";

class AddProductQuantityAction implements CartAction {
    payload: {
        variantId: string;
        leasingDuration: number;
    };

    constructor(variantId: string, leasingDuration: number) {
        this.payload = {
            variantId,
            leasingDuration,
        };
    }

    apply(cart: Product[]) {
        const product = cart.find(
            (product) =>
                product.variantId === this.payload.variantId &&
                product.leasingDuration === this.payload.leasingDuration,
        );

        if (!product) {
            return cart;
        }

        const updatedCart = [...cart, cloneProduct(product)];
        localStorage.setItem(
            CURRENT_STORAGE_VERSION,
            JSON.stringify(updatedCart),
        );
        return updatedCart;
    }
}

export default AddProductQuantityAction;
