import React, { useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { Controller, useForm } from "react-hook-form";
import { Form, FormGroup, Input, Row } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    DeviceFragment,
    DeviceOs,
    DeviceStatus,
    DeviceType,
    refetchComputeDashboardDataQuery,
    refetchFindDevicesQuery,
    useCreateDeviceMutation,
} from "graphql/management/model/apollo";
import formContent from "utils/device/device-form";
import type { FormData } from "utils/device/device-form";
import { osLabels, typesLabels } from "utils/device/device-labels";
import deviceOs from "utils/device/device-os";
import { convertToDeviceType } from "utils/device/device-types-converter";

import "./create-device.scss";

interface createDeviceInterface {
    devicesNames: string[];
    saving: boolean;
    setSaving: React.Dispatch<React.SetStateAction<boolean>>;
    selectedDeviceType: DeviceType;
    setSelectedDevice: React.Dispatch<
        React.SetStateAction<DeviceFragment | null | undefined>
    >;
    setIsCreateDevice: React.Dispatch<React.SetStateAction<boolean>>;
    cancelEdit: () => void;
}

const CleaqSwal = withReactContent(Swal);

const CreateDeviceComponent = (props: createDeviceInterface) => {
    const [createDevice] = useCreateDeviceMutation();

    const {
        watch,
        setValue,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            name: "",
            serial: "",
            status: DeviceStatus.OTHER,
            deviceType: DeviceType.LAPTOP,
            deviceOs: DeviceOs.MAC,
            processor: "",
            screenSize: "",
            storageSize: "",
            memorySize: "",
            customNotes: "",
        },
    });
    const [selectedDeviceType, selectedDeviceOs] = watch([
        "deviceType",
        "deviceOs",
    ]);
    const deviceOsList = deviceOs(selectedDeviceType);
    useEffect(() => {
        if (selectedDeviceOs && !deviceOsList.includes(selectedDeviceOs)) {
            setValue("deviceOs", deviceOsList[0]);
        }
    }, [selectedDeviceOs, deviceOsList, setValue]);

    const CreateDeviceForm = async (dataPush: formContent) => {
        props.setSaving(true);
        try {
            await createDevice({
                variables: {
                    customNotes: dataPush.customNotes,
                    specs: {
                        deviceOs: dataPush.deviceOs,
                        deviceType: convertToDeviceType(dataPush.deviceType),
                        memorySize: dataPush.memorySize,
                        screenSize: dataPush.screenSize,
                        processor: dataPush.processor,
                        storageSize: dataPush.storageSize,
                    },
                    reference: dataPush.name,
                    serialNumber: dataPush.serial,
                    status: dataPush.status,
                },
                refetchQueries: [
                    refetchFindDevicesQuery(),
                    refetchComputeDashboardDataQuery(),
                ],
                update: async (_, result) => {
                    props.setIsCreateDevice(false);
                    if (result.data?.createDevice) {
                        props.setSelectedDevice(result.data.createDevice);
                    }
                },
            });
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html:
                    "Vérifiez le numéro de série renseigné." +
                    "<br/>Si le problème persiste, contactez nous dans le chat",
                icon: "error",
            });
        }
        props.setSaving(false);
    };

    function deviceTypes() {
        const options: any = [];
        typesLabels.forEach((value, key) => {
            options.push(
                <option
                    key={value}
                    value={key}
                >
                    {value}
                </option>,
            );
        });
        return options;
    }

    return (
        <div className="profile-mail">
            <Form
                noValidate
                onSubmit={handleSubmit(CreateDeviceForm)}
            >
                <div className="media">
                    <div className="media-body mt-0">
                        {!props.saving ? (
                            <div className="nlt-card-title-buttons">
                                <button
                                    type="submit"
                                    className="nlt-button-inside-form-save"
                                >
                                    Enregistrer
                                </button>
                                <div
                                    aria-hidden="true"
                                    onClick={props.cancelEdit}
                                    className="nlt-button-inside-form-cancel"
                                >
                                    Annuler
                                </div>
                            </div>
                        ) : (
                            <div className="nlt-loader-container">
                                <div className="nlt-loader-save" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="nlt-wide-card-right-panel-container">
                    <h6 className="mb-3">Informations générales</h6>
                    <ul>
                        <Row className="nlt-row-form-fix fix-padding">
                            <div className="nlt-item-info-label-edit col-md-5 required">
                                Nom
                            </div>
                            <FormGroup className="col-md-7">
                                {!errors?.name && (
                                    <span className="mandatory-label-small m-t-0" />
                                )}
                                <span className="nlt-mandatory-label-small nlt-font-red m-t-0">
                                    {errors?.name &&
                                        "Le nom de l'appareil est requis"}
                                </span>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: (value) =>
                                            value.trim().length > 0,
                                    }}
                                    render={({
                                        field: { ref, onChange, value },
                                    }) => (
                                        <Autocomplete
                                            id="autocomplete"
                                            inputValue={value}
                                            onInputChange={(_e, data: any) =>
                                                onChange(data)
                                            }
                                            onChange={(_e, data: any) =>
                                                onChange(data)
                                            }
                                            freeSolo
                                            options={props.devicesNames!}
                                            renderInput={(params) => (
                                                <TextField
                                                    name="name"
                                                    {...params}
                                                    margin="normal"
                                                    variant="outlined"
                                                    placeholder='MacBook Pro 15"'
                                                    className="nlt-form-fix"
                                                    innerRef={ref}
                                                    autoFocus
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Row>

                        <Row className="nlt-row-form-fix fix-padding">
                            <div className="nlt-item-info-label-edit col-md-5 required">
                                N° Série
                            </div>
                            <FormGroup className="col-md-7">
                                {!errors?.serial && (
                                    <span className="mandatory-label-small" />
                                )}
                                <span className="nlt-mandatory-label-small nlt-font-red">
                                    {errors?.serial &&
                                        "Le numéro de série est requis"}
                                </span>
                                <Controller
                                    name="serial"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: (value) =>
                                            value.trim().length > 0,
                                    }}
                                    render={({ field: { ref, ...rest } }) => (
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="text"
                                            placeholder="ABCD234GF"
                                            innerRef={ref}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Row>

                        <div className="nlt-form-spacing-fix">
                            <Row className="fix-padding">
                                <div className="nlt-item-info-label-edit col-md-5 required">
                                    Statut
                                </div>
                                <FormGroup className="col-md-7">
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({
                                            field: { ref, ...rest },
                                        }) => (
                                            <Input
                                                {...rest}
                                                name="status"
                                                className="form-control"
                                                type="select"
                                                innerRef={ref}
                                            >
                                                <option
                                                    value={DeviceStatus.OTHER}
                                                >
                                                    Matériel à associer
                                                </option>
                                                <option
                                                    value={DeviceStatus.SPARE}
                                                >
                                                    Matériel en stock
                                                </option>
                                                <option
                                                    value={
                                                        DeviceStatus.MAINTENANCE
                                                    }
                                                >
                                                    Matériel en maintenance
                                                </option>
                                            </Input>
                                        )}
                                    />
                                </FormGroup>
                            </Row>
                        </div>

                        <Row className="nlt-form-spacing-fix fix-padding">
                            <div className="nlt-item-info-label-edit col-md-5 required">
                                Type d'appareil
                            </div>
                            <FormGroup className="col-md-7">
                                <Controller
                                    name="deviceType"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { ref, ...rest } }) => (
                                        <Input
                                            {...rest}
                                            id="device-type"
                                            className="form-control"
                                            type="select"
                                            innerRef={ref}
                                        >
                                            {deviceTypes()}
                                        </Input>
                                    )}
                                />
                            </FormGroup>
                        </Row>

                        <Row className="nlt-form-spacing-fix fix-padding">
                            <div className="nlt-item-info-label-edit col-md-5 required">
                                OS de l'appareil
                            </div>
                            <FormGroup className="col-md-7">
                                <Controller
                                    name="deviceOs"
                                    control={control}
                                    render={({ field: { ref, ...rest } }) => (
                                        <Input
                                            {...rest}
                                            id="device-os"
                                            className="form-control"
                                            type="select"
                                            innerRef={ref}
                                        >
                                            {deviceOsList.map((os) => (
                                                <option
                                                    key={os}
                                                    value={os}
                                                >
                                                    {osLabels.get(os)}
                                                </option>
                                            ))}
                                        </Input>
                                    )}
                                />
                            </FormGroup>
                        </Row>

                        <div className="nlt-device-sep" />

                        <h6 className="mb-3">Caractéristiques techniques</h6>

                        <Row className="nlt-form-spacing-fix fix-padding">
                            <div className="nlt-item-info-label-edit col-md-5">
                                Taille d'écran
                            </div>
                            <FormGroup className="col-md-7">
                                <Controller
                                    name="screenSize"
                                    control={control}
                                    render={({ field: { ref, ...rest } }) => (
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="text"
                                            placeholder='15"'
                                            innerRef={ref}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Row>

                        <Row className="nlt-form-spacing-fix fix-padding">
                            <div className="nlt-item-info-label-edit col-md-5">
                                Stockage
                            </div>
                            <FormGroup className="col-md-7">
                                <Controller
                                    name="storageSize"
                                    control={control}
                                    render={({ field: { ref, ...rest } }) => (
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="text"
                                            placeholder="512Go"
                                            innerRef={ref}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Row>

                        <Row className="nlt-form-spacing-fix fix-padding">
                            <div className="nlt-item-info-label-edit col-md-5">
                                Mémoire vive (RAM)
                            </div>
                            <FormGroup className="col-md-7">
                                <Controller
                                    name="memorySize"
                                    control={control}
                                    render={({ field: { ref, ...rest } }) => (
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="text"
                                            placeholder="16Go"
                                            innerRef={ref}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Row>
                        <Row className="nlt-form-spacing-fix fix-padding">
                            <div className="nlt-item-info-label-edit col-md-5">
                                Processeur
                            </div>
                            <FormGroup className="col-md-7">
                                <Controller
                                    name="processor"
                                    control={control}
                                    render={({ field: { ref, ...rest } }) => (
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="text"
                                            placeholder="Apple M1"
                                            innerRef={ref}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Row>
                        <div className="nlt-device-sep" />

                        <h6 className="mb-3">Notes sur l'appareil</h6>
                        <FormGroup className="col-md-12">
                            <Controller
                                name="customNotes"
                                control={control}
                                render={({ field: { ref, ...rest } }) => (
                                    <Input
                                        {...rest}
                                        className="form-control"
                                        type="textarea"
                                        placeholder="Notes sur l'appareil"
                                        innerRef={ref}
                                    />
                                )}
                            />
                        </FormGroup>
                    </ul>
                </div>
            </Form>
        </div>
    );
};

export default CreateDeviceComponent;
