import React, { useState } from "react";

import { Button } from "@material-ui/core";

import "./toggle-filter.scss";

interface ToggleFilterProps {
    onClick: (selected: boolean) => void;
    children: React.ReactNode;
}

export default function ToggleFilter(props: ToggleFilterProps) {
    const [selected, setSelected] = useState(false);

    const handleClick = () => {
        setSelected(!selected);
        props.onClick(selected);
    };

    return (
        <Button
            data-cy="device-cleaq-toggle"
            className={`toggle-filter${selected ? " clicked" : ""}`}
            onClick={handleClick}
            variant="contained"
        >
            {props.children}
        </Button>
    );
}
