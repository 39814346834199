import React, { useState } from "react";

import moment from "moment";

import Product from "components/business/order/product/product";
import ProductAddress from "components/business/order/product/product-address";
import { getEarliestShippingDateAvailable } from "components/business/order/step-form/utils";
import InstructionEditIcon from "components/common/icons/InstructionEditIcon";
import SetProductDeliveryDateAction from "components/provider/context/cart/actions/set-product-delivery-date-action";
import { useCartDispatch } from "components/provider/context/cart/cart-provider";
import { DeliveryTypeInput } from "graphql/order/model/apollo";
import useEffectOnce from "utils/use-effect-once/use-effect-once";

import ProductItemAddress from "./product-item-address/product-item-address";
import ProductItemDate from "./product-item-date/product-item-date";
import ProductItemInfo from "./product-item-info/product-item-info";
import ProductItemPopup from "./product-item-popup/product-item-popup";

import "./product-item.scss";

interface ItemProps {
    product: Product;
    addresses: ProductAddress[];
    onCreateAddress: () => void;
    setSelectedProduct: React.Dispatch<React.SetStateAction<Product | null>>;
}

const ProductItem = (props: ItemProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [earliestShippingDate] = useState<Date>(
        getEarliestShippingDateAvailable(props.product.shippingDelay),
    );
    const cartDispatch = useCartDispatch();

    const setDeliveryDate = (date: Date, deliveryType: DeliveryTypeInput) => {
        props.setSelectedProduct(props.product);
        cartDispatch(
            new SetProductDeliveryDateAction(props.product, date, deliveryType),
        );
    };

    useEffectOnce(() => {
        setDeliveryDate(
            moment(props.product.deliveryDate.date).toDate(),
            props.product.deliveryDate.deliveryType,
        );
        props.setSelectedProduct(null);
    });

    const hasInstructions = (props.product.instructions?.length ?? 0) > 0;

    const instructionsButtonLabel = hasInstructions ? "Modifier" : "Ajouter";

    return (
        <div
            key={props.product.innerId}
            className="product-item"
        >
            <ProductItemInfo product={props.product} />
            <ProductItemAddress
                product={props.product}
                addresses={props.addresses}
                setSelectedProduct={props.setSelectedProduct}
                onCreateAddress={props.onCreateAddress}
            />
            <div className="product-item__date">
                <ProductItemDate
                    startDate={earliestShippingDate}
                    deliveryDate={props.product.deliveryDate}
                    setDeliveryDate={setDeliveryDate}
                    shippingDelay={props.product.shippingDelay}
                />
                <div className="product-item__instructions">
                    <button
                        type="button"
                        className="instructions__button"
                        onClick={() => setIsModalVisible(true)}
                    >
                        <InstructionEditIcon isFilled={hasInstructions} />
                        {instructionsButtonLabel}
                    </button>
                </div>
            </div>
            {isModalVisible && (
                <ProductItemPopup
                    setIsModalVisible={setIsModalVisible}
                    product={props.product}
                />
            )}
        </div>
    );
};

export default ProductItem;
