import React from "react";

import AddMember from "components/business/user/add-member";
import { OrganisationFragment } from "graphql/management/model/apollo";

interface tagDetailsDisplayInterface {
    selectedTag: OrganisationFragment | null | undefined;
    setSelectedTag: React.Dispatch<
        React.SetStateAction<OrganisationFragment | null | undefined>
    >;
}

const TagDetailsDisplay = (props: tagDetailsDisplayInterface) => (
    <ul>
        <li className="nlt-item-info">
            <div className="nlt-item-info-label">Nom du tag</div>
            <div className="nlt-item-info-data">{props.selectedTag?.name}</div>
        </li>
        <li className="nlt-item-info">
            <div className="nlt-item-info-label">Couleur du tag</div>
            <span
                className="color-pick-square"
                style={{
                    backgroundColor: props.selectedTag?.hexaColor!,
                }}
            />
        </li>
        <li className="nlt-item-info">
            <div className="nlt-item-info-label">Appareils</div>
            <div className="nlt-item-info-data">
                {props.selectedTag?.devices?.length || 0}
            </div>
        </li>
        <li className="nlt-item-info">
            <div className="nlt-item-info-label">Membres</div>
            <div className="nlt-item-info-data">
                {props.selectedTag?.users?.length || 0}
            </div>
        </li>
        <div className="nlt-device-sep" />
        <AddMember
            selectedOrganisation={props.selectedTag!}
            setSelectedOrganisation={props.setSelectedTag}
        />
    </ul>
);

export default TagDetailsDisplay;
