import React from "react";

interface AccessoryConfigTagProps {
    config: string | string[];
    unit?: string;
}

const AccessoryConfigTag = (props: AccessoryConfigTagProps) => {
    const { config, unit } = props;
    if (config.length > 0) {
        if (Array.isArray(config)) {
            return (
                <span className="specs-infos">
                    {config.length > 3
                        ? `${config.slice(0, 3).join(", ")} ...`
                        : config.join(", ")}
                </span>
            );
        }

        return (
            <span className="specs-infos">
                {config}
                {unit && unit}
            </span>
        );
    }

    return <></>;
};

AccessoryConfigTag.defaultProps = {
    unit: null,
};

export default AccessoryConfigTag;
