import React from "react";

import classnames from "classnames";

import isDark from "utils/color/isDark";
import type { hexColor } from "utils/color/types";

import styles from "./Tag.module.scss";

interface TagProps {
    label: string;
    color: hexColor;
}

const Tag = ({ label, color }: TagProps) => {
    return (
        <div
            className={styles.tag}
            style={{ backgroundColor: color }}
        >
            <p
                className={classnames(styles.tagLabel, {
                    [styles.blackText]: !isDark(color),
                    [styles.whiteText]: isDark(color),
                })}
            >
                {label}
            </p>
        </div>
    );
};

export default Tag;
