const isWeekDay = (date: Date) => date.getDay() !== 0 && date.getDay() !== 6;

function getEarliestShippingDateAvailable(delay: number): Date {
    const earliestShippingDate: Date = new Date();
    let remainingShippingDelay = delay;

    while (remainingShippingDelay > 0) {
        earliestShippingDate.setDate(earliestShippingDate.getDate() + 1);
        if (isWeekDay(earliestShippingDate)) {
            remainingShippingDelay -= 1;
        }
    }

    return earliestShippingDate;
}

export { isWeekDay, getEarliestShippingDateAvailable };
