import Filters from "components/business/catalog/filter/filters";
import { emptyFilters } from "components/provider/context/catalog-filters/catalog-filters-provider";

import CatalogFiltersAction from "./catalog-filters-action";

class ClearCatalogFiltersAction implements CatalogFiltersAction {
    private defaultFilters: Filters = emptyFilters;

    apply(_: Filters) {
        return this.defaultFilters;
    }
}

export default ClearCatalogFiltersAction;
