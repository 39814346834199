import React from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    OrganisationFragment,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    useDeleteOrganisationsMutation,
} from "graphql/management/model/apollo";

interface deleteTagInterface {
    selectedTag: OrganisationFragment | null | undefined;
    setSelectedTag: React.Dispatch<
        React.SetStateAction<OrganisationFragment | null | undefined>
    >;
}

const CleaqSwal = withReactContent(Swal);

const DeleteSingleTag = (props: deleteTagInterface) => {
    const [deleteOrganisations] = useDeleteOrganisationsMutation();

    const DeleteSingleOrganisation = () => {
        CleaqSwal.fire({
            title: "Vous souhaitez supprimer un tag !",
            html: "Les utilisateurs de ce tag seront toujours disponibles",
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
            cancelButtonText: "Annuler",
            denyButtonText: "Confirmer suppression",
        }).then((result) => {
            if (result.isDenied && props.selectedTag) {
                deleteOrganisations({
                    variables: {
                        ids: props.selectedTag.id,
                    },
                    refetchQueries: [
                        refetchFindUsersQuery(),
                        refetchFindOrganisationsQuery(),
                        refetchFindDevicesQuery(),
                    ],
                    onCompleted: () => {
                        props.setSelectedTag(null);
                    },
                })
                    .then((response: any) => {
                        CleaqSwal.fire({
                            title: "Confirmé !",
                            html: "Le tag a été supprimé",
                            icon: "success",
                        });
                    })
                    .catch((error: any) => {
                        CleaqSwal.fire({
                            title: "Oops, une erreur s'est produite !",
                            html: "Contactez-nous dans le chat !",
                            icon: "error",
                        });
                    });
            }
        });
    };

    return (
        <div
            aria-hidden="true"
            className="nlt-button-inside-delete"
            onClick={DeleteSingleOrganisation}
        >
            <i className="icofont icofont-bin" />
        </div>
    );
};

export default DeleteSingleTag;
