/* eslint-disable no-unused-expressions */
import { OrderStatus } from "graphql/order/model/apollo";

interface AddressStatus {
    label: string;
    key: OrderStatus;
    stylingProperty: string;
}

const StatusList = [
    "Commande refusée",
    "En attente de validation",
    "En attente de signature",
    "En cours",
    "Livraison en cours",
    "En cours de facturation",
    "Facturée",
];

export const OrderStatusFilterValues = new Map([
    [OrderStatus.PENDING_VALIDATION, StatusList[1]],
    [OrderStatus.PENDING_SIGNATURE, StatusList[2]],
    [OrderStatus.IN_PROGRESS, StatusList[3]],
    [OrderStatus.PENDING_DELIVERY, StatusList[4]],
    [OrderStatus.PENDING_BILLING, StatusList[5]],
    [OrderStatus.BILLED, StatusList[6]],
    [OrderStatus.REJECTED, StatusList[0]],
]);

export const defineAddressStatus = (status: OrderStatus): AddressStatus => {
    switch (status) {
        case "REJECTED":
            return {
                label: StatusList[0],
                key: OrderStatus.REJECTED,
                stylingProperty: "rejected",
            } as AddressStatus;
        case "PENDING_SIGNATURE":
            return {
                label: StatusList[2],
                key: OrderStatus.PENDING_SIGNATURE,
                stylingProperty: "waiting",
            } as AddressStatus;
        case "IN_PROGRESS":
            return {
                label: StatusList[3],
                key: OrderStatus.IN_PROGRESS,
                stylingProperty: "encours",
            } as AddressStatus;
        case "PENDING_DELIVERY":
            return {
                label: StatusList[4],
                key: OrderStatus.PENDING_DELIVERY,
                stylingProperty: "encours",
            } as AddressStatus;
        case "PENDING_BILLING":
            return {
                label: StatusList[5],
                key: OrderStatus.PENDING_BILLING,
                stylingProperty: "finalised",
            } as AddressStatus;
        case "BILLED":
            return {
                label: StatusList[6],
                key: OrderStatus.BILLED,
                stylingProperty: "finalised",
            } as AddressStatus;
        default:
            return {
                label: StatusList[1],
                key: OrderStatus.PENDING_VALIDATION,
                stylingProperty: "waiting",
            } as AddressStatus;
    }
};
