import React from "react";

import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    UserFormData,
    OrganisationOption,
} from "components/business/user/types";
import CustomNotes from "components/common/layout/custom-notes/custom-notes";
import {
    refetchFindAdminQuery,
    refetchFindCurrentUserQuery,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    UserFragment,
    UserRole,
    useUpdateUserMutation,
} from "graphql/management/model/apollo";

import AssignDevice from "../../assign-device";

import DisplayUserDetails from "./user-details";
import EditUserDetails from "./user-details-edit";
import UserDetailsMenu from "./user-details-menu";

interface UserDetailsInterface {
    saving: boolean;
    setSaving: React.Dispatch<React.SetStateAction<boolean>>;
    organisationOptions: OrganisationOption[] | undefined;
}

interface UserDetailsMenuInterface {
    editable: boolean;
    setEditable: React.Dispatch<React.SetStateAction<boolean>>;
    selectedUser: UserFragment;
    setSelectedUser: React.Dispatch<
        React.SetStateAction<UserFragment | null | undefined>
    >;
    setIsCreateUser: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserDetailsPanel = (
    props: UserDetailsInterface & UserDetailsMenuInterface,
) => {
    const { control, reset, handleSubmit } = useForm<UserFormData>({
        defaultValues: {
            phoneNumber: props.selectedUser.phoneNumber ?? "",
            email: props.selectedUser.email,
            firstName: props.selectedUser.firstName,
            lastName: props.selectedUser.lastName,
            organisations: (props.selectedUser.organisations ?? []).map(
                (orgaItem) => ({
                    value: orgaItem.id!,
                    label: orgaItem.name!,
                }),
            ),
            administrateur: props.selectedUser.role === UserRole.OWNER,
            customNotes: props.selectedUser.customNotes ?? "",
        },
    });

    const CancelEdit = () => {
        reset();
        props.setEditable(false);
        props.setSaving(false);
        props.setIsCreateUser(false);
    };

    const [updateUser] = useUpdateUserMutation();

    const CleaqSwal = withReactContent(Swal);

    const UpdateUserForm = async (dataPush: UserFormData) => {
        props.setSaving(true);
        try {
            await updateUser({
                variables: {
                    email: props.selectedUser?.email!,
                    firstName: dataPush.firstName,
                    lastName: dataPush.lastName,
                    organisations: dataPush.organisations?.map(
                        (orgaItem: any) => ({ id: orgaItem?.value! }),
                    ),
                    phoneNumber: dataPush.phoneNumber,
                    role:
                        dataPush?.administrateur === true
                            ? UserRole.OWNER
                            : UserRole.USER,
                    customNotes: dataPush.customNotes,
                },
                update: async (_, updatedUserResult) => {
                    const updatedUser = updatedUserResult?.data?.updateUser;
                    props.setSelectedUser(updatedUser);
                },
                refetchQueries: [
                    refetchFindDevicesQuery(),
                    refetchFindOrganisationsQuery(),
                    refetchFindUsersQuery(),
                    refetchFindCurrentUserQuery(),
                    refetchFindAdminQuery(),
                ],
            });
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Contactez-nous dans le chat !",
                icon: "error",
            });
        }
        CancelEdit();
    };

    return (
        <div className="profile-mail">
            <Form
                onSubmit={handleSubmit(UpdateUserForm)}
                name="management-user"
                id="management-user"
            >
                <div className="media">
                    <div className="media-body mt-0">
                        {!props.saving ? (
                            <UserDetailsMenu
                                resetForm={reset}
                                editable={props.editable}
                                setEditable={props.setEditable}
                                selectedUser={props.selectedUser}
                                setSelectedUser={props.setSelectedUser}
                                cancelEdit={CancelEdit}
                            />
                        ) : (
                            <div className="nlt-loader-container">
                                <div className="nlt-loader-save" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="nlt-wide-card-right-panel-container">
                    <h6 className="mb-3">Informations</h6>
                    <ul>
                        {!props.editable ? (
                            <>
                                <DisplayUserDetails
                                    selectedUser={props.selectedUser}
                                />
                                <div className="nlt-device-sep" />
                                <AssignDevice
                                    setSelectedUser={props.setSelectedUser}
                                    selectedUser={props.selectedUser}
                                />
                                <div className="nlt-device-sep" />
                                <CustomNotes
                                    notes={props.selectedUser.customNotes}
                                    data-cy="user-display-notes"
                                />
                            </>
                        ) : (
                            <EditUserDetails
                                formControl={control}
                                selectedUser={props.selectedUser}
                                organisationOptions={props.organisationOptions}
                            />
                        )}
                    </ul>
                </div>
            </Form>
        </div>
    );
};

export default UserDetailsPanel;
