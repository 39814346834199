import React from "react";

import { Col } from "reactstrap";

interface Item {
    itemName: string | undefined;
    itemCount: number | undefined;
}

interface DevicesListInfo {
    title: string;
    capitalise: boolean;
    listItem: Item[];
}

const DashboardDevicesList = (props: DevicesListInfo) => (
    <Col
        xl={4}
        md={4}
        sm={12}
    >
        <h6>Par {props.title}</h6>
        <div className="table table-bordernone">
            <div className="nlt-ranked-list">
                {props.listItem.map((item) => (
                    <div key={`${item.itemName}`}>
                        <div className="nlt-ranked-device-sep" />
                        <div className="nlt-ranked-device">
                            <div
                                className={
                                    props.capitalise
                                        ? "nlt-ranked-device-name capitalise"
                                        : "nlt-ranked-device-name "
                                }
                            >
                                {item.itemName}
                            </div>
                            <div className="nlt-ranked-device-quantity">
                                {item.itemCount}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </Col>
);

export default DashboardDevicesList;
