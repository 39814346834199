import React from "react";

interface deviceDisplayLineInterface {
    label: string;
    data: JSX.Element | string | null | undefined;
    "data-cy"?: string;
}

const DeviceDisplayLine = (props: deviceDisplayLineInterface) => (
    <li
        className="nlt-item-info"
        data-cy={props["data-cy"]}
    >
        <div
            className="nlt-item-info-label"
            data-cy={`${props["data-cy"]}-label`}
        >
            {props.label}
        </div>
        <div
            className="nlt-item-info-data"
            data-cy={`${props["data-cy"]}-data`}
        >
            {props.data}
        </div>
    </li>
);

DeviceDisplayLine.defaultProps = { "data-cy": null };

export default DeviceDisplayLine;
