interface SignatoryIconProps {
    color?: string;
}

export default function SignatoryIcon({ color = "black" }: SignatoryIconProps) {
    return (
        <svg
            width="24"
            height="18"
            viewBox="0 0 24 18"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.38691 0.299805C3.32936 0.299805 2.31513 0.719915 1.56733 1.46772C0.819525 2.21552 0.399414 3.22975 0.399414 4.2873V6.0998H23.5994V4.2873C23.5994 3.22975 23.1793 2.21552 22.4315 1.46772C21.6837 0.719915 20.6695 0.299805 19.6119 0.299805H4.38691ZM23.5994 7.5498H0.399414V13.7123C0.399414 14.7699 0.819525 15.7841 1.56733 16.5319C2.31513 17.2797 3.32936 17.6998 4.38691 17.6998H19.6119C20.6695 17.6998 21.6837 17.2797 22.4315 16.5319C23.1793 15.7841 23.5994 14.7699 23.5994 13.7123V7.5498ZM17.0744 13.3498H19.9744C20.1667 13.3498 20.3511 13.4262 20.4871 13.5622C20.623 13.6981 20.6994 13.8825 20.6994 14.0748C20.6994 14.2671 20.623 14.4515 20.4871 14.5875C20.3511 14.7234 20.1667 14.7998 19.9744 14.7998H17.0744C16.8821 14.7998 16.6977 14.7234 16.5618 14.5875C16.4258 14.4515 16.3494 14.2671 16.3494 14.0748C16.3494 13.8825 16.4258 13.6981 16.5618 13.5622C16.6977 13.4262 16.8821 13.3498 17.0744 13.3498Z"
                fill={color}
            />
        </svg>
    );
}
