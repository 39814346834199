import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";
import { Row } from "reactstrap";

import "./skeleton-company.scss";

const SkeletonCompany = () => (
    <>
        <div className="nlt-card-header mb-3">
            <Row>
                <div className="col-md-9 pull-left d-flex">
                    <div className="icon-company">
                        <Skeleton
                            variant="rect"
                            width={50}
                            height={50}
                        />
                    </div>
                    <div className="nlt-card-title-company">
                        <Skeleton width={75} />
                    </div>
                </div>
                <div className="col-md-3 pull-right" />
            </Row>
        </div>
        <Row className="m-b-12">
            <div className="col-md-12 skeleton-company-main-address">
                <div className="col-12">
                    <div>
                        <h5 className="mb-3">Adresse de facturation</h5>
                        <div className="row">
                            <ul className="col-6">
                                <div>
                                    <Skeleton width={200} />
                                </div>
                                <div>
                                    <Skeleton width={200} />
                                </div>
                                <div>
                                    <Skeleton width={200} />
                                </div>
                                <div>
                                    <Skeleton width={200} />
                                </div>
                            </ul>
                            <ul className="col-6">
                                <div>
                                    <Skeleton width={200} />
                                </div>
                                <div>
                                    <Skeleton width={200} />
                                </div>
                                <div>
                                    <Skeleton width={200} />
                                </div>
                                <div>
                                    <Skeleton width={200} />
                                </div>
                                <div>
                                    <Skeleton width={200} />
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    </>
);

export default SkeletonCompany;
