import { TempCart } from "components/provider/context/temp-cart/types";

import AddProduct from "./add-product";

class TempCartPricingInfo {
    negotiated: boolean;

    totalPrice: number;

    variantSubtotal: number;

    accessoriesSubtotal: number;

    normalPrice: number;

    constructor(
        negotiated: boolean,
        totalPrice: number,
        variantSubtotal: number,
        accessoriesSubtotal: number,
        normalPrice: number,
    ) {
        this.negotiated = negotiated;
        this.totalPrice = totalPrice;
        this.variantSubtotal = variantSubtotal;
        this.accessoriesSubtotal = accessoriesSubtotal;
        this.normalPrice = normalPrice;
    }
}

const buildTempCartPrice = (
    instanceId: string,
    tempCart: TempCart,
): TempCartPricingInfo => {
    const variantPricing = new AddProduct(
        tempCart.variant,
        instanceId,
        tempCart.quantity,
        tempCart.leasingDuration,
    ).buildPricingInfo();

    const tempCartPricing = new TempCartPricingInfo(
        variantPricing.negotiated,
        variantPricing.totalPrice,
        variantPricing.totalPrice,
        0,
        variantPricing.normalPrice,
    );

    tempCart.suggestedAccessories.forEach((accessory) => {
        const accessoryPricing = new AddProduct(
            accessory,
            instanceId,
            tempCart.quantity,
            tempCart.leasingDuration,
        ).buildPricingInfo();

        tempCartPricing.totalPrice += accessoryPricing.totalPrice;
        tempCartPricing.normalPrice += accessoryPricing.normalPrice;
        tempCartPricing.accessoriesSubtotal += accessoryPricing.totalPrice;

        if (accessoryPricing.negotiated === true) {
            tempCartPricing.negotiated = accessoryPricing.negotiated;
        }
    });

    return tempCartPricing;
};

export default buildTempCartPrice;
