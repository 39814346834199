import React from "react";

import Product from "components/business/order/product/product";
import {
    AudioProductSpecs,
    DeviceProductSpecs,
    DockstationProductSpecs,
    KeyboardsProductSpecs,
    MousesProductSpecs,
    ScreensProductSpecs,
} from "components/business/order/product/product-specs";
import translator, {
    formatIsIncluded,
    formatMemory,
} from "utils/translator/translator";
import translatorAccessoryType from "utils/translator/translator-accessory-type";
import translatorConnectivity from "utils/translator/translator-connectivity";

import "./products-specs.scss";

interface ProductsSpecsProps {
    product: Product;
    isCart: boolean;
}

const ProductsSpecs = (props: ProductsSpecsProps) => {
    const { product } = props;

    const formatSpecs = (label: string, data: string | null) => (
        <div className="cart-specs-items">
            <div
                className="cart-title"
                data-cy="cart-title"
            >
                {label}
            </div>
            <div
                className="cart-value"
                data-cy="cart-value"
            >
                {translator.translate(data)}
            </div>
        </div>
    );

    const buildSpecsProduct = (product: Product) => {
        if (product.type === "Variant") {
            const specsProduct = product.specs as DeviceProductSpecs;
            return (
                <>
                    {formatSpecs("Processeur :", specsProduct.processor)}
                    {formatSpecs(
                        "Mémoire RAM :",
                        formatMemory(parseFloat(specsProduct.ram ?? "0")),
                    )}
                    {formatSpecs(
                        "Stockage :",
                        formatMemory(parseFloat(specsProduct.storage ?? "0")),
                    )}
                </>
            );
        }
        if (product.type === "Audio") {
            const specsAudio = product.specs as AudioProductSpecs;
            return (
                <>
                    {formatSpecs(
                        "Type :",
                        translatorAccessoryType.translate(specsAudio.type),
                    )}
                    {formatSpecs(
                        "Réduction du bruit :",
                        formatIsIncluded(specsAudio.noiseCancellation),
                    )}
                    {formatSpecs(
                        "Connectivité :",
                        specsAudio.connectivity
                            .map((connectivity) =>
                                translatorConnectivity.translate(connectivity),
                            )
                            .join(", "),
                    )}
                </>
            );
        }
        if (product.type === "Dockstation") {
            const specsDockstation = product.specs as DockstationProductSpecs;
            return (
                <>
                    {formatSpecs(
                        "Connexion :",
                        translatorConnectivity.translate(
                            specsDockstation.connection,
                        ),
                    )}
                    {formatSpecs(
                        "Compatibilité :",
                        specsDockstation.compatibility,
                    )}
                    {formatSpecs(
                        "Connectivité :",
                        `${
                            specsDockstation.connectivity.map((connectivity) =>
                                translatorConnectivity.translate(connectivity),
                            ).length
                        } en 1`,
                    )}
                </>
            );
        }
        if (product.type === "Keyboard") {
            const specsKeyboard = product.specs as KeyboardsProductSpecs;
            return (
                <>
                    {formatSpecs(
                        "Compatibilité :",
                        specsKeyboard.compatibility,
                    )}
                    {formatSpecs(
                        "Pavé numérique :",
                        formatIsIncluded(specsKeyboard.numPad),
                    )}
                    {formatSpecs(
                        "Connectivité :",
                        specsKeyboard.connectivity
                            .map((connectivity) =>
                                translatorConnectivity.translate(connectivity),
                            )
                            .join(", "),
                    )}
                </>
            );
        }
        if (product.type === "Mouse") {
            const specsMouse = product.specs as MousesProductSpecs;
            return (
                <>
                    {formatSpecs(
                        "Batterie :",
                        translatorAccessoryType.translate(specsMouse.power),
                    )}
                    {formatSpecs("Compatibilité :", specsMouse.compatibility)}
                    {formatSpecs(
                        "Connectivité :",
                        specsMouse.connectivity
                            .map((connectivity) =>
                                translatorConnectivity.translate(connectivity),
                            )
                            .join(", "),
                    )}
                </>
            );
        }
        if (product.type === "Screen") {
            const specsScreen = product.specs as ScreensProductSpecs;
            // eslint-disable-next-line quotes
            const size = specsScreen.size.toString().concat('"');
            return (
                <>
                    {formatSpecs("Taille :", size)}
                    {formatSpecs("Résolution :", specsScreen.resolution)}
                    {formatSpecs(
                        "Connectivité :",
                        `${
                            specsScreen.connectivity.map((connectivity) =>
                                translatorConnectivity.translate(connectivity),
                            ).length
                        } interfaces`,
                    )}
                </>
            );
        }
        return "";
    };

    return (
        <div
            className={props.isCart ? "cart-specs" : "user-tunnel-specs"}
            data-cy={props.isCart ? "cart-specs" : "user-tunnel-specs"}
        >
            {buildSpecsProduct(product)}
        </div>
    );
};

export default ProductsSpecs;
