interface CheckIconProps {
    color: string;
}

const CheckIcon = ({ color = "#000" }: CheckIconProps) => (
    <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.39159 7.0312L10.2916 1.1312C10.4749 0.947867 10.7083 0.856201 10.9916 0.856201C11.2749 0.856201 11.5083 0.947867 11.6916 1.1312C11.8749 1.31453 11.9666 1.54787 11.9666 1.8312C11.9666 2.11453 11.8749 2.34787 11.6916 2.5312L5.09159 9.1312C4.89159 9.3312 4.65826 9.4312 4.39159 9.4312C4.12492 9.4312 3.89159 9.3312 3.69159 9.1312L1.09159 6.5312C0.908256 6.34787 0.816589 6.11453 0.816589 5.8312C0.816589 5.54787 0.908256 5.31453 1.09159 5.1312C1.27492 4.94787 1.50826 4.8562 1.79159 4.8562C2.07492 4.8562 2.30826 4.94787 2.49159 5.1312L4.39159 7.0312Z"
            fill={color}
        />
    </svg>
);

export default CheckIcon;
