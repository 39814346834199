/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import type { UseFormReturn, SubmitHandler } from "react-hook-form";
import { Form } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    DeviceFragment,
    DeviceSource,
    DeviceType,
    refetchComputeDashboardDataQuery,
    refetchFindDevicesQuery,
    refetchFindUsersQuery,
    useFindDeviceIncidentQuery,
    useUpdateDeviceMutation,
} from "graphql/management/model/apollo";
import formContent from "utils/device/device-form";
import type { FormData } from "utils/device/device-form";

import DeleteSingleDevice from "../delete/delete-single-device";
import Incident from "../incident";

import DeviceDetailsDisplay from "./display/display-device";
import DeviceUpdateForm from "./update/update-device";

import "./device-details.scss";

interface deviceDetailsInterface {
    deviceForm: UseFormReturn<FormData>;
    saving: boolean;
    setSaving: React.Dispatch<React.SetStateAction<boolean>>;
    editable: boolean;
    setEditable: React.Dispatch<React.SetStateAction<boolean>>;
    isCreateDevice: boolean;
    isKeycloakUser: Boolean;
    deviceNames: string[];
    selectedDevice: DeviceFragment;
    setSelectedDevice: React.Dispatch<
        React.SetStateAction<DeviceFragment | null | undefined>
    >;
    selectedDeviceType: DeviceType;
    setSelectedDeviceType: React.Dispatch<React.SetStateAction<DeviceType>>;
    nameValue: string;
    setNameValue: React.Dispatch<React.SetStateAction<string>>;
    cancelEdit: () => void;
}

const CleaqSwal = withReactContent(Swal);

const DeviceDetails = (props: deviceDetailsInterface) => {
    const { deviceForm } = props;
    const { handleSubmit } = deviceForm;
    const { data: incidents } = useFindDeviceIncidentQuery();
    const [updateDevice] = useUpdateDeviceMutation();

    const UpdateDeviceForm: SubmitHandler<formContent> = async (
        dataPush: formContent,
    ) => {
        props.setSaving(true);
        try {
            await updateDevice({
                variables: {
                    customNotes: dataPush.customNotes,
                    reference: props.nameValue
                        ? props.nameValue
                        : props.selectedDevice.reference,
                    status: dataPush.status
                        ? dataPush.status
                        : props.selectedDevice.status,
                    specs: {
                        deviceOs: dataPush.deviceOs
                            ? dataPush.deviceOs
                            : props.selectedDevice.specs.deviceOs,
                        deviceType: dataPush.deviceType
                            ? dataPush.deviceType
                            : props.selectedDevice.specs.deviceType,
                        storageSize: dataPush.storageSize
                            ? dataPush.storageSize
                            : props.selectedDevice.specs.storageSize,
                        processor: dataPush.processor
                            ? dataPush.processor
                            : props.selectedDevice.specs.processor,
                        screenSize: dataPush.screenSize
                            ? dataPush.screenSize
                            : props.selectedDevice.specs.screenSize,
                        memorySize: dataPush.memorySize
                            ? dataPush.memorySize
                            : props.selectedDevice.specs.memorySize,
                    },
                    serialNumber: props.selectedDevice?.serialNumber!,
                },
                update: async (_, result) => {
                    if (result.data?.updateDevice != null) {
                        props.setSelectedDevice(result.data.updateDevice);
                    }
                },
                refetchQueries: [
                    refetchFindDevicesQuery(),
                    refetchFindUsersQuery(),
                    refetchComputeDashboardDataQuery(),
                ],
            });
        } catch (error) {
            await CleaqSwal.fire({
                title: "Oops, une erreur s'est produite !",
                html: "Contactez-nous dans le chat !",
                icon: "error",
            });
        }
        props.cancelEdit();
    };

    return (
        <div className="profile-mail">
            <Form onSubmit={handleSubmit(UpdateDeviceForm)}>
                <div className="media">
                    <div className="media-body mt-0">
                        {!props.saving ? (
                            <div className="nlt-card-title-buttons">
                                {props.editable ? (
                                    <>
                                        {!props.isKeycloakUser &&
                                            props.selectedDevice.source !==
                                                DeviceSource.CLEAQ && (
                                                <DeleteSingleDevice
                                                    selectedDevice={
                                                        props.selectedDevice
                                                    }
                                                    setSelectedDevice={
                                                        props.setSelectedDevice
                                                    }
                                                />
                                            )}
                                        <button
                                            type="submit"
                                            className="nlt-button-inside-form-save"
                                        >
                                            Enregistrer
                                        </button>
                                        <div
                                            aria-hidden="true"
                                            onClick={props.cancelEdit}
                                            className="nlt-button-inside-form-cancel"
                                        >
                                            Annuler
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {!props.isCreateDevice &&
                                            props.selectedDevice.source ===
                                                DeviceSource.CLEAQ && (
                                                <div>
                                                    <Incident
                                                        selectedDevice={
                                                            props.selectedDevice
                                                        }
                                                        incident={
                                                            incidents?.findDeviceIncident?.find(
                                                                (incident) =>
                                                                    incident?.serialNumber ===
                                                                    props
                                                                        .selectedDevice
                                                                        ?.serialNumber,
                                                            ) || null
                                                        }
                                                        setSelectedDevice={
                                                            props.setSelectedDevice
                                                        }
                                                    />
                                                </div>
                                            )}
                                        {!props.isKeycloakUser && (
                                            <div>
                                                <a
                                                    data-cy="device-edit-button"
                                                    aria-hidden="true"
                                                    className="nlt-button-inside"
                                                    onClick={() => {
                                                        props.setEditable(true);
                                                        props.setSelectedDeviceType(
                                                            props.selectedDevice
                                                                ?.specs
                                                                .deviceType,
                                                        );
                                                        props.setNameValue(
                                                            props.selectedDevice
                                                                ?.reference!,
                                                        );
                                                    }}
                                                >
                                                    <i className="icofont icofont-pencil-alt-2 mr-2" />
                                                    Editer
                                                </a>
                                            </div>
                                        )}
                                        <div className="float-left">
                                            <a
                                                aria-hidden="true"
                                                className="nlt-button-inside-close"
                                                onClick={() => {
                                                    props.setSelectedDevice(
                                                        null,
                                                    );
                                                }}
                                            >
                                                <i className="icofont icofont-close-line" />
                                            </a>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="nlt-loader-container">
                                <div className="nlt-loader-save" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="nlt-wide-card-right-panel-container device-details-container">
                    <h6 className="mb-3">Informations générales</h6>
                    <ul>
                        {props.editable ? (
                            <DeviceUpdateForm
                                deviceForm={props.deviceForm}
                                deviceNames={props.deviceNames}
                                selectedDevice={props.selectedDevice}
                                setSelectedDevice={props.setSelectedDevice}
                                selectedDeviceType={props.selectedDeviceType}
                                setSelectedDeviceType={
                                    props.setSelectedDeviceType
                                }
                                nameValue={props.nameValue}
                                setNameValue={props.setNameValue}
                            />
                        ) : (
                            <DeviceDetailsDisplay
                                selectedDevice={props.selectedDevice}
                                setSelectedDevice={props.setSelectedDevice}
                                isKeycloakUser={props.isKeycloakUser}
                            />
                        )}
                    </ul>
                </div>
            </Form>
        </div>
    );
};

export default DeviceDetails;
