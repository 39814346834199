import React, { useState } from "react";

import classnames from "classnames";

import styles from "./MultiToggle.module.scss";

interface MultiToggleProps {
    options: string[];
    onToggle: (option: string) => void;
}

const MultiToggle = ({ options, onToggle }: MultiToggleProps) => {
    const [selected, setSelected] = useState<number>(0);

    return (
        <div className={styles.multiToggle}>
            {options.map((option, index) => (
                <button
                    key={option}
                    className={classnames(
                        index === 0
                            ? `${styles.firstButton}`
                            : index === options.length - 1
                            ? `${styles.lastButton}`
                            : `${styles.button}`,
                        {
                            [styles.selected]: index === selected,
                        },
                        styles.buttons,
                    )}
                    onClick={() => {
                        onToggle(option);
                        setSelected(index);
                    }}
                    data-cy={`toggle-${option}`}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

export default MultiToggle;
