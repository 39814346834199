enum FiltersTypeEnum {
    laptop = "laptop",
    tablet = "tablet",
    smartphone = "smartphone",
    desktop = "desktop",
    screen = "screen",
    keyboard = "keyboard",
    mouse = "mouse",
    dockstation = "dockstation",
    audio = "audio",
}

export default FiltersTypeEnum;
