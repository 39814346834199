import React, { useEffect } from "react";
import { useState } from "react";

import { Tab } from "@headlessui/react";

import styles from "./Tabs.module.scss";

interface TabsProps {
    tabs: string[];
    onClick: (tab: string) => void;
    activeTab?: string;
}

const Tabs = ({ tabs, onClick, activeTab }: TabsProps) => {
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        const tabIndex = tabs.findIndex((tab) => tab === activeTab);

        if (tabIndex !== -1) {
            setSelectedTab(tabIndex);
        }
    }, [activeTab, tabs]);

    return (
        <div className={styles.container}>
            <Tab.Group>
                <Tab.List className={styles.tabList}>
                    {tabs.map((tab, index) => (
                        <Tab
                            key={tab}
                            className={`${styles.tab} ${
                                selectedTab === index && styles.selectedTab
                            }`}
                            onClick={() => {
                                setSelectedTab(index);
                                onClick(tab);
                            }}
                        >
                            {tab}
                        </Tab>
                    ))}
                </Tab.List>
            </Tab.Group>
        </div>
    );
};

Tabs.defaultProps = { activeTab: undefined };

export default Tabs;
