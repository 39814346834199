import React from "react";

import CartIcon from "components/common/icons/catalog/cart-icon";
import { useCart } from "components/provider/context/cart/cart-provider";
import { formatPrice } from "utils/translator/translator";

import "./cart-summary.scss";

interface CartSummaryProps {
    onCartToggle: () => void;
    isCartVisible: boolean;
    isCatalogStep: boolean;
}

const CartSummary = (props: CartSummaryProps) => {
    const cart = useCart();
    const cartSum = cart.reduce((acc, product) => acc + product.pricing, 0);
    const productSum = formatPrice(cartSum);
    const productCount = cart.length;

    return (
        <button
            type="button"
            className={`cart-icon ${
                !props.isCartVisible && props.isCatalogStep
                    ? "cart-icon-small"
                    : ""
            }`}
            onClick={() => props.onCartToggle()}
            data-cy="cart-icon"
        >
            {!props.isCartVisible && props.isCatalogStep ? (
                <div className="icon">
                    <CartIcon />
                    <span className="icon-count">{productCount}</span>
                </div>
            ) : (
                <>
                    <div className="icon">
                        <CartIcon />
                    </div>
                    <div>
                        <div
                            className="cart-info"
                            data-cy="cart-summary-count"
                        >
                            <span>{productCount}</span>
                            <span> Produits</span>
                        </div>
                        <div
                            className="cart-info"
                            data-cy="cart-summary-price"
                        >
                            <span>{productSum}</span>
                            <span> HT / mois</span>
                        </div>
                    </div>
                </>
            )}
        </button>
    );
};

export default CartSummary;
