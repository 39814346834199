import React from "react";

import { AdminUserFragment } from "graphql/management/model/apollo";

import AdminItem from "./admin-item/admin-item";
import SkeletonAdminItem from "./admin-item/skeleton-admin-item/skeleton-admin-item";

interface AdminListProps {
    admins: AdminUserFragment[] | undefined;
    loading: boolean;
}
export default function AdminList(props: AdminListProps) {
    return (
        <div className="col-md-4">
            <div className="custom-card col-12">
                <div className="nlt-card-header">
                    <div className="nlt-card-title mb-2">
                        Mes Administrateurs
                    </div>
                </div>
                <div className="appointment-table table-responsive">
                    <div className="nlt-admin-list">
                        {props.loading ? (
                            <SkeletonAdminItem />
                        ) : (
                            props.admins?.map((user) => (
                                <AdminItem
                                    key={user.email}
                                    firstName={user?.firstName!}
                                    lastName={user?.lastName!}
                                    mail={user?.email!}
                                />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
