import React from "react";

import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import "./multi-actions-button.scss";

interface Action {
    title: string;
    icon: React.ReactNode | undefined;
    onClick: () => void;
}

interface MultipleActionsButtonInfo {
    title: React.ReactNode | undefined;
    actions: Action[];
    "data-cy": string;
}

const useStyles = makeStyles({
    root: {
        background: "#FF625A",
        borderRadius: 6,
        color: "#f7f3eb",
        height: 34,
        padding: "8px 5px 8px 10px",
        boxShadow: "none",
    },
    label: {
        textTransform: "none",
        fontSize: "14px",
    },
});

const MultiActionsButton = (props: MultipleActionsButtonInfo) => {
    const classes = useStyles();
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div
                data-cy={props["data-cy"]}
                className="multiple-actions-button"
            >
                <ButtonGroup
                    variant="contained"
                    ref={anchorRef}
                    aria-label="split button"
                    classes={{
                        root: classes.root,
                    }}
                >
                    <Button
                        size="small"
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        classes={{
                            root: classes.root,
                            label: classes.label,
                        }}
                    >
                        <div>
                            {props.title}
                            {open ? <ArrowDropUp /> : <ArrowDropDown />}
                        </div>
                    </Button>
                </ButtonGroup>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    placement="bottom-end"
                    className="popup-width"
                >
                    {({ TransitionProps }) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <Grow {...TransitionProps}>
                            <Paper>
                                <MenuList
                                    id="split-button-menu"
                                    autoFocusItem
                                >
                                    {props.actions.map((action) => (
                                        <MenuItem
                                            key={action.title}
                                            onClick={(e) => {
                                                setOpen(false);
                                                action.onClick();
                                            }}
                                        >
                                            <div className="action-item-icon">
                                                <div>{action.icon}</div>
                                                <div className="icon">
                                                    {action.title}
                                                </div>
                                            </div>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default MultiActionsButton;
