import Filters from "components/business/catalog/filter/filters";

import CatalogFiltersAction from "./actions/catalog-filters-action";

const catalogFiltersReducer = (
    catalogFilters: Filters,
    action: CatalogFiltersAction,
) => {
    const updatedCatalogFilters = action.apply(catalogFilters);
    return updatedCatalogFilters;
};

export default catalogFiltersReducer;
