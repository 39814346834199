import React from "react";

const DevicePlaceHolder = () => (
    <div className="nlt-device-placeholder">
        <div className="nlt-document-placeholder-icon">
            <i className="icofont icofont-computer" />
        </div>
        <div className="nlt-document-info">
            <div className="nlt-document-title">Aucun Appareil</div>
            <div className="nlt-document-date">
                Retrouvez ici la liste de vos appareils
            </div>
        </div>
    </div>
);

export default DevicePlaceHolder;
