import React from "react";

import AddProduct from "components/business/catalog/utils/add-product";
import { AccessoryType } from "components/business/catalog/utils/types";
import getSpecsFromAccessory from "utils/catalog/get-specs-from-accessory";
import { formatPrice } from "utils/translator/translator";

import "./accessory.scss";

interface AccessoryProps {
    accessory: AccessoryType;
    instanceId: string;
}

const Accessory = (props: AccessoryProps) => {
    const pricingInfo = new AddProduct(
        props.accessory,
        props.instanceId,
        1,
        36,
    ).buildPricingInfo();

    const specs = getSpecsFromAccessory(props.accessory);

    const displayConnectivity = (key: string) => {
        if (key === "Connectivité :" && specs.get(key)!.split(",").length > 3) {
            return `${specs.get(key)!.split(",").slice(0, 3).join(", ")} ...`;
        }
        return specs.get(key);
    };

    return (
        <>
            {props.accessory.image?.url && (
                <img
                    src={props.accessory.image.url}
                    alt={
                        props.accessory.image.title ||
                        props.accessory.name ||
                        ""
                    }
                    className="accessory-image"
                />
            )}
            <div className="accessory">
                <div className="accessory-header">
                    <h4 className="accessory-title">{props.accessory.name}</h4>
                </div>
                <div className="accessory-price">
                    <strong>{formatPrice(pricingInfo.price)}</strong> HT / mois
                    {pricingInfo.negotiated && (
                        <i className="icofont icofont-star" />
                    )}
                </div>
                <div className="accessory-details">
                    <div className="details">
                        {Array.from(specs.keys()).map((key) => (
                            <div
                                className="accessory-spec"
                                key={key}
                            >
                                <span>{key}</span>
                                <span>{displayConnectivity(key)}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Accessory;
