import React from "react";

import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import styles from "./SkeletonIntegrations.module.scss";

const IntegrationsSkeleton = () => {
    return (
        <>
            <div className={styles.skeletonContainer}>
                <Skeleton
                    width={110}
                    height={110}
                    containerClassName={styles.skeletonImage}
                    baseColor="#e1deda"
                    highlightColor="#fcf9f4"
                    borderRadius={10}
                />
                <div className={styles.skeletonContent}>
                    <div className={styles.skeletonHeader}>
                        <Skeleton
                            height={16}
                            width={190}
                            baseColor="#e1deda"
                            highlightColor="#fcf9f4"
                        />
                    </div>
                    <Skeleton
                        height={12}
                        width={120}
                        baseColor="#e1deda"
                        highlightColor="#fcf9f4"
                    />
                    <div className={styles.skeletonDetails}>
                        <Skeleton
                            height={12}
                            width={120}
                            baseColor="#e1deda"
                            highlightColor="#fcf9f4"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntegrationsSkeleton;
