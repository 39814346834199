import React from "react";

const TagPlaceHolder = () => (
    <div className="nlt-tag-placeholder">
        <div className="nlt-document-placeholder-icon">
            <i className="icofont icofont-ui-user-group" />
        </div>
        <div className="nlt-document-info">
            <div className="nlt-document-title">Aucun Tag</div>
            <div className="nlt-document-date">
                Retrouvez ici la liste de vos tags
            </div>
        </div>
    </div>
);

export default TagPlaceHolder;
