import React from "react";

import styles from "./Description.module.scss";

export interface Specs {
    name: string;
    value: string;
}
interface DescriptionProps {
    details: Specs[] | string;
}

const Description = ({ details }: DescriptionProps) => {
    return (
        <div className={styles.description}>
            <div className={styles.details}>
                {Array.isArray(details)
                    ? details.map((spec) => {
                          return (
                              <div
                                  className={styles.spec}
                                  key={spec.name}
                              >
                                  <span>{spec.name} :</span>
                                  <span>{spec.value}</span>
                              </div>
                          );
                      })
                    : details}
            </div>
        </div>
    );
};

export default Description;
