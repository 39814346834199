/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState } from "react";

import BillingIcon from "components/common/icons/BillingIcon";
import ContractIcon from "components/common/icons/ContractIcon";
import OtherIcon from "components/common/icons/OtherIcon";
import ScheduleIcon from "components/common/icons/ScheduleIcon";
import SubscriptionIcon from "components/common/icons/SubscriptionIcon";
import SkeletonDocument from "components/common/layout/file-manager/skeleton-document/skeleton-document";
import SearchBar from "components/common/layout/searchbar/searchbar";
import {
    InstanceFileFragment,
    usePresignDownloadLazyQuery,
} from "graphql/management/model/apollo";

import DocumentPlaceholder from "./document-placeholder/document-placeholder";

import "./file-manager.scss";

interface DocumentItemProps {
    document: InstanceFileFragment;
}

const DocumentItem = ({ document }: DocumentItemProps) => {
    const [presignDownload] = usePresignDownloadLazyQuery({
        fetchPolicy: "network-only",
        onCompleted: (link) => window.open(link.presignDownload, "_blank"),
    });

    function downloadFile(file: InstanceFileFragment) {
        if (file.fileType === "SAAS_INVOICE") {
            window.open(file.filePath!, "_blank");
            return;
        }
        presignDownload({
            variables: { filePath: file.filePath },
        });
    }

    function getStatusCBackgroundColorClass() {
        switch (document.fileType) {
            case "CONTRACT":
                return "contract-color";
            case "SCHEDULE":
                return "schedule-color";
            case "BILLING":
                return "billing-color";
            case "SUBSCRIPTION_INVOICE":
                return "subscription-color";
            default:
                return "document-default-color";
        }
    }

    function getTypeLabel() {
        switch (document.fileType) {
            case "CONTRACT":
                return "Contrat";
            case "SCHEDULE":
                return "Échéancier";
            case "SUBSCRIPTION_INVOICE":
                return "Facture";
            case "BILLING":
                return "Facture intermédiaire";
            default:
                return "";
        }
    }

    function getDocumentIcon() {
        switch (document.fileType) {
            case "CONTRACT":
                return <ContractIcon />;
            case "SCHEDULE":
                return <ScheduleIcon />;
            case "SUBSCRIPTION_INVOICE":
                return <SubscriptionIcon />;
            case "BILLING":
                return <BillingIcon />;
            default:
                return <OtherIcon />;
        }
    }

    return (
        <div
            aria-hidden="true"
            onClick={() => downloadFile(document)}
            className="document-item"
            data-cy="document-item"
        >
            <div
                className={`document-color ${getStatusCBackgroundColorClass()}`}
            />
            <div className="document-icon">{getDocumentIcon()}</div>
            <div className="document-info">
                <div
                    className="document-name"
                    data-cy="document-name"
                >
                    {document.fileName}
                </div>
                <div
                    className="document-type"
                    data-cy="document-type"
                >
                    {getTypeLabel()}
                </div>
            </div>
        </div>
    );
};

interface FileManagerProps {
    title?: string;
    files: InstanceFileFragment[] | undefined | null;
    loading: boolean;
}

const FileManager = (props: FileManagerProps) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setSearchTerm(event.target.value);
    };

    const fileList = props.files?.filter((data) => {
        if (searchTerm == null) return data;
        if (data.fileName?.toLowerCase().includes(searchTerm.toLowerCase())) {
            return data;
        }
    });

    const displayDocuments = () => {
        if (fileList && fileList.length > 0) {
            return (
                <>
                    {fileList.map((document) => (
                        <DocumentItem
                            key={document.filePath}
                            document={document}
                        />
                    ))}
                </>
            );
        }
        return <DocumentPlaceholder />;
    };

    return (
        <div className="file-manager">
            <div className="custom-card col-12">
                <div className="nlt-card-header">
                    <span className="nlt-card-title">{props.title}</span>
                </div>
                <SearchBar
                    searchBarType="card"
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleSearchChange={handleSearchChange}
                />
                <div
                    className="documents"
                    data-cy="documents"
                >
                    {props.loading ? <SkeletonDocument /> : displayDocuments()}
                </div>
            </div>
        </div>
    );
};

FileManager.defaultProps = { title: "Mes Documents" };

export default FileManager;
