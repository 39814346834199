import React, { useState } from "react";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Redirect, useHistory, useParams } from "react-router";

import BravasConfiguration from "components/business/Integration/BravasConfiguration/BravasConfiguration";
import useDeleteIntegration from "components/business/Integration/Hooks/useDeleteIntegration";
import useFindCatalogIntegrations from "components/business/Integration/Hooks/useFindCatalogIntegrations";
import LeftChevronIcon from "components/common/icons/LeftChevronIcon";
import SmallScreen from "components/common/layout/banner/small-screen";
import Button from "components/common/layout/Button/Button";
import Link from "components/common/Link/Link";
import Tabs from "components/common/Tab/Tabs";
import Tag from "components/common/Tag/Tag";
import {
    useFindIntegrationsQuery,
    IntegrationConfigOutput,
    IntegrationProvider,
} from "graphql/management/model/apollo";

import styles from "./Integration.module.scss";
import SkeletonIntegration from "./SkeletonIntegration/SkeletonIntegration";

interface IntegrationParams {
    integrationName: string;
}

const Integration = () => {
    const [activeTab, setActiveTab] = useState("Description");

    const { integrationName }: IntegrationParams = useParams();
    const history = useHistory();

    const { data: catalogIntegrations, loading: catalogQueryLoading } =
        useFindCatalogIntegrations();

    const { data: instanceIntegrations, loading: instanceQueryLoading } =
        useFindIntegrationsQuery();

    const [deleteIntegration] = useDeleteIntegration(integrationName);

    const activeIntegrationProviders =
        instanceIntegrations?.currentInstance?.integrations.map((i) =>
            i.provider.toString(),
        );

    const findConfigValueByName = (
        configs: IntegrationConfigOutput[] | undefined,
        targetName: string,
    ): string | null => {
        if (configs === undefined) {
            return null;
        }

        for (const config of configs) {
            if (config.name === targetName) {
                return config.value;
            }
        }

        return null;
    };

    const returnOnIntegrations = () =>
        history.push(`${process.env.PUBLIC_URL}/integrations`);

    const integration = catalogIntegrations.find(
        (i) => i.name === integrationName,
    );

    if (catalogQueryLoading || instanceQueryLoading) {
        return <SkeletonIntegration />;
    }

    if (integration === undefined) {
        return <Redirect to="/not-found" />;
    }

    const integrationProvider =
        IntegrationProvider[
            integration.name.toUpperCase() as keyof typeof IntegrationProvider
        ];

    const integrationConfig =
        instanceIntegrations?.currentInstance?.integrations.find(
            (i) => i.provider === integrationProvider,
        )?.config;

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    const handleActivateIntegration = () => {
        setActiveTab("Configuration");
    };

    return (
        <>
            <div
                className={styles.container}
                key={integration.name}
            >
                <div className={styles.returnContainer}>
                    <Button
                        theme="outlinedTertiary"
                        onClick={returnOnIntegrations}
                    >
                        <div className={styles.returnButton}>
                            <LeftChevronIcon /> RETOUR
                        </div>
                    </Button>
                </div>
                <div className={styles.content}>
                    <div className={styles.left}>
                        <div>
                            <img
                                alt={integration.displayName}
                                src={integration.logoUrl}
                                className={styles.logo}
                            />
                        </div>
                        <div className={styles.tag}>
                            <Tag
                                label={integration.category.toUpperCase()}
                                color={"#F7B87D"}
                            />
                        </div>
                        <div className={styles.links}>
                            {integration.links.map((link) => {
                                return (
                                    <Link
                                        url={link}
                                        hasIcon={true}
                                        label={integration.displayName}
                                        key={link}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.header}>
                            <div>
                                <h1 className={styles.title}>
                                    {integration.displayName}
                                </h1>
                                <p className={styles.shortDescription}>
                                    {integration.shortDescription}
                                </p>
                            </div>
                            <div className={styles.toggleButton}>
                                {activeIntegrationProviders?.includes(
                                    integration.name.toUpperCase(),
                                ) ? (
                                    <Button
                                        theme="outlinedSecondary"
                                        onClick={deleteIntegration}
                                    >
                                        Désactiver
                                    </Button>
                                ) : (
                                    <Button
                                        theme="outlinedPrimary"
                                        onClick={handleActivateIntegration}
                                    >
                                        Activer
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div>
                            <Tabs
                                tabs={
                                    integration.about
                                        ? [
                                              "Description",
                                              "Configuration",
                                              "A propos",
                                          ]
                                        : ["Description", "Configuration"]
                                }
                                onClick={handleTabClick}
                                activeTab={activeTab}
                            />
                            <div className={styles.longDescription}>
                                {activeTab === "Description" && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: documentToHtmlString(
                                                integration.longDescription,
                                                {
                                                    preserveWhitespace: true,
                                                },
                                            ),
                                        }}
                                    />
                                )}
                                {activeTab === "Configuration" &&
                                    (integration.name === "bravas" &&
                                    activeIntegrationProviders ? (
                                        <BravasConfiguration
                                            fqdn={findConfigValueByName(
                                                integrationConfig,
                                                "fqdn",
                                            )}
                                            isActive={activeIntegrationProviders.includes(
                                                integration.name.toUpperCase(),
                                            )}
                                        />
                                    ) : (
                                        "lorem ipsum dolor sit amet, consectetur adipiscing el"
                                    ))}
                                {activeTab === "A propos" && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: documentToHtmlString(
                                                integration.about,
                                                {
                                                    preserveWhitespace: true,
                                                },
                                            ),
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SmallScreen />
        </>
    );
};

export default Integration;
