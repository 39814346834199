import React from "react";

import getIconColor from "./utils";

interface DesktopIconProps {
    isActive?: boolean;
}

export default function DesktopIcon(props: DesktopIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27 20"
            height="20"
            width="27"
        >
            <rect
                width="23.32"
                height="17.61"
                rx="1.75"
                ry="1.75"
                fill={getIconColor(props.isActive ?? false)}
            />
            <rect
                x="1.73"
                y="1.98"
                width="19.87"
                height="13.65"
                fill="#eae7e1"
            />
            <rect
                x="10.7"
                y="16.55"
                width="2.62"
                height="3.79"
                fill={getIconColor(props.isActive ?? false)}
            />
            <path
                d="m7.32,20.34c0-.7.57-1.27,1.27-1.27h6.85c.7,0,1.27.57,1.27,1.27H7.32Z"
                fill={getIconColor(props.isActive ?? false)}
            />
            <rect
                x="17.8"
                y="5.07"
                width="9.05"
                height="15.27"
                rx="1.75"
                ry="1.75"
                fill={getIconColor(props.isActive ?? false)}
            />
            <path
                d="m24.51,7.05c0-.15.06-.3.17-.41.11-.11.26-.17.41-.17s.3.06.41.17c.11.11.17.26.17.41s-.06.3-.17.41c-.11.11-.26.17-.41.17s-.3-.06-.41-.17c-.11-.11-.17-.26-.17-.41Z"
                fill="#eae7e1"
            />
        </svg>
    );
}

DesktopIcon.defaultProps = {
    isActive: false,
};
