export class Filters {
    storage: string[];

    os: string[];

    ram: string[];

    type: string[];

    processor: string[];

    screen: string[];

    budget: number[][];

    searchTerm: string;

    constructor(
        storage: string[],
        os: string[],
        ram: string[],
        type: string[],
        processor: string[],
        screen: string[],
        budget: number[][],
        searchTerm: string,
    ) {
        this.storage = storage;
        this.os = os;
        this.ram = ram;
        this.type = type;
        this.processor = processor;
        this.screen = screen;
        this.budget = budget;
        this.searchTerm = searchTerm;
    }
}

export enum FilterNames {
    storage = "storage",
    os = "os",
    ram = "ram",
    type = "type",
    processor = "processor",
    screen = "screen",
    budget = "budget",
    searchTerm = "searchTerm",
}

export enum SidebarFilters {
    storage = "storage",
    os = "os",
    ram = "ram",
    type = "type",
    processor = "processor",
    screen = "screen",
    budget = "budget",
}

export default Filters;
