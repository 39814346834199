import React from "react";

interface DeliveryIconProps {
    shippingDelay: number;
}

const DeliveryIcon = (props: DeliveryIconProps) => {
    const DEFAULT_SHIPPING_DELAY = 5;
    const LONG_SHIPPING_DELAY = 16;

    function getStyleFromShippingDelay(shippingDelay: number): string {
        if (shippingDelay <= DEFAULT_SHIPPING_DELAY) {
            return "#28A745";
        }
        if (
            shippingDelay > DEFAULT_SHIPPING_DELAY &&
            shippingDelay < LONG_SHIPPING_DELAY
        ) {
            return "#FD7E14";
        }
        return "#DC3545";
    }
    return (
        <svg
            width="30"
            height="19"
            viewBox="0 0 30 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 17.75C10.663 17.75 11.2989 17.4866 11.7678 17.0178C12.2366 16.5489 12.5 15.913 12.5 15.25C12.5 14.587 12.2366 13.9511 11.7678 13.4822C11.2989 13.0134 10.663 12.75 10 12.75C9.33696 12.75 8.70107 13.0134 8.23223 13.4822C7.76339 13.9511 7.5 14.587 7.5 15.25C7.5 15.913 7.76339 16.5489 8.23223 17.0178C8.70107 17.4866 9.33696 17.75 10 17.75ZM22.5 17.75C23.163 17.75 23.7989 17.4866 24.2678 17.0178C24.7366 16.5489 25 15.913 25 15.25C25 14.587 24.7366 13.9511 24.2678 13.4822C23.7989 13.0134 23.163 12.75 22.5 12.75C21.837 12.75 21.2011 13.0134 20.7322 13.4822C20.2634 13.9511 20 14.587 20 15.25C20 15.913 20.2634 16.5489 20.7322 17.0178C21.2011 17.4866 21.837 17.75 22.5 17.75Z"
                stroke={getStyleFromShippingDelay(props.shippingDelay)}
                strokeWidth="1.875"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5625 15.25H18.75V2.25C18.75 2.05109 18.671 1.86032 18.5303 1.71967C18.3897 1.57902 18.1989 1.5 18 1.5H1.25M7.0625 15.25H4.5C4.40151 15.25 4.30398 15.2306 4.21299 15.1929C4.12199 15.1552 4.03931 15.1 3.96967 15.0303C3.90003 14.9607 3.84478 14.878 3.80709 14.787C3.7694 14.696 3.75 14.5985 3.75 14.5V8.375"
                stroke={getStyleFromShippingDelay(props.shippingDelay)}
                strokeWidth="1.875"
                strokeLinecap="round"
            />
            <path
                d="M2.5 5.25H7.5"
                stroke={getStyleFromShippingDelay(props.shippingDelay)}
                strokeWidth="1.875"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.75 5.25H25.7625C25.9075 5.25004 26.0493 5.29209 26.1709 5.37106C26.2925 5.45004 26.3885 5.56256 26.4475 5.695L28.685 10.73C28.7276 10.8256 28.7498 10.9291 28.75 11.0337V14.5C28.75 14.5985 28.7306 14.696 28.6929 14.787C28.6552 14.878 28.6 14.9607 28.5303 15.0303C28.4607 15.1 28.378 15.1552 28.287 15.1929C28.196 15.2306 28.0985 15.25 28 15.25H25.625M18.75 15.25H20"
                stroke={getStyleFromShippingDelay(props.shippingDelay)}
                strokeWidth="1.875"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default DeliveryIcon;
