import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import "./product.scss";
import "./skeleton-catalog.scss";

const SkeletonCatalogItem = () => (
    <div className="skeleton-wrapper">
        <Skeleton className="skeleton-image" />
        <div className="skeleton-product">
            <div className="skeleton-header">
                <Skeleton
                    className="skeleton-title"
                    width={190}
                />
            </div>
            <div className="product-details-price price">
                <Skeleton
                    height={19}
                    width={120}
                />
            </div>
            <div className="skeleton-details">
                <div className="product-details-specs">
                    <div className="product-details-spec">
                        <Skeleton
                            height={19}
                            width={70}
                            className="skeleton-specs"
                        />
                        <Skeleton
                            height={19}
                            width={70}
                            className="skeleton-specs"
                        />
                    </div>
                    <div className="product-details-spec">
                        <Skeleton
                            height={19}
                            width={70}
                            className="skeleton-specs"
                        />
                        <Skeleton
                            height={19}
                            width={70}
                            className="skeleton-specs"
                        />
                    </div>
                    <div className="product-details-spec">
                        <Skeleton
                            height={19}
                            width={70}
                            className="skeleton-specs"
                        />
                        <Skeleton
                            height={19}
                            width={70}
                            className="skeleton-specs"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default SkeletonCatalogItem;
