import CURRENT_STORAGE_VERSION from "components/business/catalog/cart/globalVars";
import AddProduct from "components/business/catalog/utils/add-product";
import Product from "components/business/order/product/product";

import CartAction from "./cart-action";

class AddProductAction implements CartAction {
    payload: {
        addProduct: AddProduct[];
    };

    constructor(addProduct: AddProduct[]) {
        this.payload = {
            addProduct,
        };
    }

    apply(cart: Product[]) {
        const { addProduct } = this.payload;
        const additionalProducts: Product[] = [];

        addProduct.forEach((product) => {
            const pricingInfo = product.buildPricingInfo();

            for (let i = 0; i < product.quantity; i += 1) {
                const newProduct = new Product(
                    product.variant,
                    pricingInfo.negotiated,
                    pricingInfo.price,
                    product.leasingDuration,
                );
                additionalProducts.push(newProduct);
            }
        });

        const updatedCart = [...cart, ...additionalProducts];
        localStorage.setItem(
            CURRENT_STORAGE_VERSION,
            JSON.stringify(updatedCart),
        );
        return updatedCart;
    }
}

export default AddProductAction;
