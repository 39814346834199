/* eslint-disable */
// This file was automatically generated and should not be edited.
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends " $fragmentName" | "__typename"
              ? T[P]
              : never;
      };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    _FieldSet: { input: any; output: any };
};

export type CommonSpecsOutput = {
    __typename: "CommonSpecsOutput";
    deviceType: ProductType;
    imageUrl: Scalars["String"]["output"];
    variantId: Scalars["String"]["output"];
};

export type ContractOutput = {
    __typename: "ContractOutput";
    crmId: Scalars["String"]["output"];
    signed: Scalars["Boolean"]["output"];
    signers: Array<Scalars["String"]["output"]>;
    url: Scalars["String"]["output"];
};

export type CustomerInput = {
    affectedCatalogs?: InputMaybe<Array<Scalars["String"]["input"]>>;
    companyCrmId?: InputMaybe<Scalars["String"]["input"]>;
    companyId: Scalars["String"]["input"];
    companyName: Scalars["String"]["input"];
    userEmail: Scalars["String"]["input"];
    userName: Scalars["String"]["input"];
};

export type CustomerOutput = {
    __typename: "CustomerOutput";
    companyId: Scalars["String"]["output"];
    companyName: Scalars["String"]["output"];
    userEmail: Scalars["String"]["output"];
    userName: Scalars["String"]["output"];
};

export type DeliveryDateInput = {
    date: Scalars["String"]["input"];
    deliveryType: DeliveryTypeInput;
};

export enum DeliveryTypeInput {
    SCHEDULED = "SCHEDULED",
    SOONEST = "SOONEST",
}

export enum LeasingDurationInput {
    MONTHS_24 = "MONTHS_24",
    MONTHS_36 = "MONTHS_36",
    MONTHS_48 = "MONTHS_48",
}

export type Mutation = {
    __typename: "Mutation";
    createOrder: Maybe<OrderOutput>;
};

export type MutationcreateOrderArgs = {
    orderInput: OrderInput;
};

export enum OrderByDate {
    ASC = "ASC",
    DESC = "DESC",
}

export type OrderInput = {
    client: CustomerInput;
    products: Array<ProductInput>;
};

export type OrderOutput = {
    __typename: "OrderOutput";
    client: CustomerOutput;
    contract: Maybe<ContractOutput>;
    createdAt: Scalars["String"]["output"];
    id: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    priceSum: Scalars["Float"]["output"];
    status: OrderStatus;
    uniqueShippingDates: Array<UniqueShippingDateOutput>;
};

export enum OrderStatus {
    BILLED = "BILLED",
    IN_PROGRESS = "IN_PROGRESS",
    PENDING_BILLING = "PENDING_BILLING",
    PENDING_DELIVERY = "PENDING_DELIVERY",
    PENDING_SIGNATURE = "PENDING_SIGNATURE",
    PENDING_VALIDATION = "PENDING_VALIDATION",
    REJECTED = "REJECTED",
}

export type OrderSummaryOutput = {
    __typename: "OrderSummaryOutput";
    createdAt: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    priceSum: Scalars["Float"]["output"];
    subOrders: Array<SubOrderOutput>;
};

export type ProductAddressInput = {
    cityName: Scalars["String"]["input"];
    country: Scalars["String"]["input"];
    id: Scalars["String"]["input"];
    instructions?: InputMaybe<Scalars["String"]["input"]>;
    label: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    phoneNumber: Scalars["String"]["input"];
    recipient?: InputMaybe<Scalars["String"]["input"]>;
    zipcode: Scalars["String"]["input"];
};

export type ProductAddressOutput = {
    __typename: "ProductAddressOutput";
    cityName: Scalars["String"]["output"];
    country: Scalars["String"]["output"];
    id: Scalars["String"]["output"];
    instructions: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    phoneNumber: Scalars["String"]["output"];
    recipient: Scalars["String"]["output"];
    zipcode: Scalars["String"]["output"];
};

export type ProductAudioSpecsOutput = {
    __typename: "ProductAudioSpecsOutput";
    connectivity: Array<Scalars["String"]["output"]>;
    noiseCancellation: Scalars["Boolean"]["output"];
    type: Scalars["String"]["output"];
};

export enum ProductDeliveryStatusOutput {
    BILLING = "BILLING",
    DELIVERED = "DELIVERED",
    ORDERED = "ORDERED",
    RECEIVED = "RECEIVED",
    SENT = "SENT",
    TO_ORDER = "TO_ORDER",
}

export type ProductDeviceSpecsOutput = {
    __typename: "ProductDeviceSpecsOutput";
    deviceOs: ProductOs;
    memorySize: Scalars["String"]["output"];
    processor: Scalars["String"]["output"];
    screenSize: Scalars["String"]["output"];
    storageSize: Scalars["String"]["output"];
};

export type ProductDockstationSpecsOutput = {
    __typename: "ProductDockstationSpecsOutput";
    compatibility: Scalars["String"]["output"];
    connection: Scalars["String"]["output"];
    connectivity: Array<Scalars["String"]["output"]>;
};

export type ProductInput = {
    address: ProductAddressInput;
    deliveryDate: DeliveryDateInput;
    instructions?: InputMaybe<Scalars["String"]["input"]>;
    leasingDuration: LeasingDurationInput;
    reference: Scalars["String"]["input"];
    user?: InputMaybe<ProductUserInput>;
    variantId: Scalars["String"]["input"];
};

export type ProductKeyboardSpecsOutput = {
    __typename: "ProductKeyboardSpecsOutput";
    compatibility: Scalars["String"]["output"];
    connectivity: Array<Scalars["String"]["output"]>;
    numPad: Scalars["Boolean"]["output"];
};

export type ProductLeasingDataOutput = {
    __typename: "ProductLeasingDataOutput";
    filesCode: Maybe<Scalars["String"]["output"]>;
    leasingMonthsDuration: Scalars["Int"]["output"];
    leasingPrice: Scalars["Float"]["output"];
    purchasePrice: Scalars["Float"]["output"];
};

export type ProductMouseSpecsOutput = {
    __typename: "ProductMouseSpecsOutput";
    compatibility: Scalars["String"]["output"];
    connectivity: Array<Scalars["String"]["output"]>;
    power: Scalars["String"]["output"];
    type: Scalars["String"]["output"];
};

export enum ProductOs {
    ANDROID = "ANDROID",
    CHROME = "CHROME",
    IOS = "IOS",
    LINUX = "LINUX",
    MAC = "MAC",
    OTHER = "OTHER",
    WINDOWS = "WINDOWS",
}

export type ProductOutput = {
    __typename: "ProductOutput";
    address: ProductAddressOutput;
    commonSpecs: CommonSpecsOutput;
    deliveryStatus: Maybe<ProductDeliveryStatusOutput>;
    id: Scalars["String"]["output"];
    instructions: Maybe<Scalars["String"]["output"]>;
    leasingData: ProductLeasingDataOutput;
    reference: Scalars["String"]["output"];
    serialNumber: Maybe<Scalars["String"]["output"]>;
    specs: ProductSpecsOutput;
    trackingUrl: Maybe<Scalars["String"]["output"]>;
    user: Maybe<ProductUserOutput>;
};

export type ProductScreenSpecsOutput = {
    __typename: "ProductScreenSpecsOutput";
    connectivity: Array<Scalars["String"]["output"]>;
    integratedCamera: Scalars["Boolean"]["output"];
    refreshRate: Scalars["Int"]["output"];
    resolution: Scalars["String"]["output"];
    size: Scalars["Float"]["output"];
};

export type ProductSpecsOutput =
    | ProductAudioSpecsOutput
    | ProductDeviceSpecsOutput
    | ProductDockstationSpecsOutput
    | ProductKeyboardSpecsOutput
    | ProductMouseSpecsOutput
    | ProductScreenSpecsOutput;

export enum ProductType {
    AUDIO = "AUDIO",
    DESKTOP = "DESKTOP",
    DOCKSTATION = "DOCKSTATION",
    KEYBOARD = "KEYBOARD",
    LAPTOP = "LAPTOP",
    MOUSE = "MOUSE",
    OTHER = "OTHER",
    PHONE = "PHONE",
    SCREEN = "SCREEN",
    SMARTPHONE = "SMARTPHONE",
    TABLET = "TABLET",
}

export type ProductUserInput = {
    email: Scalars["String"]["input"];
    firstName: Scalars["String"]["input"];
    lastName: Scalars["String"]["input"];
};

export type ProductUserOutput = {
    __typename: "ProductUserOutput";
    email: Scalars["String"]["output"];
    firstName: Scalars["String"]["output"];
    lastName: Scalars["String"]["output"];
};

export type Query = {
    __typename: "Query";
    _service: Maybe<_Service>;
    findOrder: Maybe<OrderOutput>;
    findOrders: Maybe<Array<OrderSummaryOutput>>;
};

export type QueryfindOrderArgs = {
    crmId: Scalars["String"]["input"];
};

export type QueryfindOrdersArgs = {
    orderByDate?: InputMaybe<OrderByDate>;
    orderStatus?: InputMaybe<OrderStatus>;
};

export type SubOrderOutput = {
    __typename: "SubOrderOutput";
    contract: Maybe<ContractOutput>;
    crmId: Scalars["String"]["output"];
    priceSum: Scalars["Float"]["output"];
    productCount: Scalars["Int"]["output"];
    status: OrderStatus;
    uniqueAddresses: Array<Scalars["String"]["output"]>;
    uniqueReferences: Array<Scalars["String"]["output"]>;
};

export type UniqueAddressOutput = {
    __typename: "UniqueAddressOutput";
    address: ProductAddressOutput;
    products: Array<ProductOutput>;
};

export type UniqueShippingDateOutput = {
    __typename: "UniqueShippingDateOutput";
    shippingDate: Maybe<Scalars["String"]["output"]>;
    uniqueAddresses: Array<UniqueAddressOutput>;
};

export enum UserRole {
    ADMIN = "ADMIN",
    OWNER = "OWNER",
    PUSH_FILES = "PUSH_FILES",
    USER = "USER",
}

export type _Service = {
    __typename: "_Service";
    sdl: Scalars["String"]["output"];
};

export type CreateOrderMutationVariables = Exact<{
    client: CustomerInput;
    products: Array<ProductInput> | ProductInput;
}>;

export type CreateOrderMutation = {
    createOrder: {
        __typename: "OrderOutput";
        createdAt: string;
        status: OrderStatus;
    } | null;
};

export type ContractFragment = {
    __typename: "ContractOutput";
    signed: boolean;
    url: string;
};

export type SubOrderFragment = {
    __typename: "SubOrderOutput";
    crmId: string;
    uniqueAddresses: Array<string>;
    productCount: number;
    uniqueReferences: Array<string>;
    status: OrderStatus;
    priceSum: number;
    contract: {
        __typename: "ContractOutput";
        signed: boolean;
        url: string;
    } | null;
};

export type OrderSummaryFragment = {
    __typename: "OrderSummaryOutput";
    name: string;
    priceSum: number;
    createdAt: string;
    subOrders: Array<{
        __typename: "SubOrderOutput";
        crmId: string;
        uniqueAddresses: Array<string>;
        productCount: number;
        uniqueReferences: Array<string>;
        status: OrderStatus;
        priceSum: number;
        contract: {
            __typename: "ContractOutput";
            signed: boolean;
            url: string;
        } | null;
    }>;
};

export type FindOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type FindOrdersQuery = {
    findOrders: Array<{
        __typename: "OrderSummaryOutput";
        name: string;
        priceSum: number;
        createdAt: string;
        subOrders: Array<{
            __typename: "SubOrderOutput";
            crmId: string;
            uniqueAddresses: Array<string>;
            productCount: number;
            uniqueReferences: Array<string>;
            status: OrderStatus;
            priceSum: number;
            contract: {
                __typename: "ContractOutput";
                signed: boolean;
                url: string;
            } | null;
        }>;
    }> | null;
};

export type ProductAddressFragment = {
    __typename: "ProductAddressOutput";
    id: string;
    name: string;
    recipient: string;
    label: string;
    zipcode: string;
    cityName: string;
    country: string;
};

export type ProductUserFragment = {
    __typename: "ProductUserOutput";
    firstName: string;
    lastName: string;
    email: string;
};

export type ProductDeviceSpecFragment = {
    __typename: "ProductDeviceSpecsOutput";
    memorySize: string;
    processor: string;
    storageSize: string;
};

export type ProductAudioSpecFragment = {
    __typename: "ProductAudioSpecsOutput";
    type: string;
    noiseCancellation: boolean;
    connectivity: Array<string>;
};

export type ProductKeyboardSpecFragment = {
    __typename: "ProductKeyboardSpecsOutput";
    compatibility: string;
    numPad: boolean;
    connectivity: Array<string>;
};

export type ProductMouseSpecFragment = {
    __typename: "ProductMouseSpecsOutput";
    type: string;
    power: string;
    compatibility: string;
    connectivity: Array<string>;
};

export type ProductScreenSpecFragment = {
    __typename: "ProductScreenSpecsOutput";
    size: number;
    resolution: string;
    refreshRate: number;
    integratedCamera: boolean;
    connectivity: Array<string>;
};

export type ProductDockstationSpecFragment = {
    __typename: "ProductDockstationSpecsOutput";
    compatibility: string;
    connection: string;
    connectivity: Array<string>;
};

export type UniqueAddressFragment = {
    __typename: "UniqueAddressOutput";
    address: {
        __typename: "ProductAddressOutput";
        id: string;
        name: string;
        recipient: string;
        label: string;
        zipcode: string;
        cityName: string;
        country: string;
    };
    products: Array<{
        __typename: "ProductOutput";
        id: string;
        reference: string;
        serialNumber: string | null;
        trackingUrl: string | null;
        deliveryStatus: ProductDeliveryStatusOutput | null;
        user: {
            __typename: "ProductUserOutput";
            firstName: string;
            lastName: string;
            email: string;
        } | null;
        commonSpecs: {
            __typename: "CommonSpecsOutput";
            deviceType: ProductType;
            imageUrl: string;
        };
        specs:
            | {
                  __typename: "ProductAudioSpecsOutput";
                  type: string;
                  noiseCancellation: boolean;
                  connectivity: Array<string>;
              }
            | {
                  __typename: "ProductDeviceSpecsOutput";
                  memorySize: string;
                  processor: string;
                  storageSize: string;
              }
            | {
                  __typename: "ProductDockstationSpecsOutput";
                  compatibility: string;
                  connection: string;
                  connectivity: Array<string>;
              }
            | {
                  __typename: "ProductKeyboardSpecsOutput";
                  compatibility: string;
                  numPad: boolean;
                  connectivity: Array<string>;
              }
            | {
                  __typename: "ProductMouseSpecsOutput";
                  type: string;
                  power: string;
                  compatibility: string;
                  connectivity: Array<string>;
              }
            | {
                  __typename: "ProductScreenSpecsOutput";
                  size: number;
                  resolution: string;
                  refreshRate: number;
                  integratedCamera: boolean;
                  connectivity: Array<string>;
              };
        leasingData: {
            __typename: "ProductLeasingDataOutput";
            leasingMonthsDuration: number;
            leasingPrice: number;
        };
    }>;
};

export type UniqueShippingDateFragment = {
    __typename: "UniqueShippingDateOutput";
    shippingDate: string | null;
    uniqueAddresses: Array<{
        __typename: "UniqueAddressOutput";
        address: {
            __typename: "ProductAddressOutput";
            id: string;
            name: string;
            recipient: string;
            label: string;
            zipcode: string;
            cityName: string;
            country: string;
        };
        products: Array<{
            __typename: "ProductOutput";
            id: string;
            reference: string;
            serialNumber: string | null;
            trackingUrl: string | null;
            deliveryStatus: ProductDeliveryStatusOutput | null;
            user: {
                __typename: "ProductUserOutput";
                firstName: string;
                lastName: string;
                email: string;
            } | null;
            commonSpecs: {
                __typename: "CommonSpecsOutput";
                deviceType: ProductType;
                imageUrl: string;
            };
            specs:
                | {
                      __typename: "ProductAudioSpecsOutput";
                      type: string;
                      noiseCancellation: boolean;
                      connectivity: Array<string>;
                  }
                | {
                      __typename: "ProductDeviceSpecsOutput";
                      memorySize: string;
                      processor: string;
                      storageSize: string;
                  }
                | {
                      __typename: "ProductDockstationSpecsOutput";
                      compatibility: string;
                      connection: string;
                      connectivity: Array<string>;
                  }
                | {
                      __typename: "ProductKeyboardSpecsOutput";
                      compatibility: string;
                      numPad: boolean;
                      connectivity: Array<string>;
                  }
                | {
                      __typename: "ProductMouseSpecsOutput";
                      type: string;
                      power: string;
                      compatibility: string;
                      connectivity: Array<string>;
                  }
                | {
                      __typename: "ProductScreenSpecsOutput";
                      size: number;
                      resolution: string;
                      refreshRate: number;
                      integratedCamera: boolean;
                      connectivity: Array<string>;
                  };
            leasingData: {
                __typename: "ProductLeasingDataOutput";
                leasingMonthsDuration: number;
                leasingPrice: number;
            };
        }>;
    }>;
};

export type FindOrderQueryVariables = Exact<{
    crmId: Scalars["String"]["input"];
}>;

export type FindOrderQuery = {
    findOrder: {
        __typename: "OrderOutput";
        id: string;
        priceSum: number;
        name: string;
        createdAt: string;
        status: OrderStatus;
        client: {
            __typename: "CustomerOutput";
            userEmail: string;
            userName: string;
        };
        uniqueShippingDates: Array<{
            __typename: "UniqueShippingDateOutput";
            shippingDate: string | null;
            uniqueAddresses: Array<{
                __typename: "UniqueAddressOutput";
                address: {
                    __typename: "ProductAddressOutput";
                    id: string;
                    name: string;
                    recipient: string;
                    label: string;
                    zipcode: string;
                    cityName: string;
                    country: string;
                };
                products: Array<{
                    __typename: "ProductOutput";
                    id: string;
                    reference: string;
                    serialNumber: string | null;
                    trackingUrl: string | null;
                    deliveryStatus: ProductDeliveryStatusOutput | null;
                    user: {
                        __typename: "ProductUserOutput";
                        firstName: string;
                        lastName: string;
                        email: string;
                    } | null;
                    commonSpecs: {
                        __typename: "CommonSpecsOutput";
                        deviceType: ProductType;
                        imageUrl: string;
                    };
                    specs:
                        | {
                              __typename: "ProductAudioSpecsOutput";
                              type: string;
                              noiseCancellation: boolean;
                              connectivity: Array<string>;
                          }
                        | {
                              __typename: "ProductDeviceSpecsOutput";
                              memorySize: string;
                              processor: string;
                              storageSize: string;
                          }
                        | {
                              __typename: "ProductDockstationSpecsOutput";
                              compatibility: string;
                              connection: string;
                              connectivity: Array<string>;
                          }
                        | {
                              __typename: "ProductKeyboardSpecsOutput";
                              compatibility: string;
                              numPad: boolean;
                              connectivity: Array<string>;
                          }
                        | {
                              __typename: "ProductMouseSpecsOutput";
                              type: string;
                              power: string;
                              compatibility: string;
                              connectivity: Array<string>;
                          }
                        | {
                              __typename: "ProductScreenSpecsOutput";
                              size: number;
                              resolution: string;
                              refreshRate: number;
                              integratedCamera: boolean;
                              connectivity: Array<string>;
                          };
                    leasingData: {
                        __typename: "ProductLeasingDataOutput";
                        leasingMonthsDuration: number;
                        leasingPrice: number;
                    };
                }>;
            }>;
        }>;
        contract: {
            __typename: "ContractOutput";
            signed: boolean;
            url: string;
        } | null;
    } | null;
};

export const ContractFragmentDoc = gql`
    fragment Contract on ContractOutput {
        signed
        url
    }
`;
export const SubOrderFragmentDoc = gql`
    fragment SubOrder on SubOrderOutput {
        crmId
        uniqueAddresses
        productCount
        uniqueReferences
        status
        priceSum
        contract {
            ...Contract
        }
    }
    ${ContractFragmentDoc}
`;
export const OrderSummaryFragmentDoc = gql`
    fragment OrderSummary on OrderSummaryOutput {
        name
        priceSum
        createdAt
        subOrders {
            ...SubOrder
        }
    }
    ${SubOrderFragmentDoc}
`;
export const ProductAddressFragmentDoc = gql`
    fragment ProductAddress on ProductAddressOutput {
        id
        name
        recipient
        label
        zipcode
        cityName
        country
    }
`;
export const ProductUserFragmentDoc = gql`
    fragment ProductUser on ProductUserOutput {
        firstName
        lastName
        email
    }
`;
export const ProductDeviceSpecFragmentDoc = gql`
    fragment ProductDeviceSpec on ProductDeviceSpecsOutput {
        memorySize
        processor
        storageSize
    }
`;
export const ProductAudioSpecFragmentDoc = gql`
    fragment ProductAudioSpec on ProductAudioSpecsOutput {
        type
        noiseCancellation
        connectivity
    }
`;
export const ProductDockstationSpecFragmentDoc = gql`
    fragment ProductDockstationSpec on ProductDockstationSpecsOutput {
        compatibility
        connection
        connectivity
    }
`;
export const ProductKeyboardSpecFragmentDoc = gql`
    fragment ProductKeyboardSpec on ProductKeyboardSpecsOutput {
        compatibility
        numPad
        connectivity
    }
`;
export const ProductMouseSpecFragmentDoc = gql`
    fragment ProductMouseSpec on ProductMouseSpecsOutput {
        type
        power
        compatibility
        connectivity
    }
`;
export const ProductScreenSpecFragmentDoc = gql`
    fragment ProductScreenSpec on ProductScreenSpecsOutput {
        size
        resolution
        refreshRate
        integratedCamera
        connectivity
    }
`;
export const UniqueAddressFragmentDoc = gql`
    fragment UniqueAddress on UniqueAddressOutput {
        address {
            ...ProductAddress
        }
        products {
            id
            reference
            user {
                ...ProductUser
            }
            serialNumber
            trackingUrl
            commonSpecs {
                deviceType
                imageUrl
            }
            specs {
                __typename
                ... on ProductDeviceSpecsOutput {
                    ...ProductDeviceSpec
                }
                ... on ProductAudioSpecsOutput {
                    ...ProductAudioSpec
                }
                ... on ProductDockstationSpecsOutput {
                    ...ProductDockstationSpec
                }
                ... on ProductKeyboardSpecsOutput {
                    ...ProductKeyboardSpec
                }
                ... on ProductMouseSpecsOutput {
                    ...ProductMouseSpec
                }
                ... on ProductScreenSpecsOutput {
                    ...ProductScreenSpec
                }
            }
            leasingData {
                leasingMonthsDuration
                leasingPrice
            }
            deliveryStatus
        }
    }
    ${ProductAddressFragmentDoc}
    ${ProductUserFragmentDoc}
    ${ProductDeviceSpecFragmentDoc}
    ${ProductAudioSpecFragmentDoc}
    ${ProductDockstationSpecFragmentDoc}
    ${ProductKeyboardSpecFragmentDoc}
    ${ProductMouseSpecFragmentDoc}
    ${ProductScreenSpecFragmentDoc}
`;
export const UniqueShippingDateFragmentDoc = gql`
    fragment UniqueShippingDate on UniqueShippingDateOutput {
        shippingDate
        uniqueAddresses {
            ...UniqueAddress
        }
    }
    ${UniqueAddressFragmentDoc}
`;
export const CreateOrderDocument = gql`
    mutation CreateOrder($client: CustomerInput!, $products: [ProductInput!]!) {
        createOrder(orderInput: { client: $client, products: $products }) {
            createdAt
            status
        }
    }
`;
export type CreateOrderMutationFn = Apollo.MutationFunction<
    CreateOrderMutation,
    CreateOrderMutationVariables
>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      client: // value for 'client'
 *      products: // value for 'products'
 *   },
 * });
 */
export function useCreateOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateOrderMutation,
        CreateOrderMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateOrderMutation,
        CreateOrderMutationVariables
    >(CreateOrderDocument, options);
}
export type CreateOrderMutationHookResult = ReturnType<
    typeof useCreateOrderMutation
>;
export type CreateOrderMutationResult =
    Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<
    CreateOrderMutation,
    CreateOrderMutationVariables
>;
export const FindOrdersDocument = gql`
    query FindOrders {
        findOrders {
            ...OrderSummary
        }
    }
    ${OrderSummaryFragmentDoc}
`;

/**
 * __useFindOrdersQuery__
 *
 * To run a query within a React component, call `useFindOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindOrdersQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindOrdersQuery,
        FindOrdersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindOrdersQuery, FindOrdersQueryVariables>(
        FindOrdersDocument,
        options,
    );
}
export function useFindOrdersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindOrdersQuery,
        FindOrdersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindOrdersQuery, FindOrdersQueryVariables>(
        FindOrdersDocument,
        options,
    );
}
export type FindOrdersQueryHookResult = ReturnType<typeof useFindOrdersQuery>;
export type FindOrdersLazyQueryHookResult = ReturnType<
    typeof useFindOrdersLazyQuery
>;
export type FindOrdersQueryResult = Apollo.QueryResult<
    FindOrdersQuery,
    FindOrdersQueryVariables
>;
export function refetchFindOrdersQuery(variables?: FindOrdersQueryVariables) {
    return { query: FindOrdersDocument, variables: variables };
}
export const FindOrderDocument = gql`
    query FindOrder($crmId: String!) {
        findOrder(crmId: $crmId) {
            id
            priceSum
            name
            createdAt
            client {
                userEmail
                userName
            }
            status
            uniqueShippingDates {
                ...UniqueShippingDate
            }
            contract {
                ...Contract
            }
        }
    }
    ${UniqueShippingDateFragmentDoc}
    ${ContractFragmentDoc}
`;

/**
 * __useFindOrderQuery__
 *
 * To run a query within a React component, call `useFindOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrderQuery({
 *   variables: {
 *      crmId: // value for 'crmId'
 *   },
 * });
 */
export function useFindOrderQuery(
    baseOptions: Apollo.QueryHookOptions<
        FindOrderQuery,
        FindOrderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindOrderQuery, FindOrderQueryVariables>(
        FindOrderDocument,
        options,
    );
}
export function useFindOrderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindOrderQuery,
        FindOrderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindOrderQuery, FindOrderQueryVariables>(
        FindOrderDocument,
        options,
    );
}
export type FindOrderQueryHookResult = ReturnType<typeof useFindOrderQuery>;
export type FindOrderLazyQueryHookResult = ReturnType<
    typeof useFindOrderLazyQuery
>;
export type FindOrderQueryResult = Apollo.QueryResult<
    FindOrderQuery,
    FindOrderQueryVariables
>;
export function refetchFindOrderQuery(variables: FindOrderQueryVariables) {
    return { query: FindOrderDocument, variables: variables };
}
