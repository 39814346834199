import React from "react";

import { TabContent, TabPane } from "reactstrap";

const TagSidePanel = (props: any) => (
    <div
        className="nlt-wide-card-right-panel"
        style={{ width: "50%" }}
    >
        <div>
            <TabContent>
                <TabPane>{props.children}</TabPane>
            </TabContent>
        </div>
    </div>
);

export default TagSidePanel;
