interface InstructionEditIconProps {
    isFilled?: boolean;
}

export default function InstructionEditIcon(props: InstructionEditIconProps) {
    return props.isFilled ? (
        <svg
            width="23"
            height="18"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.6483 2.67996H2.97056C2.08691 2.67996 1.37056 3.39631 1.37056 4.27996V16.0589C1.37056 16.9426 2.0869 17.6589 2.97056 17.6589H15.017C15.9007 17.6589 16.617 16.9426 16.617 16.0589V12.6659"
                stroke="#FF625A"
                strokeWidth="2.03"
                strokeLinecap="round"
            />
            <path
                d="M4.76067 6.63345L11.1819 6.63345"
                stroke="#FF625A"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <line
                x1="5.05217"
                y1="10.3771"
                x2="8.13876"
                y2="10.3771"
                stroke="#FF625A"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M16.9535 6.11414L11.0255 13.3539L10.9348 13.3737L10.9365 13.2677L16.8584 6.03632L16.9535 6.11414ZM19.178 3.20374L19.6284 2.65381L19.7234 2.73157L19.273 3.28151L19.178 3.20374Z"
                stroke="#FF625A"
                strokeWidth="2.80145"
            />
        </svg>
    ) : (
        <svg
            width="23"
            height="18"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.3237 2.67996H2.64595C1.76229 2.67996 1.04594 3.39631 1.04594 4.27996V16.0589C1.04594 16.9426 1.76229 17.6589 2.64594 17.6589H14.6924C15.5761 17.6589 16.2924 16.9426 16.2924 16.0589V12.6659"
                stroke="#353535"
                strokeWidth="2.03"
                strokeLinecap="round"
            />
            <path
                d="M4.43605 6.63345L10.8573 6.63345"
                stroke="#353535"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <line
                x1="4.72755"
                y1="10.3771"
                x2="7.81414"
                y2="10.3771"
                stroke="#353535"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M16.6289 6.11414L10.7009 13.3539L10.6102 13.3737L10.6119 13.2677L16.5338 6.03632L16.6289 6.11414ZM18.8534 3.20374L19.3037 2.65381L19.3988 2.73157L18.9484 3.28151L18.8534 3.20374Z"
                stroke="#353535"
                strokeWidth="2.80145"
            />
        </svg>
    );
}

InstructionEditIcon.defaultProps = {
    isFilled: false,
};
