/* eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }] */
import React from "react";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import { AccessoryType } from "components/business/catalog/utils/types";
import { ProductFragment } from "graphql/catalog/model/apollo";

import "./Details.scss";

const DetailsDescription = (props: { description: any }) => (
    // https://github.com/jsx-eslint/eslint-plugin-react/blob/3c11201f5bf0954b822986105ef17c922acf6036/docs/rules/no-danger.md#when-not-to-use-it
    // If you are certain the content passed to dangerouslySetInnerHTML is sanitized HTML you can disable this rule.
    // eslint-disable-next-line react/no-danger
    <div
        dangerouslySetInnerHTML={{
            __html: documentToHtmlString(props.description?.json, {
                preserveWhitespace: true,
            }),
        }}
    />
);

interface DetailProps {
    product: ProductFragment | AccessoryType;
    onClose: () => void;
}

const Details = (props: DetailProps) => (
    <div className="pop-up-wrapper">
        <div className="product-catalog-specs">
            <>
                <h3>Spécifications Techniques</h3>
                <button
                    type="button"
                    className="nlt-button-inside-close absolute-close"
                    onClick={() => {
                        props.onClose();
                    }}
                >
                    <i className="icofont icofont-close" />
                </button>
            </>
            {props.product.__typename === "Product" ? (
                <div className="long-description">
                    <h4>Caractéristiques</h4>
                    <ul className="specs">
                        {props.product.screenSizeValue && (
                            <li className="specs-list">
                                <div
                                    className="spec-key"
                                    data-cy="product-screen-size-label"
                                >
                                    Taille d'écran :
                                </div>
                                <div
                                    className="spec-value"
                                    data-cy="product-screen-size-value"
                                >
                                    {`${props.product.screenSizeValue} pouces`}
                                </div>
                            </li>
                        )}
                        {props.product.height && (
                            <li className="specs-list">
                                <div
                                    className="spec-key"
                                    data-cy="product-height-label"
                                >
                                    Hauteur :
                                </div>
                                <div
                                    className="spec-value"
                                    data-cy="product-height-value"
                                >
                                    {`${props.product.height}mm`}
                                </div>
                            </li>
                        )}
                        {props.product.width && (
                            <li className="specs-list">
                                <div
                                    className="spec-key"
                                    data-cy="product-width-label"
                                >
                                    Largeur :
                                </div>
                                <div
                                    className="spec-value"
                                    data-cy="product-width-value"
                                >
                                    {`${props.product.width}mm`}
                                </div>
                            </li>
                        )}
                        {props.product?.thickness && (
                            <li className="specs-list">
                                <div
                                    className="spec-key"
                                    data-cy="product-thickness-label"
                                >
                                    Epaisseur :
                                </div>
                                <div
                                    className="spec-value"
                                    data-cy="product-thickness-value"
                                >
                                    {`${props.product.thickness}mm`}
                                </div>
                            </li>
                        )}
                        {props.product?.weight && (
                            <li className="specs-list">
                                <div
                                    className="spec-key"
                                    data-cy="product-weight-label"
                                >
                                    Poids :
                                </div>
                                <div
                                    className="spec-value"
                                    data-cy="product-weight-value"
                                >
                                    {`${props.product.weight}g`}
                                </div>
                            </li>
                        )}
                    </ul>
                    <DetailsDescription
                        description={props.product.longDescription}
                    />
                </div>
            ) : (
                <div className="long-description">
                    <DetailsDescription
                        description={props.product.description}
                    />
                </div>
            )}
        </div>
    </div>
);

export default Details;
