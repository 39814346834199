/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { usePresignDownloadLazyQuery } from "graphql/management/model/apollo";

interface deviceDisplayFileInterface {
    label: string;
    filePath: string;
    fileName: string;
}

const DeviceDisplayFile = (props: deviceDisplayFileInterface) => {
    const [presignDownload] = usePresignDownloadLazyQuery({
        fetchPolicy: "network-only",
        onCompleted: (link) => window.open(link.presignDownload, "_blank"),
    });

    function downloadFile(filePathParam: string) {
        return presignDownload({
            variables: { filePath: filePathParam },
        });
    }

    return (
        <>
            <li className="nlt-item-info">
                <div className="nlt-item-info-label">{props.label}</div>
                <span className="nlt-item-info-data">
                    <a
                        aria-hidden="true"
                        className="file-click"
                        onClick={() => downloadFile(props.filePath!)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fa fa-file-text-o txt-primary" />{" "}
                        {props.fileName}
                    </a>
                </span>
            </li>
        </>
    );
};

export default DeviceDisplayFile;
