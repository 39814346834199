/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from "react";

import { useKeycloak } from "@react-keycloak/web";
import { UseFormReset } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { UserFormData } from "components/business/user/types";
import {
    refetchComputeDashboardDataQuery,
    refetchFindAdminQuery,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    useDeleteUserMutation,
    useFindAdminQuery,
    UserFragment,
    UserRole,
} from "graphql/management/model/apollo";
import { logout } from "utils/authentication/authentication";

interface UserDetailsMenuInterface {
    resetForm: UseFormReset<UserFormData>;
    editable: boolean;
    setEditable: React.Dispatch<React.SetStateAction<boolean>>;
    selectedUser: UserFragment;
    setSelectedUser: React.Dispatch<
        React.SetStateAction<UserFragment | null | undefined>
    >;
    cancelEdit: () => void;
}

const UserDetailsMenu = (props: UserDetailsMenuInterface) => {
    const { keycloak } = useKeycloak();

    const CleaqSwal = withReactContent(Swal);

    const [deleteUserMutation] = useDeleteUserMutation();

    const adminQuery = useFindAdminQuery();

    const deleteUser = () => {
        if (
            props.selectedUser?.role === UserRole.OWNER &&
            adminQuery.data?.findUser &&
            adminQuery.data?.findUser.length === 1
        ) {
            CleaqSwal.fire({
                title: "Suppression impossible !",
                html:
                    "Vous êtes le seul administrateur de l'entreprise.\n" +
                    // eslint-disable-next-line quotes
                    'Supprimer votre entreprise dans l\'onglet \n "Mon entreprise"',
                icon: "error",
            });
        } else {
            CleaqSwal.fire({
                title: "Vous souhaitez supprimer un utilisateur !",
                html: "L'utilisateur pourra toujours se connecter mais n'aura plus accès aux informations de votre entreprise !",
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
                cancelButtonText: "Annuler",
                denyButtonText: "Confirmer suppression",
            }).then((result) => {
                if (result.isDenied) {
                    deleteUserMutation({
                        variables: {
                            email: props.selectedUser?.email!,
                        },
                        update: () => {
                            props.setSelectedUser(null);
                        },
                        refetchQueries: [
                            refetchFindDevicesQuery(),
                            refetchFindOrganisationsQuery(),
                            refetchComputeDashboardDataQuery(),
                            refetchFindUsersQuery(),
                            refetchFindAdminQuery(),
                        ],
                    })
                        .then((response: any) => {
                            CleaqSwal.fire({
                                title: "Confirmé !",
                                html: "L'utilisateur a été supprimé",
                                icon: "success",
                            }).then((result) => {
                                if (
                                    props.selectedUser.email ===
                                    keycloak.profile?.email
                                ) {
                                    logout(keycloak);
                                }
                            });
                        })
                        .catch((error: any) => {
                            CleaqSwal.fire({
                                title: "Oops, une erreur s'est produite !",
                                html: "Contactez-nous dans le chat !",
                                icon: "error",
                            });
                        });
                }
            });
        }
    };

    const handleOnEditable = () => {
        props.resetForm({
            phoneNumber: props.selectedUser.phoneNumber ?? "",
            email: props.selectedUser.email,
            firstName: props.selectedUser.firstName,
            lastName: props.selectedUser.lastName,
            organisations: (props.selectedUser.organisations ?? []).map(
                (orgaItem) => ({
                    value: orgaItem.id!,
                    label: orgaItem.name!,
                }),
            ),
            administrateur: props.selectedUser.role === UserRole.OWNER,
            customNotes: props.selectedUser.customNotes ?? "",
        });
        props.setEditable(true);
    };

    return (
        <div className="nlt-card-title-buttons">
            <div>
                <a
                    className="nlt-button-inside"
                    hidden={props.editable}
                    onClick={handleOnEditable}
                >
                    <i className="icofont icofont-pencil-alt-2 mr-2" />
                    Editer
                </a>
            </div>
            <div className="float-left">
                <a
                    className="nlt-button-inside-close"
                    onClick={() => {
                        props.setSelectedUser(null);
                    }}
                    hidden={props.editable}
                >
                    <i className="icofont icofont-close-line" />
                </a>
            </div>
            <div>
                <div
                    className="nlt-button-inside-delete"
                    hidden={!props.editable}
                    onClick={() => deleteUser()}
                >
                    <i className="icofont icofont-bin" />
                </div>
            </div>
            <button
                type="submit"
                className="nlt-button-inside-form-save"
                hidden={!props.editable}
            >
                Enregistrer
            </button>
            <div
                className="nlt-button-inside-cancel"
                onClick={props.cancelEdit}
                hidden={!props.editable}
            >
                Annuler
            </div>
        </div>
    );
};

export default UserDetailsMenu;
