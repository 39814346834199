interface ErrorProps {
    color: string;
}

const ErrorIcon = ({ color = "#000" }: ErrorProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM8.5 14.5C8.5 14.1022 8.65804 13.7206 8.93934 13.4393C9.22064 13.158 9.60218 13 10 13C10.3978 13 10.7794 13.158 11.0607 13.4393C11.342 13.7206 11.5 14.1022 11.5 14.5C11.5 14.8978 11.342 15.2794 11.0607 15.5607C10.7794 15.842 10.3978 16 10 16C9.60218 16 9.22064 15.842 8.93934 15.5607C8.65804 15.2794 8.5 14.8978 8.5 14.5ZM9.016 4.82C9.05775 4.58944 9.17913 4.38085 9.35897 4.23065C9.53881 4.08045 9.76569 3.99817 10 3.99817C10.2343 3.99817 10.4612 4.08045 10.641 4.23065C10.8209 4.38085 10.9423 4.58944 10.984 4.82L11 5V10L10.984 10.18C10.9423 10.4106 10.8209 10.6191 10.641 10.7694C10.4612 10.9196 10.2343 11.0018 10 11.0018C9.76569 11.0018 9.53881 10.9196 9.35897 10.7694C9.17913 10.6191 9.05775 10.4106 9.016 10.18L9 10V5L9.016 4.82Z"
            fill={color}
        />
    </svg>
);

export default ErrorIcon;
