import React, { forwardRef, useState } from "react";

import moment from "moment";
import DatePicker from "react-datepicker";

import { DEFAULT_SHIPPING_DELAY } from "components/business/catalog/shipping-delay-info/shipping-delay-info";
import ProductDeliveryDate from "components/business/order/product/product-delivery-date";
import { isWeekDay } from "components/business/order/step-form/utils";
import { DeliveryTypeInput } from "graphql/order/model/apollo";

interface ProductItemDateProps {
    shippingDelay: number | null;
    startDate: Date;
    setDeliveryDate: (date: Date, deliveryType: DeliveryTypeInput) => void;
    deliveryDate: ProductDeliveryDate;
}

export default function ProductItemDate(props: ProductItemDateProps) {
    const [selectedDate, setSelectedDate] = useState<Date>(
        moment(props.deliveryDate.date).toDate(),
    );
    const [isScheduledDelivery, setIsScheduledDelivery] = useState<boolean>(
        props.deliveryDate.deliveryType === DeliveryTypeInput.SCHEDULED,
    );
    const delay = props.shippingDelay || DEFAULT_SHIPPING_DELAY;
    const ShippingDelaySelector = forwardRef(
        ({ onClick, value }: any, ref: any) => {
            if (!isScheduledDelivery) {
                return (
                    <>
                        <p className="date-picker__label">
                            Livraison la plus rapide
                        </p>
                        <p className="date-picker__value">
                            Au plus tard {delay} jours après
                            <br />
                            la signature du contrat.
                        </p>
                        <button
                            type="button"
                            className="open-calendar-button"
                            onClick={onClick}
                            ref={ref}
                        >
                            Programmer la livraison
                        </button>
                    </>
                );
            }

            return (
                <>
                    <p className="date-picker__label">
                        Livraison personnalisée
                    </p>
                    <div className="date-picker__value">
                        <p>
                            Programmée le{" "}
                            <button
                                type="button"
                                className="date-picker__calendar"
                                onClick={onClick}
                                ref={ref}
                            >
                                {moment.utc(selectedDate).format("DD/MM/YYYY")}
                            </button>
                            <br />
                            sous réserve de signature du contrat
                        </p>
                    </div>
                    <button
                        className="open-calendar-button"
                        onClick={() => {
                            setSelectedDate(props.startDate);
                            props.setDeliveryDate(
                                props.startDate,
                                DeliveryTypeInput.SOONEST,
                            );
                            setIsScheduledDelivery(false);
                        }}
                        type="button"
                    >
                        Choisir la livraison rapide
                    </button>
                </>
            );
        },
    );

    return (
        <div className="product-item__date-wrapper">
            <DatePicker
                selected={selectedDate}
                onChange={(date) => {
                    if (date) {
                        setSelectedDate(date);
                        setIsScheduledDelivery(true);
                        props.setDeliveryDate(
                            date,
                            DeliveryTypeInput.SCHEDULED,
                        );
                    }
                }}
                minDate={props.startDate}
                filterDate={(date: Date) => isWeekDay(date)}
                customInput={<ShippingDelaySelector />}
                locale="fr"
                calendarStartDay={1}
            />
        </div>
    );
}
