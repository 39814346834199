import React from "react";

import { useHistory } from "react-router";
import { v4 as uuid } from "uuid";

import SkeletonOptimizeCard from "components/business/dashboard/dashboard-optimize/skeleton-optimize-card/skeleton-optimize-card";
import {
    DeviceComputedStatus,
    InstanceDashboardFragment,
} from "graphql/management/model/apollo";
import WithDevices from "utils/user-with-devices/user-with-devices";

import OptimizeCard from "./optimize-card/optimize-card";

interface OptimizeInfo {
    instanceDashboard: InstanceDashboardFragment | null | undefined;
    dashboardLoading: boolean;
}

const Optimize = (props: OptimizeInfo) => {
    const history = useHistory();

    const RedirectToDevices = (status: DeviceComputedStatus) => {
        history.push({
            pathname: `${process.env.PUBLIC_URL}/devices`,
            state: { statusSearch: status },
        });
    };

    const RedirectToUsers = (status: WithDevices) => {
        history.push({
            pathname: `${process.env.PUBLIC_URL}/users`,
            state: { association: status },
        });
    };

    return (
        <div className="nlt-card-optimize">
            <div className="nlt-card-header">
                <div className="nlt-card-title">Optimiser mon compte</div>
            </div>

            <div className="nlt-optimize-grid">
                <div className="nlt-optimize-actions">
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            marginBottom: "14px",
                        }}
                    >
                        {props.dashboardLoading ? (
                            [...Array(2)].map(() => (
                                <SkeletonOptimizeCard key={uuid()} />
                            ))
                        ) : (
                            <>
                                <OptimizeCard
                                    icon="fa fa-user"
                                    redirectFunction={() =>
                                        RedirectToUsers(
                                            WithDevices.WITHOUT_DEVICES,
                                        )
                                    }
                                    subtitle="sans appareil"
                                    title="utilisateurs"
                                    count={
                                        props.instanceDashboard
                                            ?.deviceUsersToAffect?.usersCount
                                    }
                                />
                                <div className="nlt-optimize-sep" />
                                <OptimizeCard
                                    icon="fa fa-desktop"
                                    redirectFunction={() =>
                                        RedirectToDevices(
                                            DeviceComputedStatus.TO_LINK,
                                        )
                                    }
                                    subtitle="à affecter"
                                    title="appareils"
                                    count={
                                        props.instanceDashboard
                                            ?.deviceUsersToAffect?.devicesCount
                                    }
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className="nlt-optimize-progress-container">
                    {!props.dashboardLoading && (
                        <div
                            className="nlt-optimize-progress"
                            style={{
                                width: `${props.instanceDashboard?.deviceAffectedRate}%`,
                            }}
                        >
                            {(props.instanceDashboard?.deviceAffectedRate &&
                                props.instanceDashboard?.deviceAffectedRate >=
                                    5 &&
                                `${props.instanceDashboard?.deviceAffectedRate}%`) ||
                                ""}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Optimize;
