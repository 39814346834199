import React from "react";

import { UserFragment, UserRole } from "graphql/management/model/apollo";

interface DisplayUserDetailsInterface {
    selectedUser: UserFragment;
}

const DisplayUserDetails = (props: DisplayUserDetailsInterface) => (
    <>
        <li className="nlt-item-info">
            <div className="nlt-item-info-label">Nom</div>
            <div className="nlt-item-info-data">
                {props.selectedUser.lastName}
            </div>
        </li>
        <li className="nlt-item-info">
            <div className="nlt-item-info-label">Prénom</div>
            <div className="nlt-item-info-data">
                {props.selectedUser.firstName}
            </div>
        </li>
        <li className="nlt-item-info">
            <div className="nlt-item-info-label">Adresse e-mail</div>
            <div className="nlt-item-info-data">{props.selectedUser.email}</div>
        </li>
        <li className="nlt-item-info">
            <div className="nlt-item-info-label">N° téléphone</div>
            <div className="nlt-item-info-data">
                {props.selectedUser.phoneNumber}
            </div>
        </li>
        <li className="nlt-item-info">
            <div className="nlt-item-info-label">Role</div>
            <div className="nlt-item-info-data">
                {props.selectedUser.role === UserRole.OWNER
                    ? "Administrateur"
                    : "Utilisateur"}
            </div>
        </li>
        <div className="nlt-device-sep" />
        <h6 className="mb-3">Tags</h6>
        <div className="nlt-wide-card-tag-ls">
            {props.selectedUser.organisations &&
            props.selectedUser.organisations.length > 0 ? (
                props.selectedUser.organisations?.map((orga) => (
                    <div
                        key={orga.id}
                        style={{
                            backgroundColor: orga.hexaColor!,
                            color: "white",
                        }}
                        className="nlt-wide-card-tag-item"
                    >
                        {orga.name}
                    </div>
                ))
            ) : (
                <li>Aucun tag affecté !</li>
            )}
        </div>
    </>
);

export default DisplayUserDetails;
