import { VariantFragment } from "graphql/catalog/model/apollo";

import type { AccessoryType } from "./types";

interface ProductPricingInfo {
    price: number;
    negotiated: boolean;
    normalPrice: number;
    totalPrice: number;
}

class AddProduct {
    variant: VariantFragment | AccessoryType;

    instanceId: string;

    quantity: number = 1;

    leasingDuration: number;

    constructor(
        variant: VariantFragment | AccessoryType,
        instanceId: string,
        quantity: number,
        leasingDuration: number,
    ) {
        this.variant = variant;
        this.instanceId = instanceId;
        this.quantity = quantity;
        this.leasingDuration = leasingDuration;
    }

    setProductName(name: string | null): void {
        this.variant.name = name;
    }

    buildPricingInfo(): ProductPricingInfo {
        let price: number;
        let normalPrice: number;
        let negotiatedForDuration: boolean = false;
        const negotiated =
            this.variant.companyCoefficient?.[this.instanceId] || null;

        switch (this.leasingDuration) {
            case 24:
                normalPrice = this.variant.price24 ?? 0;
                negotiatedForDuration = (negotiated?.price24 ?? 0) > 0;
                price = (
                    negotiatedForDuration ? negotiated?.price24 : normalPrice
                ) as number;
                break;

            case 36:
                normalPrice = this.variant.price36 ?? 0;
                negotiatedForDuration = (negotiated?.price36 ?? 0) > 0;
                price = (
                    negotiatedForDuration ? negotiated?.price36 : normalPrice
                ) as number;
                break;

            case 48:
                normalPrice = this.variant.price48 ?? 0;
                negotiatedForDuration = (negotiated?.price48 ?? 0) > 0;
                price = (
                    negotiatedForDuration ? negotiated?.price48 : normalPrice
                ) as number;
                break;

            default:
                normalPrice = 0;
                price = 0;
                break;
        }

        const totalPrice = price * this.quantity;

        return {
            price,
            negotiated: negotiatedForDuration,
            normalPrice,
            totalPrice,
        };
    }
}

export default AddProduct;
