import Product from "components/business/order/product/product";

import CartAction from "./actions/cart-action";

const cartReducer = (cart: Array<Product>, action: CartAction) => {
    const updatedCart = action.apply(cart);
    return updatedCart;
};

export default cartReducer;
