import React from "react";

import { TextField, FormGroup } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import Product from "components/business/order/product/product";
import ProductAddress from "components/business/order/product/product-address";
import SetProductAddressAction from "components/provider/context/cart/actions/set-product-address-action";
import {
    useCart,
    useCartDispatch,
} from "components/provider/context/cart/cart-provider";
import hasMissingAddress from "utils/missing-address/missing-address";

interface ProductItemAddressProps {
    product: Product;
    addresses: ProductAddress[];
    setSelectedProduct: React.Dispatch<React.SetStateAction<Product | null>>;
    onCreateAddress: () => void;
}

export default function ProductItemAddress(props: ProductItemAddressProps) {
    const cartDispatch = useCartDispatch();
    const cart = useCart();

    const selectAddress = (address: ProductAddress | null) => {
        props.setSelectedProduct(props.product);
        cartDispatch(new SetProductAddressAction(props.product, address));
    };

    const selectableAddresses = props.addresses.filter(
        (address) =>
            address.phoneNumber.length > 0 && address.recipient.length > 0,
    );
    const disabled =
        props.addresses.length > 0 && selectableAddresses.length <= 0;
    const className = disabled
        ? "product-item__address-form"
        : "product-item__warning-address-phone";
    const selectedAddress = disabled ? null : props.product.address;

    return (
        <div
            className={`product-item__address ${
                hasMissingAddress(cart) && selectedAddress === null
                    ? "not-filled"
                    : ""
            }`}
        >
            <FormGroup>
                <Autocomplete
                    disabled={disabled}
                    id="autocomplete"
                    className={className}
                    getOptionLabel={(address) => address.label}
                    onChange={(
                        e: any,
                        data: ProductAddress | null,
                        reason: string,
                    ) => {
                        if (reason === "clear") {
                            selectAddress(null);
                        } else {
                            selectAddress(data);
                        }
                        props.setSelectedProduct(null);
                    }}
                    options={selectableAddresses}
                    color="primary"
                    value={selectedAddress}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Adresse de livraison"
                            margin="normal"
                            variant="outlined"
                            className="nlt-form-fix"
                            InputLabelProps={{
                                style: {
                                    color: "red",
                                },
                            }}
                        />
                    )}
                    noOptionsText={
                        <div className="select-inner-button">
                            <span>Vous n'avez aucune adresse enregistrée</span>
                            <button
                                type="button"
                                className="select-inner-button-button"
                                onMouseDown={() => {
                                    props.setSelectedProduct(props.product);
                                    props.onCreateAddress();
                                }}
                            >
                                <i className="icofont icofont-plus" />
                                <span>Ajouter une adresse</span>
                            </button>
                        </div>
                    }
                />
                {disabled && (
                    <span className="product-item__error-address-phone">
                        Assurez-vous d'avoir au moins une adresse avec un numéro
                        de téléphone renseigné.
                    </span>
                )}
            </FormGroup>
        </div>
    );
}
