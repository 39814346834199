class Translator {
    private dictionary = new Map([
        ["intel i5", "Intel Core i5"],
        ["intel i7", "Intel Core i7"],
        ["intel i9", "Intel Core i9"],
        ["apple m1", "Apple M1"],
        ["apple m2", "Apple M2"],
        ["apple m1 pro", "Apple M1 Pro"],
        ["apple m1 max", "Apple M1 Max"],
        ["apple m2 pro", "Apple M2 Pro"],
        ["apple m2 max", "Apple M2 Max"],
        ["mac", "MacOS"],
        ["windows", "Windows"],
        ["pc", "PC"],
        ["ios", "iOS"],
        ["android", "Android"],
        ["chromeos", "ChromeOS"],
        ["apple", "Apple"],
        ["dell", "Dell"],
        ["lenovo", "Lenovo"],
        ["logitech", "Logitech"],
        ["all", "Universelle"],
    ]);

    translate = (value: string | null): string => {
        if (value === null) return "";

        return this.dictionary.has(value.toString())
            ? this.dictionary.get(value.toString()) ?? ""
            : value.toString();
    };
}

export const formatPrice = (price: number | null) =>
    price ? `${price.toFixed(2).toString().replace(".", ",")}€` : "0€";

export const formatIsIncluded = (isIncluded: boolean) =>
    isIncluded === true ? "Avec" : "Sans";

export const formatMemory = (value: number | null) => {
    if (!value) return "";

    return value / 1024 >= 1 ? `${Math.ceil(value / 1024)}To` : `${value}Go`;
};

const translator = new Translator();

export default translator;
