import React from "react";

import Button from "components/common/layout/Button/Button";
import { SubOrderFragment } from "graphql/order/model/apollo";
import { defineAddressStatus } from "utils/order-status/order-status";
import { formatPrice } from "utils/translator/translator";

import "./OrderHistoryItem.scss";

interface OrderInfo {
    subOrder: SubOrderFragment;
}

const OrderHistoryItem = (props: OrderInfo) => {
    const orderStatus = defineAddressStatus(props.subOrder.status);

    const productCount = props.subOrder.productCount;

    const orderProductsSummary = props.subOrder.uniqueReferences;

    const orderUniqueAddresses = props.subOrder.uniqueAddresses;

    const handleQuoteButtonClick =
        (url: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            event.stopPropagation();
            window.open(url, "_blank");
        };

    return (
        <div className="order-item">
            <div
                className="products-list"
                data-cy="products-list"
            >
                <div className="order-item-info-title">
                    Produits ({productCount})
                </div>
                <div className="order-item-info-data">
                    <div className="list-info-inside-item">
                        {orderProductsSummary.slice(0, 2).map((product) => (
                            <div key={product}>{product}</div>
                        ))}
                    </div>
                    {orderProductsSummary.length > 2 && (
                        <div>{orderProductsSummary.length - 2} de plus</div>
                    )}
                </div>
            </div>
            <div
                className="delivery"
                data-cy="delivery-info"
            >
                <div className="order-item-info-title">
                    Livraison ({orderUniqueAddresses.length})
                </div>
                <div className="order-item-info-data">
                    <div className="list-info-inside-item">
                        {orderUniqueAddresses
                            .slice(0, 2)
                            .map((uniqueAddress) => (
                                <div key={uniqueAddress}>{uniqueAddress}</div>
                            ))}
                    </div>
                    {orderUniqueAddresses.length > 2 && (
                        <div>{orderUniqueAddresses.length - 2} de plus</div>
                    )}
                </div>
            </div>
            <div
                className="status-info"
                data-cy="status-info"
            >
                <div className="order-item-info-title">Statut</div>
                <div
                    className={`order-status status-${orderStatus.stylingProperty}`}
                >
                    {orderStatus.label}
                </div>
            </div>
            <div
                className="suborder-quote"
                data-cy="suborder-quote"
            >
                {props.subOrder.contract && !props.subOrder.contract.signed && (
                    <Button
                        theme="outlinedSecondary"
                        onClick={handleQuoteButtonClick(
                            props.subOrder.contract.url,
                        )}
                    >
                        Signer le bon de commande
                    </Button>
                )}
            </div>
            <div
                className="price-info"
                data-cy="suborder-price"
            >
                <div>
                    <div>
                        <div className="order-item-info-data">
                            <div>
                                <p>
                                    <strong>
                                        {formatPrice(props.subOrder.priceSum)}
                                    </strong>{" "}
                                    HT / mois
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderHistoryItem;
