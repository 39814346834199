import Swal, { SweetAlertIcon } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const CleaqSwal = withReactContent(Swal);

async function displayAlertMessage(
    message: string,
    icon: SweetAlertIcon,
    callbackMethod?: () => void,
) {
    const title =
        icon === "error" ? "Oops, une erreur s'est produite !" : "Confirmé !";

    await CleaqSwal.fire({
        title,
        html: message,
        icon,
    });

    if (callbackMethod) {
        callbackMethod();
    }
}

export default displayAlertMessage;
