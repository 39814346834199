import {
    useDeleteIntegrationMutation,
    IntegrationProvider,
    refetchFindIntegrationsQuery,
    DeleteIntegrationMutationHookResult,
} from "graphql/management/model/apollo";
import displayAlertMessage from "utils/displayAlertMessage/displayAlertMessage";

const useDeleteIntegration = (
    provider: string,
): DeleteIntegrationMutationHookResult => {
    const integrationProvider =
        IntegrationProvider[
            provider.toUpperCase() as keyof typeof IntegrationProvider
        ];

    const [deleteIntegration, deleteIntegrationResult] =
        useDeleteIntegrationMutation({
            variables: { provider: integrationProvider },
            onCompleted: () => {
                displayAlertMessage(
                    "L'intégration a bien été désactivée.",
                    "success",
                );
            },
            onError: () => {
                displayAlertMessage(
                    "L'intégration n'a pas pu être désactivée.",
                    "error",
                );
            },
            refetchQueries: [refetchFindIntegrationsQuery()],
        });

    return [deleteIntegration, deleteIntegrationResult];
};

export default useDeleteIntegration;
