import React from "react";

const RightChevronIcon = () => {
    return (
        <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.38916 16.636L11.3181 11.7071C11.7086 11.3166 11.7086 10.6834 11.3181 10.2929L6.38916 5.36395"
                stroke="#494949"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default RightChevronIcon;
