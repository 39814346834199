import React from "react";

import { Tooltip } from "@material-ui/core";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ProductAddress from "components/business/order/product/product-address";
import CrossIcon from "components/common/icons/CrossIcon";
import RemoveAddressFromProductsAction from "components/provider/context/cart/actions/remove-address-from-products-action";
import { useCartDispatch } from "components/provider/context/cart/cart-provider";
import {
    refetchFindInstanceQuery,
    useDeleteShippingAddressMutation,
} from "graphql/management/model/apollo";
import displayCountryName from "utils/country/display-country";

interface AddressItemOrderProps {
    address: ProductAddress;
    onSelect: (address: ProductAddress | undefined) => void;
    selected: boolean;
    handleEdit: () => void;
}

const AddressItemOrder = (props: AddressItemOrderProps) => {
    const [deleteAddressMutation] = useDeleteShippingAddressMutation();
    const CleaqSwal = withReactContent(Swal);
    const cartDispatch = useCartDispatch();

    const deleteAddress = async (addressId: string) => {
        await deleteAddressMutation({
            variables: {
                addressId,
            },
            refetchQueries: [refetchFindInstanceQuery()],
        });

        cartDispatch(new RemoveAddressFromProductsAction(addressId));
    };

    const selectAddress = (active: boolean) => {
        props.onSelect(active ? props.address : undefined);
    };

    const isAddressSelectable =
        props.address.phoneNumber.length > 0 &&
        props.address.recipient.length > 0;

    const addressLabel = (address: ProductAddress) => {
        if (address.name.length > 0) {
            return (
                <>
                    <div className="address-line">
                        <span className="address-line__name">
                            {address.name}
                        </span>
                    </div>
                    <div className="address-line">
                        <span className="address-line__country">
                            {`${address.zipcode} ${
                                address.cityName
                            }, ${displayCountryName(address.country)}`}
                        </span>
                    </div>
                    {address.phoneNumber.length > 0 && (
                        <div className="address-line">
                            <span className="address-line__phone-number">
                                {address.phoneNumber}
                            </span>
                        </div>
                    )}
                </>
            );
        }
        return "Adresse non définie";
    };

    return (
        <div className={`address ${props.selected ? "select-all" : ""}`}>
            <div className="address-btn">
                <button
                    className="edit-btn"
                    type="button"
                    onClick={props.handleEdit}
                >
                    <i className="icofont icofont-pencil-alt-2 mr-2 mb-0" />
                </button>
                {props.address.id !== "000000000000000000000000" && (
                    <button
                        className="delete-btn"
                        type="button"
                        onClick={() =>
                            CleaqSwal.fire({
                                title: `Vous souhaitez supprimer l'adresse ${props.address.label} ?`,
                                showCancelButton: true,
                                showDenyButton: true,
                                showConfirmButton: false,
                                cancelButtonText: "Annuler",
                                denyButtonText: "Confirmer suppression",
                            }).then((result) => {
                                if (result.isDenied) {
                                    deleteAddress(props.address.id!);
                                }
                            })
                        }
                    >
                        <CrossIcon />
                    </button>
                )}
            </div>
            <div className="address-content">
                <div className="title">
                    <i
                        className={`icofont ${
                            props.address.id === "000000000000000000000000"
                                ? "icofont-building"
                                : "icofont-home"
                        }`}
                        style={{ paddingRight: "5px" }}
                    />
                    {props.address.label}
                </div>
                <div className="content">{addressLabel(props.address)}</div>
                <div className="foot">
                    {isAddressSelectable ? (
                        <label className="switch">
                            <input
                                disabled={props.address.phoneNumber === ""}
                                type="checkbox"
                                onChange={(e) => {
                                    selectAddress(e.target.checked);
                                }}
                                checked={props.selected}
                                data-cy="assign-address-button"
                            />
                            <span className="slider" />
                            <div className="switch-title">
                                Tout assigner à cette adresse
                            </div>
                        </label>
                    ) : (
                        <div className="foot-warning">
                            Données manquantes{" "}
                            <Tooltip
                                title="Certaines informations sont manquantes pour cette adresse. Veuillez les compléter pour pouvoir l'assigner à vos produits."
                                placement="right"
                            >
                                <i className="icofont icofont-info-circle" />
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddressItemOrder;
