class FilterValues {
    constructor(
        screenSize: string[],
        processor: string[],
        storage: string[],
        ram: string[],
        os: string[],
        budget: number[][],
    ) {
        this.screenSize = screenSize;
        this.processor = processor;
        this.storage = storage;
        this.ram = ram;
        this.os = os;
        this.budget = budget;
    }

    screenSize: string[];

    processor: string[];

    storage: string[];

    ram: string[];

    os: string[];

    budget: number[][];
}

export default FilterValues;
