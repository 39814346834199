import React from "react";

import "./loader.scss";

// eslint-disable-next-line react/require-default-props
const Loader = (props: { main?: boolean }) => (
    <>
        <div
            id="loader"
            className={`loader-wrapper${props.main ? "" : " content-loader"}`}
        >
            <div className="loader-index">
                <span />
            </div>
        </div>
    </>
);

export default Loader;
