import React from "react";

import getIconColor from "./utils";

interface LaptopIconProps {
    isActive?: boolean;
}

export default function LaptopIcon(props: LaptopIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
            fill="none"
        >
            <path
                d="m4.67,5.83h18.67v12.83H4.67V5.83Z"
                fill="#EAE7E1"
            />
            <path
                d="m23.33,21c1.28,0,2.32-1.05,2.32-2.33V5.83c.01-1.28-1.04-2.33-2.32-2.33H4.67c-1.28,0-2.33,1.05-2.33,2.33v12.83c0,1.28,1.05,2.33,2.33,2.33H0c0,1.28,1.05,2.33,2.33,2.33h23.33c1.28,0,2.33-1.05,2.33-2.33h-4.67ZM4.67,5.83h18.67v12.83H4.67V5.83Zm9.33,16.33c-.64,0-1.17-.52-1.17-1.17s.53-1.17,1.17-1.17,1.17.52,1.17,1.17-.53,1.17-1.17,1.17Z"
                fill={getIconColor(props.isActive ?? false)}
            />
        </svg>
    );
}

LaptopIcon.defaultProps = {
    isActive: false,
};
