import React from "react";

import { useForm, Controller } from "react-hook-form";
import { Form, FormGroup, Input, Row } from "reactstrap";

import { currentUserFragment } from "graphql/management/model/apollo";

import { FormContent } from "../account-form";
import ProfilePic from "../profile-pic/profile-pic";

interface AccountFormEditProps {
    updateUserForm: (dataPush: FormContent) => Promise<void>;
    user: currentUserFragment;
    saving: boolean;
    cancelEdit: () => void;
    deleteUser: () => void;
}

export default function AccountFormEdit(props: AccountFormEditProps) {
    const { updateUserForm, user, saving, cancelEdit, deleteUser } = props;

    const { control, handleSubmit } = useForm<FormContent>({
        defaultValues: {
            lastName: user.lastName,
            firstName: user.firstName,
            phoneNumber: user.phoneNumber || "",
            organisations: user.organisations,
            administrateur: user.role === "OWNER",
        },
    });

    const onSubmit = handleSubmit(updateUserForm);

    return (
        <>
            <Form onSubmit={onSubmit}>
                <div className="nlt-card-header">
                    <span className="nlt-card-title">
                        Informations personnelles
                    </span>
                </div>
                <div className="nlt-wide-card-right-panel-container">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                            marginTop: "2px",
                        }}
                    >
                        <div className="info-avatar">
                            <ProfilePic currentUser={user} />
                        </div>
                        <div className="d-flex flex-column justify-content-between align-items-end">
                            {!saving ? (
                                <div className="nlt-card-title-buttons">
                                    <button
                                        type="submit"
                                        className="nlt-button-inside-form-save"
                                    >
                                        Enregistrer
                                    </button>
                                    <div
                                        aria-hidden="true"
                                        className="nlt-button-inside-cancel"
                                        onClick={cancelEdit}
                                    >
                                        Annuler
                                    </div>
                                </div>
                            ) : (
                                <div className="nlt-loader-container">
                                    <div className="nlt-loader-save" />
                                </div>
                            )}
                        </div>
                    </div>
                    <ul className="m-t-30">
                        <Row className="nlt-row-form-fix">
                            <div className="nlt-item-info-label-edit col-md-5 required">
                                Nom
                            </div>
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (value) =>
                                        value?.trim()?.length > 0,
                                }}
                                render={({
                                    field: { ref, ...rest },
                                    fieldState: { error },
                                }) => (
                                    <FormGroup className="col-md-7">
                                        {!error ? (
                                            <span className="mandatory-label-small" />
                                        ) : (
                                            <span className="nlt-mandatory-label-small nlt-font-red">
                                                Le nom de famille est requis
                                            </span>
                                        )}
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="text"
                                            placeholder="Petit"
                                            innerRef={ref}
                                        />
                                    </FormGroup>
                                )}
                            />
                        </Row>
                        <Row className="nlt-row-form-fix">
                            <div className="nlt-item-info-label-edit col-md-5 required">
                                Prénom
                            </div>
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (value) =>
                                        value?.trim()?.length > 0,
                                }}
                                render={({
                                    field: { ref, ...rest },
                                    fieldState: { error },
                                }) => (
                                    <FormGroup className="col-md-7">
                                        {!error ? (
                                            <span className="mandatory-label-small" />
                                        ) : (
                                            <span className="nlt-mandatory-label-small nlt-font-red">
                                                Le prénom est requis
                                            </span>
                                        )}
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="text"
                                            placeholder="Laura"
                                            innerRef={ref}
                                        />
                                    </FormGroup>
                                )}
                            />
                        </Row>
                        <Row className="nlt-row-form-fix">
                            <div className="nlt-item-info-label-edit col-md-5">
                                N° téléphone
                            </div>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                render={({ field: { ref, ...rest } }) => (
                                    <FormGroup className="col-md-7">
                                        <Input
                                            {...rest}
                                            className="form-control"
                                            type="tel"
                                            placeholder="06 01 02 03 04"
                                            innerRef={ref}
                                        />
                                    </FormGroup>
                                )}
                            />
                        </Row>
                    </ul>
                </div>
            </Form>

            <div
                aria-hidden="true"
                onClick={() => deleteUser()}
                className="deleteAccountButton"
            >
                <p>Supprimer mon compte</p>
            </div>
        </>
    );
}
