import React, { useState } from "react";

import { Controller, useForm } from "react-hook-form";

import Input from "components/common/input/Input/Input";
import Button from "components/common/layout/Button/Button";
import {
    refetchFindInstanceQuery,
    useUpdateSirenMutation,
} from "graphql/management/model/apollo";

import styles from "./SirenForm.module.scss";

interface SirenFormProps {
    siren: string;
}

interface FormMessage {
    message: string;
    type: "success" | "error";
}

class SirenFormFields {
    siren: string = "";
}

const SirenForm = (props: SirenFormProps) => {
    const [updateSiren] = useUpdateSirenMutation();
    const [formMessage, setFormMessage] = useState<FormMessage | null>(null);

    const {
        formState: { errors },
        handleSubmit,
        control,
        watch,
    } = useForm<SirenFormFields>({
        mode: "onChange",
        defaultValues: {
            siren: props.siren,
        },
    });

    const watchSiren = watch("siren");

    const submitSiren = async (form: SirenFormFields) => {
        const SIREN = form.siren.replace(/ /g, "");

        await updateSiren({
            variables: {
                siren: SIREN,
            },
            onCompleted: () => {
                setFormMessage({
                    message:
                        "La demande de modification du SIREN a bien été transmise à nos équipes.",
                    type: "success",
                });
            },
            onError: () => {
                setFormMessage({
                    message:
                        "La demande de modification du SIREN n'a pas pu être transmise à nos équipes.",
                    type: "error",
                });
            },
            refetchQueries: [refetchFindInstanceQuery()],
        });
    };

    const shouldDisplayButton = () => props.siren !== watchSiren;

    return (
        <>
            {!formMessage ? (
                <div>
                    <p className={styles.description}>
                        La modification du SIREN et le transfert de vos
                        éventuelles locations en cours ne sont pas immédiats ni
                        automatiques. Des informations complémentaires peuvent
                        vous être demandées. Le transfert de vos locations
                        existantes vers la nouvelle entité peut également
                        requérir une approbation et un contrat spécifique.
                    </p>
                    <form
                        onSubmit={handleSubmit(submitSiren)}
                        data-cy="siren-form"
                    >
                        <Controller
                            name="siren"
                            control={control}
                            rules={{
                                required:
                                    "Le numéro SIREN est requis pour continuer.",
                                pattern: {
                                    value: /^\d{9}$/,
                                    message:
                                        "Le numéro SIREN ne contient pas le bon nombre de caractères (9 caractères).",
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <Input
                                    {...field}
                                    data-cy="siren-input"
                                    title="SIREN"
                                    placeholder="123456789"
                                    errorMessage={error?.message}
                                    isValid={
                                        watchSiren.length > 0 &&
                                        error?.message === undefined
                                    }
                                    tooltipText="Le numéro SIREN est composé de 9 chiffres. Il figure sur votre extrait Kbis."
                                />
                            )}
                        />
                        <div className={styles.btnGroup}>
                            {shouldDisplayButton() && (
                                <Button
                                    data-cy="siren-submit"
                                    disabled={errors.siren !== undefined}
                                    theme="primary"
                                >
                                    Confirmer la demande
                                </Button>
                            )}
                        </div>
                    </form>
                </div>
            ) : (
                <div
                    className={`${
                        formMessage.type === "success"
                            ? styles.success
                            : styles.error
                    }`}
                >
                    {formMessage.message}
                </div>
            )}
        </>
    );
};

export default SirenForm;
