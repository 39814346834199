import React from "react";

import Skeleton from "@material-ui/lab/Skeleton";

import "./skeleton-admin-item.scss";

const SkeletonAdminItem = () => (
    <div className="nlt-admin-container">
        <div className="nlt-admin">
            <div className="nlt-admin-name">
                <Skeleton width={120} />
            </div>
            <div className="nlt-admin-mail">
                <Skeleton width={150} />
            </div>
        </div>
        <div className="text-right">
            <Skeleton
                className="skeleton-button-admin"
                variant="rect"
                width={54}
                height={32}
            />
        </div>
    </div>
);

export default SkeletonAdminItem;
