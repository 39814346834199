import React, { useState } from "react";

import { FormGroup, FormControlLabel } from "@material-ui/core";

import { FilterNames } from "components/business/catalog/filter/filters";
import CustomCheckbox from "components/common/input/custom-checkbox/custom-checkbox";
import SetCatalogFiltersAction from "components/provider/context/catalog-filters/actions/set-catalog-filters-action";
import { useCatalogFiltersDispatch } from "components/provider/context/catalog-filters/catalog-filters-provider";

interface RangeCheckboxFilterProps {
    type: FilterNames;
    filters: number[][];
    valuesChecked: number[][];
}

export default function RangeCheckboxFilter(props: RangeCheckboxFilterProps) {
    const [show, setShow] = useState<boolean>(false);
    const catalogFiltersDispatch = useCatalogFiltersDispatch();

    const filterLabel: { [key: string]: string } = {
        budget: "Budget",
    };

    const getFilterName = (filter: number[]) =>
        `de ${filter[0]}€ à ${filter[1]}€`;

    return (
        <div className="filter range-filter">
            <div
                aria-hidden="true"
                className="filter-title"
                onClick={() => setShow(!show)}
            >
                <h6>{filterLabel[props.type]}</h6>
            </div>
            <div className="filter-options">
                <FormGroup>
                    {props.filters.length > 0 &&
                        props.filters.map((filter, index) => (
                            <FormControlLabel
                                key={getFilterName(filter)}
                                control={
                                    <CustomCheckbox
                                        onChange={(event) => {
                                            catalogFiltersDispatch(
                                                new SetCatalogFiltersAction(
                                                    props.type,
                                                    filter,
                                                ),
                                            );
                                        }}
                                        value={filter}
                                        size="small"
                                        checked={JSON.stringify(
                                            props.valuesChecked,
                                        ).includes(JSON.stringify(filter))}
                                    />
                                }
                                label={getFilterName(filter)}
                            />
                        ))}
                </FormGroup>
            </div>
        </div>
    );
}
