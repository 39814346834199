import React, { useState } from "react";

import CrossIcon from "components/common/icons/CrossIcon";

import "./CareContactDetails.scss";

const CareContactDetails = () => {
    const [animationName, setAnimationName] = useState("");

    return (
        <div className={`care-contact-details ${animationName}`}>
            <button
                className="care-contact-circle"
                onClick={() => setAnimationName("openAnimation")}
            />
            <div className="care-contact-content">
                <div className="care-contact-button">
                    <button
                        onClick={() => {
                            setAnimationName("closeAnimation");
                        }}
                        className="close-button"
                    >
                        <CrossIcon />
                    </button>
                </div>
                <h2 className="care-contact-title">
                    Une question à propos de votre commande?
                </h2>
                <a
                    className="care-contact-links"
                    href="mailto:care@cleaq.com"
                >
                    <i className="icofont icofont-email"></i> care@cleaq.com
                </a>
                <a
                    className="care-contact-links"
                    href="tel:+33176410215"
                >
                    <i className="icofont icofont-phone"></i> 01 76 41 02 15
                </a>
            </div>
        </div>
    );
};

export default CareContactDetails;
