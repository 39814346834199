import React from "react";

const HeadphonesIcon = () => (
    <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.5 0C4.986 0 0.5 4.486 0.5 10V14.143C0.5 15.167 1.397 16 2.5 16H3.5C3.76522 16 4.01957 15.8946 4.20711 15.7071C4.39464 15.5196 4.5 15.2652 4.5 15V9.857C4.5 9.59178 4.39464 9.33743 4.20711 9.14989C4.01957 8.96236 3.76522 8.857 3.5 8.857H2.592C3.148 4.987 6.478 2 10.5 2C14.522 2 17.852 4.987 18.408 8.857H17.5C17.2348 8.857 16.9804 8.96236 16.7929 9.14989C16.6054 9.33743 16.5 9.59178 16.5 9.857V16C16.5 17.103 15.603 18 14.5 18H12.5V17H8.5V20H14.5C16.706 20 18.5 18.206 18.5 16C19.603 16 20.5 15.167 20.5 14.143V10C20.5 4.486 16.014 0 10.5 0Z"
            fill="#FF625A"
        />
    </svg>
);

export default HeadphonesIcon;
