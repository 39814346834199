import React from "react";

import { v4 as uuid } from "uuid";

import SkeletonCheckboxFilter from "./skeleton-checkbox-filter/skeleton-checkbox-filter";

import "components/business/catalog/filter/filters-sidebar/filters-sidebar.scss";

const SkeletonFiltersSidebar = () => (
    <div className="filters-sidebar">
        {[...Array(3)].map((_) => (
            <SkeletonCheckboxFilter key={uuid()} />
        ))}
    </div>
);

export default SkeletonFiltersSidebar;
