import React from "react";

import { TabContent, TabPane } from "reactstrap";

import { OrganisationOption } from "components/business/user/types";
import { UserFragment } from "graphql/management/model/apollo";

import UserDetailsPanel from "./details/user-details-panel";
import CreateUserPanel from "./user-create";
interface UserSidePanelInterface {
    saving: boolean;
    setSaving: React.Dispatch<React.SetStateAction<boolean>>;
    organisationOptions: OrganisationOption[] | undefined;
    editable: boolean;
    setEditable: React.Dispatch<React.SetStateAction<boolean>>;
    selectedUser: UserFragment | null | undefined;
    setSelectedUser: React.Dispatch<
        React.SetStateAction<UserFragment | null | undefined>
    >;
    isCreateUser: boolean;
    setIsCreateUser: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserSidePanel = (props: UserSidePanelInterface) => (
    <>
        <div
            className="nlt-wide-card-right-panel"
            style={{ width: "50%" }}
        >
            <div style={{ position: "sticky" }}>
                <TabContent>
                    <TabPane>
                        {props.selectedUser && (
                            <UserDetailsPanel
                                saving={props.saving}
                                setSaving={props.setSaving}
                                organisationOptions={props.organisationOptions}
                                editable={props.editable}
                                setEditable={props.setEditable}
                                selectedUser={props.selectedUser}
                                setSelectedUser={props.setSelectedUser}
                                setIsCreateUser={props.setIsCreateUser}
                            />
                        )}
                        {props.isCreateUser && (
                            <CreateUserPanel
                                saving={props.saving}
                                setSaving={props.setSaving}
                                setIsCreateUser={props.setIsCreateUser}
                                setSelectedUser={props.setSelectedUser}
                                setEditable={props.setEditable}
                                organisationOptions={props.organisationOptions}
                            />
                        )}
                    </TabPane>
                </TabContent>
            </div>
        </div>
    </>
);

export default UserSidePanel;
