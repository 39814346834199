import React from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    AddressFragment,
    refetchFindInstanceQuery,
    useDeleteShippingAddressMutation,
} from "graphql/management/model/apollo";

import AddressPlaceholder from "./address-placeholder/address-placeholder";
import AddressItem from "./AddressItemInstance/AddressItemInstance";
import "./address-list.scss";

interface CreateAddressListProps {
    addresses: Array<AddressFragment>;
    setAddressForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddressList = (props: CreateAddressListProps) => {
    const [deleteAddress] = useDeleteShippingAddressMutation();
    const CleaqSwal = withReactContent(Swal);

    const removeAddress = async (addressId: string) => {
        await deleteAddress({
            variables: {
                addressId,
            },
            refetchQueries: [refetchFindInstanceQuery()],
        });
    };

    const handleRemoveAddress = (address: AddressFragment) => {
        CleaqSwal.fire({
            title: `Vous souhaitez supprimer l'adresse ${address.label} ?`,
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
            cancelButtonText: "Annuler",
            denyButtonText: "Confirmer suppression",
        }).then((result) => {
            if (result.isDenied) {
                removeAddress(address.id!);
            }
        });
    };

    return (
        <>
            <button
                type="button"
                className="nlt-button-inside-form pull-right"
                onClick={() => props.setAddressForm(true)}
                data-cy="add-new-address"
            >
                Ajouter
            </button>
            <div className="nlt-card-header">
                <div className="nlt-card-title">Adresses de livraison</div>
            </div>
            <div className="address-list">
                {props.addresses.length ? (
                    <div className="address-wrapper add-address">
                        <div>
                            <div className="address-list-wrapper">
                                {props.addresses.map((address) => (
                                    <AddressItem
                                        key={address.id}
                                        label={address?.label!}
                                        addressName={address?.addressName}
                                        phoneNumber={address?.phoneNumber}
                                        zipcode={address?.zipCode}
                                        country={address?.country}
                                        onClick={() =>
                                            handleRemoveAddress(address)
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <AddressPlaceholder />
                )}
            </div>
        </>
    );
};
export default AddressList;
