import React from "react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
    DeviceFragment,
    refetchComputeDashboardDataQuery,
    refetchFindDevicesQuery,
    refetchFindOrganisationsQuery,
    refetchFindUsersQuery,
    useDeleteDevicesMutation,
} from "graphql/management/model/apollo";

interface deleteSingleDeviceInterface {
    selectedDevice: DeviceFragment;
    setSelectedDevice: React.Dispatch<
        React.SetStateAction<DeviceFragment | null | undefined>
    >;
}

const CleaqSwal = withReactContent(Swal);

const DeleteSingleDevice = (props: deleteSingleDeviceInterface) => {
    const [deleteDevicesMutation] = useDeleteDevicesMutation();

    const deleteDevice = () => {
        CleaqSwal.fire({
            title: "Vous souhaitez supprimer un appareil !",
            html: "Toutes les informations liées à cet appareil seront définitivement supprimées.",
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
            cancelButtonText: "Annuler",
            denyButtonText: "Confirmer suppression",
        }).then((result) => {
            if (result.isDenied) {
                deleteDevicesMutation({
                    variables: {
                        serials: [props.selectedDevice?.serialNumber!],
                    },
                    refetchQueries: [
                        refetchComputeDashboardDataQuery(),
                        refetchFindDevicesQuery(),
                        refetchFindOrganisationsQuery(),
                        refetchFindUsersQuery(),
                    ],
                    onCompleted: () => {
                        props.setSelectedDevice(null);
                    },
                })
                    .then(() => {
                        CleaqSwal.fire({
                            title: "Confirmé !",
                            html: "L'appareil a été supprimé",
                            icon: "success",
                        });
                    })
                    .catch(() => {
                        CleaqSwal.fire({
                            title: "Oops, une erreur s'est produite !",
                            html: "Contactez-nous dans le chat !",
                            icon: "error",
                        });
                    });
            }
        });
    };

    return (
        <div>
            <div
                aria-hidden="true"
                className="nlt-button-inside-delete"
                data-cy="button-delete-device"
                onClick={() => deleteDevice()}
            >
                <i className="icofont icofont-bin" />
            </div>
        </div>
    );
};

export default DeleteSingleDevice;
