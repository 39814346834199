import React from "react";

import DeliveryIcon from "components/common/icons/DeliveryIcon";

export const DEFAULT_SHIPPING_DELAY = 5;

interface ShippingDelayInfoProps {
    shippingDelay: number | null;
}
const ShippingDelayInfo = (props: ShippingDelayInfoProps) => {
    const delay = props.shippingDelay || DEFAULT_SHIPPING_DELAY;

    return (
        <>
            <DeliveryIcon shippingDelay={delay} />
            {`Livraison estimée à ${delay} jours ouvrés après la signature du contrat.`}
        </>
    );
};

export default ShippingDelayInfo;
