export default function iconDeviceLink(deviceType: string) {
    switch (deviceType) {
        case "LAPTOP":
            return "https://images.ctfassets.net/85xu1vrcoskd/3mll8cs95Q5VAs1NRWjzYn/1671268eae9507fb499b49ac64966b9e/icon-laptop.png";
        case "TABLET":
            return "https://images.ctfassets.net/85xu1vrcoskd/6Jbge00wbqLrkpLcRy6MjP/8183ee4901116cd6053fed8c52263978/icon-tablet.png";
        case "PHONE":
            return "https://images.ctfassets.net/85xu1vrcoskd/6EUTmrRJrJ20yzKzu5AaBM/41728b3613ce0229efdda60f9d310beb/icon-phone.png";
        case "DESKTOP":
            return "https://images.ctfassets.net/85xu1vrcoskd/1Rh6YgyAeWg755vS7cbnWz/30de18057849a9ddecf78b9cbd5ee346/icon-desktop.png?h=250";
        case "OTHER":
            return "https://images.ctfassets.net/85xu1vrcoskd/2HvjBLqlGGLP569zaqf099/75e86b13e284b58514f31dc608618a2e/icon-other.png?h=250";
        default:
            return "";
    }
}
