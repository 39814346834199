import React, { useEffect, useState } from "react";

import { cloneDeep } from "@apollo/client/utilities";
import { useKeycloak } from "@react-keycloak/web";
import moment from "moment";
import { Loader } from "react-bootstrap-typeahead";
import Dropzone, {
    IFileWithMeta,
    IUploadParams,
} from "react-dropzone-uploader";
import { DownloadCloud } from "react-feather";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import SmallScreen from "components/common/layout/banner/small-screen";
import {
    CsvMappingInput,
    CsvSeparator,
    FileType,
    ImportCsvMappingFragment,
    OwnerCsvColumn,
    useFindInstanceQuery,
    useLoadOwnerCsvImportMutation,
    usePresignCompanyUploadLazyQuery,
    useSuggestMappingLazyQuery,
} from "graphql/management/model/apollo";

const CleaqSwal = withReactContent(Swal);

interface ErrorMapping {
    label: string;
    columns: Array<OwnerCsvColumn>;
}

const CsvUpload = (): JSX.Element => {
    const { initialized } = useKeycloak();
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState<Array<ErrorMapping>>([]);
    const [mapping, setMapping] = useState<ImportCsvMappingFragment>();
    const [mappingToPush, setMappingToPush] = useState<CsvMappingInput>({
        separator: CsvSeparator.SEMICILON,
        columnMapping: [],
    });
    const instance = useFindInstanceQuery();

    const [presignUploadFile] = usePresignCompanyUploadLazyQuery({
        variables: {
            fileName: "import.csv",
            fileType: FileType.IMPORT,
            folder: `${instance.data?.currentInstance?.shareId}/import`,
        },
    });
    const [loadOwnerFile] = useLoadOwnerCsvImportMutation();
    const [suggestMapping] = useSuggestMappingLazyQuery({
        variables: {
            filePath: "import.csv",
        },
    });

    useEffect(() => {
        const currentErrors: Array<ErrorMapping> = [];
        const email = mappingToPush.columnMapping.find(
            (mappingItem) =>
                mappingItem.suggestedColumn === OwnerCsvColumn.USER_EMAIL,
        );
        const serial = mappingToPush.columnMapping.find(
            (mappingItem) =>
                mappingItem.suggestedColumn === OwnerCsvColumn.DEVICE_SERIAL,
        );
        const deviceInfo = mappingToPush.columnMapping.find((mappingItem) =>
            [
                OwnerCsvColumn.DEVICE_NAME,
                OwnerCsvColumn.DEVICE_STORAGE,
                OwnerCsvColumn.DEVICE_SCREEN,
                OwnerCsvColumn.DEVICE_PROCESSOR,
                OwnerCsvColumn.DEVICE_MEMORY,
                OwnerCsvColumn.DEVICE_CUSTOM_NOTES,
                OwnerCsvColumn.DEVICE_TYPE,
                OwnerCsvColumn.DEVICE_OS,
            ].includes(mappingItem.suggestedColumn),
        );
        const userInfo = mappingToPush.columnMapping.find((mappingItem) =>
            [
                OwnerCsvColumn.USER_LASTNAME,
                OwnerCsvColumn.USER_FIRSTNAME,
                OwnerCsvColumn.USER_ADMIN,
            ].includes(mappingItem.suggestedColumn),
        );
        if (email) {
            if (
                !mappingToPush.columnMapping.find(
                    (mappingItem) =>
                        mappingItem.suggestedColumn ===
                        OwnerCsvColumn.USER_FIRSTNAME,
                ) ||
                !mappingToPush.columnMapping.find(
                    (mappingItem) =>
                        mappingItem.suggestedColumn ===
                        OwnerCsvColumn.USER_LASTNAME,
                )
            ) {
                currentErrors.push({
                    label: "Prénom et/ou Nom manquant",
                    columns: [],
                });
            }
        }
        if (serial) {
            if (
                !mappingToPush.columnMapping.find(
                    (mappingItem) =>
                        mappingItem.suggestedColumn ===
                        OwnerCsvColumn.DEVICE_NAME,
                )
            ) {
                currentErrors.push({
                    label: "Nom d'appareil manquant",
                    columns: [],
                });
            }
        }
        if (!serial && deviceInfo) {
            currentErrors.push({
                label: "N° de série manquant",
                columns: [],
            });
        }
        if (!email && userInfo) {
            currentErrors.push({
                label: "Email de l'utilisateur manquant",
                columns: [],
            });
        }
        const columns: OwnerCsvColumn[] = [];
        const ownerCsvColumnValues = Object.values(OwnerCsvColumn);
        ownerCsvColumnValues.forEach((column) => {
            if (
                mappingToPush.columnMapping.filter(
                    (mappingItem) => mappingItem.suggestedColumn === column,
                ).length > 1
            ) {
                columns.push(column);
            }
        });

        if (columns?.length) {
            currentErrors.push({
                label: "Des doublons sont présents",
                columns,
            });
        }
        setErrors(currentErrors);
    }, [mappingToPush]);

    const Redirect = () => {
        window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
    };

    const handleChangeStatus = (
        meta: { meta: any; file: any },
        status: any,
    ) => {};

    const getUploadParams = async (
        file: IFileWithMeta,
    ): Promise<IUploadParams> => {
        const url = await presignUploadFile();
        return {
            url: url.data?.presignUploadCompany ?? "",
            method: "PUT",
            body: file.file,
            headers: {
                "x-amz-meta-type": FileType.IMPORT,
                "x-amz-meta-code":
                    `${instance.data?.currentInstance?.shareId}/import`!,
                "x-amz-meta-instanceId": instance.data?.currentInstance?.id!,
            },
        };
    };

    const handleSubmit = async (files: any, allFiles: any[]) => {
        const responseMapping = await suggestMapping();
        if (responseMapping?.data) {
            const tempMapping = cloneDeep(
                responseMapping.data.suggestImportCsvMapping,
            );
            setMappingToPush({
                separator: tempMapping.separator,
                columnMapping: tempMapping.columnMapping.map((element) => ({
                    actualColumnName: element.actualColumnName,
                    suggestedColumn: element.suggestedColumn,
                    sample: element.sample,
                })),
            });
            setMapping(tempMapping);
            setStep(2);
        }
    };

    const UpdateMapping = (
        actualColumn: string,
        mappedColumn: OwnerCsvColumn,
    ): void => {
        setMappingToPush({
            separator: mappingToPush?.separator,
            columnMapping: mappingToPush?.columnMapping?.map((element) => {
                const returnedElement = element;
                if (element.actualColumnName === actualColumn) {
                    returnedElement.suggestedColumn = mappedColumn;
                }
                return returnedElement;
            }),
        });
    };

    const MappingUserOptions = [
        {
            value: OwnerCsvColumn.USER_EMAIL,
            label: "Utilisateur - Email",
        },
        {
            value: OwnerCsvColumn.USER_LASTNAME,
            label: "Utilisateur - Nom",
        },
        {
            value: OwnerCsvColumn.USER_FIRSTNAME,
            label: "Utilisateur - Prénom",
        },
        {
            value: OwnerCsvColumn.USER_ADMIN,
            label: "Utilisateur - Rôle",
        },
    ];

    const MappingDeviceOptions = [
        {
            value: OwnerCsvColumn.DEVICE_SERIAL,
            label: "Appareil - Num Série",
        },
        {
            value: OwnerCsvColumn.DEVICE_NAME,
            label: "Appareil - Nom",
        },
        {
            value: OwnerCsvColumn.DEVICE_TYPE,
            label: "Appareil - Type",
        },
        {
            value: OwnerCsvColumn.DEVICE_OS,
            label: "Appareil - OS",
        },
        {
            value: OwnerCsvColumn.DEVICE_SCREEN,
            label: "Appareil - Taille écran",
        },
        {
            value: OwnerCsvColumn.DEVICE_CUSTOM_NOTES,
            label: "Appareil - Notes",
        },
        {
            value: OwnerCsvColumn.DEVICE_MEMORY,
            label: "Appareil - Mémoire",
        },
        {
            value: OwnerCsvColumn.DEVICE_PROCESSOR,
            label: "Appareil - Processor",
        },
        {
            value: OwnerCsvColumn.DEVICE_STORAGE,
            label: "Appareil - Stockage",
        },
    ];

    const MappingOptions = [
        {
            label: "Appareil",
            options: MappingDeviceOptions,
        },

        {
            label: "Utilisateur",
            options: MappingUserOptions,
        },
        {
            label: "Ne pas utiliser",
            options: [
                {
                    value: OwnerCsvColumn.DEFAULT,
                    label: "Ne pas utiliser",
                },
            ],
        },
    ];

    const SubmitCsvLoad = async (): Promise<void> => {
        await loadOwnerFile({
            variables: {
                filePath: "import.csv",
                importMapping: mappingToPush,
            },
        });

        await CleaqSwal.fire({
            title: "Confirmé !",
            html: "Votre import CSV est en cours de traitement",
            icon: "success",
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: "Retour au dashboard",
        });

        Redirect();
    };

    const ShowErrors = async (): Promise<void> => {
        let errorsLi = "";
        let i = 0;
        errors.forEach((error, index) => {
            errorsLi += `<div class="m-t-5">${(i += 1)}. ${error.label}</div>`;
        });
        await CleaqSwal.fire({
            title: "Oops !",
            html: `Veuillez corriger les erreurs suivantes pour continuer : ${errorsLi}`,
            icon: "error",
        });
    };

    const accepts = [
        "text/comma-separated-values",
        "text/csv",
        "application/csv",
        "application/excel",
        "application/vnd.ms-excel",
        "application/vnd.msexcel",
        "text/anytext",
    ];

    if (!initialized) return <Loader />;

    return (
        <>
            <div className="nlt-container">
                <div className="nlt-card-full-import">
                    <div className="nlt-card-header">
                        <div className="nlt-card-title">
                            Importer des données
                        </div>
                        <div>
                            En 2 étapes, l'import d'un fichier .CSV vous permet
                            de charger les données de votre flotte informatique.
                            C'est efficace et rapide.
                        </div>
                    </div>

                    <div className="nlt-import-steps">
                        <div
                            className={`nlt-import-step-sep ${
                                step > 0
                                    ? "nlt-background-purple"
                                    : "nlt-background-grey"
                            }`}
                        />
                        <div className="nlt-import-step">
                            <p
                                className={
                                    step > 0
                                        ? "nlt-background-purple"
                                        : "nlt-background-grey"
                                }
                            >
                                1
                            </p>
                        </div>
                        <div
                            className={`nlt-import-step-sep ${
                                step > 1
                                    ? "nlt-background-purple"
                                    : "nlt-background-grey"
                            }`}
                        />
                        <div className="nlt-import-step">
                            <p
                                className={
                                    step > 1
                                        ? "nlt-background-purple"
                                        : "nlt-background-grey"
                                }
                            >
                                2
                            </p>
                        </div>
                        <div className="nlt-import-step-sep nlt-background-grey" />
                    </div>

                    <div className="nlt-import-title-header">
                        {step > 1 && (
                            <div
                                aria-hidden="true"
                                className="nlt-button-import-cancel"
                                onClick={() => setStep(step - 1)}
                            >
                                Retour
                            </div>
                        )}
                        {step === 2 && errors.length === 0 && (
                            <div
                                aria-hidden="true"
                                className="nlt-button-import"
                                onClick={SubmitCsvLoad}
                            >
                                <i className="fa fa-check nlt-button-icon-plus" />
                                Terminer
                            </div>
                        )}
                        {step === 2 && errors.length > 0 && (
                            <div
                                aria-hidden="true"
                                className="nlt-button-import-error"
                                onClick={ShowErrors}
                            >
                                <i className="icofont icofont-warning nlt-button-icon-plus" />
                                {`${errors.length} Erreurs`}
                            </div>
                        )}
                    </div>

                    {step === 1 && (
                        <div className="nlt-import-step-body">
                            <p className="title">
                                Préparons ensemble l'import de votre fichier
                                .csv
                            </p>
                            <div className="d-flex justify-content-start align-items-center">
                                <p>
                                    Vous n'avez pas encore de fichier prêt à
                                    l'import ?
                                </p>
                                <a
                                    className="nlt-import-template-download"
                                    href={`https://files.cleaq.com/import/Cleaq-ImportTemplate.csv?date=${moment().format(
                                        "YYYY-MM-DD",
                                    )}`}
                                    download
                                >
                                    <DownloadCloud />
                                    Télécharger le template
                                </a>
                            </div>

                            <div className="nlt-import-step-second">
                                <Dropzone
                                    onChangeStatus={handleChangeStatus}
                                    getUploadParams={getUploadParams}
                                    maxFiles={1}
                                    multiple={false}
                                    canCancel={false}
                                    onSubmit={handleSubmit}
                                    submitButtonContent="Continuer"
                                    inputContent={(_, extra) => {
                                        if (extra.reject) {
                                            return "Fichier CSV uniquement";
                                        }
                                        if (extra.active) {
                                            return "Déposez le fichier";
                                        }
                                        return "Déposez votre fichier .CSV ou cliquez ici";
                                    }}
                                    accept={accepts.join(", ")}
                                    styles={{
                                        dropzone: {
                                            height: "100%",
                                            width: "100%",
                                        },
                                        dropzoneActive: {
                                            borderColor: "#59cf94",
                                        },
                                        dropzoneReject: {
                                            borderColor: "#e75767",
                                        },
                                        inputLabel: (_, extra) => {
                                            if (extra.reject) {
                                                return { color: "#e75767" };
                                            }
                                            if (extra.active) {
                                                return { color: "#59cf94" };
                                            }
                                            return {};
                                        },
                                    }}
                                />
                                <div className="nlt-import-step-footer">
                                    <div>Assurez-vous que :</div>
                                    <ul>
                                        <li>
                                            <i className="icofont icofont-arrow-right m-r-5" />
                                            Le nom de chaque colonne est
                                            renseigné
                                        </li>
                                        <li>
                                            <i className="icofont icofont-arrow-right m-r-5" />
                                            L'encodage est en UTF-8
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}

                    {step === 2 && (
                        <div className="nlt-import-step-body">
                            <div className="nlt-import-step-third">
                                <table className="table table-bordernone ">
                                    <thead>
                                        <tr>
                                            <th className="nlt-import-step-third-row-header">
                                                Colonne actuelle
                                            </th>
                                            <th className="nlt-import-step-third-row-header">
                                                Champ correspondant
                                            </th>
                                            <th className="nlt-import-step-third-row-header">
                                                Échantillon
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mapping &&
                                            mapping.columnMapping.map(
                                                (column) => (
                                                    <tr
                                                        key={
                                                            column.actualColumnName
                                                        }
                                                    >
                                                        <td className="nlt-import-step-third-row-col">
                                                            {
                                                                column.actualColumnName
                                                            }
                                                        </td>
                                                        <td className="nlt-import-step-third-row-col">
                                                            <Select
                                                                defaultValue={MappingOptions.flatMap(
                                                                    (element) =>
                                                                        element.options,
                                                                ).find(
                                                                    (element) =>
                                                                        element.value ===
                                                                        column.suggestedColumn,
                                                                )}
                                                                onChange={(
                                                                    option,
                                                                ) =>
                                                                    UpdateMapping(
                                                                        column.actualColumnName,
                                                                        option?.value ||
                                                                            OwnerCsvColumn.DEFAULT,
                                                                    )
                                                                }
                                                                options={
                                                                    MappingOptions
                                                                }
                                                                styles={{
                                                                    control: (
                                                                        styles: any,
                                                                        data,
                                                                    ) => {
                                                                        const val =
                                                                            data
                                                                                ?.getValue()
                                                                                ?.values()
                                                                                ?.next()?.value;
                                                                        if (
                                                                            errors.find(
                                                                                (
                                                                                    error,
                                                                                ) =>
                                                                                    error.columns.includes(
                                                                                        val?.value,
                                                                                    ),
                                                                            )
                                                                        ) {
                                                                            return {
                                                                                ...styles,
                                                                                borderColor:
                                                                                    "#e75767",
                                                                            };
                                                                        }
                                                                        return {
                                                                            ...styles,
                                                                        };
                                                                    },
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="nlt-import-step-third-row-col">
                                                            {column.sample.join(
                                                                "; ",
                                                            )}
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <SmallScreen />
        </>
    );
};

export default withRouter(CsvUpload);
