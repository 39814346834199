import React from "react";

import Configure from "components/business/catalog/configuration/configure/Configure";
import ConfigurePrice from "components/business/catalog/configuration/configure/ConfigurePrice";
import SuggestedAccessories from "components/business/catalog/configuration/configure/suggested-accessories/SuggestedAccessories";
import DurationInput from "components/business/catalog/configuration/DurationInput";
import ShippingDelayInfo from "components/business/catalog/shipping-delay-info/shipping-delay-info";
import AddProduct from "components/business/catalog/utils/add-product";
import buildTempCartPrice from "components/business/catalog/utils/build-price";
import { AccessoryType } from "components/business/catalog/utils/types";
import RepairabilityIndex from "components/common/icons/repairability-index/repairability-index";
import AddProductAction from "components/provider/context/cart/actions/add-product-action";
import { useCartDispatch } from "components/provider/context/cart/cart-provider";
import UpdateDurationTempCartAction from "components/provider/context/temp-cart/actions/update-duration-temp-cart-action";
import UpdateQuantityTempCartAction from "components/provider/context/temp-cart/actions/update-quantity-temp-cart-action";
import {
    useTempCart,
    useTempCartDispatch,
} from "components/provider/context/temp-cart/temp-cart-provider";
import { ProductFragment, VariantFragment } from "graphql/catalog/model/apollo";
import { formatPrice } from "utils/translator/translator";

interface DeviceItemCatalogDetailsProps {
    product: ProductFragment;
    variants: VariantFragment[];
    instanceId: string;
    onClose: () => void;
}

const DeviceItemCatalogDetails = (props: DeviceItemCatalogDetailsProps) => {
    const { product, variants, instanceId } = props;
    const tempCart = useTempCart();
    const tempCartDispatch = useTempCartDispatch();
    const cartDispatch = useCartDispatch();

    const setCurrentLeasingDuration = (duration: number): void => {
        tempCartDispatch(new UpdateDurationTempCartAction(duration));
    };
    const tempCartPrice = buildTempCartPrice(props.instanceId, tempCart);

    const handleIncrement = (increment: boolean) => {
        if (increment) {
            tempCartDispatch(new UpdateQuantityTempCartAction(true));
        } else if (tempCart.quantity > 1) {
            tempCartDispatch(new UpdateQuantityTempCartAction(false));
        }
    };

    const handleAddToCart = () => {
        const addProduct = new AddProduct(
            tempCart.variant,
            props.instanceId,
            tempCart.quantity,
            tempCart.leasingDuration,
        );
        addProduct.setProductName(props.product.name);

        const products = [
            addProduct,
            ...tempCart.suggestedAccessories.map(
                (accessory) =>
                    new AddProduct(
                        accessory,
                        props.instanceId,
                        tempCart.quantity,
                        tempCart.leasingDuration,
                    ),
            ),
        ];

        cartDispatch(new AddProductAction(products));
        props.onClose();
    };

    const accessoriesWithPriceForDuration =
        props.product.suggestedAccessoriesCollection?.items
            .filter((e): e is AccessoryType => e !== null)
            .filter((accessory) => {
                const priceKey =
                    `price${tempCart.leasingDuration}` as keyof AccessoryType;
                return accessory[priceKey] !== null && accessory[priceKey] > 0;
            }) ?? [];

    return (
        <div className="catalog-product-right">
            <div className="catalog-product-right-top">
                <div className="catalog-product-repairability">
                    {product.repairability && product.repairability > 0 && (
                        <RepairabilityIndex index={product.repairability} />
                    )}
                </div>
                <div className="catalog-product-title">
                    <h4 className="product-title">{product.name}</h4>
                </div>
                <div
                    className="catalog-product-shipping-delay"
                    data-cy="shipping-delay"
                >
                    <ShippingDelayInfo
                        shippingDelay={tempCart.variant.shippingDelay}
                    />
                </div>
            </div>
            <div className="scroll">
                <DurationInput
                    product={tempCart.variant}
                    currentLeasingDuration={tempCart.leasingDuration}
                    setCurrentLeasingDuration={setCurrentLeasingDuration}
                />
                {variants.length > 0 && <Configure variants={props.variants} />}
                {accessoriesWithPriceForDuration.length > 0 && (
                    <div className="suggested-accessories-recommendation">
                        <p className="suggested-recommendation">
                            {accessoriesWithPriceForDuration.length > 1
                                ? "Recommandés avec ce produit"
                                : "Recommandé avec ce produit"}
                        </p>
                        <div className="suggested-accessories">
                            {accessoriesWithPriceForDuration.map(
                                (suggestedAccessory) => {
                                    const possiblePrices = new Map();
                                    possiblePrices.set(
                                        24,
                                        suggestedAccessory?.price24,
                                    );
                                    possiblePrices.set(
                                        36,
                                        suggestedAccessory?.price36,
                                    );
                                    possiblePrices.set(
                                        48,
                                        suggestedAccessory?.price48,
                                    );

                                    return [24, 36, 48].map((duration) => {
                                        if (
                                            suggestedAccessory &&
                                            tempCart.leasingDuration ===
                                                duration &&
                                            possiblePrices.get(duration) > 0
                                        ) {
                                            return (
                                                <SuggestedAccessories
                                                    suggestedAccessory={
                                                        suggestedAccessory
                                                    }
                                                    instanceId={instanceId}
                                                    key={
                                                        suggestedAccessory.sys
                                                            .id
                                                    }
                                                />
                                            );
                                        }
                                        return null;
                                    });
                                },
                            )}
                        </div>
                    </div>
                )}
            </div>
            <ConfigurePrice
                quantity={tempCart.quantity}
                accessoriesSubtotal={
                    tempCartPrice.accessoriesSubtotal
                        ? formatPrice(tempCartPrice.accessoriesSubtotal)
                        : null
                }
                variantSubtotal={
                    tempCartPrice.variantSubtotal
                        ? formatPrice(tempCartPrice.variantSubtotal)
                        : null
                }
                totalPrice={tempCartPrice.totalPrice}
                negotiated={tempCartPrice.negotiated}
                normalPrice={tempCartPrice.normalPrice}
                handleIncrement={handleIncrement}
                handleAddToCart={handleAddToCart}
            />
        </div>
    );
};

export default DeviceItemCatalogDetails;
