import React, { useEffect, useState } from "react";

import { ThemeProvider } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import { withRouter } from "react-router-dom";

import Loader from "components/common/layout/loader/loader";
import Sidebar from "components/common/layout/sidebar/sidebar";
import settings from "config";
import CleaqTheme from "utils/theme-cleaq/theme-cleaq";

const App = ({ children }: any) => {
    const { initialized, keycloak } = useKeycloak();
    const [profileReady, setProfileReady] = useState(false);

    useEffect(() => {
        if (settings.REACT_APP_ENV === "prod") {
            const script = document.createElement("script");
            script.text =
                "     (function(h,o,t,j,a,r){\n" +
                "        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n" +
                "        h._hjSettings={hjid:2852821,hjsv:6};\n" +
                "        a=o.getElementsByTagName('head')[0];\n" +
                "        r=o.createElement('script');r.async=1;\n" +
                "        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n" +
                "        a.appendChild(r);\n" +
                "      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";

            document.body.appendChild(script);
        }
    }, []);

    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            Promise.all([keycloak.loadUserProfile(), keycloak.loadUserInfo()])
                .then(() => setProfileReady(true))
                .catch(() => {
                    keycloak.logout().then(() => {
                        window.location.href = keycloak.createLoginUrl();
                    });
                });
        }
    }, [initialized, keycloak]);

    return (
        <>
            <ThemeProvider theme={CleaqTheme}>
                {profileReady ? (
                    <div
                        className="page-wrapper compact-wrapper"
                        id="pageWrapper"
                    >
                        <div className="page-body-wrapper sidebar-icon">
                            <Sidebar />
                            <div className="page-body">{children}</div>
                        </div>
                    </div>
                ) : (
                    <Loader main />
                )}
            </ThemeProvider>
        </>
    );
};

export default withRouter(App);
