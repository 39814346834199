import React from "react";

import { ApolloProvider } from "@apollo/client";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Widget from "components/common/widget/widget";
import useFeatureFlags, { feature } from "components/hooks/useFeatureFlags";
import App from "components/provider/app/app";
import Error404 from "pages/error-404/error-404";
import { routes } from "routes/routes";
import apolloClient from "utils/apollo/apollo";
import { checkAuthorization } from "utils/authentication/authentication";
import keycloakClient from "utils/keycloak/keycloak";
import { tokenLogger } from "utils/token/token";
import useEffectOnce from "utils/use-effect-once/use-effect-once";

import "./index.scss";
import "@cleaq/design-system/styles.css";
import "react-phone-number-input/style.css";

const Root = () => {
    // React Transition Group:
    // How to pass ref to mapped component to avoid Warning: findDOMNode is deprecated in StrictMode
    // https://stackoverflow.com/a/66587451
    const nodeRef = React.useRef(null);
    const abortController = new AbortController();

    const { keycloak, initialized } = useKeycloak();
    const { router: toggleRouter } = useFeatureFlags();

    function cleanup() {
        abortController.abort();
    }

    useEffectOnce(cleanup);

    const appRoutes = routes(keycloak, toggleRouter);

    const widgetEnabled = toggleRouter.featureIsEnabled(feature.HELP_WIDGET);

    return (
        <BrowserRouter basename="/">
            <App>
                <TransitionGroup>
                    <Switch>
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/`}
                            render={() => <Redirect to={appRoutes[0]?.path} />}
                        />
                        {appRoutes.map(({ path, params, Component }) => (
                            <Route
                                key={path}
                                exact
                                path={`${process.env.PUBLIC_URL}${
                                    params || path
                                }`}
                            >
                                {({ match }) => (
                                    <CSSTransition
                                        nodeRef={nodeRef}
                                        in={match != null}
                                        timeout={500}
                                        classNames="router-transition"
                                        onEnter={() =>
                                            checkAuthorization(
                                                initialized,
                                                keycloak,
                                            )
                                        }
                                        unmountOnExit
                                    >
                                        <Component />
                                    </CSSTransition>
                                )}
                            </Route>
                        ))}
                        <Route
                            path="*"
                            component={Error404}
                        />
                    </Switch>
                </TransitionGroup>
                {widgetEnabled && <Widget />}
            </App>
        </BrowserRouter>
    );
};

const container = document.getElementById("root");
const root = createRoot(container!);

// TODO: React.StrictMode causes infinite loop due to keycloak
// we need to fix this and set it back to StrictMode
root.render(
    <ReactKeycloakProvider
        authClient={keycloakClient}
        onTokens={tokenLogger}
        initOptions={{
            onLoad: "login-required",
            checkLoginIframe: false,
        }}
    >
        <ApolloProvider client={apolloClient}>
            <Root />
        </ApolloProvider>
    </ReactKeycloakProvider>,
);
