import React, { ComponentPropsWithoutRef, ElementType } from "react";

import classnames from "classnames";

import styles from "./Button.module.scss";

type ButtonProps<T extends ElementType> = {
    theme?: string;
    children?: React.ReactNode;
    as?: T;
};

const Button = <T extends ElementType>({
    theme,
    children,
    as,
    ...rest
}: ButtonProps<T> & ComponentPropsWithoutRef<T>) => {
    const getTheme = (value: string) => theme === value;
    const Component = as ?? "button";

    return (
        <Component
            className={classnames(styles.button, styles.defaultButton, {
                [styles.primaryButton]: getTheme("primary"),
                [styles.secondaryButton]: getTheme("secondary"),
                [styles.outlinedPrimaryButton]: getTheme("outlinedPrimary"),
                [styles.outlinedSecondaryButton]: getTheme("outlinedSecondary"),
                [styles.outlinedTertiaryButton]: getTheme("outlinedTertiary"),
                [styles.disabledButton]: getTheme("disabledButton"),
            })}
            {...rest}
        >
            {children}
        </Component>
    );
};

Button.defaultProps = {
    theme: "default",
    children: "Button",
};

export default Button;
