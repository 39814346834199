import React from "react";

import { TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import Product from "components/business/order/product/product";
import SetProductInstructionsAction from "components/provider/context/cart/actions/set-product-instructions-action";
import { useCartDispatch } from "components/provider/context/cart/cart-provider";

interface ProductItemPopupProps {
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    product: Product;
}

class InstructionsForm {
    instructions: string = "";
}

export default function ProductItemPopup(props: ProductItemPopupProps) {
    const { handleSubmit, control } = useForm<InstructionsForm>({
        mode: "onChange",
        defaultValues: {
            instructions: props.product.instructions ?? "",
        },
    });

    const cartDispatch = useCartDispatch();

    const handleSubmitInstructions = (form: InstructionsForm) => {
        const instructions = form.instructions ?? "";
        cartDispatch(
            new SetProductInstructionsAction(props.product, instructions),
        );
        props.setIsModalVisible(false);
    };

    return (
        <div className="pop-up-wrapper">
            <div className="adding-instructions-modal modal-large">
                <button
                    type="button"
                    className="nlt-button-inside-close absolute-close"
                    onClick={() => props.setIsModalVisible(false)}
                >
                    <i className="icofont icofont-close" />
                </button>
                <form onSubmit={handleSubmit(handleSubmitInstructions)}>
                    <div className="head-title">
                        Ajouter des demandes spécifiques
                    </div>
                    <div className="modal-body with-foot">
                        <div className="create-modal-form">
                            <span>Instructions</span>
                            <p className="delay-indication">
                                Attention : les demandes de langues de claviers
                                spécifiques sont susceptibles d’impacter
                                fortement les délais de livraison (3/4 semaines
                                environ)
                            </p>
                            <Controller
                                name="instructions"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Type de clavier, accessoire supplémentaire..."
                                        variant="outlined"
                                        multiline
                                        fullWidth
                                        minRows={5}
                                        color="primary"
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="modal-buttons">
                        <button
                            type="submit"
                            className="cleaq-button"
                        >
                            Enregistrer
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
