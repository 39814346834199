import React from "react";

import { useKeycloak } from "@react-keycloak/web";
import moment from "moment";

import AssignUser from "components/business/user/AssignUserManagement";
import RepairabilityIndex from "components/common/icons/repairability-index/repairability-index";
import CustomNotes from "components/common/layout/custom-notes/custom-notes";
import {
    CarbonFootprintFragment,
    CarbonSavingsFragment,
    DeviceFragment,
    DeviceSource,
    IntegrationProvider,
    useFindIntegrationsQuery,
} from "graphql/management/model/apollo";
import computeDeviceStatus from "utils/device/device-compute-status";
import { typesLabels, osLabels } from "utils/device/device-labels";
import { formatPrice } from "utils/translator/translator";

import DeviceDisplayFile from "./device-display-file";
import DeviceDisplayLine from "./device-display-line";
import DeviceDetailsMDM from "./DeviceDetailsMDM/DeviceDetailsMDM";

import "./display-device.scss";

interface deviceDetailsDisplayInterface {
    selectedDevice: DeviceFragment;
    setSelectedDevice: React.Dispatch<
        React.SetStateAction<DeviceFragment | null | undefined>
    >;
    isKeycloakUser: Boolean;
}

const DeviceDetailsDisplay = (props: deviceDetailsDisplayInterface) => {
    const { keycloak } = useKeycloak();

    const integrationsQuery = useFindIntegrationsQuery();

    const integrationsConfig = integrationsQuery.data?.currentInstance;

    const integrationsBravas = integrationsConfig?.integrations.filter(
        (integrations) => integrations.provider === IntegrationProvider.BRAVAS,
    );

    const hasIntegrations = integrationsBravas && integrationsBravas.length > 0;

    const bravasFQDN = integrationsBravas?.map((integration) =>
        integration.config
            .filter((configs) => configs.name === "fqdn")
            ?.map((config) => config.value),
    );

    function displayDeviceDate(deviceDate: string) {
        return moment.utc(deviceDate).format("DD/MM/YYYY");
    }

    function spanDeviceStatus(selectedDevice: DeviceFragment) {
        return (
            <span className={computeDeviceStatus(selectedDevice).color}>
                {computeDeviceStatus(selectedDevice).status}
            </span>
        );
    }

    if (props.selectedDevice.ecoData === null) {
        return <></>;
    }

    const { manufacturing } = props.selectedDevice.ecoData.carbonFootprint;
    const { transport } = props.selectedDevice.ecoData.carbonFootprint;
    const { refurb } = props.selectedDevice.ecoData.carbonSavings;
    const { repairs } = props.selectedDevice.ecoData.carbonSavings;
    const { repairabilityIndex } = props.selectedDevice.ecoData;

    const DataCarbonFootprint = ({
        manufacturing,
        transport,
    }: CarbonFootprintFragment) => (
        <>
            {`${(manufacturing + transport).toFixed(1)} kg de `}
            <span>
                CO
                <sub>2</sub>
            </span>
        </>
    );

    const DataCarbonSavings = ({ refurb, repairs }: CarbonSavingsFragment) => (
        <>
            {`${(refurb + repairs).toFixed(1)} kg de `}
            <span>
                CO
                <sub>2</sub>
            </span>
        </>
    );

    const DatasCarbonNotNull = () => {
        if (manufacturing > 0 && transport > 0 && refurb > 0 && repairs > 0) {
            return true;
        }
        return false;
    };

    const RepairabilityIndexExists = () => {
        if (repairabilityIndex && repairabilityIndex > 0) {
            return true;
        }
        return false;
    };

    return (
        <>
            <DeviceDisplayLine
                label="Date de l'ajout"
                data={displayDeviceDate(props.selectedDevice.createdAt)}
            />
            <DeviceDisplayLine
                label="Nom de l'appareil"
                data={props.selectedDevice.reference}
            />
            <DeviceDisplayLine
                label="N° Série"
                data={props.selectedDevice.serialNumber}
            />
            <DeviceDisplayLine
                label="Statut"
                data={spanDeviceStatus(props.selectedDevice)}
            />
            <DeviceDisplayLine
                label="Type d'appareil"
                data={typesLabels.get(props.selectedDevice.specs.deviceType)}
            />
            <DeviceDisplayLine
                label="OS de l'appareil"
                data={osLabels.get(props.selectedDevice.specs.deviceOs)}
            />

            <div className="nlt-device-sep" />

            <h6 className="mb-3">Caractéristiques techniques</h6>
            <DeviceDisplayLine
                label="Taille d'écran"
                data={props.selectedDevice.specs.screenSize}
            />
            <DeviceDisplayLine
                label="Stockage"
                data={props.selectedDevice.specs.storageSize}
            />
            <DeviceDisplayLine
                label="Mémoire vive (RAM)"
                data={props.selectedDevice.specs.memorySize}
            />
            <DeviceDisplayLine
                label="Processeur"
                data={props.selectedDevice.specs.processor}
            />

            <div className="nlt-device-sep" />

            {keycloak.hasRealmRole("OWNER") && hasIntegrations && (
                <DeviceDetailsMDM
                    serialNumber={props.selectedDevice.serialNumber}
                    bravasFQDN={bravasFQDN}
                />
            )}

            {!props.isKeycloakUser && (
                <div>
                    <AssignUser
                        selectedDevice={props.selectedDevice}
                        setSelectedDevice={props.setSelectedDevice}
                    />
                    <div className="nlt-device-sep" />
                </div>
            )}

            {props.selectedDevice.source === DeviceSource.CLEAQ && (
                <>
                    <h6 className="mb-3">Mon offre de location</h6>

                    {props.selectedDevice.leasingData?.leasingPrice && (
                        <DeviceDisplayLine
                            label="Montant de ma location"
                            data={
                                <>
                                    <strong>
                                        {formatPrice(
                                            props.selectedDevice.leasingData
                                                ?.leasingPrice,
                                        )}
                                    </strong>{" "}
                                    HT / mois
                                </>
                            }
                        />
                    )}

                    {props.selectedDevice.leasingData?.leasingStart && (
                        <DeviceDisplayLine
                            label="Date de début du contrat"
                            data={displayDeviceDate(
                                props.selectedDevice.leasingData?.leasingStart!,
                            )}
                        />
                    )}

                    {props.selectedDevice.leasingData?.leasingEnd && (
                        <DeviceDisplayLine
                            label="Date de fin du contrat"
                            data={displayDeviceDate(
                                props.selectedDevice.leasingData?.leasingEnd!,
                            )}
                        />
                    )}

                    {props.selectedDevice?.contractFile &&
                        props.selectedDevice?.contractFile?.fileName && (
                            <DeviceDisplayFile
                                label="Mon contrat"
                                filePath={
                                    props.selectedDevice?.contractFile
                                        ?.filePath!
                                }
                                fileName={
                                    props.selectedDevice?.contractFile?.fileName
                                }
                            />
                        )}

                    {props.selectedDevice?.scheduleFile &&
                        props.selectedDevice?.scheduleFile?.fileName && (
                            <DeviceDisplayFile
                                label="Mon échéancier"
                                filePath={
                                    props.selectedDevice?.scheduleFile
                                        ?.filePath!
                                }
                                fileName={
                                    props.selectedDevice?.scheduleFile?.fileName
                                }
                            />
                        )}

                    <div className="nlt-device-sep" />
                </>
            )}
            {props.selectedDevice.source === DeviceSource.CLEAQ &&
                (DatasCarbonNotNull() || RepairabilityIndexExists()) && (
                    <>
                        <div className="ecodata-title">
                            <h6
                                className="mb-3"
                                data-cy="ecodata-title"
                            >
                                Données environnementales
                            </h6>
                            {DatasCarbonNotNull() && (
                                <a
                                    href="https://cleaq.notion.site/M-thodologie-Simulateur-Carbone-989f4d8a22514417af9272c699594d82"
                                    className="ecodata-more-button"
                                    title="En savoir plus"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="icofont icofont-question-circle" />
                                </a>
                            )}
                        </div>
                        {DatasCarbonNotNull() && (
                            <>
                                <DeviceDisplayLine
                                    label="Empreinte carbone"
                                    data={
                                        <DataCarbonFootprint
                                            {...props.selectedDevice.ecoData
                                                .carbonFootprint}
                                        />
                                    }
                                    data-cy="carbon-footprint"
                                />
                                <DeviceDisplayLine
                                    label="Emissions évitées grâce à Cleaq"
                                    data={
                                        <DataCarbonSavings
                                            {...props.selectedDevice.ecoData
                                                .carbonSavings}
                                        />
                                    }
                                    data-cy="carbon-savings"
                                />
                            </>
                        )}
                        {repairabilityIndex && RepairabilityIndexExists() && (
                            <DeviceDisplayLine
                                label="Indice de réparabilité"
                                data={
                                    <RepairabilityIndex
                                        index={repairabilityIndex}
                                    />
                                }
                                data-cy="repairability-index"
                            />
                        )}
                        <div className="nlt-device-sep" />
                    </>
                )}
            <CustomNotes
                notes={props.selectedDevice.customNotes}
                data-cy="device-display-notes"
            />
        </>
    );
};

export default DeviceDetailsDisplay;
