import React, { useEffect, useState } from "react";

import { useCart } from "components/provider/context/cart/cart-provider";

import CartProduct from "./cart-product/cart-product";
import CartSummary from "./cart-summary/cart-summary";

import "./cart.scss";

interface CartProps {
    onValidate: () => void;
    catalog: boolean;
}

const Cart = (props: CartProps) => {
    const cart = useCart();
    const [showCart, setShowCart] = useState<boolean>(false);

    useEffect(() => {
        const handleOnKeydown = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                setShowCart(false);
            }
        };
        document.addEventListener("keydown", handleOnKeydown);
        return () => document.removeEventListener("keydown", handleOnKeydown);
    }, []);

    const uniqueVariants = cart
        .map((product) => product.variantId)
        .filter((variant, idx, arr) => arr.indexOf(variant) === idx);

    const productsByVariantOrderedByDuration = uniqueVariants
        .map((variantId) =>
            cart.filter((product) => product.variantId === variantId),
        )
        .map((productByVariant) =>
            productByVariant.sort(
                (a, b) => a.leasingDuration - b.leasingDuration,
            ),
        )
        .filter((productList) => productList.length > 0);

    const productsByVariantAndDuration = productsByVariantOrderedByDuration.map(
        (productList) => {
            const uniqueDuration = productList
                .map((product) => product.leasingDuration)
                .filter((duration, idx, arr) => arr.indexOf(duration) === idx);
            const productByLeasingDuration = uniqueDuration.map((duration) =>
                productList.filter(
                    (product) => product.leasingDuration === duration,
                ),
            );
            return productByLeasingDuration;
        },
    );

    return (
        <>
            <div className={`${showCart ? "pop-up-wrapper" : ""}`}>
                <div
                    className={`cart ${
                        showCart ? "cart-visible" : "cart-hidden"
                    }`}
                >
                    <div className="cart-head">
                        <div>
                            <div>
                                <span
                                    aria-hidden="true"
                                    className="round-button"
                                    onClick={() => {
                                        setShowCart(!showCart);
                                    }}
                                >
                                    <i className="icofont icofont-close" />
                                </span>
                            </div>
                            Panier
                        </div>
                        <CartSummary
                            isCatalogStep={props.catalog}
                            isCartVisible={showCart}
                            onCartToggle={() => setShowCart(!showCart)}
                        />
                    </div>
                    <div className="cart-items">
                        {productsByVariantAndDuration.map(
                            (productsByDuration) =>
                                productsByDuration.map((productList) => (
                                    <CartProduct
                                        key={`${productList[0].variantId}-
                                            ${productList[0].leasingDuration}`}
                                        productList={productList}
                                    />
                                )),
                        )}
                    </div>
                    {props.catalog && cart.length > 0 && (
                        <div className="foot">
                            <div
                                aria-hidden="true"
                                className="cleaq-button"
                                data-cy="cart-submit"
                                id="cartSubmit"
                                onClick={() => {
                                    setShowCart(false);
                                    props.onValidate();
                                }}
                            >
                                Valider mon panier
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Cart;
