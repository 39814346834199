import React from "react";

import ProductUser from "components/business/order/product/product-user";

import "./affected-user-label.scss";

interface AffectedUserLabelProps {
    user: ProductUser | null;
}

export default function AffectedUserLabel(props: AffectedUserLabelProps) {
    const { user } = props;
    const classes = `device__user ${
        user === null ? "notify-badge" : "success-badge"
    }`;
    const label =
        user === null
            ? "Aucun Utilisateur"
            : `${user.firstName} ${user.lastName}`;

    return (
        <div className={classes}>
            <p>{label}</p>
        </div>
    );
}
